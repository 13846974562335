import * as React from 'react'
import {
  SymptomFilterDropdownLayout,
  SymptomFilterDropdownContainer,
  SymptomSelectorMenuItem,
} from '../symptomSelector.styles'
import { ControlledSelect } from '~/util/minorAilments/reactHookFormSelect'
import { white } from '~/sharedStyles/colorPalette'
import { SymptomSvgIcon } from '../symptomSvgIcon/SymptomSvgIcon'
import { AppointmentActivityGroup } from '~/lib/appointmentActivityGroup'

interface FormInputProps {
  name: string
  control: any
  symptoms: AppointmentActivityGroup[]
  label: string
  methods: any
  selectedSymptom: string
  setSelectedSymptom: any
}

export const SymptomFilterDropdown: React.FC<FormInputProps> = ({
  name,
  control,
  symptoms,
  label,
  methods,
  selectedSymptom,
  setSelectedSymptom,
}) => {
  const dropdownRenderFunction = () => {
    const selected = symptoms.filter(
      (option) => option.urlParam === selectedSymptom
    )
    const label = selected[0].title
    return <div>{label}</div>
  }

  return (
    <SymptomFilterDropdownContainer>
      <SymptomFilterDropdownLayout>
        <ControlledSelect
          id="symptom"
          name={name}
          label={label}
          control={control}
          defaultValue={selectedSymptom}
          variant="outlined"
          margin="normal"
          register={methods.register(name)}
          renderValue={dropdownRenderFunction}
          style={{ backgroundColor: white }}
        >
          {symptoms.map((symptom, index) => {
            return (
              <SymptomSelectorMenuItem
                key={index}
                selecteditem={symptom.urlParam === selectedSymptom ? 1 : 0}
                value={symptom.urlParam}
                onClick={() => setSelectedSymptom(symptom.urlParam)}
              >
                <div
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    width: '3rem',
                    height: '2rem',
                    marginRight: '1rem',
                  }}
                >
                  <SymptomSvgIcon
                    width="95%"
                    height="95%"
                    selectedSymptom={selectedSymptom === symptom.urlParam}
                    iconPath={symptom.iconUrl}
                  />
                </div>

                <div>{symptom.title}</div>
              </SymptomSelectorMenuItem>
            )
          })}
        </ControlledSelect>
      </SymptomFilterDropdownLayout>
    </SymptomFilterDropdownContainer>
  )
}
