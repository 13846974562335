import { Box, Button, Container, Typography } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import {
  DebuggerActions,
  DebuggerContainer,
  DebuggerMenu,
} from './debugger.styles'
import { FeatureFlags } from './menuOptions/featureFlags'
import { Tenants } from './menuOptions/tenants'

export const Debugger = () => {
  const [mainComponent, setMainComponent] = useState<string>('')
  const closeDebugger = () => {
    localStorage.removeItem('medme-debugger')
    window.location.reload()
  }

  return (
    <DebuggerContainer>
      <DebuggerMenu>
        <Typography variant="h5">Debugger</Typography>
        <Box>
          <Button
            onClick={() => {
              setMainComponent('featureFlags')
            }}
          >
            Feature Flags
          </Button>
          <Button
            onClick={() => {
              setMainComponent('tenants')
            }}
          >
            Tenants
          </Button>
        </Box>
        <DebuggerActions>
          <Button onClick={closeDebugger}>Quit Debugger</Button>
        </DebuggerActions>
      </DebuggerMenu>
      <Container>
        {mainComponent === 'featureFlags' && <FeatureFlags />}
        {mainComponent === 'tenants' && <Tenants />}
      </Container>
    </DebuggerContainer>
  )
}
