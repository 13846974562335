import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ActivityGroupCards } from './ActivityGroupCards/ActivityGroupCards'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useProvinces } from './ActivityGroupCards/hooks/useProvinces'
import { useGetGroupAppointmentActivities } from './useGetGroupAppointmentActivities'
import { Loading } from '~/components/loading/loading'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { EAppointmentTypeMethod } from '~/graphql/types/schema.type'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import uniqBy from 'lodash/uniqBy'
import { useGetAvailableProvinces } from '../../hooks/useGetAvailableProvinces'

export interface ActivityGroupLandingFormState {
  province: string
  symptom: string
  appointmentId: string
  method: EAppointmentTypeMethod
}

export const ActivityGroupLandingForm: React.FC = () => {
  const {
    state: {
      scope,
      router: {
        searchParams: { appointmentType, global },
      },
      pharmacy,
      appointmentActivityGroup,
    },
    selectAppointmentActivityAsync,
  } = useBookingContext()

  const { t } = useMedMeTranslation()

  const { nextStep } = useFormManager()
  const [error, setError] = React.useState('')
  const provincesData = useProvinces()
  const defaultValues = {
    province: scope.regionCode || '',
    appointmentId: '',
  }
  const methods = useForm<ActivityGroupLandingFormState>({
    defaultValues: defaultValues,
    shouldUnregister: false,
  })

  const groupId = appointmentActivityGroup.id

  const { availableProvinces, loading: getAvailableProvincesLoading } =
    useGetAvailableProvinces(appointmentActivityGroup.id)

  const filteredProvinces = React.useMemo(() => {
    if (!getAvailableProvincesLoading && availableProvinces) {
      return provincesData.filter(({ value }) =>
        availableProvinces.includes(value)
      )
    }
    return []
  }, [getAvailableProvincesLoading, availableProvinces, provincesData])

  React.useEffect(() => {
    if (filteredProvinces.length === 1)
      methods.setValue('province', filteredProvinces[0].value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProvinces, methods?.setValue])

  const formProvince = methods.watch('province')
  const selectedProvince = provincesData?.some(
    (dataProvince) => dataProvince.value === formProvince
  )
    ? formProvince
    : ''
  const getAppointmentActivityUrlParam = (urlParam: string) => {
    if (selectedProvince !== '') {
      return urlParam
    }
    return ''
  }

  const skipGetAppointmentActivities = !groupId || !selectedProvince

  const {
    appointmentActivities,
    loading: getApptActivitiesLoading,
    error: getApptActivitiesError,
  } = useGetGroupAppointmentActivities(
    {
      regionCode: selectedProvince,
      countryCode: 'CA',
      groupId,
      locationId: pharmacy.id,
    },
    skipGetAppointmentActivities
  )

  // In pharmacy context we should fetch regional appointment activities when global flag is true
  const skipGetRegionalAppointmentActivities =
    !groupId || !pharmacy.id || !global

  const {
    appointmentActivities: regionalAppointmentActivities,
    loading: getRegionalApptActivitiesLoading,
    error: getRegionalApptActivitiesError,
  } = useGetGroupAppointmentActivities(
    {
      regionCode: pharmacy.province,
      countryCode: 'CA',
      groupId,
    },
    skipGetRegionalAppointmentActivities
  )

  const onSubmit = async (data: ActivityGroupLandingFormState) => {
    const { error } = await selectAppointmentActivityAsync({
      id: data.appointmentId,
      regionCode: data.province,
      method: data.method,
    })
    if (error) {
      setError(error)
    }
    nextStep()
  }

  const anyErrors =
    error || getApptActivitiesError || getRegionalApptActivitiesError

  if (anyErrors) {
    throw new Error(anyErrors)
  }

  if (
    getApptActivitiesLoading ||
    getRegionalApptActivitiesLoading ||
    getAvailableProvincesLoading
  ) {
    return <Loading title={t('loading.title.loading')} />
  }

  let combinedAppointmentActivities = appointmentActivities || []

  if (regionalAppointmentActivities.length > 0) {
    combinedAppointmentActivities = uniqBy(
      [...appointmentActivities, ...regionalAppointmentActivities],
      'urlParam'
    )
  }

  return (
    <FormProvider {...methods}>
      <ActivityGroupCards
        selectedProvince={selectedProvince}
        appointmentActivityUrlParam={getAppointmentActivityUrlParam(
          appointmentType
        )}
        appointmentActivityGroup={appointmentActivityGroup}
        appointmentActivities={combinedAppointmentActivities}
        hideProvinceSelector={!!pharmacy.id}
        provinces={filteredProvinces}
        onSubmit={onSubmit}
      />
    </FormProvider>
  )
}
