import { JsonValue } from './jsonValue'

export const isJsonBoolean = (value: JsonValue): value is boolean => {
  if (typeof value === 'boolean') return true
  return false
}

export const createJsonBoolean = (value: JsonValue) => {
  if (typeof value === 'boolean') return value
  if (typeof value === 'number') return value !== 0
  if (typeof value === 'string') {
    if (value === 'true') return true
    if (value === 'false') return false
  }
  return false
}
