import { Extension } from 'fhir/r5'

export const isValidExtensionList = (
  extension: Extension,
  extensionValidator: (extension: Extension) => boolean
) => {
  const nestedExtensions = extension.extension ?? []
  return (
    nestedExtensions.length > 0 && nestedExtensions.every(extensionValidator)
  )
}
