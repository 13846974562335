import { useContext } from 'react'
import { CoBookingContext } from '../CoBookingContext'

export const useCoBooking = () => {
  const context = useContext(CoBookingContext)
  if (context === undefined) {
    throw new Error('useCoBooking must be used within a CoBookingProvider')
  }

  return context
}
