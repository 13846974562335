import { QuestionnaireItem } from 'fhir/r4'
import { createFhirDisplayItem } from './fhirDisplayItem'
import { createFhirGroupItem } from './fhirGroupItem'
import { createFhirInputItem } from './fhirInputItem'

const typeSpecificItemProps = (json: QuestionnaireItem) => {
  switch (json.type) {
    case 'display':
      return createFhirDisplayItem(json)
    case 'group':
      return createFhirGroupItem(json)
    default:
      return createFhirInputItem(json)
  }
}

export const createFhirQuestionnaireItem = (
  json: QuestionnaireItem
): QuestionnaireItem => {
  return {
    text: json.text ?? '',
    linkId: json.linkId ?? '',
    extension: json.extension,
    code: json.code,
    enableWhen: json.enableWhen,
    enableBehavior: json.enableBehavior,
    _enableBehavior: json._enableBehavior,
    repeats: false,
    _type: json._type,
    maxLength: json.maxLength,
    ...typeSpecificItemProps(json),
  }
}

export type FhirQuestionnaireItem = ReturnType<
  typeof createFhirQuestionnaireItem
>
