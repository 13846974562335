import { Extension } from 'fhir/r5'
import { ExtensionValidationConditionGroupList } from '../validation.type'
import { isValidExtensionList } from '../../lib/isValidExtensionList'
import { isExtensionValidationConditionGroup } from './isExtensionValidationConditionGroup'

export const isExtensionValidationConditionGroupList = (
  extension: Extension
): extension is ExtensionValidationConditionGroupList => {
  return (
    extension.url === 'conditionGroupList' &&
    isValidExtensionList(extension, isExtensionValidationConditionGroup) &&
    Object.keys(extension).length === 2
  )
}
