import { useEffect } from 'react'
import { EnterpriseEnums } from '~/shared/constants/enterpriseConstants'
import { getEnterpriseCode, isLCLRedirect } from '~/util/enterpriseMethods'

type EnterpriseData = {
  [key: string]: {
    faviconHref: string
    documentTitle: string
  }
}
const enterpriseData: EnterpriseData = {
  SDM: {
    faviconHref:
      'https://www1.shoppersdrugmart.ca/static/core/images/favicon.ico',
    documentTitle: 'Shoppers Drug Mart',
  },
  LCL: {
    faviconHref:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABRFBMVEX////FxsiUlJScnqH+/v4NDQ3Gxsb9/f0AAADW19jGx8mQkJDn5+j8/PzV1teanJ+bnaDt7e7W1thERET7+/vX2NmfoaTk5OWdn6L39/fV1tjm5+j09PTExcfj4+TY2dqPj4/Z2tuho6aeoKPR0tPj5OTg4eLU1Nbi4+O4urzExsfLzM7Ky83V1dfU1df19va5u73f4OGytLbCw8WztbesrrDw8PH5+fm9vsCvsLP6+vuZm57P0NH19fXHyMru7u9GRka4uLjDxMbT1NWmqKry8vO+v8Hz8/S3uLvBwsS5ur3S09S7vb/4+fmfoaP4+Pm1t7nb293z8/Pc3d6wsrSwsbSvsbTMzc7Q0ND8/P0oKCiVlZWtrrGio6ZTU1Pn6OnLzM34+PixsbGcnqKtr7Hy8vKusLLU1dagoqXm5uZdXV3Z2dn90rA5AAABAUlEQVQ4y2NgGAVQwM7OgmD7+vOiy2dnxTlCmVaBPqFhXs76DCxy8uJwXWysHEwQlrmsHz8zMz+zhyuLnqwUO6YCFQmJAMVgTzEBJxE+Rk5MBTKmAiFBDAwibgIugpzYFMiJMXuDaHtRRXchbApEmcVUYZ7BakU6s1I8XgWiyVATWFiwKxDPYFYB0YYWxmZojuQWkZSUTExNE0gRBHrGyNrSVohRiJedHRLGQAVRDsJAkCSvzBwTbmMioSzKwselLcTJySmlxg5WAAFMDAmxAvz8/Eo6Iuy6XGDAqA6MmcxoBW4QUIhgiBS3M9DU0uBlYBGU5gEBaUEWjKjlHU3e5AEAev4dtc4wlkwAAAAASUVORK5CYII=',
    documentTitle: 'Loblaw Pharmacy',
  },
  PHX: {
    faviconHref:
      'https://www1.shoppersdrugmart.ca/static/core/images/favicon.ico',
    documentTitle: 'Pharmaprix',
  },
  MINT: {
    faviconHref:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAACXBIWXMAAC4jAAAuIwF4pT92AAACs0lEQVR4nO2bwZHaMBRAnzK5sx0sHYQOlg6yHUAJHH3wTDj4oCMdxKlgKcHpYKkgSwUJFTgHCebb2CAbsDH734wPy8iS/FaS/W19k+c5iuNL3x24J1SG4Os1KzM2GQNj/+cEeLpS1WkexR9XqquW1jKMTabAFHfRE+D5Ol2qJAM+Qgoam4zbiguWYWzyBLz643ubxjoiNTaZAGvciMpCTzwrw0tY+GPUtocdMwJmwMzYZAss8yhOz510cgE1NnnFDc8fDEdEmWfgp7FJ5kdMLbUyjE1S4I3hSijzAmT+H1xJpQwvYnajTvXJCHirE3Ikw9hkwWOKkKT+MaBAQYZfLJcddahPRlRcZ3lkzHmcNeIcs/LoKMuoXVx65t+Nyhaut/yc8dKgokvZENbxNI/i9wb1zoGUsFCgUMbsQ3g/ZP40aPQSfuVRPO+orWDkNBl32G7aYVvBaAgvUBkClSFQGQKVIVAZApUhUBmCvmSsfITcK+VA7aqfChrwDfhrbBJSttGju3+1lxEWff/GveEHhjFNZv6zRCgrWr6GGIKMzlAZApUhUBkClSFQGQKVIVAZApUhGIqMoFjG2GTOBZ87+opNmtIklmnNUEZGJ6gMgcoQqAyByhCoDIHKEKgMgcoQqAyByhCoDMFBRpPd949KeWTseulFfxR2EZZlZN314y44KSPtrh+9s8Ml6BwoyMijeI3brPoZWOVRXNiUW3U3mfP4a8cmj+Jl+ccjGX5r8qKLHvXEBrENQWLqMp79NoA1j5VlsAGm5emxp1YGHPJPVgw/GWeHWyOWpwqdlHEo5HbDDDFDaYu7Qx4tllUEyTgULua2TrnPKbTFTe/M3x2DaSTj6GQ3Yia4jIR9uveEbiRtcWmm+yMD3kNGQB0XyThbeTE3/mJuHT/dVMbQ0BBeoDIE/wH5/bDRcuqfNgAAAABJRU5ErkJggg==',
    documentTitle: 'Health Box',
  },
  JCG: {
    faviconHref:
      'https://www.jeancoutu.com/cdn/src/img/favicon/pjc/v-637825905530000000/favicon.ico',
    documentTitle: 'Consultation - Jean Coutu',
  },
  BRUNET: {
    faviconHref:
      'https://www.brunet.ca/cdn/src/img/favicon/brn/v-637825905230000000/favicon.ico',
    documentTitle: 'Consultation - Brunet',
  },
  MEDME: {
    faviconHref: '/assets/medme_favicon.svg',
    documentTitle: 'MedMe',
  },
  LDRUGS: {
    faviconHref:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAABjUExURQAAAABdqgBdqgBdqgBdqgBdqgBdqgBdqgBdqgBdqgBdqgBdqgBbqRBmry15uTuCvkaJwh5vtFeUx+30+Xep0sXa7NLj8PX4/JW83LrT6ISx1uTu9myizmKby////6XG4dzp9GWESbUAAAALdFJOUwA5itHyJZHmlUWa4cznbgAAAAFiS0dEHnIKICsAAAAHdElNRQfoCRIPNSUjqp/mAAAB60lEQVRIx+WW6XLjIAyAnbNpuRGXQRzv/5SL7bTJ1pkm3h87s7OasYxlfRiEDBqGWXb7w5H8IMfDfjfc5HR+I0/l7XL69H8/PHef5PB+9X+h++tHZuL0Yv/zN6ZRnV/3J+TS4/PygOZB7Yb9Fn9CzsOGGcyzGI7bgOOwzZ+Q/xqgi6a0X/N9al4ti+l3gHHWbUxIRVVvKq4454x2u+RdqcXhBlANhlJuAZw0PrDRRkjJChYAkhHJKQRN74FcDCW1oAYbCmTrQonoRyxVWEAowZQ1wFxSxKZakksdyMy12t1iCZDAroDI2Oilaq5600oHUICN3Vx9hFrLCkhuNJCcN7FInICUICsHzlfpK2/fAI5oMhMxSiJQsawFYhaEKgyZKZREIr8HpnDLGE1/EWPMCg1TURNdqyYMa5BCfi7EbaVDcc1j167UPn1ZKnrnPGIZndN8nRqhaDEF1WS/AKMFzmOOZYya0UeAVMnVAuBxAVpToVUemk/yIdADVLtGX63XPfyjR+OdqTlMy7oGPMDII2jVak7gHRfOpzbqBsnyB8CSblPqcU64lKqbpFC866m9Bugtk+nXA/1q//0/7p8GNm/Gm7f7zQfK5iNruGwBPv7k2N18sG8vHfqoLq8UJx+nu3pmd35W/pyv5c8vkGZx5983YEwAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjQtMDktMThUMTU6NTM6MzYrMDA6MDCN1EveAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDI0LTA5LTE4VDE1OjUzOjM2KzAwOjAw/InzYgAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyNC0wOS0xOFQxNTo1MzozNyswMDowMA3r2QkAAAAASUVORK5CYII=',
    documentTitle: 'London Drugs',
  },
}

const getPageHeader = () => {
  if (isLCLRedirect()) {
    return enterpriseData[EnterpriseEnums.SDM]
  }

  return enterpriseData[getEnterpriseCode()]
}

const usePageHeader = () => {
  useEffect(() => {
    const pageHeader = getPageHeader()
    document.title = pageHeader?.documentTitle || document.title

    const faviconElement = document.getElementById(
      'favicon'
    ) as HTMLAnchorElement
    if (faviconElement) {
      faviconElement.href = pageHeader?.faviconHref || faviconElement.href
    }
  }, [])
}

export default usePageHeader
