import env from './env.medme.io.json'

export default {
  'dev-on.medme.io': env.DEV_ON_TENANT_ID,
  'dev-ab.medme.io': env.DEV_AB_TENANT_ID,
  'dev-mb.medme.io': env.DEV_MB_TENANT_ID,
  'dev-sk.medme.io': env.DEV_SK_TENANT_ID,
  'dev-nb.medme.io': env.DEV_NB_TENANT_ID,
  'dev-pe.medme.io': env.DEV_PE_TENANT_ID,
  'dev-nl.medme.io': env.DEV_NL_TENANT_ID,
  'dev-ns.medme.io': env.DEV_NS_TENANT_ID,
  'dev-bc.medme.io': env.DEV_BC_TENANT_ID,
  'heartpharmacycadborobay-dev.medme.io': env.HEARTPHARMACYCADBOROBAY_TENANT_ID,
  'heartpharmacycampus-dev.medme.io': env.HEARTPHARMACYCAMPUS_TENANT_ID,
  'heartpharmacyfairfield-dev.medme.io': env.HEARTPHARMACYFAIRFIELD_TENANT_ID,
  'heartpharmacyshelbourne-dev.medme.io': env.HEARTPHARMACYSHELBOURNE_TENANT_ID,
  'heartpharmacystanthonys-dev.medme.io': env.HEARTPHARMACYSTANTHONYS_TENANT_ID,
  'heartpharmacysidney-dev.medme.io': env.HEARTPHARMACYSIDNEY_TENANT_ID,

  // ENTERPRISE_SETUP
  'heartpharmacy-dev.medme.io': env.HEARTPHARMACY_TENANT_ID,
  'wconnect.medme.io': env.WCONNECT_TENANT_ID,
  'lifesmartpharmacy.medme.io': env.LIFESMART_TENANT_ID,
  'jcg.medme.io': env.JCG_MEDME_IO_TENANT_ID,
  'brunet.medme.io': env.JCG_MEDME_IO_TENANT_ID,
}
