import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../../types'
import { LanguageCodesFE } from '~/types/languageEnums'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Dépistage des urgences médicales',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'Si vous êtes en situation d’urgence médicale, composez le 9-1-1 ou rendez-vous immédiatement aux urgences de l’hôpital le plus proche.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })
  const eligibility = createFhirQuestionnaireItem({
    text: 'L’admissibilité à l’évaluation et à la prescription de médicaments pour des problèmes de santé courants sera évaluée par le pharmacien au moment de votre rendez-vous.',
    linkId: 'eligibility',
    type: 'display',
  })
  const healthcardPaymentDisclaimerHeader = createFhirQuestionnaireItem({
    text: 'AVERTISSEMENT:',
    linkId: 'healthcardDisclaimerHeader',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const notReimbursed = createFhirQuestionnaireItem({
    text: 'L’évaluation de cette maladie n’est pas remboursée par l’assurance-maladie du Nouveau-Brunswick. Des honoraires professionnels s’appliquent.',
    linkId: 'notReimbursed',
    type: 'display',
  })
  const medicationPaymentDisclaimer = createFhirQuestionnaireItem({
    text: 'Si une ordonnance est rédigée pour ce service, le coût du médicament n’est pas inclus dans l’évaluation et sera à mes frais ou couvert par mon régime d’assurance-médicaments.',
    linkId: 'medicationPaymentDisclaimer',
    type: 'display',
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      eligibility,
      healthcardPaymentDisclaimerHeader,
      notReimbursed,
      medicationPaymentDisclaimer,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirNotReimbursedMAMedicalScreeningSdmNBJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['NB'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: [
        'Allergies',
        'OralThrush',
        'PeriodCramps',
        'Hemorrhoids',
        'InsectBites',
        'MuscleAches',
        'EmergencyContraception',
        'CankerSores',
        'AthletesFoot',
        'JockItch',
        'Headache',
        'Threadworms',
        'YeastInfection',
        'Pinworms',
        'SleepDisorder',
        'Ringworms',
      ],
    },
  },
  metadata: {
    id: 'fhirNotReimbursedMAMedicalScreeningSdmNBJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
    languageCode: LanguageCodesFE.FR,
  },
}
