import * as React from 'react'
import { AaIconContainer, AaIconLayout } from './AaiIcon.style'

export const isSvg = (iconUrl: string) => {
  return iconUrl.split('.').pop() === 'svg'
}

export const AaiIcon: React.FC<{
  src: string
  hideWhenSm?: boolean
}> = ({ src, hideWhenSm = false }) => {
  if (!src) return null
  return (
    <AaIconLayout hideWhenSm={hideWhenSm}>
      <AaIconContainer isSvg={isSvg(src)}>
        <img width="80%" src={src} />
      </AaIconContainer>
    </AaIconLayout>
  )
}
