import * as React from 'react'

export interface FhirFormContextProps {
  disabledItems: Record<string, true | undefined>
  hiddenItems: Set<string> | undefined
}

export const FhirFormContext = React.createContext<FhirFormContextProps>({
  disabledItems: {},
  hiddenItems: undefined,
})
