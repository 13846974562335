import * as React from 'react'
import { FhirFormContextProps, FhirFormContext } from '../FhirFormContext'

export const FhirFormProvider: React.FC<FhirFormContextProps> = ({
  children,
  disabledItems,
  hiddenItems,
}) => {
  return (
    <FhirFormContext.Provider value={{ disabledItems, hiddenItems }}>
      {children}
    </FhirFormContext.Provider>
  )
}
