import React from 'react'
import { useHistory } from 'react-router-dom'

const DEFAULT_RECOVERY_ROUTE = '/schedule'
const RECOVERY_PATH_KEY = 'recoveryPath'

export interface UseRecoveryRouteProps {
  recoveryRoute?: string
  condition?: boolean
}

export function useRecoveryRoute({
  recoveryRoute,
  condition,
}: UseRecoveryRouteProps = {}) {
  const history = useHistory()

  const setRecoveryRoute = React.useCallback((path: string) => {
    window.sessionStorage.setItem(RECOVERY_PATH_KEY, path)
  }, [])

  const getRecoveryRoute = React.useCallback(() => {
    return (
      window.sessionStorage.getItem(RECOVERY_PATH_KEY) || DEFAULT_RECOVERY_ROUTE
    )
  }, [])

  const redirectToRecoveryRoute = React.useCallback(() => {
    history.push(getRecoveryRoute())
  }, [getRecoveryRoute, history])

  React.useEffect(() => {
    if (recoveryRoute) {
      setRecoveryRoute(recoveryRoute)
    }
  }, [recoveryRoute, setRecoveryRoute])

  React.useEffect(() => {
    if (condition) {
      redirectToRecoveryRoute()
    }
  }, [redirectToRecoveryRoute, condition])

  return { setRecoveryRoute, getRecoveryRoute, redirectToRecoveryRoute }
}
