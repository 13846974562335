import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../types'
import { LanguageCodesFE } from '~/types/languageEnums'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Emergency Screening',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'If you are experiencing a medical emergency, call 9-1-1 or go to your nearest hospital emergency room.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })
  const utiEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 16 years old
- Male sex assigned at birth
- Pregnant
- Breastfeeding an infant under 1 month old
\n
If you fall in the category of individuals for whom Pharmacists cannot prescribe, please book an appointment at your nearest walk-in clinic or primary care provider for further assessment.
    `,
    linkId: 'utiEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'UrinaryTractInfection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const eligibility = createFhirQuestionnaireItem({
    text: 'Eligibility for assessment and prescribing for minor ailments will be assessed by the pharmacist at the time of your appointment.',
    linkId: 'eligibility',
    type: 'display',
  })
  const healthcardPaymentDisclaimerHeader = createFhirQuestionnaireItem({
    text: 'DISCLAIMER:',
    linkId: 'healthcardDisclaimerHeader',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const reimbursed = createFhirQuestionnaireItem({
    text: "If you don't have an active Manitoba health card, professional fees apply for the assessment.",
    linkId: 'reimbursed',
    type: 'display',
  })
  const costOfDrugDisclaimer = createFhirQuestionnaireItem({
    text: 'In the event a prescription is written for this service, the cost of the medication is not included in the assessment. The cost will be covered by you or your private drug insurance',
    linkId: 'costOfDrugDisclaimer',
    type: 'display',
  })
  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      utiEligibility,
      eligibility,
      healthcardPaymentDisclaimerHeader,
      reimbursed,
      costOfDrugDisclaimer,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirMAMedicalScreeningSdmMBJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['MB'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: ['UrinaryTractInfection'],
    },
  },
  metadata: {
    id: 'fhirMAMedicalScreeningSdmNSJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
    languageCode: LanguageCodesFE.EN,
  },
}
