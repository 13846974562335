import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: ${({ flex }) => flex || '0 0'};
  width: ${({ width }) => width || 'auto'};
`

export const TripleRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-shrink: 0;
  flex-flow: row nowrap;
`
