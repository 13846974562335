import * as React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import moment from 'moment'

import { KeyboardDatePicker, DatePickerProps, Day } from '@material-ui/pickers'

import { DayContainer } from './DateField.style'

export const DateField: React.FC<{
  name: string
  label?: string
  defaultValue?: string | null
  helperText?: string
  placeholder?: string
  disablePast?: DatePickerProps['disablePast']
  disableFuture?: DatePickerProps['disableFuture']
  format?: string
  views?: DatePickerProps['views']
  openTo?: DatePickerProps['openTo']
  autoOk?: boolean
  disabled?: boolean
  required?: boolean
}> = ({
  name,
  label,
  defaultValue = null,
  disablePast = false,
  disableFuture = false,
  format = 'yyyy-MM-DD',
  helperText = '',
  placeholder = '',
  views = ['year', 'month', 'date'],
  openTo = 'year',
  autoOk = true,
  disabled = false,
  required = false,
}) => {
  const { errors, setValue } = useFormContext()
  const { field } = useController({
    name: name,
    defaultValue: defaultValue,
  })
  const error = disabled === true ? '' : errors[name]?.message

  const renderDay: DatePickerProps['renderDay'] = React.useCallback(
    (day, selectedDay, dayInCurrentMonth, dayComponent) => {
      return (
        <Day {...dayComponent.props}>
          <DayContainer>{day?.format('D') ?? ''}</DayContainer>
        </Day>
      )
    },
    []
  )

  const getDatePickerValue = React.useCallback(() => {
    return field.value ? moment(field.value, format) : null
  }, [field.value])

  return (
    <KeyboardDatePicker
      fullWidth={true}
      name={field.name}
      label={label}
      disableToolbar={true}
      value={getDatePickerValue()}
      onChange={(day) => {
        if (day) setValue(name, day.format(format))
      }}
      onBlur={field.onBlur}
      variant="inline"
      inputVariant="outlined"
      PopoverProps={{
        style: {
          zIndex: 10000,
        },
      }}
      renderDay={renderDay}
      style={{
        fontFamily: 'inherit',
        backgroundColor: 'white',
      }}
      format={format}
      views={views}
      placeholder={placeholder}
      openTo={openTo}
      disablePast={disablePast}
      disableFuture={disableFuture}
      autoOk={autoOk}
      error={Boolean(error)}
      helperText={error || helperText}
      disabled={disabled}
      inputRef={field.ref}
      required={!disabled && required}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  )
}
