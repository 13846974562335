import { Pharmacy } from '~/graphql/types/schemaNode.type'
import { allProvinces } from './minorAilments/provincesUtil'
import { createPharmacy } from '~/lib/pharmacy'

export enum ECountry {
  CANADA = 'Canada',
  USA = 'United States',
}

export const DEFAULT_COUNTRY = ECountry.CANADA

export const ADDRESS_MAPPING = {
  street_number: 'streetNumber',
  route: 'streetName',
  locality: 'city',
  administrative_area_level_1: 'province',
  country: 'country',
  postal_code: 'postalCode',
  postal_code_prefix: 'postalCode',
} as const

export const getProvinceAbbreviation = (
  province: string,
  country: ECountry
) => {
  if (country === ECountry.CANADA) {
    const provinceObj = allProvinces.find(
      (prov) => prov.label.toLowerCase() === province.toLowerCase()
    )

    return provinceObj?.value || province
  }

  return province
}

export const getTransformedAddressComponent = (
  componentKey: (typeof ADDRESS_MAPPING)[keyof typeof ADDRESS_MAPPING],
  value: string,
  country = DEFAULT_COUNTRY
) => {
  if (componentKey !== 'province') return value

  return getProvinceAbbreviation(value, country)
}

export const getFormattedBuildingAndCityAddressFromPharmacyAddress = (
  pharmacyAddress:
    | Pharmacy['pharmacyAddress']
    | ReturnType<typeof createPharmacy>['pharmacyAddress']
) => {
  const { streetName, streetNumber, poBox, city, province, postalCode, unit } =
    pharmacyAddress || {}

  return {
    buildingAddress: `${streetNumber ?? ''} ${streetName ?? ''} ${
      poBox ? `, P.O Box ${poBox}` : ''
    }`,
    cityAddress: `${city}, ${province} ${postalCode} ${
      unit ? `(${unit})` : ''
    }`,
  }
}
