import React from 'react'
import { ErrorLayout } from './ErrorLayout'
import { ErrorComponent } from './ErrorComponent'
import { ErrorFallbackProps } from '../ErrorBoundary'

export function DefaultFallback({
  children,
}: React.PropsWithChildren<Omit<ErrorFallbackProps, 'resetErrorBoundary'>>) {
  return (
    <ErrorLayout>
      <ErrorComponent>{children}</ErrorComponent>
    </ErrorLayout>
  )
}
