import { JsonFormStep } from './types'

export const consent: JsonFormStep = {
  type: 'preset',
  preset: 'consent',
  metadata: {
    id: 'consent',
    name: 'bookingDetails.consent',
    path: 'consent',
    showOnSteps: true,
  },
}
