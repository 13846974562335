import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// hooks
import useMediaHook from '~/hooks/mediaHook'

// redux
import { updateSidebarOption } from '~/store/pharmacistLayout'

// components
import SidebarOption from './sidebarOption'

// styles
import {
  ExpandableOptionsContainer,
  OptionContainer,
  IconContainer,
  OptionText,
} from '../pharmacistLayout.styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ExpandableSidebarOption = ({
  selected,
  name,
  subOptions,
  icon,
  title,
}) => {
  const dispatch = useDispatch()
  const showMore = useSelector((state) => state.pharmacistLayout)
  const deviceType = useMediaHook(
    // Width set to 1280px to handle the need of minimized sidebar on larger tablets
    ['(max-width: 1280px)'],
    ['tablet'],
    'desktop'
  )

  return (
    <ExpandableOptionsContainer>
      <OptionContainer
        selected={selected === name}
        to=""
        as={'div'}
        onClick={() => dispatch(updateSidebarOption(!showMore[name], name))}
        key={name}
      >
        <IconContainer>{icon}</IconContainer>
        {(showMore.pharmacistLayout || deviceType === 'desktop') && (
          <OptionText>{title}</OptionText>
        )}
        {(showMore.pharmacistLayout || deviceType === 'desktop') && (
          <ExpandMoreIcon
            style={{ transform: showMore[name] ? 'rotate(180deg)' : 'none' }}
          />
        )}
      </OptionContainer>
      {showMore[name] &&
        subOptions.map((props, i) => {
          const { isHidden, ...rest } = props || {}
          if (isHidden) return null
          return (
            <SidebarOption
              selected={selected}
              key={`${props.name}${i}`}
              {...rest}
            />
          )
        })}
    </ExpandableOptionsContainer>
  )
}

export default ExpandableSidebarOption
