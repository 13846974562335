import * as React from 'react'
import { createAppointmentActivityGroup } from '~/lib/appointmentActivityGroup'

export const createAppointmentActivityGroupContext = () => ({
  initialAppointmentActivityGroup: createAppointmentActivityGroup(),
  appointmentActivityGroup: createAppointmentActivityGroup(),
  dispatch: (() => undefined) as React.Dispatch<any>,
})

export type AppointmentActivityGroupContextInterface = ReturnType<
  typeof createAppointmentActivityGroupContext
>

export const AppointmentActivityGroupContext = React.createContext(
  createAppointmentActivityGroupContext()
)
