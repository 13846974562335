import { RuleSerializable, ConditionProperties } from 'json-rules-engine'
import { FhirQuestionnaireItem } from '~/pages/AppointmentIntake/lib/fhir'
import { createEnableWhenEvent } from '../events'

export const createEnableWhenRule = (
  item: FhirQuestionnaireItem
): RuleSerializable => {
  const { enableWhen, enableBehavior, linkId } = item

  if (!enableWhen || enableWhen.length === 0)
    throw new Error('createEnableWhenRule: enableWhen is undefined')

  return {
    name: `${linkId}EnableWhenEvent`,
    conditions: (() => {
      const conditionProperties = enableWhen.map<ConditionProperties>(
        ({ operator, question, id, _id, ...values }) => {
          const value = Object.keys(values).reduce<any>((acc, key) => {
            if (key.includes('answer')) {
              const value = values[key as keyof typeof values]

              if (value !== undefined) {
                return value
              }
            }
            return acc
          }, undefined)

          const [fact, ...splitPath] = question.split('.')
          const path = splitPath.join('.')

          return {
            fact: fact,
            path: path || undefined,
            operator: operator,
            value: value,
          }
        }
      )
      if (enableBehavior === 'any') {
        return {
          any: conditionProperties,
        }
      } else {
        return {
          all: conditionProperties,
        }
      }
    })(),
    event: createEnableWhenEvent({
      fieldName: linkId,
    }),
    priority: 100,
  }
}
