import { defaultTheme } from '~/sharedStyles/colorPalette'

export const createTheme = (data?: any) => ({
  color: {
    backgroundPrimary: String(
      data?.color?.backgroundPrimary ?? defaultTheme.color.backgroundPrimary
    ),
    backgroundSecondary: String(
      data?.color?.backgroundSecondary ?? defaultTheme.color.backgroundSecondary
    ),
    titlePrimary: String(
      data?.color?.titlePrimary ?? defaultTheme.color.titlePrimary
    ),
    titleSecondary: String(
      data?.color?.titleSecondary ?? defaultTheme.color.titleSecondary
    ),
    titleTertiary: String(
      data?.color?.titleTertiary ?? defaultTheme.color.titleTertiary
    ),
    textPrimary: String(
      data?.color?.textPrimary ?? defaultTheme.color.textPrimary
    ),
    textSecondary: String(
      data?.color?.textSecondary ?? defaultTheme.color.textSecondary
    ),
    textTertiary: String(
      data?.color?.textTertiary ?? defaultTheme.color.textTertiary
    ),
    buttonPrimary: String(
      data?.color?.buttonPrimary ?? defaultTheme.color.buttonPrimary
    ),
    buttonSecondary: String(
      data?.color?.buttonSecondary ?? defaultTheme.color.buttonSecondary
    ),
    buttonTertiary: String(
      data?.color?.buttonTertiary ?? defaultTheme.color.buttonTertiary
    ),
  },
  font: {
    main: String(data?.font?.main ?? defaultTheme.font.main),
    heading: String(data?.font?.heading ?? defaultTheme.font.heading),
    generic: String(data?.font?.generic ?? defaultTheme.font.generic),
  },
})
