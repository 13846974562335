import * as React from 'react'
import { appointmentPropFns } from '~/lib/appointment'
import { JsonPrimitive } from '~/lib/json'
import { patientPropFns } from '~/lib/patient'
import { useAppointmentContext, usePatientContext } from '../../..'
import { BookingContextInterface } from '../../BookingContext'
import { updateEntity } from './updateEntity'
/**
 * Returns functions that update BookingContext state
 * @param context
 * @returns Functions that update BookingContext state
 *
 * @see {@link updateFields}
 */
export const useUpdateFields = (context: BookingContextInterface) => {
  const { updatePatient } = usePatientContext()
  const { updateAppointment } = useAppointmentContext()

  const invalidKeys = Object.keys(context)

  /**
   * Update patient and appointment states with new data.
   *
   * @param data
   */
  const updateFields = React.useCallback(
    (
      data: Record<string, JsonPrimitive | undefined>,
      fieldToStateMap: Record<
        string,
        {
          entity: 'patient' | 'appointment'
          path: string
        }[]
      >
    ): void => {
      const updatedFields = Object.keys(data).reduce<
        Record<string, JsonPrimitive>
      >((acc, key) => {
        const value = data[key]
        if (invalidKeys.includes(key)) {
          return acc
        }
        if (value === undefined) {
          return acc
        }
        return {
          ...acc,
          [key]: value,
        }
      }, {})

      updatePatient(
        updateEntity(
          data,
          fieldToStateMap,
          context.patient,
          'patient',
          patientPropFns
        )
      )

      updateAppointment(
        updateEntity(
          data,
          fieldToStateMap,
          context.appointment,
          'appointment',
          appointmentPropFns
        )
      )

      context.setFields({
        ...context.fields,
        ...updatedFields,
      })

      return
    },
    [context]
  )

  return {
    updateFields,
  }
}
