import * as React from 'react'
import Loading from '~/components/loading'

import {
  AppointmentContext,
  AppointmentContextInterface,
  createAppointmentContext,
} from '../AppointmentContext'
import { deepPropUpdate } from '~/pages/AppointmentIntake/lib/deepPropUpdate'
import { useCurrentAppointment } from '../useCurrentAppointment'

const reducer = (
  state: AppointmentContextInterface,
  action: any
): AppointmentContextInterface => {
  switch (action.type) {
    case 'initializeAppointment':
      return {
        ...state,
        initialAppointment: action.appointment,
        appointment: action.appointment,
      }
    case 'updateAppointment':
      return {
        ...state,
        appointment: deepPropUpdate(state.appointment, action.appointment),
      }
    case 'setAppointment':
      return {
        ...state,
        appointment: action.appointment,
      }
    default:
      console.log('unknown action type', action.type, action)
      return state
  }
}

export const AppointmentProvider: React.FC = ({ children }) => {
  const [context, dispatch] = React.useReducer(
    reducer,
    createAppointmentContext()
  )
  const [initialized, setInitialized] = React.useState(false)
  const { appointment, loading } = useCurrentAppointment()

  React.useEffect(() => {
    if (!loading && !initialized) {
      dispatch({
        type: 'initializeAppointment',
        appointment: appointment,
      })
      setInitialized(true)
    }
  }, [loading])

  if (!initialized) return <Loading title="Loading Appointment" />

  return (
    <AppointmentContext.Provider value={{ ...context, dispatch }}>
      {children}
    </AppointmentContext.Provider>
  )
}
