import { Event } from 'json-rules-engine'
import { ValidationError } from './validationError.type'

export const createValidationError = (
  params: Event['params']
): ValidationError => {
  return {
    type: 'FormFieldValidationError',
    params: {
      fieldName: params?.fieldName ?? '',
      type: params?.type ?? '',
      message: params?.message ?? '',
    },
  }
}
