import { Extension } from 'fhir/r4'
import { fhirExtGridUrl, createFhirExtGridProps } from './fhirExtGrid'
import {
  fhirExtInputVariantUrl,
  createFhirExtInputVariantProps,
} from './fhirExtInputVariant'

type FhirExtPropsRecord<T extends (...args: any) => any> = Partial<
  ReturnType<T>
>

type FhirExtProps = FhirExtPropsRecord<typeof createFhirExtGridProps> &
  FhirExtPropsRecord<typeof createFhirExtInputVariantProps>

export const createFhirExtProps = (extensions?: Extension[]): FhirExtProps => {
  return (
    extensions?.reduce((acc, ext) => {
      switch (ext.url) {
        case fhirExtGridUrl:
          return { ...acc, ...createFhirExtGridProps(ext) }
        case fhirExtInputVariantUrl:
          return { ...acc, ...createFhirExtInputVariantProps(ext) }
        default:
          //console.error(`createFhirExtProps: ext.url not found: ${ext.url}`)
          return acc
      }
    }, {}) ?? {}
  )
}
