import * as React from 'react'
import {
  NavigationButton,
  NavigationLeftButtonText,
} from './CalendarNavbar.style'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { useResponsive } from '../../hooks/useResponsive'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const PrevNavButton: React.FC<{
  onClick: () => void
  isDisabled: boolean
  numDays: number
}> = ({ onClick, isDisabled, numDays }) => {
  const { deviceType } = useResponsive()
  const { t } = useMedMeTranslation('patient')

  const buttonText = t('schedulingSelection.navigateLastDays', {
    numberOfDaysToShow: numDays,
  })
  if (deviceType === 'desktop')
    return (
      <NavigationButton onClick={onClick} disabled={isDisabled}>
        <KeyboardArrowLeftIcon />
        <NavigationLeftButtonText>{buttonText}</NavigationLeftButtonText>
      </NavigationButton>
    )

  if (!isDisabled)
    return (
      <ArrowBackIosIcon style={{ marginRight: '-0.25rem' }} onClick={onClick} />
    )

  return null
}
