export type JsonPrimitive = string | number | boolean | null

export const isJsonPrimitive = (value: any): value is JsonPrimitive => {
  if (typeof value === 'number') return true
  if (typeof value === 'boolean') return true
  if (typeof value === 'string') return true
  if (value === null) return true
  return false
}

export const createJsonPrimitive = (value: any): JsonPrimitive => {
  if (typeof value === 'number') return value
  if (typeof value === 'boolean') return value
  if (typeof value === 'string') return value
  if (value === null) return value
  throw new Error(`${value} is not a JsonPrimitive`)
}
