import * as React from 'react'
import { useGetEnterprisePharmacyWhiteLabelQuery } from '~/graphql/types/schema.type'
import { useRouterContext } from '../../RouterContext'
import { createWhiteLabel } from '../createWhiteLabel'

/**
 * Since enterprises don't have a store selected for the /schedule route, we need to populate white label using this hook
 * @returns
 */
export const useFallbackEnterpriseWhiteLabel = () => {
  const { enterpriseCode, storeNo } = useRouterContext()
  const [whiteLabel, setWhiteLabel] = React.useState(createWhiteLabel())
  const [initialized, setInitialized] = React.useState(false)
  const [error, setError] = React.useState('')

  const result = useGetEnterprisePharmacyWhiteLabelQuery({
    variables: {
      enterpriseName: enterpriseCode,
    },
    skip: !enterpriseCode || Boolean(storeNo) || initialized,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  React.useEffect(() => {
    if (!initialized && !result.loading) {
      const { data, error } = result
      if (error) {
        setError(error.message)
      } else if (data) {
        setWhiteLabel(createWhiteLabel(data.getEnterprisePharmacy))
      }
      setInitialized(true)
    }
  }, [result])

  return {
    whiteLabel,
    loading: !initialized,
    error,
  }
}
