import * as React from 'react'
import { DeepPartial } from 'redux'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import {
  AppointmentActivityContext,
  AppointmentActivityContextInterface,
} from '../AppointmentActivityContext'

export const useAppointmentActivityContext = () => {
  const context = React.useContext(AppointmentActivityContext)
  if (context === undefined) {
    throw new Error(
      'useAppointmentActivityContext must be used within a AppointmentActivityProvider'
    )
  }
  const { dispatch, ...state } = context
  const setAppointmentActivity = React.useCallback(
    (appointmentActivity: DeepPartial<AppointmentActivityInstance>) => {
      context.dispatch({
        type: 'setAppointmentActivity',
        appointmentActivity: appointmentActivity,
      })
    },
    [context.dispatch]
  )

  const updateScope = React.useCallback(
    (scope: DeepPartial<AppointmentActivityContextInterface['scope']>) => {
      dispatch({ type: 'updateScope', scope })
    },
    [dispatch]
  )

  return {
    ...state,
    setAppointmentActivity,
    updateScope,
  }
}
