import { useEffect, useState } from 'react'
import { useDefaultFlagState } from './useDefaultFlagState'

export interface FlagMap {
  [flagKey: string]: boolean
}

/**
 * generic hook for interacting with feature flags
 * @param storageKey key for storage object holding flags
 * @param storage an implementation of the Storage interface https://developer.mozilla.org/en-US/docs/Web/API/Storage
 */
export const useFeatureFlags = (storageKey: string, storage: Storage) => {
  const defaultState = useDefaultFlagState()
  const flagsFromStorage = JSON.parse(storage.getItem(storageKey) || '{}')
  const currentFlags = { ...defaultState, ...flagsFromStorage }
  const [flags, setFlags] = useState<FlagMap>(currentFlags)

  useEffect(() => {
    const fetchFromStorage = () => {
      const flagsFromStorage = JSON.parse(storage.getItem(storageKey) || '{}')
      const flags = { ...defaultState, ...flagsFromStorage }
      setFlags(flags)
    }
    fetchFromStorage()

    // if adopted broadly this should move to a central context with a single listener
    window.addEventListener(storageKey, fetchFromStorage, false)

    return () => {
      window.removeEventListener(storageKey, fetchFromStorage, false)
    }
  }, [defaultState, storage, storageKey])

  const setFlag = (flagKey: string, status: boolean) => {
    flags[flagKey] = status
    storage.setItem(storageKey, JSON.stringify(flags))
    setFlags(flags)
  }

  return {
    isFlagEnabled: (flagKey: string) => !!flags[flagKey],
    setFlagEnabled: (flagKey: string) => setFlag(flagKey, true),
    setFlagDisabled: (flagKey: string) => setFlag(flagKey, false),
    getAllFlags: () => flags,
  }
}

/**
 * hook that implements useFeatureFlags using localStorage
 * @param storageKey key for storage object holding flags
 */
export const useLocalFeatureFlags = (storageRef: string) => {
  // if (!window || !window.localStorage)
  return useFeatureFlags(storageRef, localStorage)
}
