import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export interface CurrentPharmacy {
  schedulingLink: string
  createdAt: string
  enterprise: string
  hidePersonalCalendar: boolean
  hideSharedCalendar: boolean
  id: string
  logoCircle: string
  logoLong: string
  logoLongInverse: string
  name: string
  pharmacyAddress: {
    city: string
    country: string
    id: string
    poBox: string
    postalCode: string
    province: string
    streetName: string
    streetNumber: string
    unit: string
  }
  pharmacyContact: {
    id: string
    phone: string
    fax: string
    email: string
  }
  province: string
  countryCode?: string
  storeNo: string
  timeZone: string
  zone: string
}

export interface PharmacyState {
  pharmacy: CurrentPharmacy
}

export const useCurrentPharmacy = (): CurrentPharmacy => {
  const pharmacy = useSelector<PharmacyState, CurrentPharmacy>(
    (state) => state.pharmacy
  )

  const currentPharmacy = useMemo(() => {
    const { storeNo, ...pharmacyData } = pharmacy
    return {
      ...pharmacyData,
      storeNo,
      schedulingLink: `https://${window.location.host}${
        storeNo ? `/${storeNo}` : ''
      }/schedule`,
    }
  }, [pharmacy])

  return currentPharmacy
}
