import React from 'react'
import { MarkdownComponent } from '~/lib/markdown/MarkdownComponent'
import { SectionBodyContainer } from '../../Consent.style'
import { Body } from '~/pages/AppointmentIntake/AppointmentIntake.style'

type ConsentDescriptionContainerProps = {
  acknowledgeConsent: string
  consentDescription: string
}

export const ConsentDescriptionContainer: React.FC<
  ConsentDescriptionContainerProps
> = ({ acknowledgeConsent, consentDescription }) => {
  return (
    <SectionBodyContainer>
      <Body>
        <SectionBodyContainer>{acknowledgeConsent}</SectionBodyContainer>
        <MarkdownComponent>{consentDescription}</MarkdownComponent>
      </Body>
    </SectionBodyContainer>
  )
}
