import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'
import { FbaFlagReturnType } from './types'

export const useIsFbAFiltersEnabled = (services: { urlParam: string }[]) => {
  const { isEnabledForAll, urlParamsEnabled } =
    useFeatureFlag<FbaFlagReturnType>(
      FeatureFlag.ENABLE_FBA_ON_STORE_SELECTION_PAGE_JSON,
      { isEnabledForAll: false }
    )
  const isEnabled =
    isEnabledForAll ||
    (Array.isArray(urlParamsEnabled) &&
      services.every((service) => urlParamsEnabled.includes(service.urlParam)))

  return isEnabled
}
