import * as React from 'react'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import {
  CreateAppointmentDocumentInput,
  usePharmacistCreateAppointmentDocumentMutation,
} from '~/graphql/types/schemaNode.type'

export const usePharmacistCreateAppointmentDocument = () => {
  const [mutation] = usePharmacistCreateAppointmentDocumentMutation()

  const pharmacistCreateAppointmentDocument = React.useCallback(
    async (input: CreateAppointmentDocumentInput) => {
      const { appointmentId, documentTemplateId, updatedById } = input

      try {
        const response = await mutation({
          variables: {
            appointmentId,
            documentTemplateId,
            updatedById,
          },
          context: {
            // must pass graphqlAuthGuard in TS BE
            clientName: EServer.NODE,
          },
        })
        return response
      } catch (e) {
        console.error(
          'pharmacistCreateAppointmentDocument: failed to create a document' + e
        )
      }
    },
    [mutation]
  )

  return pharmacistCreateAppointmentDocument
}
