import { PatientOutput as NodePatient } from '~/graphql/types/schemaNode.type'
import {
  ELanguageCode,
  Patient as JavaPatient,
} from '~/graphql/types/schema.type'
import { DeepPartial } from '~/pages/AppointmentIntake/lib'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const getJavaPatientFromNode = (
  patient: DeepPartial<NodePatient> | undefined | null
): DeepPartial<JavaPatient> => {
  return {
    id: patient?.id ?? '',
    firstName: patient?.firstName ?? '',
    lastName: patient?.lastName ?? '',
    sex: patient?.sex ?? '',
    gender: patient?.gender ?? '',
    birthDate: patient?.birthDate ?? '',
    email: patient?.email ?? '',
    languageCode: (patient?.langCode as unknown as ELanguageCode) ?? null,
    address: {
      unit: patient?.address?.unit ?? '',
      streetNumber: patient?.address?.streetNumber ?? '',
      streetName: patient?.address?.streetName ?? '',
      city: patient?.address?.city ?? '',
      province: patient?.address?.province ?? '',
      postalCode: patient?.address?.postalCode ?? '',
      country: patient?.address?.country ?? '',
      poBox: patient?.address?.poBox ?? '',
    },
    phone: {
      home: patient?.phone?.home ?? '',
      cell: patient?.phone?.cell ?? '',
    },
    healthCard: {
      number: patient?.healthCard?.number ?? '',
      versionCode: patient?.healthCard?.versionCode ?? '',
      expiry: patient?.healthCard?.expiry ?? '',
      province: patient?.healthCard?.province ?? '',
    },
    primaryCareProvider: {
      firstName: patient?.primaryCareProvider?.firstName ?? '',
      lastName: patient?.primaryCareProvider?.lastName ?? '',
      phone: patient?.primaryCareProvider?.phone ?? '',
      email: patient?.primaryCareProvider?.email ?? '',
    },
    emergencyContact: {
      firstName: patient?.emergencyContact?.firstName ?? '',
      lastName: patient?.emergencyContact?.lastName ?? '',
      relationship: patient?.emergencyContact?.relationship ?? '',
      phone: patient?.emergencyContact?.phone ?? '',
    },
    patientConsent: {
      caslEmailConsent: patient?.consent?.caslEmailConsent ?? false,
      caslSmsConsent: patient?.consent?.caslSmsConsent ?? false,
    },
  }
}
