import React, { forwardRef, useEffect } from 'react'
import dayjs from 'dayjs'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined'

import {
  StyledBackdrop,
  StyledDropdown,
  StyledDrawer,
  StyledDropdownContainer,
  StyledHeader,
  StyledTitle,
  StyledFlipIconContainer,
} from './DetailsWidget.styles'
import { DESKTOP_MIN_SCREEN_SIZE } from '../../const'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import tz from 'dayjs/plugin/timezone'
import {
  DetailsWidgetContent,
  DetailsWidgetContentProps,
} from './DetailsWidgetContent'

dayjs.extend(tz)

interface DetailsWidgetProps extends DetailsWidgetContentProps {
  mobileRef: React.RefObject<HTMLDivElement>
}

const ChildrenWithRefWrapper = forwardRef<
  HTMLDivElement,
  Pick<DetailsWidgetProps, 'children'>
>(({ children }, ref) => {
  return <div ref={ref}>{children}</div>
})

export function DetailsWidgetMobile(props: DetailsWidgetProps) {
  const { t } = useMedMeTranslation('patient')
  const [expanded, setExpanded] = React.useState(false)
  const { children, mobileRef, ...context } = props

  useEffect(() => {
    if (expanded) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [expanded])

  const matches = useMediaQuery(`(min-width: ${DESKTOP_MIN_SCREEN_SIZE}px)`)

  useEffect(() => {
    if (matches && expanded) {
      setExpanded(false)
    }
  }, [matches, expanded])

  return (
    <>
      <StyledDropdownContainer>
        <StyledDropdown onClick={() => setExpanded(!expanded)}>
          <StyledHeader>
            <StyledTitle>{t('detailsWidget.title')}</StyledTitle>
            <StyledFlipIconContainer expanded={expanded}>
              <ExpandMoreOutlinedIcon />
            </StyledFlipIconContainer>
          </StyledHeader>
          <StyledDrawer expanded={expanded}>
            <DetailsWidgetContent {...context} />
          </StyledDrawer>
        </StyledDropdown>
        <StyledDrawer expanded={expanded}>
          <ChildrenWithRefWrapper ref={mobileRef}>
            {children}
          </ChildrenWithRefWrapper>
        </StyledDrawer>
      </StyledDropdownContainer>
      <StyledBackdrop expanded={expanded} onClick={() => setExpanded(false)} />
    </>
  )
}
