import React from 'react'
import { useFeatureFlag } from '~/tools/featureGating'
import { FeatureFlag } from '~/tools/featureGating/featureFlags'
import { IAppointmentActivityCardContainerProps } from './AppointmentActivityCardContainer.types'
import { AaiCard as NewBookingAaiCard } from '~/pages/AppointmentIntake/NewBooking/ServiceSelection/components/AaiCard'
import { AppointmentActivityAccordion } from './components/AppointmentActivityAccordion'
import { AaiCard as LegacyBookingAaiCard } from '~/pages/AppointmentIntake/components'

export const AppointmentActivityCardContainer = ({
  isLegacy,
  ...cardProps
}: IAppointmentActivityCardContainerProps) => {
  const isSchedulePageArrowAccordionEnabled = useFeatureFlag<boolean>(
    FeatureFlag.ENABLE_SCHEDULE_PAGE_ARROW_ACCORDION,
    false
  )

  if (isSchedulePageArrowAccordionEnabled) {
    return <AppointmentActivityAccordion {...cardProps} isLegacy={isLegacy} />
  }

  if (isLegacy) {
    return (
      <LegacyBookingAaiCard {...cardProps} selected={cardProps.isSelected} />
    )
  }

  return <NewBookingAaiCard {...cardProps} selected={cardProps.isSelected} />
}
