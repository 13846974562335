import { Extension } from 'fhir/r5'
import { isEqual } from 'lodash'
import * as React from 'react'
import { UseFormMethods } from 'react-hook-form'
import {
  REUSE_ITEM_EXT_MEDME_URL,
  REUSE_TRIGGER_EXT_MEDME_URL,
} from '~/lib/fhir/extensions/constants'
import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
  SimpleValueType,
} from '~/lib/fhir/fhirQuestionnaire'
import { useReusableIntakeDataState } from '~/pages/AppointmentIntake/NewBooking/Intake/ReusableIntakeDataContext'

type ReuseTriggerItems = Record<string, { linkIds: string[] }>

/**
 * @param questionnaire FhirQuestionnaire
 * @param methods method from react-hook-form useForm
 *
 * @returns
 */
export const useReuseableFhirQuestionnaireItems = (
  questionnaire: FhirQuestionnaire,
  methods: UseFormMethods<Record<string, SimpleValueType>>
) => {
  const [error, setError] = React.useState<Error | undefined>(undefined)
  const { updateData, patientIndex, data } = useReusableIntakeDataState()
  // Used to watch for changes based on the reuse-trigger extension.
  const [reuseTriggerItems, setReuseTriggerItems] =
    React.useState<ReuseTriggerItems>({})
  // Grouped items that can update a triggers' state when changed.
  const [reuseItemGroups, setReuseItemGroups] = React.useState<
    Record<string, { linkIds: Set<string> }>
  >({})
  // Used to watch for changes based on the reuse-item extension.
  const [reuseItemIds, setReuseItemIds] = React.useState<string[]>([])
  React.useEffect(() => {
    const seen = new Set(
      questionnaire.getQuestionnaireItems().map((item) => item.linkId)
    )

    questionnaire.getQuestionnaireItems().forEach((item) => {
      interface ReuseTriggerExtension extends Omit<Extension, 'valueCode'> {
        valueCode: string[]
      }

      const reuseTriggerExtensions = item.extension?.filter((ext) => {
        return ext.url === REUSE_TRIGGER_EXT_MEDME_URL
      }) as ReuseTriggerExtension[] | undefined

      reuseTriggerExtensions?.forEach((ext) => {
        const reuseItemValueCodes: string[] = ext.valueCode

        reuseItemValueCodes.forEach((valueCode) => {
          if (!seen.has(valueCode)) {
            setError(
              new Error(
                `Reuse trigger answer value code "${valueCode}" not found in questionnaire`
              )
            )
          }
        })

        // Triggers that fire when a User selects a checkbox.
        setReuseTriggerItems((prevState: ReuseTriggerItems) => ({
          ...prevState,
          [item.linkId]: {
            ...prevState[item.linkId],
            linkIds: reuseItemValueCodes,
          },
        }))

        // Items that will be reused by the User and can update a triggers' state.
        setReuseItemGroups(
          (prevState: Record<string, { linkIds: Set<string> }>) => {
            const state = { ...prevState }
            reuseItemValueCodes.forEach((valueCode) => {
              if (!state[valueCode]) {
                state[valueCode] = {
                  linkIds: new Set([item.linkId]),
                }
              }
              state[valueCode].linkIds.add(item.linkId)
            })
            return state
          }
        )
      })
    })
  }, [questionnaire, questionnaire.linkIdItems])

  // Checkboxes that allow a User to copy previously inputted data.
  const reuseTriggers = methods.watch(Object.keys(reuseTriggerItems))
  const reuseTriggersRef = React.useRef(reuseTriggers)

  React.useEffect(() => {
    for (const key in reuseTriggers) {
      if (reuseTriggers[key] === reuseTriggersRef.current[key]) continue
      const rule = reuseTriggerItems[key]

      if (!rule) continue

      const linkIdsToUpdate: string[] = rule.linkIds || []

      if (reuseTriggers[key]) {
        linkIdsToUpdate.forEach((id) => {
          if (data?.[id]) {
            methods.setValue(id, data[id])
          }
        })
      }
    }
    reuseTriggersRef.current = reuseTriggers
  }, [reuseTriggers, reuseTriggerItems, methods, data])

  const getReuseItemIds = React.useCallback(
    (acc: Set<string>, items: FhirQuestionnaireItem[]) => {
      items.forEach((item) => {
        if (
          item.extension?.some((ext) => ext.url === REUSE_ITEM_EXT_MEDME_URL)
        ) {
          // If a Questionnaire item has the reuse extension, add it to the list.
          // If it has children, the extension should also apply to all items and
          // recursively search and add them to the list.
          acc.add(item.linkId)
          const getLinkIds = (
            acc: Set<string>,
            items: FhirQuestionnaireItem[]
          ) => {
            items.forEach((item) => {
              if (item.linkId) {
                acc.add(item.linkId)
              }
              if (item.item) {
                getLinkIds(acc, item.item)
              }
            })
            return acc
          }
          return getLinkIds(acc, item.item ?? [])
        } else {
          // If a Questionnaire item does not have the reuse extension
          // recursively search for the extension in child items.
          return getReuseItemIds(acc, item.item ?? [])
        }
      })
      return acc
    },
    []
  )

  React.useEffect(() => {
    const reuseItems = getReuseItemIds(
      new Set(),
      questionnaire.getQuestionnaireItems()
    )
    setReuseItemIds(Array.from(reuseItems))
  }, [getReuseItemIds, questionnaire, questionnaire.linkIdItems])

  // Inputs to be later reused by the User during submission.
  const reuseItemsMethods = methods.watch(reuseItemIds)
  const reuseItemGroupsMethodsRef = React.useRef(reuseItemsMethods)

  React.useEffect(() => {
    if (String(patientIndex) === '0' || !reuseItemsMethods) {
      return
    }

    if (isEqual(reuseItemGroupsMethodsRef.current, reuseItemsMethods)) return

    for (const key in reuseItemGroups) {
      if (reuseItemsMethods[key] === reuseItemGroupsMethodsRef.current[key])
        continue
      const rule = reuseItemGroups[key]

      if (!rule) continue

      const linkIdsToUpdate: string[] = Array.from(rule.linkIds) || []

      if (reuseItemsMethods[key] !== data?.[key]) {
        linkIdsToUpdate.forEach((id) => {
          methods.setValue(id, false)
        })
      }
    }
    reuseItemGroupsMethodsRef.current = reuseItemsMethods
  }, [
    data,
    methods,
    patientIndex,
    reuseItemGroups,
    reuseItemsMethods,
    reuseTriggers,
  ])

  const saveReusableData = React.useCallback(() => {
    if (String(patientIndex) !== '0' || !reuseItemsMethods) {
      return
    }
    updateData(reuseItemsMethods)
  }, [reuseItemsMethods, patientIndex, updateData])

  // The reuse triggers should be visible for all patients except the first.
  const hiddenItems = React.useMemo(() => {
    const hiddenItemsIds = new Set<string>()

    if (!patientIndex) {
      Object.keys(reuseTriggerItems).forEach((linkId) => {
        hiddenItemsIds.add(linkId)
      })
    }
    return hiddenItemsIds
  }, [patientIndex, reuseTriggerItems])

  return React.useMemo(
    () => ({
      error,
      saveReusableData,
      hiddenItems,
    }),
    [error, hiddenItems, saveReusableData]
  )
}
