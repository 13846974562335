import { createAppointmentType } from '~/lib/appointmentType'
import {
  DeepPartial,
  ObjectPropTypeFns,
} from '../../../pages/AppointmentIntake/lib/types'
import { createAppointmentMethod } from './createAppointmentMethod'
import { createAppointmentScreeningAnswers } from './createAppointmentScreeningAnswers'
import {
  ELanguageCode,
  Appointment as JavaAppointment,
} from '~/graphql/types/schema.type'
import { Appointment, QuestionnaireResponse } from '..'
import { createAppointmentLanguageCode } from './createAppointmentLanguageCode'

type MaybeAppointment = DeepPartial<JavaAppointment> & {
  questionnaireResponses?: QuestionnaireResponse[]
}

// TODO: we can probably deprecate this function once we clean up the graphql typing in the BE
export const createAppointment = (
  appointment?: MaybeAppointment | null
): Appointment => {
  return {
    id: appointment?.id ?? '',
    startDateTime: appointment?.startDateTime ?? '',
    endDateTime: appointment?.endDateTime ?? '',
    method: createAppointmentMethod(appointment?.method),
    status: appointment?.appointmentStatus ?? '',
    appointmentStatus: appointment?.appointmentStatus ?? '',
    isWalkin: appointment?.isWalkin ?? false,
    noOfPeople: appointment?.noOfPeople ?? 1,
    langCode: appointment?.langCode ?? ELanguageCode.EN,
    consentCollectedBy: appointment?.consentCollectedBy ?? '',
    consentGiverName: appointment?.consentGiverName ?? '',
    bookingEndDate: String(appointment?.bookingEndDate ?? ''),
    bookingStartDate: String(appointment?.bookingStartDate ?? ''),
    linkExpiresAt: appointment?.linkExpiresAt ?? '',
    documentationCompletedAt: appointment?.documentationCompletedAt ?? '',
    createdAt: appointment?.createdAt ?? '',
    updatedAt: appointment?.updatedAt ?? '',
    appointmentType: createAppointmentType(appointment?.appointmentType),
    reasonForVisit: appointment?.reasonForVisit ?? '',
    injectionName: appointment?.injectionName ?? '',
    screeningAnswers: createAppointmentScreeningAnswers(
      appointment?.screeningAnswers
    ),
    questionnaireResponses: appointment?.questionnaireResponses ?? [],
  }
}

const throwWhenCalled = () => {
  throw new Error('should never go here')
}

export const appointmentPropFns: ObjectPropTypeFns<Appointment> = {
  id: String,
  startDateTime: String,
  endDateTime: String,
  method: createAppointmentMethod,
  status: String,
  appointmentStatus: String,
  isWalkin: Boolean,
  noOfPeople: Number,
  langCode: createAppointmentLanguageCode,
  consentCollectedBy: String,
  consentGiverName: String,
  bookingStartDate: String,
  bookingEndDate: String,
  linkExpiresAt: String,
  documentationCompletedAt: String,
  createdAt: String,
  updatedAt: String,
  appointmentType: throwWhenCalled,
  reasonForVisit: String,
  injectionName: String,
  screeningAnswers: throwWhenCalled,
  questionnaireResponses: throwWhenCalled,
}
