import { Extension } from 'fhir/r5'
import { ExtensionValidationConditionGroup } from '../validation.type'
import { isExtensionValidationBehaviour } from './isExtensionValidationBehaviour'
import { isExtensionValidationConditionList } from './isExtensionValidationConditionList'
import { isValidExtensionProps } from '../../lib/isValidExtensionProps'
import { isExtensionValidationConditionGroupList } from './isExtensionValidationConditionGroupList'

export const isExtensionValidationConditionGroup = (
  extension: Extension
): extension is ExtensionValidationConditionGroup => {
  if (
    extension.url === 'conditionGroup' &&
    extension.extension &&
    extension.extension.length >= 2
  ) {
    if (extension.extension.length === 2) {
      const includesBehaviour: boolean =
        extension.extension.find(isExtensionValidationBehaviour) !== undefined
      return (
        includesBehaviour &&
        extension.extension.find(
          (extension) =>
            isExtensionValidationConditionList(extension) ||
            isExtensionValidationConditionGroupList(extension)
        ) !== undefined
      )
    } else if (extension.extension.length === 3) {
      return isValidExtensionProps(extension, [
        isExtensionValidationBehaviour,
        isExtensionValidationConditionList,
        isExtensionValidationConditionGroupList,
      ])
    }
  }
  return false
}
