import * as React from 'react'
import { FhirFormContextProps, FhirFormContext } from '../FhirFormContext'

export const FhirFormProvider: React.FC<FhirFormContextProps> = ({
  children,
  disabledFields,
}) => {
  return (
    <FhirFormContext.Provider value={{ disabledFields }}>
      {children}
    </FhirFormContext.Provider>
  )
}
