import * as React from 'react'
import { PharmacyContextInterface } from '../../PharmacyContext'

export const useResetPharmacy = (
  dispatch: PharmacyContextInterface['dispatch'],
  initialPharmacyId: string
) => {
  const resetPharmacy = React.useCallback(() => {
    dispatch({ type: 'resetPharmacy' })
    localStorage.setItem('pharmacyId', initialPharmacyId)
  }, [dispatch])

  return { resetPharmacy }
}
