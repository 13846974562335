import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { createFhirCodingEntityPatient } from '~/pages/AppointmentIntake/lib/fhir/systems'
import { presetFhirGridExt } from '../../../../../../../lib/fhir/extensions/fhirExtGrid/presetFhirGridExt'
import { provinceValueSet } from '../../../../lib/provinceValueSet'
import { JsonFhirFormStep } from '../../../../../types'

export const cardHeading = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Information',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Required fields (*)',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [heading, descReq],
  })
}

export const healthCard = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'healthCardHeading',
    type: 'display',
    text: 'Health Card',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const desc = createFhirQuestionnaireItem({
    linkId: 'healthCardDesc',
    type: 'display',
    text: 'Please enter only numeric characters from your Health Card',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const hcProvince = createFhirQuestionnaireItem({
    linkId: 'healthCardProvince',
    type: 'choice',
    text: 'Province',
    required: true,
    answerOption: [
      ...provinceValueSet('SK'),
      {
        valueCoding: {
          code: 'other',
          display: 'Other',
        },
      },
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    code: [createFhirCodingEntityPatient({ code: 'healthCard.province' })],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcNumber = createFhirQuestionnaireItem({
    linkId: 'healthCardNumber',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'numeric' })],
    },
    text: 'Health Card Number',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'healthCard.number' })],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 'auto',
      }),
    ],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcVersion = createFhirQuestionnaireItem({
    linkId: 'healthCardVersion',
    type: 'string',
    text: 'Version Code',
    code: [createFhirCodingEntityPatient({ code: 'healthCard.versionCode' })],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    enableBehavior: 'all',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const postOntarioDesc = createFhirQuestionnaireItem({
    linkId: 'postOntarioDesc',
    type: 'display',
    text: 'Version Code is the 2 letters after your 10-digit Health Card Number (Leave Blank if you have a red/white card)',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
    enableWhen: [
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    enableBehavior: 'any',
  })

  const noHealthCard = createFhirQuestionnaireItem({
    linkId: 'noHealthCard',
    type: 'boolean',
    text: "I don't have a Health Card",
  })

  const noHealthCardNotice = createFhirQuestionnaireItem({
    linkId: 'noHealthCardNotice',
    type: 'display',
    text: "If you don't have a valid, Saskatchewan Health Services Card, Professional fees may apply.",
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [
      heading,
      desc,
      hcProvince,
      hcNumber,
      hcVersion,
      postOntarioDesc,
      noHealthCard,
      noHealthCardNotice,
    ],
  })
}

const pcp = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'pcpHeading',
    type: 'display',
    text: 'Family Doctor',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const pcpFirstName = createFhirQuestionnaireItem({
    linkId: 'pcpFirstName',
    type: 'string',
    text: 'First Name',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.firstName' }),
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })
  const pcpLastName = createFhirQuestionnaireItem({
    linkId: 'pcpLastName',
    type: 'string',
    text: 'Last Name',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.lastName' }),
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const pcpPhone = createFhirQuestionnaireItem({
    linkId: 'pcpPhone',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    text: 'Phone Number',
    extension: [
      presetFhirGridExt({ xs: 12, sm: 4 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Invalid phone number' },
        ],
      }),
    ],
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.phone' }),
    ],
    required: true,
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const pcpInfo = createFhirQuestionnaireItem({
    linkId: 'pcpInfo',
    type: 'display',
    text: '*It may be necessary for the pharmacist to discuss and share your health information with other healthcare professionals (e.g. physicians) in accordance with generally accepted medication therapy management principles.',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const noPcp = createFhirQuestionnaireItem({
    linkId: 'noPcp',
    type: 'boolean',
    text: "I don't have a family doctor",
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [heading, pcpFirstName, pcpLastName, pcpPhone, pcpInfo, noPcp],
  })
}

export const fhirMedicalInformationQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalInformationQuestionnaire',
  name: 'Medical Information',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalInformationPage',
      type: 'group',
      item: [cardHeading(), healthCard(), pcp()],
    }),
  ],
})

export const fhirMAMedicalInformationSdmSKJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalInformationQuestionnaire,
  filter: {
    regionCodes: ['SK'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
    },
  },
  metadata: {
    id: 'fhirMAMedicalInformationSdmSKJson',
    path: 'medical-information',
    name: 'Medical Information',
    showOnSteps: true,
  },
}
