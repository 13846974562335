import * as React from 'react'
import Loading from '~/components/loading'
import { usePharmacyContext } from '../../PharmacyContext'
import { useRouterContext } from '../../RouterContext'
import {
  createAppointmentActivityContext,
  AppointmentActivityContext,
  AppointmentActivityContextInterface,
  createScope,
} from '../AppointmentActivityContext'
import { useGetInitialAppointmentActivity } from '../useGetInitialAppointmentActivity'

const reducer = (
  state: AppointmentActivityContextInterface,
  action: any
): AppointmentActivityContextInterface => {
  switch (action.type) {
    case 'initializeAppointmentActivity':
      return {
        ...state,
        initialAppointmentActivity: action.appointmentActivity,
        appointmentActivity: action.appointmentActivity,
      }
    case 'setAppointmentActivity':
      return {
        ...state,
        appointmentActivity: action.appointmentActivity,
      }
    case 'updateScope': {
      return {
        ...state,
        scope: {
          ...state.scope,
          ...action.scope,
        },
      }
    }
    default:
      console.log('unknown action type', action.type, action)
      return state
  }
}

export const AppointmentActivityProvider: React.FC = ({ children }) => {
  const {
    searchParams: { province },
  } = useRouterContext()
  const { pharmacy } = usePharmacyContext()
  const initialScope = React.useMemo(
    () =>
      createScope({
        regionCode: pharmacy.province || province,
      }),
    []
  )

  const [context, dispatch] = React.useReducer(
    reducer,
    createAppointmentActivityContext({
      scope: initialScope,
    })
  )
  const { initialAppointmentActivity, loading, error } =
    useGetInitialAppointmentActivity(initialScope)
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    if (!loading) {
      dispatch({
        type: 'initializeAppointmentActivity',
        appointmentActivity: initialAppointmentActivity,
      })
      setInitialized(true)
    }
  }, [loading])

  if (!initialized) return <Loading title="Loading AppointmentActivity" />
  if (error) throw new Error(error)

  return (
    <AppointmentActivityContext.Provider value={{ ...context, dispatch }}>
      {children}
    </AppointmentActivityContext.Provider>
  )
}
