import { Extension } from 'fhir/r5'
import { ExtensionValidationMessage } from '../validation.type'

export const isExtensionValidationMessage = (
  extension: Extension
): extension is ExtensionValidationMessage => {
  return (
    extension.url === 'message' &&
    extension.valueString !== undefined &&
    Object.keys(extension).length <= 3
  )
}
