import { Extension } from 'fhir/r5'
import { fhirStructDefUrlMedMe } from '../constants'

const extPathName = 'validation'

export const extensionValidationUrl =
  `${fhirStructDefUrlMedMe}/${extPathName}` as const

export const validationOperatorCodes = [
  // json-rule-engine default operator
  'equal',
  'notEqual',
  'lessThan',
  'lessThanInclusive',
  'greaterThan',
  'greaterThanInclusive',
  // same ones are enableWhem
  '=',
  '!=',
  '>',
  '>=',
  '<',
  '<=',
  // Custom Operators
  'exists',
  'isAfter',
  'isBefore',
  'isEmail',
  'isPhone',
  'isSameOrBefore',
  'match',
] as const

export interface ExtensionValidationOperator
  extends Pick<Extension, 'url' | 'valueCode'> {
  url: 'operator'
  valueCode: typeof validationOperatorCodes[number]
}

export interface ExtensionValidationMessage
  extends Pick<Extension, 'url' | 'valueString' | '_valueString'> {
  url: 'message'
  valueString: string
  _valueString?: Element
}

export interface ExtensionValidationIsPath
  extends Pick<Extension, 'url' | 'valueBoolean'> {
  url: 'isPath'
  valueBoolean: boolean
}

export const validExtensionValidationValueKeys = [
  'valueBoolean',
  'valueString',
  'valueInteger',
  'valueCode',
  'valueDate',
  'valueDateTime',
  'valueDecimal',
] as const
export interface ExtensionValidationValue
  extends Pick<
    Extension,
    'url' | typeof validExtensionValidationValueKeys[number]
  > {
  url: 'value'
}

export const validationBehaviourCodes = ['all', 'any'] as const
export interface ExtensionValidationBehaviour
  extends Pick<Extension, 'url' | 'valueCode'> {
  url: 'behaviour'
  valueCode: typeof validationBehaviourCodes[number]
}

export interface ExtensionValidationCondition
  extends Pick<Extension, 'url' | 'extension'> {
  url: 'condition'
  extension: (
    | ExtensionValidationOperator
    | ExtensionValidationIsPath
    | ExtensionValidationValue
  )[]
}

export interface ExtensionValidationConditionList
  extends Pick<Extension, 'url' | 'extension'> {
  url: 'conditionList'
  extension: ExtensionValidationCondition[]
}

export interface ExtensionValidationConditionGroupList
  extends Pick<Extension, 'url' | 'extension'> {
  url: 'conditionGroupList'
  extension: ExtensionValidationConditionGroup[]
}

export interface ExtensionValidationConditionGroup
  extends Pick<Extension, 'url' | 'extension'> {
  url: 'conditionGroup'
  extension: (
    | ExtensionValidationBehaviour
    | ExtensionValidationConditionList
    | ExtensionValidationConditionGroupList
  )[]
}

export interface ExtensionValidation
  extends Pick<Extension, 'url' | 'extension'> {
  url: typeof extensionValidationUrl
  extension: (ExtensionValidationConditionGroup | ExtensionValidationMessage)[]
}
