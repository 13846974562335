import React from 'react'
import { useErrorText, UseErrorTextOptions } from './useErrorText'

export interface ErrorTextProps extends UseErrorTextOptions {
  error: Error | undefined
}

/**
 * accepts an Error object and attempts to render the appropriate localized
 * text based on it's message and/or subclass
 */
export const ErrorText: React.FC<ErrorTextProps> = ({ error, ...options }) => {
  const errorText = useErrorText(error, options)

  return <>{errorText}</>
}
