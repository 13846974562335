import React, { useContext, useState, useImperativeHandle } from 'react'
import { fade } from '@material-ui/core/styles'
import { ThemeContext } from 'styled-components'
import { red } from '~/sharedStyles/colorPalette'

const StripeInput = (props) => {
  const {
    component: Component,
    inputRef,
    'aria-invalid': ariaInvalid,
    'aria-describedby': ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    options,
    ...other
  } = props
  const theme = useContext(ThemeContext)
  const [mountNode, setMountNode] = useState(null)

  useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode]
  )

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: theme.color.textPrimary,
            fontSize: '15px',
            fontFamily: 'Montserrat',
            '::placeholder': {
              color: fade(theme.color.textPrimary, 0.6),
            },
          },
          invalid: {
            color: red,
          },
        },
      }}
      {...other}
    />
  )
}

export default StripeInput
