import * as React from 'react'
import { createWhiteLabel } from './createWhiteLabel'

export const createWhiteLabelContext = (data?: any) => ({
  whiteLabel: createWhiteLabel(data?.whiteLabel),
  loading: false,
})

export type WhiteLabelContextInterface = ReturnType<
  typeof createWhiteLabelContext
>

export const WhiteLabelContext = React.createContext(createWhiteLabelContext())
