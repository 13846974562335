import * as Sentry from '@sentry/react'
import { SentryLink } from 'apollo-link-sentry'
import { EServer } from '~/graphql/hooks/useGraphQL/links'

function getHeaders(headers: Record<string, string>) {
  return {
    tenantId: headers?.['x-tenantid'],
    pharmacyId: headers?.['x-pharmacyid'],
    language: headers?.['accept-language'],
    countryCode: headers?.['x-country-code'],
    regionCode: headers?.['x-region-code'],
  }
}

function getTranslatedClientName(clientName: EServer) {
  return clientName === EServer.NODE ? 'node' : 'java'
}

export const monitoringLink = () =>
  new SentryLink({
    setTransaction: false,
    setFingerprint: false,
    attachBreadcrumbs: {
      includeContext: ['clientName'],
      transform: (breadcrumb, operation) => {
        const { clientName, headers } = operation.getContext()
        const context = {
          ...getHeaders(headers),
          clientName: getTranslatedClientName(clientName),
        }

        return { ...breadcrumb, data: { ...breadcrumb.data, context } }
      },
    },
  })

export type ExceptionPayload = {
  operationName: string
  clientName: EServer
  headers?: Record<string, string>
  extensions?: Record<string, unknown>
  path?: ReadonlyArray<string | number>
  tags?: Record<string, string>
  rawData?: string | Record<string, any>
}

export function reportGraphqlException(
  error: Error,
  payload: ExceptionPayload
) {
  Sentry.captureException(error, (scope) => {
    const translatedClientName = getTranslatedClientName(payload.clientName)

    scope.setTransactionName(payload.operationName)
    scope.setContext('GraphQL Operation', {
      operationName: payload.operationName,
      extensions: payload.extensions,
      errorCode: payload.extensions?.code ?? payload.extensions?.errorCode,
      path: payload.path,
      clientName: translatedClientName,
      ...(payload.headers && getHeaders(payload.headers)),
    })

    scope.setTag('api', 'graphql')
    scope.setTag('client', translatedClientName)

    for (const tagKey in payload.tags) {
      scope.setTag(tagKey, payload.tags[tagKey])
    }

    if (payload.rawData) {
      scope.setExtra(
        'Raw Data',
        typeof payload.rawData === 'string'
          ? payload.rawData
          : JSON.stringify(payload.rawData)
      )
    }

    return scope
  })
}
