import useMediaQuery from '@material-ui/core/useMediaQuery'

/**
 *
 * @param width - width of the device in pixels
 * @returns boolean value indicating whether the max width of the device is less than or equal to the specified width
 */
export const useIsMaxWidthOfDevice = (width = '400px') => {
  return useMediaQuery(`(max-width: ${width})`)
}
