import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useConsentDescription } from './useConsentDescription'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { EAppointmentTypeCategory } from '~/graphql/types/schema.type'
import { getFormattedBuildingAndCityAddressFromPharmacyAddress } from '~/util/addressHelpers'

export const useConsent = () => {
  const { t } = useMedMeTranslation('patient')
  const { consentDescription } = useConsentDescription()
  const {
    state: {
      pharmacy,
      appointment,
      patient,
      appointmentActivityGroup,
      appointmentActivity,
    },
  } = useBookingContext()
  const { firstName, lastName } = patient
  const { name, pharmacyAddress } = pharmacy

  const acknowledgeConsent = t('consentForm.acknowledgeConsent')

  const consentGivers = [
    { label: t('consentForm.patient'), value: 'patient' },
    { label: t('consentForm.guardian'), value: 'guardian' },
    { label: t('consentForm.other'), value: 'other' },
  ]

  const appointmentName =
    appointmentActivity.category === EAppointmentTypeCategory.MINOR_AILMENT
      ? appointmentActivityGroup.title
      : appointmentActivity.details.title
  const patientFullName = `${firstName} ${lastName}`
  const pharmacyAddressDisplay = {
    name: name,
    ...getFormattedBuildingAndCityAddressFromPharmacyAddress(pharmacyAddress),
  }

  const defaultValues = {
    consentCollectedBy: appointment?.consentCollectedBy ?? 'patient',
    consentGiverName: appointment?.consentGiverName ?? '',
  }

  const getAppointmentDetails = () => {
    const appointmentDetails = t('consentForm.subtitleCompletingTheForm', {
      appointmentName: appointmentName,
    })
    return appointmentDetails
  }

  const getPharmacistText = (urlParam: string) => {
    if (urlParam === 'poct-a1c') {
      // Get pharmacist details text for SDM POCT A1C
      return t('confirmText2')
    } else if (urlParam === 'quit-smoking') {
      // Get pharmacist details text for SDM smoking
      return t('consentForm.andAPharmacyProfessionalAssociatedWith')
    } else {
      // Get pharmacist details text for MA appointment activities
      return t('consentForm.andAPharmacyProfessionalAssociatedWith')
    }
  }

  const appointmentDetails = getAppointmentDetails()
  const pharmacyProfessionalText = getPharmacistText(
    appointmentActivity.urlParam
  )

  return {
    acknowledgeConsent,
    consentDescription,
    consentGivers,
    appointmentDetails,
    patientFullName,
    pharmacyAddressDisplay,
    pharmacyProfessionalText,
    defaultValues,
  }
}
