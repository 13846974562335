import { useFormContext } from 'react-hook-form'
import { useGetTimeslotsQuery } from '~/graphql/types/schema.type'
import * as React from 'react'
import { Pharmacy } from '~/lib/pharmacy'
import { createTimeslot } from '~/lib/timeslot'

export const useGetTimeslots = (
  pharmacy: Pharmacy,
  appointmentTypeId: string,
  startDate: string,
  endDate: string
) => {
  const { watch } = useFormContext()

  const noOfPeople = React.useMemo(() => watch('noOfPeople'), [watch])

  const {
    data,
    loading,
    error: gqlError,
  } = useGetTimeslotsQuery({
    variables: {
      pharmacyId: pharmacy.id,
      appointmentTypeId: Number(appointmentTypeId),
      noOfPeople: noOfPeople,
      filter: {
        startDate,
        endDate,
      },
    },
    fetchPolicy: 'network-only',
    context: {
      pharmacyId: pharmacy.id,
    },
  })

  const timeslots = React.useMemo(
    () => data?.publicGetAvailableTimes?.map(createTimeslot) ?? [],
    [data]
  )
  const error = gqlError?.message ?? ''
  if (/^.*0060-/.test(error)) {
    return {
      timeslots,
      loading,
      noCalendarAssigned: true,
      error: '',
    }
  }

  return {
    timeslots,
    loading,
    noCalendarAssigned: false,
    error,
  }
}
