import * as React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { NoOfPeople } from './NoOfPeople'
import { SelectDateTimeText } from './SelectDateTimeText'

export const SelectionHeader: React.FC<{
  isSingleBookingOnly: boolean
  maxNoOfPeople: number
  isWalkin?: boolean
}> = ({ isSingleBookingOnly, maxNoOfPeople, isWalkin = false }) => {
  const {
    state: {
      appointmentActivity: { urlParam },
    },
  } = useBookingContext()
  if (isSingleBookingOnly) {
    if (urlParam === 'paxlovid') {
      return (
        <SelectDateTimeText
          isWalkin={isWalkin}
          prefix="IMPORTANT: COVID-19 antiviral treatment (Paxlovid) must be taken within 5 days of symptoms starting."
        />
      )
    }
    return <SelectDateTimeText isWalkin={isWalkin} />
  }

  return (
    <>
      <NoOfPeople titlePrefix="1. " maxNoOfPeople={maxNoOfPeople} />
      <SelectDateTimeText isWalkin={isWalkin} titlePrefix="2. " />
    </>
  )
}
