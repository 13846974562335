import React, { useCallback, useState } from 'react'
import {
  Box,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useLocalFeatureFlags } from '~/hooks/useFeatureFlags'
import { DownloadButton, MainComponentHeader } from '../debugger.styles'
import { downloadCSV } from '../helpers'

export const FeatureFlags = () => {
  const { getAllFlags, setFlagDisabled, setFlagEnabled } =
    useLocalFeatureFlags('medme-flags')
  const [flags, setFlags] = useState(getAllFlags())

  const toggleFlag = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setFlagEnabled(e.target.name)
      } else {
        setFlagDisabled(e.target.name)
      }

      setFlags((prevFlags) => ({
        ...prevFlags,
        [e.target.name]: e.target.checked,
      }))
    },
    [setFlagDisabled, setFlagEnabled]
  )

  const exportCsv = useCallback(() => {
    downloadCSV(
      ['Feature', 'State'],
      Object.keys(flags).map((key) => {
        return [key, flags[key].toString()]
      })
    )
  }, [flags])

  return (
    <Box>
      <MainComponentHeader>
        <DownloadButton variant="outlined" onClick={exportCsv}>
          Export as CSV
        </DownloadButton>
      </MainComponentHeader>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Feature</TableCell>
              <TableCell align="right">State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(flags).map((key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right">
                  <Switch
                    checked={flags[key]}
                    name={key}
                    onChange={toggleFlag}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
