import env from './env.medme.io.json'

export default {
  'dev-on.medme.io-pharmacyId': env.DEV_ON_PHARMACY_ID,
  'dev-ab.medme.io-pharmacyId': env.DEV_AB_PHARMACY_ID,
  'dev-mb.medme.io-pharmacyId': env.DEV_MB_PHARMACY_ID,
  'dev-bc.medme.io-pharmacyId': env.DEV_BC_PHARMACY_ID,
  'dev-sk.medme.io-pharmacyId': env.DEV_SK_PHARMACY_ID,
  'dev-nb.medme.io-pharmacyId': env.DEV_NB_PHARMACY_ID,
  'dev-pe.medme.io-pharmacyId': env.DEV_PE_PHARMACY_ID,
  'dev-nl.medme.io-pharmacyId': env.DEV_NL_PHARMACY_ID,
  'dev-ns.medme.io-pharmacyId': env.DEV_NS_PHARMACY_ID,
  'heartpharmacycadborobay-dev.medme.io-pharmacyId':
    env.HEARTPHARMACYCADBOROBAY_PHARMACY_ID,
  'heartpharmacycampus-dev.medme.io-pharmacyId':
    env.HEARTPHARMACYCAMPUS_PHARMACY_ID,
  'heartpharmacyfairfield-dev.medme.io-pharmacyId':
    env.HEARTPHARMACYFAIRFIELD_PHARMACY_ID,
  'heartpharmacyshelbourne-dev.medme.io-pharmacyId':
    env.HEARTPHARMACYSHELBOURNE_PHARMACY_ID,
  'heartpharmacystanthonys-dev.medme.io-pharmacyId':
    env.HEARTPHARMACYSTANTHONYS_PHARMACY_ID,
  'heartpharmacysidney-dev.medme.io-pharmacyId':
    env.HEARTPHARMACYSIDNEY_PHARMACY_ID,
}
