import { Event } from 'json-rules-engine'

export interface EnableWhenEvent extends Event {
  type: 'EnableWhenEvent'
  params: {
    fieldName: string
  }
}

export const createEnableWhenEvent = (
  params: Event['params']
): EnableWhenEvent => {
  return {
    type: 'EnableWhenEvent',
    params: {
      fieldName: params?.fieldName ?? '',
    },
  }
}

export const isEnableWhenEvent = (event: Event): event is EnableWhenEvent =>
  event.type === 'EnableWhenEvent'
