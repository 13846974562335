import Skeleton from '@material-ui/lab/Skeleton'
import styled from 'styled-components'

const LoadingSkeleton = styled(Skeleton)`
  background: linear-gradient(
    269.94deg,
    rgba(182, 182, 182, 0.05) 7.15%,
    #cacaca 62.57%
  );
  width: 100%;
`

export default LoadingSkeleton
