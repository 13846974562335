import styled from 'styled-components'
import { offWhite } from '~/sharedStyles/colorPalette'
import { PageLayout } from '~/sharedStyles/containers'
import {
  isEnterprise,
  isLcl,
  isMinorEnterprise,
} from '~/util/enterpriseMethods'

export const BaseLayout = styled(PageLayout)`
  font-family: ${({ theme }) => theme?.font?.main},
    ${({ theme }) => theme?.font?.generic};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: ${offWhite};
  overflow-y: visible;
  box-sizing: border-box;
`

export const HeaderBase = styled.div`
  width: 100%;
  height: 5rem;
`

export const Header = styled(HeaderBase)`
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  top: 0;
  padding: 0 1rem;
  z-index: 500;
  background-color: ${({ theme }) =>
    isEnterprise() && !(isLcl() || isMinorEnterprise())
      ? theme.color.backgroundPrimary
      : 'white'};
  border-bottom: ${() =>
    isEnterprise() && !(isLcl() || isMinorEnterprise())
      ? 'black 1.5px solid'
      : 'none'};
`

export const Body = styled.div`
  flex: 1 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  width: 90%;

  @media only screen and (orientation: landscape) and (min-width: 1065px) {
    width: 1065px;
  }
`

export const HeaderImageContainer = styled.a`
  height: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;

  & > img {
    max-height: 100%;
    max-width: '6rem';
  }

  @media only screen and (min-width: 500px) {
    & > img {
      max-width: 100%;
    }
  }

  @media only screen and (orientation: landscape) and (min-width: 1065px) {
    margin-left: calc(((100% - 1065px) / 2) + 1rem);
  }
`

export const HeaderImageContainerMedMe = styled.a`
  width: 3rem;

  & > img {
    max-width: 100%;
  }
`

export const CardContainer = styled.div`
  flex: 1 0;
  display: flex;
`

export const AddressSection = styled.div<{ pharmacy: any }>`
  margin-right: 1rem;
  font-size: 0.75em;
  font-weight: 600;
  /* Need to change default black to white/titleTertiary once background back to blue, */
  color: ${({ theme, pharmacy }) =>
    ['REXALL', 'SDM'].includes(pharmacy.enterprise)
      ? theme.color.titleTertiary
      : 'black'};

  @media only screen and (orientation: landscape) and (min-width: 1065px) {
    margin-right: calc(((100% - 1065px) / 2) + 1rem);
  }
`

export const Image = styled.img``
