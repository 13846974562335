import styled from 'styled-components'
import { blue } from '~/sharedStyles/colorPalette'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import Typography from '@material-ui/core/Typography'

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
`

export const StyledServicesList = styled.ul`
  list-style: disc;
  padding: 0 1rem;
  margin-top: 0.5rem;

  & li {
    font-size: 0.875rem;
    line-height: 1.35;
  }

  & li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

export const BlueInfoOutlined = styled(InfoOutlined)`
  color: ${blue};
`

export const StyledServicesBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 0.5rem;
  margin-bottom: 0.2rem;
`

export const ServicesTitle = styled(Typography)`
  font-weight: 500;
`

export const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1.5rem 1rem;
  row-gap: 0.4rem;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;

  @media only screen and (min-width: 768px) {
    padding: 1.5rem;
    row-gap: 0.8rem;
  }
`
