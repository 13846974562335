import { createPresetFormStep } from '..'
import { ActivityLandingForm } from '~/pages/AppointmentIntake/forms'

export const activityLandingFormStep: typeof createPresetFormStep = (json) => {
  return {
    Component: ActivityLandingForm,
    metadata: json.metadata,
    enableWhen: (state) => {
      // Skip this step if we're coming from a documentation link or appointment activity is not set
      return (
        state.router.intakeType !== 'documentationLink' &&
        !!state.initial.appointmentActivity.id
      )
    },
    isAutoSkippable: async () => false,
  }
}
