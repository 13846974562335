import { QuestionnaireItemAnswerOption, ValueSet } from 'fhir/r5'
import { provinceList } from '~/util/geographyList'
import {
  fhirValueSetUrlMedme,
  fhirSystemUrlMedme,
} from '~/lib/fhir/extensions/constants'

export const canadaProvinceWithOtherValueSetUrl =
  fhirValueSetUrlMedme + '/canada-province-with-other'

export const canadaProvinceWithOtherValueSet: ValueSet = {
  status: 'active',
  resourceType: 'ValueSet',
  url: canadaProvinceWithOtherValueSetUrl,
  compose: {
    id: '',
    include: [
      {
        system: fhirSystemUrlMedme,
        version: '1.0.0',
        concept: [
          ...Object.keys(provinceList).map(
            (code: keyof typeof provinceList) => ({
              display: provinceList[code],
              code: code,
            })
          ),
          {
            display: 'Other',
            code: 'other',
          },
        ],
      },
    ],
  },
}

export const provinceValueSetAnswerOptions = (initialSelected?: string) => {
  return Object.keys(provinceList).map<QuestionnaireItemAnswerOption>(
    (key: keyof typeof provinceList) => {
      const value = provinceList[key]
      const coding = {
        system: canadaProvinceWithOtherValueSetUrl,
        version: '1.0.0',
        code: String(key),
        display: value,
      }

      if (initialSelected && initialSelected === key) {
        return {
          valueCoding: coding,
          initialSelected: true,
        }
      }
      return {
        valueCoding: coding,
      }
    }
  )
}
