import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
  FhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { createFhirCodingEntityPatient } from '~/pages/AppointmentIntake/lib/fhir/systems'
import { presetFhirGridExt } from '../../../../../../lib/fhir/extensions/fhirExtGrid/presetFhirGridExt'
import { countryValueSet } from '../../../lib/countryValueSet'
import { provinceValueSet } from '../../../lib/provinceValueSet'
import { JsonFhirFormStep } from '../../../../types'

export const patientInformation = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientInformationHeading',
    text: 'Contact Information for Individual(s) receiving Strep Test',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const desc = createFhirQuestionnaireItem({
    linkId: 'patientInformationDesc',
    text: 'Please enter the personal information for yourself and, if applicable, any dependent(s) or any individuals you have consent to book for. All information must be entered as it appears on each person’s provincial health card (if applicable)',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Required fields (*)',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const firstName = createFhirQuestionnaireItem({
    linkId: 'patientFirstName',
    text: 'First Name',
    type: 'string',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'firstName' })],
  })

  const lastName = createFhirQuestionnaireItem({
    linkId: 'patientLastName',
    text: 'Last Name',
    type: 'string',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'lastName' })],
  })

  const gender = createFhirQuestionnaireItem({
    linkId: 'patientGender',
    text: 'Gender',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'female',
          display: 'Female',
        },
      },
      {
        valueCoding: {
          code: 'male',
          display: 'Male',
        },
      },
      {
        valueCoding: {
          code: 'self-identify',
          display: 'Self Identify',
        },
      },
    ],
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'gender' })],
  })

  const dateOfBirth = createFhirQuestionnaireItem({
    linkId: 'patientDateOfBirth',
    text: 'Date of Birth',
    type: 'date',
    required: true,

    extension: [
      presetFhirGridExt({ xs: 12, sm: 6 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isSameOrBefore' },
          { url: 'value', valueString: 'now' },
          { url: 'message', valueString: 'Invalid date of birth' },
        ],
      }),
    ],
    code: [createFhirCodingEntityPatient({ code: 'birthDate' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientInformationSection',
    type: 'group',
    item: [heading, desc, descReq, firstName, lastName, gender, dateOfBirth],
  })
}

export const patientAddress = () => {
  const enabledWhenNoPoBox = (items: FhirQuestionnaireItem[]) =>
    createFhirQuestionnaireItem({
      linkId: 'enabledWhenNoPoBox',
      type: 'group',
      enableWhen: [
        {
          question: 'patientHasPOBox',
          operator: '=',
          answerBoolean: false,
        },
      ] as FhirQuestionnaireItem['enableWhen'],
      enableBehavior: 'all' as FhirQuestionnaireItem['enableBehavior'],
      item: items,
    })

  const renderWhenHasPoBox = {
    enableWhen: [
      {
        question: 'patientHasPOBox',
        operator: '=',
        answerBoolean: true,
      },
    ] as FhirQuestionnaireItem['enableWhen'],
    enableBehavior: 'all' as FhirQuestionnaireItem['enableBehavior'],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  }

  const heading = createFhirQuestionnaireItem({
    linkId: 'patientAddressHeading',
    type: 'display',
    text: 'Address',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const unit = createFhirQuestionnaireItem({
    linkId: 'patientUnit',
    type: 'string',
    text: 'Unit Number',
    extension: [presetFhirGridExt({ xs: 12, sm: 3 })],
    code: [createFhirCodingEntityPatient({ code: 'address.unit' })],
  })

  const streetNumberReq = createFhirQuestionnaireItem({
    linkId: 'patientStreetNumber',
    type: 'string',
    text: 'Street Number',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 3 })],
    code: [createFhirCodingEntityPatient({ code: 'address.streetNumber' })],
  })

  const streetNameReq = createFhirQuestionnaireItem({
    linkId: 'patientStreetName',
    type: 'string',
    text: 'Street Name',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.streetName' })],
  })

  const countryReq = createFhirQuestionnaireItem({
    linkId: 'patientCountry',
    type: 'choice',
    text: 'Country',
    answerOption: countryValueSet('CA'),
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.country' })],
  })

  const cityReq = createFhirQuestionnaireItem({
    linkId: 'patientCity',
    type: 'string',
    text: 'City',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.city' })],
  })

  const canadaProvince = createFhirQuestionnaireItem({
    linkId: 'patientCanadaProvince',
    type: 'choice',
    text: 'Province',
    required: true,
    answerOption: provinceValueSet(),
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.province' })],
    enableWhen: [
      {
        question: 'patientCountry',
        operator: '=',
        answerString: 'CA',
      },
    ],
    enableBehavior: 'all',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const provinceReq = createFhirQuestionnaireItem({
    linkId: 'patientProvince',
    type: 'string',
    text: 'Province/State',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.province' })],
    enableWhen: [
      {
        question: 'patientCountry',
        operator: '!=',
        answerString: 'CA',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const postalCodeReq = createFhirQuestionnaireItem({
    linkId: 'patientPostalCode',
    type: 'string',
    text: 'Postal Code/Zip Code',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.postalCode' })],
  })

  const hasPOBox = createFhirQuestionnaireItem({
    linkId: 'patientHasPOBox',
    type: 'boolean',
    text: 'I have a PO box',
  })

  const poBox = createFhirQuestionnaireItem({
    linkId: 'patientPOBox',
    type: 'string',
    text: 'PO Box',
    required: true,
    ...renderWhenHasPoBox,
    code: [createFhirCodingEntityPatient({ code: 'address.poBox' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientInformationPage',
    type: 'group',
    item: [
      heading,
      enabledWhenNoPoBox([
        unit,
        streetNumberReq,
        streetNameReq,
        countryReq,
        cityReq,
        provinceReq,
        canadaProvince,
        postalCodeReq,
      ]),
      hasPOBox,
      poBox,
    ],
  })
}

export const patientContactDetails = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsHeading',
    type: 'display',
    text: 'Contact Details',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const subheading1 = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsSubheading1',
    type: 'display',
    text: 'Please enter the details of the primary person that will be receiving the notification to book and any updates about appointments they have booked.',
  })

  const subheading2 = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsSubheading2',
    type: 'display',
    text: 'Please enter at least one phone number we can reach you at.',
  })

  const postDesc = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPostDesc',
    type: 'display',
    text: 'Please ensure that you have provided the correct email address and mobile phone number. We will use the email address provided to send you appointment updates and reminders, regarding yourself and any dependent(s) individuals you have booked on behalf of. Note that the emails will contain the most comprehensive information about your appointment.',
  })

  const email = createFhirQuestionnaireItem({
    linkId: 'patientEmail',
    type: 'string',
    text: 'Email Address',
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isEmail' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Invalid email address' },
        ],
      }),
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'email' })],
    },
    required: true,
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'email' })],
  })

  const home = createFhirQuestionnaireItem({
    linkId: 'patientHome',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    text: 'Home Phone Number',
    extension: [
      presetFhirGridExt({ sm: 8 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Invalid phone number' },
        ],
      }),
    ],
    enableWhen: [
      {
        question: 'patientContactType',
        operator: '=',
        answerString: 'home',
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    required: true,
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'phone.home' })],
  })

  const cell = createFhirQuestionnaireItem({
    linkId: 'patientCell',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    text: 'Mobile Phone Number',
    extension: [
      presetFhirGridExt({ sm: 8 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Invalid phone number' },
        ],
      }),
    ],
    enableWhen: [
      {
        question: 'patientContactType',
        operator: '=',
        answerString: 'cell',
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    required: true,
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'phone.cell' })],
  })

  const phoneType = createFhirQuestionnaireItem({
    linkId: 'patientContactType',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'cell',
          display: 'Cell',
        },
        initialSelected: true,
      },
      {
        valueCoding: {
          code: 'home',
          display: 'Home',
        },
      },
    ],
    required: true,
    extension: [presetFhirGridExt({ sm: 4 })],
  })

  const emailNotificationConsent = createFhirQuestionnaireItem({
    linkId: 'patientEmailNotificationConsent',
    type: 'boolean',
    text: 'I would like to receive future notifications and communications (email and text messages) regarding other services offered by Loblaws Inc., Shoppers Drug Mart Inc., and/or affiliates that I may benefit from. I understand that I can unsubscribe at any time.',
    code: [
      createFhirCodingEntityPatient({
        code: 'patientConsent.caslEmailConsent',
      }),
      createFhirCodingEntityPatient({ code: 'patientConsent.caslSmsConsent' }),
    ],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [
      heading,
      subheading1,
      subheading2,
      email,
      home,
      cell,
      phoneType,
      postDesc,
      emailNotificationConsent,
    ],
  })
}

export const fhirContactInformationQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'contactInformation',
  name: 'Contact Information',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'contactInformationPage',
      type: 'group',
      item: [patientInformation(), patientAddress(), patientContactDetails()],
    }),
  ],
})

export const fhirContactInformationSdmStrepTestJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirContactInformationQuestionnaire,
  metadata: {
    id: 'fhirContactInformationSdmStrepTestJson',
    name: 'Contact Information',
    path: 'contact-information',
    showOnSteps: true,
  },
}
