import ReactDOM from 'react-dom'
import { useBookingContext } from '../../contexts/NewBookingContext'

export const DetailsWidgetItem: React.FC<{ type: 'mobile' | 'desktop' }> = ({
  children,
  type,
}) => {
  const {
    detailsWidget: { desktopRef, mobileRef },
  } = useBookingContext()

  const ref = type === 'mobile' ? mobileRef : desktopRef

  if (!ref?.current) return null

  return ReactDOM.createPortal(children, ref.current, 'DetailsWidgetItem')
}
