import { JsonFormStep } from './types'

export const pendingRescheduleLanding: JsonFormStep = {
  type: 'preset',
  preset: 'pendingRescheduleLanding',
  metadata: {
    id: 'pendingRescheduleLanding',
    name: 'Pending Reschedule',
    path: 'pending-reschedule',
    showOnSteps: false,
  },
}
