import { TSFixMe } from '~/types/commonTypes'

declare const InstallTrigger: TSFixMe

// Opera 8.0+
export const isOpera =
  // @ts-ignore
  (!!window.opr && !!opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined'

// Safari 3.0+ "[object HTMLElementexport constructor]"
export const isSafari =
  /export constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(
    !window.safari ||
      // @ts-ignore
      (typeof safari !== 'undefined' && window.safari.pushNotification)
  )

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || !!document.documentMode

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia

// Chrome 1 - 79
export const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

// Edge (based on chromium) detection
export const isEdgeChromium = navigator.userAgent.indexOf('Edg') !== -1

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS
