import * as React from 'react'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import {
  EAppointmentTypeMethod,
  useGetAppointmentActivityLazyQuery,
} from '~/graphql/types/schemaNode.type'
import { createAppointmentActivityInstance } from '~/lib/appointmentActivity'
import { useAppointmentContext, usePharmacyContext } from '../../..'
import { useAppointmentActivityContext } from '../../../AppointmentActivityContext'

import { useLanguageForPatient } from '~/hooks/useLanguageForPatient'

export const useSelectAppointmentActivity = () => {
  const { languageForFHIR } = useLanguageForPatient()

  const [query] = useGetAppointmentActivityLazyQuery({
    context: {
      clientName: EServer.NODE,
      headers: {
        'accept-language': languageForFHIR,
      },
    },
  })

  const { setAppointmentActivity, scope, updateScope } =
    useAppointmentActivityContext()
  const { initialPharmacy, resetPharmacy } = usePharmacyContext()
  const { updateAppointment } = useAppointmentContext()

  const selectAppointmentActivityAsync = React.useCallback(
    async ({
      id,
      regionCode,
      pharmacyId,
      countryCode = 'CA',
      method,
    }: {
      id: string
      regionCode: string
      pharmacyId?: string
      countryCode?: string
      method?: EAppointmentTypeMethod
    }) => {
      const { data, error } = await query({
        variables: { id, regionCode, locationId: pharmacyId, countryCode },
      })
      if (error) {
        return {
          error: error.message,
          appointmentActivity: createAppointmentActivityInstance(),
        }
      }
      const newAA = createAppointmentActivityInstance(
        data?.appointmentActivityInstance
      )

      setAppointmentActivity(newAA)
      updateScope({
        locationId: pharmacyId,
        regionCode: regionCode,
        countryCode: countryCode,
      })
      updateAppointment({ method })
      return {
        error: '',
        appointmentActivity: newAA,
      }
    },
    [setAppointmentActivity, updateScope, query, updateAppointment]
  )

  const resetAppointmentActivity = React.useCallback(async () => {
    const newScope = {
      ...scope,
      locationId: initialPharmacy.id,
    }
    updateScope(newScope)
  }, [resetPharmacy, updateAppointment, updateScope])
  return {
    selectAppointmentActivityAsync,
    resetAppointmentActivity,
  }
}
