import styled from 'styled-components'
import { FormControlLabel, Grid } from '@material-ui/core'

export const Layout = styled(FormControlLabel)`
  width: fit-content;
  margin-left: 0px;
  margin-right: 0px;
`
export const RadioButtonLayout = styled(Grid)`
  display: flex;
  flex-direction: column nowrap;
  justify-content: flex-start;
  width: 2.5rem;
`
export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: inherit; // inherit property from parent (wrap if parent = mui components; nowrap if parent = hardcoded Layout)
`
