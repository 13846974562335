import React, { useCallback, useMemo, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import xPharmacyIds from '~/xPharmacyIds'
import { DownloadButton, MainComponentHeader } from '../debugger.styles'
import { downloadCSV } from '../helpers'
import xTenantIds from '~/xTenantIds'

type Tenant = {
  tenantId?: string
  pharmacyId?: string
}

export const Tenants = () => {
  const [searchString, setSearchString] = useState<string>('')
  const tenantsMap = useMemo(() => {
    return Object.keys(xTenantIds).reduce((acc, key) => {
      acc[key] = {
        tenantId: xTenantIds[key],
        pharmacyId: xPharmacyIds[`${key}-pharmacyId`],
      }
      return acc
    }, {} as Record<string, Tenant>)
  }, [])
  const [tenants, setTenants] = useState<Record<string, Tenant>>(tenantsMap)

  const searchTenant = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value)

      const filteredTenants: Record<string, Tenant> = {}
      Object.keys(tenantsMap).forEach((key) => {
        if (key.includes(e.target.value)) {
          filteredTenants[key] = tenantsMap[key]
        }
      })
      setTenants(filteredTenants)
    },
    [setSearchString, tenantsMap, setTenants]
  )

  const exportCsv = useCallback(() => {
    downloadCSV(
      ['Domain', 'Tenant Id', 'Pharmacy Id'],
      Object.keys(tenants).map((key) => {
        return [key, tenants[key].tenantId ?? '', tenants[key].pharmacyId ?? '']
      })
    )
  }, [tenants])

  return (
    <Box>
      <MainComponentHeader>
        <TextField
          value={searchString}
          placeholder="Search..."
          onInput={searchTenant}
        />
        <DownloadButton variant="outlined" onClick={exportCsv}>
          Export as CSV
        </DownloadButton>
      </MainComponentHeader>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell align="right">Tenant Id</TableCell>
              <TableCell align="right">Pharmacy Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(tenants).map((key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right">{tenants[key].tenantId}</TableCell>
                <TableCell align="right">{tenants[key].pharmacyId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
