import { ConditionProperties } from 'json-rules-engine'
import {
  ExtensionValidationCondition,
  validExtensionValidationValueKeys,
} from '~/lib/fhir/extensions/validation'

export interface RuleCondition extends ConditionProperties {
  fact: string
  operator: string
  value: unknown
}

type CreateRuleConditionSignature = (fact: string) => RuleCondition

export const toCreateRuleConditionFromValidationCondition = (
  extension: ExtensionValidationCondition
): CreateRuleConditionSignature => {
  const { operator, value } = extension.extension.reduce<
    Partial<RuleCondition>
  >((acc, ext) => {
    switch (ext.url) {
      case 'isPath': {
        if (ext.valueBoolean === true) {
          throw new Error(
            'TODO: need to implement path traversal for validation'
          )
        }
        break
      }
      case 'operator': {
        acc.operator = ext.valueCode
        break
      }
      case 'value': {
        const value = validExtensionValidationValueKeys.reduce<
          string | number | boolean | undefined
        >((acc, key) => {
          if (ext[key] !== undefined) {
            if (acc === undefined) {
              acc = ext[key]
            } else {
              throw new Error(
                'toCreateRuleConditionFromValidationCondition: multiple values found in value extension'
              )
            }
          }
          return acc
        }, undefined)

        if (value === undefined) {
          throw new Error(
            'toCreateRuleConditionFromValidationCondition: no value found in value extension'
          )
        }
        acc.value = value
        break
      }
      default:
        break
    }
    return acc
  }, {})

  if (operator !== undefined && value !== undefined) {
    const fn: CreateRuleConditionSignature = (fact) => ({
      fact: fact,
      operator: operator,
      value: value,
    })
    return fn
  }
  throw new Error('missing property value in when converting to RuleCondition')
}
