import React, { ComponentType, lazy } from 'react'
import { MonitoredRoute } from '~/tools/monitoring'

const GeneralWaitlistList = lazy(
  () => import('../pages/pharmacistSide/generalWaitlistList')
)
const CovidVaccineWaitlist = lazy(
  () => import('../pages/pharmacistSide/covidVaccineWaitlist')
)
const CovidVaccineDoseTwoWaitlist = lazy(
  () => import('../pages/pharmacistSide/covidVaccineDoseTwoWaitlist')
)
const CovidVaccineDoseThreeWaitlist = lazy(
  () => import('../pages/pharmacistSide/covidVaccineDoseThreeWaitlist')
)
const FluShotWaitlist = lazy(
  () => import('../pages/pharmacistSide/fluShotWaitlist')
)

interface RouteConfig {
  path: string
  Component: ComponentType<any>
  props?: { [key: string]: unknown }
}

const WAITLIST_ROUTE_TO_COMPONENT_MAPPINGS: RouteConfig[] = [
  {
    path: '/pharmacist/generalWaitlistList',
    Component: GeneralWaitlistList,
  },
  {
    path: '/pharmacist/covidVaccineWaitlist',
    Component: CovidVaccineWaitlist,
  },
  {
    path: '/pharmacist/covidVaccineDoseTwoWaitlist',
    Component: CovidVaccineDoseTwoWaitlist,
  },
  {
    path: '/pharmacist/covidVaccineDoseThreeWaitlist',
    Component: CovidVaccineDoseThreeWaitlist,
  },
  {
    path: '/pharmacist/fluShotWaitlist',
    Component: FluShotWaitlist,
    props: { subCategory: 'FLU_REGULAR', key: 'FLU_REGULAR' },
  },
  {
    path: '/pharmacist/fluShotHighDoseWaitlist',
    Component: FluShotWaitlist,
    props: { subCategory: 'FLU_HIGH_DOSE', key: 'FLU_HIGH_DOSE' },
  },
  {
    path: '/pharmacist/fluMistWaitlist',
    Component: FluShotWaitlist,
    props: { subCategory: 'FLU_MIST', key: 'FLU_MIST' },
  },
]

const allRoutes = WAITLIST_ROUTE_TO_COMPONENT_MAPPINGS.map(({ path }) => path)

export const PharmacistWaitlistRoutes = ({
  isDisabled,
}: {
  isDisabled: boolean
}) => {
  if (isDisabled) return null

  return (
    <MonitoredRoute path={allRoutes}>
      {WAITLIST_ROUTE_TO_COMPONENT_MAPPINGS.map(
        ({ path, Component, props }) => (
          <MonitoredRoute path={path} key={path}>
            <Component {...props} />
          </MonitoredRoute>
        )
      )}
    </MonitoredRoute>
  )
}
