import * as React from 'react'
import { usePublicGetPharmacyLazyQuery } from '~/graphql/types/schema.type'
import { createPharmacy } from '~/lib/pharmacy'

export const useSelectPharmacy = (dispatch: React.Dispatch<any>) => {
  const [query] = usePublicGetPharmacyLazyQuery()
  const selectPharmacyAsync = React.useCallback(
    async (id: string) => {
      const createError = (message?: string | null) => {
        return {
          pharmacy: createPharmacy(),
          error: message ?? '',
        }
      }

      if (id === '') {
        return createError('selectPharmacy: id is empty')
      }

      const currentId = localStorage.getItem('pharmacyId') ?? ''
      localStorage.setItem('pharmacyId', id)
      const { data, error } = await query({
        context: {
          pharmacyId: id,
        },
      })

      if (error) {
        localStorage.setItem('pharmacyId', currentId)
        return createError(`selectPharmacy: ${error.message}`)
      }

      const pharmacy = createPharmacy(data?.getPharmacy ?? {})

      dispatch({
        type: 'setPharmacy',
        pharmacy: pharmacy,
      })

      return {
        pharmacy: pharmacy,
        error: '',
      }
    },
    [query]
  )

  return {
    selectPharmacyAsync,
  }
}
