import { createPresetFormStep } from '..'
import { StoreTimeslotsList } from '~/pages/AppointmentIntake/forms'

export const storeTimeslotSelectorFormStep: typeof createPresetFormStep = (
  json
) => {
  return {
    Component: StoreTimeslotsList,
    metadata: json.metadata,
    enableWhen: (state) => {
      return !['documentationLink'].includes(state.router.intakeType)
    },
    isAutoSkippable: async () => false,
  }
}
