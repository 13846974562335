import { Extension } from 'fhir/r4'
import { fhirStructDefUrlMedMe } from '../constants'

const extPathName = 'fhirExtHideWhenDisabled'

export const fhirExtHideWhenDisabledUrl =
  `${fhirStructDefUrlMedMe}${extPathName}` as const

export const isFhirExtHideWhenDisabled = (
  fhir?: Extension
): fhir is FhirExtHideWhenDisabled => {
  return fhir?.url === fhirExtHideWhenDisabledUrl
}

export const createFhirExtHideWhenDisabled = (
  fhir?: Partial<Extension>
): FhirExtHideWhenDisabled => {
  return {
    url: fhirExtHideWhenDisabledUrl,
    valueBoolean: fhir?.valueBoolean ?? false,
  }
}

export type FhirExtHideWhenDisabled = {
  url: typeof fhirExtHideWhenDisabledUrl
  valueBoolean: boolean
}
