import React from 'react'
import { EAppointmentTypeMethod } from '~/graphql/types/schemaNode.type'
import { MethodsDropDownMenu as LegacyMethodsDropDownMenu } from '~/pages/AppointmentIntake/components/AaiCard/MethodsDropDownMenu/MethodsDropDownMenu'
import { MethodsDropDownMenu as NewMethodsDropDownMenu } from '~/pages/AppointmentIntake/NewBooking/ServiceSelection/components/AaiCard/MethodsDropDownMenu/MethodsDropDownMenu'

interface AppointmentActivityCardMethodsDropDownProps {
  isLegacy?: boolean
  enabledMethods: string[]
  appointmentMethod?: EAppointmentTypeMethod
}

export const AppointmentActivityCardMethodsDropDown = (
  props: AppointmentActivityCardMethodsDropDownProps
) => {
  const { isLegacy, enabledMethods, appointmentMethod } = props

  if (isLegacy)
    return (
      <LegacyMethodsDropDownMenu
        enabledMethods={enabledMethods}
        appointmentMethod={appointmentMethod}
      />
    )

  return (
    <NewMethodsDropDownMenu
      enabledMethods={enabledMethods}
      appointmentMethod={appointmentMethod}
    />
  )
}
