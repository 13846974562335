import React from 'react'
import { useInitializeAuthClient } from './hooks'
import { identityProviderClient, IIdentityProviderClient } from '../utils'
import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'

interface AuthContextValues {
  identityProviderClient: IIdentityProviderClient
}

const AuthContext = React.createContext<AuthContextValues | null>(null)

export const useAuthContext = () => {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuthContext must be used within an AuthContextProvider')
  }

  return { identityProviderClient }
}

type AuthProviderProps = {
  children: React.ReactNode
  loader?: React.ReactNode
}

export const AuthContextProvider = ({
  children,
  loader = null,
}: AuthProviderProps) => {
  const { loading, error } = useInitializeAuthClient({
    client: identityProviderClient,
  })

  if (loading) {
    return <>{loader}</>
  }

  if (error) {
    throw new Error('Cannot load IDP integrations')
  }

  return (
    <AuthContext.Provider value={{ identityProviderClient }}>
      {children}
    </AuthContext.Provider>
  )
}

export const AuthContextProviderFeatureGated = ({
  children,
  loader,
}: AuthProviderProps) => {
  const isIdpIntegrationEnabled = useFeatureFlag(
    FeatureFlag.ENABLE_FETCH_IDP_INTEGRATIONS,
    false
  )

  if (isIdpIntegrationEnabled) {
    return <AuthContextProvider loader={loader}>{children}</AuthContextProvider>
  }

  return <>{children}</>
}
