import { createTheme } from '../Theme'

export const createWhiteLabel = (data?: any): WhiteLabel => {
  const path = process.env.REACT_APP_S3_BASE_URL ?? ''
  return {
    theme: createTheme(data?.theme),
    logoCircle: `${path}${String(data?.logoCircle ?? '')}`,
    logoLong: `${path}${String(data?.logoLong ?? '/medme/long.png')}`,
    logoLongInverse: `${path}${String(data?.logoLongInverse ?? '')}`,
  }
}

export type WhiteLabel = {
  theme: ReturnType<typeof createTheme>
  logoCircle: string
  logoLong: string
  logoLongInverse: string
}
