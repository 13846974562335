const initialState = {
  pharmacistId: null,
  walkInAppointmentsFilters: {
    statuses: ['ARRIVED', 'NOTIFIED'],
  },
}

const UPDATE_DASHBOARD_PHARMACIST_ID = 'UPDATE_DASHBOARD_PHARMACIST_ID'
const CLEAR_DASHBOARD_PHARMACIST_ID = 'CLEAR_DASHBOARD_PHARMACIST_ID'
const UPDATE_WALKIN_APPOINTMENT_STATUSES = 'UPDATE_WALKIN_APPOINTMENT_STATUSES'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_PHARMACIST_ID:
      return {
        ...state,
        pharmacistId: action.pharmacistId,
      }
    case CLEAR_DASHBOARD_PHARMACIST_ID:
      return {
        ...state,
        pharmacistId: null,
      }
    case UPDATE_WALKIN_APPOINTMENT_STATUSES:
      return {
        ...state,
        walkInAppointmentsFilters: {
          ...state.walkInAppointmentsFilters,
          statuses: action.statuses,
        },
      }
    default:
      return state
  }
}

export const updatePharmacistId = (pharmacistId) => ({
  type: UPDATE_DASHBOARD_PHARMACIST_ID,
  pharmacistId,
})

export const clearDashboardPharmacistId = () => ({
  type: CLEAR_DASHBOARD_PHARMACIST_ID,
})

export const updateWalkInAppointmentStatuses = (statuses) => ({
  type: UPDATE_WALKIN_APPOINTMENT_STATUSES,
  statuses,
})

export default reducer
