import axios from 'axios'

export const gotoImdLink = (pharmacy) => {
  if (pharmacy.enterprise === 'NWC') {
    const OAUTH_TOKEN_DATA = {
      grant_type: 'client_credentials',
      scope: 'sso',
    }
    const base64Encoded = window.btoa(
      `${process.env.IMD_CLIENT_ID}:${process.env.IMD_CLIENT_SECRET}`
    )

    const OAUTH_TOKEN_HEADERS = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${base64Encoded}`,
      },
    }

    axios
      .post(
        'https://ca.api.imdhealth.com/oauth/token',
        OAUTH_TOKEN_DATA,
        OAUTH_TOKEN_HEADERS
      )
      .then((response) => {
        const { data } = response

        const SSO_HEADERS = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data.access_token}`,
          },
        }

        const SSO_DATA = {
          user: {
            external_id: pharmacy.id,
            practitioner_type: 'Physician',
          },
        }

        return axios.post(
          'https://ca.api.imdhealth.com/v3/sso',
          SSO_DATA,
          SSO_HEADERS
        )
      })
      .then((response) => {
        const { data } = response
        window.open(data.imd_url, '_blank')
      })
      .catch((error) => console.error(error))
  } else {
    window.open(
      `https://app.imdhealth.com/sso?app_id=MedMe&access_token=w2hdJ8lHi_Fh2aOrdp9NfhzdRZ-R8jbTsj7CdX3OMNI&clinician_type=Pharmacy&language=en&user_id=${pharmacy.id}`,
      '_blank'
    )
  }

  return null
}
