import R5 from 'fhir/r5'

interface RequiredSystemCoding extends R5.ValueSetComposeInclude {
  system: string
  version: string
  concept: {
    code: string
    display: string
  }[]
}

class FhirValueSetCompose implements R5.ValueSetCompose {
  id?: string | undefined
  include: RequiredSystemCoding[]

  constructor(compose: R5.ValueSetCompose) {
    this.id = compose.id
    this.include = compose.include?.map((valueSetCoding) => {
      const { system, version, concept } = valueSetCoding
      if (!system || !version || !concept || concept.length === 0) {
        throw new Error('FhirValueSetCompose missing a required property')
      }
      return {
        system,
        version,
        concept: concept.map((concept) => {
          const { code, display } = concept
          if (!code) {
            throw new Error('code is required in RequiredValueSetCoding')
          }
          return {
            code: code,
            display: display || code,
          }
        }),
      }
    })
  }
}

/**
 * We are mainly using valueSets for their codes right now, so we will be more strict
 * with which fields are required in compose.
 */
export class FhirValueSet implements R5.ValueSet {
  id?: string | undefined
  url: string
  language?: string | undefined
  resourceType: 'ValueSet'
  status: 'draft' | 'active' | 'retired' | 'unknown'
  compose: FhirValueSetCompose

  constructor(valueSet: R5.ValueSet) {
    if (!valueSet.compose || !valueSet.url) {
      throw new Error('compose is required for FhirValueSet')
    }
    this.id = valueSet.id
    this.url = valueSet.url
    this.language = valueSet.language
    this.resourceType = valueSet.resourceType
    this.status = valueSet.status
    this.compose = new FhirValueSetCompose(valueSet.compose)
  }

  /**
   * Return a list of Codings from this value set
   */
  getCodings(): Required<
    Pick<R5.Coding, 'system' | 'version' | 'code' | 'display'>
  >[] {
    return this.compose.include.reduce<
      Required<Pick<R5.Coding, 'system' | 'version' | 'code' | 'display'>>[]
    >((acc, compose) => {
      compose.concept.forEach((concept) => {
        acc.push({
          system: compose.system,
          version: compose.version,
          code: concept.code,
          display: concept.display,
        })
      })
      return acc
    }, [])
  }
}
