import {
  type PatientUpdatePatientAndUpdateAppointmentMutation,
  usePatientUpdatePatientAndUpdateAppointmentMutation,
  EAppointmentStatus,
} from '~/graphql/types/schema.type'

import { createAppointment } from '~/lib/appointment'
import { createPatient } from '~/lib/patient'

import {
  useAppointmentContext,
  usePatientContext,
  useRouterContext,
} from '~/pages/AppointmentIntake/contexts'
import { useAnalytics } from '~/hooks/useAnalytics'

import { createIntakeAnswerInput } from '../createIntakeAnswerInput'
import { createIntakeSubmissionResult } from '../createIntakeSubmissionResult'
import { createPatientInput } from '../createPatientInput'

export const useUpdatePatientAndAppointment = () => {
  const {
    appointmentTypeWithCalendarToken,
    patientToken,
    appointmentToken,
    intakeType,
  } = useRouterContext()

  const { patient } = usePatientContext()
  const { appointment } = useAppointmentContext()
  const [mutation] = usePatientUpdatePatientAndUpdateAppointmentMutation()

  const { trackAnalyticsEvent } = useAnalytics()

  const updatePatientAndAppointment = async () => {
    if (intakeType !== 'documentationLink') {
      return
    }

    try {
      const isWaitlisted = appointment.status === EAppointmentStatus.WAITLIST
      const isWalkin = appointment.isWalkin ?? false

      const { data } = await mutation({
        variables: {
          key1: appointmentTypeWithCalendarToken,
          key2: patientToken,
          key3: appointmentToken,
          patientUpdatePatientAndIntakeAnswerInput: {
            patient: createPatientInput(patient),
            intakeAnswer: createIntakeAnswerInput(appointment),
          },
        },
        onCompleted: (
          _data: PatientUpdatePatientAndUpdateAppointmentMutation
        ) => {
          if (!isWaitlisted) {
            trackAnalyticsEvent('pt__ph_appt_booking_confirm__end', {
              ui_flow: 'hooks',
              booking_type: isWalkin ? 'walk-in' : 'scheduled',
            })
          }
        },
      })

      const updatedPatient = createPatient(
        data?.patientUpdatePatientAndUpdateAppointment
      )

      const updatedAppointment = createAppointment(
        data?.patientUpdatePatientAndUpdateAppointment?.appointments?.filter(
          (resAppointment) => resAppointment?.id === appointment.id
        )?.[0]
      )

      return createIntakeSubmissionResult({
        patient: updatedPatient,
        appointment: updatedAppointment,
      })
    } catch (error) {
      const timeslotTaken =
        error?.graphQLErrors?.[0]?.extensions?.errorCode === '0038'

      return createIntakeSubmissionResult({
        error: String(error),
        timeslotTaken,
      })
    }
  }

  return {
    updatePatientAndAppointment,
  }
}
