import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirCodingEntityPatient } from '~/pages/AppointmentIntake/lib/fhir/systems'
import { presetFhirGridExt } from '../../../../../../lib/fhir/extensions/fhirExtGrid/presetFhirGridExt'
import { provinceValueSet } from '../../../lib/provinceValueSet'
import { JsonFhirFormStep } from '../../../../types'

export const cardHeading = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Information',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Required fields (*)',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [heading, descReq],
  })
}

export const healthCard = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'healthCardHeading',
    type: 'display',
    text: 'Health Card',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const desc = createFhirQuestionnaireItem({
    linkId: 'healthCardDesc',
    type: 'display',
    text: 'Please enter only numeric characters from your Health Card. This is collected and used for provincial health plan billing purposes.',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const hcProvince = createFhirQuestionnaireItem({
    linkId: 'healthCardProvince',
    type: 'choice',
    text: 'Province',
    required: true,
    answerOption: [
      ...provinceValueSet('MB'),
      {
        valueCoding: {
          code: 'other',
          display: 'Other',
        },
      },
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    code: [createFhirCodingEntityPatient({ code: 'healthCard.province' })],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcNumber = createFhirQuestionnaireItem({
    linkId: 'healthCardNumber',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'numeric' })],
    },
    text: 'Health Card Number',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'healthCard.number' })],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 'auto',
      }),
    ],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcVersion = createFhirQuestionnaireItem({
    linkId: 'healthCardVersion',
    type: 'string',
    text: 'Version Code',
    code: [createFhirCodingEntityPatient({ code: 'healthCard.versionCode' })],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    enableBehavior: 'all',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const postOntarioDesc = createFhirQuestionnaireItem({
    linkId: 'postOntarioDesc',
    type: 'display',
    text: 'Version Code is the 2 letters after your 10-digit Health Card Number (Leave Blank if you have a red/white card)',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
    enableWhen: [
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    enableBehavior: 'any',
  })

  const noHealthCard = createFhirQuestionnaireItem({
    linkId: 'noHealthCard',
    type: 'boolean',
    text: "I don't have a health card",
  })

  const noHealthCardNotice = createFhirQuestionnaireItem({
    linkId: 'noHealthCardNotice',
    type: 'display',
    text: "If you don't have a valid Ontario Health Card (OHIP), Professional fees apply.",
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
    enableWhen: [
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [
      heading,
      desc,
      hcProvince,
      hcNumber,
      hcVersion,
      postOntarioDesc,
      noHealthCard,
      noHealthCardNotice,
    ],
  })
}

export const fhirMedicalInformationQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalInformationQuestionnaire',
  name: 'Medical Information',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalInformationPage',
      type: 'group',
      item: [cardHeading(), healthCard()],
    }),
  ],
})

export const fhirMedicalInformationSdmSmokingJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalInformationQuestionnaire,
  metadata: {
    id: 'fhirMedicalInformationSdmSmokingJson',
    name: 'Medical Information',
    path: 'medical-information',
    showOnSteps: true,
  },
}
