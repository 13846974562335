import * as React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getEnterpriseCode } from '~/util/enterpriseMethods'
import { getBooleanParam, getParam } from '../paramHelpers'
import {
  AppointmentIntakeRouterContext,
  IntakeType,
  RouterContext,
} from '../RouterContext'

type PossibleParams = {
  appointmentTypeWithCalendarToken?: string
  patientToken?: string
  appointmentToken?: string
  storeNo?: string
  activity?: string
  activityGroup?: string
  appointmentActivityId?: string
  formPath?: string
}

function getSearchParams(
  searchString: string
): AppointmentIntakeRouterContext['searchParams'] {
  const searchParams = new URLSearchParams(searchString)

  return {
    webview: getBooleanParam('webview', searchParams),
    province: getParam('province', searchParams) ?? '',
    appointmentType: getParam('appointmentType', searchParams) ?? '',
    global: getBooleanParam('global', searchParams),
    isWalkin: getBooleanParam('isWalkin', searchParams),
    language: getParam('language', searchParams) ?? '',
    key1: getParam('key1', searchParams) ?? '',
    key2: getParam('key2', searchParams) ?? '',
    key3: getParam('key3', searchParams) ?? '',
    service: getParam('service', searchParams) ?? '',
  }
}

export const RouterProvider: React.FC = ({ children }) => {
  const params = useParams<PossibleParams>()
  const location = useLocation()
  const [{ pathname, search }] = React.useState(() => ({
    hostname: window.location.hostname,
    pathname: location.pathname,
    search: location.search,
  }))

  // @todo fix state for this...
  const [
    {
      appointmentTypeWithCalendarToken,
      patientToken,
      appointmentToken,
      storeNo,
      activity,
      activityGroup,
      appointmentActivityId,
    },
  ] = React.useState({ ...params })

  const [intakeType] = React.useState<IntakeType>(() => {
    if (pathname.includes('/bookingLink')) return 'bookingLink'
    if (pathname.includes('/documentationLink')) return 'documentationLink'
    if (pathname.includes('/bookNextAppointmentLink'))
      return 'bookNextAppointmentLink'
    if (pathname.includes('/schedule')) return 'public'
    if (pathname.includes('/booking')) return 'public'
    // Context: intake type doesnt actually matter for the following case
    // It was added so that we can avoid throwing an error on refresh during booking flow for PRC2
    if (pathname.includes('/public/activity/appointment'))
      return 'bookingInProgress'
    throw new Error('unable to determine intake type')
  })

  const [enterpriseCode] = React.useState(() => getEnterpriseCode())
  const [searchParams, setSearchParams] = React.useState<
    AppointmentIntakeRouterContext['searchParams']
  >(getSearchParams(search))

  React.useEffect(() => {
    setSearchParams(getSearchParams(location.search))
  }, [location.search])

  const value: AppointmentIntakeRouterContext = {
    hostname: window.location.hostname,
    pathname,
    search: location.search,
    appointmentTypeWithCalendarToken: appointmentTypeWithCalendarToken ?? '',
    patientToken: patientToken ?? '',
    appointmentToken: appointmentToken ?? '',
    storeNo: storeNo ?? '',
    intakeType: intakeType,
    activity: activity ?? '',
    activityGroup: activityGroup ?? '',
    appointmentActivityId: appointmentActivityId ?? '',
    // TODO: To be removed when we move all group links to the new appointment activity system
    group: pathname.includes('/groups/minorAilments')
      ? 'minorAilments'
      : pathname.includes('/activity-groups/')
      ? activityGroup ?? ''
      : '',
    formPath: params.formPath ?? '',
    enterpriseCode,
    searchParams: searchParams,
  }

  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  )
}
