import * as React from 'react'
import { createPharmacy } from '~/lib/pharmacy'
import { useInitialEnterprisePharmacy } from './useInitialEnterprisePharmacy'
import { useInitialIndiePharmacy } from './useInitialIndiePharmacy'
import { useRouterContext } from '../../../RouterContext'

export const useInitialPharmacy = () => {
  const { storeNo, enterpriseCode } = useRouterContext()
  const [pharmacy, setPharmacy] = React.useState(createPharmacy())
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState('')

  const {
    pharmacy: ePharmacy,
    loading: eLoading,
    error: eError,
  } = useInitialEnterprisePharmacy(enterpriseCode, storeNo)

  const {
    pharmacy: iPharmacy,
    loading: iLoading,
    error: iError,
  } = useInitialIndiePharmacy(enterpriseCode)

  React.useEffect(() => {
    if (!eLoading && !iLoading) {
      if (Boolean(eError) || Boolean(iError)) {
        setError(eError || iError)
      } else {
        setPharmacy(enterpriseCode ? ePharmacy : iPharmacy)
      }
      setLoading(false)
    }
  }, [eLoading, iLoading])

  return {
    pharmacy,
    loading,
    error,
  }
}
