import { createFhirInputItem } from '..'

export const createFhirIntegerItem: typeof createFhirInputItem = (json) => {
  if (json.type !== 'integer')
    throw new Error('createFhirDisplayItem: json.item !== "integer"')

  return {
    type: 'integer',
  }
}
