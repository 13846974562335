import { createPresetFormStep } from '..'
import { Completion } from '~/pages/AppointmentIntake/forms'
import {
  canFillOutDocumentation,
  isAppointmentStatusCompleteLike,
} from '~/lib/appointment'

export const completionFormStep: typeof createPresetFormStep = (json) => {
  return {
    Component: Completion,
    metadata: json.metadata,
    condition: (state) => {
      const appointment = state.initial.appointment
      if (state.router.intakeType == 'documentationLink') {
        return !canFillOutDocumentation(appointment)
      }

      return isAppointmentStatusCompleteLike(appointment)
    },
    isAutoSkippable: async () => false,
  }
}
