import * as React from 'react'
import { BookingContextInterface } from '../../BookingContext'
import {
  useCancelReservedTimeslotMutation,
  useReserveTimeslotMutation,
} from '~/graphql/types/schema.type'
import { Timeslot } from '~/lib/timeslot'
import { useAppointmentContext } from '../../..'

export const useReserveTimeslot = (
  reservationId: BookingContextInterface['reservationId'],
  setReservationId: BookingContextInterface['setReservationId']
) => {
  const [mutation] = useReserveTimeslotMutation()
  const [clearMutation] = useCancelReservedTimeslotMutation()
  const { initialAppointment, updateAppointment } = useAppointmentContext()

  const reserveTimeslotAsync = React.useCallback(
    async (
      pharmacyId: string,
      appointmentTypeId: string,
      timeslot: Timeslot
    ) => {
      try {
        const { data } = await mutation({
          variables: {
            pharmacyId: pharmacyId,
            reserveAppointmentInput: {
              appointmentTypeId: Number(appointmentTypeId),
              startDateTime: timeslot.startDateTime,
              endDateTime: timeslot.endDateTime,
              resourceId: timeslot.resourceId || undefined,
            },
          },
        })

        const reservationId = data?.reserveAppointmentV1

        if (!reservationId) {
          throw new Error('No reservation ID returned')
        }
        setReservationId(reservationId)
        updateAppointment({
          startDateTime: timeslot.startDateTime,
          endDateTime: timeslot.endDateTime,
        })
        return {
          reservationId: reservationId,
          error: '',
        }
      } catch (e) {
        return {
          reservationId: '',
          error: String(e),
        }
      }
    },
    [mutation, setReservationId]
  )

  const clearReservation = React.useCallback(() => {
    if (reservationId) {
      clearMutation({
        variables: { reservationId },
      }).catch((e) => {
        console.log('failed to cancel but we continue', e)
      })
      setReservationId('')
    }

    updateAppointment({
      startDateTime: initialAppointment.startDateTime,
      endDateTime: initialAppointment.endDateTime,
    })
  }, [reservationId])
  return { reserveTimeslotAsync, clearReservation }
}
