import { AppointmentActivityInstance } from '~/lib/appointmentActivity'

export const useAAIMetadata = ({
  aai,
}: {
  aai: AppointmentActivityInstance
}) => {
  if (!aai || !aai.details || !aai.settings) return null

  const { description, subDescription, appointmentActivityId } = aai
  const {
    title,
    subtitle,
    badges,
    description: descriptionFromDetails,
    subDescription: subDescriptionFromDetails,
  } = aai.details
  const { iconUrl, enabledMethods } = aai.settings

  const definedDescription = description.length
    ? description
    : descriptionFromDetails
  const definedSubDescription = subDescription.length
    ? subDescription
    : subDescriptionFromDetails

  const result = {
    appointmentActivityId,
    title,
    subtitle,
    badges,
    iconUrl,
    enabledMethods,
    description: definedDescription,
    subDescription: definedSubDescription,
  }

  return result
}
