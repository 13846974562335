import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Eligibility Screening ',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const completingQuestions = createFhirQuestionnaireItem({
    text: 'If completing on behalf of someone else, answer from their perspective.',
    linkId: 'completingQuestions',
    type: 'display',
  })

  const glaucoma = createFhirQuestionnaireItem({
    text: 'Have you ever been diagnosed with glaucoma?',
    linkId: 'glaucoma',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const visionLoss = createFhirQuestionnaireItem({
    text: 'Do you have pain and loss of vision (not just blurry vision)?',
    linkId: 'visionLoss',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const headache = createFhirQuestionnaireItem({
    text: 'Do you have a severe headache?',
    linkId: 'headache',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const blisters = createFhirQuestionnaireItem({
    text: 'Do you have small blisters on your eyelids or on the skin around your eye(s)?',
    linkId: 'blisters',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const foreignBody = createFhirQuestionnaireItem({
    text: 'Do you have any reason to believe there is a foreign object in your eye?',
    linkId: 'foreignBody',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      glaucoma,
      visionLoss,
      headache,
      blisters,
      foreignBody,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningPinkEyeIDJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['ID'],
    isEnterprise: false,
    appointmentActivity: {
      urlParams: ['PinkEye', 'pinkeye-treatment'],
    },
  },
  metadata: {
    id: 'fhirMedicalScreeningPinkEyeIDJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
