import { Appointment, createAppointment } from '~/lib/appointment'
import { Patient, createPatient } from '~/lib/patient'

export type IntakeSubmissionResult = {
  appointment: Appointment
  patient: Patient
  error: string
  timeslotTaken: boolean
}

export const createIntakeSubmissionResult = (
  data?: Partial<IntakeSubmissionResult>
): IntakeSubmissionResult => {
  return {
    appointment: data?.appointment ?? createAppointment(),
    patient: data?.patient ?? createPatient(),
    error: data?.error ?? '',
    timeslotTaken: data?.timeslotTaken ?? false,
  }
}
