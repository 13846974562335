import { JsonFormStep } from './types'

export const storeTimeslotSelector: JsonFormStep = {
  type: 'preset',
  preset: 'storeTimeslotSelector',
  metadata: {
    id: 'storeTimeslotSelector',
    name: 'Timeslot Selector',
    path: 'timeslot-selector',
    showOnSteps: false,
  },
}
