import React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

import {
  Card,
  TitleContainer,
  Title,
  Body,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import {
  MedicalInformationContainer,
  SectionHeader,
} from './MedicalInformation.style'

export const MedicalInformation = () => {
  const { t } = useMedMeTranslation('patient')
  const { state } = useBookingContext()
  const {
    patient: { healthCard: hc, primaryCareProvider: pcp, emergencyContact },
    appointmentActivity: { urlParam },
  } = state

  /**
   * Returns the correct primary care provider heading based on urlParam
   */
  const getPcpHeading = () => {
    switch (urlParam) {
      case 'poct-a1c':
        return 'Primary Care Provider'
      default:
        return t('reviewAndConfirm.familyDoctor')
    }
  }

  const cardTitle = t('reviewAndConfirm.medicalInformationTitle')
  const hcHeading = t('reviewAndConfirm.healthCard')
  const hcNumber = [hc.number, hc.versionCode].filter(Boolean).join(' ')
  const provinceDisplayName =
    hc.province === 'other' ? t('reviewAndConfirm.other') : hc.province
  const hcProvince = hc.province
    ? `${provinceDisplayName} ${t('reviewAndConfirm.healthCard')}`
    : ''
  const hcInfo = [hcNumber, hcProvince].filter(Boolean)

  const pcpHeading = getPcpHeading()
  const pcpName = [pcp.firstName, pcp.lastName].filter(Boolean).join(' ')
  const pcpInfo = [pcpName, pcp.phone].filter(Boolean)

  const emergencyContactHeading = t('reviewAndConfirm.emergencyContact')
  const emergencyContactName = [
    emergencyContact.firstName,
    emergencyContact.lastName,
  ]
    .filter(Boolean)
    .join(' ')
  const emergencyContactInfo = [
    emergencyContactName,
    emergencyContact.relationship,
    emergencyContact.phone,
  ].filter(Boolean)

  const allInfo = [...hcInfo, ...pcpInfo, ...emergencyContactInfo].filter(
    Boolean
  )
  if (allInfo.length === 0) return null

  return (
    <Card>
      <TitleContainer>
        <Title>{cardTitle}</Title>
      </TitleContainer>
      <MedicalInformationContainer>
        {hcInfo.length > 0 && (
          <div>
            <SectionHeader>{hcHeading}</SectionHeader>
            {hcInfo.map((info, index) => (
              <Body key={index}>{info}</Body>
            ))}
          </div>
        )}
        {pcpInfo.length > 0 && (
          <div>
            <SectionHeader>{pcpHeading}</SectionHeader>
            {pcpInfo.map((info, index) => (
              <Body key={index}>{info}</Body>
            ))}
          </div>
        )}
        {emergencyContactInfo.length > 0 && (
          <div>
            <SectionHeader>{emergencyContactHeading}</SectionHeader>
            {emergencyContactInfo.map((info, index) => (
              <Body key={index}>{info}</Body>
            ))}
          </div>
        )}
      </MedicalInformationContainer>
    </Card>
  )
}
