import * as React from 'react'
import { Pharmacy } from '~/lib/pharmacy'
import { useMapbox, Coordinate } from '../../hooks'
import { MapContainer } from './MapBox.style'

export type MapBoxProps = {
  pharmacyList: Pharmacy[]
  pharmacy: Pharmacy
  setPharmacy: (pharmacy: Pharmacy) => void
  coordinate: Coordinate | null
  setCoordinate: (coordinates: Coordinate) => void
}
export const MapBox: React.FC<MapBoxProps> = (props) => {
  const { mapRef } = useMapbox(props)
  return <MapContainer ref={mapRef} />
}
