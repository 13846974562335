import { GridProps } from '@material-ui/core'
import { Extension } from 'fhir/r4'
import { fhirStructDefUrlMedMe } from '../constants'

const extPathName = 'grid'
export const fhirExtGridUrl = `${fhirStructDefUrlMedMe}${extPathName}`

const FhirExtGridParam = ['xs', 'sm', 'md', 'lg', 'xl'] as const

export type FhirExtGridProps = Pick<GridProps, typeof FhirExtGridParam[number]>

export const isFhirExtGrid = (fhir: Extension): fhir is FhirExtGrid => {
  return fhir.url === fhirExtGridUrl
}

export const createFhirExtGrid = (fhir: Extension) => {
  const createParamExt = (ext: Extension) => {
    if (FhirExtGridParam.includes(ext.url as any)) {
      return {
        url: ext.url,
        valueInteger: ext.valueInteger,
        valueString: ext.valueString,
      }
    }
    throw new Error('createFhirExtGrid: ext.url not in params')
  }

  return {
    url: fhirExtGridUrl,
    extension: fhir.extension?.map(createParamExt) ?? [],
  }
}

export type FhirExtGrid = ReturnType<typeof createFhirExtGrid>

export const auto = (data: string | undefined) =>
  data === 'auto' ? true : undefined

export const createFhirExtGridProps = (
  fhir: Extension
): { [extPathName]: FhirExtGridProps | undefined } => {
  if (!isFhirExtGrid(fhir))
    return {
      [extPathName]: undefined,
    }

  return {
    [extPathName]:
      fhir.extension?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.url]: auto(cur.valueString) ?? cur.valueInteger,
        }),
        {}
      ) ?? {},
  }
}
