import * as React from 'react'
import useMediaHook from '~/hooks/mediaHook'

const getNumDays = (width: number) => {
  if (width > 875) return 7
  return Math.floor(width / 125)
}

export const useResponsive = () => {
  const [numDays, setNumDays] = React.useState(getNumDays(window.innerWidth))
  const deviceType: 'tablet' | 'desktop' = useMediaHook(
    ['(orientation: portrait)'],
    ['tablet'],
    'desktop'
  )

  React.useLayoutEffect(() => {
    const onResize = () => {
      const newNum = getNumDays(window.innerWidth)
      if (newNum !== numDays) {
        setNumDays(newNum)
      }
    }

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [numDays])

  return {
    numDays,
    deviceType,
  }
}
