import * as React from 'react'
import { Body } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { GrayListElement } from './markdown.style'
import { MarkdownComponent } from './MarkdownComponent'

interface MarkdownProps {
  children: string
}

export const FhirMarkdownComponent: React.FC<MarkdownProps> = ({
  children,
}) => {
  return (
    <Body>
      <MarkdownComponent liOverride={GrayListElement}>
        {children}
      </MarkdownComponent>
    </Body>
  )
}
