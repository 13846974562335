import * as React from 'react'
import { Grid } from '@material-ui/core'
import { FhirQuestionnaireItemComponent } from '../FhirQuestionnaireItemComponent'
import { FhirQuestionnaire } from '../../../../lib/fhir/resources'

export const FhirQuestionnaireComponent: React.FC<{
  questionnaire: FhirQuestionnaire
}> = React.memo(({ questionnaire }) => {
  return (
    <Grid container spacing={1}>
      {questionnaire.item?.map((item, i) => (
        <FhirQuestionnaireItemComponent
          questionnaireItem={item}
          questionnaire={questionnaire}
          key={i}
        />
      )) ?? null}
    </Grid>
  )
})
