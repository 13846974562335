import styled from 'styled-components'
import { Heading1 } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const Layout = styled.div`
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 2rem;
`

export const Title = Heading1

export const MapContainer = styled.div`
  width: 100%;
  height: 30vh;
  margin-bottom: 1rem;
`
