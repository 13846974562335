import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText'

interface ControlledSelectProps {
  name: string
  label: string
  control: Control<Record<string, any>>
  defaultValue: unknown
  children: React.ReactNode
  errors?: FieldErrors<Record<string, string>>
  renderValue?: (value: unknown) => React.ReactNode
  // All other props:
  [x: string]: any
}

export const ControlledSelect: React.FC<ControlledSelectProps> = ({
  name,
  label,
  control,
  defaultValue,
  children,
  rules,
  errors,
  renderValue,
  ...rest
}) => {
  const labelId = `${name}-label`
  return (
    <FormControl {...rest}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select labelId={labelId} label={label} renderValue={renderValue}>
            {children}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        error={errors && !!errors[name]}
      />
      {errors && errors[name]?.message && (
        <FormHelperText>{errors[name]?.message}</FormHelperText>
      )}
    </FormControl>
  )
}
