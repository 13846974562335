import { Box, Select, SvgIcon } from '@material-ui/core'
import styled from 'styled-components'
import { LanguageSelectorTheme } from './LanguageDropdown'
import { primary } from '~/sharedStyles/colorPalette'

export const Dropdown = styled(Select)<{
  theme: LanguageSelectorTheme
  sizeVariant?: 'regular' | 'large'
}>`
  color: ${(props) => {
    if (props.theme === 'light') return 'white'
    if (props.theme === 'primary') return primary
    return 'black'
  }};
  font-size: ${(props) => (props.sizeVariant === 'large' ? '1.25rem' : '1rem')};
  font-weight: ${(props) =>
    props.sizeVariant === 'large' ? 'normal' : 'lighter'};
  border-radius: 5px;
  .MuiSelect-select.MuiSelect-select {
    padding: ${(props) =>
      props.sizeVariant === 'large' ? '0.75rem' : '0.5rem'};
  }
  border: ${(props) => (props.variant === 'outlined' ? '1px solid' : 'none')};
  border-color: ${(props) => {
    if (props.theme === 'light') return 'white'
    if (props.theme === 'primary') return primary
    return 'black'
  }};
`

export const SelectedLanguage = styled(Box)<{ theme: LanguageSelectorTheme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-family: 'Montserrat', sans-serif;

  svg {
    color: ${(props) => (props.theme === 'light' ? 'white' : 'black')};
  }
`

export const DropDownIcon = styled(SvgIcon)`
  margin-left: -0.6rem;
`
