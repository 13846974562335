import { Questionnaire } from '~/graphql/types/schemaNode.type'
import R5 from 'fhir/r5'
import { JsonFhirR5FormStep } from '../../jsonFormSteps'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import { convertQuestionnaireToFhirR5Questionnaire } from '~/lib/questionnaire/convertQuestionnaireToFhirR5Questionnaire'

export const convertQuestionnaireToFhirFormStep = (
  questionnaire: Questionnaire,
  language: string
): JsonFhirR5FormStep => {
  const fhir: R5.Questionnaire = questionnaire.fhirJson
  const result: JsonFhirR5FormStep = {
    type: 'fhir',
    fhir: new FhirQuestionnaire(
      convertQuestionnaireToFhirR5Questionnaire(questionnaire),
      language
    ),
    version: 'R5',
    id: questionnaire.id,
    versionId: questionnaire.versionId,
    path: questionnaire.urlParam,
    metadata: {
      id: questionnaire.id,
      name: fhir.title ?? questionnaire.urlParam,
      path: questionnaire.urlParam,
      showOnSteps: false,
    },
  }
  return result
}
