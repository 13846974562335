import { DeepPartial } from '../types'

export const deepPropUpdate = <T extends Record<string, any>>(
  object: T,
  props: DeepPartial<T> | undefined
): T => {
  if (!props) return object
  const result = Object.keys(props).reduce((acc, key) => {
    if (typeof acc[key] === 'object' && typeof props[key] === 'object') {
      return {
        ...acc,
        [key]: deepPropUpdate(object[key], props[key]),
      }
    } else if (props[key] !== undefined) {
      return {
        ...acc,
        [key]: props[key],
      }
    } else return acc
  }, object)

  return result
}
