import {
  PublicCreateAppointmentInputV1,
  EAppointmentTypeMethod,
  PatientCreateAppointmentInputV1,
} from '~/graphql/types/schema.type'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { Appointment } from '~/lib/appointment'
import { getLangCode } from '~/util/lang'

export const createPatientAppointmentInput = (
  appointment: Appointment,
  reservationId: string
): PatientCreateAppointmentInputV1 => {
  return {
    reservationId: reservationId || undefined,
    startDateTime: appointment.startDateTime || undefined,
    endDateTime: appointment.endDateTime || undefined,
    noOfPeople: appointment.noOfPeople,
    reasonForVisit: appointment.reasonForVisit || undefined,
  }
}

export const createPublicAppointmentInput = (
  appointment: Appointment,
  appointmentActivity: AppointmentActivityInstance,
  reservationId: string,
  language: string
): PublicCreateAppointmentInputV1 => {
  const createMethod = (method: string): EAppointmentTypeMethod => {
    if (method in EAppointmentTypeMethod) {
      return method as EAppointmentTypeMethod
    }
    throw new Error(`Invalid appointment method: ${method}`)
  }

  return {
    appointmentTypeId: Number(appointmentActivity.appointmentTypeId),
    method: createMethod(appointment.method),
    ...createPatientAppointmentInput(appointment, reservationId),
    noOfPeople: appointment.noOfPeople,
    reasonForVisit: appointment.reasonForVisit || undefined,
    langCode: getLangCode(language),
  }
}
