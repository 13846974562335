import styled from 'styled-components'
import { offWhite } from './colorPalette'

const responsiveFontSize = (
  minFont,
  maxFont,
  minScreen = 320,
  maxScreen = 1200,
  units = 'px'
) => `
    calc(${minFont}${units} + ${maxFont - minFont} * 
    (100vw - ${minScreen}${units})/${maxScreen - minScreen})`

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

export const PageLayout = styled.div`
  min-height: 100vh;
  min-height: var(--app-height);
  font-size: ${responsiveFontSize(18, 20)};
  font-family: 'Montserrat', sans-serif;
  overflow-y: scroll;
`

export const OffWhitePageLayout = styled(PageLayout)`
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
  /* width: 100%; */
  background-color: ${offWhite};
  box-sizing: border-box;
`

export const Header = styled.div`
  display: flex;
`

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const Box = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem;
  border-radius: 15px;
  /* background-color: white; */
  margin: 1rem;

  & > div {
    flex: 1 0 auto;
  }
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`
