import { useEffect } from 'react'

type HardRedirectProps = {
  to: string
}

export const HardRedirect = ({ to }: HardRedirectProps) => {
  useEffect(() => {
    window.location.href = to // This performs a hard redirect
  }, [to])

  return null // No need to render anything
}
