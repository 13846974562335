import * as React from 'react'

// components
import { Grid, InputLabel, FormControl, Select, Box } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControl'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { useMethodsDropDownVerbiage } from './useMethodsDropDownVerbiage'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

type MethodsDropDownMenuProps = {
  enabledMethods: string[]
  appointmentMethod?: string
}

export const MethodsDropDownMenu: React.FC<MethodsDropDownMenuProps> = ({
  enabledMethods,
  appointmentMethod = undefined,
}) => {
  const verbiage = useMethodsDropDownVerbiage()

  const hasOnsiteMethod = enabledMethods.find((item) => item === 'ONSITE')
  const initialMethodValue =
    appointmentMethod ?? (hasOnsiteMethod ? 'ONSITE' : enabledMethods[0])

  // General rule:
  // user do not see a dropdown selector and the value defaults to Onsite if only Onsite is available
  // if value != Onsite, then show the dropdown selector and mention the method
  const hideDropDown = hasOnsiteMethod && enabledMethods.length === 1

  const { t } = useMedMeTranslation('patient')

  return hideDropDown ? null : (
    <Box mb={3}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Heading4>{verbiage.headingLabel}</Heading4>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormControlLabel variant="outlined">
              <InputLabel>{verbiage.inputLabel}</InputLabel>
              <Select
                disabled={true}
                native
                label={t('method')}
                defaultValue={initialMethodValue}
              >
                {enabledMethods.map(
                  (method: keyof typeof verbiage.methodMap, index) => (
                    <option key={index} value={method}>
                      {verbiage.methodMap[method]}
                    </option>
                  )
                )}
              </Select>
            </FormControlLabel>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
