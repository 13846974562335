import { Engine } from 'json-rules-engine'
import moment from 'moment'

/**
 * This function checks to see whether the date provided by factValue is less than
 * the current date minus the number of months given by jsonValue
 */
export const addIsBefore = (rulesEngine: Engine) => {
  rulesEngine.addOperator('isBefore', (factValue, jsonValue) => {
    if (!factValue) return true
    // maxDate is the current date minus the number of months given by jsonValue
    const maxDate = moment(
      moment().subtract(Number(jsonValue), 'months').format('YYYY-MM-DD')
    )
    const factDate = moment(String(factValue))
    return factDate < maxDate
  })
}
