import React from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

interface IProps {
  i18nKey: string
  t: ReturnType<typeof useMedMeTranslation>['t']
}

const StyledBoldText = styled.span`
  font-weight: 700;
`

/**
 * LocalizedFormattedText component serves as a common utility for applying
 * text transformations like bold, italic etc. in localized strings.
 *
 *
 * @component
 * @param {function} props.t - The translation function provided by `react-i18next`.
 * @param {string} props.i18nKey - The key for the localized string.
 * @returns {JSX.Element} A formatted text element with dynamic components (e.g., bold).
 */
export const LocalizedFormattedText: React.FC<IProps> = ({ t, i18nKey }) => {
  return (
    <Trans
      t={t}
      i18nKey={i18nKey}
      components={{
        bold: <StyledBoldText />,
        // add new ones here ... eg: italic
      }}
    />
  )
}
