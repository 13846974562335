import * as React from 'react'
import moment from 'moment'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { Pharmacy } from '~/lib/pharmacy'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'

export const defaultFormat = 'YYYY-MM-DD'

export const useBookingRange = (
  pharmacy: Pharmacy,
  aai: AppointmentActivityInstance
) => {
  const {
    state: { appointment },
  } = useBookingContext()

  const output = React.useMemo(() => {
    const maxDaysOffset = 200
    const { bookingStartDate: aptStartDate, bookingEndDate: aptEndDate } =
      appointment
    const { bookingStartDate: aTypeStartDate, bookingEndDate: aTypeEndDate } =
      aai

    // Make it so that it gives a date the pharmcy timezone
    const dateMtz = (date: string) =>
      moment.tz(date || undefined, pharmacy.timeZone).startOf('day')

    const today = () => moment().tz(pharmacy.timeZone).startOf('day')
    const todayWithOffset = () => today().add(maxDaysOffset, 'day')

    const aptStartDateMtz = aptStartDate ? dateMtz(aptStartDate) : today()
    const aptEndDateMtz = aptEndDate ? dateMtz(aptEndDate) : todayWithOffset()
    const aaStartDateMtz = aTypeStartDate ? dateMtz(aTypeStartDate) : today()
    const aaEndDateMtz = aTypeEndDate
      ? dateMtz(aTypeEndDate)
      : todayWithOffset()

    const bookingStartDate = () => {
      const step1 = aptStartDateMtz.isAfter(aaStartDateMtz)
        ? aptStartDateMtz
        : aaStartDateMtz
      const step2 = today().isAfter(step1) ? today() : step1
      return step2
    }

    const hardMaxDate = () =>
      bookingStartDate().clone().add(maxDaysOffset, 'day')

    const bookingEndDate = () => {
      const step1 = aptEndDateMtz.isBefore(aaEndDateMtz)
        ? aptEndDateMtz
        : aaEndDateMtz
      const step2 = hardMaxDate().isBefore(step1) ? hardMaxDate() : step1

      return step2
    }

    const result = {
      maxDaysOffset,
      bookingStartDate: bookingStartDate,
      bookingEndDate: bookingEndDate,
      today: today,
      dateMtz: dateMtz,
    }
    return result
  }, [])

  return output
}
