import * as React from 'react'
import {
  Layout,
  Title,
  SubTitle,
  TagsLayout,
  TitleAndTagsContainer,
  TitleFlexBasis,
  tooltipTheme,
} from '../AaiCard.style'
import {
  Card,
  BodyContainer,
  Footer,
  TitleLayout,
  TitleContainer,
  CardLayout,
  SubmitButtonContainer,
  SelectButton,
  GetNotifiedContainer,
} from './DeselectedCard.style'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { AaiCardSubmitButton } from '../AaiCardSubmitButton'
import { AaiIcon } from '../AaiIcon'
import { AaiBadge } from '../AaiBadge'
import { ThemeProvider } from '@material-ui/core/styles'
import { MarkdownComponent } from '~/lib/markdown/MarkdownComponent'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { CardBadge } from '../../shared.styles'
import { useRouterContext } from '~/pages/AppointmentIntake/contexts'

type DeselectedApptCardProps = {
  aai: AppointmentActivityInstance
  available: boolean
  showUnselectedSubmitButton?: boolean
  showSelectButton?: boolean
  onExpand: (id: string) => void
  onSubmit: (id: string) => void
}

export const DeselectedCard: React.FC<DeselectedApptCardProps> = ({
  aai,
  available,
  showUnselectedSubmitButton = false,
  showSelectButton = true,
  onExpand,
  onSubmit,
}) => {
  const {
    appointmentActivityId,
    details: { title, subtitle, badges, waitlistName, waitlistDescription },
    description,
    settings: { iconUrl },
    isWaitlisted,
  } = aai

  const { t } = useMedMeTranslation('common')

  const { intakeType } = useRouterContext()
  const isBookingLink = intakeType === 'bookingLink'

  // We should ignore isWaitlisted flag for booking link
  const isWaitlistEnabled = isWaitlisted && !isBookingLink
  const titleText = (isWaitlistEnabled && waitlistName) || title
  const descriptionText =
    (isWaitlistEnabled && waitlistDescription) || description

  return (
    <Layout>
      <Card
        aria-expanded={false}
        role="button"
        tabIndex={0}
        elevation={2}
        onClick={() => onExpand(appointmentActivityId)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onExpand(appointmentActivityId)
          }
        }}
      >
        <AaiIcon src={iconUrl} />
        <CardLayout>
          <TitleContainer>
            <TitleAndTagsContainer>
              <TitleFlexBasis>
                <TitleLayout>
                  <Title>{titleText}</Title>
                  {Boolean(subtitle) && <SubTitle>{subtitle}</SubTitle>}
                </TitleLayout>
              </TitleFlexBasis>
              <TagsLayout>
                <ThemeProvider theme={tooltipTheme}>
                  {badges.map((badge, index) => (
                    <AaiBadge badge={badge} key={index} />
                  ))}
                </ThemeProvider>
              </TagsLayout>
            </TitleAndTagsContainer>
            {showUnselectedSubmitButton && (
              <SubmitButtonContainer>
                <AaiCardSubmitButton
                  onClick={() => {
                    onSubmit(appointmentActivityId)
                  }}
                  isAvailable={Boolean(available)}
                />
              </SubmitButtonContainer>
            )}
          </TitleContainer>
          {descriptionText ? (
            <BodyContainer>
              <Heading4>
                <MarkdownComponent>{descriptionText}</MarkdownComponent>
              </Heading4>
            </BodyContainer>
          ) : null}
          {showUnselectedSubmitButton && (
            <Footer>
              <AaiCardSubmitButton
                onClick={() => {
                  onSubmit(appointmentActivityId)
                }}
                isAvailable={Boolean(available)}
              />
            </Footer>
          )}
        </CardLayout>
        <GetNotifiedContainer>
          {isWaitlistEnabled ? (
            <CardBadge>{t('getNotified', { ns: 'patient' })}</CardBadge>
          ) : null}
          {showSelectButton && (
            <SelectButton aria-label={t('select')}>
              {t('select').toLocaleUpperCase()}
            </SelectButton>
          )}
        </GetNotifiedContainer>
      </Card>
    </Layout>
  )
}
