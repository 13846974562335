import { EServer } from '~/graphql/hooks/useGraphQL/links'
import {
  GetGroupAppointmentActivitiesQueryVariables,
  useGetGroupAppointmentActivitiesQuery,
} from '~/graphql/types/schemaNode.type'
import { useLanguageForPatient } from '~/hooks/useLanguageForPatient'
import { createAppointmentActivityInstance } from '~/lib/appointmentActivity'

export const useGetGroupAppointmentActivities = (
  variables: GetGroupAppointmentActivitiesQueryVariables,
  skip = false
) => {
  const { languageForFHIR: language } = useLanguageForPatient()

  const {
    data,
    error: gqlError,
    loading,
  } = useGetGroupAppointmentActivitiesQuery({
    skip,
    variables: {
      ...variables,
      isPublic: variables.isPublic || true,
      isActive: variables.locationId ? variables.isActive || true : undefined,
      regionCode: variables.regionCode || undefined,
      locationId: variables.locationId || undefined,
    },
    context: {
      clientName: EServer.NODE,
      headers: {
        'accept-language': language || 'en-CA',
        // In pharmacy context the 'x-pharmacyid' header is set automatically, therefore in order to be able to query
        // regional scope AAIs we should override it explicitly with an empty string. In this case
        // the backend will take into account the value of 'locationId' variable.
        'x-pharmacyid': '',
      },
    },
  })
  const appointmentActivities =
    data?.appointmentActivityInstanceList?.map((activity) =>
      createAppointmentActivityInstance(activity)
    ) ?? []
  const error = gqlError?.message ?? ''

  return {
    appointmentActivities,
    error,
    loading,
  }
}
