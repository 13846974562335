import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../types'
import { LanguageCodesFE } from '~/types/languageEnums'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Minor Illness Assessment',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'If you are experiencing a medical emergency, call 9-1-1 or go to your nearest hospital emergency room.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })
  const utiEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 16 years old
- Male sex assigned at birth
- Pregnant
- Have not been previously diagnosed with UTI by a doctor or nurse practitioner.
- This is your 2nd (or more) UTI in 6 months.
- Have had more than 2 UTIs in the last year.
- Have had symptoms for more than 2 weeks
    `,
    linkId: 'utiEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'UrinaryTractInfection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const sinusInfectionEligibility = createFhirQuestionnaireItem({
    text: `This is a check-up to see if you have nasal congestion. You will NOT be eligible for this service if any of the below apply to you:
- You are under 3 years of age
- You are immunocompromised
- You are experiencing neck stiffness, impaired vision or double vision, severe intractable headache, weakness/paralysis of eye muscle, eyelid droop or swelling around the eyes, bulging eyes
- You have had persistent nausea and/or vomiting
    `,
    linkId: 'sinusInfectionEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'sinus-infection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const earInfectionEligibility = createFhirQuestionnaireItem({
    text: `This is a check-up to see if you have an ear infection. You will NOT be eligible for this service if any of the below apply to you:
- You are under 3 years of age
- You are on immunosuppressive medications or have immunosuppressive conditions
- You have had ear tubes or had prior ear surgery
- You have had >3 ear infections in last 6 months or >4-6 ear infections in the last 12 months
    `,
    linkId: 'earInfectionEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ear-infection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const eligibility = createFhirQuestionnaireItem({
    text: 'Eligibility for assessment and prescribing for minor ailments will be assessed by the pharmacist at the time of your appointment.',
    linkId: 'eligibility',
    type: 'display',
  })
  const reimbursed = createFhirQuestionnaireItem({
    text: 'If you don’t have a valid NS Health card, Professional fees apply.',
    linkId: 'reimbursed',
    type: 'display',
  })
  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      utiEligibility,
      earInfectionEligibility,
      sinusInfectionEligibility,
      eligibility,
      reimbursed,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirMAMedicalScreeningSdmNSJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['NS'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: [
        'UrinaryTractInfection',
        'Shingles',
        'Contraception',
        'TickBites',
        'sinus-infection',
        'ear-infection',
      ],
    },
  },
  metadata: {
    id: 'fhirMAMedicalScreeningSdmNSJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
    languageCode: LanguageCodesFE.EN,
  },
}
