import {
  AppointmentActivityInstance as AAI,
  Questionnaire,
  QuestionnaireStatus,
} from '~/graphql/types/schemaNode.type'
import {
  AppointmentActivityInstance,
  AppointmentActivityInstanceBase,
  AppointmentActivityInstanceExtensions,
} from '../AppointmentActivityInstance'
import { DeepPartial } from '../../../pages/AppointmentIntake/lib/types'
import { createAppointmentActivityGroup } from '~/lib/appointmentActivityGroup'
import { createAppointmentActivityDetailsBadge } from '../createAppointmentActivityDetailsBadge'
import { getIsWalkinFeatureEnabled } from '../util'

const removeFalsyFromArray = (
  acc: string[],
  ele: string | undefined
): string[] => (ele ? [...acc, ele] : acc)

// Should stop using this and rely on graphql document typing instead since types from BE are now correct
export const createAppointmentActivityInstance = (
  apptActivityInstance?:
    | DeepPartial<AAI>
    | DeepPartial<AppointmentActivityInstance>
    | null
): AppointmentActivityInstance => {
  const aaiBase: AppointmentActivityInstanceBase = {
    id: apptActivityInstance?.id ?? '',
    appointmentActivityId: apptActivityInstance?.appointmentActivityId ?? '',
    appointmentTypeId: apptActivityInstance?.appointmentTypeId ?? '',
    isActive: apptActivityInstance?.isActive ?? false,
    name: apptActivityInstance?.name ?? '',
    category: apptActivityInstance?.category ?? '',
    subCategory: apptActivityInstance?.subCategory ?? '',
    description: apptActivityInstance?.description ?? '',
    subDescription: apptActivityInstance?.subDescription ?? '',
    urlParam: apptActivityInstance?.urlParam ?? '',
    groups:
      apptActivityInstance?.groups?.map(createAppointmentActivityGroup) || [],
    details: {
      description: apptActivityInstance?.details?.description ?? '',
      title: apptActivityInstance?.details?.title ?? '',
      subDescription: apptActivityInstance?.details?.subDescription ?? '',
      subtitle: apptActivityInstance?.details?.subtitle ?? '',
      bookingConfirmation:
        apptActivityInstance?.details?.bookingConfirmation ?? '',
      bookingVirtualConfirmation:
        apptActivityInstance?.details?.bookingVirtualConfirmation ?? '',
      bookingPhoneConfirmation:
        apptActivityInstance?.details?.bookingPhoneConfirmation ?? '',
      timeslotSelectionDescription:
        apptActivityInstance?.details?.timeslotSelectionDescription,
      walkinBookingConfirmation:
        apptActivityInstance?.details?.walkinBookingConfirmation ?? '',
      waitlistConfirmation:
        apptActivityInstance?.details?.waitlistConfirmation ?? '',
      badges:
        apptActivityInstance?.details?.badges?.map(
          createAppointmentActivityDetailsBadge
        ) || [],
      waitlistName: apptActivityInstance?.details?.waitlistName ?? '',
      waitlistDescription:
        apptActivityInstance?.details?.waitlistDescription ?? '',
      waitlistSubDescription:
        apptActivityInstance?.details?.waitlistSubDescription ?? '',
      upsellServiceText: apptActivityInstance?.details?.upsellServiceText ?? '',
      upsellServiceTitle:
        apptActivityInstance?.details?.upsellServiceTitle ?? '',
      promoImageHref: apptActivityInstance?.details?.promoImageHref ?? '',
      promoImageMobileS3Path:
        apptActivityInstance?.details?.promoImageMobileS3Path ?? '',
      promoImageDesktopS3Path:
        apptActivityInstance?.details?.promoImageDesktopS3Path ?? '',
    },
    settings: {
      iconUrl: apptActivityInstance?.settings?.iconUrl ?? '',
      isPublic: apptActivityInstance?.settings?.isPublic ?? false,
      isBookable: apptActivityInstance?.settings?.isBookable ?? false,
      maxGroupSize: apptActivityInstance?.settings?.maxGroupSize ?? null,
      enabledMethods:
        apptActivityInstance?.settings?.enabledMethods?.reduce(
          removeFalsyFromArray,
          []
        ) ?? [],
      documentTemplates:
        apptActivityInstance?.settings?.documentTemplates?.map(
          (documentTemplate) => {
            return {
              id: documentTemplate?.id ?? '',
              display: documentTemplate?.display ?? '',
              name: documentTemplate?.name ?? '',
              s3path: documentTemplate?.s3path ?? '',
              questionnaireId: documentTemplate?.questionnaireId ?? '',
            }
          }
        ) ?? [],
    },
    scope: {
      locationId: apptActivityInstance?.scope?.locationId ?? null,
      sublocationId: apptActivityInstance?.scope?.sublocationId ?? null,
      regionCode: apptActivityInstance?.scope?.regionCode ?? '',
      countryCode: apptActivityInstance?.scope?.countryCode ?? '',
    },
    intakeForms: apptActivityInstance?.intakeForms?.map(String) ?? [],
    bookingEndDate: String(apptActivityInstance?.bookingEndDate ?? ''),
    bookingStartDate: String(apptActivityInstance?.bookingStartDate ?? ''),
    isWalkinAllowed: apptActivityInstance?.isWalkinAllowed ?? false,
    isWaitlisted: apptActivityInstance?.isWaitlisted ?? false,
    isPrivate: apptActivityInstance?.isPrivate ?? true,
    // BE should fix typing for elements to not be nullable
    questionnaires:
      (() => {
        if (!apptActivityInstance?.questionnaires) {
          return undefined
        }
        return apptActivityInstance.questionnaires.reduce<Questionnaire[]>(
          (acc, questionnaire) => {
            if (!questionnaire) {
              return acc
            }
            const safeType: Questionnaire = {
              createdAt: questionnaire.createdAt,
              createdBy: questionnaire.createdBy ?? '',
              fhirJson: questionnaire.fhirJson ?? {},
              id: questionnaire.id ?? '',
              isPharmacistOnly: questionnaire.isPharmacistOnly ?? false,
              status: questionnaire.status ?? QuestionnaireStatus.draft,
              updatedAt: questionnaire.createdAt,
              updatedBy: questionnaire.updatedBy ?? '',
              urlParam: questionnaire.urlParam ?? '',
              versionId: questionnaire.versionId ?? '',
            }
            if (questionnaire) {
              acc.push(safeType)
            }
            return acc
          },
          []
        )
      })() ?? [],
  }

  const aaiExtensions: AppointmentActivityInstanceExtensions = {
    isWalkinFeatureEnabled: getIsWalkinFeatureEnabled(aaiBase),
  }

  return {
    ...aaiBase,
    ...aaiExtensions,
  }
}
