import React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import Typography from '@material-ui/core/Typography'
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined'
import DateRangeOutlined from '@material-ui/icons/DateRangeOutlined'
import PersonOutlineOutlined from '@material-ui/icons/PersonOutlineOutlined'

import {
  StyledBlock,
  StyledServicesBlock,
  ServicesTitle,
  Card,
  StyledServicesList,
} from './PatientBookingDetailsCard.styles'
import { Pharmacy, Sublocation } from '~/graphql/types/schemaNode.type'
import { transformToHumanReadableNumber } from '~/util/formatContact'
import {
  AccessTimeOutlined,
  PhoneOutlined,
  InfoOutlined,
} from '@material-ui/icons'
import { Checkbox, Divider, FormControlLabel, Grid } from '@material-ui/core'
import { DateFormat, getLocalizedDateFormat } from '~/util/date'
import { PharmacyService } from '../../NewBooking/AvailabilitySelection/hooks/useGetServicesByPharmacy/useGetServicesByPharmacy'

interface PatientBookingDetailsCardProps {
  pharmacy: Pharmacy
  externalClinic?: Sublocation
  title?: string
  startDateTime?: Date
  numberOfPersons?: number
  duration?: number
  displayServiceSelection?: boolean
  services?: PharmacyService[]
  selectedServices?: PharmacyService[]
  onChange?: (urlParam: string) => void
}

export const PatientBookingDetailsCard: React.FC<
  PatientBookingDetailsCardProps
> = ({
  pharmacy,
  externalClinic,
  title,
  numberOfPersons,
  startDateTime,
  duration,
  displayServiceSelection = false,
  services,
  selectedServices = [],
  onChange = () => {},
}) => {
  const { t } = useMedMeTranslation('patient')

  return (
    <Card>
      {displayServiceSelection && services && services.length > 1 ? (
        <StyledServicesBlock>
          <InfoOutlined />
          <div>
            <ServicesTitle variant="body1" gutterBottom>
              {title}
            </ServicesTitle>
            <Grid container direction="column" style={{ width: 'fit-content' }}>
              {services.map((service) => (
                <FormControlLabel
                  key={service.id}
                  control={
                    <Checkbox
                      checked={selectedServices.some(
                        (serviceToCheck) =>
                          serviceToCheck.urlParam === service.urlParam
                      )}
                      name={service.urlParam}
                      onChange={(e) => onChange(e.target.name)}
                    />
                  }
                  label={service.details?.title || service.name}
                />
              ))}
            </Grid>
          </div>
        </StyledServicesBlock>
      ) : null}

      {services && (!displayServiceSelection || services.length === 1) ? (
        <StyledServicesBlock>
          <InfoOutlined />
          <div>
            <ServicesTitle variant="body1" gutterBottom>
              {title}
            </ServicesTitle>
            <StyledServicesList>
              {services.map((service) => (
                <li key={service.id}>
                  {service.details?.title || service.name}
                </li>
              ))}
            </StyledServicesList>
          </div>
        </StyledServicesBlock>
      ) : null}
      {numberOfPersons ? (
        <StyledBlock>
          <PersonOutlineOutlined />
          <Typography variant="body2">
            {t('bookingDetailsCard.persons', { count: numberOfPersons })}
          </Typography>
        </StyledBlock>
      ) : null}
      {startDateTime ? (
        <StyledBlock>
          <DateRangeOutlined />
          <Typography variant="body2">
            {getLocalizedDateFormat(
              startDateTime.toString(),
              DateFormat.AbbrDayDateTime,
              pharmacy.timeZone
            )}
          </Typography>
        </StyledBlock>
      ) : null}
      {duration ? (
        <StyledBlock>
          <AccessTimeOutlined />
          <Typography variant="body2">
            {t('detailsWidget.duration', {
              duration,
            })}
          </Typography>
        </StyledBlock>
      ) : null}
      <Divider />
      {externalClinic ? (
        <StyledBlock>
          <LocationOnOutlined />
          <Typography variant="body2">
            {`${externalClinic.name}, ${externalClinic.address.unit}`}
          </Typography>
        </StyledBlock>
      ) : pharmacy.pharmacyAddress ? (
        <StyledBlock>
          <LocationOnOutlined />
          <Typography variant="body2">
            {`${pharmacy.name}, ${pharmacy.pharmacyAddress?.streetNumber} ${pharmacy.pharmacyAddress?.streetName}, ${pharmacy.pharmacyAddress?.city}, ${pharmacy.pharmacyAddress?.province}, ${pharmacy.pharmacyAddress?.postalCode}`}
          </Typography>
        </StyledBlock>
      ) : null}
      {pharmacy.pharmacyContact?.phone && !externalClinic ? (
        <StyledBlock>
          <PhoneOutlined />
          <Typography variant="body2">
            {transformToHumanReadableNumber(pharmacy.pharmacyContact?.phone)}
          </Typography>
        </StyledBlock>
      ) : null}
    </Card>
  )
}
