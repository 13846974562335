import { useCallback } from 'react'
import axios from 'axios'
import { reportException } from '~/tools/monitoring'

export const useRwgConversion = (rwgToken: string | null) => {
  const sendConversionData = useCallback(async () => {
    if (!rwgToken) return

    const partnerId = process.env.RWG_PARTNER_ID
    const endpoint = process.env.RWG_CONVERSION_ENDPOINT

    if (!partnerId || !endpoint) {
      reportException(
        new Error('RWG_PARTNER_ID or RWG_CONVERSION_ENDPOINT is not set')
      )
      return
    }

    const conversionData = {
      conversion_partner_id: partnerId,
      rwg_token: rwgToken,
      merchant_changed: 2,
    }

    try {
      await axios.post(endpoint, conversionData, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
    } catch (error) {
      reportException(error)
    }
  }, [rwgToken])

  return { sendConversionData }
}
