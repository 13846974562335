import { Box, Drawer, Fab, styled } from '@material-ui/core'
import { headerGray } from '~/sharedStyles/colorPalette'

const INTERCOM_Z_INDEX = 2147483003

export const TitleBarContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 1rem',
  backgroundColor: headerGray,
}))

export const DrawerContainer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    maxWidth: '450px',
    marginTop: '2.5rem',
    borderRadius: '5px',
    zIndex: INTERCOM_Z_INDEX + 1,
  },
}))

export const LogoMd = styled('img')(() => ({
  width: '150px',
}))

export const LogoSm = styled('img')(() => ({
  width: '50px',
}))

export const StyledFab = styled(Fab)(() => ({
  position: 'absolute',
  top: '4rem',
  right: 0,
  zIndex: 1400,
  borderRadius: '20px 0 0 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 2.25rem',
}))
