import * as React from 'react'
import { useGetInitialEnterprisePharmacyQuery } from '~/graphql/types/schema.type'
import { createPharmacy } from '~/lib/pharmacy'

export const useInitialEnterprisePharmacy = (
  enterprise: string,
  storeNo: string | null
) => {
  const [pharmacy, setPharmacy] = React.useState(createPharmacy())
  const [initialized, setInitialized] = React.useState(false)
  const [error, setError] = React.useState('')

  const result = useGetInitialEnterprisePharmacyQuery({
    variables: {
      storeNo: storeNo || undefined,
      enterpriseName: enterprise || undefined,
    },
    skip: initialized || !enterprise || !storeNo,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  React.useEffect(() => {
    if (!initialized && !result.loading) {
      const { data, error } = result
      if (error) {
        setError(error.message)
      } else if (data) {
        setPharmacy(createPharmacy(data.getEnterprisePharmacy))
      }
      setInitialized(true)
    }
  }, [result])

  return {
    pharmacy,
    loading: !initialized,
    error,
  }
}
