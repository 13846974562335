import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { Layout } from './plainLabeledCheckbox.styles'
import { CheckboxInput, InputLabel } from '../../patientRegistration.styles'

const PlainLabeledCheckbox = ({
  className,
  label,
  styles = {},
  labelStyles = {},
  error,
  helperText,
  disabled,
  checked,
  onChange,
  ...rest
}) => {
  return (
    <Layout className={className} {...styles}>
      <FormControlLabel
        style={{ margin: 0 }}
        control={
          <CheckboxInput
            disabled={disabled}
            checked={checked}
            color="default"
            onChange={(e) => onChange(e)}
            {...rest}
          />
        }
        disabled={disabled}
        label={<InputLabel color={labelStyles.color || ''}>{label}</InputLabel>}
        labelPlacement={labelStyles.placement || 'end'}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Layout>
  )
}

export default PlainLabeledCheckbox
