import React from 'react'

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NextLink,
  Operation,
  from,
} from '@apollo/client'

import { typeDefs } from '~/auth/resolvers'

import { authLink, errorLink, httpLink, ErrorMapType } from './links'

import { reportGraphqlException, monitoringLink } from '~/tools/monitoring'

type useGraphQLCallbacks = {
  refreshToken: () => any
  logout: () => void
  cache: InMemoryCache
}

export const useGraphQL = ({
  refreshToken,
  logout,
  cache,
}: useGraphQLCallbacks) => {
  const expiredTokenErrorHandler = async (
    operation: Operation,
    forward: NextLink
  ) => {
    const token = await refreshToken()
    operation.setContext(({ headers }: Record<string, any>) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }))

    if (token) {
      return forward(operation)
    }
  }

  const errorMap: ErrorMapType = {
    TOKEN_EXPIRED: expiredTokenErrorHandler,
    UNAUTHENTICATED: expiredTokenErrorHandler,
    '0015': (operation) => {
      const context = operation.getContext()
      reportGraphqlException(new Error('NOT AUTHORIZED'), {
        clientName: context.clientName,
        operationName: operation.operationName,
        headers: context.headers,
        extensions: { errorCode: '0015' },
      })
      logout()
    },
  }

  const client = new ApolloClient({
    link: from([
      monitoringLink(),
      authLink(refreshToken),
      errorLink(errorMap),
      httpLink(),
    ]),
    cache,
    typeDefs,
  })

  if (window.Cypress) {
    window.graphqlClient = client
  }

  const GraphQLProvider: React.FC = ({ children }) => (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  )

  return {
    GraphQLProvider,
  }
}

declare global {
  interface Window {
    Cypress: any
    graphqlClient: any
    dataLayer?: any[]
  }
}
