import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import MuiSelect from '@material-ui/core/Select'
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'

type SelectFC = React.FC<{
  name: string
  label?: string
  defaultValue?: string
  options: { value: string; label: string }[]
  helperText?: string
  disabled?: boolean
  required?: boolean
  styles?: Record<string, string>
  fixedWidth?: boolean
  handleSelectChange?: (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => void
}>

export const Select: SelectFC = (props) => {
  const { errors, trigger, setValue, getValues } = useFormContext()

  const defaultValue = React.useMemo(() => {
    const validValues = props.options.map((option) => option.value)
    if (validValues.includes(props.defaultValue ?? '')) {
      return props.defaultValue
    }
    return ''
  }, [])
  const value = React.useMemo(() => {
    const currentValue = getValues(props.name)
    const validValues = props.options.map((option) => option.value)
    if (validValues.includes(currentValue)) {
      return currentValue
    }
    return defaultValue
  }, [])

  const {
    field: { onChange, ...otherFields },
  } = useController({
    name: props.name,
    defaultValue: defaultValue,
  })
  const error = props.disabled === true ? '' : errors[props.name]?.message

  React.useEffect(() => {
    const skipValidationOnRequired = !(value === '' && props.required)
    setValue(props.name, value, {
      shouldValidate: skipValidationOnRequired,
    })
  }, [value])

  return (
    <FormControl
      component="fieldset"
      error={Boolean(error)}
      fullWidth={!props?.fixedWidth}
      disabled={props.disabled}
      required={!props.disabled && props.required}
    >
      {props.label && (
        <InputLabel
          id={`${props.name}-label`}
          className="MuiInputLabel-outlined"
        >
          {props.label}
        </InputLabel>
      )}
      <MuiSelect
        variant="outlined"
        label={props.label || undefined}
        fullWidth
        style={props.styles}
        {...otherFields}
        onChange={(e) => {
          onChange(e)
          trigger(props.name)
          if (props.handleSelectChange) {
            props.handleSelectChange(e)
          }
        }}
      >
        {props.options.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value}
            style={{ fontWeight: props.styles?.fontWeight || 'inherit' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText className="MuiFormHelperText-contained">
        {error || props.helperText}
      </FormHelperText>
    </FormControl>
  )
}
