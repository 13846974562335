import * as React from 'react'
import { createAppointment } from '~/lib/appointment'

export const createAppointmentContext = () => ({
  initialAppointment: createAppointment(),
  appointment: createAppointment(),
  dispatch: (() => undefined) as React.Dispatch<any>,
})

export type AppointmentContextInterface = ReturnType<
  typeof createAppointmentContext
>

export const AppointmentContext =
  React.createContext<AppointmentContextInterface>(createAppointmentContext())
