import * as React from 'react'
import {
  AppointmentActivityIconContainer,
  AppointmentActivityIconLayout,
} from './AppointmentActivityIcon.style'

export const isSvg = (iconUrl: string) => {
  return iconUrl.split('.').pop() === 'svg'
}

export const AppointmentActivityIcon: React.FC<{
  src: string
  hideWhenSm?: boolean
}> = ({ src, hideWhenSm = false }) => {
  if (!src) return null
  return (
    <AppointmentActivityIconLayout hideWhenSm={hideWhenSm}>
      <AppointmentActivityIconContainer isSvg={isSvg(src)}>
        <img width="80%" src={src} />
      </AppointmentActivityIconContainer>
    </AppointmentActivityIconLayout>
  )
}
