import * as React from 'react'
import { FhirFormContext } from '../FhirFormContext'

export const useFhirFormContext = () => {
  const context = React.useContext(FhirFormContext)
  if (context === undefined) {
    throw new Error('useFhirFormContext must be used within a FhirFormProvider')
  }
  return context
}
