import styled from 'styled-components'
import {
  Heading4,
  Body,
  Button,
  InverseButton,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: white;
  border-radius: 5px;
  padding: 2rem 1.5rem;
  overflow-y: scroll;
  font-family: 'Montserrat', sans-serif;
  max-height: 85vh;
  max-width: 90vw;

  @media only screen and (min-width: 550px) {
    width: 550px;
    padding: 2rem;
  }

  scrollbar-width: none; // Hides scrollbar for Firefox
  ::-webkit-scrollbar {
    display: none;
  }
`

export const Title = styled(Heading4)`
  margin-bottom: 2rem;
  font-weight: 600;
`

export const BodyText = styled(Body)`
  margin-bottom: 1rem;
`

export const BoldText = styled(BodyText)`
  font-weight: 600;
`

export const SubmitButton = styled(Button)`
  align-self: flex-end;
  width: 9rem;
  height: 3rem;
`

export const ErrorButton = styled(InverseButton)`
  align-self: flex-end;
  width: 9rem;
  height: 3rem;
`

export const Bold = styled.span`
  font-weight: 600;
`
