import { createFhirInputItem } from '../fhirInputItem'

export const createFhirBooleanItem: typeof createFhirInputItem = (json) => {
  if (json.type !== 'boolean')
    throw new Error('createFhirBooleanItem: json.item !== "boolean"')

  return {
    type: 'boolean',
  }
}
