import * as React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'

import { createFhirQuestionnaireResolverEngine } from './createFhirQuestionnaireResolverEngine'
import {
  FhirQuestionnaireResolverBuilder,
  createFhirQuestionnaireResolverBuilder as createDefaultResolverBuilder,
} from './createFhirQuestionnaireResolver'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import { TFunction } from 'i18next'

export const useFhirQuestionnaireResolver = (
  questionnaire: FhirQuestionnaire,
  resolverBuilder: FhirQuestionnaireResolverBuilder = createDefaultResolverBuilder(),
  t: TFunction
) => {
  const { state } = useBookingContext()

  const result = React.useMemo(() => {
    const rulesEngine = createFhirQuestionnaireResolverEngine(
      state,
      questionnaire,
      t
    )
    const resolver = resolverBuilder(rulesEngine, questionnaire)

    return { resolver }
  }, [state, questionnaire, resolverBuilder, t])

  return result
}
