import styled from 'styled-components'
import { gray } from '~/sharedStyles/colorPalette'

export const WalkInContainer = styled.div<{ isBottom?: boolean }>`
  display: flex;
  margin-left: auto;
  margin-top: 0.5rem;
  align-items: start;
`

export const WalkInLabel = styled.div`
  display: flex;
  align-items: middle;
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${gray} !important;
  line-height: 1.35;
`
