import * as React from 'react'
import {
  Title,
  SubTitle,
  TagsLayout,
  tooltipTheme,
  TitleAndTagsContainer,
  TitleFlexBasis,
} from '../AaiCard.style'

import {
  SelectedCardLayout,
  Header,
  BodyLayout,
  SelectedCardFooter,
  TitleLayout,
  TitleContainer,
  HeaderContentLayout,
  Description,
  GetNotifiedContainer,
} from './SelectedCard.style'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { ThemeProvider } from '@material-ui/styles'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { AaiBadge } from '../AaiBadge'
import { AaiIcon } from '../AaiIcon'
import { AaiCardSubmitButton } from '../AaiCardSubmitButton'
import { MarkdownComponent } from '~/lib/markdown/MarkdownComponent'
import { MethodsDropDownMenu } from '../MethodsDropDownMenu/MethodsDropDownMenu'
import {
  useAppointmentContext,
  useRouterContext,
} from '~/pages/AppointmentIntake/contexts'
import { useFormContext } from 'react-hook-form'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { CardBadge } from '../../shared.styles'

const removeDuplicateDescription = (aai: AppointmentActivityInstance) => {
  if (aai.description === aai.subDescription) {
    return {
      ...aai,
      description: '',
    }
  }
  return aai
}

type SelectedApptCardProps = {
  aai: AppointmentActivityInstance
  available: boolean
  appointmentMethod?: string
  onCollapse: (id: string) => void
  onSubmit: (id: string) => void
}

export const SelectedCard: React.FC<SelectedApptCardProps> = ({
  aai,
  available,
  appointmentMethod = undefined,
  onCollapse,
  onSubmit,
}) => {
  const filteredAai = React.useMemo(() => {
    return removeDuplicateDescription(aai)
  }, [aai])
  const { updateAppointment } = useAppointmentContext()
  const { getValues } = useFormContext()
  const method = getValues('method')

  const { t } = useMedMeTranslation('patient')

  const { intakeType } = useRouterContext()
  const isBookingLink = intakeType === 'bookingLink'

  const {
    appointmentActivityId,
    details: {
      title,
      subtitle,
      badges,
      waitlistName,
      waitlistDescription,
      waitlistSubDescription,
    },
    description,
    subDescription,
    settings: { iconUrl, enabledMethods },
    isWaitlisted,
  } = filteredAai

  // We should ignore isWaitlisted flag for booking link
  const isWaitlistEnabled = isWaitlisted && !isBookingLink

  const titleText = (isWaitlistEnabled && waitlistName) || title
  const descriptionText =
    (isWaitlistEnabled && waitlistDescription) || description
  const subDescriptionText =
    (isWaitlistEnabled && waitlistSubDescription) || subDescription

  return (
    <SelectedCardLayout aria-expanded role="button" tabIndex={0} elevation={2}>
      <Header onClick={() => onCollapse('')}>
        <AaiIcon src={iconUrl} hideWhenSm={true} />
        <HeaderContentLayout>
          <TitleContainer>
            <TitleAndTagsContainer>
              <TitleFlexBasis>
                <TitleLayout>
                  <Title>{titleText}</Title>
                  {subtitle !== '' && <SubTitle>{subtitle}</SubTitle>}
                </TitleLayout>
              </TitleFlexBasis>
              <TagsLayout>
                <ThemeProvider theme={tooltipTheme}>
                  {badges.map((badge, index) => (
                    <AaiBadge badge={badge} key={index} />
                  ))}
                </ThemeProvider>
              </TagsLayout>
            </TitleAndTagsContainer>
          </TitleContainer>
          {descriptionText ? (
            <Description>
              <MarkdownComponent>{descriptionText}</MarkdownComponent>
            </Description>
          ) : null}
        </HeaderContentLayout>
        <GetNotifiedContainer>
          {isWaitlistEnabled ? <CardBadge>{t('getNotified')}</CardBadge> : null}
        </GetNotifiedContainer>
      </Header>
      <BodyLayout>
        <MethodsDropDownMenu
          appointmentMethod={appointmentMethod}
          enabledMethods={enabledMethods}
        />
        {subDescriptionText ? (
          <Heading4>
            <MarkdownComponent>{subDescriptionText}</MarkdownComponent>
          </Heading4>
        ) : null}
        <SelectedCardFooter>
          <AaiCardSubmitButton
            onClick={() => {
              onSubmit(appointmentActivityId)
              updateAppointment({
                method,
              })
            }}
            isAvailable={Boolean(available)}
          />
        </SelectedCardFooter>
      </BodyLayout>
    </SelectedCardLayout>
  )
}
