import {
  caslConsentFilter,
  CaslConsentFilterType,
} from '~/types/selectValueEnums'

export const getCaslLabel = () =>
  'contactInformationForm.contactSection.caslEmailConsent'

export const getCaslConsent = (caslEmailConsent: CaslConsentFilterType) => {
  if (caslEmailConsent === caslConsentFilter.YES) {
    return true
  } else if (caslEmailConsent === caslConsentFilter.NO) {
    return false
  } else {
    return null
  }
}
