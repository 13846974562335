import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import { BookingContextInterface } from '../../BookingContext'

import { useCreateNewPatientAndAppointment } from './useCreateNewPatientAndAppointment'
import { useUpdatePatientAndAppointment } from './useUpdatePatientAndAppointment'
import { useUpdatePatientAndCreateAppointment } from './useUpdatePatientAndCreateAppointment'
import { usePublicCreateQuestionnaireResponses } from './usePublicCreateQuestionnaireResponses'
import { useUpdateQuestionnaireResponses } from './useUpdateQuestionnaireResponses'
import { IntakeSubmissionResult } from './createIntakeSubmissionResult'
import { usePharmacistCreateAppointmentDocument } from '~/lib/pdf-data-layer/usePharmacistCreateAppointmentDocument'
import { AppointmentActivitySettings } from '~/graphql/types/schemaNode.type'

export const useSubmitIntake = (
  reservationId: BookingContextInterface['reservationId'],
  aai: { settings: Pick<AppointmentActivitySettings, 'documentTemplates'> },
  questionnaires: FhirQuestionnaire[]
) => {
  const { createNewPatientAndAppointment } =
    useCreateNewPatientAndAppointment(reservationId)

  const { updatePatientAndAppointment } = useUpdatePatientAndAppointment()

  const { updatePatientAndCreateAppointment } =
    useUpdatePatientAndCreateAppointment(reservationId)

  const { publicCreateQuestionnaireResponses } =
    usePublicCreateQuestionnaireResponses(questionnaires)

  const { updateQuestionnaireResponses } =
    useUpdateQuestionnaireResponses(questionnaires)

  const forwardResult =
    (fn: (result: IntakeSubmissionResult) => Promise<any>) =>
    async (result: IntakeSubmissionResult | undefined) => {
      if (result) {
        await fn(result)
      }
      return Promise.resolve(result)
    }

  const createQuestionnaireResponsesForAppointment = forwardResult((result) =>
    publicCreateQuestionnaireResponses(result.patient.id, result.appointment.id)
  )

  const pharmacistCreateAppointmentDocument =
    usePharmacistCreateAppointmentDocument()

  const createAppointmentDocumentsFromTemplates = forwardResult(
    async (result) => {
      try {
        return await Promise.all(
          aai.settings.documentTemplates.map(async (template) =>
            pharmacistCreateAppointmentDocument({
              appointmentId: result.appointment.id,
              documentTemplateId: template.id,
              updatedById: result.patient.id,
            })
          )
        )
      } catch (e) {
        console.error('soft failing createAppointmentDocument', e)
        Promise.resolve(undefined)
      }
    }
  )

  const updateQuestionnaireResponsesForAppointment = forwardResult(
    async (result) => {
      try {
        return await Promise.all([
          aai.settings.documentTemplates.map(async (template) =>
            pharmacistCreateAppointmentDocument({
              appointmentId: result.appointment.id,
              documentTemplateId: template.id,
              updatedById: result.patient.id,
            })
          ),
          updateQuestionnaireResponses(
            result.patient.id,
            result.appointment.id
          ),
        ])
      } catch (e) {
        console.error('soft failing updateAppointmentDocument, e')
        Promise.resolve(undefined)
      }
    }
  )

  const submitIntakeAsync = async () => {
    const values = await Promise.all([
      // only fires for intakeType 'public' (i.e. patient-initiated flows)
      // @todo move conditional here and rename hook for clarity (public/patient-initiated flow)
      //
      // per current understanding:
      // - in this case the patient independently initiated the flow
      // - this case is most relevant to partner referral/affiliate tracking as this traffic may come
      //   from a referral/affiliate partner
      createNewPatientAndAppointment()
        .then(createQuestionnaireResponsesForAppointment)
        .then(createAppointmentDocumentsFromTemplates),

      // conditionally fires only for intakeType 'documentationLink'
      // @todo move conditional buried in here + rename hook for clarity (public/patient-initiated flow)
      //
      // per current understanding:
      // - in this case an appointment exists and was created by pharmacist
      // - pharmacist requests the patient to complete additional information (questionnaire, etc)
      // - patient has fulfilled the request and this function submits the data to backend
      updatePatientAndAppointment()
        .then(updateQuestionnaireResponsesForAppointment)
        .then(createAppointmentDocumentsFromTemplates),

      // conditionally fires only for intakeType 'bookingLink' + 'bookNextAppointmentLink'
      // @todo move conditional here and rename hook for clarity (public/patient-initiated flow)
      //
      // per current understanding:
      // - in bookingLink case
      updatePatientAndCreateAppointment()
        .then(updateQuestionnaireResponsesForAppointment)
        .then(createAppointmentDocumentsFromTemplates),
    ])
    const value = values.find(Boolean)

    if (!value) {
      throw new Error('No submission occurred. This should not be possible')
    }

    return value
  }

  return {
    submitIntakeAsync,
  }
}
