import styled from 'styled-components'
import { completedGreen } from '~/sharedStyles/colorPalette'
import {
  Card,
  Body,
  Heading3,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const BookingDetailsWrapperLayout = styled.div<{
  flexDirection?: 'row' | 'column'
}>`
  display: flex;
  flex-flow: ${({ flexDirection }) => flexDirection ?? 'column'} nowrap;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: inherit;
  width: 100%;
  column-gap: 1rem;
`

export const StickyContainer = styled.div`
  align-self: flex-start;
  position: sticky;
  top: 6rem;
  margin-right: 1rem;
`

export const BookingDetailsHeaderBase = styled.div`
  width: 100%;
  height: 3rem;
`

export const BookingDetailsHeader = styled(BookingDetailsHeaderBase)`
  position: fixed;
  z-index: 100;
  left: 0;
  padding: 1rem;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BookingDetailsLayout = styled(Card)`
  box-sizing: border-box;
  padding: 2rem 1.5rem;
  overflow-y: scroll;
  max-height: 75vh;
  width: 90vw;
  gap: 1rem;
  scrollbar-width: none; // Hides scrollbar for Firefox
  ::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 1065px) {
    width: 265px;
    max-height: none;
    padding: 2rem 1.5rem;
  }
`

export const Title = styled(Heading3)`
  font-weight: 700;
`

export const Content = styled(Body)`
  line-height: 1.35;
  color: ${({ theme }) => theme.color.textSecondary};
`
export const PatientSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const PatientTitle = styled(Body)`
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  font-weight: 600;
`

export const FormStepDetail = styled(Body)<{
  current?: boolean
  disabled?: boolean
}>`
  display: flex;
  align-items: center;
  margin: 0 -1.5rem;
  padding: 0.75rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.05);
  color: ${({ theme, disabled, current }) =>
    disabled && !current ? theme.color.textSecondary : 'black'};

  & > svg {
    margin-right: 0.5rem;
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.textSecondary : completedGreen};
  }
`

export const CircleIcon = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.color.textSecondary};
  border-radius: 50%;
  margin: 4px 4px 4px 3px;
  margin-right: 10px;
`
