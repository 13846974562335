import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip, Box } from '@material-ui/core'
// hooks
import useMediaHook from '~/hooks/mediaHook'

// styles
import {
  OptionContainer,
  IconContainer,
  OptionText,
  RowContainer,
  HelperText,
} from '../pharmacistLayout.styles'

const SidebarOption = forwardRef(
  (
    {
      selected,
      name,
      icon,
      title,
      link,
      children,
      helperText,
      tooltipTitle,
      tooltipHelper,
      ...rest
    },
    ref
  ) => {
    const { pharmacistLayout } = useSelector((state) => state.pharmacistLayout)
    const deviceType = useMediaHook(
      // Width set to 1280px to handle the need of minimized sidebar on larger tablets
      ['(max-width: 1280px)'],
      ['tablet'],
      'desktop'
    )

    return (
      <Tooltip
        title={
          tooltipTitle ? (
            <>
              <Box fontSize={14} mb={0.5}>
                {tooltipTitle}
              </Box>
              <Box fontSize={14} fontWeight={'fontWeightLight'}>
                {tooltipHelper}
              </Box>
            </>
          ) : (
            ''
          )
        }
        arrow
      >
        <OptionContainer
          selected={selected === name}
          to={!link ? '' : link}
          as={!link ? 'div' : undefined}
          ref={ref}
          key={name}
          {...rest}
        >
          <IconContainer>{icon}</IconContainer>
          {(pharmacistLayout || deviceType === 'desktop') && (
            <RowContainer>
              <OptionText>{title}</OptionText>
              <HelperText>{helperText}</HelperText>
            </RowContainer>
          )}
          {children}
        </OptionContainer>
      </Tooltip>
    )
  }
)

export default SidebarOption
