import * as React from 'react'
import { Alert } from '@material-ui/lab'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const NoServicesAvailable: React.FC = () => {
  const { t } = useMedMeTranslation('patient')

  return (
    <Alert severity="info">{t('activityLanding.noServicesAvailable')}</Alert>
  )
}
