import { Extension } from 'fhir/r5'
import { ExtensionValidationCondition } from '../validation.type'
import { isValidExtensionProps } from '../../lib/isValidExtensionProps'
import { isExtensionValidationOperator } from './isExtensionValidationOperator'
import { isExtensionValidationIsPath } from './isExtensionValidationIsPath'
import { isExtensionValidationValue } from './isExtensionValidationValue'

export const isExtensionValidationCondition = (
  extension: Extension
): extension is ExtensionValidationCondition => {
  return (
    extension.url === 'condition' &&
    isValidExtensionProps(extension, [
      isExtensionValidationOperator,
      isExtensionValidationIsPath,
      isExtensionValidationValue,
    ]) &&
    Object.keys(extension).length === 2
  )
}
