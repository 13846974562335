import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Eligibility Screening',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const completingQuestions = createFhirQuestionnaireItem({
    text: 'If completing on behalf of someone else, answer from their perspective.',
    linkId: 'completingQuestions',
    type: 'display',
  })

  const overTwoWeeks = createFhirQuestionnaireItem({
    text: 'Are you over 2 weeks of age?',
    linkId: 'overTwoWeeks',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const tamifluAllergy = createFhirQuestionnaireItem({
    text: 'Do you have an allergy to Tamiflu (oseltamivir)?',
    linkId: 'tamifluAllergy',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const fluSymptoms = createFhirQuestionnaireItem({
    text: 'Do you have any signs or symptoms of the flu right now? Symptoms include: cough, muscle aches, headache, sore throat, fever, nausea/vomiting/diarrhea',
    linkId: 'fluSymptoms',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'If you are not currently experiencing flu symptoms but are at risk of contracting the flu, it may be more appropriate to book a flu prevention appointment to ensure you are provided the correct service.',
          },
        ],
      }),
    ],
    required: true,
  })

  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      overTwoWeeks,
      tamifluAllergy,
      fluSymptoms,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningFluTreatmentIDJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['ID'],
    isEnterprise: false,
    appointmentActivity: {
      urlParams: ['flu-treatment'],
    },
  },
  metadata: {
    id: 'fhirMedicalScreeningFluTreatmentIDJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
