import { Engine, RuleSerializable } from 'json-rules-engine'
import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtValidationProps,
  isFhirExtValidation,
} from '../../../../../lib/fhir/extensions/fhirExtValidation'
import { createValidationRule } from './rules'
import { createRequiredRule } from './rules/createRequiredRule'
import { TFunction } from 'i18next'

const getValidationRules = (
  item: FhirQuestionnaireItem
): RuleSerializable[] => {
  const { extension, required } = item
  const requiredRule = required ? createRequiredRule(item.linkId) : null
  const validationRules =
    extension?.reduce<RuleSerializable[]>((acc, ext) => {
      if (isFhirExtValidation(ext)) {
        const validationRule = createValidationRule(
          item.linkId,
          createFhirExtValidationProps(ext)
        )
        return [...acc, validationRule]
      }
      return acc
    }, []) ?? []

  const rules = [requiredRule, ...validationRules].reduce<RuleSerializable[]>(
    (acc, rule) => (rule ? [...acc, rule] : acc),
    []
  )

  return rules
}

const getValidationRulesFromItems = (
  items: FhirQuestionnaireItem[]
): RuleSerializable[] => {
  return (
    items.reduce((acc, item) => {
      if (item.type === 'group') {
        if (item.item) {
          return [...acc, ...getValidationRulesFromItems(item.item)]
        } else {
          return acc
        }
      }
      const itemRules = getValidationRules(item)
      return [...acc, ...itemRules]
    }, []) ?? []
  )
}

export const addValidationRules =
  (questionnaire: FhirQuestionnaire, t: TFunction) => (rulesEngine: Engine) => {
    const rules = getValidationRulesFromItems(questionnaire.item)
    rules.forEach((rule: RuleSerializable) => {
      if (rule.event.params && rule.event.params.type === 'required') {
        rule.event.params.message = t('formRequired')
      }
      rulesEngine.addRule(rule)
    })
    return rulesEngine
  }
