import { CreatePatientAndIntakeAnswerInput } from '~/graphql/types/schema.type'
import { Appointment } from '~/lib/appointment'
import { Patient } from '~/lib/patient'
import { createIntakeAnswerInput } from '../createIntakeAnswerInput'
import { createPatientInput } from '../createPatientInput'

export const createPatientAndIntakeAnswerInput = (
  patient: Patient,
  appointment: Appointment
): CreatePatientAndIntakeAnswerInput => {
  return {
    patient: createPatientInput(patient),
    intakeAnswer: createIntakeAnswerInput(appointment),
    patientServiceFlagInputs: [],
  }
}
