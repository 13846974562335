import { Extension } from 'fhir/r5'

export const isValidExtensionProps = (
  extension: Extension,
  extensionValidators: ((extension: Extension) => boolean)[]
) => {
  const nestedExtensions = extension.extension ?? []
  if (nestedExtensions.length === extensionValidators.length) {
    return extensionValidators.every((fn) => nestedExtensions.some(fn))
  }
  return false
}
