import { Dispatch, SetStateAction, createContext } from 'react'

type AiChatContextType = {
  drawerOpen: boolean
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
}

export const AiChatContext = createContext<AiChatContextType>({
  drawerOpen: false,
  setDrawerOpen: () => {
    // do nothing
  },
})
