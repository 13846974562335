import React from 'react'
import { Box, Chip, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { LogoMd, LogoSm, TitleBarContainer } from '../AiChatContent.styles'

interface Props {
  setDrawerOpen: (arg: boolean) => void
  mediumLogo: { src: string; alt: string }
  smallLogo: { src: string; alt: string }
}

export const TitleBar = ({ setDrawerOpen, mediumLogo, smallLogo }: Props) => {
  return (
    <TitleBarContainer>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gridGap={'0.25rem'}
        marginY={'0.5rem'}
      >
        <Box display={'flex'} alignItems={'end'}>
          <LogoMd src={mediumLogo.src} alt={mediumLogo.alt} />
          <Chip
            label="BETA"
            size="small"
            variant="outlined"
            color="secondary"
          />
        </Box>
        <Box
          display={'flex'}
          gridGap={'0.25rem'}
          marginBottom={'0.25rem'}
          alignItems={'center'}
        >
          <Box fontSize={'10px'}>Powered by</Box>
          <LogoSm src={smallLogo.src} alt={smallLogo.alt} />
        </Box>
      </Box>
      <Box>
        <IconButton
          aria-label="close"
          size="small"
          onClick={() => setDrawerOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </TitleBarContainer>
  )
}
