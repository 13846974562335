import React from 'react'
import { Patient } from '~/lib/patient'

import { SectionBodyContainer } from '../../Completion.style'
import { Body } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { formatDeliveryMessage } from '~/pages/AppointmentIntake/helpers'

type ContactInfoContainerProps = {
  patient: Patient
}

export const ContactInfoContainer: React.FC<ContactInfoContainerProps> = ({
  patient,
}) => {
  const {
    email,
    phone: { cell },
  } = patient
  const { t } = useMedMeTranslation('patient')

  return (
    <SectionBodyContainer>
      <Body>{formatDeliveryMessage(t, email, cell)}</Body>
    </SectionBodyContainer>
  )
}
