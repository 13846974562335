import * as React from 'react'
import Loading from '~/components/loading'
import { deepPropUpdate } from '~/pages/AppointmentIntake/lib/deepPropUpdate'
import { useCurrentPatient } from '../useCurrentPatient'

import {
  PatientContext,
  PatientContextInterface,
  createPatientContext,
} from '../PatientContext'

const reducer = (
  state: PatientContextInterface,
  action: any
): PatientContextInterface => {
  switch (action.type) {
    case 'initializePatient':
      return {
        ...state,
        initialPatient: action.patient,
        patient: action.patient,
      }
    case 'updatePatient':
      return {
        ...state,
        patient: deepPropUpdate(state.patient, action.patient),
      }
    case 'setPatient':
      return {
        ...state,
        patient: action.patient,
      }
    default:
      console.log('unknown action type', action.type, action)
      return state
  }
}

export const PatientProvider: React.FC = ({ children }) => {
  const [initialized, setInitialized] = React.useState(false)
  const [context, dispatch] = React.useReducer(reducer, createPatientContext())
  const { patient, loading } = useCurrentPatient()

  React.useEffect(() => {
    if (!loading && !initialized) {
      dispatch({ type: 'initializePatient', patient: patient })
      setInitialized(true)
    }
  }, [loading])

  if (!initialized) return <Loading title="Loading Patient" />

  return (
    <PatientContext.Provider value={{ ...context, dispatch }}>
      {children}
    </PatientContext.Provider>
  )
}
