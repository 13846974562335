import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'
import { Heading2 } from '../AppointmentIntake.style'

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    text-transform: unset;
    font-family: ${({ theme }) => theme.font.main},
      ${({ theme }) => theme.font.generic};
  }
  &.MuiButton-outlined {
    color: ${({ theme }) => theme.color.buttonPrimary};
    border: 1.5px solid ${({ theme }) => theme.color.buttonPrimary};
    background-color: ${({ theme }) => theme.color.backgroundSecondary};
  }
  &.MuiButton-containedPrimary {
    background-color: ${({ theme }) => theme.color.buttonPrimary};
    color: white;
  }
`

export const Layout = styled.div`
  width: 100%;
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 1rem;

  & .MuiTypography-root {
    color: ${({ theme }) => theme.color.primary};
    font-family: ${({ theme }) => theme.font.main},
      ${({ theme }) => theme.font.generic};
  }
`

export const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1.5rem 1rem;
  border-radius: 3px;
  background-color: white;
  row-gap: 0.4rem;

  @media only screen and (min-width: 768px) {
    padding: 1.5rem;
    row-gap: 0.8rem;
  }
`
export const PageTitle = styled(Typography)`
  font-weight: 600;
  font-size: 1.5rem;

  @media only screen and (min-width: 768px) {
    font-size: 2rem;
  }
`
export const CardTitle = styled(Heading2)`
  font-weight: 700;
  margin-bottom: 1.5rem;
`

export const CardBadge = styled.div`
  width: max-content;
  padding: 0 6.5px 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  color: inherit;
  z-index: 1;
  @media (max-width: 700px) {
    padding: 0 0 0.5rem;
  }
`
