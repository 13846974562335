import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { useGetGroupFromUrlParamQuery } from '~/graphql/types/schemaNode.type'
import { createAppointmentActivityGroup } from '~/lib/appointmentActivityGroup'
import { useRouterContext } from '../../../RouterContext'
import { useLanguageForPatient } from '~/hooks/useLanguageForPatient'
import { usePharmacyContext } from '../../../PharmacyContext'

export const useInitialAppointmentActivityGroup = () => {
  const { group } = useRouterContext()
  const { pharmacy } = usePharmacyContext()

  const { languageForFHIR } = useLanguageForPatient()

  const {
    data,
    loading,
    error: gqlError,
  } = useGetGroupFromUrlParamQuery({
    variables: {
      urlParam: group,
      regionCode: pharmacy?.pharmacyAddress?.province ?? undefined,
      countryCode: 'CA',
    },
    skip: !group,
    context: {
      clientName: EServer.NODE,
      headers: {
        'accept-language': languageForFHIR,
      },
    },
    fetchPolicy: 'network-only',
  })

  const appointmentActivityGroup = createAppointmentActivityGroup(
    data?.appointmentActivityGroup
  )

  const error = gqlError?.message ?? ''

  return {
    appointmentActivityGroup,
    loading,
    error,
  }
}
