import { MenuItem, SvgIcon } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import {
  DropDownIcon,
  Dropdown,
  SelectedLanguage,
} from './LanguageDropdown.styles'
import LanguageIcon from '@material-ui/icons/Language'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

import { LanguageLocale, languageTranslations } from '~/util/languageMethods'

export type LanguageSelectorTheme = 'light' | 'dark' | 'primary'

export type LanguageDropdownProps = {
  supportedLanguages: LanguageLocale[]
  theme?: LanguageSelectorTheme
  variant?: 'outlined' | 'outlined-large'
  defaultLanguage?: LanguageLocale
  onLanguageChange: (selectedLanguage: LanguageLocale) => void
  shouldHideIcon?: boolean
}

export const LanguageDropdown = ({
  theme = 'light',
  variant,
  supportedLanguages,
  onLanguageChange,
  defaultLanguage = LanguageLocale.EN_CA,
  shouldHideIcon,
}: LanguageDropdownProps) => {
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(defaultLanguage)
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      const selectedLanguage = event.target.value as LanguageLocale
      setSelectedLanguage(selectedLanguage)
      onLanguageChange(selectedLanguage)
    },
    [onLanguageChange]
  )

  const styleVariant = variant?.includes('outlined') ? 'outlined' : undefined
  const sizeVariant = variant?.includes('large') ? 'large' : 'regular'

  const getLanguageLabel = (language: string) => {
    if (sizeVariant === 'large') {
      return languageTranslations[
        language.toLocaleUpperCase()
      ].toLocaleUpperCase()
    }
    return languageTranslations[language.toLocaleUpperCase()]
  }

  return (
    <Dropdown
      theme={theme}
      variant={styleVariant}
      sizeVariant={sizeVariant}
      defaultValue={selectedLanguage}
      value={selectedLanguage}
      onChange={handleChange}
      disableUnderline
      IconComponent={() => null}
      renderValue={(value: string) => {
        return (
          <SelectedLanguage theme={theme}>
            {shouldHideIcon ? null : (
              <SvgIcon>
                <LanguageIcon />
              </SvgIcon>
            )}
            {getLanguageLabel(value)}
            <DropDownIcon>
              <ArrowDropDown />
            </DropDownIcon>
          </SelectedLanguage>
        )
      }}
    >
      {supportedLanguages.map((language) => {
        return (
          <MenuItem key={language} value={language}>
            {getLanguageLabel(language)}
          </MenuItem>
        )
      })}
    </Dropdown>
  )
}
