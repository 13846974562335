import React from 'react'
import { DrawerContainer } from '../AiChatContent.styles'
import { TitleBar } from './TitleBar'
import { AiChatContext } from '../context'

export const ChatDrawer = () => {
  const { drawerOpen, setDrawerOpen } = React.useContext(AiChatContext)
  return (
    <DrawerContainer
      anchor="right"
      variant="persistent"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <TitleBar
        mediumLogo={{
          src: '/assets/clinical-search.svg',
          alt: 'clinical-search',
        }}
        smallLogo={{
          src: '/assets/asepha-ai.png',
          alt: 'asepha-ai',
        }}
        setDrawerOpen={setDrawerOpen}
      />
      <iframe
        src={process.env.MEDMEAI_IFRAME_URL}
        style={{ height: '100%', width: '100%', border: 'none' }}
      ></iframe>
    </DrawerContainer>
  )
}
