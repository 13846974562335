import { InMemoryCache } from '@apollo/client'

import {
  getIsAdmin,
  getIsPharmacyStaffReadOnly,
  getIsSamlLogin,
  updateClientQueryParamsWithObj,
} from '~/auth'
import { getPharmacyId } from '~/lib/tenantMetadata'

import {
  mergePaginatedResults,
  readPaginatedResults,
} from '~/util/paginationMethods'

const getQueryData = () => {
  const pharmacyId = getPharmacyId(true)
  const token = localStorage.getItem('token') ?? ''
  const isAdmin = getIsAdmin(token)
  const isPharmacyStaffReadOnly = getIsPharmacyStaffReadOnly(token)
  const isSamlLogin = getIsSamlLogin()
  const data = {
    isLoggedIn: Boolean(token) && Boolean(pharmacyId),
    isAdmin,
    isPharmacyStaffReadOnly,
    isSamlLogin,
  }

  return data
}

export const getCache = () => {
  const cache = new InMemoryCache({
    typePolicies: {
      Questionnaire: {
        keyFields: ['id', 'versionId'],
      },
      Query: {
        fields: {
          allAppointmentsScheduledV1: {
            keyArgs: ['search', 'filter'],
            merge: (...args) =>
              mergePaginatedResults(...args, 'appointmentsScheduled'),
            read: (...args) =>
              readPaginatedResults(...args, 'appointmentsScheduled'),
          },
          allAppointmentsScheduledJCG: {
            keyArgs: false,
            merge: (...args) =>
              mergePaginatedResults(...args, 'appointmentsScheduled'),
            read: (...args) =>
              readPaginatedResults(...args, 'appointmentsScheduled'),
          },
          allPatientsV1: {
            keyArgs: ['search', 'filter'],
            merge: (...args) => mergePaginatedResults(...args, 'patients'),
            read: (...args) => readPaginatedResults(...args, 'patients'),
          },
          allAppointmentsCovidWaitlistV1: {
            keyArgs: ['search', 'filter'],
            merge: (...args) =>
              mergePaginatedResults(...args, 'appointmentsCovidWaitlistModel'),
            read: (...args) =>
              readPaginatedResults(...args, 'appointmentsCovidWaitlistModel'),
          },
          allAppointmentsCovidWaitlistDose2V1: {
            keyArgs: ['search', 'filter'],
            merge: (...args) =>
              mergePaginatedResults(...args, 'appointmentsCovidWaitlistModel'),
            read: (...args) =>
              readPaginatedResults(...args, 'appointmentsCovidWaitlistModel'),
          },
          allAppointmentsCovidWaitlistLinkSentV1: {
            keyArgs: ['search', 'filter'],
            merge: (...args) =>
              mergePaginatedResults(
                ...args,
                'appointmentsCovidWaitlistLinkSent'
              ),
            read: (...args) =>
              readPaginatedResults(
                ...args,
                'appointmentsCovidWaitlistLinkSent'
              ),
          },
          allAppointmentsCovidWaitlistLinkSentDose2V1: {
            keyArgs: ['search', 'filter'],
            merge: (...args) =>
              mergePaginatedResults(
                ...args,
                'appointmentsCovidWaitlistLinkSent'
              ),
            read: (...args) =>
              readPaginatedResults(
                ...args,
                'appointmentsCovidWaitlistLinkSent'
              ),
          },
          allAppointmentsCovidWaitlistExpiredLinkV1: {
            keyArgs: ['search', 'filter'],
            merge: (...args) =>
              mergePaginatedResults(
                ...args,
                'appointmentsCovidWaitlistExpiredLink'
              ),
            read: (...args) =>
              readPaginatedResults(
                ...args,
                'appointmentsCovidWaitlistExpiredLink'
              ),
          },
          allAppointmentsCovidWaitlistExpiredLinkDose2V1: {
            keyArgs: ['search', 'filter'],
            merge: (...args) =>
              mergePaginatedResults(
                ...args,
                'appointmentsCovidWaitlistExpiredLink'
              ),
            read: (...args) =>
              readPaginatedResults(
                ...args,
                'appointmentsCovidWaitlistExpiredLink'
              ),
          },
        },
      },
    },
  })

  cache.writeQuery(updateClientQueryParamsWithObj(getQueryData()))

  return cache
}
