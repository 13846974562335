import { createPresetFormStep } from '..'
import { Consent } from '~/pages/AppointmentIntake/forms'

export const consentFormStep: typeof createPresetFormStep = (json) => {
  return {
    Component: Consent,
    metadata: json.metadata,
    isAutoSkippable: async () => false,
  }
}
