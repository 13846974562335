import styled from 'styled-components'
import { apptTypeIconBackground } from '~/sharedStyles/colorPalette'

export const AppointmentActivityIconContainer = styled.div<{
  isSvg: boolean
}>`
  display: grid;
  place-items: center;
  width: 7rem;
  height: 7rem;
  background-color: ${(props) => props.isSvg && apptTypeIconBackground};
  border-radius: 50%;
  @media (max-width: 700px) {
    width: 5rem;
    height: 5rem;
  }
  @media (max-width: 340px) {
    width: 4rem;
    height: 4rem;
  }
`

export const AppointmentActivityIconLayout = styled.div<{
  hideWhenSm: boolean
}>`
  display: grid;
  place-content: center;
  @media (max-width: 700px) {
    display: ${({ hideWhenSm }) => (hideWhenSm ? 'none' : 'auto')};
  }
`
