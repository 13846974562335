import { Engine } from 'json-rules-engine'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import {
  addEnableWhenEventRules,
  addIsEmail,
  addFhirEnableWhenOperators,
  addIsSameOrBefore,
  addMatch,
  addIsPhone,
  addIsBefore,
  addIsAfter,
} from '../../rulesEngine'

export const createDisabledItemsEngine = (
  questionnaire: FhirQuestionnaire
): Engine => {
  const rulesEngine = new Engine()
  const addOperators: ((rulesEngine: Engine) => void)[] = [
    addFhirEnableWhenOperators,
    addEnableWhenEventRules(questionnaire),
    addMatch,
    addIsEmail,
    addIsPhone,
    addIsSameOrBefore,
    addIsBefore,
    addIsAfter,
  ]
  addOperators.forEach((fn) => {
    fn(rulesEngine)
  })

  return rulesEngine
}
