import xOrganizationIds from '~/xOrganizationIds'
import { TenantMetadata } from './types'
import _ from 'lodash'
import xTenantIds from '~/xTenantIds'
import xPharmacyIds from '~/xPharmacyIds'
import { getMetadata } from './bootstrapUtils'

let tenantMetadata: TenantMetadata | null = null

export const initTenantMetadata = (metadata: TenantMetadata) => {
  // clone metadata to prevent mutation
  tenantMetadata = _.cloneDeep(metadata)
}

// TODO: remove xOrganizationIds, xTenantIds, xPharmacyIds checks
// https://medmehealth.atlassian.net/browse/NEB-1115

export const getOrganizationId = () => {
  if (!tenantMetadata || 'error' in tenantMetadata) {
    return xOrganizationIds[window.location.hostname] ?? null
  }

  return tenantMetadata.organizationId
}

export const getTenantId = () => {
  if (!tenantMetadata || 'error' in tenantMetadata) {
    return xTenantIds[window.location.hostname] ?? null
  }

  return tenantMetadata.tenantId
}

export const getTenantMetadataId = () => {
  if (!tenantMetadata || 'error' in tenantMetadata) {
    return null
  }

  return tenantMetadata.id
}

/**
 *
 * @param checkLocalStorage Whether to check localStorage for pharmacyId
 * @returns pharmacyId or null
 */
export const getPharmacyId = (checkLocalStorage: boolean = false) => {
  if (checkLocalStorage && localStorage.getItem('pharmacyId')) {
    return localStorage.getItem('pharmacyId')
  }

  if (!tenantMetadata || 'error' in tenantMetadata) {
    return xPharmacyIds[`${window.location.hostname}-pharmacyId`] ?? null
  }

  return tenantMetadata.pharmacyId
}

export const getTenantCountry = () => {
  // if tenantMetadata is available, use it
  if (tenantMetadata && !('error' in tenantMetadata)) {
    return tenantMetadata.country
  }

  // get metadata from localStorage
  const metadata = getMetadata()

  if (!metadata || 'error' in metadata) {
    return 'CA' // default to Canada
  }

  return metadata.country
}
