import * as React from 'react'
import {
  usePublicGetEnterprisePharmaciesQuery,
  usePublicGetAllEnterprisePharmaciesQuery,
  usePublicGetAppointmentTypesQuery,
} from '~/graphql/java/queries'
import { getAaiTitleAndSubtitle } from '~/lib/appointmentActivity/util'
import { createAppointmentType } from '~/lib/appointmentType'

import { createPharmacy } from '~/lib/pharmacy'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'

import { getEnterpriseCode } from '~/util/enterpriseMethods'

export const useStoreSelectionData = () => {
  const {
    state: { appointmentActivity, appointment, router, pharmacy, scope },
  } = useBookingContext()
  const { method } = appointment
  const aaiTitleAndSubtitle = getAaiTitleAndSubtitle(appointmentActivity)
  const {
    storeNo,
    searchParams: { global },
  } = router

  const bypassOnlyStoreNo = React.useMemo(() => {
    return storeNo ? global : false
  }, [])

  //the reason for this logic here is our old store selection relies on the appointment type name match with the appointment_type table's type
  //the urlParams below are SDM exclusive which is why this logic works (since the AA.name will match the type name)
  const appointmentTypeName = [
    'pharmacist-led-clinic',
    'flushot-regular',
    'flushot-senior',
  ].includes(appointmentActivity?.urlParam)
    ? appointmentActivity?.name
    : aaiTitleAndSubtitle

  const isEnterprise = Boolean(getEnterpriseCode())
  // Use this endpoint by default
  const {
    data: pharmacyListData,
    loading: pharmaciesLoading,
    error: pharmaciesError,
  } = usePublicGetEnterprisePharmaciesQuery({
    variables: {
      appointmentTypeName,
      appointmentTypeMethod: method,
      enterpriseName: getEnterpriseCode(),
      storeNo: bypassOnlyStoreNo ? null : storeNo, // Bypass the storeNo and return all stores if group query parameter is true
    },
    skip: !isEnterprise || bypassOnlyStoreNo,
    context: {
      pharmacyId: '',
    },
  })

  const {
    data: sdmLclPharmacyListData,
    loading: sdmLclLoading,
    error: sdmLclError,
  } = usePublicGetAllEnterprisePharmaciesQuery({
    variables: {
      appointmentTypeName,
      appointmentTypeMethod: method,
      isGlobal: bypassOnlyStoreNo,
      page: 0,
    },
    skip: !isEnterprise || !bypassOnlyStoreNo,
    context: {
      pharmacyId: '',
    },
  })

  const {
    data: indieApptTypesData,
    loading: indieApptTypesLoading,
    error: indieApptTypeError,
  } = usePublicGetAppointmentTypesQuery({
    variables: {
      appointmentTypeId: Number(appointmentActivity.appointmentTypeId),
    },
    skip: isEnterprise,
  })

  const indiePharmacyList = !isEnterprise
    ? [
        {
          ...pharmacy,
          appointmentTypes:
            indieApptTypesData?.publicGetAppointmentTypes.map(
              createAppointmentType
            ) ?? [],
        },
      ]
    : null
  const pharmacyList = React.useMemo(() => {
    return (
      indiePharmacyList ??
      pharmacyListData?.publicGetEnterprisePharmacies?.map(createPharmacy) ??
      sdmLclPharmacyListData?.publicGetAllEnterprisePharmacies?.map(
        createPharmacy
      ) ??
      []
    ).filter((pharmacy) => {
      /** Only return pharmacies based on the following conditions:
       *  1. The pharmacy has at least one appointment type if its publicIntake is enabled
       *  2. For regional appointment activities, return the pharmacies in the region. Otherwise return all stores
       * */
      const isEmptyRegion = scope.regionCode === ''
      const hasAppointmentTypes = pharmacy.appointmentTypes.length > 0
      const matchesRegionCode = pharmacy.province === scope.regionCode
      if (isEmptyRegion) {
        return hasAppointmentTypes
      }
      return hasAppointmentTypes && matchesRegionCode
    })
  }, [pharmacyListData, sdmLclPharmacyListData, indiePharmacyList])

  const loading = pharmaciesLoading || sdmLclLoading || indieApptTypesLoading
  const error =
    (pharmaciesError || sdmLclError || indieApptTypeError)?.message ?? ''

  const showMapbox =
    pharmacyList.filter(
      ({ pharmacyAddress }) =>
        pharmacyAddress.longitude !== null && pharmacyAddress.latitude !== null
    ).length > 0

  return {
    pharmacyList,
    showMapbox,
    loading,
    error,
  }
}
