import styled from 'styled-components'
import { createTheme } from '@material-ui/core'

export const Layout = styled.div`
  display: grid;
  word-wrap: break-word;
`

export const TagsLayout = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-basis: auto;
  width: fit-content;
`

export const Title = styled.span`
  font-weight: 600;
`

export const SubTitle = styled.span``

export const TitleAndTagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  flex-wrap: wrap;
  gap: 1rem;
`

export const TitleFlexBasis = styled.div`
  flex-basis: auto;
  width: fit-content;
`

export const tooltipTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'gray',
      },
      arrow: {
        color: 'gray',
      },
    },
  },
})
