import React from 'react'
import { Box } from '@material-ui/core'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { ErrorFallbackProps } from '../ErrorBoundary'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

export function ErrorComponent({
  children,
}: React.PropsWithChildren<Omit<ErrorFallbackProps, 'resetErrorBoundary'>>) {
  const { t } = useMedMeTranslation()

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      flexGrow={1}
      justifyContent="center"
      height="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        height="5rem"
        width="5rem"
        borderRadius="2.5rem"
        style={{ background: 'rgba(128, 128, 128, 0.25)' }}
      >
        <ErrorOutlineIcon
          fontSize="large"
          style={{ color: 'rgb(96, 96, 96)' }}
        />
      </Box>
      <Box
        fontSize="2rem"
        lineHeight="2.2rem"
        letterSpacing="0.01em"
        color="#000000"
        margin="1.4em 0 1.25rem"
      >
        {t('oops')}
      </Box>
      {children}
    </Box>
  )
}
