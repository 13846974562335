const initialState = {
  offset: 0,
  search: '',
  sortBy: 'WAITLIST_JOINED_DATE_ASC',
  waitlistJoinedDate: null,
  createdBy: null,
  isAge: true,
  patientBirthYear: null,
  patientAge: null,
  email: null,
  cell: null,
  showFilterOptions: true,
  tabIndex: 0,
  linkSentAt: null,
  linkSentCount: null,
  lastMassSendTime: null,
  screeningQuestionIds: null,
  waitlistServiceFlags: null,
}

const UPDATE_FLU_SHOT_WAITLIST_OFFSET = 'UPDATE_FLU_SHOT_WAITLIST_OFFSET'
const UPDATE_FLU_SHOT_WAITLIST_SEARCH = 'UPDATE_FLU_SHOT_WAITLIST_SEARCH'
const CLEAR_FLU_SHOT_WAITLIST_FILTER = 'CLEAR_FLU_SHOT_WAITLIST_FILTER'
const UPDATE_FLU_SHOT_WAITLIST_SHOW_FILTER_OPTIONS =
  'UPDATE_FLU_SHOT_WAITLIST_SHOW_FILTER_OPTIONS'
const UPDATE_FLU_SHOT_WAITLIST_CREATED_BY =
  'UPDATE_FLU_SHOT_WAITLIST_CREATED_BY'
const UPDATE_FLU_SHOT_WAITLIST_IS_AGE = 'UPDATE_FLU_SHOT_WAITLIST_IS_AGE'
const UPDATE_FLU_SHOT_WAITLIST_AGE = 'UPDATE_FLU_SHOT_WAITLIST_AGE'
const UPDATE_FLU_SHOT_WAITLIST_BIRTH_YEAR =
  'UPDATE_FLU_SHOT_WAITLIST_BIRTH_YEAR'
const UPDATE_FLU_SHOT_WAITLIST_EMAIL = 'UPDATE_FLU_SHOT_WAITLIST_EMAIL'
const UPDATE_FLU_SHOT_WAITLIST_CELL = 'UPDATE_FLU_SHOT_WAITLIST_CELL'
const UPDATE_FLU_SHOT_WAITLIST_TAB_INDEX = 'UPDATE_FLU_SHOT_WAITLIST_TAB_INDEX'
const UPDATE_FLU_SHOT_WAITLIST_SORT_BY = 'UPDATE_FLU_SHOT_WAITLIST_SORT_BY'
const UPDATE_FLU_SHOT_WAITLIST_LINK_SENT_AT =
  'UPDATE_FLU_SHOT_WAITLIST_LINK_SENT_AT'
const UPDATE_FLU_SHOT_WAITLIST_LINK_SENT_COUNT =
  'UPDATE_FLU_SHOT_WAITLIST_LINK_SENT_COUNT'
const UPDATE_LAST_MASS_SEND_TIME = 'UPDATE_LAST_MASS_SEND_TIME'
const UPDATE_FLU_SHOT_WAITLIST_JOIN_DATE = 'UPDATE_FLU_SHOT_WAITLIST_JOIN_DATE'
const UPDATE_FLU_SHOT_WAITLIST_QUESTION_IDS =
  'UPDATE_FLU_SHOT_WAITLIST_QUESTION_IDS'
const UPDATE_FLU_SHOT_WAITLIST_SERVICE_FLAGS =
  'UPDATE_FLU_SHOT_WAITLIST_SERVICE_FLAGS'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FLU_SHOT_WAITLIST_OFFSET:
      return {
        ...state,
        offset: action.offset,
      }
    case UPDATE_FLU_SHOT_WAITLIST_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    case UPDATE_FLU_SHOT_WAITLIST_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case CLEAR_FLU_SHOT_WAITLIST_FILTER:
      return {
        ...initialState,
        showFilterOptions: state.showFilterOptions,
      }
    case UPDATE_FLU_SHOT_WAITLIST_SHOW_FILTER_OPTIONS:
      return {
        ...state,
        showFilterOptions: action.toggleFilterOptions,
      }
    case UPDATE_FLU_SHOT_WAITLIST_IS_AGE:
      return {
        ...state,
        isAge: action.isAge,
      }
    case UPDATE_FLU_SHOT_WAITLIST_QUESTION_IDS:
      return {
        ...state,
        screeningQuestionIds: action.screeningQuestionIds,
      }
    case UPDATE_FLU_SHOT_WAITLIST_BIRTH_YEAR:
      return {
        ...state,
        patientBirthYear: action.patientBirthYear,
      }
    case UPDATE_FLU_SHOT_WAITLIST_AGE:
      return {
        ...state,
        patientAge: action.patientAge,
      }
    case UPDATE_FLU_SHOT_WAITLIST_EMAIL:
      return {
        ...state,
        email: action.email,
      }
    case UPDATE_FLU_SHOT_WAITLIST_CELL:
      return {
        ...state,
        cell: action.cell,
      }
    case UPDATE_FLU_SHOT_WAITLIST_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex,
      }
    case UPDATE_FLU_SHOT_WAITLIST_LINK_SENT_AT:
      return {
        ...state,
        linkSentAt: action.linkSentAt,
      }
    case UPDATE_FLU_SHOT_WAITLIST_LINK_SENT_COUNT:
      return {
        ...state,
        linkSentCount: action.linkSentCount,
      }
    case UPDATE_FLU_SHOT_WAITLIST_JOIN_DATE:
      return {
        ...state,
        waitlistJoinedDate: action.waitlistJoinedDate,
      }
    case UPDATE_LAST_MASS_SEND_TIME:
      return {
        ...state,
        lastMassSendTime: action.lastMassSendTime,
      }
    case UPDATE_FLU_SHOT_WAITLIST_CREATED_BY:
      return {
        ...state,
        createdBy: action.createdBy,
      }
    case UPDATE_FLU_SHOT_WAITLIST_SERVICE_FLAGS:
      return {
        ...state,
        waitlistServiceFlags: action.waitlistServiceFlags,
      }
    default:
      return state
  }
}

export const updateOffset = (offset) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_OFFSET,
  offset,
})

export const updateSearch = (search) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_SEARCH,
  search,
})

export const clearFilter = () => ({
  type: CLEAR_FLU_SHOT_WAITLIST_FILTER,
})

export const updateShowFilterOptions = (toggleFilterOptions) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_SHOW_FILTER_OPTIONS,
  toggleFilterOptions,
})

export const updateCreatedBy = (createdBy) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_CREATED_BY,
  createdBy,
})

export const updateIsAge = (isAge) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_IS_AGE,
  isAge,
})

export const updateAge = (patientAge) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_AGE,
  patientAge,
})

export const updateBirthYear = (patientBirthYear) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_BIRTH_YEAR,
  patientBirthYear,
})

export const updateEmail = (email) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_EMAIL,
  email,
})

export const updateCell = (cell) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_CELL,
  cell,
})

export const updateTabIndex = (tabIndex) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_TAB_INDEX,
  tabIndex,
})

export const updateSortBy = (sortBy) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_SORT_BY,
  sortBy,
})

export const updateLinkSentAt = (linkSentAt) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_LINK_SENT_AT,
  linkSentAt,
})

export const updateLinkSentCount = (linkSentCount) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_LINK_SENT_COUNT,
  linkSentCount,
})

export const updateLastMassSendTime = (lastMassSendTime) => ({
  type: UPDATE_LAST_MASS_SEND_TIME,
  lastMassSendTime,
})

export const updateWaitlistJoinDate = (waitlistJoinedDate) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_JOIN_DATE,
  waitlistJoinedDate,
})

export const updateScreeningQuestionIds = (screeningQuestionIds) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_QUESTION_IDS,
  screeningQuestionIds,
})

export const updateWaitlistServiceFlags = (waitlistServiceFlags) => ({
  type: UPDATE_FLU_SHOT_WAITLIST_SERVICE_FLAGS,
  waitlistServiceFlags,
})

export default reducer
