import React from 'react'
import { StyledSvgIcon } from './symptomIcon.styles'

export const SymptomSvgIcon = ({ ...props }) => {
  return (
    <StyledSvgIcon
      src={props.iconPath}
      width={props.width}
      height={props.height}
    />
  )
}
