import React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
// styles
import {
  TitleContainer,
  Title,
  Body,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import {
  ContactInformationContainer,
  SectionHeader,
} from './ContactInformation.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { transformToHumanReadableNumber } from '~/util/formatContact'
import { formatAddress } from '~/util/formatDisplay'

export const ContactInformation = () => {
  const { t } = useMedMeTranslation('patient')
  const { state } = useBookingContext()
  const {
    patient: {
      firstName,
      lastName,
      gender,
      sex,
      birthDate,
      phone: { home, cell },
      email,
      address: patientAddress,
    },
  } = state
  const personal = [firstName, lastName, gender, sex, birthDate]
  const addressSegments = formatAddress(patientAddress).split('\n')
  const contact = [cell, home, email]

  return (
    <div>
      <TitleContainer>
        <Title>{t('contactInformation.title')}</Title>
      </TitleContainer>
      <ContactInformationContainer>
        {personal.join('').length !== 0 && (
          <div>
            <Body>
              {firstName} {lastName}
            </Body>
            <Body>{t(gender) || t(sex)}</Body>
            <Body>{birthDate}</Body>
          </div>
        )}
        {addressSegments.length !== 0 && (
          <div>
            <SectionHeader>
              {t('contactInformation.address.title')}
            </SectionHeader>
            {addressSegments.map((segment, i) => (
              <Body key={i}>{segment}</Body>
            ))}
          </div>
        )}
        {contact.join('').length !== 0 && (
          <div>
            <SectionHeader>{t('contactTitle')}</SectionHeader>
            {cell && <Body>M: {transformToHumanReadableNumber(cell)}</Body>}
            {home && <Body>H: {transformToHumanReadableNumber(home)}</Body>}
            {email && <Body>{email}</Body>}
          </div>
        )}
      </ContactInformationContainer>
    </div>
  )
}
