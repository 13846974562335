import styled from 'styled-components'
import { PageLayout } from '~/sharedStyles/containers'
import { offWhite } from '~/sharedStyles/colorPalette'

export const Layout = styled(PageLayout)`
  font-family: ${({ theme }) => theme?.font?.main},
    ${({ theme }) => theme?.font?.generic};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 0 0;
  background-color: ${offWhite};
  padding-top: 8rem;
  overflow-y: visible;
  box-sizing: border-box;

  @media only screen and (orientation: landscape) and (min-width: 1065px) {
    padding-top: 5rem;
  }
`
