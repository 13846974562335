export default {
  'medme.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_STAGING_PHARMACY_ID,
  'medme-ns.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_NS_STAGING_PHARMACY_ID,
  'medme-ab.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_AB_STAGING_PHARMACY_ID,
  'medme-sk.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_SK_STAGING_PHARMACY_ID,
  'medme-nb.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_NB_STAGING_PHARMACY_ID,
  'medme-pe.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_PE_STAGING_PHARMACY_ID,
  'medme-mb.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_MB_STAGING_PHARMACY_ID,
  'medme-bc.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_BC_STAGING_PHARMACY_ID,
  'medme-on.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_ON_STAGING_PHARMACY_ID,
  'medme-nl.staging.medmeapp.com-pharmacyId':
    process.env.MEDME_NL_STAGING_PHARMACY_ID,
  'sdm-sit.staging.medemapp.com-pharmacyId':
    process.env.SDM_SIT_STAGING_PHARMACY_ID,
  'sdm-pt.staging.medmeapp.com-pharmacyId':
    process.env.SDM_PT_STAGING_PHARMACY_ID,
}
