import {
  AppointmentActivity,
  AppointmentActivityGroup as GqlAAG,
} from '~/graphql/types/schemaNode.type'
import { DeepPartial } from '../../../pages/AppointmentIntake/lib/types'
import { AppointmentActivityGroup } from '../AppointmentActivityGroup'

export const createAppointmentActivityGroup = (
  appointmentActivityGroup?: DeepPartial<GqlAAG> | null
): AppointmentActivityGroup => {
  const res = {
    id: appointmentActivityGroup?.id || '',
    title: appointmentActivityGroup?.title || '',
    subtitle: appointmentActivityGroup?.subtitle || '',
    description: appointmentActivityGroup?.description || '',
    subDescription: appointmentActivityGroup?.subDescription || '',
    iconUrl: appointmentActivityGroup?.iconUrl || '',
    urlParam: appointmentActivityGroup?.urlParam || '',
    children:
      appointmentActivityGroup?.children?.map(createAppointmentActivityGroup) ||
      [],
    appointmentActivity:
      (appointmentActivityGroup?.appointmentActivity as AppointmentActivity[]) ||
      [],
  }
  return res
}
