import { FormStep } from './FormStep/types'
import { PatientProgressNode } from '../../../AppointmentIntake/components/ProgressWidget/ProgressWidget'
import {
  CombinedTimeslot,
  EAppointmentTypeMethod,
} from '~/graphql/types/schemaNode.type'
import { ServiceDetails } from './PatientIntakeContext'
import { REVIEW_AND_CONFIRM_PAGE_PATH } from '../constants'
import { newBookingPaths } from '~/routes'

export const generateIntakeStepPath = (formStep: FormStep, search: string) => {
  const pathParts: string[] = ['/booking/intake']
  const patientIdx = formStep.patientIdx?.toString()
  if (patientIdx) pathParts.push(patientIdx)
  pathParts.push(formStep.questionnairePath)
  const urlPath = pathParts.join('/')
  return `${urlPath}${search}`
}

export const cleanName = (fullName: string) => {
  return fullName.replace(/\s+/g, ' ').trim().toLowerCase()
}

export const getIsWalkin = (timeSlot?: CombinedTimeslot) => {
  return timeSlot === null
}

const generateReviewStep = (reviewStep?: FormStep) => {
  return {
    label: reviewStep?.displayName || 'Review',
    open: false,
    done: false,
  }
}

const generateNodeLabel = (
  numberOfPatients: number,
  patientIdx: string,
  patientLabel: string
) => {
  return numberOfPatients === 1
    ? undefined
    : `${patientLabel} ${Number(patientIdx) + 1}`
}

// if location index matches patientIdx
const shouldNodeBeOpen = (
  numberOfPatients: number,
  patientIdx: string,
  currentQuestionnairePath: string,
  currentPatientIdx?: number
) => {
  if (currentQuestionnairePath === REVIEW_AND_CONFIRM_PAGE_PATH) {
    return numberOfPatients === 1 ? true : false
  }
  return Number(patientIdx) === Number(currentPatientIdx)
}

const shouldNodeBeDone = (
  patientIdx: string,
  currentQuestionnairePath: string,
  currentPatientIdx?: number
) => {
  if (currentQuestionnairePath === REVIEW_AND_CONFIRM_PAGE_PATH) {
    return true
  }

  // if patientIdx from url > patientIdx from the loop
  return Number(currentPatientIdx) > Number(patientIdx)
}

const isStepDone = (
  stepIndex: number,
  currentQuestionnairePath: string,
  currentQuestionnairePathIndex: number
) => {
  if (currentQuestionnairePath === REVIEW_AND_CONFIRM_PAGE_PATH) {
    return true
  }

  return stepIndex < currentQuestionnairePathIndex
}

const isCurrentStep = (
  stepIndex: number,
  currentQuestionnairePath: string,
  currentQuestionnairePathIndex: number,
  nodePatientIdx: number,
  currentPatientIdx?: number
) => {
  if (currentQuestionnairePath === REVIEW_AND_CONFIRM_PAGE_PATH) {
    return false
  }

  return (
    nodePatientIdx === currentPatientIdx &&
    stepIndex === currentQuestionnairePathIndex
  )
}

const generateStep = (
  stepLabel: string,
  stepIndex: number,
  currentQuestionnairePath: string,
  currentQuestionnairePathIndex: number,
  nodePatientIdx: number,
  currentPatientIdx?: number
) => {
  return {
    label: stepLabel,
    done: isStepDone(
      stepIndex,
      currentQuestionnairePath,
      currentQuestionnairePathIndex
    ),
    current: isCurrentStep(
      stepIndex,
      currentQuestionnairePath,
      currentQuestionnairePathIndex,
      nodePatientIdx,
      currentPatientIdx
    ),
  }
}

const generateNodeSteps = (
  steps: FormStep[],
  currentQuestionnairePath: string,
  currentQuestionnairePathIndex: number,
  nodePatientIdx: number,
  currentPatientIdx?: number
) => {
  return steps.map((step: FormStep, stepIndex: number) => {
    return generateStep(
      step.displayName,
      stepIndex,
      currentQuestionnairePath,
      currentQuestionnairePathIndex,
      nodePatientIdx,
      currentPatientIdx
    )
  })
}

export type GenerateProgressInput = {
  steps: FormStep[]
  questionnairePath: string
  patientIdx?: number
  patientLocalizedLabel: string
}

export const generateProgressInput = (
  input: GenerateProgressInput
): PatientProgressNode[] => {
  // group steps by patientId
  const {
    steps,
    questionnairePath: currentQuestionnairePath,
    patientIdx: currentPatientIdx,
    patientLocalizedLabel: patientLabel,
  } = input
  const stepsByPatient = steps.reduce((acc: any, step: FormStep) => {
    if (!step.patientIdx && step.patientIdx !== 0) {
      return acc
    }
    if (!acc[step.patientIdx]) {
      acc[step.patientIdx] = [step]
    } else {
      acc[step.patientIdx].push(step)
    }
    return acc
  }, {})

  const numberOfPatients = Object.keys(stepsByPatient).length
  const reviewStep = steps.find(
    (step) => step.questionnairePath === REVIEW_AND_CONFIRM_PAGE_PATH
  )

  if (currentQuestionnairePath === REVIEW_AND_CONFIRM_PAGE_PATH) {
    const patientProgressDoneNode = Object.keys(stepsByPatient).map(
      (nodePatientIdx: string) => {
        const steps = stepsByPatient[nodePatientIdx]
        return {
          open: shouldNodeBeOpen(
            numberOfPatients,
            nodePatientIdx,
            currentQuestionnairePath,
            currentPatientIdx
          ),
          done: shouldNodeBeDone(
            nodePatientIdx,
            currentQuestionnairePath,
            currentPatientIdx
          ),
          label: generateNodeLabel(
            numberOfPatients,
            nodePatientIdx,
            patientLabel
          ),
          steps: generateNodeSteps(
            steps,
            currentQuestionnairePath,
            0,
            Number(nodePatientIdx),
            currentPatientIdx
          ),
        } as PatientProgressNode
      }
    )

    patientProgressDoneNode.push(generateReviewStep(reviewStep))
    return patientProgressDoneNode
  }

  const patientNodes = Object.keys(stepsByPatient).map(
    (nodePatientIdx: string) => {
      const steps = stepsByPatient[nodePatientIdx]
      const questionnairePathArray = steps.map(
        (step: FormStep) => step.questionnairePath
      )
      const currentQuestionnairePathIndex = questionnairePathArray.indexOf(
        currentQuestionnairePath
      )

      const patientProgressNode: PatientProgressNode = {
        open: shouldNodeBeOpen(
          numberOfPatients,
          nodePatientIdx,
          currentQuestionnairePath,
          currentPatientIdx
        ),
        label: generateNodeLabel(
          numberOfPatients,
          nodePatientIdx,
          patientLabel
        ),
        done: shouldNodeBeDone(
          nodePatientIdx,
          currentQuestionnairePath,
          currentPatientIdx
        ),
        steps: generateNodeSteps(
          steps,
          currentQuestionnairePath,
          currentQuestionnairePathIndex,
          Number(nodePatientIdx),
          currentPatientIdx
        ),
      }
      return patientProgressNode
    }
  )

  patientNodes.push(generateReviewStep(reviewStep))

  return patientNodes
}

export const getProgressInputStatus = (
  input: PatientProgressNode[],
  urlPath: string
) => {
  // keep track of which step is currently being displayed for each patient
  const currentNodeIndexes = input.map((nodes) =>
    nodes.steps?.findIndex((step) => step.current === true)
  )

  // if the review step is being displayed, mark the last node as current for each patient
  if (
    urlPath === `${newBookingPaths.intake}/${REVIEW_AND_CONFIRM_PAGE_PATH}` ||
    urlPath === `${newBookingPaths.waitlist}/${REVIEW_AND_CONFIRM_PAGE_PATH}`
  ) {
    return input.map((nodes) => {
      const currentNodeIndex = nodes.steps?.findIndex(
        (step) => step.current === true
      )
      const stepsLength = nodes.steps?.length || 1
      if (currentNodeIndex === -1) {
        return stepsLength
      }
      return currentNodeIndex
    })
  }
  return currentNodeIndexes
}

export const getNextSteps = (
  serviceDetails: ServiceDetails,
  method: EAppointmentTypeMethod,
  isWalkin: boolean
) => {
  const {
    walkinBookingConfirmation,
    bookingConfirmation,
    bookingPhoneConfirmation,
    bookingVirtualConfirmation,
  } = serviceDetails

  if (isWalkin) return walkinBookingConfirmation
  if (method === 'VIRTUAL') return bookingVirtualConfirmation
  if (method === 'PHONE') return bookingPhoneConfirmation

  return bookingConfirmation
}
