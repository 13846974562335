export const darkBlue = '#4F81BD'
export const blue = '#4F81BD'
export const lightBlue = '#69A8F7'
export const chatBlue = '#e5ecf5'
export const optionBlue = '#e4ebf4'
export const optionActiveBlue = '#F0F4F9'
export const sidebarBlue = '#3e3a4e'
export const selectedPageBlue = '#736f86'
export const medicalBlue = '#F7F9FC'
export const registrationBlue = '#3e3a4e'
export const activeButtonBlue = '#F6F9FC'
export const secondaryActionBlue = '#416EA4'
export const slateBlue = '#4F81BD'
export const blueGray = '#B3C1D3'
export const plusBlue = '#d9e6eb'
export const smsBubbleBlue = '#EAEAEA'
export const filterBlue = '#D8E1EB'
export const teal = '#00ADA9'
export const tealBlue = '#007BA2'
export const materialUiBlue = '#3F51B5'
export const loadingBlue = '#819ea9'

export const darkOrange = '#F49542'
export const orange = '#F7A258'
export const lightOrange = 'F7B57C'

export const darkPink = '#FF7F7F'

export const darkGreen = '#3AB972'
export const green = '#5FC68B'
export const lightGreen = '#B7E8B1'
export const samlGreen = '#00B2A9'

export const darkViolet = '#ACBBE8'

export const lightRed = '#ED8B90'
export const red = '#ff0000'
export const mistyRoseRed = '#fce6e6'
export const mahoganyRed = '#8f6c6c'

export const darkGray = '#707070'
export const unavailableGray = '#292929'
export const gray = '#6d6d6d'
export const chatGray = '#f1f0f0'
export const smokeGray = '#9c9c9c'
export const lightGray = '#D7D7D7'
export const headerGray = '#f4f7f8'
export const formGray = '#878A8C'
export const covidGray = '#bec2c4'
export const followUpGray = '#b3c1d3'
export const activeGray = '#efeef4'
export const disabledGray = '#F7F7F7'
export const idleDarkGray = '#363636'
export const settingsGray = '#EDEBF5'
export const storeGray = '#e8e8e8'
export const patientCountGray = '#DBDEDF'
export const paleGray = '#e6e6e6'

export const offWhite = '#F4F7F8'
export const offWhiteTranslucent = 'rgba(243, 246, 247, 0.85)'
export const black = '#000000'
export const white = '#ffffff'

export const indigo = '#063E54'
export const darkIndigo = '#032431'
export const primary = indigo

// Rexall colors
export const poolsideBlue = '#00B2A9'
export const metroGray = '#C7C9C7'
export const sunshineYellow = '#FFCD00'
export const warmGray = '#9EA2A2'
export const wellCaGreen = '#6EBE4A'
export const ironGray = '#5F6062'

export const danger = '#940000'
export const warning = '#ffcc00'
export const needsPrint = '#A2116D'
export const exploBackground = '#F0F0F0'

// Patient Intake colors
export const textPrimary = 'rgba(0, 0, 0, 0.8)'
export const textSecondary = 'rgba(0, 0, 0, 0.6)'
export const textTertiary = 'rgba(0, 0, 0, 0.2)'
export const completedGreen = '#5FC68B'
export const apptTypeIconBackground = '#FFF7F2'
export const medMeLightGreen = '#C3C430'

// TODO: Populate this object with colors from the figma style library if you are using figma
export const medmeTheme = {
  light: {
    primary: {
      main: indigo,
    },
    info: {
      main: '#0288D1',
      shade: {
        '50p': 'rgba(2, 136, 209, 0.1)', // TODO: opacity is 0.5 in figma, but 0.1 is needed for component because figma using opacity 0.2 css broke children
      },
    },
  },
}

// Default Theme
export const defaultTheme = {
  color: {
    backgroundPrimary: indigo,
    backgroundSecondary: 'white',
    titlePrimary: 'black',
    titleSecondary: blue,
    titleTertiary: 'white',
    textPrimary,
    textSecondary,
    textTertiary,
    buttonPrimary: indigo,
    buttonSecondary: 'black',
    buttonTertiary: darkGray,
  },
  font: {
    main: 'Montserrat',
    heading: 'Urbanist',
    generic: 'sans-serif',
  },
}
