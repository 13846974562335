import env from './env.medmeapp.ca.json'
import oldProd from './env.prod.json'

export default {
  'medmepharmacy.medmeapp.ca': oldProd.MEDME_ON_TENANT_ID,
  'ms0410.medmeapp.ca': oldProd.MS0410_TENANT_ID,
  'medshop389.medmeapp.ca': oldProd.MEDSHOP389_TENANT_ID,
  'ms320.medmeapp.ca': oldProd.MS320_TENANT_ID,
  'medshop111.medmeapp.ca': oldProd.MEDSHOP111_TENANT_ID,
  'medicineshoppe404.medmeapp.ca': oldProd.MEDICINESHOPPE404_TENANT_ID,
  'lapaixpharmacy.medmeapp.ca': oldProd.LAPAIXPHARMACY_TENANT_ID,
  'universalhealthpharmacy.medmeapp.ca':
    oldProd.UNIVERSALHEALTHPHARMACY_TENANT_ID,
  'ps392.medmeapp.ca': oldProd.PS392_TENANT_ID,
  'ps393.medmeapp.ca': oldProd.PS393_TENANT_ID,
  'burnabypharmacy.medmeapp.ca': oldProd.BURNABYPHARMACY_TENANT_ID,
  'marketmall.medmeapp.ca': oldProd.MARKETMALL_TENANT_ID,
  'meadowsmile.medmeapp.ca': oldProd.MEADOWSMILE_TENANT_ID,
  'newportpharma.medmeapp.ca': oldProd.NEWPORTPHARMA_TENANT_ID,
  'idagriesbach.medmeapp.ca': oldProd.IDAGRIESBACH_TENANT_ID,
  'pilotmoundpharmacy.medmeapp.ca': oldProd.PILOTMOUNDPHARMACY_TENANT_ID,
  'chestermere.medmeapp.ca': oldProd.CHESTERMERE_TENANT_ID,
  'stonewallpharmacy.medmeapp.ca': oldProd.STONEWALLPHARMACY_TENANT_ID,
  'pharmasaveoncentre.medmeapp.ca': oldProd.PHARMASAVEONCENTRE_TENANT_ID,
  'pharmasavedominion.medmeapp.ca': oldProd.PHARMASAVEDOMINION_TENANT_ID,
  'medshoppe380.medmeapp.ca': oldProd.MEDSHOPPE380_TENANT_ID,
  'meritidapharmacy.medmeapp.ca': oldProd.MERITIDAPHARMACY_TENANT_ID,
  'elmsdalerx.medmeapp.ca': oldProd.ELMSDALERX_TENANT_ID,
  'pharmacyfirst.medmeapp.ca': oldProd.PHARMACYFIRST_TENANT_ID,
  'tantallonpharmasave.medmeapp.ca': oldProd.TANTALLONPHARMASAVE_TENANT_ID,
  'bridgewaterguardian.medmeapp.ca': oldProd.BRIDGEWATERGUARDIAN_TENANT_ID,
  'gulfcanadamedicineshoppe.medmeapp.ca':
    oldProd.GULFCANADAMEDICINESHOPPE_TENANT_ID,
  'hawkwoodmedicineshoppe.medmeapp.ca':
    oldProd.HAWKWOODMEDICINESHOPPE_TENANT_ID,
  'bearspawpharmacy.medmeapp.ca': oldProd.BEARSPAWPHARMACY_TENANT_ID,
  'dcp.medmeapp.ca': oldProd.DCP_TENANT_ID,
  'sharbotlakepharmacy.medmeapp.ca': oldProd.SHARBOTLAKEPHARMACY_TENANT_ID,
  'montaguepharmasave.medmeapp.ca': oldProd.MONTAGUEPHARMASAVE_TENANT_ID,
  'rylanderpharmasave.medmeapp.ca': oldProd.RYLANDERPHARMASAVE_TENANT_ID,
  'westkingpharmacy.medmeapp.ca': oldProd.WESTKINGPHARMACY_TENANT_ID,
  'viccompharmacy.medmeapp.ca': oldProd.VICCOMPHARMACY_TENANT_ID,
  'joanspharmacy.medmeapp.ca': oldProd.JOANSPHARMACY_TENANT_ID,
  'plapmedicineshoppe.medmeapp.ca': oldProd.PLAPMEDICINESHOPPE_TENANT_ID,
  'brandonmedicineshoppe.medmeapp.ca': oldProd.BRANDONMEDICINESHOPPE_TENANT_ID,
  'ms403.medmeapp.ca': oldProd.MS403_TENANT_ID,
  'campusmedicineshoppe.medmeapp.ca': oldProd.CAMPUSMEDICINESHOPPE_TENANT_ID,
  'belleriverpharmasave.medmeapp.ca': oldProd.BELLERIVERPHARMASAVE_TENANT_ID,
  'lawlor.medmeapp.ca': oldProd.LAWLOR_TENANT_ID,
  'medicineshoppestvital.medmeapp.ca': oldProd.MEDICINESHOPPESTVITAL_TENANT_ID,
  'thecornerdrugstore.medmeapp.ca': oldProd.THECORNERDRUGSTORE_TENANT_ID,
  'cardstonpharmasave.medmeapp.ca': oldProd.CARDSTONPHARMASAVE_TENANT_ID,
  'medicineshoppepembina.medmeapp.ca': oldProd.MEDICINESHOPPEPEMBINA_TENANT_ID,
  'pharmacycoop.medmeapp.ca': oldProd.PHARMACYCOOP_TENANT_ID,
  'ststephenguardian.medmeapp.ca': oldProd.STSTEPHENGUARDIAN_TENANT_ID,
  'albertcountypharmacy.medmeapp.ca': oldProd.ALBERTCOUNTYPHARMACY_TENANT_ID,
  'hamptonguardianpharmacy.medmeapp.ca':
    oldProd.HAMPTONGUARDIANPHARMACY_TENANT_ID,
  'eaglemanor.medmeapp.ca': oldProd.EAGLEMANOR_TENANT_ID,
  'medshoppe264.medmeapp.ca': oldProd.MEDSHOPPE264_TENANT_ID,
  'pharmacyassociates.medmeapp.ca': oldProd.PHARMACYASSOCIATES_TENANT_ID,
  'plainsrdwestpharmacy.medmeapp.ca': oldProd.PLAINSRDWESTPHARMACY_TENANT_ID,
  'medshoppe353.medmeapp.ca': oldProd.MEDSHOPPE353_TENANT_ID,
  'quarrypharmacy.medmeapp.ca': oldProd.QUARRYPHARMACY_TENANT_ID,
  'spadinamedicalpharmacy.medmeapp.ca':
    oldProd.SPADINAMEDICALPHARMACY_TENANT_ID,
  'eastyorkpharmasave.medmeapp.ca': oldProd.EASTYORKPHARMASAVE_TENANT_ID,
  'citypharmacy.medmeapp.ca': oldProd.CITYPHARMACY_TENANT_ID,
  'ms386.medmeapp.ca': oldProd.MS386_TENANT_ID,
  'tepharmasave.medmeapp.ca': oldProd.TEPHARMASAVE_TENANT_ID,
  'bathpharmacy.medmeapp.ca': oldProd.BATHPHARMACY_TENANT_ID,
  'inverarypharmasave.medmeapp.ca': oldProd.INVERARYPHARMASAVE_TENANT_ID,
  'dickisons.medmeapp.ca': oldProd.DICKISONS_TENANT_ID,
  'beachespharmasave.medmeapp.ca': oldProd.BEACHESPHARMASAVE_TENANT_ID,
  'sanomedpharmasave.medmeapp.ca': oldProd.SANOMEDPHARMASAVE_TENANT_ID,
  'kip.medmeapp.ca': oldProd.KIP_TENANT_ID,
  'southlandpharmacy.medmeapp.ca': oldProd.SOUTHLANDPHARMACY_TENANT_ID,
  'hollypharmacy.medmeapp.ca': oldProd.HOLLYPHARMACY_TENANT_ID,
  'restondrugs.medmeapp.ca': oldProd.RESTONDRUGS_TENANT_ID,
  'northgatemedshoppe.medmeapp.ca': oldProd.NORTHGATEMEDSHOPPE_TENANT_ID,
  'gibbonsguardian.medmeapp.ca': oldProd.GIBBONSGUARDIAN_TENANT_ID,
  'highparkpharmacy.medmeapp.ca': oldProd.HIGHPARKPHARMACY_TENANT_ID,
  'junctionpharmacy.medmeapp.ca': oldProd.JUNCTIONPHARMACY_TENANT_ID,
  'canadiancompoundingpharmacy.medmeapp.ca':
    oldProd.CANADIANCOMPOUNDINGPHARMACY_TENANT_ID,
  'coaldalepharmasave.medmeapp.ca': oldProd.COALDALEPHARMASAVE_TENANT_ID,
  'prod-bc.medmeapp.ca': oldProd.PRODBC_TENANT_ID,
  'ps794.medmeapp.ca': oldProd.PS794_TENANT_ID,
  'boggiofonthillpharmacy.medmeapp.ca':
    oldProd.BOGGIOFONTHILLPHARMACY_TENANT_ID,
  'boggiopharmacy.medmeapp.ca': oldProd.BOGGIOPHARMACY_TENANT_ID,
  'boggiomackinnonpharmacy.medmeapp.ca':
    oldProd.BOGGIOMACKINNONPHARMACY_TENANT_ID,
  'boggiogrimsbypharmacy.medmeapp.ca': oldProd.BOGGIOGRIMSBYPHARMACY_TENANT_ID,
  'boggioedwards.medmeapp.ca': oldProd.BOGGIOEDWARDS_TENANT_ID,
  'creemorevillagepharmacy.medmeapp.ca':
    oldProd.CREEMOREVILLAGEPHARMACY_TENANT_ID,
  'wortleypharmasave.medmeapp.ca': oldProd.WORTLEYPHARMASAVE_TENANT_ID,
  'kqpharmacy.medmeapp.ca': oldProd.KQPHARMACY_TENANT_ID,
  'medicineshoppe360.medmeapp.ca': oldProd.MEDICINESHOPPE360_TENANT_ID,
  'medicineshoppe303.medmeapp.ca': oldProd.MEDICINESHOPPE303_TENANT_ID,
  'bramptonpharmacist.medmeapp.ca': oldProd.BRAMPTONPHARMACIST_TENANT_ID,
  'medicineshoppe396.medmeapp.ca': oldProd.MEDICINESHOPPE396_TENANT_ID,
  'medicineshoppeyorkton.medmeapp.ca': oldProd.MEDICINESHOPPEYORKTON_TENANT_ID,
  'tobiapharmacy.medmeapp.ca': oldProd.TOBIAPHARMACY_TENANT_ID,
  'pharmasave720.medmeapp.ca': oldProd.PHARMASAVE720_TENANT_ID,
  'pharmacylakeside.medmeapp.ca': oldProd.PHARMACYLAKESIDE_TENANT_ID,
  'bowmanvilleclinic.medmeapp.ca': oldProd.BOWMANVILLECLINIC_TENANT_ID,
  'harrowsmithpharmacy.medmeapp.ca': oldProd.HARROWSMITHPHARMACY_TENANT_ID,
  'keeleandfinchpharmasave.medmeapp.ca':
    oldProd.KEELEANDFINCHPHARMASAVE_TENANT_ID,
  'kingsvilleida.medmeapp.ca': oldProd.KINGSVILLEIDA_TENANT_ID,
  'westminsterida.medmeapp.ca': oldProd.WESTMINSTERIDA_TENANT_ID,
  'medicineshoppe161.medmeapp.ca': oldProd.MEDICINESHOPPE161_TENANT_ID,
  'brookspharmasave.medmeapp.ca': oldProd.BROOKSPHARMASAVE_TENANT_ID,
  'breslaucommonspharmacy.medmeapp.ca':
    oldProd.BRESLAUCOMMONSPHARMACY_TENANT_ID,
  'nextdoorpharmacy.medmeapp.ca': oldProd.NEXTDOORPHARMACY_TENANT_ID,
  'gardinerspharmacy.medmeapp.ca': oldProd.GARDINERSPHARMACY_TENANT_ID,
  'medshopperochdale.medmeapp.ca': oldProd.MEDSHOPPEROCHDALE_TENANT_ID,
  'porthealthpharmasave.medmeapp.ca': oldProd.PORTHEALTHPHARMASAVE_TENANT_ID,
  'pharmaciecocagne.medmeapp.ca': oldProd.PHARMACIECOCAGNE_TENANT_ID,
  'healthsmart.medmeapp.ca': oldProd.HEALTHSMART_TENANT_ID,
  'norarmpharmacy.medmeapp.ca': oldProd.NORARMPHARMACY_TENANT_ID,
  'unicarepharmacy.medmeapp.ca': oldProd.UNICAREPHARMACY_TENANT_ID,
  'morrisonpharmasave.medmeapp.ca': oldProd.MORRISONPHARMASAVE_TENANT_ID,
  'allcurespharmacy.medmeapp.ca': oldProd.ALLCURESPHARMACY_TENANT_ID,
  'msp121.medmeapp.ca': oldProd.MSP121_TENANT_ID,
  'lakewood.medmeapp.ca': oldProd.LAKEWOOD_TENANT_ID,
  'ms0214.medmeapp.ca': oldProd.MS0214_TENANT_ID,
  'peopleschoiceremedy.medmeapp.ca': oldProd.PEOPLESCHOICEREMEDY_TENANT_ID,
  'healthmaxpharmacy.medmeapp.ca': oldProd.HEALTHMAXPHARMACY_TENANT_ID,
  'shermanida.medmeapp.ca': oldProd.SHERMANIDA_TENANT_ID,
  'medicineshoppekillarney.medmeapp.ca':
    oldProd.MEDICINESHOPPEKILLARNEY_TENANT_ID,
  'kennedyroadpharmacy.medmeapp.ca': oldProd.KENNEDYROADPHARMACY_TENANT_ID,
  'carepharmacy.medmeapp.ca': oldProd.CAREPHARMACY_TENANT_ID,
  'lacliepharmacy.medmeapp.ca': oldProd.LACLIEPHARMACY_TENANT_ID,
  'jacksonspharmasave.medmeapp.ca': oldProd.JACKSONSPHARMASAVE_TENANT_ID,
  'innisfilpharmasave.medmeapp.ca': oldProd.INNISFILPHARMASAVE_TENANT_ID,
  'ms228.medmeapp.ca': oldProd.MS228_TENANT_ID,
  'westmountmedicalpharmacy.medmeapp.ca':
    oldProd.WESTMOUNTMEDICALPHARMACY_TENANT_ID,
  'ruhmedicineshoppe.medmeapp.ca': oldProd.RUHMEDICINESHOPPE_TENANT_ID,
  'virdendrugs.medmeapp.ca': oldProd.VIRDENDRUGS_TENANT_ID,
  'rxdrugmartsmithville.medmeapp.ca': oldProd.RXDRUGMARTSMITHVILLE_TENANT_ID,
  'dundaspharmasave.medmeapp.ca': oldProd.DUNDASPHARMASAVE_TENANT_ID,
  'beamsvillepharmacy.medmeapp.ca': oldProd.BEAMSVILLEPHARMACY_TENANT_ID,
  'lincolnpharmacy.medmeapp.ca': oldProd.LINCOLNPHARMACY_TENANT_ID,
  'melvillepharmasave.medmeapp.ca': oldProd.MELVILLEPHARMASAVE_TENANT_ID,
  'warmanpharmasave.medmeapp.ca': oldProd.WARMANPHARMASAVE_TENANT_ID,
  'ms317.medmeapp.ca': oldProd.MS317_TENANT_ID,
  'siroccopharmacy.medmeapp.ca': oldProd.SIROCCOPHARMACY_TENANT_ID,
  'capsulepharmacy.medmeapp.ca': oldProd.CAPSULEPHARMACY_TENANT_ID,
  'lendrumpharmacy.medmeapp.ca': oldProd.LENDRUMPHARMACY_TENANT_ID,
  'pharmasavecedarcourt.medmeapp.ca': oldProd.PHARMASAVECEDARCOURT_TENANT_ID,
  'wppharmacy.medmeapp.ca': oldProd.WPPHARMACY_TENANT_ID,
  'chappelle.medmeapp.ca': oldProd.CHAPPELLE_TENANT_ID,
  'medicineshoppe251.medmeapp.ca': oldProd.MEDICINESHOPPE251_TENANT_ID,
  'medicineshoppe120.medmeapp.ca': oldProd.MEDICINESHOPPE120_TENANT_ID,
  'gracepharmacy.medmeapp.ca': oldProd.GRACEPHARMACY_TENANT_ID,
  'barriefieldpharmasave.medmeapp.ca': oldProd.BARRIEFIELDPHARMASAVE_TENANT_ID,
  'medicineshoppeairdrie.medmeapp.ca': oldProd.MEDICINESHOPPEAIRDRIE_TENANT_ID,
  'yakimetspharmacy.medmeapp.ca': oldProd.YAKIMETSPHARMACY_TENANT_ID,
  'markdalepharmacy.medmeapp.ca': oldProd.MARKDALEPHARMACY_TENANT_ID,
  'foresthillspharmasave.medmeapp.ca': oldProd.FORESTHILLSPHARMASAVE_TENANT_ID,
  'summersidepharmasave.medmeapp.ca': oldProd.SUMMERSIDEPHARMASAVE_TENANT_ID,
  'royaloakpharmacy.medmeapp.ca': oldProd.ROYALOAKPHARMACY_TENANT_ID,
  'wlremedysrx.medmeapp.ca': oldProd.WLREMEDYSRX_TENANT_ID,
  'bonaventurepharmacy.medmeapp.ca': oldProd.BONAVENTUREPHARMACY_TENANT_ID,
  'medshoppevernon.medmeapp.ca': oldProd.MEDSHOPPEVERNON_TENANT_ID,
  'whitbymedicineshoppe.medmeapp.ca': oldProd.WHITBYMEDICINESHOPPE_TENANT_ID,
  'pharmaciestisidore.medmeapp.ca': oldProd.PHARMACIESTISIDORE_TENANT_ID,
  'pellowpharmasave.medmeapp.ca': oldProd.PELLOWPHARMASAVE_TENANT_ID,
  'achesonpharmasave.medmeapp.ca': oldProd.ACHESONPHARMASAVE_TENANT_ID,
  'kristenspharmacy.medmeapp.ca': oldProd.KRISTENSPHARMACY_TENANT_ID,
  'quickcarepharmacy.medmeapp.ca': oldProd.QUICKCAREPHARMACY_TENANT_ID,
  'landmarkpharmacy.medmeapp.ca': oldProd.LANDMARKPHARMACY_TENANT_ID,
  'nobletonpharmasave.medmeapp.ca': oldProd.NOBLETONPHARMASAVE_TENANT_ID,
  'kensingtonpharmacy.medmeapp.ca': oldProd.KENSINGTONPHARMACY_TENANT_ID,
  'westbrampharmacy.medmeapp.ca': oldProd.WESTBRAMPHARMACY_TENANT_ID,
  'lionheadpharmacy.medmeapp.ca': oldProd.LIONHEADPHARMACY_TENANT_ID,
  'setonpharmacy.medmeapp.ca': oldProd.SETONPHARMACY_TENANT_ID,
  'medicineshoppe119.medmeapp.ca': oldProd.MEDICINESHOPPE119_TENANT_ID,
  'pharmasave439.medmeapp.ca': oldProd.PHARMASAVE439_TENANT_ID,
  'gordonpharmasave.medmeapp.ca': oldProd.GORDONPHARMASAVE_TENANT_ID,
  'purehealth.medmeapp.ca': oldProd.PUREHEALTH_TENANT_ID,
  'medicineshoppe216.medmeapp.ca': oldProd.MEDICINESHOPPE216_TENANT_ID,
  'evapharmacy.medmeapp.ca': oldProd.EVAPHARMACY_TENANT_ID,
  'bramcitypharmacy.medmeapp.ca': oldProd.BRAMCITYPHARMACY_TENANT_ID,
  'arcade.medmeapp.ca': oldProd.ARCADE_TENANT_ID,
  'mgp.medmeapp.ca': oldProd.MGP_TENANT_ID,
  'mainstreetpharmasave.medmeapp.ca': oldProd.MAINSTREETPHARMASAVE_TENANT_ID,
  'thornburypharmasave.medmeapp.ca': oldProd.THORNBURYPHARMASAVE_TENANT_ID,
  'pharmasave41stknight.medmeapp.ca': oldProd.PHARMASAVE41STKNIGHT_TENANT_ID,
  'royalmedicalpharmasave.medmeapp.ca':
    oldProd.ROYALMEDICALPHARMASAVE_TENANT_ID,
  'medcab.medmeapp.ca': oldProd.MEDCAB_TENANT_ID,
  'londonroadpharmacy.medmeapp.ca': oldProd.LONDONROADPHARMACY_TENANT_ID,
  'hanoverpharmasave.medmeapp.ca': oldProd.HANOVERPHARMASAVE_TENANT_ID,
  'aubinpharmasave.medmeapp.ca': oldProd.AUBINPHARMASAVE_TENANT_ID,
  'ultracarepharmacy.medmeapp.ca': oldProd.ULTRACAREPHARMACY_TENANT_ID,
  'saltspringpharmasave.medmeapp.ca': oldProd.SALTSPRINGPHARMASAVE_TENANT_ID,
  'grandriverpharmacy.medmeapp.ca': oldProd.GRANDRIVERPHARMACY_TENANT_ID,
  'primacarepharmacy.medmeapp.ca': oldProd.PRIMACAREPHARMACY_TENANT_ID,
  'rutherfordguardian.medmeapp.ca': oldProd.RUTHERFORDGUARDIAN_TENANT_ID,
  'huntspharmacy.medmeapp.ca': oldProd.HUNTSPHARMACY_TENANT_ID,
  'pringlecreekpharmacy.medmeapp.ca': oldProd.PRINGLECREEKPHARMACY_TENANT_ID,
  'metcalfepharmacy.medmeapp.ca': oldProd.METCALFEPHARMACY_TENANT_ID,
  'silvercreek.medmeapp.ca': oldProd.SILVERCREEK_TENANT_ID,
  'columbiastreetpharmacy.medmeapp.ca':
    oldProd.COLUMBIASTREETPHARMACY_TENANT_ID,
  'stirlingpharmacy.medmeapp.ca': oldProd.STIRLINGPHARMACY_TENANT_ID,
  'moosecreekpharmacy.medmeapp.ca': oldProd.MOOSECREEKPHARMACY_TENANT_ID,
  'kleospharmacy.medmeapp.ca': oldProd.KLEOSPHARMACY_TENANT_ID,
  'cwrrx.medmeapp.ca': oldProd.CWRRX_TENANT_ID,
  'carlingwoodpharmasave.medmeapp.ca': oldProd.CARLINGWOODPHARMASAVE_TENANT_ID,
  'riverrunpharmacy.medmeapp.ca': oldProd.RIVERRUNPHARMACY_TENANT_ID,
  'grandparkpharmacy.medmeapp.ca': oldProd.GRANDPARKPHARMACY_TENANT_ID,
  'medicineshoppebeverly.medmeapp.ca': oldProd.MEDICINESHOPPEBEVERLY_TENANT_ID,
  'msfleetwood.medmeapp.ca': oldProd.MSFLEETWOOD_TENANT_ID,
  'pharmasavefh.medmeapp.ca': oldProd.PHARMASAVEFH_TENANT_ID,
  'medshoppe315.medmeapp.ca': oldProd.MEDSHOPPE315_TENANT_ID,
  'uppervillagepharmasave.medmeapp.ca':
    oldProd.UPPERVILLAGEPHARMASAVE_TENANT_ID,
  'simcoesquareguardian.medmeapp.ca': oldProd.SIMCOESQUAREGUARDIAN_TENANT_ID,
  'ferndalerxpharmacy.medmeapp.ca': oldProd.FERNDALERXPHARMACY_TENANT_ID,
  'glenmorekelownapharmasave.medmeapp.ca':
    oldProd.GLENMOREKELOWNAPHARMASAVE_TENANT_ID,
  'springdalepharmacy.medmeapp.ca': oldProd.SPRINGDALEPHARMACY_TENANT_ID,
  'primarycarepharmacy.medmeapp.ca': oldProd.PRIMARYCAREPHARMACY_TENANT_ID,
  'rrxespanola.medmeapp.ca': oldProd.RRXESPANOLA_TENANT_ID,
  'brantarts.medmeapp.ca': oldProd.BRANTARTS_TENANT_ID,
  'rrxbrantford.medmeapp.ca': oldProd.RRXBRANTFORD_TENANT_ID,
  'brinkleypharmacy.medmeapp.ca': oldProd.BRINKLEYPHARMACY_TENANT_ID,
  'summeridgeguardian.medmeapp.ca': oldProd.SUMMERIDGEGUARDIAN_TENANT_ID,
  'noblepharmacy.medmeapp.ca': oldProd.NOBLEPHARMACY_TENANT_ID,
  'southfieldspharmasave.medmeapp.ca': oldProd.SOUTHFIELDSPHARMASAVE_TENANT_ID,
  'portelginpharmasave.medmeapp.ca': oldProd.PORTELGINPHARMASAVE_TENANT_ID,
  'jonsmedshoppe.medmeapp.ca': oldProd.JONSMEDSHOPPE_TENANT_ID,
  'nhfp.medmeapp.ca': oldProd.NHFP_TENANT_ID,
  'procarepharmacy.medmeapp.ca': oldProd.PROCAREPHARMACY_TENANT_ID,
  'medbrandsida.medmeapp.ca': oldProd.MEDBRANDSIDA_TENANT_ID,
  'medartspharmasave.medmeapp.ca': oldProd.MEDARTSPHARMASAVE_TENANT_ID,
  'northernshorespc.medmeapp.ca': oldProd.NORTHERNSHORESPC_TENANT_ID,
  'unicare.medmeapp.ca': oldProd.UNICARE_TENANT_ID,
  'tuscanyguardian.medmeapp.ca': oldProd.TUSCANYGUARDIAN_TENANT_ID,
  'emeraldhillspharmasave.medmeapp.ca':
    oldProd.EMERALDHILLSPHARMASAVE_TENANT_ID,
  'nottinghampharmasave.medmeapp.ca': oldProd.NOTTINGHAMPHARMASAVE_TENANT_ID,
  'brentwoodpharmasave.medmeapp.ca': oldProd.BRENTWOODPHARMASAVE_TENANT_ID,
  'ps364.medmeapp.ca': oldProd.PS364_TENANT_ID,
  'trailsidepharmasave.medmeapp.ca': oldProd.CWPHARMASAVE_TENANT_ID,
  'caravaggioida.medmeapp.ca': oldProd.CARAVAGGIOIDA_TENANT_ID,
  'williamsburgremedyrx.medmeapp.ca': oldProd.WILLIAMSBURGREMEDYRX_TENANT_ID,
  'newmarketpharmacy.medmeapp.ca': oldProd.NEWMARKETPHARMACY_TENANT_ID,
  'roulstonswellnesscentre.medmeapp.ca':
    oldProd.ROULSTONSWELLNESSCENTRE_TENANT_ID,
  'kortrightpharmacy.medmeapp.ca': oldProd.KORTRIGHTPHARMACY_TENANT_ID,
  'acadiadrivepharmacy.medmeapp.ca': oldProd.ACADIADRIVEPHARMACY_TENANT_ID,
  'kozlovpharmacy.medmeapp.ca': oldProd.KOZLOVPHARMACY_TENANT_ID,
  'sullivanspharmacy.medmeapp.ca': oldProd.SULLIVANSPHARMACY_TENANT_ID,
  'thorntonpharmacy.medmeapp.ca': oldProd.THORNTONPHARMACY_TENANT_ID,
  'robinspharmacy.medmeapp.ca': oldProd.ROBINSPHARMACY_TENANT_ID,
  'paulspharmasave.medmeapp.ca': oldProd.PAULSPHARMASAVE_TENANT_ID,
  'caledoneastguardian.medmeapp.ca': oldProd.CALEDONEASTGUARDIAN_TENANT_ID,
  'paramountpharmasave.medmeapp.ca': oldProd.PARAMOUNTPHARMASAVE_TENANT_ID,
  'morelliswalkerspharmacy.medmeapp.ca':
    oldProd.MORELLISWALKERSPHARMACY_TENANT_ID,
  'atlaspharmacy.medmeapp.ca': oldProd.ATLASPHARMACY_TENANT_ID,
  'hawkesburypharmacy.medmeapp.ca': oldProd.HAWKESBURYPHARMACY_TENANT_ID,
  'woodlandpharmacy.medmeapp.ca': oldProd.WOODLANDPHARMACY_TENANT_ID,
  'truscottpharmacy.medmeapp.ca': oldProd.TRUSCOTTPHARMACY_TENANT_ID,
  'gorepharmacy.medmeapp.ca': oldProd.GOREPHARMACY_TENANT_ID,
  'uptownapothecary.medmeapp.ca': oldProd.UPTOWNAPOTHECARY_TENANT_ID,
  'medicinepointida.medmeapp.ca': oldProd.MEDICINEPOINTIDA_TENANT_ID,
  'charolaisida.medmeapp.ca': oldProd.CHAROLAISIDA_TENANT_ID,
  'cwpharmasave.medmeapp.ca': oldProd.ELORATRAILSIDEPHARMASAVE_TENANT_ID,
  'milfordpharmasave.medmeapp.ca': oldProd.MILFORDPHARMASAVE_TENANT_ID,
  'pharmasave9745.medmeapp.ca': oldProd.PHARMASAVE9745_TENANT_ID,
  'whitfieldguardian.medmeapp.ca': oldProd.WHITFIELDGUARDIAN_TENANT_ID,
  'lucknowpharmasave.medmeapp.ca': oldProd.LUCKNOWPHARMASAVE_TENANT_ID,
  'phs711.medmeapp.ca': oldProd.PHS711_TENANT_ID,
  'bossonspharmacy.medmeapp.ca': oldProd.BOSSONSPHARMACY_TENANT_ID,
  'beaumontcentrepharmacy.medmeapp.ca':
    oldProd.BEAUMONTCENTREPHARMACY_TENANT_ID,
  'kanatapharmasave.medmeapp.ca': oldProd.KANATAPHARMASAVE_TENANT_ID,
  'westlockida.medmeapp.ca': oldProd.PIDSADOWSKIIDA_TENANT_ID,
  'pharmasaveuxbridge.medmeapp.ca': oldProd.PHARMASAVEUXBRIDGE_TENANT_ID,
  'pharmacymgr.medmeapp.ca': oldProd.PHARMACYMGR_TENANT_ID,
  'manhattanguardianpharmacy.medmeapp.ca':
    oldProd.MANHATTANGUARDIANPHARMACY_TENANT_ID,
  'ingersollpharmasave.medmeapp.ca': oldProd.INGERSOLLPHARMASAVE_TENANT_ID,
  'cookspharmacy.medmeapp.ca': oldProd.COOKSPHARMACY_TENANT_ID,
  'alconapharmacy.medmeapp.ca': oldProd.ALCONAPHARMACY_TENANT_ID,
  'ghpharmasave.medmeapp.ca': oldProd.GHPHARMASAVE_TENANT_ID,
  'robinsonsida.medmeapp.ca': oldProd.ROBINSONSIDA_TENANT_ID,
  'cyrilpharmasave.medmeapp.ca': oldProd.CYRILPHARMASAVE_TENANT_ID,
  'riepertpharmacy.medmeapp.ca': oldProd.RIEPERTPHARMACY_TENANT_ID,
  'myerspharmacy.medmeapp.ca': oldProd.MYERSPHARMACY_TENANT_ID,
  'nkshealthmississauga.medmeapp.ca': oldProd.NKSHEALTHMISSISSAUGA_TENANT_ID,
  'pharmaciewendover.medmeapp.ca': oldProd.PHARMACIEWENDOVER_TENANT_ID,
  'westendpharmasave.medmeapp.ca': oldProd.WESTENDPHARMASAVE_TENANT_ID,
  'idastouffville.medmeapp.ca': oldProd.IDASTOUFFVILLE_TENANT_ID,
  'youngspharmacyandhomecare.medmeapp.ca':
    oldProd.YOUNGSPHARMACYANDHOMECARE_TENANT_ID,
  'beavertonida.medmeapp.ca': oldProd.BEAVERTONIDA_TENANT_ID,
  'gravenhurstida.medmeapp.ca': oldProd.GRAVENHURSTIDA_TENANT_ID,
  'morellisboxgrove.medmeapp.ca': oldProd.MORELLISBOXGROVE_TENANT_ID,
  'meadowlands.medmeapp.ca': oldProd.MEADOWLANDS_TENANT_ID,
  'ps647.medmeapp.ca': oldProd.PS647_TENANT_ID,
  'carlspharmacy.medmeapp.ca': oldProd.CARLSPHARMACY_TENANT_ID,
  'mountroyalpharmacy.medmeapp.ca': oldProd.MOUNTROYALPHARMACY_TENANT_ID,
  'carriagecrossingpharmacy.medmeapp.ca':
    oldProd.CARRIAGECROSSINGPHARMACY_TENANT_ID,
  'mountdalepharmacy.medmeapp.ca': oldProd.MOUNTDALEPHARMACY_TENANT_ID,
  'kellysguardiandrugstore.medmeapp.ca':
    oldProd.KELLYSGUARDIANDRUGSTORE_TENANT_ID,
  'morellis15yorkst.medmeapp.ca': oldProd.MORELLIS15YORKST_TENANT_ID,
  'supercarepharmasave.medmeapp.ca': oldProd.SUPERCAREPHARMASAVE_TENANT_ID,
  'centralpointpharmacy.medmeapp.ca': oldProd.CENTRALPOINTPHARMACY_TENANT_ID,
  'epa.medmeapp.ca': oldProd.EPA_TENANT_ID,
  'eustacepharmasave.medmeapp.ca': oldProd.EUSTACEPHARMASAVE_TENANT_ID,
  'michaelspharmacy.medmeapp.ca': oldProd.MICHAELSPHARMACY_TENANT_ID,
  'pollardida.medmeapp.ca': oldProd.POLLARDIDA_TENANT_ID,
  'oakbaypharmasave.medmeapp.ca': oldProd.OAKBAYPHARMASAVE_TENANT_ID,
  'tsa.medmeapp.ca': oldProd.TSA_TENANT_ID,
  'medhealthpharmacy.medmeapp.ca': oldProd.MEDHEALTHPHARMACY_TENANT_ID,
  'forestpharmasave.medmeapp.ca': oldProd.FORESTPHARMASAVE_TENANT_ID,
  'heritagehills.medmeapp.ca': oldProd.HERITAGEHILLS_TENANT_ID,
  'lackner.medmeapp.ca': oldProd.LACKNER_TENANT_ID,
  'charlottecarepharmacy.medmeapp.ca': oldProd.CHARLOTTECAREPHARMACY_TENANT_ID,
  'gabrielpharmacy.medmeapp.ca': oldProd.GABRIELPHARMACY_TENANT_ID,
  'inglesidepharmacy.medmeapp.ca': oldProd.INGLESIDEPHARMACY_TENANT_ID,
  'longsaultpharmacy.medmeapp.ca': oldProd.LONGSAULTPHARMACY_TENANT_ID,
  'accurxdrugstore.medmeapp.ca': oldProd.ACCURXDRUGSTORE_TENANT_ID,
  'pscaledoneast.medmeapp.ca': oldProd.PSCALEDONEAST_TENANT_ID,
  'cookstownpharmasave.medmeapp.ca': oldProd.COOKSTOWNPHARMASAVE_TENANT_ID,
  'bancroftida.medmeapp.ca': oldProd.BANCROFTIDA_TENANT_ID,
  'uptownpharmacy.medmeapp.ca': oldProd.UPTOWNPHARMACY_TENANT_ID,
  'pelhamhillspharmacy.medmeapp.ca': oldProd.PELHAMHILLSPHARMACY_TENANT_ID,
  'westlincolnpharmacy.medmeapp.ca': oldProd.WESTLINCOLNPHARMACY_TENANT_ID,
  'yorkgateida.medmeapp.ca': oldProd.YORKGATEIDA_TENANT_ID,
  'kingcitypharmacy.medmeapp.ca': oldProd.KINGCITYPHARMACY_TENANT_ID,
  'johnstonspharmacy.medmeapp.ca': oldProd.JOHNSTONSPHARMACY_TENANT_ID,
  'medicineshoppe129.medmeapp.ca': oldProd.MEDICINESHOPPE129_TENANT_ID,
  'sunderlandpharmacy.medmeapp.ca': oldProd.SUNDERLANDPHARMACY_TENANT_ID,
  'medionerx.medmeapp.ca': oldProd.MEDIONERX_TENANT_ID,
  'rowanspharmasave.medmeapp.ca': oldProd.ROWANSPHARMASAVE_TENANT_ID,
  'northgaetzpharmacy.medmeapp.ca': oldProd.NORTHGAETZPHARMACY_TENANT_ID,
  'bankstreetpharmacy.medmeapp.ca': oldProd.BANKSTREETPHARMACY_TENANT_ID,
  'portwellerpharmacy.medmeapp.ca': oldProd.PORTWELLERPHARMACY_TENANT_ID,
  'highlandremedysrx.medmeapp.ca': oldProd.HIGHLANDREMEDYSRX_TENANT_ID,
  'mmmcpharmacy.medmeapp.ca': oldProd.MMMCPHARMACY_TENANT_ID,
  'walkerroadpharmasave.medmeapp.ca': oldProd.WALKERROADPHARMASAVE_TENANT_ID,
  'universitypharmacy.medmeapp.ca': oldProd.UNIVERSITYPHARMACY_TENANT_ID,
  'hiddenvalley.medmeapp.ca': oldProd.HIDDENVALLEY_TENANT_ID,
  'neighbourhoodpharmacy.medmeapp.ca': oldProd.NEIGHBOURHOODPHARMACY_TENANT_ID,
  'staynerpharmasave.medmeapp.ca': oldProd.STAYNERPHARMASAVE_TENANT_ID,
  'rossmorepharmacy.medmeapp.ca': oldProd.ROSSMOREPHARMACY_TENANT_ID,
  'madawaskapharmacy.medmeapp.ca': oldProd.MADAWASKAPHARMACY_TENANT_ID,
  'courtlandpharmacy.medmeapp.ca': oldProd.COURTLANDPHARMACY_TENANT_ID,
  'proactivepharmacy.medmeapp.ca': oldProd.PROACTIVEPHARMACY_TENANT_ID,
  'livelypharmacy.medmeapp.ca': oldProd.LIVELYPHARMACY_TENANT_ID,
  'ganpharmasave.medmeapp.ca': oldProd.GANPHARMASAVE_TENANT_ID,
  'pwwpharmasave.medmeapp.ca': oldProd.PWWPHARMASAVE_TENANT_ID,
  'remedysrxonkent.medmeapp.ca': oldProd.REMEDYSRXONKENT_TENANT_ID,
  'adelpharmasave.medmeapp.ca': oldProd.ADELPHARMASAVE_TENANT_ID,
  'colespharmacy.medmeapp.ca': oldProd.COLESPHARMACY_TENANT_ID,
  'erinmillsida.medmeapp.ca': oldProd.ERINMILLSIDA_TENANT_ID,
  'newcarepharmacy.medmeapp.ca': oldProd.NEWCAREPHARMACY_TENANT_ID,
  'mdpharmasave.medmeapp.ca': oldProd.MDPHARMASAVE_TENANT_ID,
  'tillsonburgcarepharmacy.medmeapp.ca':
    oldProd.TILLSONBURGCAREPHARMACY_TENANT_ID,
  'havelockguardian.medmeapp.ca': oldProd.HAVELOCKGUARDIAN_TENANT_ID,
  'medicineshoppeonfort.medmeapp.ca': oldProd.MEDICINESHOPPEONFORT_TENANT_ID,
  'embrunida.medmeapp.ca': oldProd.EMBRUNIDA_TENANT_ID,
  'neilsonpharmacy.medmeapp.ca': oldProd.NEILSONPHARMACY_TENANT_ID,
  'bellpharmacy.medmeapp.ca': oldProd.BELLPHARMACY_TENANT_ID,
  'foresthillpharmacy.medmeapp.ca': oldProd.FORESTHILLPHARMACY_TENANT_ID,
  'heartpharmacy.medmeapp.ca': oldProd.HEARTPHARMACY_TENANT_ID,
  'pharmasaveosoyoos.medmeapp.ca': oldProd.PHARMASAVEOSOYOOS_TENANT_ID,
  'belmontguardian.medmeapp.ca': oldProd.BELMONTGUARDIAN_TENANT_ID,
  'marathonida.medmeapp.ca': oldProd.MARATHONIDA_TENANT_ID,
  'stewartsguardian.medmeapp.ca': oldProd.STEWARTSGUARDIAN_TENANT_ID,
  'nep.medmeapp.ca': oldProd.NEWEDINBURGHGUARDIAN_TENANT_ID,
  'northcarepharmacy.medmeapp.ca': oldProd.NORTHCAREPHARMACY_TENANT_ID,
  'chelmsfordguardian.medmeapp.ca': oldProd.CHELMSFORDGUARDIAN_TENANT_ID,
  'chelmsfordida.medmeapp.ca': oldProd.CHELMSFORDIDA_TENANT_ID,
  'limogesguardian.medmeapp.ca': oldProd.LIMOGESGUARDIAN_TENANT_ID,
  'medicineshoppe268.medmeapp.ca': oldProd.MEDICINESHOPPE268_TENANT_ID,
  'omcpharmacy.medmeapp.ca': oldProd.OMCPHARMACY_TENANT_ID,
  'robertsonsida.medmeapp.ca': oldProd.ROBERTSONSIDA_TENANT_ID,
  'wasagabeachida.medmeapp.ca': oldProd.WASAGABEACHIDA_TENANT_ID,
  'medidrugsclareview.medmeapp.ca': oldProd.MEDIDRUGSCLAREVIEW_TENANT_ID,
  'medidrugsmillcreek.medmeapp.ca': oldProd.MEDIDRUGSMILLCREEK_TENANT_ID,
  'medicinepointpch.medmeapp.ca': oldProd.MEDICINEPOINTPCH_TENANT_ID,
  'garneaupharmasave.medmeapp.ca': oldProd.GARNEAUPHARMASAVE_TENANT_ID,
  'yorkcarepharmacy.medmeapp.ca': oldProd.YORKCAREPHARMACY_TENANT_ID,
  'clareviewdrugmart.medmeapp.ca': oldProd.CLAREVIEWDRUGMART_TENANT_ID,
  'innovationida.medmeapp.ca': oldProd.INNOVATIONIDA_TENANT_ID,
  'kenronfoothills.medmeapp.ca': oldProd.KENRONFOOTHILLS_TENANT_ID,
  'ms393.medmeapp.ca': oldProd.MS393_TENANT_ID,
  'copperridgeida.medmeapp.ca': oldProd.COPPERRIDGEIDA_TENANT_ID,
  'shawnessypharmacy.medmeapp.ca': oldProd.SHAWNESSYPHARMACY_TENANT_ID,
  'dundalkpharmacy.medmeapp.ca': oldProd.DUNDALKPHARMACY_TENANT_ID,
  'northeyremedysrx.medmeapp.ca': oldProd.NORTHEYREMEDYSRX_TENANT_ID,
  'carewellpharmacy.medmeapp.ca': oldProd.CAREWELLPHARMACY_TENANT_ID,
  'bramqueenpharmacy.medmeapp.ca': oldProd.BRAMQUEENPHARMACY_TENANT_ID,
  'pineplazaida.medmeapp.ca': oldProd.PINEPLAZAIDA_TENANT_ID,
  'cridgepharmacydowntown.medmeapp.ca':
    oldProd.CRIDGEPHARMACYDOWNTOWN_TENANT_ID,
  'cridgepharmacykeating.medmeapp.ca': oldProd.CRIDGEPHARMACYKEATING_TENANT_ID,
  'kenronrockyview.medmeapp.ca': oldProd.KENRONROCKYVIEW_TENANT_ID,
  'limeridgepharmacy.medmeapp.ca': oldProd.LIMERIDGEPHARMACY_TENANT_ID,
  'careida.medmeapp.ca': oldProd.CAREIDA_TENANT_ID,
  'nkshealthlondon.medmeapp.ca': oldProd.NKSHEALTHLONDON_TENANT_ID,
  'mellpharmacy.medmeapp.ca': oldProd.MELLPHARMACY_TENANT_ID,
  'peterstreetpharmacy.medmeapp.ca': oldProd.PETERSTREETPHARMACY_TENANT_ID,
  'phcp2015.medmeapp.ca': oldProd.PHCP2015_TENANT_ID,
  'highlandgreenremedysrx.medmeapp.ca':
    oldProd.HIGHLANDGREENREMEDYSRX_TENANT_ID,
  'qualicumpharmasave.medmeapp.ca': oldProd.QUALICUMPHARMASAVE_TENANT_ID,
  'ugpharmacy.medmeapp.ca': oldProd.UGPHARMACY_TENANT_ID,
  'medicineshoppebyhospital.medmeapp.ca':
    oldProd.MEDICINESHOPPEBYHOSPITAL_TENANT_ID,
  'ghcpharmacy.medmeapp.ca': oldProd.GHCPHARMACY_TENANT_ID,
  'laclabichevaluedrugmart.medmeapp.ca':
    oldProd.LACLABICHEVALUEDRUGMART_TENANT_ID,
  'primecarepharmacy.medmeapp.ca': oldProd.PRIMECAREPHARMACY_TENANT_ID,
  'millwoodsidapharmacy.medmeapp.ca': oldProd.MILLWOODSIDAPHARMACY_TENANT_ID,
  'cordovabaypharmacy.medmeapp.ca': oldProd.CORDOVABAYPHARMACY_TENANT_ID,
  'conistonguardian.medmeapp.ca': oldProd.CONISTONGUARDIAN_TENANT_ID,
  'castledownsarp.medmeapp.ca': oldProd.CASTLEDOWNSARP_TENANT_ID,
  'ps242.medmeapp.ca': oldProd.PS242_TENANT_ID,
  'pharmasaveturnervalley.medmeapp.ca':
    oldProd.PHARMASAVETURNERVALLEY_TENANT_ID,
  'bvpharmacy.medmeapp.ca': oldProd.BVPHARMACY_TENANT_ID,
  'hcp.medmeapp.ca': oldProd.HCP_TENANT_ID,
  'parkedalepharmacy.medmeapp.ca': oldProd.PARKEDALEPHARMACY_TENANT_ID,
  'draytonpharmacy.medmeapp.ca': oldProd.DRAYTONPHARMACY_TENANT_ID,
  'westboropharmasave.medmeapp.ca': oldProd.WESTBOROPHARMASAVE_TENANT_ID,
  'perthpharmasave.medmeapp.ca': oldProd.PERTHPHARMASAVE_TENANT_ID,
  'pharmasave9662.medmeapp.ca': oldProd.PHARMASAVE9662_TENANT_ID,
  'dundasneyagawapharmacy.medmeapp.ca':
    oldProd.DUNDASNEYAGAWAPHARMACY_TENANT_ID,
  'courticepharmasave.medmeapp.ca': oldProd.COURTICEPHARMASAVE_TENANT_ID,
  'westminster.medmeapp.ca': oldProd.WESTMINSTER_TENANT_ID,
  'mainstpharmasave.medmeapp.ca': oldProd.MAINSTPHARMASAVE_TENANT_ID,
  'totalhealthpharmacymilton.medmeapp.ca':
    oldProd.TOTALHEALTHPHARMACYMILTON_TENANT_ID,
  'mooneysbaypharmasave.medmeapp.ca': oldProd.MOONEYSBAYPHARMASAVE_TENANT_ID,
  'hoganpharmacy.medmeapp.ca': oldProd.HOGANPHARMACY_TENANT_ID,
  'prohealthbarrie.medmeapp.ca': oldProd.PROHEALTHBARRIE_TENANT_ID,
  'firstplacepharmacy.medmeapp.ca': oldProd.FIRSTPLACEPHARMACY_TENANT_ID,
  'cumberlandpharmasave.medmeapp.ca': oldProd.CUMBERLANDPHARMASAVE_TENANT_ID,
  'ariapharmacy.medmeapp.ca': oldProd.ARIAPHARMACY_TENANT_ID,
  'nexuspharmacy.medmeapp.ca': oldProd.NEXUSPHARMACY_TENANT_ID,
  'mountainsidepharmacy.medmeapp.ca': oldProd.MOUNTAINSIDEPHARMACY_TENANT_ID,
  'brameastpharmacy.medmeapp.ca': oldProd.BRAMEASTPHARMACY_TENANT_ID,
  'mcintyreida.medmeapp.ca': oldProd.MCINTYREIDA_TENANT_ID,
  'swanlakepharmacy.medmeapp.ca': oldProd.SWANLAKEPHARMACY_TENANT_ID,
  'brownspharmasave.medmeapp.ca': oldProd.BROWNSPHARMASAVE_TENANT_ID,
  'idauxbridge.medmeapp.ca': oldProd.IDAUXBRIDGE_TENANT_ID,
  'stonechurchpharmacy.medmeapp.ca': oldProd.STONECHURCHPHARMACY_TENANT_ID,
  'coulterspharmacy.medmeapp.ca': oldProd.COULTERSPHARMACY_TENANT_ID,
  'yorksuperpharmacy.medmeapp.ca': oldProd.YORKSUPERPHARMACY_TENANT_ID,
  'cityviewpharmacy.medmeapp.ca': oldProd.CITYVIEWPHARMACY_TENANT_ID,
  'jacksonsguardian.medmeapp.ca': oldProd.JACKSONSGUARDIAN_TENANT_ID,
  'sgpharm.medmeapp.ca': oldProd.SGPHARM_TENANT_ID,
  'peopleschoicedrugmart.medmeapp.ca': oldProd.PEOPLESCHOICEDRUGMART_TENANT_ID,
  'wynpluspharmacy.medmeapp.ca': oldProd.WYNPLUSPHARMACY_TENANT_ID,
  'surecare.medmeapp.ca': oldProd.SURECARE_TENANT_ID,
  'erincreek.medmeapp.ca': oldProd.ERINCREEK_TENANT_ID,
  'plaza67.medmeapp.ca': oldProd.PLAZA67_TENANT_ID,
  'elginmallpharmasave.medmeapp.ca': oldProd.ELGINMALLPHARMASAVE_TENANT_ID,
  'nicklespharmacy.medmeapp.ca': oldProd.NICKLESPHARMACY_TENANT_ID,
  'portrowanpharmasave.medmeapp.ca': oldProd.PORTROWANPHARMASAVE_TENANT_ID,
  'evanspharmasave.medmeapp.ca': oldProd.EVANSPHARMASAVE_TENANT_ID,
  'rxdrugmartlindsay.medmeapp.ca': oldProd.RXDRUGMARTLINDSAY_TENANT_ID,
  'walthersida.medmeapp.ca': oldProd.WALTHERSIDA_TENANT_ID,
  'portlandpharmasave.medmeapp.ca': oldProd.PORTLANDPHARMASAVE_TENANT_ID,
  'bayviewidapharmacy.medmeapp.ca': oldProd.BAYVIEWIDAPHARMACY_TENANT_ID,
  'merrettspharmacy.medmeapp.ca': oldProd.MERRETTSPHARMACY_TENANT_ID,
  'panoramahillspharmacy.medmeapp.ca': oldProd.PANORAMAHILLSPHARMACY_TENANT_ID,
  'kwmedicineshoppe.medmeapp.ca': oldProd.KWMEDICINESHOPPE_TENANT_ID,
  'gatewaymedicalplexpharmacy.medmeapp.ca':
    oldProd.GATEWAYMEDICALPLEXPHARMACY_TENANT_ID,
  'rxdrugmartdurham.medmeapp.ca': oldProd.RXDRUGMARTDURHAM_TENANT_ID,
  'sutherlandpharmacy.medmeapp.ca': oldProd.SUTHERLANDPHARMACY_TENANT_ID,
  'pharmasavewestedmontonmall.medmeapp.ca':
    oldProd.PHARMASAVEWESTEDMONTONMALL_TENANT_ID,
  'guardianmountainview.medmeapp.ca': oldProd.GUARDIANMOUNTAINVIEW_TENANT_ID,
  'ps931.medmeapp.ca': oldProd.PS931_TENANT_ID,
  'cooksqueensbushpharmacy.medmeapp.ca':
    oldProd.COOKSQUEENSBUSHPHARMACY_TENANT_ID,
  'lacostepharmacy.medmeapp.ca': oldProd.LACOSTEPHARMACY_TENANT_ID,
  'fairviewremedys.medmeapp.ca': oldProd.FAIRVIEWREMEDYS_TENANT_ID,
  'tottenhampharmasave.medmeapp.ca': oldProd.TOTTENHAMPHARMASAVE_TENANT_ID,
  'oshawadiscountpharmacy.medmeapp.ca':
    oldProd.OSHAWADISCOUNTPHARMACY_TENANT_ID,
  'pharmasaveaurora.medmeapp.ca': oldProd.PHARMASAVEAURORA_TENANT_ID,
  'jamespotterroadpharmacy.medmeapp.ca':
    oldProd.JAMESPOTTERROADPHARMACY_TENANT_ID,
  'harrisvillepharmacy.medmeapp.ca': oldProd.HARRISVILLEPHARMACY_TENANT_ID,
  'superrxpharmacy.medmeapp.ca': oldProd.SUPERRXPHARMACY_TENANT_ID,
  'msp368.medmeapp.ca': oldProd.MSP368_TENANT_ID,
  'bishoppharmacy.medmeapp.ca': oldProd.BISHOPPHARMACY_TENANT_ID,
  'bramptoncommunitypharmacy.medmeapp.ca':
    oldProd.BRAMPTONCOMMUNITYPHARMACY_TENANT_ID,
  'dukepharmacy.medmeapp.ca': oldProd.DUKEPHARMACY_TENANT_ID,
  'creditviewida.medmeapp.ca': oldProd.CREDITVIEWIDA_TENANT_ID,
  'whitneypharmacy.medmeapp.ca': oldProd.WHITNEYPHARMACY_TENANT_ID,
  'markhamackpharmacy.medmeapp.ca': oldProd.MARKHAMACKPHARMACY_TENANT_ID,
  'sundrefamilypharmacy.medmeapp.ca': oldProd.SUNDREFAMILYPHARMACY_TENANT_ID,
  'cobypharmacy.medmeapp.ca': oldProd.COBYPHARMACY_TENANT_ID,
  'prod-mb.medmeapp.ca': env.PRODMB_TENANT_ID,
  'prod-nb.medmeapp.ca': env.PRODNB_TENANT_ID,
  'prod-on.medmeapp.ca': env.PRODON_TENANT_ID,
  'prod-sk.medmeapp.ca': env.PRODSK_TENANT_ID,
  'prod-nl.medmeapp.ca': env.PRODNL_TENANT_ID,
  'prod-pe.medmeapp.ca': env.PRODPE_TENANT_ID,
  'prod-ns.medmeapp.ca': env.PRODNS_TENANT_ID,
  'prod-ab.medmeapp.ca': env.PRODAB_TENANT_ID,
  'yorktonpharmasave.medmeapp.ca': env.YORKTONPHARMASAVE_TENANT_ID,
  'potterswheel.medmeapp.ca': env.POTTERSWHEEL_TENANT_ID,
  'heritageidarx.medmeapp.ca': env.HERITAGEIDARX_TENANT_ID,
  'michaelspharmasave.medmeapp.ca': env.MICHAELSPHARMASAVE_TENANT_ID,
  'primecare.medmeapp.ca': env.PRIMECARE_TENANT_ID,
  'pharmasavegordonpharmacy.medmeapp.ca':
    env.PHARMASAVEGORDONPHARMACY_TENANT_ID,
  'auroraidapharmacy.medmeapp.ca': env.AURORAIDAPHARMACY_TENANT_ID,
  'thorncliffemedicineshoppe.medmeapp.ca':
    env.THORNCLIFFEMEDICINESHOPPE_TENANT_ID,
  'southlandcoop.medmeapp.ca': env.SOUTHLANDCOOP_TENANT_ID,
  'medicalbuildingpharmacy.medmeapp.ca': env.MEDICALBUILDINGPHARMACY_TENANT_ID,
  'transconaidapharmacy.medmeapp.ca': env.TRANSCONAIDAPHARMACY_TENANT_ID,
  'coleman.medmeapp.ca': env.COLEMAN_TENANT_ID,
  'cookswaterloo.medmeapp.ca': env.COOKSWATERLOO_TENANT_ID,
  'sloaneguardian.medmeapp.ca': env.SLOANEGUARDIAN_TENANT_ID,
  'pharmasave467.medmeapp.ca': env.PHARMASAVE467_TENANT_ID,
  'macarthurdrugs.medmeapp.ca': env.MACARTHURDRUGS_TENANT_ID,
  'wincarepharmacy.medmeapp.ca': env.WINCAREPHARMACY_TENANT_ID,
  'sherbrooke.medmeapp.ca': env.SHERBROOKE_TENANT_ID,
  'gibbonsremedysrx.medmeapp.ca': env.GIBBONSREMEDYSRX_TENANT_ID,
  'southottawapharmacy.medmeapp.ca': env.SOUTHOTTAWAPHARMACY_TENANT_ID,
  'deerparkida.medmeapp.ca': env.DEERPARKIDA_TENANT_ID,
  'maidstoneida.medmeapp.ca': env.MAIDSTONEIDA_TENANT_ID,
  'radiancepharmacy.medmeapp.ca': env.RADIANCEPHARMACY_TENANT_ID,
  'riverbendcooppharmacy.medmeapp.ca': env.RIVERBENDCOOPPHARMACY_TENANT_ID,
  'madillsidapharmacy.medmeapp.ca': env.MADILLSIDAPHARMACY_TENANT_ID,
  'advancedpharmacy.medmeapp.ca': env.ADVANCEDPHARMACY_TENANT_ID,
  'roulstonsportdover.medmeapp.ca': env.ROULSTONSPORTDOVER_TENANT_ID,
  'cmp.medmeapp.ca': env.CMP_TENANT_ID,
  'littlebritainpharmacy.medmeapp.ca': env.LITTLEBRITAINPHARMACY_TENANT_ID,
  'sallyspharmasave.medmeapp.ca': env.SALLYSPHARMASAVE_TENANT_ID,
  'riverterracepharmacy.medmeapp.ca': env.RIVERTERRACEPHARMACY_TENANT_ID,
  'academypharmacy.medmeapp.ca': env.ACADEMYPHARMACY_TENANT_ID,
  'bridlepath.medmeapp.ca': env.BRIDLEPATH_TENANT_ID,
  'idarichmondmedical.medmeapp.ca': env.IDARICHMONDMEDICAL_TENANT_ID,
  'mckenziesida.medmeapp.ca': env.MCKENZIESIDA_TENANT_ID,
  'synergyremedysrx.medmeapp.ca': env.SYNERGYREMEDYSRX_TENANT_ID,
  'healthhubpharmacy.medmeapp.ca': env.HEALTHHUBPHARMACY_TENANT_ID,
  'caledoniapharmachoice.medmeapp.ca': env.CALEDONIA_TENANT_ID,
  'homesteadcooppharmacy.medmeapp.ca': env.HOMESTEADCOOPPHARMACY_TENANT_ID,
  'warkworthpharmacy.medmeapp.ca': env.WARKWORTHPHARMACY_TENANT_ID,
  'medprohealth.medmeapp.ca': env.MEDPROHEALTH_TENANT_ID,
  'dalecliffpharmacy.medmeapp.ca': env.DALECLIFFPHARMACY_TENANT_ID,
  'michaelspharmasave9671.medmeapp.ca': env.MICHAELSPHARMASAVE9671_TENANT_ID,
  'papharmacy.medmeapp.ca': env.PAPHARMACY_TENANT_ID,
  'carpenterguardian.medmeapp.ca': env.CARPENTERGUARDIAN_TENANT_ID,
  'marshallpark.medmeapp.ca': env.MARSHALLPARK_TENANT_ID,
  'owensoundida.medmeapp.ca': env.OWENSOUNDIDA_TENANT_ID,
  'pharmasavenorthbattleford.medmeapp.ca':
    env.PHARMASAVENORTHBATTLEFORD_TENANT_ID,
  'pharmasavebarriedowntown.medmeapp.ca':
    env.PHARMASAVEBARRIEDOWNTOWN_TENANT_ID,
  'pharmasavebayview.medmeapp.ca': env.PHARMASAVEBAYVIEW_TENANT_ID,
  'pharmasavetrenton.medmeapp.ca': env.PHARMASAVETRENTON_TENANT_ID,
  'bellevillepharmacy.medmeapp.ca': env.BELLEVILLEPHARMACY_TENANT_ID,
  'stcharlespharmacy.medmeapp.ca': env.STCHARLESPHARMACY_TENANT_ID,
  'memorialpharmasave.medmeapp.ca': env.MEMORIALPHARMASAVE_TENANT_ID,
  'johnstonspharmacyhastings.medmeapp.ca':
    env.JOHNSTONSPHARMACYHASTINGS_TENANT_ID,
  'fittonspharmacy.medmeapp.ca': env.FITTONSPHARMACY_TENANT_ID,
  'reliancedrugmart.medmeapp.ca': env.RELIANCEDRUGMART_TENANT_ID,
  'riversdalepharmacy.medmeapp.ca': env.RIVERSDALEPHARMACY_TENANT_ID,
  'pinegrovepharmacy.medmeapp.ca': env.PINEGROVEPHARMACY_TENANT_ID,
  'londoncarepharmacy.medmeapp.ca': env.LONDONCAREPHARMACY_TENANT_ID,
  'pharmasavesimcoe.medmeapp.ca': env.PHARMASAVESIMCOE_TENANT_ID,
  'granthampharmacy.medmeapp.ca': env.GRANTHAMPHARMACY_TENANT_ID,
  'huronstreetpharmasave.medmeapp.ca': env.HURONSTREETPHARMASAVE_TENANT_ID,
  'hillsidepharmasave.medmeapp.ca': env.HILLSIDEPHARMASAVE_TENANT_ID,
  'niagararemedy.medmeapp.ca': env.NIAGARAREMEDY_TENANT_ID,
  'pharmasaveallandale.medmeapp.ca': env.PHARMASAVEALLANDALE_TENANT_ID,
  'grandpharmacy.medmeapp.ca': env.GRANDPHARMACY_TENANT_ID,
  'ucs.medmeapp.ca': env.UCS_TENANT_ID,
  'riverview.medmeapp.ca': env.RIVERVIEW_TENANT_ID,
  'frontlinepharmacy.medmeapp.ca': env.FRONTLINEPHARMACY_TENANT_ID,
  'lc.medmeapp.ca': env.LC_TENANT_ID,
  'victoriasquare.medmeapp.ca': env.VICTORIASQUARE_TENANT_ID,
  'behealthypharmacy.medmeapp.ca': env.BEHEALTHYPHARMACY_TENANT_ID,
  'firstavepharmacy.medmeapp.ca': env.FIRSTAVEPHARMACY_TENANT_ID,
  'maindrugmart.medmeapp.ca': env.MAINDRUGMART_TENANT_ID,
  'pharmasavebathurst.medmeapp.ca': env.PHARMASAVEBATHURST_TENANT_ID,
  'theapothecaryshop.medmeapp.ca': env.THEAPOTHECARYSHOP_TENANT_ID,
  'medtrustpharmasave.medmeapp.ca': env.MEDTRUSTPHARMASAVE_TENANT_ID,
  'pharmasaveadelaidenorth.medmeapp.ca': env.PHARMASAVEADELAIDENORTH_TENANT_ID,
  'markhamhealthplex.medmeapp.ca': env.MARKHAMHEALTHPLEX_TENANT_ID,
  'cochranepharmacy.medmeapp.ca': env.COCHRANEPHARMACY_TENANT_ID,
  'huronpharmasave.medmeapp.ca': env.HURONPHARMASAVE_TENANT_ID,
  '1355pharmasave.medmeapp.ca': env['1355PHARMASAVE_TENANT_ID'],
  'queenpharmacy.medmeapp.ca': env.QUEENPHARMACY_TENANT_ID,
  'rxdrugmartdeepriver.medmeapp.ca': env.RXDRUGMARTDEEPRIVER_TENANT_ID,
  'rxdrugmartwingham.medmeapp.ca': env.RXDRUGMARTWINGHAM_TENANT_ID,
  'rxdrugmartiroquoisfalls.medmeapp.ca': env.RXDRUGMARTIROQUOISFALLS_TENANT_ID,
  'rxdrugmartsturgeonfalls.medmeapp.ca': env.RXDRUGMARTSTURGEONFALLS_TENANT_ID,
  'rxdrugmartstayner.medmeapp.ca': env.RXDRUGMARTSTAYNER_TENANT_ID,
  'westhillpharmacy.medmeapp.ca': env.WESTHILLPHARMACY_TENANT_ID,
  'medisensepharmacy.medmeapp.ca': env.MEDISENSEPHARMACY_TENANT_ID,
  'pioneercooppharmacy.medmeapp.ca': env.PIONEERCOOPPHARMACY_TENANT_ID,
  'interpharmacy.medmeapp.ca': env.INTERPHARMACY_TENANT_ID,
  'thoroldmedicalpharmacy.medmeapp.ca': env.THOROLDMEDICALPHARMACY_TENANT_ID,
  'firstmedicalpharmacy.medmeapp.ca': env.FIRSTMEDICALPHARMACY_TENANT_ID,
  'devonpharmasave.medmeapp.ca': env.DEVONPHARMASAVE_TENANT_ID,
  'medicalpharmacylondon.medmeapp.ca': env.MEDICALPHARMACYLONDON_TENANT_ID,
  'letitiapharmacy.medmeapp.ca': env.LETITIAPHARMACY_TENANT_ID,
  'healthcareidapharmacy.medmeapp.ca': env.HEALTHCAREIDAPHARMACY_TENANT_ID,
  'medicineshoppe274.medmeapp.ca': env.MEDICINESHOPPE274_TENANT_ID,
  'grandebankspharmacy.medmeapp.ca': env.GRANDEBANKSPHARMACY_TENANT_ID,
  'valest.medmeapp.ca': env.VALEST_TENANT_ID,
  'alconapharmacy2.medmeapp.ca': env.ALCONAPHARMACY2_TENANT_ID,
  'martingrovepharmacy.medmeapp.ca': env.MARTINGROVEPHARMACY_TENANT_ID,
  'theclinicpharmacy.medmeapp.ca': env.THECLINICPHARMACY_TENANT_ID,
  'healthcaremart.medmeapp.ca': env.HEALTHCAREMART_TENANT_ID,
  'wellandpharmsave.medmeapp.ca': env.WELLANDPHARMSAVE_TENANT_ID,
  'singarspharmacy.medmeapp.ca': env.SINGARSPHARMACY_TENANT_ID,
  'healthcheckpharmacy.medmeapp.ca': env.HEALTHCHECKPHARMACY_TENANT_ID,
  'mcintyrepharmacy.medmeapp.ca': env.MCINTYREPHARMACY_TENANT_ID,
  'strasburgcrossingpharmasave.medmeapp.ca':
    env.STRASBURGCROSSINGPHARMASAVE_TENANT_ID,
  'bloorwestpharmacy.medmeapp.ca': env.BLOORWESTPHARMACY_TENANT_ID,
  'pharmaciealfred.medmeapp.ca': env.PHARMACIEALFRED_TENANT_ID,
  'pharmacybygrange.medmeapp.ca': env.PHARMACYBYGRANGE_TENANT_ID,
  'kenmountpharmasave.medmeapp.ca': env.KENMOUNTPHARMASAVE_TENANT_ID,
  'professionalmedicalpharmacy.medmeapp.ca':
    env.PROFESSIONALMEDICALPHARMACY_TENANT_ID,
  'cooksnewhamburg.medmeapp.ca': env.COOKSNEWHAMBURG_TENANT_ID,
  'westoncarepharmacy.medmeapp.ca': env.WESTONCAREPHARMACY_TENANT_ID,
  'dannyswellnesspharmacy.medmeapp.ca': env.DANNYSWELLNESSPHARMACY_TENANT_ID,
  'ortonparkpharmacy.medmeapp.ca': env.ORTONPARKPHARMACY_TENANT_ID,
  'queeneastmedicalpharmacy.medmeapp.ca':
    env.QUEENEASTMEDICALPHARMACY_TENANT_ID,
  'quinteidapharmacy.medmeapp.ca': env.QUINTEIDAPHARMACY_TENANT_ID,
  'littlecurrentguardian.medmeapp.ca': env.LITTLECURRENTGUARDIAN_TENANT_ID,
  'cookskingsbury.medmeapp.ca': env.COOKSKINGSBURY_TENANT_ID,
  'avapharmacy.medmeapp.ca': env.AVAPHARMACY_TENANT_ID,
  '1909ida.medmeapp.ca': env['1909IDA_TENANT_ID'],
  'healthhubyongepharmacy.medmeapp.ca': env.HEALTHHUBYONGEPHARMACY_TENANT_ID,
  'lakewoodpeoplespharmacy.medmeapp.ca': env.LAKEWOODPEOPLESPHARMACY_TENANT_ID,
  'medicinecabinetpharmacy.medmeapp.ca': env.MEDICINECABINETPHARMACY_TENANT_ID,
  'chathampharmasave.medmeapp.ca': env.CHATHAMPHARMASAVE_TENANT_ID,
  'sheppardcommunitypharmacy.medmeapp.ca':
    env.SHEPPARDCOMMUNITYPHARMACY_TENANT_ID,
  'emeraldpharmacy.medmeapp.ca': env.EMERALDPHARMACY_TENANT_ID,
  'rocklandpharmacy.medmeapp.ca': env.ROCKLANDPHARMACY_TENANT_ID,
  'graftonpharmacy.medmeapp.ca': env.GRAFTONPHARMACY_TENANT_ID,
  'newcaledoniapharmacy.medmeapp.ca': env.NEWCALEDONIAPHARMACY_TENANT_ID,
  'healthsense.medmeapp.ca': env.HEALTHSENSE_TENANT_ID,
  'northendfarmacia.medmeapp.ca': env.NORTHENDFARMACIA_TENANT_ID,
  'riverroadpharmasave.medmeapp.ca': env.RIVERROADPHARMASAVE_TENANT_ID,
  '9elevendrugmart.medmeapp.ca': env['9ELEVENDRUGMART_TENANT_ID'],
  'bentonmedicalpharmacy.medmeapp.ca': env.BENTONMEDICALPHARMACY_TENANT_ID,
  'stroudguardian.medmeapp.ca': env.STROUDGUARDIAN_TENANT_ID,
  'somersetdrugs.medmeapp.ca': env.SOMERSETDRUGS_TENANT_ID,
  'robinsonspharmasave.medmeapp.ca': env.ROBINSONSPHARMASAVE_TENANT_ID,
  'harmonyvalleypharmacy.medmeapp.ca': env.HARMONYVALLEYPHARMACY_TENANT_ID,
  'rosedaleidapharmacy.medmeapp.ca': env.ROSEDALEIDAPHARMACY_TENANT_ID,
  'medicineshoppe246.medmeapp.ca': env.MEDICINESHOPPE246_TENANT_ID,
  'lighthousepharmacy.medmeapp.ca': env.LIGHTHOUSEPHARMACY_TENANT_ID,
  'ottawasouthpharmasave.medmeapp.ca': env.OTTAWASOUTHPHARMASAVE_TENANT_ID,
  'durhamapothecary.medmeapp.ca': env.DURHAMAPOTHECARY_TENANT_ID,
  'campuspharmasave.medmeapp.ca': env.CAMPUSPHARMASAVE_TENANT_ID,
  'medicineshoppe215.medmeapp.ca': env.MEDICINESHOPPE215_TENANT_ID,
  'barrhavenmedicalpharmacy.medmeapp.ca':
    env.BARRHAVENMEDICALPHARMACY_TENANT_ID,
  'highlandplazapharmacy.medmeapp.ca': env.HIGHLANDPLAZAPHARMACY_TENANT_ID,
  'islingtonidapharmacy.medmeapp.ca': env.ISLINGTONIDAPHARMACY_TENANT_ID,
  'forddrivepharmacy.medmeapp.ca': env.FORDDRIVEPHARMACY_TENANT_ID,
  'keenedrugmart.medmeapp.ca': env.KEENEDRUGMART_TENANT_ID,
  'carnduffdrugmart.medmeapp.ca': env.CARNDUFFDRUGMART_TENANT_ID,
  'vinapharmacy.medmeapp.ca': env.VINAPHARMACY_TENANT_ID,
  'mallorytownpharmacy.medmeapp.ca': env.MALLORYTOWNPHARMACY_TENANT_ID,
  'medicineshoppeoakville.medmeapp.ca': env.MEDICINESHOPPEOAKVILLE_TENANT_ID,
  'palmyraguardian.medmeapp.ca': env.PALMYRAGUARDIAN_TENANT_ID,
  'divisionpharmacy.medmeapp.ca': env.DIVISIONPHARMACY_TENANT_ID,
  'townlinepharmacy.medmeapp.ca': env.TOWNLINEPHARMACY_TENANT_ID,
  'parkdalepharmasave.medmeapp.ca': env.PARKDALEPHARMASAVE_TENANT_ID,
  'riverdalepharmacy.medmeapp.ca': env.RIVERDALEPHARMACY_TENANT_ID,
  'allcarepharmacy.medmeapp.ca': env.ALLCAREPHARMACY_TENANT_ID,
  'cayugapharmacy.medmeapp.ca': env.CAYUGAPHARMACY_TENANT_ID,
  'lincolncentrepharmacy.medmeapp.ca': env.LINCOLNCENTREPHARMACY_TENANT_ID,
  'longfieldspharmacy.medmeapp.ca': env.LONGFIELDSPHARMACY_TENANT_ID,
  'royalcitypharmacy.medmeapp.ca': env.ROYALCITYPHARMACY_TENANT_ID,
  'stevensvillepharmacy.medmeapp.ca': env.STEVENSVILLEPHARMACY_TENANT_ID,
  'agapepharmacy.medmeapp.ca': env.AGAPEPHARMACY_TENANT_ID,
  'bcp.medmeapp.ca': env.BCP_TENANT_ID,
  'lapsleyidapharmacy.medmeapp.ca': env.LAPSLEYIDAPHARMACY_TENANT_ID,
  'medicalcarepharmacy.medmeapp.ca': env.MEDICALCAREPHARMACY_TENANT_ID,
  'wellingtonpharmacy.medmeapp.ca': env.WELLINGTONPHARMACY_TENANT_ID,
  'pharmasavespeedvale.medmeapp.ca': env.PHARMASAVESPEEDVALE_TENANT_ID,
  'royaloakspharmacy.medmeapp.ca': env.ROYALOAKSPHARMACY_TENANT_ID,
  'wellandmedicalpharmacyplymouth.medmeapp.ca':
    env.WELLANDMEDICALPHARMACYPLYMOUTH_TENANT_ID,
  'iriscompoundingpharmacy.medmeapp.ca': env.IRISCOMPOUNDINGPHARMACY_TENANT_ID,
  'chapmanspharmacy.medmeapp.ca': env.CHAPMANSPHARMACY_TENANT_ID,
  'bhpharmacy.medmeapp.ca': env.BHPHARMACY_TENANT_ID,
  'windsorcrossingpharmasave.medmeapp.ca':
    env.WINDSORCROSSINGPHARMASAVE_TENANT_ID,
  'glenroypharmacy.medmeapp.ca': env.GLENROYPHARMACY_TENANT_ID,
  'rideaupharmacy.medmeapp.ca': env.RIDEAUPHARMACY_TENANT_ID,
  'bigbayguardian.medmeapp.ca': env.BIGBAYGUARDIAN_TENANT_ID,
  'mostarpharmacy.medmeapp.ca': env.MOSTARPHARMACY_TENANT_ID,
  'wellandmedicalpharmacyontario.medmeapp.ca':
    env.WELLANDMEDICALPHARMACYONTARIO_TENANT_ID,
  'caredrugsaurora.medmeapp.ca': env.CAREDRUGSAURORA_TENANT_ID,
  'oakstreetpharmacy.medmeapp.ca': env.OAKSTREETPHARMACY_TENANT_ID,
  'voakhealth.medmeapp.ca': env.VOAKHEALTH_TENANT_ID,
  '2carepharmacy.medmeapp.ca': env['2CAREPHARMACY_TENANT_ID'],
  'eastendpharmasave.medmeapp.ca': env.EASTENDPHARMASAVE_TENANT_ID,
  'medsavepharmacy.medmeapp.ca': env.MEDSAVEPHARMACY_TENANT_ID,
  'blueskypharmacy.medmeapp.ca': env.BLUESKYPHARMACY_TENANT_ID,
  'laurelwoodpharmasave.medmeapp.ca': env.LAURELWOODPHARMASAVE_TENANT_ID,
  'norwichpharmasave.medmeapp.ca': env.NORWICHPHARMASAVE_TENANT_ID,
  'pharmacy101.medmeapp.ca': env.PHARMACY101_TENANT_ID,
  'lefroypharmacy.medmeapp.ca': env.LEFROYPHARMACY_TENANT_ID,
  'harvesterpharmacy.medmeapp.ca': env.HARVESTERPHARMACY_TENANT_ID,
  'huntclubpharmacy.medmeapp.ca': env.HUNTCLUBPHARMACY_TENANT_ID,
  'nickelcentre.medmeapp.ca': env.NICKELCENTRE_TENANT_ID,
  'baylypharmacy.medmeapp.ca': env.BAYLYPHARMACY_TENANT_ID,
  'trustcarepharmasave.medmeapp.ca': env.TRUSTCAREPHARMASAVE_TENANT_ID,
  'clearviewpharmacy.medmeapp.ca': env.CLEARVIEWPHARMACY_TENANT_ID,
  'roulstonsdelhi.medmeapp.ca': env.ROULSTONSDELHI_TENANT_ID,
  'pharmasave3021.medmeapp.ca': env.PHARMASAVE3021_TENANT_ID,
  'sandycove.medmeapp.ca': env.SANDYCOVE_TENANT_ID,
  'pharmasavebradford.medmeapp.ca': env.PHARMASAVEBRADFORD_TENANT_ID,
  'lifewatchpharmacy.medmeapp.ca': env.LIFEWATCHPHARMACY_TENANT_ID,
  'dundasclinicalpharmacy.medmeapp.ca': env.DUNDASCLINICALPHARMACY_TENANT_ID,
  'sandwichida.medmeapp.ca': env.SANDWICHIDA_TENANT_ID,
  'medicaltreepharmacy.medmeapp.ca': env.MEDICALTREEPHARMACY_TENANT_ID,
  'iroquoisfallsguardian.medmeapp.ca': env.IROQUOISFALLSGUARDIAN_TENANT_ID,
  'saubleguardian.medmeapp.ca': env.SAUBLEGUARDIAN_TENANT_ID,
  'peninsulaida.medmeapp.ca': env.PENINSULAIDA_TENANT_ID,
  'tlcida.medmeapp.ca': env.TLCIDA_TENANT_ID,
  'scottsdrugstore.medmeapp.ca': env.SCOTTSDRUGSTORE_TENANT_ID,
  'macneildodd.medmeapp.ca': env.MACNEILDODD_TENANT_ID,
  'medicineshoppedartmouth.medmeapp.ca': env.MEDICINESHOPPEDARTMOUTH_TENANT_ID,
  'medicineshoppe408.medmeapp.ca': env.MEDICINESHOPPE408_TENANT_ID,
  'baysideida.medmeapp.ca': env.BAYSIDEIDA_TENANT_ID,
  'fontainebleuida.medmeapp.ca': env.FONTAINEBLEUIDA_TENANT_ID,
  'forestgladeida.medmeapp.ca': env.FORESTGLADEIDA_TENANT_ID,
  'dorchesterida.medmeapp.ca': env.DORCHESTERIDA_TENANT_ID,
  'cridgepharmacy.medmeapp.ca': env.CRIDGEPHARMACY_TENANT_ID,
  'cookspinebush.medmeapp.ca': env.COOKSPINEBUSH_TENANT_ID,
  'medicineshoppe288.medmeapp.ca': env.MEDICINESHOPPE288_TENANT_ID,
  'reynoldspharmasave.medmeapp.ca': env.REYNOLDSPHARMASAVE_TENANT_ID,
  'rossandersonpharmacy.medmeapp.ca': env.ROSSANDERSONPHARMACY_TENANT_ID,
  'oxfordpharmacy.medmeapp.ca': env.OXFORDPHARMACY_TENANT_ID,
  'msfairviewclaytonpark.medmeapp.ca': env.MSFAIRVIEWCLAYTONPARK_TENANT_ID,
  'macdonnellpharmacy.medmeapp.ca': env.MACDONNELLPHARMACY_TENANT_ID,
  'finchmidlandpharmacy.medmeapp.ca': env.FINCHMIDLANDPHARMACY_TENANT_ID,
  'elmdalemedical.medmeapp.ca': env.ELMDALEMEDICAL_TENANT_ID,
  'ontariostreetpharmacy.medmeapp.ca': env.ONTARIOSTREETPHARMACY_TENANT_ID,
  'newsudburymedicalclinic.medmeapp.ca': env.NEWSUDBURYMEDICALCLINIC_TENANT_ID,
  'blueskiespharmacyida.medmeapp.ca': env.BLUESKIESPHARMACYIDA_TENANT_ID,
  'carepluspharmacy.medmeapp.ca': env.CAREPLUSPHARMACY_TENANT_ID,
  'clickpharmacy.medmeapp.ca': env.CLICKPHARMACY_TENANT_ID,
  'stonespharmasave.medmeapp.ca': env.STONESPHARMASAVE_TENANT_ID,
  'ridgesquarepharmacy.medmeapp.ca': env.RIDGESQUAREPHARMACY_TENANT_ID,
  'mapleviewhealthcarepharmacy.medmeapp.ca':
    env.MAPLEVIEWHEALTHCAREPHARMACY_TENANT_ID,
  'medcurepharmacy.medmeapp.ca': env.MEDCUREPHARMACY_TENANT_ID,
  'windfieldspharmacy.medmeapp.ca': env.WINDFIELDSPHARMACY_TENANT_ID,
  'chisholmspharmachoice.medmeapp.ca': env.CHISHOLMSPHARMACHOICE_TENANT_ID,
  'blueskiespharmacyguardian.medmeapp.ca':
    env.BLUESKIESPHARMACYGUARDIAN_TENANT_ID,
  'sunnyvalemed.medmeapp.ca': env.SUNNYVALEMED_TENANT_ID,
  'memorialcompounding.medmeapp.ca': env.MEMORIALCOMPOUNDING_TENANT_ID,
  'oxfordguardianpharmacy.medmeapp.ca': env.OXFORDGUARDIANPHARMACY_TENANT_ID,
  'medshoppe418.medmeapp.ca': env.MEDSHOPPE418_TENANT_ID,
  'caledonpharmacy.medmeapp.ca': env.CALEDONPHARMACY_TENANT_ID,
  'kingraypharmacy.medmeapp.ca': env.KINGRAYPHARMACY_TENANT_ID,
  'lifesmartpharmacy.medmeapp.ca': env.LIFESMART_TENANT_ID,
  'portcreditvillagepharmacy.medmeapp.ca':
    env.PORTCREDITVILLAGEPHARMACY_TENANT_ID,
  'yc.medmeapp.ca': env.YC_TENANT_ID,
  'clinicplusida.medmeapp.ca': env.CLINICPLUSIDA_TENANT_ID,
  'stjosephpharmacy.medmeapp.ca': env.STJOSEPHPHARMACY_TENANT_ID,
  'voakpharmacy.medmeapp.ca': env.VOAKPHARMACY_TENANT_ID,
  'huntsvillepharmasave.medmeapp.ca': env.HUNTSVILLEPHARMASAVE_TENANT_ID,
  'westonmedicalpharmacy.medmeapp.ca': env.WESTONMEDICALPHARMACY_TENANT_ID,
  'davisvillepharmacy.medmeapp.ca': env.DAVISVILLEPHARMACY_TENANT_ID,
  'frederickpharmasave.medmeapp.ca': env.FREDERICKPHARMASAVE_TENANT_ID,
  'hcreekph.medmeapp.ca': env.HCREEKPH_TENANT_ID,
  'adelaidepharmacy.medmeapp.ca': env.ADELAIDEPHARMACY_TENANT_ID,
  'purehealthrh.medmeapp.ca': env.PUREHEALTHRH_TENANT_ID,
  'trinitydrugstore.medmeapp.ca': env.TRINITYDRUGSTORE_TENANT_ID,
  'bayridgepharmacy.medmeapp.ca': env.BAYRIDGEPHARMACY_TENANT_ID,
  'purehealthv.medmeapp.ca': env.PUREHEALTHV_TENANT_ID,
  'richviewpharmacy.medmeapp.ca': env.RICHVIEWPHARMACY_TENANT_ID,
  'hamptonexternal.medmeapp.ca': env.HAMPTONEXTERNAL_TENANT_ID,
  'uniquecarepharmacy.medmeapp.ca': env.UNIQUECAREPHARMACY_TENANT_ID,
  'tomkenmedicalpharmacy.medmeapp.ca': env.TOMKENMEDICALPHARMACY_TENANT_ID,
  'arianapharmacy.medmeapp.ca': env.ARIANAPHARMACY_TENANT_ID,
  'lcp.medmeapp.ca': env.LCP_TENANT_ID,
  'clarkspharmasave.medmeapp.ca': env.CLARKSPHARMASAVE_TENANT_ID,
  'bramnorthpharmacy.medmeapp.ca': env.BRAMNORTHPHARMACY_TENANT_ID,
  'inspiredpharmacy.medmeapp.ca': env.INSPIREDPHARMACY_TENANT_ID,
  'henderson.medmeapp.ca': env.HENDERSON_TENANT_ID,
  'huroniamedicalpharmacy.medmeapp.ca': env.HURONIAMEDICALPHARMACY_TENANT_ID,
  'standrewspharmasave.medmeapp.ca': env.STANDREWSPHARMASAVE_TENANT_ID,
  'riverdaleeastida.medmeapp.ca': env.RIVERDALEEASTIDA_TENANT_ID,
  'romanapharmacy.medmeapp.ca': env.ROMANAPHARMACY_TENANT_ID,
  'goldencarepharmacy.medmeapp.ca': env.GOLDENCAREPHARMACY_TENANT_ID,
  'peopleschoiceremedymarkham.medmeapp.ca':
    env.PEOPLESCHOICEREMEDYMARKHAM_TENANT_ID,
  'pharmacyonmetcalfe.medmeapp.ca': env.PHARMACYONMETCALFE_TENANT_ID,
  'edmontonpharmacy.medmeapp.ca': env.EDMONTONPHARMACY_TENANT_ID,
  'purehealtho.medmeapp.ca': env.PUREHEALTHO_TENANT_ID,
  'shopritepharmacy.medmeapp.ca': env.SHOPRITEPHARMACY_TENANT_ID,
  'tuxedodrugs.medmeapp.ca': env.TUXEDODRUGS_TENANT_ID,
  'kingstownpharmasave.medmeapp.ca': env.KINGSTOWNPHARMASAVE_TENANT_ID,
  'alnhealth.medmeapp.ca': env.ALNHEALTH_TENANT_ID,
  'hcpharmacy.medmeapp.ca': env.HCPHARMACY_TENANT_ID,
  'zaraspharmacy.medmeapp.ca': env.ZARASPHARMACY_TENANT_ID,
  'memorialmedicalpharmasave.medmeapp.ca':
    env.MEMORIALMEDICALPHARMASAVE_TENANT_ID,
  'ohswekenpharmasave.medmeapp.ca': env.OHSWEKENPHARMASAVE_TENANT_ID,
  'pathwaypharmacy.medmeapp.ca': env.PATHWAYPHARMACY_TENANT_ID,
  'mortarpestle.medmeapp.ca': env.MORTARPESTLE_TENANT_ID,
  'adelaideida.medmeapp.ca': env.ADELAIDEIDA_TENANT_ID,
  'shannonidapharmacy.medmeapp.ca': env.SHANNONIDAPHARMACY_TENANT_ID,
  'boylevaluedrugmart.medmeapp.ca': env.BOYLEVALUEDRUGMART_TENANT_ID,
  'sexsmithpharmacy.medmeapp.ca': env.SEXSMITHPHARMACY_TENANT_ID,
  'familypharmacy46.medmeapp.ca': env.FAMILYPHARMACY46_TENANT_ID,
  'sundrepharmasave.medmeapp.ca': env.SUNDREPHARMASAVE_TENANT_ID,
  'northeastpharmacy.medmeapp.ca': env.NORTHEASTPHARMACY_TENANT_ID,
  'sundrecommunitydrugmart.medmeapp.ca': env.SUNDRECOMMUNITYDRUGMART_TENANT_ID,
  'elkpointpharmacy.medmeapp.ca': env.ELKPOINTPHARMACY_TENANT_ID,
  'consortpharmacy.medmeapp.ca': env.CONSORTPHARMACY_TENANT_ID,
  'hardistycommunitydrugmart.medmeapp.ca':
    env.HARDISTYCOMMUNITYDRUGMART_TENANT_ID,
  'rxdrugmartbarrhead.medmeapp.ca': env.RXDRUGMARTBARRHEAD_TENANT_ID,
  'rxdrugmarthighprairie49.medmeapp.ca': env.RXDRUGMARTHIGHPRAIRIE49_TENANT_ID,
  'rxdrugmartblairmore.medmeapp.ca': env.RXDRUGMARTBLAIRMORE_TENANT_ID,
  'rxdrugmartbanff.medmeapp.ca': env.RXDRUGMARTBANFF_TENANT_ID,
  'rxdrugmartjasper.medmeapp.ca': env.RXDRUGMARTJASPER_TENANT_ID,
  'rxdrugmartfortmacleod.medmeapp.ca': env.RXDRUGMARTFORTMACLEOD_TENANT_ID,
  'rxdrugmarthighprairie53.medmeapp.ca': env.RXDRUGMARTHIGHPRAIRIE53_TENANT_ID,
  'rxdrugmartcamrose.medmeapp.ca': env.RXDRUGMARTCAMROSE_TENANT_ID,
  'forbespharmacycoldlake.medmeapp.ca': env.FORBESPHARMACYCOLDLAKE_TENANT_ID,
  'pinchercreek.medmeapp.ca': env.PINCHERCREEK_TENANT_ID,
  'whitehornremedyrx.medmeapp.ca': env.WHITEHORNREMEDYRX_TENANT_ID,
  'chaparralremedyrx.medmeapp.ca': env.CHAPARRALREMEDYRX_TENANT_ID,
  'rxdrugmartmarinamall.medmeapp.ca': env.RXDRUGMARTMARINAMALL_TENANT_ID,
  'rxdrugmarttricitymall.medmeapp.ca': env.RXDRUGMARTTRICITYMALL_TENANT_ID,
  'medicinehat315.medmeapp.ca': env.MEDICINEHAT315_TENANT_ID,
  'medicinehat303.medmeapp.ca': env.MEDICINEHAT303_TENANT_ID,
  'medicinehat302.medmeapp.ca': env.MEDICINEHAT302_TENANT_ID,
  'redcliffpharmasave.medmeapp.ca': env.REDCLIFFPHARMASAVE_TENANT_ID,
  'grandinprescriptioncentre.medmeapp.ca':
    env.GRANDINPRESCRIPTIONCENTRE_TENANT_ID,
  'madiganpharmacy.medmeapp.ca': env.MADIGANPHARMACY_TENANT_ID,
  'familypharmacy49.medmeapp.ca': env.FAMILYPHARMACY49_TENANT_ID,
  'familypharmacy50.medmeapp.ca': env.FAMILYPHARMACY50_TENANT_ID,
  'rxdrugmartkamsack.medmeapp.ca': env.RXDRUGMARTKAMSACK_TENANT_ID,
  'rxdrugmartshaunavon.medmeapp.ca': env.RXDRUGMARTSHAUNAVON_TENANT_ID,
  'rxdrugmartmoosomin.medmeapp.ca': env.RXDRUGMARTMOOSOMIN_TENANT_ID,
  'melspharmacy.medmeapp.ca': env.MELSPHARMACY_TENANT_ID,
  'clarkespharmacythompson.medmeapp.ca': env.CLARKESPHARMACYTHOMPSON_TENANT_ID,
  'pembinavalleypharmacy.medmeapp.ca': env.PEMBINAVALLEYPHARMACY_TENANT_ID,
  'hopkins.medmeapp.ca': env.HOPKINS_TENANT_ID,
  'pharmasaveoaktree.medmeapp.ca': env.PHARMASAVEOAKTREE_TENANT_ID,
  'rockyharbour.medmeapp.ca': env.ROCKYHARBOUR_TENANT_ID,
  'arcfluclinic.medmeapp.ca': env.ARCFLUCLINIC_TENANT_ID,
  'hendersonspharmacy.medmeapp.ca': env.HENDERSONSPHARMACY_TENANT_ID,
  'ms363.medmeapp.ca': env.MS363_TENANT_ID,
  'medlandia.medmeapp.ca': env.MEDLANDIA_TENANT_ID,
  'minimajorspharmacy.medmeapp.ca': env.MINIMAJORSPHARMACY_TENANT_ID,
  'thp.medmeapp.ca': env.THP_TENANT_ID,
  'pharmasense.medmeapp.ca': env.PHARMASENSE_TENANT_ID,
  'glengate.medmeapp.ca': env.GLENGATE_TENANT_ID,
  'radiantpharmacy.medmeapp.ca': env.RADIANTPHARMACY_TENANT_ID,
  'northlandpharmacy.medmeapp.ca': env.NORTHLANDPHARMACY_TENANT_ID,
  'everestpharmacy.medmeapp.ca': env.EVERESTPHARMACY_TENANT_ID,
  'gleneden.medmeapp.ca': env.GLENEDEN_TENANT_ID,
  'dgrx.medmeapp.ca': env.DGRX_TENANT_ID,
  'eastbridgepharmacy.medmeapp.ca': env.EASTBRIDGEPHARMACY_TENANT_ID,
  'galleriapharmacy.medmeapp.ca': env.GALLERIAPHARMACY_TENANT_ID,
  'geenspharmacy.medmeapp.ca': env.GEENSPHARMACY_TENANT_ID,
  'demo.medmeapp.ca': env.DEMO_TENANT_ID,
  'centralpharmacy.medmeapp.ca': env.CENTRALPHARMACY_TENANT_ID,
  'fraserrx.medmeapp.ca': env.FRASERRX_TENANT_ID,
  'staffordida.medmeapp.ca': env.STAFFORDIDA_TENANT_ID,
  'eriepharmacy.medmeapp.ca': env.ERIEPHARMACY_TENANT_ID,
  'strathroypharmacy.medmeapp.ca': env.STRATHROYPHARMACY_TENANT_ID,
  'petersdrugs.medmeapp.ca': env.PETERSDRUGS_TENANT_ID,
  'alshafapharmacy.medmeapp.ca': env.ALSHAFAPHARMACY_TENANT_ID,
  'eastsidepharmacy.medmeapp.ca': env.EASTSIDEPHARMACY_TENANT_ID,
  'maynoothida.medmeapp.ca': env.MAYNOOTHIDA_TENANT_ID,
  'idapharmacybancroft.medmeapp.ca': env.IDAPHARMACYBANCROFT_TENANT_ID,
  'wellerpharmacy.medmeapp.ca': env.WELLERPHARMACY_TENANT_ID,
  'dvp.medmeapp.ca': env.DVP_TENANT_ID,
  'prguardian.medmeapp.ca': env.PRGUARDIAN_TENANT_ID,
  'sundridgepharmacy.medmeapp.ca': env.SUNDRIDGEPHARMACY_TENANT_ID,
  'southriverpharmacy.medmeapp.ca': env.SOUTHRIVERPHARMACY_TENANT_ID,
  'hogarthsclinic.medmeapp.ca': env.HOGARTHSCLINIC_TENANT_ID,
  'oakvillepharmacy.medmeapp.ca': env.OAKVILLEPHARMACY_TENANT_ID,
  'nblytravel.medmeapp.ca': env.NBLYTRAVEL_TENANT_ID,
  'bedfordrx.medmeapp.ca': env.BEDFORDRX_TENANT_ID,
  'grandeprairie.medmeapp.ca': env.GRANDEPRAIRIE_TENANT_ID,
  'bethcunni.medmeapp.ca': env.BETHCUNNI_TENANT_ID,
  'medicineshoppemartensville.medmeapp.ca':
    env.MEDICINESHOPPEMARTENSVILLE_TENANT_ID,
  'travelmed.medmeapp.ca': env.TRAVELMED_TENANT_ID,
  'medshoppe294clinic.medmeapp.ca': env.MEDSHOPPE294CLINIC_TENANT_ID,
  'ms421.medmeapp.ca': env.MS421_TENANT_ID,
  'lovelldrugs5056.medmeapp.ca': env.LOVELLDRUGS5056_TENANT_ID,
  'frontenac.medmeapp.ca': env.FRONTENAC_TENANT_ID,
  'taylorcreekpharmacy.medmeapp.ca': env.TAYLORCREEKPHARMACY_TENANT_ID,
  'medicenterpharmacy.medmeapp.ca': env.MEDICENTERPHARMACY_TENANT_ID,
  'hopehealthpharmacy.medmeapp.ca': env.HOPEHEALTHPHARMACY_TENANT_ID,
  'whitespharmacy.medmeapp.ca': env.WHITESPHARMACY_TENANT_ID,
  'woodbuffalopharmacy.medmeapp.ca': env.WOODBUFFALOPHARMACY_TENANT_ID,
  'pharmasave9407.medmeapp.ca': env.PHARMASAVE9407_TENANT_ID,
  'care4youpharmacy.medmeapp.ca': env.CARE4YOUPHARMACY_TENANT_ID,
  'bowmanvillepharmacy.medmeapp.ca': env.BOWMANVILLEPHARMACY_TENANT_ID,
  'strathconapharmacy.medmeapp.ca': env.STRATHCONAPHARMACY_TENANT_ID,
  'tibbpharmacyclinic.medmeapp.ca': env.TIBBPHARMACYCLINIC_TENANT_ID,
  'ms258.medmeapp.ca': env.MEDICINESHOPPESYDNEY_TENANT_ID, // previously 'medicineshoppesydney.medmeapp.ca'
  'healthplusida.medmeapp.ca': env.HEALTHPLUSIDA_TENANT_ID,
  'kaplet.medmeapp.ca': env.KAPLET_TENANT_ID,
  'wallacepharmacy.medmeapp.ca': env.WALLACEPHARMACY_TENANT_ID,
  'commongoodpharmacy.medmeapp.ca': env.COMMONGOODPHARMACY_TENANT_ID,
  'garrisonsquare.medmeapp.ca': env.GARRISONSQUARE_TENANT_ID,
  'dundaswestpharmacy.medmeapp.ca': env.DUNDASWESTPHARMACY_TENANT_ID,
  'hammondsplainspharmacyclinic.medmeapp.ca':
    env.HAMMONDSPLAINSPHARMACYCLINIC_TENANT_ID,
  'bridgewaterguardianmichelin.medmeapp.ca':
    env.BRIDGEWATERGUARDIANMICHELIN_TENANT_ID,
}
