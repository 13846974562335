import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  Grid,
  FormControlLabel,
} from '@material-ui/core'
import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { FhirMarkdownComponent } from '~/lib/markdown/FhirMarkdownComponent'
import { indigo } from '~/sharedStyles/colorPalette'
import { OptionsContainer } from './RadioField.style'

type RadioFC = React.FC<{
  name: string
  label?: string
  options: { value: string; label: string }[]
  defaultValue?: string
  LabelComponent?: React.FC
  helperText?: string
  disabled?: boolean
  required?: boolean
}>

export const YesNoRadioField: RadioFC = ({
  name,
  label,
  options,
  defaultValue: propDefaultValue = '',
  LabelComponent = React.Fragment,
  helperText = '',
  disabled = false,
  required = false,
}) => {
  const { errors, setValue, getValues } = useFormContext()
  const value = getValues(name)

  const defaultValue = React.useMemo(() => {
    const validValues = options.map((option) => option.value)
    if (validValues.includes(propDefaultValue ?? '')) {
      return propDefaultValue
    }
    return ''
  }, [])

  const { field } = useController({
    name: name,
    defaultValue: defaultValue,
  })
  const error = disabled === true ? '' : errors[name]?.message

  React.useEffect(() => {
    const skipValidationOnRequired = !(value === '' && required)
    setValue(name, value, {
      shouldValidate: skipValidationOnRequired,
    })
  }, [value])

  return (
    <MuiFormControl
      component="fieldset"
      error={Boolean(error)}
      fullWidth
      disabled={disabled}
      {...field}
    >
      <MuiRadioGroup name={name} value={value}>
        <Grid container style={{ columnGap: '1rem' }}>
          {label ? (
            <Grid item xs={12} sm={true}>
              <FhirMarkdownComponent>{label}</FhirMarkdownComponent>
            </Grid>
          ) : null}
          <OptionsContainer>
            {options.map((option, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={1}
                  style={{ minWidth: 'fit-content' }}
                  key={i}
                >
                  <FormControlLabel
                    control={<MuiRadio style={{ color: indigo }} />}
                    value={option.value}
                    label={<LabelComponent>{option.label}</LabelComponent>}
                  />
                </Grid>
              )
            })}
          </OptionsContainer>
        </Grid>
      </MuiRadioGroup>
      <MuiFormHelperText
        className="MuiFormHelperText-contained"
        style={{
          width: '100%',
          marginRight: '0px',
          marginLeft: '0px',
          fontSize: '0.9rem',
        }}
      >
        {error || helperText}
      </MuiFormHelperText>
    </MuiFormControl>
  )
}
