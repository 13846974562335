import * as React from 'react'

import { Pharmacy } from '~/lib/pharmacy/'
import { LocationCard } from './LocationCard'
import { Coordinate } from '../../../hooks/useCoordinate'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { LocationListLayout } from './LocationList.style'
import { NoLocations } from './NoLocations'
import { sortLocationsByDistance, moveLocationToTop } from '../../../helpers'

type LocationContainerPageProps = {
  pharmacyList: Pharmacy[]
  selectedPharmacyId: string
  coordinate: Coordinate | null
  onChange: (pharmacy: Pharmacy) => void
}

const getLocationList =
  (
    pharmacyList: Pharmacy[],
    prioritizedPharmacyId: string,
    coordinate: Coordinate | null
  ) =>
  () => {
    const sorted = sortLocationsByDistance(pharmacyList, coordinate)
    const moved = moveLocationToTop(sorted, prioritizedPharmacyId)
    return moved
  }

export const LocationList: React.FC<LocationContainerPageProps> = ({
  pharmacyList,
  selectedPharmacyId,
  coordinate,
  onChange,
}) => {
  const {
    state: { initial },
  } = useBookingContext()

  const locationList = React.useMemo(
    getLocationList(pharmacyList, initial.pharmacy.id, coordinate),
    [pharmacyList, coordinate]
  )
  const findPharmacyById = React.useCallback(
    (id: string) => {
      const pharmacy = pharmacyList.find((pharmacy) => pharmacy.id === id)
      if (!pharmacy)
        throw new Error(
          'Pharmacy not found within pharmacyList: Should not happen unless list is incorrect'
        )
      return pharmacy
    },
    [pharmacyList]
  )

  return (
    <LocationListLayout>
      {locationList.map((location) => (
        <LocationCard
          key={location.pharmacyId}
          pharmacy={findPharmacyById(location.pharmacyId)!}
          distance={location.distance}
          selected={location.pharmacyId === selectedPharmacyId}
          onClick={onChange}
        />
      ))}
      {locationList.length === 0 && <NoLocations />}
    </LocationListLayout>
  )
}
