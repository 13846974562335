import React from 'react'

import { SectionBodyContainer, SectionBodyBold } from '../../Consent.style'

type BoldTextContainerProps = {
  text: string
}

export const BoldTextContainer: React.FC<BoldTextContainerProps> = ({
  text,
}) => {
  return (
    <SectionBodyContainer>
      <SectionBodyBold>{text}</SectionBodyBold>
    </SectionBodyContainer>
  )
}
