import * as React from 'react'
import moment from 'moment'
import lodash from 'lodash'
import { Pharmacy } from '~/lib/pharmacy'
import {
  HeaderSection,
  DateText,
  DayText,
  CalendarGrid,
  HiddenInput,
  DateColumn,
  DateTimeCard,
  FadeScroller,
} from './TimeslotCalendar.style'
import { EmptyCalendar } from './components/EmptyCalendar'
import { useFormContext } from 'react-hook-form'
import { Card } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { SelectionHeader } from './components/SelectionHeader'
import { CalendarNavbar } from './components/CalendarNavbar'
import { useResponsive } from './hooks/useResponsive'
import { useBookingRange, defaultFormat } from './hooks/useBookingRange'
import { IsWalkinRadio } from './components/IsWalkinRadio/IsWalkinRadio'
import { LoadingTimeslots } from './components/LoadingTimeslots/LoadingTimeslots'
import { useGetTimeslots } from './hooks/useGetTimeslots'
import { useAvailableTimeslotsMap } from './hooks/useAvailableTimeslotsMap'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const TimeslotCalendar: React.FC<{
  pharmacy: Pharmacy
  appointmentActivity: AppointmentActivityInstance
  hideHeader?: boolean
}> = ({ pharmacy, appointmentActivity, hideHeader = false }) => {
  const scrollToRef = React.useRef<HTMLDivElement>(null)

  const { timeZone } = pharmacy
  const { register, setValue, watch } = useFormContext()
  const { numDays } = useResponsive()
  const { t } = useMedMeTranslation('patient')

  const { bookingStartDate, bookingEndDate, dateMtz } = useBookingRange(
    pharmacy,
    appointmentActivity
  )

  const [calendarFocused, setCalendarFocused] = React.useState(false)
  const [startDate, setStartDate] = React.useState(
    bookingStartDate().format(defaultFormat)
  )

  const endDate = React.useMemo(() => {
    const slotEndDate = dateMtz(startDate).add(numDays - 1, 'days')
    return slotEndDate.isBefore(bookingEndDate())
      ? slotEndDate.format(defaultFormat)
      : bookingEndDate().format(defaultFormat)
  }, [dateMtz, startDate, numDays, bookingEndDate])

  const { timeslots, loading, error, noCalendarAssigned } = useGetTimeslots(
    pharmacy,
    appointmentActivity.appointmentTypeId,
    startDate,
    endDate
  )

  const { availableTimeslotsMap } = useAvailableTimeslotsMap(
    timeslots,
    startDate,
    endDate,
    timeZone
  )

  const {
    startDateMTz,
    isStartDateToday,
    hasMoreDays,
    isPrevDisabled,
    isNextDisabled,
    onNext,
    onPrev,
  } = React.useMemo(() => {
    const startDateMTz = (add?: number) => dateMtz(startDate).add(add, 'days')
    const todayMTz = (add?: number) =>
      moment().tz(pharmacy.timeZone).add(add, 'days').startOf('day')
    const isStartDateToday = (add?: number) =>
      startDateMTz(add).format(defaultFormat) ===
      todayMTz().format(defaultFormat)

    const hasMoreDays = startDateMTz(numDays - 1).isBefore(bookingEndDate())

    const isPrevDisabled = dateMtz(startDate).isSameOrBefore(bookingStartDate())
    const isNextDisabled = !hasMoreDays

    const resetSelectedTimeslot = () => {
      setValue('startDateTime', '')
      setValue('endDateTime', '')
      setValue('resourceId', '')
    }
    const onNext = () => {
      setStartDate(startDateMTz(numDays).format(defaultFormat))
      resetSelectedTimeslot()
    }
    const onPrev = () => {
      setStartDate(startDateMTz(-numDays).format(defaultFormat))
      resetSelectedTimeslot()
    }
    return {
      startDateMTz,
      isStartDateToday,
      hasMoreDays,
      isPrevDisabled,
      isNextDisabled,
      onNext,
      onPrev,
    }
  }, [startDate, numDays, bookingStartDate, bookingEndDate])

  const isCurrentWeekEmpty = React.useMemo(
    () =>
      Object.values(availableTimeslotsMap).reduce(
        (acc, timeslots) => acc && timeslots.length === 0,
        true
      ),
    [availableTimeslotsMap]
  )
  const isSingleBookingOnly = true
  const maxNoOfPeople = 6
  const todayText = t('schedulingSelection.today')

  const isWalkin = React.useMemo(() => watch('isWalkin') === 'true', [watch])

  React.useEffect(() => {
    if (!loading && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [loading])
  if (error) {
    throw new Error(error)
  }
  return (
    <Card ref={scrollToRef}>
      <HiddenInput tabIndex={-1} name="startDateTime" />
      <HiddenInput tabIndex={-1} name="endDateTime" />
      <HiddenInput tabIndex={-1} name="resourceId" />

      {hideHeader ? null : (
        <>
          <SelectionHeader
            isSingleBookingOnly={isSingleBookingOnly}
            maxNoOfPeople={maxNoOfPeople}
            isWalkin={
              appointmentActivity.isWalkinFeatureEnabled &&
              appointmentActivity.isWalkinAllowed
            }
          />
          {appointmentActivity.isWalkinFeatureEnabled &&
          appointmentActivity.isWalkinAllowed ? (
            <IsWalkinRadio />
          ) : null}
        </>
      )}
      <CalendarNavbar
        startDateMTz={startDateMTz()}
        numDays={numDays}
        onPrevClick={onPrev}
        onNextClick={onNext}
        isPrevDisabled={isPrevDisabled}
        isNextDisabled={isNextDisabled}
        isDisabled={isWalkin || noCalendarAssigned}
      />
      {loading ? (
        <LoadingTimeslots />
      ) : (
        <div
          style={{
            pointerEvents: isWalkin ? 'none' : undefined,
            opacity: isWalkin ? 0.3 : 1,
            height: hideHeader ? '650px' : '350px',
          }}
        >
          <CalendarGrid numberOfCards={numDays} focus={calendarFocused}>
            {(isCurrentWeekEmpty || noCalendarAssigned) && (
              <EmptyCalendar
                numDays={numDays}
                hasMoreDays={hasMoreDays && !noCalendarAssigned}
                onGetMoreDays={onNext}
              />
            )}

            {lodash.range(numDays).map((i) => (
              <div key={i}>
                <HeaderSection>
                  <DateText>{startDateMTz(i).format('ll')}</DateText>
                  <DayText today={isStartDateToday(i)}>
                    {isStartDateToday(i)
                      ? todayText
                      : startDateMTz(i).format('l')}
                  </DayText>
                </HeaderSection>
                <DateColumn>
                  {availableTimeslotsMap[
                    startDateMTz(i).format(defaultFormat)
                  ]?.map(({ startDateTime, endDateTime, resourceId }, j) => (
                    <DateTimeCard
                      name="startDateTime"
                      value={startDateTime}
                      key={j}
                      ref={register}
                      aria-label={moment
                        .tz(startDateTime, timeZone)
                        .format('MMM D ddd h:mmA')}
                      onChange={() => {
                        setValue('endDateTime', endDateTime)
                        setValue('resourceId', resourceId)
                      }}
                      onFocus={() => {
                        setCalendarFocused(true)
                      }}
                      onBlur={() => {
                        setCalendarFocused(false)
                      }}
                      data-cy={'DateTimeCard'}
                    >
                      {moment.tz(startDateTime, timeZone).format('LT')}
                    </DateTimeCard>
                  ))}
                </DateColumn>
              </div>
            ))}
          </CalendarGrid>
        </div>
      )}
      {!isCurrentWeekEmpty && <FadeScroller />}
    </Card>
  )
}
