import styled from 'styled-components'
import { Heading3 } from '../../AppointmentIntake.style'

export const Layout = styled.div`
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
`

export const SectionBody = styled(Heading3)`
  font-weight: 500;
`

export const SectionTitle = styled(Heading3)`
  font-weight: 600;
  margin-bottom: 1rem;
`
