import * as React from 'react'

import { Title, SubTitle, Description } from './ActivityCards.style'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { FormStepContentLayout } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { NoServicesAvailable } from '~/pages/AppointmentIntake/components/NoServicesAvailable'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { Appointment } from '~/lib/appointment'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { AppointmentActivityCardContainer } from '~/componentsTs/AppointmentActivityCardContainer'
import { IAaiCardProps } from '~/componentsTs/AppointmentActivityCardContainer/AppointmentActivityCardContainer.types'

type AilmentSelectorPageProps = {
  aais: AppointmentActivityInstance[]
  selectedAaiId: string
  appointment?: Appointment
  hideDescription?: boolean
  onChange: (id: string) => void
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
}

export const ActivityCards: React.FC<AilmentSelectorPageProps> = ({
  aais,
  selectedAaiId,
  appointment = undefined,
  onChange,
  onSubmit,
  hideDescription = false,
}) => {
  const { state } = useBookingContext()
  const { t } = useMedMeTranslation('patient')

  const activityLandingTitle = t('activityLanding.activityLandingTitle')
  const activityLandingSubTitle = t('activityLanding.activityLandingSubTitle')
  const activityLandingDescription = t(
    'activityLanding.activityLandingDescription'
  )

  // if there's a valid appointmentId, we use the appointment method
  const appointmentMethod = appointment?.id ? appointment?.method : undefined

  return (
    <FormStepContentLayout>
      <form onSubmit={onSubmit}>
        <div style={{ display: 'grid', rowGap: '0.5rem' }}>
          <Title>{activityLandingTitle}</Title>
          {activityLandingSubTitle && (
            <SubTitle>{activityLandingSubTitle}</SubTitle>
          )}
          {activityLandingDescription && !hideDescription && (
            <Description>{activityLandingDescription}</Description>
          )}
        </div>
        <div
          data-testid="ailmentAppointmentList"
          style={{ display: 'grid', rowGap: '1.5rem' }}
        >
          {aais.length > 0 ? (
            aais.map((aai, index) => {
              const cardContainerProps: IAaiCardProps = {
                aai,
                isSelected: selectedAaiId === aai.appointmentActivityId,
                isAvailable:
                  aai.settings.isBookable || Boolean(state.appointment.id),
                onExpand: onChange,
                onCollapse: onChange,
                onSubmit: onChange,
                appointmentMethod,
              }

              return (
                <div
                  key={index}
                  data-testid={`apptType${aai.appointmentActivityId}`}
                >
                  <AppointmentActivityCardContainer
                    {...cardContainerProps}
                    isLegacy={true}
                  />
                </div>
              )
            })
          ) : (
            <NoServicesAvailable />
          )}
        </div>
      </form>
    </FormStepContentLayout>
  )
}
