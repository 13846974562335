import * as React from 'react'
import { FhirMarkdownComponent } from '~/lib/markdown/FhirMarkdownComponent'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Body,
  CardTitle,
  SectionTitle,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { createFhirExtProps } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { FhirLeafComponent } from '../FhirLeafComponent'

export const FhirDisplayLabel: typeof FhirLeafComponent = ({
  questionnaireItem,
}) => {
  const { _type } = questionnaireItem
  const { typeVariant } = React.useMemo(
    () => createFhirExtProps(_type?.extension),
    []
  )

  const Component = React.useMemo(() => {
    switch (typeVariant) {
      case 'h1':
        return Heading1
      case 'h2':
        return Heading2
      case 'h3':
        return Heading3
      case 'h4':
        return Heading4
      case 'body1':
        return Body
      case 'cardTitle':
        return CardTitle
      case 'markdown':
        return FhirMarkdownComponent
      case 'sectionTitle':
        return SectionTitle
      default:
        return Body
    }
  }, [])

  return <Component>{questionnaireItem.text as string}</Component>
}
