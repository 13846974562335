import { useQuery, gql } from '@apollo/client'

// util
import { isSdmEnterprise } from '~/util/enterpriseMethods'

const IS_USER_LOGGED_IN = gql`
  query isUserLoggedIn {
    isLoggedIn @client
    isAdmin @client
    isPharmacyStaffReadOnly @client
    isSamlLogin @client
  }
`

export const useIsUserLoggedIn = () => {
  const { data } = useQuery(IS_USER_LOGGED_IN)

  const updatedData = {
    ...data,
    isSdmPharmacyStaffReadOnly:
      (data?.isPharmacyStaffReadOnly && isSdmEnterprise()) ?? false,
  }

  return {
    data: updatedData,
  }
}
