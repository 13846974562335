import { FhirFormStateContext } from '../../FhirForm'
import { DefaultValuesAccumulator } from './defaultValuesAccumulator.type'

const isRecord = (value: unknown): value is Record<string, unknown> =>
  typeof value === 'object' && value !== null

const get = (
  value: unknown,
  path: string
): string | boolean | number | undefined => {
  if (isRecord(value) && Boolean(path)) {
    const [firstElement, ...rest] = path.split('.')
    return get(value[firstElement], rest.join('.'))
  } else if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return value
  } else {
    return undefined
  }
}

export const setEntityValueWhenUndefined =
  (state: FhirFormStateContext): DefaultValuesAccumulator =>
  (acc, linkIdItems) => {
    Object.keys(linkIdItems).forEach((linkId) => {
      const item = linkIdItems[linkId]
      if (acc[linkId] === undefined) {
        const entityPathObjs = item.getEntityPathObject()
        acc[linkId] = entityPathObjs.reduce((acc, { entity, path }) => {
          if (acc !== undefined) {
            return acc
          }
          switch (entity) {
            case 'patient':
              return get(state.patient, path)
            case 'appointment':
              return get(state.appointment, path)
            default:
              return undefined
          }
        }, undefined)
      }
    })

    return acc
  }
