import { ApolloError } from '@apollo/client'
import { TSFixMe } from '~/types/commonTypes'

export function isTimeSlotReservationError(error?: ApolloError): boolean {
  return (
    error?.graphQLErrors?.some(
      (graphQLError) =>
        (graphQLError?.extensions?.originalError as TSFixMe)?.code ===
        'TIME_SLOT_RESERVATION_FAILED'
    ) ?? false
  )
}
