import * as React from 'react'
import Loading from '~/components/loading'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const LoadingTimeslots: React.FC = () => {
  const { t } = useMedMeTranslation('common')

  const title = t('loading.title.holdOnTight')
  const subtitle = t('loading.subtitle.retrievingAvailableTimes')
  return <Loading title={title} subTitle={subtitle} />
}
