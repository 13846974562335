import styled from 'styled-components'
import {
  Body,
  Heading1,
  Heading2,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const SectionBodyBold = styled(Body)`
  font-weight: 700;
`
export const SectionBodySemiBold = styled(Body)`
  font-weight: 600;
  color: gray;
`
export const SectionBodyContainer = styled.div`
  margin-bottom: 1rem;
`

export const NextSteps = styled.ol`
  list-style: auto;
  padding-left: 1rem;
  gap: 0.5rem;
`

export const NextStepElement = styled.li`
  margin-bottom: 0.5rem;
`

export const SectionTitle = styled(Heading1)`
  margin-bottom: 1rem;
`

export const CardTitle = styled(Heading2)`
  font-weight: 700;
  margin-bottom: 1.5rem;
`

export const NextStepsContainer = styled.div`
  display: 'flex';
  min-width: 0;
`
