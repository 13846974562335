import * as React from 'react'

import {
  usePharmacyContext,
  useWhiteLabelContext,
} from '~/pages/AppointmentIntake/contexts'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { LayoutWrapper } from '~/componentsTs/PatientSideLayout'
import { Body, CardContainer } from './FormStepLayout.style'

type LayoutProps = React.PropsWithChildren<{
  children: React.ReactNode
  showAddress?: boolean
}>
export const FormStepLayout = ({
  children,
  showAddress = false,
}: LayoutProps) => {
  const { pharmacy } = usePharmacyContext()
  const { whiteLabel, loading: isWhiteLabelLoading } = useWhiteLabelContext()
  const { isLastForm } = useFormManager()

  return (
    <LayoutWrapper
      pharmacy={pharmacy}
      whiteLabel={whiteLabel}
      isLastForm={isLastForm}
      showAddress={showAddress}
      isWhiteLabelLoading={isWhiteLabelLoading}
    >
      <Body>
        <CardContainer>{children}</CardContainer>
      </Body>
    </LayoutWrapper>
  )
}
