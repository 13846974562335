export const createBookingFields = (fields?: unknown) => {
  const isFieldsObject = (
    fields: unknown
  ): fields is Record<string, string | number | boolean | null | undefined> => {
    return typeof fields === 'object' && fields !== null
  }

  if (!isFieldsObject(fields)) {
    //throw new Error('fields must be an object')
    return {}
  }

  return Object.keys(fields).reduce<
    Record<string, string | number | boolean | null>
  >((acc, key) => {
    if (!/^[a-zA-Z]+$/.test(key)) {
      throw new Error(`Field name '${key}' contains invalid characters`)
    }
    const value = fields[key]
    if (
      typeof value === 'number' ||
      typeof value === 'string' ||
      typeof value === 'boolean' ||
      value === null
    ) {
      return {
        ...acc,
        [key]: value,
      }
    } else {
      return {
        ...acc,
        [key]: null,
      }
    }
  }, {})
}
