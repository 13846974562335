import { Engine } from 'json-rules-engine'
import { Resolver } from 'react-hook-form'
import {
  FhirQuestionnaire,
  SimpleValueType,
} from '~/lib/fhir/fhirQuestionnaire'
import { createValidationErrorHandler as createDefaultValidationErrorHandler } from './createValidationErrorHandler'

export const createFhirQuestionnaireResolverBuilder =
  ({ validationErrorHandler = createDefaultValidationErrorHandler() } = {}) =>
  (
    rulesEngine: Engine,
    questionnaire: FhirQuestionnaire
  ): Resolver<Record<string, SimpleValueType>> =>
  async (values: Record<string, SimpleValueType>) => {
    const getDefaultStateForFields = () =>
      Object.keys(questionnaire.linkIdItems).reduce<
        Record<string, undefined | true>
      >((acc, key) => {
        acc[key] = undefined
        return acc
      }, {})
    Object.keys({ ...getDefaultStateForFields(), ...values }).forEach((key) => {
      const value = values[key]
      rulesEngine.addFact(key, value)
    })
    const result = await rulesEngine.run()
    const errors = validationErrorHandler(result, questionnaire)

    if (Object.keys(errors).length) {
      return {
        errors,
        values: {},
      }
    }
    return {
      values,
      errors: {},
    }
  }

export type FhirQuestionnaireResolverBuilder = ReturnType<
  typeof createFhirQuestionnaireResolverBuilder
>
