import React from 'react'
import styled from 'styled-components'
import { Field, ErrorMessage } from 'formik'
import Cleave from 'cleave.js/react'
import { primary, darkBlue, lightRed, lightGray } from './colorPalette'

export const InputField = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 1rem;
`

export const Label = styled.label`
  font-weight: 700;
  margin-bottom: 0;
  color: ${primary};
`

export const Input = styled(Field)`
  width: 100%;
  border: 1.5px solid ${lightGray};
  border-radius: 5px;
  padding: 0 0.5rem;
  transition: all 0.15s ease-in-out;
  font-size: 1em;
  font-family: inherit;
  background-color: transparent;

  &:focus {
    outline: none;
    border: 1.5px solid ${darkBlue};
  }

  &::placeholder {
    color: #d7d7d7;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const ErrorField = styled(ErrorMessage)`
  color: ${lightRed};
  font-weight: 700;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: -0.75rem;
`

export const ErrorText = styled.div`
  color: ${lightRed};
  font-weight: 700;
  text-align: center;
  margin: 0.5rem 0;
`

export const CheckboxField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

export const CheckboxLabel = styled.label`
  color: ${primary};
  margin: 0;
  flex: 0.9;
  white-space: nowrap;
`

export const Checkbox = styled(Field)`
  transform: scale(1.25);
  flex: 0.1;
  margin: 0 0.25rem;
`

export const CheckboxInput = styled.input`
  transform: scale(1.25);
  flex: 0.1;
  margin: 0 0.25rem;
`

export const CleaveInput = ({ field, inputRef, ...props }) => (
  <div>
    <Cleave
      {...field}
      {...props}
      htmlRef={(r) => inputRef && (inputRef.current = r)}
    />
  </div>
)
