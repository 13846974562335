import { isSdmEnterprise } from '../enterpriseMethods'

export type Province = {
  label: string
  value: string
}

export const allProvinces: Province[] = [
  {
    label: 'Alberta',
    value: 'AB',
  },
  {
    label: 'British Columbia',
    value: 'BC',
  },
  {
    label: 'Manitoba',
    value: 'MB',
  },
  {
    label: 'New Brunswick',
    value: 'NB',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    label: 'Nova Scotia',
    value: 'NS',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Prince Edward Island',
    value: 'PE',
  },
  {
    label: 'Quebec',
    value: 'QC',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    label: 'Yukon',
    value: 'YT',
  },
]

export const defaultProvinces: Province[] = [
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Alberta',
    value: 'AB',
  },
  {
    label: 'British Columbia',
    value: 'BC',
  },
  {
    label: 'Manitoba',
    value: 'MB',
  },
  {
    label: 'Prince Edward Island',
    value: 'PE',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    label: 'Nova Scotia',
    value: 'NS',
  },
  {
    label: 'New Brunswick',
    value: 'NB',
  },
]
export const sdmProvinces: Province[] = [
  {
    label: 'Alberta',
    value: 'AB',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
]

export enum EProvinceCode {
  ALBERTA = 'AB',
  BRITISH_COLUMBIA = 'BC',
  MANITOBA = 'MB',
  NEW_BRUNSWICK = 'NB',
  NEWFOUNDLAND_AND_LABRADOR = 'NL',
  NOVA_SCOTIA = 'NS',
  NUNAVUT = 'NU',
  NORTHWEST_TERRITORIES = 'NWT',
  ONTARIO = 'ON',
  PRINCE_EDWARD_ISLAND = 'PE',
  QUEBEC = 'QC',
  SASKATCHEWAN = 'SK',
  YUKON = 'YK',
  YUKON_TERRITORY = 'YT',
}

const MA_SUPPORTED_PROVINCE_CODES: EProvinceCode[] = [
  EProvinceCode.ONTARIO,
  EProvinceCode.BRITISH_COLUMBIA,
  EProvinceCode.ALBERTA,
  EProvinceCode.NOVA_SCOTIA,
  EProvinceCode.NEWFOUNDLAND_AND_LABRADOR,
  EProvinceCode.NEW_BRUNSWICK,
]

export const isMinorAilmentAvailable = (province: string): boolean => {
  return (
    !isSdmEnterprise() &&
    MA_SUPPORTED_PROVINCE_CODES.includes(province as EProvinceCode)
  )
}
