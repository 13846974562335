import { useMemo } from 'react'
import {
  GetPharmacySupportedLanguageQuery,
  useGetPharmacySupportedLanguageQuery,
} from '../../graphql/types/schemaNode.type'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { ELanguageCode } from '~/graphql/types/schema.type'

export type PharmacySupportedLanguage = Omit<
  NonNullable<
    NonNullable<
      GetPharmacySupportedLanguageQuery['getPharmacy']
    >['supportedLanguages']
  >[0],
  '__typename'
>

export const usePharmacySupportedLanguages = (pharmacyId?: string) => {
  const { data, loading } = useGetPharmacySupportedLanguageQuery({
    variables: {
      pharmacyId: String(pharmacyId), // this will never be empty because of the skip
    },
    context: {
      clientName: EServer.NODE,
    },
    skip: !pharmacyId,
  })

  const supportedLanguages = useMemo(
    () => data?.getPharmacy?.supportedLanguages ?? [],
    [data?.getPharmacy?.supportedLanguages]
  )

  const supportsFrench = useMemo(
    () => supportedLanguages.some((lang) => lang.code == ELanguageCode.FR),
    [supportedLanguages]
  )

  return {
    supportedLanguages,
    supportsFrench,
    loading,
  }
}
