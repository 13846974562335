import * as React from 'react'

import { BaseLayout, HeaderBase } from './LayoutWrapper.style'

import { Pharmacy as PharmacyGql } from '~/graphql/types/schema.type'
import { Pharmacy } from '~/lib/pharmacy'
import { DeepPartial } from '~/pages/AppointmentIntake/lib/types'
import { WhiteLabel } from '~/pages/AppointmentIntake/contexts/WhiteLabelContext/createWhiteLabel'
import { HeaderWrapper } from './HeaderWrapper'
import { isJcg } from '~/util/enterpriseMethods'

type LayoutProps = React.PropsWithChildren<{
  children: React.ReactNode
  pharmacy: DeepPartial<PharmacyGql> | Pharmacy
  whiteLabel?: WhiteLabel
  isLastForm?: boolean
  showAddress?: boolean
  isWhiteLabelLoading?: boolean
}>
export const LayoutWrapper = ({
  children,
  pharmacy,
  whiteLabel,
  isLastForm = false,
  showAddress = false,
  isWhiteLabelLoading = false,
}: LayoutProps) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const whiteLabelLogo = whiteLabel?.logoLong
  const whiteLabelLogoInverse = whiteLabel?.logoLongInverse
  const pharmacyLogo = pharmacy?.logoLong

  let logo = whiteLabelLogo
    ? whiteLabelLogo
    : pharmacyLogo
    ? process.env.REACT_APP_S3_BASE_URL + pharmacyLogo
    : ''

  //JCG does not follow our "Pharmacist facing logo is always patient facing logo data pattern" - See NEB-112
  if (isJcg() && whiteLabelLogoInverse) {
    logo = whiteLabelLogoInverse
  }

  return (
    <BaseLayout>
      <HeaderBase />
      {!isWhiteLabelLoading && (
        <HeaderWrapper
          logo={logo}
          showAddress={showAddress}
          isLastForm={isLastForm}
          pharmacy={pharmacy}
        />
      )}
      {children}
    </BaseLayout>
  )
}
