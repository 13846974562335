import * as React from 'react'
import { Pharmacy } from '~/lib/pharmacy'

export const useUpdateHeader = (pharmacy: Pharmacy) => {
  React.useEffect(() => {
    if (pharmacy.id !== localStorage.getItem('pharmacyId')) {
      localStorage.setItem('pharmacyId', pharmacy.id)
    }
  }, [pharmacy.id])
}
