import { Extension } from 'fhir/r5'
import {
  ExtensionValidationBehaviour,
  validationBehaviourCodes,
} from '../validation.type'

export const isExtensionValidationBehaviour = (
  extension: Extension
): extension is ExtensionValidationBehaviour => {
  return (
    extension.url === 'behaviour' &&
    (validationBehaviourCodes as ReadonlyArray<string>).includes(
      extension.valueCode ?? ''
    ) &&
    Object.keys(extension).length === 2
  )
}
