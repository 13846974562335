import { Extension } from 'fhir/r5'
import { extensionValidationUrl } from '../validation.type'
import { ExtensionValidation } from '../validation.type'
import { isValidExtensionProps } from '../../lib/isValidExtensionProps'
import { isExtensionValidationMessage } from './isExtensionValidationMessage'
import { isExtensionValidationConditionGroup } from './isExtensionValidationConditionGroup'

export const isExtensionValidation = (
  extension: Extension
): extension is ExtensionValidation => {
  return (
    extension.url === extensionValidationUrl &&
    isValidExtensionProps(extension, [
      isExtensionValidationMessage,
      isExtensionValidationConditionGroup,
    ]) &&
    Object.keys(extension).length === 2
  )
}
