export const transformToAwsPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber

  const parsedPhoneNumber =
    (phoneNumber[0] === '+' ? '+' : '+1') + phoneNumber.replace(/\D/g, '')

  return parsedPhoneNumber
}

export const transformToInternationalAwsPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber
  const parsedPhoneNumber = '+' + phoneNumber.replace(/\D/g, '')

  return parsedPhoneNumber
}

export const transformToStandardPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber
  if (phoneNumber.match(/^[0-9]{3} [0-9]{3} [0-9]{4}/)) return phoneNumber
  const areaCode = phoneNumber.substring(2, 5)
  const middle = phoneNumber.substring(5, 8)
  const last = phoneNumber.substring(8)

  return `${areaCode} ${middle} ${last}`
}

export const transformToAmericanHrefNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber
  if (phoneNumber.match(/^[0-9]{3} [0-9]{3} [0-9]{4}$/))
    return `+1${phoneNumber.replace(/\s/g, '-')}`
  if (phoneNumber.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
    return `+1${phoneNumber}`
  const areaCode = phoneNumber.substring(2, 5)
  const middle = phoneNumber.substring(5, 8)
  const last = phoneNumber.substring(8)

  return `+1${areaCode}-${middle}-${last}`
}

export const transformToHumanReadableNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber
  const areaCode = phoneNumber.substring(2, 5)
  const middle = phoneNumber.substring(5, 8)
  const last = phoneNumber.substring(8)

  return `+1 (${areaCode}) ${middle}-${last}`
}

export const transformToCovaxPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber

  const parsedPhoneNumber = phoneNumber.replace(/\D/g, '')
  const areaCode = parsedPhoneNumber.slice(-10, -7)
  const middle = parsedPhoneNumber.slice(-7, -4)
  const last = parsedPhoneNumber.slice(-4, parsedPhoneNumber.length)

  return `${areaCode}-${middle}-${last}`
}

export const formatDeliveryMessage = (email, cell) => {
  if (email && cell)
    return `We've sent a confirmation with next steps to your email at ${email} and phone at ${transformToHumanReadableNumber(
      cell
    )}`
  else if (email)
    return `We've sent an email confirmation with next steps to ${email}`
  else if (cell)
    return `We've sent a text confirmation with next steps to ${transformToHumanReadableNumber(
      cell
    )}`

  return ''
}

export const formatPostalCode = (postalCode) => {
  if (!postalCode) return ''
  const strippedPostalCode = postalCode.replace(/ /g, '').toUpperCase()
  if (strippedPostalCode.length !== 6) return ''

  return `${strippedPostalCode.slice(0, 3)} ${strippedPostalCode.slice(3, 6)}`
}
