import * as React from 'react'
import { createPatient } from '~/lib/patient'

export const createPatientContext = () => ({
  initialPatient: createPatient(),
  patient: createPatient(),
  dispatch: (() => undefined) as React.Dispatch<any>,
})

export type PatientContextInterface = ReturnType<typeof createPatientContext>

export const PatientContext = React.createContext(createPatientContext())
