import { Extension } from 'fhir/r4'
import { fhirStructDefUrlMedMe } from '../constants'

const extPathName = 'typeVariant'

export const fhirExtInputVariantUrl =
  `${fhirStructDefUrlMedMe}${extPathName}` as const

export const isFhirExtInputVariant = (
  fhir?: Extension
): fhir is FhirExtInputVariant => {
  return fhir?.url === fhirExtInputVariantUrl
}

export const createFhirExtInputVariant = (
  fhir?: Partial<Extension>
): FhirExtInputVariant => {
  return {
    url: fhirExtInputVariantUrl,
    valueString: fhir?.valueString ?? '',
  }
}

export type FhirExtInputVariant = {
  url: typeof fhirExtInputVariantUrl
  valueString: string
}

export const createFhirExtInputVariantProps = (
  ext: Extension
): { [extPathName]: string | undefined } => {
  if (isFhirExtInputVariant(ext)) {
    const typedExt = createFhirExtInputVariant(ext)
    return {
      [extPathName]: typedExt.valueString,
    }
  }

  return {
    [extPathName]: undefined,
  }
}
