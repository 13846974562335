import * as React from 'react'
import {
  NavigationButton,
  NavigationRightButtonText,
} from './CalendarNavbar.style'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { useResponsive } from '../../hooks/useResponsive'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const NextNavButton: React.FC<{
  onClick: () => void
  isDisabled: boolean
  numDays: number
}> = ({ onClick, isDisabled, numDays }) => {
  const { deviceType } = useResponsive()
  const { t } = useMedMeTranslation('patient')

  const buttonText = t('schedulingSelection.navigateNextDays', {
    numberOfDaysToShow: numDays,
  })

  if (deviceType === 'desktop')
    return (
      <NavigationButton onClick={onClick} disabled={isDisabled}>
        <NavigationRightButtonText>{buttonText}</NavigationRightButtonText>
        <KeyboardArrowRightIcon />
      </NavigationButton>
    )

  if (!isDisabled)
    return (
      <ArrowForwardIosIcon
        style={{ marginRight: '-0.25rem' }}
        onClick={onClick}
      />
    )

  return null
}
