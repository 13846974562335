import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { Layout, CheckboxInput } from './CheckboxField.style'

export const CheckboxField: React.FC<{
  name: string
  label: React.ReactNode
  defaultValue?: boolean
  helperText?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
}> = (props) => {
  const { errors, setValue, trigger } = useFormContext()
  const { field } = useController({
    name: props.name,
    defaultValue: props.defaultValue ?? false,
  })

  const error = props.disabled === true ? '' : errors[props.name]?.message

  return (
    <Layout>
      <FormControlLabel
        control={
          <CheckboxInput
            {...field}
            color="default"
            checked={field.value}
            onChange={(e) => {
              field.onChange(e) // not sure if needed but ill leave it here
              setValue(props.name, e.target.checked)
              trigger(props.name)
            }}
            required={props.disabled ? false : props.required}
            disableRipple
          />
        }
        label={props.label}
        style={{ fontFamily: 'inherit' }}
        disabled={props.disabled}
      />
      <FormHelperText error={Boolean(error)}>
        {error || props.helperText}
      </FormHelperText>
    </Layout>
  )
}
