import { createPresetFormStep } from '..'
import { PendingRescheduleLanding } from '~/pages/AppointmentIntake/forms'
import { isPendingReschedule } from '~/lib/appointment'

export const pendingRescheduleLandingFormStep: typeof createPresetFormStep = (
  json
) => {
  return {
    Component: PendingRescheduleLanding,
    metadata: json.metadata,
    condition: (state) => {
      const appointment = state.initial.appointment
      return isPendingReschedule(appointment)
    },
    isAutoSkippable: async () => false,
  }
}
