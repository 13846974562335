import { DeepPartial } from '~/pages/AppointmentIntake/lib'
import { Timeslot } from '../timeslot.type'

export const createTimeslot = (data: DeepPartial<Timeslot>): Timeslot => {
  return {
    startDateTime: data?.startDateTime ?? '',
    endDateTime: data?.endDateTime ?? '',
    resourceId: data?.resourceId ?? null,
  }
}
