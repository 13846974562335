import React, { lazy } from 'react'
import { BookingProvider as NewBookingProvider } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import { CommonProviders } from '~/pages/AppointmentIntake/NewBooking/CommonProviders'
import { WaitlistIntake } from '~/pages/AppointmentIntake/NewBooking/WaitlistIntake'
import { MonitoredRoute } from '~/tools/monitoring'
import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'

export const newBookingPaths = {
  services: '/booking/services',
  availability: '/booking/availability',
  intake: '/booking/intake',
  completion: '/booking/completion',
  waitlist: '/booking/waitlist',
} as const

export const oldBookingPaths = {
  publicActivity: '/:storeNo?/public/activity/:activity/appointment/:formPath',
  activityGroups: '/:storeNo?/schedule/activity-groups/:activityGroup',
  groups: '/:storeNo?/schedule/groups/minorAilments',
  activity: '/:storeNo?/schedule/activity/:activity',
  documentationLink:
    '/:storeNo?/documentationLink/:appointmentTypeWithCalendarToken/:patientToken/:appointmentToken',
  bookNextAppointmentLink:
    '/:storeNo?/bookNextAppointmentLink/:appointmentTypeWithCalendarToken/:patientToken/:appointmentActivityId',
  bookingLink:
    '/:storeNo?/bookingLink/:appointmentTypeWithCalendarToken/:patientToken/:appointmentToken',
} as const

const AppointmentIntake = lazy(() => import('~/pages/AppointmentIntake'))
const ServiceSelection = lazy(
  () => import('~/pages/AppointmentIntake/NewBooking/ServiceSelection')
)
const AvailabilitySelection = lazy(
  () => import('~/pages/AppointmentIntake/NewBooking/AvailabilitySelection')
)
const FilteredAvailabilitySelection = lazy(
  () =>
    import('~/pages/AppointmentIntake/NewBooking/FilteredAvailabilitySelection')
)
const Intake = lazy(() => import('~/pages/AppointmentIntake/NewBooking/Intake'))
const Completion = lazy(
  () => import('~/pages/AppointmentIntake/NewBooking/Completion')
)

// TODO: remove demo component
export const AppointmentIntakeRoutes = () => {
  const oldPaths = Object.values(oldBookingPaths)
  const newPaths = Object.values(newBookingPaths)

  const appointmentIntakePaths = [...oldPaths, ...newPaths]

  const featureFlagEnableLocationBasedStoreSelection = useFeatureFlag(
    FeatureFlag.ENABLE_PATIENT_LOCATION_INPUT_STORE_SELECTION_PAGE,
    false
  )

  const bookingStoreSelection = featureFlagEnableLocationBasedStoreSelection ? (
    <FilteredAvailabilitySelection />
  ) : (
    <AvailabilitySelection />
  )

  return (
    <MonitoredRoute path={appointmentIntakePaths}>
      <NewBookingProvider>
        <MonitoredRoute path={oldPaths}>
          <AppointmentIntake />
        </MonitoredRoute>
        <MonitoredRoute path={Object.values(newBookingPaths)}>
          <CommonProviders>
            <MonitoredRoute path={newBookingPaths.services}>
              <ServiceSelection />
            </MonitoredRoute>
            <MonitoredRoute path={newBookingPaths.availability}>
              {bookingStoreSelection}
            </MonitoredRoute>
            <MonitoredRoute path={newBookingPaths.intake}>
              <Intake />
            </MonitoredRoute>
            <MonitoredRoute path={newBookingPaths.waitlist}>
              <WaitlistIntake />
            </MonitoredRoute>
            <MonitoredRoute path={newBookingPaths.completion}>
              <Completion />
            </MonitoredRoute>
          </CommonProviders>
        </MonitoredRoute>
      </NewBookingProvider>
    </MonitoredRoute>
  )
}
