/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { theme } from '~/util/mui.theme'

// hooks
import useLogoutHook from '~/auth/useLogout'
import { useIsUserLoggedIn } from '~/auth'

// components
import Sidebar from './components/sidebar'

// styles
import {
  PharmacistLayoutWrapper,
  PharmacistPageContent,
} from './pharmacistLayout.styles'
import { TSFixMe } from '~/types/commonTypes'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as ScThemeProvider } from 'styled-components'
import { PharmacyAaiContextProvider } from '~/context/PharmacyAaiContext'
import { defaultTheme } from '~/sharedStyles/colorPalette'
import { AiChatContent } from '~/componentsTs/AiChatContent'
import { getLocaleBasedLanguageCode } from '~/util/languageMethods'
import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'

interface PharmacyLayoutProps extends React.ComponentPropsWithoutRef<TSFixMe> {
  selected?: string
  isAdmin?: boolean
}

const PharmacistLayout = forwardRef<TSFixMe, PharmacyLayoutProps>(
  ({ children, selected, isAdmin }, ref) => {
    const { data } = useIsUserLoggedIn()
    const pharmacy = useSelector((state: TSFixMe) => state.pharmacy)
    const pharmacist = useSelector((state: TSFixMe) => state.pharmacist)
    const { logout } = useLogoutHook()
    const userInactivityTimeoutInMinutes = useFeatureFlag(
      FeatureFlag.CONFIGURE_USER_INACTIVITY_LOGOUT_TIME,
      120
    )
    useIdleTimer({
      timeout: userInactivityTimeoutInMinutes * 60 * 1000,
      onIdle: () => {
        logout()
      },
      debounce: 500,
    })

    useEffect(() => {
      // if they get to this component, that means that they have the isLoggedIn flag as true, but if they don't have any pharmacy info
      // then should not be in here. Log them out
      // this seems to be happening to rexall only
      // this is a good example of a band-aid
      if (
        data?.isLoggedIn === true &&
        data?.isAdmin === false &&
        !pharmacy?.logoCircle
      )
        logout()
    }, [data, pharmacy, logout])

    const language = getLocaleBasedLanguageCode(pharmacist.languageCode)

    return (
      <MuiThemeProvider theme={theme}>
        <ScThemeProvider theme={defaultTheme}>
          <PharmacyAaiContextProvider
            pharmacy={pharmacy}
            language={language}
            skip={isAdmin}
          >
            <PharmacistLayoutWrapper>
              <Sidebar isAdmin={isAdmin} selected={selected} />

              <PharmacistPageContent ref={ref}>
                <Suspense fallback={null}>{children}</Suspense>
              </PharmacistPageContent>

              <AiChatContent />
            </PharmacistLayoutWrapper>
          </PharmacyAaiContextProvider>
        </ScThemeProvider>
      </MuiThemeProvider>
    )
  }
)

export default PharmacistLayout
