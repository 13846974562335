import { TextField as MuiTextField } from '@material-ui/core'
import React from 'react'
import { useFormContext, useController } from 'react-hook-form'

export const EmailField: React.FC<{
  name: string
  label?: string
  defaultValue?: string
  helperText?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  sanitizationRegex?: RegExp
}> = ({
  name,
  label,
  defaultValue = '',
  helperText,
  placeholder,
  disabled = false,
  required = false,
  sanitizationRegex = /(\\|\/|\?|:|;|!|"|\{|\}|\[|\]|\*|%|\$|#|\^|=|\(|\)|\+|\t|\r)/i,
}) => {
  const { errors, setValue } = useFormContext()
  const {
    field: { onChange: formOnChange, onBlur: formOnBlur, ...restOfFields },
  } = useController({
    name: name,
    defaultValue: defaultValue,
  })

  const onChange: React.ComponentProps<typeof MuiTextField>['onChange'] =
    React.useCallback(
      (e) => {
        e.target.value = e.target.value.replace(sanitizationRegex, '')
        formOnChange(e)
      },
      [formOnChange]
    )

  const onBlur: React.ComponentProps<typeof MuiTextField>['onBlur'] =
    React.useCallback(
      (e) => {
        const value = e.target.value
        const cleaned = value.replace(/\s+/g, '')
        if (cleaned !== value) {
          setValue(name, cleaned, { shouldValidate: true })
        }
        formOnBlur()
      },
      [formOnBlur]
    )

  const error = disabled ? '' : errors[name]?.message
  return (
    <MuiTextField
      variant="outlined"
      type="email"
      label={label}
      error={Boolean(error)}
      helperText={error || helperText}
      placeholder={placeholder}
      disabled={disabled}
      required={!disabled && required}
      fullWidth
      name={restOfFields.name}
      value={restOfFields.value}
      onChange={onChange}
      onBlur={onBlur}
      ref={restOfFields.ref}
    />
  )
}
