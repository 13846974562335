import React, { createContext, useContext } from 'react'
import {
  Appointment,
  AppointmentActivityInstance,
  AvailabilityDateTimeValue,
  CombinedTimeslot,
  PatientOutput,
  Pharmacy,
  Sublocation,
} from '~/graphql/types/schemaNode.type'
import { ClaimCodeFromBookingReturnType } from '~/util/claimCode'

export interface BookingManagementContextInterface {
  isInitialized: boolean
  isCancelled: boolean
  isRescheduled: boolean
  isPatientDocumentLoading: boolean
  appointments: Appointment[]
  numberOfPersons: number
  startDateTime?: Date
  pharmacy?: Pharmacy
  duration?: number
  urlParams: string[]
  services: AppointmentActivityInstance[]
  timeSlot?: CombinedTimeslot
  claimCode: ClaimCodeFromBookingReturnType | string | null
  dispatch: React.Dispatch<{ type: string; payload: any }>
  mainService?: AppointmentActivityInstance | null
  existingBooking?: Appointment
  nextAvailableDate?: AvailabilityDateTimeValue
  externalClinic?: Sublocation
  mainPatient?: PatientOutput
}

export const BookingManagementContext = createContext<
  BookingManagementContextInterface | undefined
>(undefined)

export const useBookingManagementContext = () => {
  const context = useContext(BookingManagementContext)
  if (context === undefined) {
    throw new Error(
      'useBookingManagementContext must be used within a BookingManagementProvider'
    )
  }

  return context
}
