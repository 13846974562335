import styled from 'styled-components'
import { lightGray } from '~/sharedStyles/colorPalette'

export const EditorContainer = styled.div`
  width: 100%;
  border: 1px ${lightGray} solid;
  border-radius: 3px;
  max-height: 20vh;
  overflow-y: auto;

  &:focus-within {
    border: 1.5px solid ${lightGray};
  }

  .rdw-editor-main {
    padding: 0 1rem;
  }

  .sticky-toolbar {
    position: sticky;
    top: 0;
    z-index: 100;
  }
`
