import { NextLink, Operation } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { reportGraphqlException } from '~/tools/monitoring'
import { TSFixMe } from '~/types/commonTypes'

export type ErrorMapType = {
  [index in string]?: (operation: Operation, forward: NextLink) => TSFixMe
}

export const errorLink = (errorMap: ErrorMapType) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    const context = operation.getContext()

    graphQLErrors?.forEach((graphQLError) => {
      const { message, extensions, path } = graphQLError
      const errorMessage = `[GraphQL error]: ${message}`
      const errorHandler =
        errorMap[
          (extensions as TSFixMe)?.errorCode ?? (extensions as TSFixMe)?.code
        ]

      if (errorHandler) return errorHandler(operation, forward)

      reportGraphqlException(new Error(errorMessage), {
        rawData: graphQLError,
        clientName: context.clientName,
        operationName: operation.operationName,
        path,
        extensions,
        headers: context.headers,
      })
      console.error(`Operation: ${operation.operationName}, ${errorMessage}`)
    })

    if (networkError) {
      const errorMessage = `[Network error]: ${networkError}`
      reportGraphqlException(new Error(errorMessage), {
        clientName: context.clientName,
        operationName: operation.operationName,
        headers: context.headers,
      })
      console.error(`Operation: ${operation.operationName}, ${errorMessage}`)
    }
  })
