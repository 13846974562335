import * as React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { isSdmEnterprise } from '~/util/enterpriseMethods'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

const consentDescSdmMaON = `
- The information I have provided is accurate to the best of my knowledge
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate. I understand that eligibility for the Minor Illness Assessment will be confirmed by the Pharmacy Team at the time of appointment.

I understand that if in the event a prescription is written for this service, the cost of the medication is not included in the assessment and will be covered by me or my drug plan.
`
const consentDescSdmMaSK = `
- The information I have provided is accurate to the best of my knowledge
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate. I understand that eligibility for the Minor Illness Assessment will be confirmed by the Pharmacy Team at the time of appointment.

I understand that if in the event a prescription is written for this service, the cost of the medication is not included in the assessment and will be covered by me or my drug plan.
`
const consentDescSdmMaAB = `
- The information I have provided is accurate to the best of my knowledge
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate. I understand that eligibility for the Minor Illness Assessment will be confirmed by the Pharmacy Team at the time of appointment.

I understand that in the event a prescription is written for this service, the cost of the prescription will be covered by me.
`
const consentDescSdmMaNL = `
- The information I have provided is accurate to the best of my knowledge
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate. I understand that eligibility for the Minor Illness Assessment will be confirmed by the Pharmacy Team at the time of appointment.

I understand that if in the event a prescription is written for this service, the cost of the medication is not included in the assessment and will be covered by me or my drug plan.
`
const consentDescSdmMaBC = `
- The information I have provided is accurate to the best of my knowledge
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate. I understand that eligibility for the Minor Illness Assessment will be confirmed by the Pharmacy Team at the time of appointment.

I understand that if in the event a prescription is written for this service, the cost of the medication is not included in the assessment and will be covered by me or my drug plan.
`

const consentDescSdmA1C = `
- The information I have provided is accurate to the best of my knowledge and I have signed up only once.
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate.

Consultation Fee: Complimentary for most existing Patients living with Diabetes or other chronic disease(s), such as hypertension or cardiovascular disease, when provided along with a Medication Review service. $25 fee may apply when provided as a standalone service.
`

const consentDescSdmStrepThroat = `
- The information I have provided is accurate to the best of my knowledge.
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate.

Service Fee: $30
`

const consentDescSdmLipidsTest = `
- The information I have provided is accurate to the best of my knowledge and I have signed up only once.
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate.

Consultation fee: No charge for eligible Patients living with Diabetes or other chronic disease(s), such as hypertension or cardiovascular disease, when the Pharmacist deems it is clinically beneficial and the service is provided along with a medication review assessment or care plan service.  $30 fee when provided as a standalone service.
`

const consentDescSdmModernaInfant = `
- The information I have provided is accurate to the best of my knowledge.
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate. I understand that eligibility for the COVID-19 Infant Vaccine - Moderna will be confirmed by the Pharmacy Team at the time of appointment.
`

const consentDescSdmPfizerInfant = `
- The information I have provided is accurate to the best of my knowledge.
- My personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate. I understand that eligibility for the COVID-19 Infant Vaccine - Pfizer will be confirmed by the Pharmacy Team.
`

const consentDescSdmPaxlovid = `
- The information I have provided is accurate to the best of my knowledge.
- Your personal information may be used and disclosed by MedMe, the pharmacy and/or other healthcare professionals in accordance with MedMe's Privacy Policy ([Terms and Conditions](https://www.medmehealth.com/terms-of-service)) and applicable laws.
- I have reviewed the information above and confirm that my answers are accurate. I understand that eligibility for the COVID-19 Antiviral Treatment (Paxlovid) Assessment will be confirmed by the Pharmacy Team at the time of appointment.
`

const defaultConsent = `
- The information I have provided is accurate to the best of my knowledge
- The secure exchange of information and liability between you, the clinic, MedMe, and any other healthcare professionals in accordance with generally accepted medication therapy management principles ([Terms and Conditions](https://www.medmehealth.com/terms-of-service))
- The details of the related pharmacy/clinic service/program, including: procedures, pricing/reimbursements, eligibility, and follow-ups
- The inherent privacy and security risks associated with virtual care, if applicable, and the best practices implemented by you and the pharmacy/clinic to reduce these risks
`
export const useConsentDescription = () => {
  const {
    state: {
      appointmentActivity,
      scope: { regionCode },
    },
  } = useBookingContext()
  const { t } = useMedMeTranslation('patient')

  const consentDescSdmSmokingCessation = t(
    'appointmentTypes.quitSmoking.consentBulletPoints'
  )

  const consentDescription = React.useMemo(() => {
    if (isSdmEnterprise()) {
      if (appointmentActivity.category === 'MINOR_AILMENT') {
        if (regionCode === 'ON') {
          return consentDescSdmMaON
        } else if (regionCode === 'SK') {
          return consentDescSdmMaSK
        } else if (regionCode === 'AB') {
          return consentDescSdmMaAB
        } else if (regionCode === 'NL') {
          return consentDescSdmMaNL
        } else if (regionCode === 'BC') {
          return consentDescSdmMaBC
        }
      } else if (appointmentActivity.urlParam === 'quit-smoking')
        // Quit Smoking
        return consentDescSdmSmokingCessation
      else if (appointmentActivity.urlParam === 'poct-a1c')
        // A1C
        return consentDescSdmA1C
      else if (appointmentActivity.urlParam === 'poct-strep')
        // Strep Test
        return consentDescSdmStrepThroat
      else if (appointmentActivity.urlParam === 'poct-lipids')
        // Lipid Test
        return consentDescSdmLipidsTest
      else if (appointmentActivity.urlParam === 'pfizer-infant')
        // Pfizer Infant
        return consentDescSdmPfizerInfant
      else if (appointmentActivity.urlParam === 'moderna-infant')
        // Moderna Infant
        return consentDescSdmModernaInfant
      else if (appointmentActivity.urlParam === 'paxlovid')
        // Paxlovid
        return consentDescSdmPaxlovid
      else if (appointmentActivity.urlParam === 'pharmacist-led-clinic') {
        // SDM Clinic
        return t('appointmentTypes.pharmacistLedClinic.consentBulletPointsFhir')
      } else {
        // Every other SDM appointment type. Moving forward, for CoBooking, we will always use this consent
        return t('consentForm.defaultConsentMarkdown')
        //throw new Error('No consent description found for SDM LCL')
      }
    }
    return defaultConsent
  }, [appointmentActivity, t])

  return {
    consentDescription,
  }
}
