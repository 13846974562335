import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../types'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Emergency Screening',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'If you are experiencing a medical emergency, call 9-1-1 or go to your nearest hospital emergency room.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })

  const allergies = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 2 years old
- Pregnant`,
    linkId: 'allergies',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Allergies',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const periodCramps = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 12 years old (if requiring prescription product)
- First episode of dysmenorrhea (new onset of pain with bleeding)`,
    linkId: 'periodCramps',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'PeriodCramps',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const heartBurn = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 18 years old
- Over 50 years old with new onset of acid reflux symptoms`,
    linkId: 'heartBurn',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'HeartBurn',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const hemorrhoids = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 12 years old
- Over 50 years old with onset of new symptoms`,
    linkId: 'hemorrhoids',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Hemorrhoids',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const coldSores = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 12 years old
- First cold sore episode`,
    linkId: 'coldSores',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ColdSores',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const muscleAches = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Individuals under 2 years old
- Individuals under 12 years old (requiring prescription product)
- Pregnant
- Females actively planning for pregnancy`,
    linkId: 'muscleAches',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'MuscleAches',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const uti = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 16 years old
- Male sex assigned at birth
- Pregnant
- Breastfeeding an infant under 1 month old.`,
    linkId: 'uti',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'UrinaryTractInfection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const underTwelveYearsOld = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 12 years old`,
    linkId: 'underTwelveYearsOld',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ShinglesTreatment',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Shingles',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Contraception',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'AthletesFoot',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const acne = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 12 years old
- New onset of acne over 30 years old`,
    linkId: 'acne',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Acne',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const cankerSores = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- First episode of ulcer at age 30 years and over.`,
    linkId: 'cankerSores',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'CankerSores',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const earInfectionBasic = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 6 months`,
    linkId: 'earInfection',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ear-infection-basic',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ear-infection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const sinusInfectionBasic = createFhirQuestionnaireItem({
    text: `This is a check-up to see if you have a sinus infection. You will NOT be eligible for this service if any of the below apply to you:
- You are under 6 months`,
    linkId: 'sinusInfection',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'sinus-infection-basic',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'sinus-infection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const underTwoYearsOld = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 2 years old`,
    linkId: 'underTwoYearsOld',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'JockItch',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Ringworms',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const headache = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Over 50 years old and presenting with a first experience of headache, not yet diagnosed by a medical doctor.`,
    linkId: 'headache',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Headache',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const nailFungus = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 18 years old
- No previous diagnosis by a medical doctor`,
    linkId: 'nailFungus',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'NailFungus',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const erectileDysfunction = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 18 years old
- No previous diagnosis of erectile dysfunction by a medical doctor`,
    linkId: 'erectileDysfunction',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ErectileDysfunction',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const threadworms = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 2 years old
- Pregnant
- Return of pinworms and/or symptoms within 2 weeks of completed pinworm treatment`,
    linkId: 'threadworms',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Threadworms',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const cannotPrescribe = createFhirQuestionnaireItem({
    text: 'If you fall in the category of individuals for whom Pharmacists cannot prescribe, please book an appointment at your nearest walk-in clinic or primary care provider for further assessment.',
    linkId: 'cannotPrescribe',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Allergies',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'PeriodCramps',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'HeartBurn',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Hemorrhoids',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ColdSores',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'MuscleAches',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'UrinaryTractInfection',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ShinglesTreatment',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Shingles',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Contraception',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Acne',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'CankerSores',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'AthletesFoot',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'JockItch',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Headache',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Ringworms',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'NailFungus',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ErectileDysfunction',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Threadworms',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ear-infection-basic',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ear-infection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const additionalEligibility = createFhirQuestionnaireItem({
    text: 'Additional eligibility for assessment and prescribing for minor ailments will be assessed by the pharmacist at the time of your appointment.',
    linkId: 'additionalEligibility',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Allergies',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'PeriodCramps',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'HeartBurn',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Hemorrhoids',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ColdSores',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ShinglesTreatment',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Shingles',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Contraception',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Acne',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'CankerSores',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'AthletesFoot',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'JockItch',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Headache',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Ringworms',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'NailFungus',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ErectileDysfunction',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ear-infection-basic',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ear-infection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const eligibility = createFhirQuestionnaireItem({
    text: 'Eligibility for assessment and prescribing for minor ailments will be assessed by the pharmacist at the time of your appointment.',
    linkId: 'eligibility',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'OralThrush',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'PinkEye',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'SkinRash',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Impetigo',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'InsectBites',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'EmergencyContraception',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'DiaperRash',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'MuscleAches',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'UrinaryTractInfection',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Threadworms',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'MorningSickness',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const healthcardPaymentDisclaimerHeader = createFhirQuestionnaireItem({
    text: 'DISCLAIMER:',
    linkId: 'healthcardDisclaimerHeader',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const skHc = createFhirQuestionnaireItem({
    text: "If you don't have a valid Saskatchewan Health Card, Professional fees may apply.",
    linkId: 'skHc',
    type: 'display',
  })

  const medicationPaymentDisclaimer = createFhirQuestionnaireItem({
    text: 'In the event a prescription is written for this service, the cost of the medication is not included in the assessment. The cost may be covered by you or your drug insurance.',
    linkId: 'medicationPaymentDisclaimer',
    type: 'display',
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      allergies,
      periodCramps,
      heartBurn,
      underTwelveYearsOld,
      underTwoYearsOld,
      erectileDysfunction,
      headache,
      cankerSores,
      nailFungus,
      acne,
      uti,
      coldSores,
      muscleAches,
      hemorrhoids,
      threadworms,
      earInfectionBasic,
      sinusInfectionBasic,
      cannotPrescribe,
      additionalEligibility,
      eligibility,
      healthcardPaymentDisclaimerHeader,
      skHc,
      medicationPaymentDisclaimer,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirMAMedicalScreeningSdmSKJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['SK'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
    },
  },
  metadata: {
    id: 'fhirMAMedicalScreeningSdmSKJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
