import * as React from 'react'
import {
  Layout,
  Title,
  ButtonContainer,
  CancelButton,
  SubmitButton,
  SubDescriptionText,
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel,
  TextLink,
} from './Consent.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { Trans } from 'react-i18next'

export const Consent: React.FC<{
  onCancel: () => void
  onAccept: () => void
}> = ({ onCancel, onAccept }) => {
  const [isAgreed, setIsAgreed] = React.useState(false)

  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed)
  }
  const { t } = useMedMeTranslation('pharmacy', {
    keyPrefix: 'asephaAiConsent',
  })

  const verbiage = {
    title: t('title'),
    checkBox: t('checkBoxText'),
    cancelButton: t('cancelButtonLabel'),
    submitButton: t('submitButtonLabel'),
  }

  return (
    <Layout>
      <Title>{verbiage.title}</Title>
      <SubDescriptionText>
        <Trans t={t} i18nKey="consentText">
          <TextLink target="_blank" href="http://www.asepha.ai/tos">
            Terms & Conditions
          </TextLink>
          <TextLink
            target="_blank"
            href="https://www.medmehealth.com/privacy-policy"
          >
            Privacy Policy
          </TextLink>
          <TextLink target="_blank" href="https://www.asepha.ai/privacy">
            Privacy Policy
          </TextLink>
        </Trans>
      </SubDescriptionText>
      <CheckboxContainer>
        <CheckboxInput checked={isAgreed} onChange={handleCheckboxChange} />
        <CheckboxLabel>{verbiage.checkBox}</CheckboxLabel>
      </CheckboxContainer>
      <ButtonContainer disabled={false}>
        <CancelButton onClick={onCancel}>{verbiage.cancelButton}</CancelButton>
        <SubmitButton disabled={!isAgreed} onClick={onAccept}>
          {verbiage.submitButton}
        </SubmitButton>
      </ButtonContainer>
    </Layout>
  )
}
