import { AccordionSummary, Box, Typography } from '@material-ui/core'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export const TitleLayout = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

export const BadgesContainer = styled(Box)`
  display: flex;
  gap: 0.5rem;
  flex-basis: auto;
  width: fit-content;
`

export const BoldText = styled(Typography)`
  font-weight: 600;
`

export const Heading4 = styled(Typography)<{
  isExpanded?: boolean
}>`
  font-size: 1.1rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.color.titleTertiary : theme.color.textPrimary};
  line-height: 1.35;
`

export const StylizedAccordionSummary = styled(AccordionSummary)<{
  isExpanded?: boolean
}>`
  background-color: ${({ isExpanded, theme }) =>
    isExpanded ? theme.color.backgroundPrimary : 'transparent'};
  transition: background-color 0.2s ease-in-out;
  align-items: flex-start;
  color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.color.titleTertiary : theme.color.textPrimary};
`

export const StylizedExpandMoreIcon = styled(ExpandMoreIcon)<{
  isExpanded?: boolean
}>`
  color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.color.titleTertiary : theme.color.textPrimary};
`

export const TitleAndBadgeContainer = styled(Box)<{
  isSmallDevice: boolean
}>`
  display: flex;
  flex-direction: ${({ isSmallDevice }) => (isSmallDevice ? 'column' : 'row')};
  align-items: flex-start;
  gap: ${({ isSmallDevice }) => (isSmallDevice ? 0 : '0.5rem')};
  margin-bottom: 0.5rem;
`

export const TitleAndDescriptionContainer = styled(Box)`
  padding: 0.25rem 1rem;

  @media (max-width: 800px) {
    padding-left: 0rem;
  }
`

export const MethodDropDownAndSubDescriptionContainer =
  TitleAndDescriptionContainer

export const TitleDescriptionAndIconContainer = styled(Box)`
  gap: 0.5rem;
`
