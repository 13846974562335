import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import { primary, black } from '~/sharedStyles/colorPalette'
import Checkbox from '@material-ui/core/Checkbox'

export const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  border-radius: 5px;
  padding: 40px;
  font-family: 'Montserrat', sans-serif;
  gap: 10px;
  max-width: 600px;
`
export const Title = styled.div`
  font-size: 1.5em;
  font-weight: 600;
`
export const ButtonContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: end;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  gap: 1rem;
`
export const CancelButton = styled(MuiButton)`
  background-color: 'transparent';
  font-weight: 600;
  color: ${({ theme }) => theme.color.primary};
  text-transform: none;
  font-size: 1rem;

  &:hover {
    background-color: transparent;
  }
`
export const SubmitButton = styled(MuiButton)`
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  color: white;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 35px;
  height: 40px;
  width: 120px;
  text-transform: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    color: white;
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const SubDescriptionText = styled.div`
  font-size: 1rem;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

export const CheckboxInput = styled(Checkbox)`
  padding: 0;
  margin-right: 10px;

  &.Mui-checked {
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
`

export const CheckboxLabel = styled.label`
  font-size: 14px;
  color: ${black};
  user-select: none;
`

export const TextLink = styled.a`
  display: inline;
  color: ${primary};

  &:visited {
    color: ${primary};
  }
`
