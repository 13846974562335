import * as React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { BookAppointment, NotAvailable } from './AaiCardSubmitButton.style'

export const AaiCardSubmitButton: React.FC<{
  onClick: () => void
  isAvailable: boolean
}> = ({ onClick, isAvailable }) => {
  const { t } = useMedMeTranslation('patient')

  const verbiage = {
    submitButtonText: t('serviceSelection.addService'),
    notAvailableText: t('aai.currentlyUnavailable'),
  }

  if (isAvailable)
    return (
      <BookAppointment
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
        type="submit"
        data-cy="bookAppointment"
      >
        {verbiage.submitButtonText}
      </BookAppointment>
    )
  return (
    <NotAvailable
      onClick={(e: any) => {
        e.stopPropagation()
      }}
    >
      {verbiage.notAvailableText}
    </NotAvailable>
  )
}
