import React from 'react'

import {
  SectionBodyContainer,
  SectionBodySemiBold,
} from '../../Completion.style'

type TextContainerProps = {
  text: string
}

export const TextContainer: React.FC<TextContainerProps> = ({ text }) => {
  return (
    <SectionBodyContainer>
      <SectionBodySemiBold>{text}</SectionBodySemiBold>
    </SectionBodyContainer>
  )
}
