import {
  AppointmentActivityInstance,
  BookAppointmentMutation,
  PatientOutput,
} from '~/graphql/types/schemaNode.type'

export type ClaimCodeFromBookingReturnType = {
  claimCodeByPatient: string | null
  claimCodeByAppointment: {
    mainServiceClaimCode: string | null
    additionalServiceClaimCodes: string[]
  }
}

export const getClaimCodeForUrlParam = (
  isClaimCodeUrlEnabled: boolean,
  claimCode: ClaimCodeFromBookingReturnType | string | null,
  shouldReturnAdditionalClaimCodes = false
): Record<string, string> => {
  if (!isClaimCodeUrlEnabled || !claimCode) return {}

  if (typeof claimCode === 'string' && claimCode.length > 0) {
    return { claimCode }
  }

  if (
    typeof claimCode === 'object' &&
    'claimCodeByPatient' in claimCode &&
    'claimCodeByAppointment' in claimCode
  ) {
    if (claimCode.claimCodeByPatient) {
      return { claimCode: claimCode.claimCodeByPatient }
    }

    const { mainServiceClaimCode, additionalServiceClaimCodes } =
      claimCode.claimCodeByAppointment

    if (mainServiceClaimCode && additionalServiceClaimCodes.length === 0) {
      return { claimCode: mainServiceClaimCode }
    }

    if (mainServiceClaimCode && additionalServiceClaimCodes.length > 0) {
      if (shouldReturnAdditionalClaimCodes) {
        return {
          claimCode: `${mainServiceClaimCode},${additionalServiceClaimCodes.join(
            ','
          )}`,
        }
      }

      return {
        claimCode: mainServiceClaimCode,
      }
    }
  }

  return {}
}

export const getClaimCodeFromBookAppointmentMutationData = (
  data?: BookAppointmentMutation | null,
  mainServiceFromContext?: AppointmentActivityInstance | null
): ClaimCodeFromBookingReturnType => {
  const result: ClaimCodeFromBookingReturnType = {
    claimCodeByPatient: null,
    claimCodeByAppointment: {
      mainServiceClaimCode: null,
      additionalServiceClaimCodes: [],
    },
  }

  const claimCodeByPatient = data?.bookAppointment?.appointmentClaim?.claimCode
  result.claimCodeByPatient = claimCodeByPatient ?? null

  const mainServiceType = mainServiceFromContext?.urlParam
  const mainPatient = data?.bookAppointment?.patients?.find((patient) =>
    patient.services?.find((service) => service.isMainPatient)
  )
  const mainService = mainPatient?.services?.find(
    (service) => service.serviceType === mainServiceType
  )
  result.claimCodeByAppointment.mainServiceClaimCode =
    mainService?.appointmentClaim?.claimCode ?? null

  const additionalServices =
    mainPatient?.services?.filter(
      (service) => service.serviceType !== mainServiceType
    ) ?? []

  result.claimCodeByAppointment.additionalServiceClaimCodes =
    (additionalServices
      .map((service) => service.appointmentClaim?.claimCode ?? null)
      .filter(Boolean) as string[]) ?? []

  /**
   * if we are at this point and there is no main service claim code, something went wrong
   * with the way we store claim code in BookingContext
   */
  if (
    !result.claimCodeByAppointment.mainServiceClaimCode &&
    result.claimCodeByAppointment.additionalServiceClaimCodes.length
  ) {
    result.claimCodeByAppointment.additionalServiceClaimCodes = []
  }

  return result
}

export const getClaimCodeFromRescheduleAppointmentData = (
  data?: PatientOutput | null
): ClaimCodeFromBookingReturnType => {
  const result: ClaimCodeFromBookingReturnType = {
    claimCodeByPatient: null,
    claimCodeByAppointment: {
      mainServiceClaimCode: null,
      additionalServiceClaimCodes: [],
    },
  }

  if (!data) return result

  if (data.appointmentClaim?.claimCode) {
    result.claimCodeByPatient = data.appointmentClaim.claimCode
  }

  // the BE guarantees that the first appointment is the main service/service
  // designated by the booking keys from the reschedule email
  const mainService = data.appointments?.[0]

  if (mainService?.appointmentClaim?.claimCode) {
    result.claimCodeByAppointment.mainServiceClaimCode =
      mainService.appointmentClaim.claimCode
  }

  const additionalServices = data.appointments?.filter(
    (appointment) =>
      appointment.appointmentType?.appointmentActivityType !==
      mainService?.appointmentType?.appointmentActivityType
  )

  if (additionalServices?.length) {
    result.claimCodeByAppointment.additionalServiceClaimCodes =
      additionalServices
        .map((service) => service.appointmentClaim?.claimCode ?? null)
        .filter(Boolean) as string[]
  }

  /**
   * if we are at this point and there is no main service claim code, something went wrong
   * with the way we store claim code in BookingContext
   */
  if (
    !result.claimCodeByAppointment.mainServiceClaimCode &&
    result.claimCodeByAppointment.additionalServiceClaimCodes.length
  ) {
    result.claimCodeByAppointment.additionalServiceClaimCodes = []
  }

  return result
}
