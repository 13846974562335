import * as React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import {
  AppointmentContainer,
  PharmacyAddressContainer,
  ContactInfoContainer,
  TextContainer,
} from './components'

import {
  SectionBodyBold,
  SectionTitle,
  CardTitle,
  NextSteps,
  NextStepElement,
  NextStepsContainer,
} from './Completion.style'

import {
  CardLayout,
  Card,
  Body,
  FormStepContentLayout,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'

import { MarkdownComponent } from '~/lib/markdown/MarkdownComponent'
import { useCompletionVerbiage } from './hooks/useCompletionVerbiage'
import { getAaiFullName } from '~/lib/appointmentActivity'
import { EAppointmentTypeMethod } from '~/graphql/types/schema.type'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const Completion: React.FC = () => {
  const { t } = useMedMeTranslation('patient')
  const {
    state: {
      pharmacy,
      appointmentActivity,
      appointment,
      patient,
      appointmentActivityGroup,
    },
  } = useBookingContext()
  const { isWalkin } = appointment
  const { pharmacyAddress, timeZone, name: pharmacyName } = pharmacy
  const {
    details: {
      bookingConfirmation,
      bookingVirtualConfirmation,
      bookingPhoneConfirmation,
      walkinBookingConfirmation,
    },
  } = appointmentActivity
  const { completionTitle, appointmentDetailsTitle } = useCompletionVerbiage()
  const appointmentActivityFullName = getAaiFullName(
    appointmentActivity,
    appointmentActivityGroup
  )

  /**
   * Grabs the appropriate next steps
   */
  const nextSteps = React.useMemo(() => {
    if (isWalkin) {
      return walkinBookingConfirmation
    } else if (appointment.method === 'VIRTUAL') {
      return bookingVirtualConfirmation
    } else if (appointment.method === 'PHONE') {
      return bookingPhoneConfirmation
    }
    return bookingConfirmation
  }, [])

  /**
   * Appends the method to the end of the AAI name, except if the method ONSITE
   */
  const getAaiNameWithMethod = (
    aaiName: string,
    method: EAppointmentTypeMethod
  ) => {
    return `${aaiName} - (${t(`enums.appointmentMethod.${method}`)})`
  }

  return (
    <FormStepContentLayout>
      <CardLayout>
        <SectionTitle>{completionTitle}</SectionTitle>
        <Card>
          <CardTitle>{appointmentDetailsTitle}</CardTitle>

          <AppointmentContainer
            appointmentActivityName={
              appointment.method !== 'ONSITE'
                ? getAaiNameWithMethod(
                    appointmentActivityFullName,
                    appointment.method
                  )
                : appointmentActivityFullName
            }
            appointment={appointment}
            timeZone={timeZone}
          />
          <TextContainer text={t('at')} />
          <SectionBodyBold>{pharmacyName}</SectionBodyBold>
          <PharmacyAddressContainer pharmacyAddress={pharmacyAddress} />

          {!isWalkin && (
            <div>
              <ContactInfoContainer patient={patient} />
              <TextContainer text={t('checkSpam')} />
            </div>
          )}
        </Card>
        {nextSteps ? (
          <Card>
            <CardTitle>{t('nextSteps')}</CardTitle>
            <Body>
              <NextStepsContainer>
                <MarkdownComponent
                  options={{
                    overrides: {
                      ol: { component: NextSteps },
                      li: { component: NextStepElement },
                    },
                  }}
                >
                  {nextSteps}
                </MarkdownComponent>
              </NextStepsContainer>
            </Body>
          </Card>
        ) : null}
      </CardLayout>
    </FormStepContentLayout>
  )
}
