import { useHistory } from 'react-router-dom'
import { AppointmentStatus } from '~/graphql/types/schemaNode.type'

export const useQueryParams = (appointment?: {
  appointmentStatus: AppointmentStatus
  appointmentType: {
    isWaitlisted?: boolean | null
  }
}) => {
  const history = useHistory()
  const search = new URLSearchParams(history.location.search)

  const isWaitlistLink =
    appointment?.appointmentStatus ===
      AppointmentStatus.WAITLIST_JOIN_LINK_SENT &&
    appointment?.appointmentType?.isWaitlisted

  return {
    isDocumentationLink: search.get('isDocumentationLink') === 'true',
    isBookingLink: !isWaitlistLink && search.get('isBookingLink') === 'true',
    rwgToken: search.get('rwg_token'),
  }
}
