import styled from 'styled-components'
import { Paper, Button } from '@material-ui/core'

import { hexToRGB } from '~/util/minorAilments/hexToRgb'

export const Card = styled(Paper)`
  display: flex;
  padding: 2rem;
  gap: 2rem;
  transition: 0.1s;
  &:hover {
    outline: 1px solid;
    outline-color: ${({ theme }) => theme.color.backgroundPrimary};
    background-color: ${({ theme }) =>
      hexToRGB(theme.color.backgroundPrimary, '0.04')};
  }
  border-radius: 5px;
  max-width: 100%;
  cursor: pointer;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  @media (min-width: 701px) {
    align-items: center;
  }
`

export const SubmitButtonContainer = styled.div`
  flex-basis: auto;
  @media (max-width: 1000px) {
    display: none;
  }
`

export const BodyContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media (max-width: 700px) {
    display: none;
  }
`

export const CardLayout = styled.div`
  width: 100%;
  @media (max-width: 700px) {
    display: grid;
    align-content: center;
    gap: 1rem;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  @media (min-width: 1001px) {
    display: none;
  }
  @media (max-width: 700px) {
    display: none;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-flow: row no-wrap;
  @media (max-width: 700px) {
    font-size: 90%;
    display: grid;
    gap: '1rem';
  }
`

export const TitleLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  @media (max-width: 700px) {
    padding-left: 0rem;
  }
`

export const InverseButton = styled(Button)`
  background-color: white;
  color: ${({ theme }) => theme.color.backgroundPrimary};
  border: ${({ theme }) => `${theme.color.backgroundPrimary} solid 2px`};

  &:hover {
    background-color: white;
  }
`

export const SelectButton = styled(InverseButton)`
  padding: 0.6rem 2rem;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
    font-size: 1rem
  width: fit-content;
  height: fit-content;

  @media (max-width: 700px) {
    padding: 0.6rem 1rem;
  }
`
