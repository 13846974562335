import { JsonFhirFormStep, JsonFhirR5FormStep } from '../types'
// Medical Information
import { fhirMAMedicalInformationIndiesABJson } from './fhirJsons/MedicalInformation/Indies/MA/fhirMAMedicalInformationIndiesABJson'
import { fhirMAMedicalInformationIndiesONJson } from './fhirJsons/MedicalInformation/Indies/MA/fhirMAMedicalInformationIndiesONJson'
import { fhirMAMedicalInformationSdmABJson } from './fhirJsons/MedicalInformation/SDM/MA/fhirMAMedicalInformationSdmABJson'
import { fhirMAMedicalInformationSdmBCJson } from './fhirJsons/MedicalInformation/SDM/MA/fhirMAMedicalInformationSdmBCJson'
import { fhirMAMedicalInformationSdmNLJson } from './fhirJsons/MedicalInformation/SDM/MA/fhirMAMedicalInformationSdmNLJson'
import { fhirMAMedicalInformationSdmONJson } from './fhirJsons/MedicalInformation/SDM/MA/fhirMAMedicalInformationSdmONJson'
import { fhirMAMedicalInformationSdmSKJson } from './fhirJsons/MedicalInformation/SDM/MA/fhirMAMedicalInformationSdmSKJson'
import { fhirMedicalInformationSdmSmokingJson } from './fhirJsons/MedicalInformation/SDM/fhirMedicalInformationSdmSmokingJson'
import { fhirMedicalInformationA1CJson } from './fhirJsons/MedicalInformation/SDM/fhirMedicalInformationA1CJson'
import { fhirMedicalInformationSdmStrepTestJson } from './fhirJsons/MedicalInformation/SDM/fhirMedicalInformationSdmStrepTestJson'
import { fhirMedicalInformationRexallTravelConsultationJson } from './fhirJsons/MedicalInformation/Rexall/fhirMedicalInformationRexallTravelConsultationJson'
import { fhirMedicalInformationSdmLipidTestJson } from './fhirJsons/MedicalInformation/SDM/fhirMedicalInformationSdmLipidTestJson'
import { fhirMedicalInformationSdmPaxlovidJson } from './fhirJsons/MedicalInformation/SDM/fhirMedicalInformationSdmPaxlovidJson'
import { fhirMedicalInformationSdmClinicJson } from './fhirJsons/MedicalInformation/SDM/fhirMedicalInformationSdmClinicJson'
import { fhirMedicalInformationSdmClinicJsonFr } from './fhirJsons/MedicalInformation/SDM/fhirMedicalInformationSdmClinicJsonFr'

// Contact Information
import { fhirMAContactInformationIndiesABJson } from './fhirJsons/ContactInformation/Indies/MA/fhirMAContactInformationIndiesABJson'
import { fhirMAContactInformationIndiesONJson } from './fhirJsons/ContactInformation/Indies/MA/fhirMAContactInformationIndiesONJson'
import { fhirMAContactInformationSdmABJson } from './fhirJsons/ContactInformation/SDM/MA/fhirMAContactInformationSdmABJson'
import { fhirMAContactInformationSdmBCJson } from './fhirJsons/ContactInformation/SDM/MA/fhirMAContactInformationSdmBCJson'
import { fhirMAContactInformationSdmONJson } from './fhirJsons/ContactInformation/SDM/MA/fhirMAContactInformationSdmONJson'
import { fhirMAContactInformationSdmNLJson } from './fhirJsons/ContactInformation/SDM/MA/fhirMAContactInformationSdmNLJson'
import { fhirMAContactInformationSdmSKJson } from './fhirJsons/ContactInformation/SDM/MA/fhirMAContactInformationSdmSKJson'
import { fhirContactInformationSdmSmokingJson } from './fhirJsons/ContactInformation/SDM/fhirContactInformationSdmSmokingJson'
import { fhirContactInformationSdmA1CJson } from './fhirJsons/ContactInformation/SDM/fhirContactInformationA1CJson'
import { fhirContactInformationSdmStrepTestJson } from './fhirJsons/ContactInformation/SDM/fhirContactInformationSdmStrepTestJson'
import { fhirContactInformationRexallTravelConsultationJson } from './fhirJsons/ContactInformation/Rexall/fhirContactInformationRexallTravelConsultationJson'
import { fhirContactInformationSdmLipidTestJson } from './fhirJsons/ContactInformation/SDM/fhirContactInformationSdmLipidTestJson'
import { fhirContactInformationSdmModernaInfantJson } from './fhirJsons/ContactInformation/SDM/fhirContactInformationSdmModernaInfantJson'
import { fhirContactInformationSdmPfizerInfantJson } from './fhirJsons/ContactInformation/SDM/fhirContactInformationSdmPfizerInfantJson'
import { fhirContactInformationSdmPaxlovidJson } from './fhirJsons/ContactInformation/SDM/fhirContactInformationSdmPaxlovidJson'
import { fhirContactInformationSdmClinicJson } from './fhirJsons/ContactInformation/SDM/fhirContactInformationSdmClinicJson'
import { fhirContactInformationSdmClinicJsonFr } from './fhirJsons/ContactInformation/SDM/fhirContactInformationSdmClinicJsonFr'
import { fhirContactInformationDefaultJson } from './fhirJsons/ContactInformation/fhirContactInformationDefaultJson'
import { fhirMedicalInformationDefaultJson } from './fhirJsons/MedicalInformation/fhirMedicalInformationDefaultJson'

export const getFhirFormStep = (
  name: string,
  language: string
): JsonFhirFormStep | JsonFhirR5FormStep => {
  switch (name) {
    // CONTACT INFORMATION:
    // Indies:
    case 'fhirMAContactInformationIndiesABJson':
      return fhirMAContactInformationIndiesABJson
    case 'fhirMAContactInformationIndiesONJson':
      return fhirMAContactInformationIndiesONJson

    // SDM:
    case 'fhirMAContactInformationSdmABJson':
      return fhirMAContactInformationSdmABJson
    case 'fhirMAContactInformationSdmBCJson':
      return fhirMAContactInformationSdmBCJson
    case 'fhirMAContactInformationSdmNLJson':
      return fhirMAContactInformationSdmNLJson
    case 'fhirMAContactInformationSdmONJson':
      return fhirMAContactInformationSdmONJson
    case 'fhirMAContactInformationSdmSKJson':
      return fhirMAContactInformationSdmSKJson
    case 'fhirContactInformationSdmSmoking':
      return fhirContactInformationSdmSmokingJson
    case 'fhirContactInformationSdmA1CJson':
      return fhirContactInformationSdmA1CJson
    case 'fhirContactInformationSdmStrepTestJson':
      return fhirContactInformationSdmStrepTestJson
    case 'fhirContactInformationSdmLipidTestJson':
      return fhirContactInformationSdmLipidTestJson
    case 'fhirContactInformationSdmModernaInfantJson':
      return fhirContactInformationSdmModernaInfantJson
    case 'fhirContactInformationSdmPfizerInfantJson':
      return fhirContactInformationSdmPfizerInfantJson
    case 'fhirContactInformationSdmPaxlovidJson':
      return fhirContactInformationSdmPaxlovidJson
    case 'fhirContactInformationSdmClinicJson':
      return language === 'en'
        ? fhirContactInformationSdmClinicJson
        : fhirContactInformationSdmClinicJsonFr

    // REXALL:
    case 'fhirContactInformationRexallTravelConsultationJson':
      return fhirContactInformationRexallTravelConsultationJson

    // MEDICAL INFORMATION:

    // Indies:
    case 'fhirMAMedicalInformationIndiesABJson':
      return fhirMAMedicalInformationIndiesABJson
    case 'fhirMAMedicalInformationIndiesONJson':
      return fhirMAMedicalInformationIndiesONJson

    // SDM:
    case 'fhirMAMedicalInformationSdmABJson':
      return fhirMAMedicalInformationSdmABJson
    case 'fhirMAMedicalInformationSdmBCJson':
      return fhirMAMedicalInformationSdmBCJson
    case 'fhirMAMedicalInformationSdmNLJson':
      return fhirMAMedicalInformationSdmNLJson
    case 'fhirMAMedicalInformationSdmONJson':
      return fhirMAMedicalInformationSdmONJson
    case 'fhirMAMedicalInformationSdmSKJson':
      return fhirMAMedicalInformationSdmSKJson
    case 'fhirMedicalInformationSdmSmoking':
      return fhirMedicalInformationSdmSmokingJson
    case 'fhirMedicalInfoA1CJson':
      return fhirMedicalInformationA1CJson
    case 'fhirMedicalInformationSdmStrepTestJson':
      return fhirMedicalInformationSdmStrepTestJson
    case 'fhirMedicalInformationSdmLipidTestJson':
      return fhirMedicalInformationSdmLipidTestJson
    case 'fhirMedicalInformationSdmPaxlovidJson':
      return fhirMedicalInformationSdmPaxlovidJson
    case 'fhirMedicalInformationSdmClinicJson':
      return language === 'en'
        ? fhirMedicalInformationSdmClinicJson
        : fhirMedicalInformationSdmClinicJsonFr

    // REXALL
    case 'fhirMedicalInformationRexallTravelConsultationJson':
      return fhirMedicalInformationRexallTravelConsultationJson

    // Generics / Defaults
    case 'fhirContactInformationDefaultJson':
      return fhirContactInformationDefaultJson

    case 'fhirMedicalInformationDefaultJson':
      return fhirMedicalInformationDefaultJson
    default:
      throw new Error(`Invalid FHIR form name: ${name}`)
  }
}
