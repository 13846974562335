import { RuleSerializable } from 'json-rules-engine'
import {
  ExtensionValidation,
  isExtensionValidationConditionGroup,
  isExtensionValidationMessage,
} from '~/lib/fhir/extensions/validation'
import { toCreateTopLevelRuleConditionFromValidationConditionGroup } from './toCreateTopLevelRuleConditionFromValidationConditionGroup'
import { getTranslatedFhirElementText } from '../../fhirQuestionnaire/fhirQuestionnaire.helpers'

export interface CreateRuleConfig {
  fact: string
  eventType: string
  priority: number
}
export type CreateRuleSignature = (config: CreateRuleConfig) => RuleSerializable

export const toCreateRuleFromValidation = (
  ext: ExtensionValidation,
  language = 'en-CA'
): CreateRuleSignature => {
  const messageExt = ext.extension.find(isExtensionValidationMessage)
  const conditionGroupExt = ext.extension.find(
    isExtensionValidationConditionGroup
  )

  if (messageExt === undefined || conditionGroupExt === undefined) {
    throw new Error('CreateRuleSignature: missing extension(s)')
  }

  const fn: CreateRuleSignature = (config) => ({
    name: `${config.fact}-${config.eventType}`,
    priority: config.priority,
    event: {
      type: config.eventType,
      params: {
        fieldName: config.fact,
        message:
          getTranslatedFhirElementText(messageExt._valueString, language) ||
          messageExt.valueString,
      },
    },
    conditions: toCreateTopLevelRuleConditionFromValidationConditionGroup(
      conditionGroupExt
    )(config.fact),
  })

  return fn
}
