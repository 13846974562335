import * as React from 'react'
import { Select } from '@material-ui/core'
import lodash from 'lodash'
import { useFormContext, useController } from 'react-hook-form'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { SectionTitle, SelectContainer } from './SelectionHeader.style'

export const NoOfPeople: React.FC<{
  titlePrefix: string
  maxNoOfPeople: number
}> = ({ titlePrefix, maxNoOfPeople }) => {
  const { control } = useFormContext()
  const { field } = useController({
    name: 'noOfPeople',
    control: control,
    defaultValue: 1,
  })

  const { t } = useMedMeTranslation('patient')

  const multiInputText = `1. ${t('groupBookingPrompt')}'`

  return (
    <SelectContainer>
      <SectionTitle>
        {titlePrefix}
        {multiInputText}
      </SectionTitle>
      <Select variant="outlined" {...field}>
        {lodash.range(maxNoOfPeople).map((i) => (
          <option value={i + 1} key={i}>
            {i + 1}
          </option>
        ))}
      </Select>
    </SelectContainer>
  )
}
