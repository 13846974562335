import { SnackbarProps } from '@material-ui/core'
import { createContext, useContext } from 'react'
const AUTO_HIDE_DURATION_IN_MILLISECONDS = 5000

type SnackbarContextType = {
  showSnackbar: (props: SnackbarProps) => void
}

export const defaultSnackbarProps: SnackbarProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  autoHideDuration: AUTO_HIDE_DURATION_IN_MILLISECONDS,
  message: '',
  open: false,
}

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
  {} as SnackbarContextType
)

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }

  return context
}
