import * as React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import useYupValidationResolverHook from '~/hooks/yupValidationResolverHook'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'

export const useFormTimeslotSelection = () => {
  const {
    state: {
      initial: { appointment },
      appointmentActivity,
      router: { searchParams },
    },
  } = useBookingContext()
  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        isWalkin: yup.string(),
        startDateTime: yup
          .string()
          .nullable()
          .when('isWalkin', {
            is: (isWalkin) => !isWalkin || isWalkin === 'false',
            then: yup
              .string()
              .required('Please select a date and time for your appointment'),
          }),
      }),
    []
  )

  const isWalkinDefault = React.useMemo(() => {
    if (!appointmentActivity.isWalkinFeatureEnabled) return 'false'
    if (!appointmentActivity.isWalkinAllowed) return 'false'
    if (appointment.isWalkin || searchParams.isWalkin) return 'true'

    return 'false'
  }, [])

  const resolver = useYupValidationResolverHook(validationSchema)

  const defaultValues = {
    isWalkin: isWalkinDefault,
    noOfPeople: appointment.noOfPeople,
    startDateTime: '',
    endDateTime: '',
    resourceId: '',
  }

  return useForm<typeof defaultValues>({
    resolver,
    defaultValues: defaultValues,
    shouldUnregister: false,
  })
}
