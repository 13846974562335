import React, { useState } from 'react'
import { StyledFab } from '../AiChatContent.styles'
import { AiChatContext } from '../context'
import { Consent } from '../components/Consent'
import Modal from '~/components/modal'
import { RootStateOrAny, useSelector } from 'react-redux'

export const FabSearch = () => {
  const { setDrawerOpen } = React.useContext(AiChatContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const closeModal = () => setShowDeleteModal(false)

  const consentGivenByPharmacists = JSON.parse(
    localStorage.getItem('aiSearchConsent') || '[]'
  )

  const { id: pharmacistId } = useSelector(
    (state: RootStateOrAny) => state.pharmacist
  )

  const handleConsentAcceptance = () => {
    localStorage.setItem(
      'aiSearchConsent',
      JSON.stringify([...consentGivenByPharmacists, pharmacistId])
    )
    setShowDeleteModal(false)
    setDrawerOpen(true)
  }

  return (
    <>
      <Modal isOpen={showDeleteModal} onClose={closeModal}>
        <Consent onCancel={closeModal} onAccept={handleConsentAcceptance} />
      </Modal>

      <StyledFab
        color="primary"
        aria-label="ai-chat"
        onClick={() => {
          const hasGivenConsent =
            consentGivenByPharmacists.includes(pharmacistId)
          if (hasGivenConsent) {
            setDrawerOpen(true)
          } else {
            setShowDeleteModal(true)
          }
        }}
      >
        <img src="/assets/medme-ai-fab-icon.svg" alt="medme-ai-fab-icon" />
      </StyledFab>
    </>
  )
}
