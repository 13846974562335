import React from 'react'
import { Controller } from 'react-hook-form'
import { Input } from '../../patientRegistration.styles'
import NumberFormat from 'react-number-format'

const PhoneInput = ({ className, defaultValue = '', ...rest }) => (
  <Controller
    as={
      <NumberFormat
        className={className}
        format="### ### ####"
        customInput={Input}
      />
    }
    {...rest}
  />
)

// explicit prop and default value if nothing supplied or, initialize in useForm, if removing defaultValue in controller input causes issues

export default PhoneInput
