export const TIME_ZONES = {
  'America/Toronto': 'EST',
  'America/Winnipeg': 'CST',
  'America/Moncton': 'AST',
  'America/Edmonton': 'MST',
  'America/Vancouver': 'PST',
  'America/Halifax': 'AST',
  'America/St_Johns': 'NST',
  'America/Regina': 'CST',
  'America/Whitehorse': 'MST',
}
