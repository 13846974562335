import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { useGetInitialAppointmentActivityQuery } from '~/graphql/types/schemaNode.type'
import { createAppointmentActivityInstance } from '~/lib/appointmentActivity'
import { useAppointmentContext } from '../../AppointmentContext'
import { useRouterContext } from '../../RouterContext'
import { BookingScope } from '~/pages/AppointmentIntake/types'
import { useLanguageForPatient } from '~/hooks/useLanguageForPatient'

export const useGetInitialAppointmentActivity = (scope: BookingScope) => {
  const { activity, appointmentActivityId } = useRouterContext()
  const { initialAppointment } = useAppointmentContext()

  const { languageForFHIR } = useLanguageForPatient()

  const {
    data,
    loading: loadingParamAAI,
    error: gqlError,
  } = useGetInitialAppointmentActivityQuery({
    variables: {
      appointmentActivityId:
        appointmentActivityId ||
        initialAppointment.appointmentType.appointmentActivityId ||
        undefined,
      urlParam: activity || undefined,
    },
    skip:
      !activity &&
      !initialAppointment.appointmentType.appointmentActivityId &&
      !appointmentActivityId,
    context: {
      clientName: EServer.NODE,
      regionCode: scope.regionCode,
      headers: {
        'accept-language': languageForFHIR,
      },
    },
    fetchPolicy: 'network-only',
  })

  const initialAppointmentActivity = createAppointmentActivityInstance(
    data?.appointmentActivityInstance
  )
  const loading = loadingParamAAI
  const error = gqlError?.message ?? ''

  return {
    initialAppointmentActivity,
    loading,
    error,
  }
}
