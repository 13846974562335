import { useCallback } from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import {
  usePatientUpdatePatientAndCreateAppointmentV1Mutation,
  usePublicCreatePatientAndAppointmentV1Mutation,
} from '~/graphql/java/mutations'
import { useGetPchSessionId } from '~/hooks/useGetPchSessionId'
import { useRouterContext } from '~/pages/AppointmentIntake/contexts'

export const useSubmitWaitlistIntake = () => {
  const { patientIntakeRecords, numberOfPersons, pharmacy, method } =
    useBookingContext()
  const {
    searchParams: { key1, key2, key3 },
  } = useRouterContext()
  const waitlistIntake = patientIntakeRecords?.[0]

  const pchSessionId = useGetPchSessionId()

  if (!waitlistIntake) {
    throw new Error('Waitlist intake is not set')
  }

  if (pharmacy?.appointmentTypes?.length !== 1) {
    throw new Error('Waitlist is supported for single service only')
  }

  const appointmentType = pharmacy.appointmentTypes[0]
  const patient = {
    firstName: waitlistIntake.patient.firstName,
    lastName: waitlistIntake.patient.lastName,
    email: waitlistIntake.patient.email,
    birthDate: waitlistIntake.patient.birthDate,
    patientConsent: waitlistIntake.patient.patientConsent,
    phone: {
      cell: waitlistIntake.patient.phone.cell,
    },
    patientIntakeAnswers: [],
  }

  const [
    publicCreatePatientAndAppointmentMutation,
    { loading: createLoading, error: createError },
  ] = usePublicCreatePatientAndAppointmentV1Mutation({
    context: {
      headers: {
        'x-pharmacyid': pharmacy.id,
      },
    },
    variables: {
      publicCreatePatientAndIntakeAnswerInput: [
        {
          patient,
        },
      ],
      publicCreateAppointmentInput: {
        method,
        appointmentTypeId: Number(appointmentType.id),
        noOfPeople: numberOfPersons,
      },
      isWalkIn: false,
      isWaitlisted: true,
      pchSessionId,
    },
  })

  const [
    patientUpdatePatientAndCreateAppointmentMutation,
    { loading: updateLoading, error: updateError },
  ] = usePatientUpdatePatientAndCreateAppointmentV1Mutation({
    context: {
      headers: {
        'x-pharmacyid': pharmacy.id,
      },
    },
    variables: {
      key1,
      key2,
      key3,
      patientCreatePatientAndIntakeAnswerInput: [
        {
          patient,
          intakeAnswer: {},
        },
      ],
      patientCreateAppointmentInput: {
        noOfPeople: numberOfPersons,
      },
      isNextAppointment: false,
      isWalkin: false,
      isWaitlisted: true,
    },
  })

  const submit = useCallback(async () => {
    if (key1 && key2 && key3) {
      const response = await patientUpdatePatientAndCreateAppointmentMutation()
      return response?.data?.patientUpdatePatientAndCreateAppointmentV1
    }

    const response = await publicCreatePatientAndAppointmentMutation()
    return response?.data?.publicCreatePatientAndAppointmentV1
  }, [
    patientUpdatePatientAndCreateAppointmentMutation,
    publicCreatePatientAndAppointmentMutation,
    key1,
    key2,
    key3,
  ])

  return {
    submit,
    loading: createLoading || updateLoading,
    error: createError || updateError,
  }
}
