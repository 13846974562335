import * as React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

import { ThemeContext } from 'styled-components'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import mapboxgl, { LngLatLike } from 'mapbox-gl'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { MapBoxPoint, MapBoxMarker, MapBoxResponseFeature } from './types'
import { Pharmacy } from '~/lib/pharmacy'

import { MapBoxProps } from '../../components/Mapbox/MapBox'
import { createCoordinate } from '../useCoordinate'

mapboxgl.accessToken = process.env.MAPBOX_GEOLOCATOR_ACCESS_TOKEN || ''

export const createMapBoxPoint = (
  longitude: number,
  latitude: number
): MapBoxPoint => ({
  type: 'Point' as const,
  coordinates: [longitude, latitude],
})

const formatLocationData = (locationData: Pharmacy[]) => (): MapBoxMarker[] =>
  locationData.reduce((acc, pharmacy) => {
    const { pharmacyAddress } = pharmacy
    if (pharmacyAddress.longitude === null || pharmacyAddress.latitude === null)
      return acc

    return [
      ...acc,
      {
        id: pharmacy.id,
        type: 'Feature' as const,
        geometry: createMapBoxPoint(
          pharmacyAddress.longitude,
          pharmacyAddress.latitude
        ),
        properties: {
          pharmacy,
        },
      },
    ]
  }, [])

export const useMapbox = ({
  pharmacyList,
  pharmacy,
  setPharmacy,
  coordinate,
  setCoordinate,
}: MapBoxProps) => {
  const { color } = React.useContext(ThemeContext)
  const mapRef = React.useRef(null)
  const [map, setMap] = React.useState<mapboxgl.Map>()
  const { t } = useMedMeTranslation('patient')

  const markerList = React.useMemo(formatLocationData(pharmacyList), [
    pharmacyList,
  ])

  const flyTo = React.useCallback(
    (coordinates: LngLatLike) =>
      map?.flyTo({
        center: coordinates,
        zoom: 13,
        animate: false,
      }),
    [map]
  )

  // Create and save map instance
  React.useEffect(() => {
    if (!map && mapRef.current) {
      const newMap = new mapboxgl.Map({
        container: mapRef.current || '',
        style: 'mapbox://styles/medmehealth/cke0jmkxb1ekd19o6mk100qpp',
        center: [-75.695, 45.424721],
        zoom: 2,
        minZoom: 2,
        maxBounds: [
          [-150, 42],
          [-40, 83],
        ],
      })

      newMap.on('load', () => {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken, // Set the access token
          mapboxgl: mapboxgl, // Set the mapbox-gl instance
          marker: true, // Use the geocoder's default marker style
          countries: 'CA', // Limit search results to Canada
          flyTo: false,
          placeholder: t('storeSelection.mapSearchPlaceholder'),
        })

        newMap.addControl(geocoder, 'top-left')

        geocoder.on('result', (ev: { result: MapBoxResponseFeature }) => {
          const searchResult = ev.result.geometry.coordinates
          setCoordinate(createCoordinate(searchResult[0], searchResult[1]))
        })

        setMap(newMap)
      })

      newMap.on('idle', () => {
        newMap.resize()
      })
    }
  }, [mapRef, map])

  // Update map with location data
  React.useEffect(() => {
    if (map) {
      const source = map.getSource('locations-data')
      if (source) {
        map.removeSource('locations-data')
      }
      map.addSource('locations-data', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: markerList,
        },
      })

      markerList.map((marker) => {
        const [lon, lat] = marker.geometry.coordinates
        return new mapboxgl.Marker({
          offset: [0, -23],
          color: color.backgroundPrimary,
        })
          .setLngLat({ lat, lon })
          .addTo(map)
          .getElement()
          .addEventListener('click', () => {
            setPharmacy(marker.properties.pharmacy)
          })
      })
    }
  }, [markerList, map])

  React.useEffect(() => {
    if (coordinate && map) {
      flyTo({ lat: coordinate.latitude, lng: coordinate.longitude })
    }
  }, [coordinate, map])

  React.useEffect(() => {
    if (pharmacy.id) {
      const [pharmacyMarker] = markerList.filter(
        ({
          properties: {
            pharmacy: { id },
          },
        }) => id === pharmacy.id
      )
      if (map && pharmacyMarker) {
        flyTo(pharmacyMarker.geometry.coordinates)
      }
    }
  }, [pharmacy, map])

  return {
    mapRef,
  }
}
