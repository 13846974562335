import styled from 'styled-components'
import {
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'
import { defaultTheme } from '~/sharedStyles/colorPalette'

export const Radio = styled(MuiRadio)`
  &.Mui-checked {
    color: ${({ theme }) =>
      theme?.color?.backgroundPrimary || defaultTheme.color.backgroundPrimary};
  }
`
export const RadioGroup = styled(MuiRadioGroup)`
  @media only screen and (orientation: landscape) {
    margin-top: 0.5rem;
  }
`
