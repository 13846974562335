import { Appointment } from '~/graphql/types/schemaNode.type'
import { dedupeRecords } from '~/util/arrays'

export const getDurationKey = (
  noOfPersons: number
): keyof Appointment['appointmentType'] => {
  switch (noOfPersons) {
    case 1:
      return 'durationOne'
    case 2:
      return 'durationTwo'
    case 3:
      return 'durationThree'
    case 4:
      return 'durationFour'
    case 5:
      return 'durationFive'
    case 6:
      return 'durationSix'
    default:
      return 'durationOne'
  }
}

export const computeDuration = (
  noOfPersons: number,
  appointments: Appointment[]
): number => {
  if (!noOfPersons || !appointments.length) return 0

  const durationKey = getDurationKey(noOfPersons)

  const uniqueAppointmentTypes = dedupeRecords(
    appointments,
    (record) => record?.appointmentType?.id || ''
  )

  const duration = uniqueAppointmentTypes?.reduce((acc, curr) => {
    return (
      acc +
      (noOfPersons === 1 && curr.duration
        ? curr.duration
        : curr?.appointmentType?.[durationKey] || 0)
    )
  }, 0)

  return duration
}
