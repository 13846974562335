import { ELanguageCode } from '~/graphql/types/schema.type'
import {
  LanguageCodeType,
  PatientOutput,
} from '~/graphql/types/schemaNode.type'
import { Patient } from '~/lib/patient'
import { DeepPartial } from '~/pages/AppointmentIntake/lib'

export const createPatientFromPatientOutput = (
  patientOutput: DeepPartial<PatientOutput>
): Patient => {
  return {
    id: patientOutput.id ?? '',
    firstName: patientOutput.firstName ?? '',
    lastName: patientOutput.lastName ?? '',
    sex: patientOutput.sex ?? '',
    gender: patientOutput.gender ?? '',
    birthDate: patientOutput.birthDate ?? '',
    email: patientOutput.email ?? '',
    languageCode:
      patientOutput.langCode === LanguageCodeType.FR
        ? ELanguageCode.FR
        : ELanguageCode.EN,
    address: {
      unit: patientOutput.address?.unit ?? '',
      streetNumber: patientOutput.address?.streetNumber ?? '',
      streetName: patientOutput.address?.streetName ?? '',
      city: patientOutput.address?.city ?? '',
      province: patientOutput.address?.province ?? '',
      postalCode: patientOutput.address?.postalCode ?? '',
      country: patientOutput.address?.country ?? '',
      poBox: patientOutput.address?.poBox ?? '',
    },
    phone: {
      home: patientOutput.phone?.home ?? '',
      cell: patientOutput.phone?.cell ?? '',
    },
    healthCard: {
      number: patientOutput.healthCard?.number ?? '',
      versionCode: patientOutput.healthCard?.versionCode ?? '',
      expiry: patientOutput.healthCard?.expiry ?? '',
      province: patientOutput.healthCard?.province ?? '',
    },
    primaryCareProvider: {
      firstName: patientOutput.primaryCareProvider?.firstName ?? '',
      lastName: patientOutput.primaryCareProvider?.lastName ?? '',
      phone: patientOutput.primaryCareProvider?.phone ?? '',
      email: patientOutput.primaryCareProvider?.email ?? '',
    },
    emergencyContact: {
      firstName: patientOutput.emergencyContact?.firstName ?? '',
      lastName: patientOutput.emergencyContact?.lastName ?? '',
      relationship: patientOutput.emergencyContact?.relationship ?? '',
      phone: patientOutput.emergencyContact?.phone ?? '',
    },
    patientConsent: {
      caslEmailConsent: patientOutput.consent?.caslEmailConsent ?? false,
      caslSmsConsent: patientOutput.consent?.caslSmsConsent ?? false,
    },
  }
}
