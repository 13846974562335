import { QuestionnaireItemAnswerOption, ValueSet } from 'fhir/r5'
import { countryList } from '~/util/geographyList'
import {
  fhirValueSetUrlMedme,
  fhirSystemUrlMedme,
} from '../../extensions/constants'

export const countryValueSetUrl = fhirValueSetUrlMedme + '/country'

export const countryValueSet: ValueSet = {
  status: 'active',
  resourceType: 'ValueSet',
  url: countryValueSetUrl,
  compose: {
    id: '',
    include: [
      {
        system: fhirSystemUrlMedme,
        version: '1.0.0',
        concept: Object.keys(countryList).map(
          (code: keyof typeof countryList) => ({
            display: countryList[code],
            code: code,
          })
        ),
      },
    ],
  },
}

export const countryValueSetAnswerOptions = (initialSelected?: string) => {
  return Object.keys(countryList).map<QuestionnaireItemAnswerOption>(
    (key: keyof typeof countryList) => {
      const value = countryList[key]
      const coding = {
        system: countryValueSetUrl,
        version: '1.0.0',
        code: String(key),
        display: value,
      }

      if (initialSelected && initialSelected === key) {
        return {
          valueCoding: coding,
          initialSelected: true,
        }
      }
      return {
        valueCoding: coding,
      }
    }
  )
}
