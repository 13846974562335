import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export type CompletionVerbiage = {
  completionTitle: string
  appointmentDetailsTitle: string
}

export const useCompletionVerbiage = () => {
  const {
    state: {
      appointment: { isWalkin },
    },
  } = useBookingContext()
  const { t } = useMedMeTranslation('patient')

  const walkinVerbiage: CompletionVerbiage = {
    completionTitle: t('appointmentBookingCompletion.walkin.title'),
    appointmentDetailsTitle: t('appointmentBookingCompletion.walkin.details'),
  }
  const bookingVerbiage: CompletionVerbiage = {
    completionTitle: t('appointmentBookingCompletion.title'),
    appointmentDetailsTitle: t(
      'appointmentBookingCompletion.successfullyBooked'
    ),
  }

  return isWalkin ? walkinVerbiage : bookingVerbiage
}
