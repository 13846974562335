import { Questionnaire } from 'fhir/r5'

export type FhirQuestionnaireMiddleware = (
  questionnaire: Questionnaire,
  ...params: unknown[]
) => Questionnaire

/**
 * Applies a list of middleware functions to a FHIR Questionnaire
 * object in order that the middleware functions are defined in the
 * middleware parameter array
 */
export const applyQuestionnaireMiddleware = (
  questionnaire: Questionnaire,
  middleware: FhirQuestionnaireMiddleware[]
): Questionnaire => {
  const func = middleware.shift()
  if (func === undefined) return questionnaire
  return applyQuestionnaireMiddleware(func(questionnaire), middleware)
}
