import { Engine } from 'json-rules-engine'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'

export const addContext =
  (state: ReturnType<typeof useBookingContext>['state']) =>
  (rulesEngine: Engine) => {
    Object.keys(state).forEach((key: keyof typeof state) =>
      rulesEngine.addFact(key, state[key])
    )
    return rulesEngine
  }
