import {
  FhirExtGridProps,
  FhirExtGrid,
  createFhirExtGrid,
  fhirExtGridUrl,
} from './fhirExtGrid'

export const presetFhirGridExt = (props: FhirExtGridProps): FhirExtGrid => {
  return createFhirExtGrid({
    url: fhirExtGridUrl,
    extension: Object.entries(props).map(([url, value]) => ({
      url: url,
      valueInteger: value !== 'auto' ? Number(value) : undefined,
      valueString: value === 'auto' ? value : undefined,
    })),
  })
}
