import styled from 'styled-components'
import { offWhite } from '~/sharedStyles/colorPalette'
import {
  InverseButton,
  Body2,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'

interface Props {
  today?: boolean
  numberOfCards?: number
  disabled?: boolean
  focus?: boolean
}

export const NavigationContainer = styled.div<Props>`
  flex: 1;
  display: flex;
  margin: 1rem 0;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  transition: opacity ease-in-out 0.2s;
`

export const NavigationButton = InverseButton

export const NavigationLeftButtonText = styled.span`
  padding-right: 0.5rem;
`

export const NavigationRightButtonText = styled.span`
  padding-left: 0.5rem;
`

export const NavigationContext = styled(Body2)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${offWhite};
  border-radius: 2px;
  margin: 0 0.5rem;
  font-weight: 600;
`
