import React from 'react'
import TextField from '@material-ui/core/TextField'

const SanitizedTextInput = ({
  className,
  onChange,
  sanitizedRegex,
  ...rest
}) => {
  const emailRegex =
    /(\\|\/|\?|:|;|!|"|\{|\}|\[|\]|\*|%|\$|#|\^|=|\(|\)|\+|\t|\r)/i
  const nonEmailRegex =
    /(@|\\|\/|\?|:|;|!|"|\{|\}|\[|\]|\*|%|\$|#|\^|=|\(|\)|\+|\t|\r)/i
  const timeRegex =
    /(@|\\|\/|\?|;|!|"|\{|\}|\[|\]|\*|%|\$|#|\^|=|\(|\)|\+|-|\t|\r)/i
  const getRegex = (name) => {
    if (sanitizedRegex) return sanitizedRegex
    if (name.includes('email')) return emailRegex
    if (name.includes('flightTime')) return timeRegex
    return nonEmailRegex
  }

  const sanitizedOnChange = (e) => {
    e.target.value = e.target.value.replace(getRegex(e.target.name), '')
    if (onChange) onChange(e)
  }

  return (
    <TextField className={className} onChange={sanitizedOnChange} {...rest} />
  )
}

export default SanitizedTextInput
