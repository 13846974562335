import * as React from 'react'
import { PharmacyAddress } from '~/lib/pharmacy'

import { SectionBodyBold, SectionBodyContainer } from '../../Completion.style'
import { getFormattedBuildingAndCityAddressFromPharmacyAddress } from '~/util/addressHelpers'

type PharmacyAddressContainerProps = {
  pharmacyAddress?: Omit<PharmacyAddress, 'country' | 'latitude' | 'longitude'>
}

export const PharmacyAddressContainer: React.FC<
  PharmacyAddressContainerProps
> = ({ pharmacyAddress }) => {
  const { buildingAddress, cityAddress } =
    getFormattedBuildingAndCityAddressFromPharmacyAddress(
      pharmacyAddress as PharmacyAddress
    )

  return (
    <SectionBodyContainer>
      <SectionBodyBold>{buildingAddress}</SectionBodyBold>
      <SectionBodyBold>{cityAddress}</SectionBodyBold>
    </SectionBodyContainer>
  )
}
