import * as React from 'react'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { NavigationFooter } from '../../../../components/NavigationFooter'
import { NoLocationsContainer, NoLocationsText } from './NoLocations.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const NoLocations = () => {
  const { prevStep } = useFormManager()
  const { t } = useMedMeTranslation('patient')

  const label = t('activityLanding.noServicesAvailable')

  return (
    <NoLocationsContainer>
      <NoLocationsText style={{ marginBottom: '1.5rem' }}>
        {label}
      </NoLocationsText>
      <NavigationFooter isNextDisabled={true} onBack={prevStep} />
    </NoLocationsContainer>
  )
}
