import React from 'react'
import {
  CardLayout,
  Heading1,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { NavigationFooter } from '~/pages/AppointmentIntake/components'
import { FormStepProps } from './types'
import { DetailsWidgetContainer } from '~/pages/AppointmentIntake/components/DetailsWidget/DetailsWidgetContainer'
import { useDetailsWidgetProgress } from '../hooks'

export type FormLayoutProps = FormStepProps & {
  isNextDisabled?: boolean
  isDisabled?: boolean
  nextButtonType?: 'submit' | 'button'
  nextButtonLabel?: string
} & Omit<JSX.IntrinsicElements['form'], 'ref'>

export const FormLayout: React.FC<Omit<FormLayoutProps, 'onComplete'>> = ({
  path,
  displayName,
  children,
  onBack,
  isNextDisabled = false,
  isDisabled = false,
  nextButtonLabel,
  ...props
}) => {
  const handleBack = React.useCallback(() => {
    onBack({ path })
  }, [onBack, path])

  const { progressInput } = useDetailsWidgetProgress(path)

  return (
    <CardLayout {...props}>
      <Heading1>{displayName}</Heading1>
      {children}
      <NavigationFooter
        nextButtonLabel={nextButtonLabel}
        onBack={handleBack}
        isDisabled={isDisabled}
        isNextDisabled={isNextDisabled}
      />
      <DetailsWidgetContainer progressInput={progressInput} />
    </CardLayout>
  )
}
