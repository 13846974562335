import styled from 'styled-components'

interface IconProps {
  width: string
  height: string
}

export const StyledSvgIcon = styled.img<IconProps>`
  svg {
    width: ${(props) => {
      return props.width
    }};
    height: ${(props) => props.height};
  }
`
