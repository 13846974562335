import { Coding } from 'fhir/r5'
import {
  isFhirCodingEntityMap,
  isFhirCodingEntityPatient,
  isFhirCodingEntityAppointment,
} from '../systems'

export interface EntityPathObject {
  entity: 'patient' | 'appointment'
  path: string
}

const INVALID_ENTITY_MAP_CODES = [
  'pharmacy',
  'appointment',
  'patient',
  'state',
  'appointmentType',
  'router',
  '',
] as const

const VALID_APPOINTMENT_ENTITY_MAP_CODES = [
  'reasonForVisit',
  'injectionName',
  'topic',
  'topicCustom',
] as const

// Can't use includes on a `Array<string> as const` since it has any string passed to
// includes is expected to be within the set of string literals, so this is a work around
const readonlyArrayIncludes = <T>(
  str: T,
  readonlyArray: ReadonlyArray<T>
): boolean => {
  return readonlyArray.includes(str)
}
export const getFieldEntityFromCode = (
  coding: Coding
): EntityPathObject | undefined => {
  if (!isFhirCodingEntityMap(coding)) {
    return undefined
  }

  if (readonlyArrayIncludes(coding.code, INVALID_ENTITY_MAP_CODES)) {
    throw new Error('getFieldCodeFromExtension: invalid entityMapCode')
  }

  if (isFhirCodingEntityPatient(coding)) {
    return {
      entity: 'patient',
      path: coding.code,
    }
  }

  if (isFhirCodingEntityAppointment(coding)) {
    if (
      readonlyArrayIncludes(coding.code, VALID_APPOINTMENT_ENTITY_MAP_CODES)
    ) {
      return {
        entity: 'appointment',
        path: coding.code,
      }
    }
    throw new Error('getFieldCodeFromExtension: invalid entityMapCode')
  }

  return undefined
}
