import { isProd } from '~/util/envMethods'

/**
 * Return the value of a URL param as a string (or null if not found) from the given
 * URLSearchParams.
 */
export const getParam = (
  key: string,
  urlSearch: URLSearchParams
): string | null => {
  return urlSearch.has(key) ? urlSearch.get(key) : null
}

/**
 * Return a mapped boolean value for the given URL Search Param key that
 * corresponds to strings "true" or "false".
 *
 * Returns `false` in prod if the key is not boolean string otherwise in other deployment
 * environments an error will be thrown.
 */
export const getBooleanParam = (
  key: string,
  urlSearchParams: URLSearchParams
): boolean => {
  const param = getParam(key, urlSearchParams)

  if (param === null || param === undefined) {
    return false
  }

  if (param !== 'true' && param !== 'false') {
    if (isProd) {
      return false // don't crash prod
    }
  }

  if (param === 'true' || param === 'false') {
    return param === 'true' // true for 'true', false for 'false'
  }

  throw new Error(
    `param key '${key}' is expected to be a string representation of a boolean value but is: ${param}`
  )
}
