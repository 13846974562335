import { useCallback, useContext } from 'react'
import { WebViewContext } from '~/context/WebViewContext'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'

export function useSendWebviewEvent() {
  const { pharmacy, services } = useBookingContext()
  const { sendEvent } = useContext(WebViewContext)
  const type = services[0].details?.title || ''
  const province = pharmacy?.pharmacyAddress?.province || ''

  const sendWebviewEvent = useCallback(
    (appointmentId = '') => {
      sendEvent('success', {
        appointmentId,
        province,
        type,
        event: 'success',
        eventType: 'booking',
      })
    },
    [sendEvent, province, type]
  )

  return {
    sendWebviewEvent,
  }
}
