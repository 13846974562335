import { createPresetFormStep } from '..'
import { ActivityGroupLandingForm } from '~/pages/AppointmentIntake/forms'

export const activityGroupLandingFormStep: typeof createPresetFormStep = (
  json
) => {
  return {
    Component: ActivityGroupLandingForm,
    metadata: json.metadata,
    enableWhen: (state) => {
      return Boolean(
        state.appointmentActivityGroup && state.router.activityGroup
      )
    },
    isAutoSkippable: async () => false,
  }
}
