import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { Appointment } from '~/lib/appointment'
import { Patient } from '~/lib/patient'
import { FhirFormContextProps, FhirFormProvider } from './FhirFormContext'
import { FhirQuestionnaireComponent } from './FhirQuestionnaireComponent'
import { useDefaultValues, useRulesEngine } from './hooks'
import { isEnableWhenEvent } from './hooks/useRulesEngine/events'

export type FhirFormStateContext = {
  patient: Patient
  appointment: Appointment
  fields: Record<string, string | number | boolean | null>
}

export const reducer = (
  state: FhirFormContextProps,
  action:
    | {
        type: 'updateDisabledField'
        value: Record<string, true | undefined>
      }
    | {
        type: 'setDisabledFields'
        value: Record<string, true | undefined>
      }
) => {
  switch (action.type) {
    case 'updateDisabledField':
      // TODO: Fix eslint error
      // eslint-disable-next-line no-case-declarations
      const [key, value] = Object.entries(action.value)[0]
      if (state.disabledFields[key] === value) return state
      return {
        ...state,
        disabledFields: { ...state.disabledFields, ...action.value },
      }
    case 'setDisabledFields':
      return {
        ...state,
        disabledFields: action.value,
      }
    default:
      throw new Error()
  }
}

export const FhirForm: React.FC<{
  questionnaire: FhirQuestionnaire
  onSubmit: (
    data: Record<string, any>,
    fieldEntityMap: Record<
      string,
      {
        entity: string
        path: string
      }[]
    >,
    e?: React.BaseSyntheticEvent<object, any, any>
  ) => Promise<void>
  Form: React.FC<JSX.IntrinsicElements['form']>
  onReady?: () => void
  state: FhirFormStateContext
}> = React.memo(
  ({ questionnaire, onSubmit, onReady = () => undefined, Form, state }) => {
    const [context, dispatch] = React.useReducer(reducer, {
      disabledFields: {},
    })
    const [loading, setLoading] = React.useState(true)

    const { rulesEngine, resolver } = useRulesEngine(questionnaire, (value) =>
      dispatch({ type: 'updateDisabledField', value })
    )

    const { defaultValues, fallbackValues, fieldEntityMap } = useDefaultValues(
      questionnaire,
      state
    )

    const methods = useForm({
      mode: 'onBlur', // Want to have rulesEngine run against events onBlur
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: resolver,
      shouldUnregister: false,
    })

    const onSubmitHandler = methods.handleSubmit((data, e) => {
      const disabledFields = context.disabledFields

      const getNestedDisabledFields = (
        items: FhirQuestionnaireItem[],
        value?: true | undefined
      ): Record<string, true> => {
        return (
          items.reduce((acc, item) => {
            if (item.type === 'group') {
              if (item.item) {
                return {
                  ...acc,
                  ...getNestedDisabledFields(
                    item.item,
                    value ?? disabledFields[item.linkId]
                  ),
                }
              } else {
                return acc
              }
            }
            if (value) {
              return { ...acc, [item.linkId]: value }
            }

            return { ...acc, [item.linkId]: disabledFields[item.linkId] }
          }, {}) ?? {}
        )
      }

      const nestedDisabledFields = getNestedDisabledFields(
        questionnaire.item ?? []
      )
      const filterdData = Object.entries(data).reduce((acc, [key, value]) => {
        if (value === undefined) return acc
        if (nestedDisabledFields[key])
          return {
            ...acc,
            [key]: fallbackValues[key],
          }
        return {
          ...acc,
          [key]:
            typeof value === 'string'
              ? value.replace(/[ \t]+/g, ' ').trim()
              : value,
        }
      }, {})
      onSubmit(filterdData, fieldEntityMap, e)
    })

    React.useEffect(() => {
      rulesEngine.run(defaultValues).then((result) => {
        const enabled = result.events.reduce((acc, event) => {
          if (isEnableWhenEvent(event)) {
            return { ...acc, [event.params.fieldName]: undefined }
          }
          return acc
        }, {})

        const disabled = result.failureEvents.reduce((acc, event) => {
          if (isEnableWhenEvent(event)) {
            return { ...acc, [event.params.fieldName]: true }
          }
          return acc
        }, {})

        dispatch({
          type: 'setDisabledFields',
          value: { ...enabled, ...disabled },
        })

        setLoading(false)
        onReady()
      })
    }, [])

    if (loading) return <div>Loading...</div>
    return (
      <Form onSubmit={onSubmitHandler} noValidate>
        <FormProvider {...methods}>
          <FhirFormProvider {...context}>
            <FhirQuestionnaireComponent questionnaire={questionnaire} />
          </FhirFormProvider>
        </FormProvider>
      </Form>
    )
  }
)
