import { Grid, Paper } from '@material-ui/core'
import * as React from 'react'
import { FhirContainerComponent } from '../FhirContainerComponent'
import { FhirQuestionnaireItemComponent } from '../FhirQuestionnaireItemComponent'
import { FhirStemComponent } from '../FhirStemComponent'

export const FhirCardComponent: typeof FhirQuestionnaireItemComponent = (
  props
) => {
  const { questionnaireItem } = props

  if (questionnaireItem.type !== 'group') {
    console.error('FhirCardComponent: Unsupported type', questionnaireItem.type)
    return null
  }

  return (
    <FhirContainerComponent {...props}>
      <Paper style={{ padding: '1rem' }}>
        <Grid container spacing={2}>
          {questionnaireItem.item?.map((item, i) => (
            <FhirStemComponent {...props} questionnaireItem={item} key={i} />
          )) ?? null}
        </Grid>
      </Paper>
    </FhirContainerComponent>
  )
}
