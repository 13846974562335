import { REVIEW_AND_CONFIRM_PAGE_PATH } from '../../constants'
import { useFormSteps } from '../hooks'
import { PatientProgressNode } from '~/pages/AppointmentIntake/components/ProgressWidget/ProgressWidget'

export function useDetailsWidgetProgress(path: string): {
  progressInput: PatientProgressNode[]
} {
  const steps = useFormSteps()
  const currentStepIndex = steps.findIndex((step) => step.path === path)
  const progressSteps = steps.filter(
    (step) => step.path !== REVIEW_AND_CONFIRM_PAGE_PATH
  )

  const progressInput: PatientProgressNode[] = [
    {
      open: true,
      steps: progressSteps.map((step, index) => ({
        label: step.displayName,
        done: index < currentStepIndex,
        current: step.path === path,
      })),
    },
  ]

  const reviewStep = steps.find(
    (step) => step.path === REVIEW_AND_CONFIRM_PAGE_PATH
  )
  if (reviewStep) {
    progressInput.push({
      label: reviewStep.displayName,
      open: false,
      done: false,
    })
  }

  return {
    progressInput,
  }
}
