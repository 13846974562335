import * as React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const useBookingErrorTimeslotSelectionVerbiage = () => {
  const { t } = useMedMeTranslation('patient')

  const verbiage = React.useMemo(() => {
    return {
      title: t('bookingErrorTimeslotSelection.title'),
      nextButtonLabel: t('bookingErrorTimeslotSelection.nextButtonLabel'),
      initializing: t('bookingErrorTimeslotSelection.initializing'),
      reserving: t('bookingErrorTimeslotSelection.reserving'),
    }
  }, [])

  return verbiage
}
