import * as React from 'react'
import { Grid } from '@material-ui/core'
import { FhirQuestionnaireItemComponent } from '../FhirQuestionnaireItemComponent'
import { FhirStemComponent } from '../FhirStemComponent'
import { FhirContainerComponent } from '../FhirContainerComponent'

export const FhirGroupComponent: typeof FhirQuestionnaireItemComponent = (
  props
) => {
  const { questionnaireItem } = props
  if (questionnaireItem.type !== 'group') {
    console.error(
      'FhirGroupComponent: Unsupported type',
      questionnaireItem.type
    )
    return null
  }

  return (
    <FhirContainerComponent {...props}>
      <Grid container spacing={2}>
        {questionnaireItem.item?.map((item, i) => (
          <FhirStemComponent {...props} questionnaireItem={item} key={i} />
        )) ?? null}
      </Grid>
    </FhirContainerComponent>
  )
}
