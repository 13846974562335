import * as React from 'react'
import { transformToStandardPhoneNumber } from '~/util/formatContact'

import {
  Layout,
  SectionTitle,
  SectionBody,
} from './CancelledAppointmentLanding.style'
import { useBookingContext } from '../../contexts'
import { FormStepContentLayout } from '../../AppointmentIntake.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const CancelledAppointmentLanding: React.FC = () => {
  const { state } = useBookingContext()
  const patient = state.initial.patient
  const pharmacy = state.initial.pharmacy
  const { t } = useMedMeTranslation('patient')

  return (
    <FormStepContentLayout>
      <Layout>
        <SectionTitle>
          {t('hi')} {patient.firstName}
        </SectionTitle>

        <SectionBody>
          {t('yourPharmacistAt')} {pharmacy.name}{' '}
          {t('hasCancelledYourAppointment')}
          <br />
          <br />
          {t('pleaseContactUsAt')}
          {transformToStandardPhoneNumber(pharmacy.pharmacyContact.phone)}{' '}
          {t('forFurtherInformation')}
        </SectionBody>
      </Layout>
    </FormStepContentLayout>
  )
}
