import * as React from 'react'
import { createAppointmentActivityInstance } from '~/lib/appointmentActivity'
import { BookingScope } from '../../types'

export const createScope = (scope?: Record<string, string>) => ({
  locationId: scope?.locationId ?? '',
  regionCode: scope?.regionCode ?? '',
  countryCode: scope?.countryCode ?? '',
  organizationId: scope?.organizationId ?? '',
  customerId: scope?.customerId ?? '',
})

export const createAppointmentActivityContext = (
  initial: { scope?: BookingScope } = {}
) => ({
  initialAppointmentActivity: createAppointmentActivityInstance(),
  appointmentActivity: createAppointmentActivityInstance(),
  scope: { ...createScope(), ...initial?.scope },
  dispatch: (() => undefined) as React.Dispatch<any>,
})

export type AppointmentActivityContextInterface = ReturnType<
  typeof createAppointmentActivityContext
>

export const AppointmentActivityContext = React.createContext(
  createAppointmentActivityContext()
)
