import { FieldError, FieldErrors } from 'react-hook-form'
import { EngineResult } from 'json-rules-engine'
import { isEnableWhenEvent, isValidationError } from './events'
import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'

export const getValidationErrors = (
  result: EngineResult,
  questionnaire: FhirQuestionnaire
): FieldErrors => {
  const errors = result.failureEvents.reduce(
    (acc: Record<string, FieldError>, event) => {
      if (isValidationError(event)) {
        return {
          ...acc,
          [event.params.fieldName]: {
            type: event.params.type,
            message: event.params.message,
          },
        }
      }
      return acc
    },
    {}
  )

  const disabledFields = result.failureEvents.reduce(
    (acc: Record<string, boolean>, event) => {
      if (isEnableWhenEvent(event)) {
        return {
          ...acc,
          [event.params.fieldName]: true,
        }
      }
      return acc
    },
    {}
  )

  const getFilteredValidations = (
    acc: FieldErrors,
    item: FhirQuestionnaireItem
  ): FieldErrors => {
    const { linkId } = item
    if (disabledFields[linkId] === true) return acc
    if (item.item) {
      return { ...acc, ...item.item.reduce(getFilteredValidations, acc) }
    }
    if (errors[linkId] === undefined) return acc

    return {
      ...acc,
      [linkId]: errors[linkId],
    }
  }

  const enabledValidations = questionnaire.item.reduce(
    getFilteredValidations,
    {}
  )

  return enabledValidations
}
