import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { ActivityCards } from './ActivityCards/ActivityCards'
import { EAppointmentTypeMethod } from '~/graphql/types/schema.type'
import { isEnterprise } from '~/util/enterpriseMethods'

export interface ActivityLandingFormState {
  id: string
  method: EAppointmentTypeMethod
}

export const ActivityLandingForm: React.FC = () => {
  const {
    state: { appointmentActivity, scope, appointment },
    selectAppointmentActivityAsync,
  } = useBookingContext()
  const { nextStep } = useFormManager()
  const [error, setError] = React.useState('')

  const defaultValues = {
    id: appointmentActivity?.appointmentActivityId || '',
  }

  const methods = useForm<ActivityLandingFormState>({
    defaultValues: defaultValues,
    shouldUnregister: false,
  })

  const onSubmit = async (data: ActivityLandingFormState) => {
    const { error } = await selectAppointmentActivityAsync({
      id: data.id,
      regionCode: scope.regionCode || '',
    })
    if (error) {
      setError(error)
    }

    nextStep()
  }

  const anyErrors = error

  if (anyErrors) {
    throw new Error(anyErrors)
  }

  const { id } = methods.watch()
  return (
    <FormProvider {...methods}>
      <ActivityCards
        aais={[appointmentActivity]}
        selectedAaiId={id}
        appointment={appointment}
        onChange={(id) => methods.setValue('id', id)}
        onSubmit={methods.handleSubmit(onSubmit)}
        hideDescription={!isEnterprise()}
      />
    </FormProvider>
  )
}
