import { range } from 'lodash'

export const mergePaginatedResults = (
  existing = {},
  incoming,
  { args },
  paginationKey
) => {
  const merged = existing[paginationKey] ? existing[paginationKey].slice(0) : []
  const end = args.offset + Math.min(args.limit, incoming[paginationKey].length)
  range(args.offset, end).map((i) => {
    merged[i] = incoming[paginationKey][i - args.offset]
  })

  return {
    ...existing,
    ...incoming,
    [paginationKey]: merged,
  }
}

export const readPaginatedResults = (existing, { args }, paginationKey) => {
  const paginatedResults =
    existing?.[paginationKey] &&
    existing[paginationKey].slice(args.offset, args.offset + args.limit)

  if (paginatedResults) {
    return {
      ...existing,
      [paginationKey]: paginatedResults,
    }
  }
}
