import React from 'react'
import { DefaultFallback, ErrorBoundary } from '~/componentsTs'

import {
  PharmacyProvider,
  RouterProvider,
  WhiteLabelProvider,
} from '../../contexts'
import { ErrorContactPharmacy } from '../../components/ErrorContactPharmacy'

interface CommonProvidersProps {
  children: React.ReactNode | React.ReactNode[]
}

export function CommonProviders({ children }: CommonProvidersProps) {
  return (
    <ErrorBoundary render={DefaultFallback}>
      <RouterProvider>
        <PharmacyProvider>
          <WhiteLabelProvider>
            <ErrorBoundary
              render={() => (
                <DefaultFallback>
                  <ErrorContactPharmacy />
                </DefaultFallback>
              )}
            >
              {children}
            </ErrorBoundary>
          </WhiteLabelProvider>
        </PharmacyProvider>
      </RouterProvider>
    </ErrorBoundary>
  )
}
