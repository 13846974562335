import { Extension } from 'fhir/r5'
import { ExtensionValidationIsPath } from '../validation.type'

export const isExtensionValidationIsPath = (
  extension: Extension
): extension is ExtensionValidationIsPath => {
  return (
    extension.url === 'isPath' &&
    extension.valueBoolean !== undefined &&
    Object.keys(extension).length === 2
  )
}
