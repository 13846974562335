import * as React from 'react'
import Loading from '~/components/loading'
import { FinishBar } from '~/pages/patientRegistrationV2/components/FinishBar'
import { FormStep } from './createFormStep'
import {
  useConditionalFormSteps,
  useFormIterator,
  useFormRouter,
} from './hooks'

import { useIntakeJson } from './hooks/useIntakeJson'
import { useCoBooking } from '../contexts'

export const FormManagerContext = React.createContext({
  formSteps: [] as FormStep[],
  formStepsIndex: 0,
  nextStep: (() => undefined) as () => void,
  prevStep: (() => undefined) as (() => void) | null,
  isLastForm: false,
})

export const FormManager = () => {
  const { formSteps } = useIntakeJson()

  // Ref is needed to get the latest formSteps in onNext callback
  const formStepsRef = React.useRef(formSteps)
  formStepsRef.current = formSteps

  const { coBookingHandler } = useCoBooking()

  const {
    FormStep: CurrentFormStep,
    formStepsIndex,
    setFormStepsIndex,
    nextStep,
    prevStep,
    loading: loadingFormStep,
    error: errorFormStep,
  } = useFormIterator(formSteps)

  const isLastForm = React.useMemo(() => {
    return formSteps.length - 1 === formStepsIndex
  }, [formSteps, formStepsIndex])

  const onBackTriggered = (formStepsIndex: number) => {
    setFormStepsIndex(formStepsIndex)
  }
  const onFallbackTriggered = () => {
    setFormStepsIndex(0)
  }

  const { formChanged, previousFormUrl } = useFormRouter({
    formSteps,
    isLastForm,
    formStepsIndex,
    onBackTriggered,
    onFallbackTriggered,
  })

  const onNext = React.useCallback(() => {
    coBookingHandler(
      formStepsRef.current.map((step) => step.metadata.path),
      formStepsIndex,
      () => {
        nextStep()
        formChanged()
      }
    )
  }, [coBookingHandler, formChanged, formStepsIndex, nextStep])

  const onBack = React.useCallback(() => {
    prevStep && prevStep()
    previousFormUrl()
  }, [prevStep, previousFormUrl])

  const { ConditionalForm } = useConditionalFormSteps()
  const FormStep = ConditionalForm || CurrentFormStep
  const loading = loadingFormStep
  const error = errorFormStep

  if (loading) return <Loading title="Loading Forms..." />
  if (error) throw new Error(error)
  if (FormStep === null) throw new Error('FormStep is null')

  return (
    <FormManagerContext.Provider
      value={{
        formSteps,
        formStepsIndex,
        nextStep: onNext,
        prevStep: onBack,
        isLastForm,
      }}
    >
      <FormStep />
      {isLastForm && <FinishBar onClick={onNext} />}
    </FormManagerContext.Provider>
  )
}
