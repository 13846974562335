import * as React from 'react'
import { useForm, FormProvider, FormState } from 'react-hook-form'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { FhirQuestionnaire, SimpleValueType } from '../fhirQuestionnaire'
import { Appointment } from '~/lib/appointment'
import { Patient } from '~/lib/patient'
import { FhirFormProvider } from './FhirFormContext'
import { FhirQuestionnaireComponent } from './FhirQuestionnaireComponent'
import {
  useFhirQuestionnaireDefaultValues,
  useFhirQuestionnaireResolver,
  useDisabledFhirQuestionnaireItems,
  FhirQuestionnaireResolverBuilder,
  useBulkAnswerFhirQuestionnaireItems,
} from './hooks'
import Loading from '~/components/loading'
import { Alert } from '~/componentsTs/Alert'
import { ErrorText } from '~/componentsTs/ErrorText/ErrorText'
import { useReuseableFhirQuestionnaireItems } from './hooks/useReuseableFhirQuestionnaireItems'

export type FhirFormState = FormState<Record<string, SimpleValueType>>

export type FhirFormStateContext = {
  patient: Patient
  appointment: Appointment
  fields: Record<string, string | number | boolean | null>
}

// Just following the old FhirForm interface, clean this up once we get rid of the old FhirForm
interface FhirFormProps {
  questionnaire: FhirQuestionnaire
  onSubmit: (
    data: Record<string, SimpleValueType>,
    fieldEntityMap: Record<
      string,
      {
        entity: string
        path: string
      }[]
    >,
    e?: React.BaseSyntheticEvent<object, unknown, unknown>,
    formState?: FhirFormState
  ) => Promise<void>
  onFormStateChange?: (formState: FhirFormState) => void
  Form: React.FC<JSX.IntrinsicElements['form']>
  state: FhirFormStateContext
  resolverBuilder?: FhirQuestionnaireResolverBuilder
  callHandleSubmit?: boolean // allows a parent component to access onSubmitHandler
}

export const FhirForm: React.FC<FhirFormProps> = React.memo(
  ({
    questionnaire,
    onSubmit,
    Form,
    state,
    onFormStateChange,
    resolverBuilder,
    callHandleSubmit,
  }: FhirFormProps) => {
    const { t } = useMedMeTranslation()

    const { defaultValues } = useFhirQuestionnaireDefaultValues(
      questionnaire,
      state
    )

    const { resolver } = useFhirQuestionnaireResolver(
      questionnaire,
      resolverBuilder,
      t
    )

    const methods = useForm({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues,
      resolver: resolver,
      shouldUnregister: false,
    })

    const { reset } = methods

    React.useEffect(() => {
      reset(defaultValues)
    }, [defaultValues, reset])

    const { disabledItems, loading, error } = useDisabledFhirQuestionnaireItems(
      questionnaire,
      methods
    )

    const { error: bulkAnswerError } = useBulkAnswerFhirQuestionnaireItems(
      questionnaire,
      methods
    )

    if (bulkAnswerError) {
      console.error(bulkAnswerError)
    }

    const {
      error: reuseableItemsError,
      saveReusableData,
      hiddenItems,
    } = useReuseableFhirQuestionnaireItems(questionnaire, methods)

    if (reuseableItemsError) {
      console.error(reuseableItemsError)
    }

    const onSubmitHandler = methods.handleSubmit((data, e) => {
      saveReusableData()
      const excludedDisabledItemsData = Object.entries(data).reduce<
        Record<string, SimpleValueType>
      >((acc, [key, value]) => {
        // Check if field is supposed to be undefined because parent item is disabled
        if (key in acc && acc[key] === undefined) return acc

        if (disabledItems[key]) {
          acc[key] = undefined
          // Ignore all disabled nested fields as well
          Object.keys(questionnaire.linkIdItems[key].linkIdItems).forEach(
            (linkId) => {
              acc[linkId] = undefined
            }
          )
          return acc
        }
        return {
          ...acc,
          [key]:
            typeof value === 'string'
              ? value.replace(/[ \t]+/g, ' ').trim() // Remove leading and trailing whitespace for strings
              : value,
        }
      }, {})

      onSubmit(
        excludedDisabledItemsData,
        questionnaire.getLinkIdEntityPathObjectMap(),
        e
      )
    })

    React.useEffect(() => {
      if (onFormStateChange) {
        onFormStateChange(methods.formState)
      }
    }, [methods.formState, onFormStateChange])

    React.useEffect(() => {
      if (callHandleSubmit) {
        onSubmitHandler()
      }
    }, [onSubmitHandler, callHandleSubmit])

    if (loading) {
      return (
        <Loading
          title={t('loading.title.loading')}
          subTitle={t('loading.subtitle.doNotCloseBrowser')}
        />
      )
    }

    if (error) {
      return (
        <Alert severity="error">
          <ErrorText error={error} />
        </Alert>
      )
    }
    return (
      <Form onSubmit={onSubmitHandler} noValidate>
        <FormProvider {...methods}>
          <FhirFormProvider
            disabledItems={disabledItems}
            hiddenItems={hiddenItems}
          >
            <FhirQuestionnaireComponent questionnaire={questionnaire} />
          </FhirFormProvider>
        </FormProvider>
      </Form>
    )
  }
)
