import React from 'react'

// styles
import { Layout, Overlay, Content, Title, SubTitle } from './loading.styles'
import CircularProgress from '@material-ui/core/CircularProgress'

type LoadingProps = {
  title: string
  subTitle?: string
  overlay?: boolean
  style?: React.CSSProperties
}
export const Loading = ({
  title,
  subTitle,
  overlay = false,
  style,
}: LoadingProps) => {
  if (overlay) {
    return (
      <div>
        <Overlay />
        <Content style={style}>
          <CircularProgress size={40} />
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </Content>
      </div>
    )
  }
  return (
    <Layout style={style}>
      <CircularProgress size={40} />
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </Layout>
  )
}
