import { Appointment as NodeAppointment } from '~/graphql/types/schemaNode.type'
import {
  EAppointmentStatus,
  EAppointmentTypeCategory,
  EAppointmentTypeSubCategory,
  ELanguageCode,
  Appointment as JavaAppointment,
  NextSteps,
} from '~/graphql/types/schema.type'
import { DeepPartial } from '~/pages/AppointmentIntake/lib'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const getJavaAppointmentFromNode = (
  appointment: DeepPartial<NodeAppointment> | undefined | null
): DeepPartial<JavaAppointment> => {
  const appointmentStatus =
    (appointment?.appointmentStatus as unknown as EAppointmentStatus) ?? ''
  const langCode = (appointment?.langCode as unknown as ELanguageCode) ?? ''
  const nextSteps =
    (appointment?.appointmentType?.nextSteps as unknown as NextSteps) ?? ''
  const subCategory =
    (appointment?.appointmentType
      ?.subCategory as unknown as EAppointmentTypeSubCategory) ?? ''
  const category =
    (appointment?.appointmentType
      ?.category as unknown as EAppointmentTypeCategory) ?? ''

  return {
    id: appointment?.id ?? '',
    startDateTime: appointment?.startDateTime ?? '',
    endDateTime: appointment?.endDateTime ?? '',
    method: appointment?.method,
    appointmentStatus: appointmentStatus,
    isWalkin: appointment?.isWalkin ?? false,
    noOfPeople: appointment?.noOfPeople ?? 1,
    langCode: langCode,
    consentCollectedBy: appointment?.consentCollectedBy ?? '',
    consentGiverName: appointment?.consentGiverName ?? '',
    /**
     *  Note: bookingEndDate and bookingStartDate are stored currently in database as local date with data type "date" rather than UTC "timestamptz"
     *  dayjs.utc() is a workaround to pass the correct date range the pharmacist selects for booking from waitlist
     */
    bookingEndDate: appointment?.bookingEndDate
      ? String(dayjs.utc(appointment?.bookingEndDate).format('YYYY-MM-DD'))
      : null,
    bookingStartDate: appointment?.bookingStartDate
      ? String(dayjs.utc(appointment?.bookingStartDate).format('YYYY-MM-DD'))
      : null,
    linkExpiresAt: appointment?.linkExpiresAt
      ? String(dayjs.utc(appointment?.linkExpiresAt).format())
      : null,
    documentationCompletedAt: appointment?.documentationCompletedAt ?? '',
    createdAt: appointment?.createdAt ?? '',
    updatedAt: appointment?.updatedAt ?? '',
    appointmentType: {
      ...appointment?.appointmentType,
      nextSteps: nextSteps,
      subCategory: subCategory,
      category: category,
    },
    reasonForVisit: appointment?.reasonForVisit ?? '',
    injectionName: appointment?.injectionName ?? '',
    screeningAnswers: [],
  }
}
