import * as React from 'react'

import { AppointmentActivityGroupContext } from '../AppointmentActivityGroupContext'

export const useAppointmentActivityGroupContext = () => {
  const context = React.useContext(AppointmentActivityGroupContext)
  if (context === undefined) {
    throw new Error(
      'useAppointmentActivityGroupContext must be used within a AppointmentActivityGroupProvider'
    )
  }
  const { dispatch, ...state } = context

  return {
    ...state,
  }
}
