import { useLocation } from 'react-router-dom'
import { ELanguageCode } from '~/graphql/types/schema.type'
import { LanguageCodesFE } from '~/types/languageEnums'
import { LANGUAGE_QUERY_PARAM } from '../languageMethods'

// convert language param to lang code supported by java back-end
export const getLangCode = (language: string | undefined | null) => {
  switch (language) {
    case 'en-CA':
      return ELanguageCode.EN
    case 'fr-CA':
      return ELanguageCode.FR
    default:
      return undefined
  }
}

// fetch language from query parameter
export const useLanguageParameter = () => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  return searchParams.get(LANGUAGE_QUERY_PARAM)
}

export const getLanguageFori18n = (langCode: string): LanguageCodesFE => {
  if (
    langCode === 'fr-CA' ||
    langCode === ELanguageCode.FR ||
    langCode === LanguageCodesFE.FR
  )
    return LanguageCodesFE.FR
  return LanguageCodesFE.EN
}
