import { Engine } from 'json-rules-engine'
import { Resolver } from 'react-hook-form'
import { JsonPrimitive } from '~/lib/json'
import { FhirQuestionnaire } from '~/pages/AppointmentIntake/lib/fhir'
import { getValidationErrors } from './getValidationErrors'

export const createRulesEngineFhirResolver =
  (rulesEngine: Engine, questionnaire: FhirQuestionnaire) =>
  (): Resolver<Record<string, JsonPrimitive>> =>
  async (values: Record<string, JsonPrimitive>) => {
    Object.keys(values).forEach((key) => {
      const value = values[key]
      rulesEngine.addFact(key, value)
    })
    const result = await rulesEngine.run()
    const errors = getValidationErrors(result, questionnaire)
    if (Object.keys(errors).length) {
      return {
        errors,
        values: {},
      }
    }
    return {
      values,
      errors: {},
    }
  }
