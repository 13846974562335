import React, { HTMLAttributes, useContext } from 'react'

import { WebViewContext } from '~/context/WebViewContext'

import { SubmitButton } from '../../patientRegistration.styles'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

// fix for weird typescript inop w/ styled button and attribute mismatch such as 'color'
interface FinishButtonProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, 'color'> {
  onClick?: () => void
  disabled: boolean
}

export const FinishButton: React.FC<FinishButtonProps> = ({
  onClick,
  ...props
}) => {
  const { t } = useMedMeTranslation('common')
  const { sendEvent } = useContext(WebViewContext)
  const handleClick = () => {
    sendEvent('close') // when patient completes flow and closes pop up
    if (onClick) {
      onClick()
    }
  }

  return (
    <SubmitButton type="button" onClick={handleClick} {...props}>
      {t('finish')}
    </SubmitButton>
  )
}
