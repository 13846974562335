import styled from 'styled-components'
import { Button, MenuItem } from '@material-ui/core'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { black, white } from '~/sharedStyles/colorPalette'
import { hexToRGB } from '~/util/minorAilments/hexToRgb'

interface CardProps {
  selected: boolean
}
interface MenuItemProps {
  selecteditem: number
}

export const Card = styled(Button)<CardProps>`
  display: block;
  height: 10rem;
  width: 10rem;
  background-color: ${({ theme }) =>
    (props) =>
      props.selected
        ? hexToRGB(theme?.color?.backgroundPrimary, '0.04')
        : white};
  color: ${({ theme }) =>
    (props) =>
      props.selected && theme.color.backgroundPrimary};
  border-color: ${({ theme }) =>
    (props) =>
      props.selected && theme.color.backgroundPrimary};
  @media (max-width: 650px) {
    display: flex;
    width: 100%;
    height: 4rem;
  }
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`
export const SymptomFilterDropdownLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`

export const SymptomFilterDropdownContainer = styled.div`
  margin-bottom: 2rem;
  @media (min-width: 701px) {
    display: none;
  }
`

export const SymptomContainer = styled.div`
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 1rem;
  @media (max-width: 950px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 700px) {
    display: none;
  }
`

export const SymptomsTitle = styled(Heading4)`
  margin-bottom: 1rem;
  font-weight: 700;
`

export const SymptomSelectorMenuItem = styled(MenuItem)<MenuItemProps>`
  height: 3rem;
  background-color: ${({ theme }) =>
    (props) =>
      props.selecteditem
        ? hexToRGB(theme.color.backgroundPrimary, '0.1')
        : white};
  color: ${({ theme }) =>
    (props) =>
      props.selecteditem ? theme.color.backgroundPrimary : black};
  font-weight: ${(props) => props.selecteditem && '500'};
  &.MuiListItem-root.Mui-selected {
    background-color: ${({ theme }) =>
      (props) =>
        props.selecteditem
          ? hexToRGB(theme.color.backgroundPrimary, '0.1')
          : white};
  }
  &.MuiListItem-button:hover {
    text-decoration: none;
    background-color: ${({ theme }) =>
      hexToRGB(theme.color.backgroundPrimary, '0.1')};
  }
`
