import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const StyledTitle = styled(Typography)`
  font-size: 1.15rem;
  font-weight: 600;
`

export const PatientProgressLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const PatientProgressLabel = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const IntakeProgressList = styled.ul`
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const IntakeNodeLabel = styled(Typography)`
  color: #000;
  font-family: GT Eesti Text Trial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const IntakeProgressContainer = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ProgressList = styled.ul`
  list-style: disc;

  & li {
    font-size: 0.875rem;
    line-height: 1.35;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  & .MuiStepLabel-root {
    height: 30px;
  }
`

export const TruncatedLabel = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const CollapsibleContainer = styled.div`
  margin: 10px;
`

export const PatientProgressContainer = styled.div`
  padding: 10px 0 0 0;
`

export const CircleIcon = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #dbdbdb;
`
