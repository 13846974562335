import * as React from 'react'
import {
  usePharmacyContext,
  usePatientContext,
  useAppointmentContext,
  useAppointmentActivityContext,
  useRouterContext,
  useAppointmentActivityGroupContext,
} from '../..'
import { BookingContext, BookingContextInterface } from '../BookingContext'

export interface FormState {
  [key: string]: unknown
}

export const BookingProvider: React.FC = ({ children }) => {
  const router = useRouterContext()
  const { initialPharmacy, pharmacy } = usePharmacyContext()
  const { initialPatient, patient } = usePatientContext()
  const { initialAppointment, appointment } = useAppointmentContext()
  const { initialAppointmentActivityGroup, appointmentActivityGroup } =
    useAppointmentActivityGroupContext()
  const { initialAppointmentActivity, appointmentActivity, scope } =
    useAppointmentActivityContext()
  const [fields, setFields] = React.useState<BookingContextInterface['fields']>(
    {}
  )
  const [questionnaires, setQuestionnaires] = React.useState<
    BookingContextInterface['questionnaires']
  >([])
  const [reservationId, setReservationId] =
    React.useState<BookingContextInterface['reservationId']>('')
  const [bookingError, setBookingError] =
    React.useState<BookingContextInterface['bookingError']>('')

  return (
    <BookingContext.Provider
      value={{
        initial: {
          pharmacy: initialPharmacy,
          patient: initialPatient,
          appointment: initialAppointment,
          appointmentActivityGroup: initialAppointmentActivityGroup,
          appointmentActivity: initialAppointmentActivity,
        },
        pharmacy,
        patient,
        appointment,
        appointmentActivityGroup,
        appointmentActivity,
        scope,
        router,
        fields,
        reservationId,
        setFields,
        setReservationId,
        bookingError,
        setBookingError,
        questionnaires,
        setQuestionnaires,
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}
