import * as React from 'react'

import {
  EmptyCalendarOverlay,
  EmptyCalendarContent,
  Text,
  ActionButton,
} from './EmptyCalendar.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const EmptyCalendar: React.FC<{
  numDays: number
  hasMoreDays: boolean
  onGetMoreDays: () => void
}> = ({ numDays, hasMoreDays, onGetMoreDays }) => {
  const { t } = useMedMeTranslation('patient')

  const text = `${
    numDays === 7
      ? t('emptyCalendar.noAvailability.forThisWeek')
      : t('emptyCalendar.noAvailability.forTheseDays', {
          numDays,
        })
  } ${
    hasMoreDays ? t('emptyCalendar.noAvailability.viewMoreAvailabilities') : ''
  }`

  const buttonText = `${
    numDays === 7
      ? t('emptyCalendar.viewNextWeek')
      : t('emptyCalendar.viewNextDays', {
          numDays,
        })
  }`

  return (
    <EmptyCalendarOverlay>
      <EmptyCalendarContent>
        <Text>{text}</Text>
        {hasMoreDays && (
          <ActionButton onClick={onGetMoreDays}>{buttonText}</ActionButton>
        )}
      </EmptyCalendarContent>
    </EmptyCalendarOverlay>
  )
}
