import * as React from 'react'
import { FhirStemComponent } from './FhirStemComponent'
import { FhirQuestionnaire, FhirQuestionnaireItem } from '../../../../lib/fhir'

export const FhirQuestionnaireItemComponent: React.FC<{
  questionnaireItem: FhirQuestionnaireItem
  questionnaire: FhirQuestionnaire
  depth?: number
  isDisabled?: boolean
}> = React.memo((props) => {
  return <FhirStemComponent {...props} />
})
