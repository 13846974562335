import React, { CSSProperties } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { ExtendedAlertSeverity } from './Alert'
import { ButtonWarning } from '../Button/ButtonWarning'
import { ButtonError } from '../Button/ButtonError'

export interface AlertButtonProps extends ButtonProps {
  severity: ExtendedAlertSeverity
}

/**
 * Preconfigured styles for alert buttons based on alert severity.
 * Used internally by the MedMe Alert component by default if buttonProps are passed
 */
export const AlertButton: React.FC<AlertButtonProps> = ({
  severity,
  variant = 'contained',
  ...props
}) => {
  const sharedStyles: CSSProperties = {
    whiteSpace: 'nowrap',
  }

  switch (severity) {
    case 'success':
      return (
        <Button
          color="secondary"
          variant={variant}
          style={{ ...sharedStyles }}
          {...props}
        />
      )
    case 'warning':
      return (
        <ButtonWarning
          variant={variant}
          style={{ ...sharedStyles }}
          {...props}
        />
      )
    case 'error':
      return (
        <ButtonError variant={variant} style={{ ...sharedStyles }} {...props} />
      )
    case 'loading':
    case 'info':
    default:
      return (
        <Button
          color="primary"
          variant={variant}
          style={{ ...sharedStyles }}
          {...props}
        />
      )
  }
}
