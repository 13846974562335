/* eslint-disable camelcase */

import R5 from 'fhir/r5'
import { createFhirCodingEntityPatient } from '~/lib/fhir/systems'
import { countryValueSetAnswerOptions } from '~/lib/fhir/questionnaires/valueSets/countryValueSet'
import { provinceValueSetAnswerOptions } from '~/lib/fhir/questionnaires/valueSets/provinceValueSet'
import { createFhirQuestionnaire } from '~/lib/fhir/questionnaires/fhirQuestionnaire'
import {
  createFhirQuestionnaireItem,
  FhirQuestionnaireItem,
} from '~/lib/fhir/questionnaires/fhirQuestionnaireItem'
import {
  ExtensionValidationMessage,
  ExtensionValidationOperator,
  extensionValidationUrl,
  ExtensionValidationValue,
  ExtensionStyling,
  ExtensionStylingGridColumn,
  ExtensionItemTypeVariant,
  extensionItemTypeVariantUrl,
  extensionStylingUrl,
} from '../extensions'

const basicValidation = (
  extMessage: ExtensionValidationMessage,
  extOperator: ExtensionValidationOperator,
  extValue: ExtensionValidationValue
) => ({
  url: extensionValidationUrl,
  extension: [
    extMessage,
    {
      url: 'conditionGroup',
      extension: [
        {
          url: 'behaviour',
          valueCode: 'all',
        },
        {
          url: 'conditionList',
          extension: [
            {
              url: 'condition',
              extension: [
                extOperator,
                extValue,
                { url: 'isPath', valueBoolean: false },
              ],
            },
          ],
        },
      ],
    },
  ],
})

const basicGridStyling = (
  value: ExtensionStylingGridColumn['valueCode']
): ExtensionStyling => ({
  url: extensionStylingUrl,
  extension: [
    {
      url: 'gridColumn',
      valueCode: value,
    },
  ],
})

const itemTypeVariantExt = (
  value: ExtensionItemTypeVariant['valueCode']
): ExtensionItemTypeVariant => ({
  url: extensionItemTypeVariantUrl,
  valueCode: value,
})

const patientInformation = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientInformationHeading',
    text: 'Contact Information',
    type: 'display',
    _type: {
      extension: [itemTypeVariantExt('heading1')],
    },
  })

  const desc = createFhirQuestionnaireItem({
    linkId: 'patientInformationDesc',
    text: 'Please enter your details as they appear on your provincial health card (if applicable)',
    type: 'display',
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Required fields (*)',
    type: 'display',
  })

  const firstName = createFhirQuestionnaireItem({
    linkId: 'patientFirstName',
    text: 'First Name + Middle Name',
    type: 'string',
    required: true,
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'firstName' })],
  })

  const lastName = createFhirQuestionnaireItem({
    linkId: 'patientLastName',
    text: 'Last Name',
    type: 'string',
    required: true,
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'lastName' })],
  })

  const gender = createFhirQuestionnaireItem({
    linkId: 'patientGender',
    text: 'Gender',
    type: 'coding',
    answerOption: [
      {
        valueCoding: {
          code: 'female',
          display: 'Female',
        },
      },
      {
        valueCoding: {
          code: 'male',
          display: 'Male',
        },
      },
      {
        valueCoding: {
          code: 'self-identify',
          display: 'Self Identify',
        },
      },
    ],
    required: true,
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'gender' })],
  })

  const dateOfBirth = createFhirQuestionnaireItem({
    linkId: 'patientDateOfBirth',
    text: 'Date of Birth',
    type: 'date',
    required: true,

    extension: [
      basicGridStyling('6'),
      basicValidation(
        { url: 'message', valueString: 'Invalid date of birth' },
        { url: 'operator', valueCode: 'isSameOrBefore' },
        { url: 'value', valueString: 'now' }
      ),
    ],
    code: [createFhirCodingEntityPatient({ code: 'birthDate' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientInformationSection',
    type: 'group',
    item: [heading, desc, descReq, firstName, lastName, gender, dateOfBirth],
  })
}

const patientAddress = () => {
  const enabledWhenNoPoBox = (items: FhirQuestionnaireItem[]) =>
    createFhirQuestionnaireItem({
      linkId: 'enabledWhenNoPoBox',
      type: 'group',
      enableWhen: [
        {
          question: 'patientHasPOBox',
          operator: '=',
          answerBoolean: false,
        },
      ],
      enableBehavior: 'all',
      item: items,
    })

  const renderWhenHasPoBox: Pick<
    R5.QuestionnaireItem,
    'enableWhen' | 'enableBehavior' | 'disabledDisplay'
  > = {
    enableWhen: [
      {
        question: 'patientHasPOBox',
        operator: '=',
        answerBoolean: true,
      },
    ],
    enableBehavior: 'all',
    disabledDisplay: 'hidden',
  }

  const heading = createFhirQuestionnaireItem({
    linkId: 'patientAddressHeading',
    type: 'display',
    text: 'Address',
    _type: {
      extension: [itemTypeVariantExt('heading2')],
    },
  })

  const unit = createFhirQuestionnaireItem({
    linkId: 'patientUnit',
    type: 'string',
    text: 'Unit Number',
    extension: [basicGridStyling('3')],
    code: [createFhirCodingEntityPatient({ code: 'address.unit' })],
  })

  const streetNumberReq = createFhirQuestionnaireItem({
    linkId: 'patientStreetNumber',
    type: 'string',
    text: 'Street Number',
    required: true,
    extension: [basicGridStyling('3')],
    code: [createFhirCodingEntityPatient({ code: 'address.streetNumber' })],
  })

  const streetNameReq = createFhirQuestionnaireItem({
    linkId: 'patientStreetName',
    type: 'string',
    text: 'Street Name',
    required: true,
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'address.streetName' })],
  })

  const countryReq = createFhirQuestionnaireItem({
    linkId: 'patientCountry',
    type: 'coding',
    text: 'Country',

    answerConstraint: 'optionsOnly',
    answerOption: countryValueSetAnswerOptions('CA'),
    required: true,
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'address.country' })],
  })

  const cityReq = createFhirQuestionnaireItem({
    linkId: 'patientCity',
    type: 'string',
    text: 'City',
    required: true,
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'address.city' })],
  })

  const canadaProvince = createFhirQuestionnaireItem({
    linkId: 'patientCanadaProvince',
    type: 'coding',
    text: 'Province',
    required: true,
    answerConstraint: 'optionsOnly',
    initial: [
      {
        valueCoding: {
          code: 'ON',
        },
      },
    ],
    answerOption: provinceValueSetAnswerOptions('ON'),
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'address.province' })],
    enableWhen: [
      {
        question: 'patientCountry',
        operator: '=',
        answerString: 'CA',
      },
    ],
    enableBehavior: 'all',
    disabledDisplay: 'hidden',
  })

  const provinceReq = createFhirQuestionnaireItem({
    linkId: 'patientProvince',
    type: 'string',
    text: 'Province/State',
    required: true,
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'address.province' })],
    enableWhen: [
      {
        question: 'patientCountry',
        operator: '!=',
        answerString: 'CA',
      },
    ],
    enableBehavior: 'any',
    disabledDisplay: 'hidden',
  })

  const postalCodeReq = createFhirQuestionnaireItem({
    linkId: 'patientPostalCode',
    type: 'string',
    text: 'Postal Code/Zip Code',
    required: true,
    extension: [basicGridStyling('6')],
    code: [createFhirCodingEntityPatient({ code: 'address.postalCode' })],
  })

  const hasPOBox = createFhirQuestionnaireItem({
    linkId: 'patientHasPOBox',
    type: 'boolean',
    text: 'I have a PO box',
  })

  const poBox = createFhirQuestionnaireItem({
    linkId: 'patientPOBox',
    type: 'string',
    text: 'PO Box',
    required: true,
    ...renderWhenHasPoBox,
    code: [createFhirCodingEntityPatient({ code: 'address.poBox' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientInformationPage',
    type: 'group',
    item: [
      heading,
      enabledWhenNoPoBox([
        unit,
        streetNumberReq,
        streetNameReq,
        countryReq,
        cityReq,
        provinceReq,
        canadaProvince,
        postalCodeReq,
      ]),
      hasPOBox,
      poBox,
    ],
  })
}

const patientContactDetails = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsHeading',
    type: 'display',
    text: 'Contact Details',
    _type: {
      extension: [itemTypeVariantExt('heading2')],
    },
  })

  const subheading1 = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsSubheading1',
    type: 'display',
    text: 'Please enter at least one phone number we can reach you at',
  })

  const postDesc = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPostDesc',
    type: 'display',
    text: 'Please ensure that you have provided the correct email address. We will send you updates and reminders exclusively about your appointment to this email and texts to your mobile phone.',
  })

  const email = createFhirQuestionnaireItem({
    linkId: 'patientEmail',
    type: 'string',
    text: 'Email Address',
    extension: [
      basicGridStyling('4'),
      basicValidation(
        { url: 'message', valueString: 'Invalid email address' },
        { url: 'operator', valueCode: 'isEmail' },
        { url: 'value', valueBoolean: true }
      ),
    ],
    _type: {
      extension: [itemTypeVariantExt('email')],
    },
    required: true,
    enableWhen: [
      {
        question: 'patientNoEmail',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'email' })],
  })

  const home = createFhirQuestionnaireItem({
    linkId: 'patientHome',
    type: 'string',
    _type: {
      extension: [itemTypeVariantExt('phone')],
    },
    text: 'Home Phone Number',
    extension: [
      basicGridStyling('4'),
      basicValidation(
        { url: 'message', valueString: 'Invalid phone number' },
        { url: 'operator', valueCode: 'isPhone' },
        { url: 'value', valueBoolean: true }
      ),
    ],
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'phone.home' })],
  })

  const cell = createFhirQuestionnaireItem({
    linkId: 'patientCell',
    type: 'string',
    _type: {
      extension: [itemTypeVariantExt('phone')],
    },
    text: 'Mobile Phone Number',
    extension: [
      basicGridStyling('4'),
      basicValidation(
        { url: 'message', valueString: 'Invalid phone number' },
        { url: 'operator', valueCode: 'isPhone' },
        { url: 'value', valueBoolean: true }
      ),
    ],
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'phone.cell' })],
  })

  const noEmail = createFhirQuestionnaireItem({
    linkId: 'patientNoEmail',
    type: 'boolean',
    text: "I don't have an email",
  })

  const noEmailConfirm = createFhirQuestionnaireItem({
    linkId: 'patientNoEmailConfirm',
    type: 'boolean',
    text: 'I understand that I will not be receiving emails and will need to contact that pharmacy directly to make changes to my appointment',
    required: true,
    enableWhen: [
      {
        question: 'patientNoEmail',
        operator: '=',
        answerBoolean: true,
      },
    ],
    enableBehavior: 'all',
    disabledDisplay: 'hidden',
  })

  const emailNotificationConsent = createFhirQuestionnaireItem({
    linkId: 'patientEmailNotificationConsent',
    type: 'boolean',
    text: "Yes, I'd like to receive occasional notifications, communications, promotions, and other news regarding my eligibility for future services at this pharmacy. I understand that I can unsubscribe at any time.",
    code: [
      createFhirCodingEntityPatient({
        code: 'patientConsent.caslEmailConsent',
      }),
      createFhirCodingEntityPatient({ code: 'patientConsent.caslSmsConsent' }),
    ],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [
      heading,
      subheading1,
      email,
      home,
      cell,
      postDesc,
      noEmail,
      noEmailConfirm,
      emailNotificationConsent,
    ],
  })
}

export const questionnaire_indie_ma_on_contactInformation =
  createFhirQuestionnaire({
    resourceType: 'Questionnaire',
    id: 'ab1e20ca-d0d9-4798-8e04-9124ab2703f7',
    version: 'a9f2daed-6db5-4d04-b57c-1deb20a3bfcf',
    title: 'Contact Information',
    name: 'contact-information',
    status: 'active',
    item: [
      createFhirQuestionnaireItem({
        linkId: 'contactInformationPage',
        type: 'group',
        _type: {
          extension: [itemTypeVariantExt('card')],
        },
        item: [patientInformation(), patientAddress(), patientContactDetails()],
      }),
    ],
  })
