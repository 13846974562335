import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { SnackbarContext, defaultSnackbarProps } from './SnackbarContext'
import { SnackbarProps } from '@material-ui/core/Snackbar'
import { StyledSnackbar } from './Snackbar.styles'
import { v4 } from 'uuid'

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbarQueue, setSnackbarQueue] = useState<SnackbarProps[]>([])
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps | undefined>(
    undefined
  )

  useEffect(() => {
    // if there is a new snackbar in the queue and there is no current snackbar, display the new one
    if (snackbarQueue.length && !snackbarProps) {
      const [next, ...rest] = snackbarQueue
      setSnackbarProps({ ...next, open: true })
      setSnackbarQueue(rest)
    } else if (snackbarQueue.length && snackbarProps) {
      /**
       * if we have snackbars in queue and a current snackbar, remove the current snackbar
       * and display the next one
       */
      setSnackbarProps(undefined)
    }
  }, [snackbarQueue, snackbarProps])

  const showSnackbar = useCallback((props: SnackbarProps) => {
    setSnackbarQueue((prev) => [
      { ...defaultSnackbarProps, ...props, key: v4() },
      ...prev,
    ])
  }, [])

  const closeSnackbar = useCallback(() => {
    setSnackbarProps(undefined)
  }, [])

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {snackbarProps ? (
        <StyledSnackbar {...snackbarProps} onClose={closeSnackbar} />
      ) : null}
      {children}
    </SnackbarContext.Provider>
  )
}
