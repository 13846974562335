import {
  StandardTranslationNamespace,
  CustomTranslationNamespace,
} from './i18n-namespaces.types'
import { getEnterpriseCode } from '~/util/enterpriseMethods'
import { isStandardTranslationNamespace } from './i18n-namespaces.type-guards'
import { EnterpriseEnums } from '~/shared/constants/enterpriseConstants'

// store the current enterprise code (or a falsey value if the current deployment is not an enterprise)
const enterprise = getEnterpriseCode()

/**
 * Returns the standard (default) or customer-specific custom translation namespace based
 * on the given `StandardTranslationNamespace`.
 *
 * This function encapsulates the conditional logic to support customer-specific overrides of a
 * given i18next translation namespace, to support cases where the customer has requested us to
 * use their provided copy and/or translations.
 *
 * This function internally depends on `getEnterpriseCode()` which determines which customer the app
 * is deployed for based on the hostname in the address bar.
 *
 * For example:
 * - `getTranslationNamespace('patient')` will return 'patient' for independent pharmacies
 * - `getTranslationNamespace('patient')` will return 'patient-custom-sdm' for SDM
 *
 * All custom namespaces must adhere to the file naming conventions defined in the Notion document
 * linked below.
 *
 * This function supports the implementation of react components that are free from any customer-specific
 * hardcoding and helps promote a clean scalable codebase and reusable components that is not littered
 * with one-off conditionals.
 *
 * @see StandardTranslationNamespace
 * @see CustomTranslationNamespace
 * @see getEnterpriseCode
 * @see {@link https://www.notion.so/medmehealth/F-E-i18n-Scope-Recommendations-3ebcfb461b5d49b9a8e19c19fef31927?pvs=4#d29b2c48147c499caf8ea95be9f5cad2 | Notion Documentation}
 *
 * @throws Error if the given namespace is not a supported i18next namespace per `StandardTranslationNamespace`
 */
const getTranslationNamespace = (
  namespace: StandardTranslationNamespace | undefined
): StandardTranslationNamespace | CustomTranslationNamespace => {
  if (!isStandardTranslationNamespace(namespace)) {
    throw new Error(`Invalid standard i18n namespace(s): ${String(namespace)}`)
  }

  switch (enterprise) {
    case EnterpriseEnums.SDM:
      return `${namespace}-custom-sdm`
    case EnterpriseEnums.LCL:
      return `${namespace}-custom-sdm`
    case EnterpriseEnums.PHX:
      return `${namespace}-custom-sdm`
    case EnterpriseEnums.JCG:
      return `${namespace}-custom-jcg`
    case EnterpriseEnums.BRUNET:
      return `${namespace}-custom-jcg`
    case EnterpriseEnums.METRO:
      return `${namespace}-custom-metro`
    case EnterpriseEnums.REXALL:
      return `${namespace}-custom-rexall`
    case EnterpriseEnums.SOF:
      return `${namespace}-custom-sof`
    default:
      return namespace
  }
}

export { getTranslationNamespace }
