import * as React from 'react'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { useGetAppointmentActivityLazyQuery } from '~/graphql/types/schemaNode.type'
import { useLanguageForPatient } from '~/hooks/useLanguageForPatient'
import { createAppointmentActivityInstance } from '~/lib/appointmentActivity'
import { Pharmacy } from '~/lib/pharmacy'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'

export const useInitializeTimeslotSelection = (pharmacy: Pharmacy) => {
  const { languageForFHIR } = useLanguageForPatient()

  const [query] = useGetAppointmentActivityLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      clientName: EServer.NODE,
      headers: {
        'accept-language': languageForFHIR,
      },
    },
  })
  const {
    state: { appointmentActivity, scope },
  } = useBookingContext()
  const [aai, setAai] = React.useState(createAppointmentActivityInstance())
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    query({
      variables: {
        ...scope,
        id: appointmentActivity.appointmentActivityId,
        regionCode: pharmacy.province,
        locationId: pharmacy.id,
      },
    })
      .then((result) => {
        const newAA = createAppointmentActivityInstance(
          result.data?.appointmentActivityInstance
        )
        setAai(newAA)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return {
    aai,
    error,
    loading,
  }
}
