import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../types'

export const medicalEmergencyScreening = () => {
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'If you are experiencing a medical emergency, call 9-1-1 or go to your nearest hospital emergency room.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })

  const paxlovidEligibilityHeading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'COVID-19 Antiviral Treatment (Paxlovid) Eligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const desc = createFhirQuestionnaireItem({
    text: `You can book a COVID-19 Antiviral Treatment (Paxlovid) Assessment if you meet all of the following eligibility criteria:
- You have received a positive COVID-19 test result
- Your symptoms started less than 5 days ago
- You have not already received Paxlovid for your current COVID-19 infection
- You belong to one of the groups below:
    1. You are 60 years of age or older 
    2. You are between the ages of 18 and 59 years old and:
- Have a weakened immune system (immunocompromised)
- Have other medical conditions  that may put you at a greater risk of becoming seriously ill
- Are unvaccinated or have an incomplete primary series* of COVID-19 vaccination
- Have not received a dose of COVID-19 vaccination or had a COVID-19 infection in the last 6 months

(* A primary series is completed with two to three doses with the original (monovalent) mRNA vaccine. This may include a fourth dose if you have a weakened immune system)


By booking an appointment, you understand that a pharmacist will confirm your eligibility before completing the COVID-19 Antiviral Treatment (Paxlovid) Assessment.
`,
    linkId: 'desc',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [medicalEmergency, paxlovidEligibilityHeading, desc],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirMedicalScreeningSdmPaxlovidJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      urlParams: ['paxlovid'],
    },
  },
  metadata: {
    id: 'fhirMedicalScreeningSdmPaxlovidJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
