import { reportException } from '~/tools/monitoring'
import axios from 'axios'
import { getTenantMetadataId } from '~/lib/tenantMetadata'
import { ICache } from '~/util/cache'
import { InMemoryCache } from '~/util/cache/InMemoryCache'
import { IIdentityProviderClient } from './types'
import { IdpIntegration } from '../types'

const IDP_INTEGRATIONS_MAP_CACHE_KEY =
  'IdentityProviderClient:idpIntegrationsMap'

export class IdentityProviderClient implements IIdentityProviderClient {
  constructor(
    private readonly cache: ICache<string, Record<string, IdpIntegration>>
  ) {
    this.cache = cache
  }

  public get isInitialized(): boolean {
    return this.cache.has(IDP_INTEGRATIONS_MAP_CACHE_KEY)
  }

  private get idpIntegrationsMap(): Record<string, IdpIntegration> {
    return this.cache.get(IDP_INTEGRATIONS_MAP_CACHE_KEY) || {}
  }

  public async initialize() {
    if (this.isInitialized) {
      return
    }

    const tenantMetadataId = getTenantMetadataId()

    if (!tenantMetadataId) {
      throw new Error('IdentityProviderClient: No tenant metadata ID found')
    }

    try {
      const response = await axios.get(
        `${process.env.NODE_GRAPHQL_ENDPOINT}/rest/v1/tenant/${tenantMetadataId}/idp_integrations`
      )

      const idpIntegrationsMap = response.data.reduce(
        (
          acc: Record<string, IdpIntegration>,
          idpIntegration: IdpIntegration
        ) => {
          const key = idpIntegration.identityProvider?.toLowerCase()

          if (!key) {
            throw new Error(
              'IdentityProviderClient: identityProvider is missing in IDP integration'
            )
          }

          acc[key] = idpIntegration
          return acc
        },
        {}
      )

      this.cache.set(IDP_INTEGRATIONS_MAP_CACHE_KEY, idpIntegrationsMap)
    } catch (error) {
      reportException(error)

      throw new Error(
        'IdentityProviderClient: Cannot initialize IdentityProviderClient'
      )
    }
  }

  public getIdpIntegrationByEnterpriseCode(
    enterpriseCode: string
  ): IdpIntegration {
    if (!this.isInitialized) {
      throw new Error(
        'IdentityProviderClient: Cannot get IDP by enterprise code. Client is not initialized'
      )
    }

    const idpIntegration = this.idpIntegrationsMap[enterpriseCode.toLowerCase()]

    if (!idpIntegration) {
      throw new Error(
        `IdentityProviderClient: IDP integration not found for enterpriseCode: ${enterpriseCode}`
      )
    }

    return idpIntegration
  }

  public getAllIdpIntegrations(): IdpIntegration[] {
    if (!this.isInitialized) {
      throw new Error(
        'IdentityProviderClient: Cannot get all IDPs. Client is not initialized'
      )
    }

    return Object.values(this.idpIntegrationsMap)
  }
}

const cache = new InMemoryCache<string, Record<string, IdpIntegration>>()

// As a workaround, we need to create an instance of the service in the global scope.
// This is necessary because the service will be utilized by the legacy getClientId helper,
// which is globally available.
export const identityProviderClient = new IdentityProviderClient(cache)
