import R5 from 'fhir/r5'
import { Questionnaire } from '~/graphql/types/schemaNode.type'
import {
  FhirQuestionnaireMiddleware,
  applyQuestionnaireMiddleware,
} from '../fhir/fhirQuestionnaire/middleware/applyQuestionnaireMiddleware'

export const convertQuestionnaireToFhirR5Questionnaire = (
  questionnaire: Pick<Questionnaire, 'id' | 'versionId' | 'fhirJson'>,
  middleware?: FhirQuestionnaireMiddleware[]
): R5.Questionnaire => {
  let questionnaireData: R5.Questionnaire = {
    ...questionnaire.fhirJson,
    id: questionnaire.id,
    version: questionnaire.versionId,
  }

  if (middleware && middleware.length) {
    questionnaireData = applyQuestionnaireMiddleware(
      questionnaireData,
      middleware
    )
  }

  return questionnaireData
}
