import { Appointment } from '~/lib/appointment'
import { useAppointmentContext } from '../../..'

export type UpdateConsentProps = Pick<
  Appointment,
  'consentCollectedBy' | 'consentGiverName'
>

export const useUpdateConsent = () => {
  const { updateAppointment } = useAppointmentContext()
  return (consent: UpdateConsentProps) => {
    updateAppointment(consent)
  }
}
