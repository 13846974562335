import { QuestionnaireItemAnswerOption } from 'fhir/r4'
import { provinceList, provinceListFr } from '~/util/geographyList'

export const provinceValueSet = (
  initialSelected?: string,
  language?: string
) => {
  const provinces = language === 'fr' ? provinceListFr : provinceList
  return Object.keys(provinces).map<QuestionnaireItemAnswerOption>(
    (key: keyof typeof provinces) => {
      const value = provinces[key]
      const coding = {
        code: String(key),
        display: value,
      }

      if (initialSelected && initialSelected === key) {
        return {
          valueCoding: coding,
          initialSelected: true,
        }
      }
      return {
        valueCoding: coding,
      }
    }
  )
}
