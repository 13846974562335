import * as React from 'react'
import { Select, YesNoRadioField } from '~/controlledContextInputs'
import { FhirLeafComponent } from '../FhirLeafComponent'
import { InputLabel } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const FhirChoiceInput: typeof FhirLeafComponent = ({
  questionnaireItem,
  isDisabled,
}) => {
  const {
    supportedExtensions: { typeVariant },
  } = questionnaireItem

  const defaultValue: string | undefined = (() => {
    const initial = questionnaireItem.getInitial()
    if (typeof initial === 'string' || initial === undefined) {
      return initial
    } else {
      throw new Error('FhirChoiceInput: currently only support string values')
    }
  })()
  const options: { value: string; label: string }[] = questionnaireItem
    .getOptions()
    .map((option) => {
      if (typeof option.value === 'string') {
        return {
          value: option.value,
          label: option.label,
        }
      } else {
        throw new Error('FhirChoiceInput: currently only support string values')
      }
    })

  if (typeVariant === 'radio') {
    return (
      <YesNoRadioField
        options={options}
        defaultValue={defaultValue}
        name={questionnaireItem.linkId}
        label={questionnaireItem.getText()}
        LabelComponent={InputLabel}
        disabled={isDisabled}
        required={questionnaireItem.required}
      />
    )
  }
  return (
    <Select
      name={questionnaireItem.linkId}
      label={questionnaireItem.getText()}
      defaultValue={defaultValue}
      options={options}
      disabled={isDisabled}
      required={questionnaireItem.required}
    />
  )
}
