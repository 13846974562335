import React from 'react'
import { FormStepComponent } from '../types'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { useReviewFormStep } from './hooks/useReviewFormStep'
import { FormLayout } from '../FormLayout'
import Loading from '~/components/loading'
import { FormProvider } from 'react-hook-form'
import { Card } from '../../WaitlistIntake.style'
import { CheckboxField } from '~/controlledContextInputs'
import { Alert } from '~/componentsTs/Alert'
import { ErrorText } from '~/componentsTs/ErrorText/ErrorText'
import { ContactInformation } from './components'

export const ReviewFormStep: FormStepComponent = (formStepProps) => {
  const { t } = useMedMeTranslation('patient')
  const { formMethods, handleSubmit, loading, error } =
    useReviewFormStep(formStepProps)

  return (
    <FormLayout
      onSubmit={handleSubmit}
      isDisabled={loading}
      nextButtonType="submit"
      {...formStepProps}
    >
      {error && (
        <Alert severity="error">
          <ErrorText error={error} />
        </Alert>
      )}
      {loading && (
        <Loading
          title={t('loading.title.submitting', { ns: 'common' })}
          subTitle={t('loading.subtitle.pleaseWaitAMoment', { ns: 'common' })}
          overlay={true}
        />
      )}
      <FormProvider {...formMethods}>
        <ContactInformation />
        <Card>
          <CheckboxField
            name="reviewAndConfirm"
            label={t('reviewAndConfirm.reviewedAllInformation')}
          />
        </Card>
      </FormProvider>
    </FormLayout>
  )
}
