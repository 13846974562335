import * as React from 'react'
import { DeselectedCard } from './DeselectedCard'
import { SelectedCard } from './SelectedCard'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { isSdmEnterprise } from '~/util/enterpriseMethods'
import { EAppointmentTypeCategory } from '~/graphql/types/schema.type'
import { useLocalFeatureFlags } from '~/hooks/useFeatureFlags'

export const AaiCard: React.FC<{
  aai: AppointmentActivityInstance
  selected: boolean
  isAvailable: boolean
  appointmentMethod?: string
  onExpand: (id: string) => void
  onCollapse: (id: string) => void
  onSubmit: (id: string) => void
}> = ({
  aai,
  selected,
  isAvailable,
  onExpand,
  onCollapse,
  onSubmit,
  appointmentMethod,
}) => {
  const featureFlags = useLocalFeatureFlags('medme-flags')
  const { category } = aai

  if (selected) {
    return (
      <SelectedCard
        appointmentMethod={appointmentMethod}
        aai={aai}
        available={isAvailable}
        onCollapse={onCollapse}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <DeselectedCard
      aai={aai}
      available={isAvailable}
      onExpand={onExpand}
      onSubmit={onSubmit}
      showUnselectedSubmitButton={
        isSdmEnterprise() &&
        aai.settings.enabledMethods.length === 1 &&
        aai.details.description === aai.details.subDescription
      }
      showSelectButton={
        featureFlags.isFlagEnabled('SDM-35') &&
        category !== EAppointmentTypeCategory.MINOR_AILMENT
      }
    />
  )
}
