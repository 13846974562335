import defaultMoment from 'moment-timezone'
import { isJcgBrunet } from './enterpriseMethods'

if (isJcgBrunet()) {
  defaultMoment.updateLocale('fr', {
    months:
      'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
        '_'
      ),
    monthsShort:
      'janv._févr._mars_avr._mai_juin_juill._août_sept._oct._nov._déc.'.split(
        '_'
      ),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH:mm', // 04:10
      LLL: 'LT D MMM YYYY', // 04:10 14 mars 2022
      LLLL: 'dddd Do MMMM YYYY', // lundi 14 mars 2022
      l: 'Do MMM YYYY', // 14 mars 2022
      ll: 'Do MMM YYYY', // 14 mars 2022
      lll: 'LT dddd DD MMM', // 04:10 lundi 14 mars
      llll: 'LT dddd DD MMM', // 04:10 lundi 14 mars
    },
  })

  defaultMoment.updateLocale('en', {
    longDateFormat: {
      LT: 'h:mm A', // 4:10 AM
      LLL: 'LT MMM. D YYYY', // 4:10 AM Mar. 14 2022
      LLLL: 'dddd, MMMM Do, YYYY', // Monday, March 14th, 2022
      l: 'MMM. DD YYYY', // Mar. 14 2022
      ll: 'MMM. D, YYYY', // Mar. 14, 2022
      lll: 'ddd MMM. DD, LT', // Mon Mar. 14, 4:10 AM
      llll: 'ddd MMM. DD, LT', // Mon Mar. 14, 4:10 AM
    },
  })
} else {
  defaultMoment.updateLocale('fr', {
    longDateFormat: {
      LT: 'HH:mm', // 23:00
      LLL: 'ddd D MMM, LT', // lun. 14 mars, 4:10
      l: 'dddd', // jeudi
      ll: 'D MMM', // 13 mars
      lll: 'dddd D MMMM', // dimanche 19 mars
      llll: 'dddd D MMM, LT', // jeudi 14 mars, 4:10
    },
  })

  defaultMoment.updateLocale('en', {
    longDateFormat: {
      LT: 'h:mmA', // 11:00PM
      LLL: 'ddd MMM D, LT', // Tue Mar 14, 4:10AM
      l: 'ddd', // Sun
      ll: 'MMM D', // Mar 13
      lll: 'ddd MMM D', // Sun Mar 19
      llll: 'ddd MMM D, LT', // Tue Mar 14, 4:10 AM
    },
  })
}

export default defaultMoment
