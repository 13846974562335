import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js'

const getUserPool = () => {
  const poolData = {
    UserPoolId: process.env.COGNITO_USER_POOL_ID,
    ClientId: process.env.COGNITO_APP_CLIENT_ID,
  }
  return new CognitoUserPool(poolData)
}

export const getCognitoUser = (email) => {
  const userPool = getUserPool()
  return new CognitoUser({
    Username: email.toLowerCase(),
    Pool: userPool,
  })
}

export const getAuthDetails = (email, password) => {
  return new AuthenticationDetails({
    Username: email.toLowerCase(),
    Password: password,
  })
}

export const getCurrentCognitoUser = () => {
  const userPool = getUserPool()
  return userPool.getCurrentUser()
}
// need to convert to specific format https://stackoverflow.com/questions/50400993/why-do-i-get-a-typeerror-this-gettoken-is-not-a-function-react-js
export const formatRefreshToken = (refreshToken) => {
  return new CognitoRefreshToken({ RefreshToken: refreshToken })
}

export const getSessionAsync = async (currentUser) =>
  new Promise((resolve, reject) => {
    try {
      currentUser.getSession((err, session) => {
        if (err) throw err
        resolve(session)
      })
    } catch (err) {
      reject(new Error(`getSessionAsync: ${err}`))
    }
  })

export const globalSignOutAsync = async (currentUser) =>
  new Promise((resolve, reject) => {
    try {
      currentUser.globalSignOut({
        onSuccess: (msg) => resolve(msg),
        onFailure: (err) => {
          throw err
        },
      })
    } catch (err) {
      reject(new Error(`globalSignOutAsync: ${err}`))
    }
  })
