import { createPatient } from '~/lib/patient'
import { useGetBookingByKeysQuery } from '~/graphql/types/schemaNode.type'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { useRouterContext } from '../../RouterContext'
import { getJavaPatientFromNode } from '~/util/adapters/nodeToJavaPatient'

export const useCurrentPatient = () => {
  const { appointmentTypeWithCalendarToken, patientToken, appointmentToken } =
    useRouterContext()

  const {
    data: nodePatient,
    loading,
    error,
  } = useGetBookingByKeysQuery({
    variables: {
      key1: appointmentTypeWithCalendarToken,
      key2: patientToken,
      key3: appointmentToken,
    },
    context: {
      clientName: EServer.NODE,
    },
    skip: !(
      appointmentTypeWithCalendarToken &&
      patientToken &&
      appointmentToken
    ),
  })

  const javaPatient = getJavaPatientFromNode(
    nodePatient?.getBookingByKeys?.patients?.[0]
  )

  const patient = createPatient(javaPatient)

  if (error) throw new Error(error.message)

  return {
    patient,
    loading,
  }
}
