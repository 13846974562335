import * as React from 'react'
import {
  Layout,
  Title,
  SubTitle,
  TagsLayout,
  TitleAndTagsContainer,
  TitleFlexBasis,
  tooltipTheme,
} from '../AaiCard.style'
import {
  Card,
  BodyContainer,
  Footer,
  TitleLayout,
  TitleContainer,
  CardLayout,
  SubmitButtonContainer,
  SelectButton,
} from './DeselectedCard.style'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { AaiCardSubmitButton } from '../AaiCardSubmitButton'
import { AaiIcon } from '../AaiIcon'
import { AaiBadge } from '../AaiBadge'
import { ThemeProvider } from '@material-ui/core/styles'
import { MarkdownComponent } from '~/lib/markdown/MarkdownComponent'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

type DeselectedApptCardProps = {
  aai: AppointmentActivityInstance
  available: boolean
  showUnselectedSubmitButton?: boolean
  showSelectButton?: boolean
  onExpand: (id: string) => void
  onSubmit: (id: string) => void
}

export const DeselectedCard: React.FC<DeselectedApptCardProps> = ({
  aai,
  available,
  showUnselectedSubmitButton = false,
  showSelectButton = true,
  onExpand,
  onSubmit,
}) => {
  const {
    id: appointmentActivityInstanceId,
    appointmentActivityId,
    details: { description, title, subtitle, badges },
    settings: { iconUrl },
  } = aai

  const { t } = useMedMeTranslation('common')

  return (
    <Layout>
      <Card
        aria-expanded={false}
        role="button"
        tabIndex={0}
        elevation={2}
        onClick={() => onExpand(appointmentActivityInstanceId)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onExpand(appointmentActivityInstanceId)
          }
        }}
      >
        <AaiIcon src={iconUrl} />
        <CardLayout>
          <TitleContainer>
            <TitleAndTagsContainer>
              <TitleFlexBasis>
                <TitleLayout>
                  <Title>{title}</Title>
                  {Boolean(subtitle) && <SubTitle>{subtitle}</SubTitle>}
                </TitleLayout>
              </TitleFlexBasis>
              <TagsLayout>
                <ThemeProvider theme={tooltipTheme}>
                  {badges.map((badge, index) => (
                    <AaiBadge badge={badge} key={index} />
                  ))}
                </ThemeProvider>
              </TagsLayout>
            </TitleAndTagsContainer>
            {showUnselectedSubmitButton && (
              <SubmitButtonContainer>
                <AaiCardSubmitButton
                  onClick={() => {
                    onSubmit(appointmentActivityId)
                  }}
                  isAvailable={Boolean(available)}
                />
              </SubmitButtonContainer>
            )}
          </TitleContainer>
          {Boolean(description) && (
            <BodyContainer>
              <Heading4>
                <MarkdownComponent>{description}</MarkdownComponent>
              </Heading4>
            </BodyContainer>
          )}
          {showUnselectedSubmitButton && (
            <Footer>
              <AaiCardSubmitButton
                onClick={() => {
                  onSubmit(appointmentActivityId)
                }}
                isAvailable={Boolean(available)}
              />
            </Footer>
          )}
        </CardLayout>
        {showSelectButton && (
          <SelectButton aria-label={t('select')}>
            {t('select').toLocaleUpperCase()}
          </SelectButton>
        )}
      </Card>
    </Layout>
  )
}
