import * as React from 'react'

import {
  FhirQuestionnaire,
  SimpleValueType,
} from '~/lib/fhir/fhirQuestionnaire'

import { FhirFormStateContext } from '../../FhirForm'
import { setEntityValueWhenUndefined } from './setEntityValueWhenUndefined'
import { setFallbackValueWhenUndefined } from './setFallbackValueWhenUndefined'
import { setItemInitialWhenUndefined } from './setItemInitialWhenUndefined'

interface InitialData {
  defaultValues: Record<string, SimpleValueType>
}

export const useFhirQuestionnaireDefaultValues = (
  questionnaire: FhirQuestionnaire,
  state: FhirFormStateContext
): InitialData => {
  const data = React.useMemo(() => {
    const defaultValues = [
      setEntityValueWhenUndefined(state),
      setItemInitialWhenUndefined,
      setFallbackValueWhenUndefined,
    ].reduce<Record<string, string | number | boolean | undefined>>(
      (acc, fn) => {
        return fn(acc, questionnaire.linkIdItems)
      },
      questionnaire.getAnswers()
    )

    return {
      defaultValues,
    }
  }, [questionnaire, state])

  return data
}
