const initialState = {
  timeZone: 'America/Toronto',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PHARMACY_DATA:
      return action.pharmacyData
    case CLEAR_PHARMACY_DATA:
      return initialState
    default:
      return state
  }
}

const LOAD_PHARMACY_DATA = 'LOAD_PHARMACY_DATA'
const CLEAR_PHARMACY_DATA = 'CLEAR_PHARMACY_DATA'

export const loadPharmacyData = (pharmacyData) => ({
  type: LOAD_PHARMACY_DATA,
  pharmacyData,
})

export const clearPharmacyData = () => ({
  type: CLEAR_PHARMACY_DATA,
})

export default reducer
