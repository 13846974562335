import * as React from 'react'
import { useUpdateFields } from './useUpdateFields'
import { BookingContext } from '../BookingContext'
import { useSubmitIntake } from './useSubmitIntake'
import { useSelectAppointmentActivity } from './useSelectAppointmentActivity'
import { useUpdateConsent } from './useUpdateConsent'
import useMediaHook from '~/hooks/mediaHook'
import { useStartAppointmentActivityIntake } from './useStartAppointmentActivityIntake'

export const useBookingContext = () => {
  const context = React.useContext(BookingContext)
  if (context === undefined) {
    throw new Error('useBookingContext must be used within a BookingProvider')
  }
  const deviceType: 'phone' | 'tablet' | 'desktop' = useMediaHook(
    ['(max-width: 500px)', '(max-width: 1065px)'],
    ['phone', 'tablet'],
    'desktop'
  )

  const {
    setFields,
    setReservationId,
    setBookingError,
    setQuestionnaires,
    ...state
  } = context
  const { updateFields } = useUpdateFields(context)
  const { submitIntakeAsync } = useSubmitIntake(
    state.reservationId,
    state.appointmentActivity,
    state.questionnaires
  )
  const { selectAppointmentActivityAsync, resetAppointmentActivity } =
    useSelectAppointmentActivity()
  const updateConsent = useUpdateConsent()
  const {
    startAppointmentActivityIntakeAsync,
    resetAppointmentActivityIntakeAsync,
  } = useStartAppointmentActivityIntake(context)
  return {
    state,
    deviceType,
    updateFields,
    submitIntakeAsync,
    resetAppointmentActivity,
    selectAppointmentActivityAsync,
    updateConsent,
    startAppointmentActivityIntakeAsync,
    resetAppointmentActivityIntakeAsync,
    setBookingError,
    setQuestionnaires,
  }
}
