import * as React from 'react'

const key = 'x-pharmacyid'

export const useWithPharmacyIdHeader = () => {
  const withPharmacyIdHeader = React.useCallback(
    async (pharmacyId: string, callback: () => Promise<void>) => {
      const currentId = localStorage.getItem(key)
      try {
        localStorage.set(key, pharmacyId)
        await callback()
      } finally {
        localStorage.set(key, currentId)
      }
    },
    []
  )

  return {
    withPharmacyIdHeader,
  }
}
