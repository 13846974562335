type ObjectWithNoFunctions<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any
    ? never
    : T[K] extends object
    ? ObjectWithNoFunctions<T[K]>
    : T[K]
}

export const deepCopy = <T extends object | undefined>(
  obj: ObjectWithNoFunctions<T> | undefined
): T => (obj === undefined ? undefined : JSON.parse(JSON.stringify(obj)))
