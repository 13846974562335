import styled from 'styled-components'

export const Layout = styled.form`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
  row-gap: 2rem;

  & > * {
    margin-bottom: 1.5rem;
  }
`
