import xTenantIds from '~/xTenantIds'
import { TenantMetadataError } from './tenantMetadataError'
import { TenantMetadata } from './types'
import xPharmacyIds from '~/xPharmacyIds'
import xOrganizationIds from '~/xOrganizationIds'
import { reportException } from '~/tools/monitoring'

export const getMetadata = (): TenantMetadata | null => {
  const medmeAppLocalStorageKey =
    process.env.MEDME_APP_METADATA_LOCAL_STORAGE_KEY

  if (!medmeAppLocalStorageKey) {
    return { error: 'No app metadata local storage key' }
  }

  const metadata = localStorage.getItem(medmeAppLocalStorageKey)
  if (!metadata) {
    return null
  }

  try {
    return JSON.parse(metadata) as TenantMetadata
  } catch (error) {
    return { error: 'Failed to parse metadata' }
  }
}

// TODO: remove function on feature flag removal
// https://medmehealth.atlassian.net/browse/NEB-1114
export function checkMetadataAndReport(metadata: TenantMetadata): void {
  if ('error' in metadata) {
    reportException(new TenantMetadataError(metadata.error))
    return
  }

  const localTenantId = xTenantIds[window.location.hostname]
  const localPharmacyId =
    xPharmacyIds[`${window.location.hostname}-pharmacyId`] ?? null
  const localOrganizationId = xOrganizationIds[window.location.hostname] ?? null
  if (
    localTenantId !== metadata.tenantId ||
    localPharmacyId !== metadata.pharmacyId ||
    localOrganizationId !== metadata.organizationId
  ) {
    reportException(new TenantMetadataError('Metadata mismatch'), {
      extra: {
        api: metadata,
        local: {
          tenantId: localTenantId,
          pharmacyId: localPharmacyId,
          organizationId: localOrganizationId,
        },
      },
    })
  }
}
