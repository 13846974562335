import { Box, Button, Toolbar } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

export const DebuggerContainer = styled(Box)`
  background-color: #f5f5f5;
`

export const DebuggerMenu = styled(Toolbar)`
  background-color: red;
  display: flex;
`

export const DebuggerActions = styled(Box)`
  margin-left: auto;
`

export const MainComponentHeader = styled(Box)`
  display: flex;
  padding: 1rem;
`

export const DownloadButton = styled(({ ...otherProps }) => (
  <Button variant="text" {...otherProps} />
))`
  margin-left: auto;
`
