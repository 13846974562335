import { JsonFormStep } from './types'

export const ailmentSelector: JsonFormStep = {
  type: 'preset',
  preset: 'ailmentSelector',
  metadata: {
    id: 'ailmentSelector',
    name: 'Ailment Selector',
    path: 'ailment-selector',
    showOnSteps: false,
  },
}
