import { QuestionnaireItemAnswerOption } from 'fhir/r4'
import { countryList } from '~/util/geographyList'

export const countryValueSet = (initialSelected?: string) => {
  return Object.keys(countryList).map<QuestionnaireItemAnswerOption>(
    (key: keyof typeof countryList) => {
      const value = countryList[key]
      const coding = {
        code: String(key),
        display: value,
      }

      if (initialSelected && initialSelected === key) {
        return {
          valueCoding: coding,
          initialSelected: true,
        }
      }
      return {
        valueCoding: coding,
      }
    }
  )
}
