import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import * as yup from 'yup'
import useYupValidationResolverHook from '~/hooks/yupValidationResolverHook'
import { createPatient } from '~/lib/patient'
import { FormStepProps } from '../../types'
import moment from 'moment'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import { MIN_ALLOWED_BIRTHDATE } from '~/shared/constants/timeConstants'

function formatPhone(value = '', countryCode = '1') {
  return value
    ? `+${countryCode}${value.replace(/[^\d]/g, '').substring(0, 10)}`
    : ''
}

function deformatPhone(value = '', countryCode = '1') {
  const regex = new RegExp(`^[+]${countryCode}`, 'g')
  return value.replace(regex, '')
}

export function useContactFormStep({ path, onComplete }: FormStepProps) {
  const { t } = useMedMeTranslation()
  const { patientIntakeRecords, numberOfPersons, dispatch } =
    useBookingContext()
  const waitlistIntake = patientIntakeRecords?.[0]

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(t('required')),
        lastName: yup.string().required(t('required')),
        birthDate: yup
          .mixed()
          .required(t('required'))
          .test(
            'validDate',
            t('patientValidation.birthDate.validDate'),
            (date) => date.isValid() && date < moment()
          )
          .test('tooOld', t('patientValidation.birthDate.tooOld'), (date) =>
            date.isAfter(MIN_ALLOWED_BIRTHDATE)
          )
          .test(
            'tooYoung',
            t('patientValidation.birthDate.tooYoung'),
            (date) => moment().diff(moment(date), 'years') >= 2
          ),
        email: yup
          .string()
          .required(t('required'))
          .email(t('validEmailValidationError')),
        noOfWaitlistedPeople: yup
          .string()
          .required(t('patientValidation.noOfPeople.required')),
      }),
    [t]
  )
  const resolver = useYupValidationResolverHook(validationSchema)

  const formMethods = useForm({
    resolver,
    reValidateMode: 'onSubmit',
    defaultValues: {
      firstName: waitlistIntake?.patient.firstName,
      lastName: waitlistIntake?.patient.lastName,
      birthDate: waitlistIntake?.patient.birthDate
        ? moment(waitlistIntake.patient.birthDate)
        : null,
      email: waitlistIntake?.patient.email,
      patientConsent: waitlistIntake?.patient.patientConsent,
      noOfWaitlistedPeople: numberOfPersons,
      phone: {
        cell: deformatPhone(waitlistIntake?.patient.phone.cell),
      },
    },
  })
  const { handleSubmit } = formMethods

  const onSubmitHandler = useCallback(
    (data) => {
      const patient = createPatient({
        ...data,
        phone: { cell: formatPhone(data.phone?.cell) },
      })
      const numberOfPersons = Number(data.noOfWaitlistedPeople)
      if (!isNaN(numberOfPersons)) {
        dispatch({ type: 'setNumberOfPersons', payload: numberOfPersons })
      }
      dispatch({
        type: 'updateIntakeRecord',
        payload: {
          patient: { ...patient, patientIdx: 0 },
        },
      })
      onComplete({ path })
    },
    [dispatch, onComplete, path]
  )

  return {
    formMethods,
    handleSubmit: handleSubmit(onSubmitHandler),
  }
}
