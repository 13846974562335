const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MEDSCHECK_DOCUMENT:
      return {
        ...state,
        [action.appointmentId]: action.medsCheckDocument,
      }
    case UPDATE_MEDSCHECK_DOCUMENT:
      return {
        ...state,
        [action.appointmentId]: action.medsCheckDocument,
      }
    case CLEAR_MEDSCHECK_DOCUMENTS:
      return {}
    default:
      return state
  }
}

const LOAD_MEDSCHECK_DOCUMENT = 'LOADS_MEDSCHECK_DOCUMENT'
const UPDATE_MEDSCHECK_DOCUMENT = 'UPDATE_MEDSCHECK_DOCUMENT'
const CLEAR_MEDSCHECK_DOCUMENTS = 'CLEAR_MEDSCHECK_DOCUMENTS'

export const loadsMedsCheckDocument = (appointmentId, medsCheckDocument) => ({
  type: LOAD_MEDSCHECK_DOCUMENT,
  appointmentId,
  medsCheckDocument,
})

export const updateMedsCheckDocument = (appointmentId, medsCheckDocument) => ({
  type: UPDATE_MEDSCHECK_DOCUMENT,
  appointmentId,
  medsCheckDocument,
})

export default reducer
