import env from './env.medmeapp.ca.json'
import oldProd from './env.prod.json'

export default {
  'medmepharmacy.medmeapp.ca-pharmacyId': oldProd.MEDME_ON_PHARMACY_ID,
  'ms0410.medmeapp.ca-pharmacyId': oldProd.MS0410_PHARMACY_ID,
  'medshop389.medmeapp.ca-pharmacyId': oldProd.MEDSHOP389_PHARMACY_ID,
  'ms320.medmeapp.ca-pharmacyId': oldProd.MS320_PHARMACY_ID,
  'medshop111.medmeapp.ca-pharmacyId': oldProd.MEDSHOP111_PHARMACY_ID,
  'medicineshoppe404.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE404_PHARMACY_ID,
  'lapaixpharmacy.medmeapp.ca-pharmacyId': oldProd.LAPAIXPHARMACY_PHARMACY_ID,
  'universalhealthpharmacy.medmeapp.ca-pharmacyId':
    oldProd.UNIVERSALHEALTHPHARMACY_PHARMACY_ID,
  'ps392.medmeapp.ca-pharmacyId': oldProd.PS392_PHARMACY_ID,
  'ps393.medmeapp.ca-pharmacyId': oldProd.PS393_PHARMACY_ID,
  'burnabypharmacy.medmeapp.ca-pharmacyId': oldProd.BURNABYPHARMACY_PHARMACY_ID,
  'marketmall.medmeapp.ca-pharmacyId': oldProd.MARKETMALL_PHARMACY_ID,
  'meadowsmile.medmeapp.ca-pharmacyId': oldProd.MEADOWSMILE_PHARMACY_ID,
  'newportpharma.medmeapp.ca-pharmacyId': oldProd.NEWPORTPHARMA_PHARMACY_ID,
  'idagriesbach.medmeapp.ca-pharmacyId': oldProd.IDAGRIESBACH_PHARMACY_ID,
  'pilotmoundpharmacy.medmeapp.ca-pharmacyId':
    oldProd.PILOTMOUNDPHARMACY_PHARMACY_ID,
  'chestermere.medmeapp.ca-pharmacyId': oldProd.CHESTERMERE_PHARMACY_ID,
  'stonewallpharmacy.medmeapp.ca-pharmacyId':
    oldProd.STONEWALLPHARMACY_PHARMACY_ID,
  'pharmasaveoncentre.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVEONCENTRE_PHARMACY_ID,
  'pharmasavedominion.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVEDOMINION_PHARMACY_ID,
  'medshoppe380.medmeapp.ca-pharmacyId': oldProd.MEDSHOPPE380_PHARMACY_ID,
  'meritidapharmacy.medmeapp.ca-pharmacyId':
    oldProd.MERITIDAPHARMACY_PHARMACY_ID,
  'elmsdalerx.medmeapp.ca-pharmacyId': oldProd.ELMSDALERX_PHARMACY_ID,
  'pharmacyfirst.medmeapp.ca-pharmacyId': oldProd.PHARMACYFIRST_PHARMACY_ID,
  'tantallonpharmasave.medmeapp.ca-pharmacyId':
    oldProd.TANTALLONPHARMASAVE_PHARMACY_ID,
  'bridgewaterguardian.medmeapp.ca-pharmacyId':
    oldProd.BRIDGEWATERGUARDIAN_PHARMACY_ID,
  'gulfcanadamedicineshoppe.medmeapp.ca':
    oldProd.GULFCANADAMEDICINESHOPPE_PHARMACY_ID,
  'hawkwoodmedicineshoppe.medmeapp.ca':
    oldProd.HAWKWOODMEDICINESHOPPE_PHARMACY_ID,
  'bearspawpharmacy.medmeapp.ca-pharmacyId':
    oldProd.BEARSPAWPHARMACY_PHARMACY_ID,
  'dcp.medmeapp.ca-pharmacyId': oldProd.DCP_PHARMACY_ID,
  'sharbotlakepharmacy.medmeapp.ca-pharmacyId':
    oldProd.SHARBOTLAKEPHARMACY_PHARMACY_ID,
  'montaguepharmasave.medmeapp.ca-pharmacyId':
    oldProd.MONTAGUEPHARMASAVE_PHARMACY_ID,
  'rylanderpharmasave.medmeapp.ca-pharmacyId':
    oldProd.RYLANDERPHARMASAVE_PHARMACY_ID,
  'westkingpharmacy.medmeapp.ca-pharmacyId':
    oldProd.WESTKINGPHARMACY_PHARMACY_ID,
  'viccompharmacy.medmeapp.ca-pharmacyId': oldProd.VICCOMPHARMACY_PHARMACY_ID,
  'joanspharmacy.medmeapp.ca-pharmacyId': oldProd.JOANSPHARMACY_PHARMACY_ID,
  'plapmedicineshoppe.medmeapp.ca-pharmacyId':
    oldProd.PLAPMEDICINESHOPPE_PHARMACY_ID,
  'brandonmedicineshoppe.medmeapp.ca-pharmacyId':
    oldProd.BRANDONMEDICINESHOPPE_PHARMACY_ID,
  'ms403.medmeapp.ca-pharmacyId': oldProd.MS403_PHARMACY_ID,
  'campusmedicineshoppe.medmeapp.ca-pharmacyId':
    oldProd.CAMPUSMEDICINESHOPPE_PHARMACY_ID,
  'belleriverpharmasave.medmeapp.ca-pharmacyId':
    oldProd.BELLERIVERPHARMASAVE_PHARMACY_ID,
  'lawlor.medmeapp.ca-pharmacyId': oldProd.LAWLOR_PHARMACY_ID,
  'medicineshoppestvital.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPESTVITAL_PHARMACY_ID,
  'thecornerdrugstore.medmeapp.ca-pharmacyId':
    oldProd.THECORNERDRUGSTORE_PHARMACY_ID,
  'cardstonpharmasave.medmeapp.ca-pharmacyId':
    oldProd.CARDSTONPHARMASAVE_PHARMACY_ID,
  'medicineshoppepembina.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPEPEMBINA_PHARMACY_ID,
  'pharmacycoop.medmeapp.ca-pharmacyId': oldProd.PHARMACYCOOP_PHARMACY_ID,
  'ststephenguardian.medmeapp.ca-pharmacyId':
    oldProd.STSTEPHENGUARDIAN_PHARMACY_ID,
  'albertcountypharmacy.medmeapp.ca-pharmacyId':
    oldProd.ALBERTCOUNTYPHARMACY_PHARMACY_ID,
  'hamptonguardianpharmacy.medmeapp.ca-pharmacyId':
    oldProd.HAMPTONGUARDIANPHARMACY_PHARMACY_ID,
  'eaglemanor.medmeapp.ca-pharmacyId': oldProd.EAGLEMANOR_PHARMACY_ID,
  'medshoppe264.medmeapp.ca-pharmacyId': oldProd.MEDSHOPPE264_PHARMACY_ID,
  'pharmacyassociates.medmeapp.ca-pharmacyId':
    oldProd.PHARMACYASSOCIATES_PHARMACY_ID,
  'plainsrdwestpharmacy.medmeapp.ca-pharmacyId':
    oldProd.PLAINSRDWESTPHARMACY_PHARMACY_ID,
  'medshoppe353.medmeapp.ca-pharmacyId': oldProd.MEDSHOPPE353_PHARMACY_ID,
  'quarrypharmacy.medmeapp.ca-pharmacyId': oldProd.QUARRYPHARMACY_PHARMACY_ID,
  'spadinamedicalpharmacy.medmeapp.ca-pharmacyId':
    oldProd.SPADINAMEDICALPHARMACY_PHARMACY_ID,
  'eastyorkpharmasave.medmeapp.ca-pharmacyId':
    oldProd.EASTYORKPHARMASAVE_PHARMACY_ID,
  'citypharmacy.medmeapp.ca-pharmacyId': oldProd.CITYPHARMACY_PHARMACY_ID,
  'ms386.medmeapp.ca-pharmacyId': oldProd.MS386_PHARMACY_ID,
  'tepharmasave.medmeapp.ca-pharmacyId': oldProd.TEPHARMASAVE_PHARMACY_ID,
  'bathpharmacy.medmeapp.ca-pharmacyId': oldProd.BATHPHARMACY_PHARMACY_ID,
  'inverarypharmasave.medmeapp.ca-pharmacyId':
    oldProd.INVERARYPHARMASAVE_PHARMACY_ID,
  'dickisons.medmeapp.ca-pharmacyId': oldProd.DICKISONS_PHARMACY_ID,
  'beachespharmasave.medmeapp.ca-pharmacyId':
    oldProd.BEACHESPHARMASAVE_PHARMACY_ID,
  'sanomedpharmasave.medmeapp.ca-pharmacyId':
    oldProd.SANOMEDPHARMASAVE_PHARMACY_ID,
  'kip.medmeapp.ca-pharmacyId': oldProd.KIP_PHARMACY_ID,
  'southlandpharmacy.medmeapp.ca-pharmacyId':
    oldProd.SOUTHLANDPHARMACY_PHARMACY_ID,
  'hollypharmacy.medmeapp.ca-pharmacyId': oldProd.HOLLYPHARMACY_PHARMACY_ID,
  'restondrugs.medmeapp.ca-pharmacyId': oldProd.RESTONDRUGS_PHARMACY_ID,
  'northgatemedshoppe.medmeapp.ca-pharmacyId':
    oldProd.NORTHGATEMEDSHOPPE_PHARMACY_ID,
  'gibbonsguardian.medmeapp.ca-pharmacyId': oldProd.GIBBONSGUARDIAN_PHARMACY_ID,
  'highparkpharmacy.medmeapp.ca-pharmacyId':
    oldProd.HIGHPARKPHARMACY_PHARMACY_ID,
  'junctionpharmacy.medmeapp.ca-pharmacyId':
    oldProd.JUNCTIONPHARMACY_PHARMACY_ID,
  'canadiancompoundingpharmacy.medmeapp.ca-pharmacyId':
    oldProd.CANADIANCOMPOUNDINGPHARMACY_PHARMACY_ID,
  'coaldalepharmasave.medmeapp.ca-pharmacyId':
    oldProd.COALDALEPHARMASAVE_PHARMACY_ID,
  'prod-bc.medmeapp.ca-pharmacyId': oldProd.PRODBC_PHARMACY_ID,
  'ps794.medmeapp.ca-pharmacyId': oldProd.PS794_PHARMACY_ID,
  'boggiofonthillpharmacy.medmeapp.ca-pharmacyId':
    oldProd.BOGGIOFONTHILLPHARMACY_PHARMACY_ID,
  'boggiopharmacy.medmeapp.ca-pharmacyId': oldProd.BOGGIOPHARMACY_PHARMACY_ID,
  'boggiomackinnonpharmacy.medmeapp.ca-pharmacyId':
    oldProd.BOGGIOMACKINNONPHARMACY_PHARMACY_ID,
  'boggiogrimsbypharmacy.medmeapp.ca-pharmacyId':
    oldProd.BOGGIOGRIMSBYPHARMACY_PHARMACY_ID,
  'boggioedwards.medmeapp.ca-pharmacyId': oldProd.BOGGIOEDWARDS_PHARMACY_ID,
  'creemorevillagepharmacy.medmeapp.ca-pharmacyId':
    oldProd.CREEMOREVILLAGEPHARMACY_PHARMACY_ID,
  'wortleypharmasave.medmeapp.ca-pharmacyId':
    oldProd.WORTLEYPHARMASAVE_PHARMACY_ID,
  'kqpharmacy.medmeapp.ca-pharmacyId': oldProd.KQPHARMACY_PHARMACY_ID,
  'medicineshoppe360.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE360_PHARMACY_ID,
  'medicineshoppe303.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE303_PHARMACY_ID,
  'bramptonpharmacist.medmeapp.ca-pharmacyId':
    oldProd.BRAMPTONPHARMACIST_PHARMACY_ID,
  'medicineshoppe396.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE396_PHARMACY_ID,
  'medicineshoppeyorkton.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPEYORKTON_PHARMACY_ID,
  'tobiapharmacy.medmeapp.ca-pharmacyId': oldProd.TOBIAPHARMACY_PHARMACY_ID,
  'pharmasave720.medmeapp.ca-pharmacyId': oldProd.PHARMASAVE720_PHARMACY_ID,
  'pharmacylakeside.medmeapp.ca-pharmacyId':
    oldProd.PHARMACYLAKESIDE_PHARMACY_ID,
  'bowmanvilleclinic.medmeapp.ca-pharmacyId':
    oldProd.BOWMANVILLECLINIC_PHARMACY_ID,
  'harrowsmithpharmacy.medmeapp.ca-pharmacyId':
    oldProd.HARROWSMITHPHARMACY_PHARMACY_ID,
  'keeleandfinchpharmasave.medmeapp.ca-pharmacyId':
    oldProd.KEELEANDFINCHPHARMASAVE_PHARMACY_ID,
  'kingsvilleida.medmeapp.ca-pharmacyId': oldProd.KINGSVILLEIDA_PHARMACY_ID,
  'westminsterida.medmeapp.ca-pharmacyId': oldProd.WESTMINSTERIDA_PHARMACY_ID,
  'medicineshoppe161.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE161_PHARMACY_ID,
  'brookspharmasave.medmeapp.ca-pharmacyId':
    oldProd.BROOKSPHARMASAVE_PHARMACY_ID,
  'breslaucommonspharmacy.medmeapp.ca-pharmacyId':
    oldProd.BRESLAUCOMMONSPHARMACY_PHARMACY_ID,
  'nextdoorpharmacy.medmeapp.ca-pharmacyId':
    oldProd.NEXTDOORPHARMACY_PHARMACY_ID,
  'gardinerspharmacy.medmeapp.ca-pharmacyId':
    oldProd.GARDINERSPHARMACY_PHARMACY_ID,
  'medshopperochdale.medmeapp.ca-pharmacyId':
    oldProd.MEDSHOPPEROCHDALE_PHARMACY_ID,
  'porthealthpharmasave.medmeapp.ca-pharmacyId':
    oldProd.PORTHEALTHPHARMASAVE_PHARMACY_ID,
  'pharmaciecocagne.medmeapp.ca-pharmacyId':
    oldProd.PHARMACIECOCAGNE_PHARMACY_ID,
  'healthsmart.medmeapp.ca-pharmacyId': oldProd.HEALTHSMART_PHARMACY_ID,
  'norarmpharmacy.medmeapp.ca-pharmacyId': oldProd.NORARMPHARMACY_PHARMACY_ID,
  'unicarepharmacy.medmeapp.ca-pharmacyId': oldProd.UNICAREPHARMACY_PHARMACY_ID,
  'morrisonpharmasave.medmeapp.ca-pharmacyId':
    oldProd.MORRISONPHARMASAVE_PHARMACY_ID,
  'allcurespharmacy.medmeapp.ca-pharmacyId':
    oldProd.ALLCURESPHARMACY_PHARMACY_ID,
  'msp121.medmeapp.ca-pharmacyId': oldProd.MSP121_PHARMACY_ID,
  'lakewood.medmeapp.ca-pharmacyId': oldProd.LAKEWOOD_PHARMACY_ID,
  'ms0214.medmeapp.ca-pharmacyId': oldProd.MS0214_PHARMACY_ID,
  'peopleschoiceremedy.medmeapp.ca-pharmacyId':
    oldProd.PEOPLESCHOICEREMEDY_PHARMACY_ID,
  'healthmaxpharmacy.medmeapp.ca-pharmacyId':
    oldProd.HEALTHMAXPHARMACY_PHARMACY_ID,
  'shermanida.medmeapp.ca-pharmacyId': oldProd.SHERMANIDA_PHARMACY_ID,
  'medicineshoppekillarney.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPEKILLARNEY_PHARMACY_ID,
  'kennedyroadpharmacy.medmeapp.ca-pharmacyId':
    oldProd.KENNEDYROADPHARMACY_PHARMACY_ID,
  'carepharmacy.medmeapp.ca-pharmacyId': oldProd.CAREPHARMACY_PHARMACY_ID,
  'lacliepharmacy.medmeapp.ca-pharmacyId': oldProd.LACLIEPHARMACY_PHARMACY_ID,
  'jacksonspharmasave.medmeapp.ca-pharmacyId':
    oldProd.JACKSONSPHARMASAVE_PHARMACY_ID,
  'innisfilpharmasave.medmeapp.ca-pharmacyId':
    oldProd.INNISFILPHARMASAVE_PHARMACY_ID,
  'ms228.medmeapp.ca-pharmacyId': oldProd.MS228_PHARMACY_ID,
  'westmountmedicalpharmacy.medmeapp.ca-pharmacyId':
    oldProd.WESTMOUNTMEDICALPHARMACY_PHARMACY_ID,
  'ruhmedicineshoppe.medmeapp.ca-pharmacyId':
    oldProd.RUHMEDICINESHOPPE_PHARMACY_ID,
  'virdendrugs.medmeapp.ca-pharmacyId': oldProd.VIRDENDRUGS_PHARMACY_ID,
  'rxdrugmartsmithville.medmeapp.ca-pharmacyId':
    oldProd.RXDRUGMARTSMITHVILLE_PHARMACY_ID,
  'dundaspharmasave.medmeapp.ca-pharmacyId':
    oldProd.DUNDASPHARMASAVE_PHARMACY_ID,
  'beamsvillepharmacy.medmeapp.ca-pharmacyId':
    oldProd.BEAMSVILLEPHARMACY_PHARMACY_ID,
  'lincolnpharmacy.medmeapp.ca-pharmacyId': oldProd.LINCOLNPHARMACY_PHARMACY_ID,
  'melvillepharmasave.medmeapp.ca-pharmacyId':
    oldProd.MELVILLEPHARMASAVE_PHARMACY_ID,
  'warmanpharmasave.medmeapp.ca-pharmacyId':
    oldProd.WARMANPHARMASAVE_PHARMACY_ID,
  'ms317.medmeapp.ca-pharmacyId': oldProd.MS317_PHARMACY_ID,
  'siroccopharmacy.medmeapp.ca-pharmacyId': oldProd.SIROCCOPHARMACY_PHARMACY_ID,
  'capsulepharmacy.medmeapp.ca-pharmacyId': oldProd.CAPSULEPHARMACY_PHARMACY_ID,
  'lendrumpharmacy.medmeapp.ca-pharmacyId': oldProd.LENDRUMPHARMACY_PHARMACY_ID,
  'pharmasavecedarcourt.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVECEDARCOURT_PHARMACY_ID,
  'wppharmacy.medmeapp.ca-pharmacyId': oldProd.WPPHARMACY_PHARMACY_ID,
  'chappelle.medmeapp.ca-pharmacyId': oldProd.CHAPPELLE_PHARMACY_ID,
  'medicineshoppe251.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE251_PHARMACY_ID,
  'medicineshoppe120.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE120_PHARMACY_ID,
  'gracepharmacy.medmeapp.ca-pharmacyId': oldProd.GRACEPHARMACY_PHARMACY_ID,
  'barriefieldpharmasave.medmeapp.ca-pharmacyId':
    oldProd.BARRIEFIELDPHARMASAVE_PHARMACY_ID,
  'medicineshoppeairdrie.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPEAIRDRIE_PHARMACY_ID,
  'yakimetspharmacy.medmeapp.ca-pharmacyId':
    oldProd.YAKIMETSPHARMACY_PHARMACY_ID,
  'markdalepharmacy.medmeapp.ca-pharmacyId':
    oldProd.MARKDALEPHARMACY_PHARMACY_ID,
  'foresthillspharmasave.medmeapp.ca-pharmacyId':
    oldProd.FORESTHILLSPHARMASAVE_PHARMACY_ID,
  'summersidepharmasave.medmeapp.ca-pharmacyId':
    oldProd.SUMMERSIDEPHARMASAVE_PHARMACY_ID,
  'royaloakpharmacy.medmeapp.ca-pharmacyId':
    oldProd.ROYALOAKPHARMACY_PHARMACY_ID,
  'wlremedysrx.medmeapp.ca-pharmacyId': oldProd.WLREMEDYSRX_PHARMACY_ID,
  'bonaventurepharmacy.medmeapp.ca-pharmacyId':
    oldProd.BONAVENTUREPHARMACY_PHARMACY_ID,
  'medshoppevernon.medmeapp.ca-pharmacyId': oldProd.MEDSHOPPEVERNON_PHARMACY_ID,
  'whitbymedicineshoppe.medmeapp.ca-pharmacyId':
    oldProd.WHITBYMEDICINESHOPPE_PHARMACY_ID,
  'pharmaciestisidore.medmeapp.ca-pharmacyId':
    oldProd.PHARMACIESTISIDORE_PHARMACY_ID,
  'pellowpharmasave.medmeapp.ca-pharmacyId':
    oldProd.PELLOWPHARMASAVE_PHARMACY_ID,
  'achesonpharmasave.medmeapp.ca-pharmacyId':
    oldProd.ACHESONPHARMASAVE_PHARMACY_ID,
  'kristenspharmacy.medmeapp.ca-pharmacyId':
    oldProd.KRISTENSPHARMACY_PHARMACY_ID,
  'quickcarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.QUICKCAREPHARMACY_PHARMACY_ID,
  'landmarkpharmacy.medmeapp.ca-pharmacyId':
    oldProd.LANDMARKPHARMACY_PHARMACY_ID,
  'nobletonpharmasave.medmeapp.ca-pharmacyId':
    oldProd.NOBLETONPHARMASAVE_PHARMACY_ID,
  'kensingtonpharmacy.medmeapp.ca-pharmacyId':
    oldProd.KENSINGTONPHARMACY_PHARMACY_ID,
  'westbrampharmacy.medmeapp.ca-pharmacyId':
    oldProd.WESTBRAMPHARMACY_PHARMACY_ID,
  'lionheadpharmacy.medmeapp.ca-pharmacyId':
    oldProd.LIONHEADPHARMACY_PHARMACY_ID,
  'setonpharmacy.medmeapp.ca-pharmacyId': oldProd.SETONPHARMACY_PHARMACY_ID,
  'medicineshoppe119.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE119_PHARMACY_ID,
  'pharmasave439.medmeapp.ca-pharmacyId': oldProd.PHARMASAVE439_PHARMACY_ID,
  'gordonpharmasave.medmeapp.ca-pharmacyId':
    oldProd.GORDONPHARMASAVE_PHARMACY_ID,
  'purehealth.medmeapp.ca-pharmacyId': oldProd.PUREHEALTH_PHARMACY_ID,
  'medicineshoppe216.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE216_PHARMACY_ID,
  'evapharmacy.medmeapp.ca-pharmacyId': oldProd.EVAPHARMACY_PHARMACY_ID,
  'bramcitypharmacy.medmeapp.ca-pharmacyId':
    oldProd.BRAMCITYPHARMACY_PHARMACY_ID,
  'arcade.medmeapp.ca-pharmacyId': oldProd.ARCADE_PHARMACY_ID,
  'mgp.medmeapp.ca-pharmacyId': oldProd.MGP_PHARMACY_ID,
  'mainstreetpharmasave.medmeapp.ca-pharmacyId':
    oldProd.MAINSTREETPHARMASAVE_PHARMACY_ID,
  'thornburypharmasave.medmeapp.ca-pharmacyId':
    oldProd.THORNBURYPHARMASAVE_PHARMACY_ID,
  'pharmasave41stknight.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVE41STKNIGHT_PHARMACY_ID,
  'royalmedicalpharmasave.medmeapp.ca-pharmacyId':
    oldProd.ROYALMEDICALPHARMASAVE_PHARMACY_ID,
  'medcab.medmeapp.ca-pharmacyId': oldProd.MEDCAB_PHARMACY_ID,
  'londonroadpharmacy.medmeapp.ca-pharmacyId':
    oldProd.LONDONROADPHARMACY_PHARMACY_ID,
  'hanoverpharmasave.medmeapp.ca-pharmacyId':
    oldProd.HANOVERPHARMASAVE_PHARMACY_ID,
  'aubinpharmasave.medmeapp.ca-pharmacyId': oldProd.AUBINPHARMASAVE_PHARMACY_ID,
  'ultracarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.ULTRACAREPHARMACY_PHARMACY_ID,
  'saltspringpharmasave.medmeapp.ca-pharmacyId':
    oldProd.SALTSPRINGPHARMASAVE_PHARMACY_ID,
  'grandriverpharmacy.medmeapp.ca-pharmacyId':
    oldProd.GRANDRIVERPHARMACY_PHARMACY_ID,
  'primacarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.PRIMACAREPHARMACY_PHARMACY_ID,
  'rutherfordguardian.medmeapp.ca-pharmacyId':
    oldProd.RUTHERFORDGUARDIAN_PHARMACY_ID,
  'huntspharmacy.medmeapp.ca-pharmacyId': oldProd.HUNTSPHARMACY_PHARMACY_ID,
  'pringlecreekpharmacy.medmeapp.ca-pharmacyId':
    oldProd.PRINGLECREEKPHARMACY_PHARMACY_ID,
  'metcalfepharmacy.medmeapp.ca-pharmacyId':
    oldProd.METCALFEPHARMACY_PHARMACY_ID,
  'silvercreek.medmeapp.ca-pharmacyId': oldProd.SILVERCREEK_PHARMACY_ID,
  'columbiastreetpharmacy.medmeapp.ca-pharmacyId':
    oldProd.COLUMBIASTREETPHARMACY_PHARMACY_ID,
  'stirlingpharmacy.medmeapp.ca-pharmacyId':
    oldProd.STIRLINGPHARMACY_PHARMACY_ID,
  'moosecreekpharmacy.medmeapp.ca-pharmacyId':
    oldProd.MOOSECREEKPHARMACY_PHARMACY_ID,
  'kleospharmacy.medmeapp.ca-pharmacyId': oldProd.KLEOSPHARMACY_PHARMACY_ID,
  'cwrrx.medmeapp.ca-pharmacyId': oldProd.CWRRX_PHARMACY_ID,
  'carlingwoodpharmasave.medmeapp.ca-pharmacyId':
    oldProd.CARLINGWOODPHARMASAVE_PHARMACY_ID,
  'riverrunpharmacy.medmeapp.ca-pharmacyId':
    oldProd.RIVERRUNPHARMACY_PHARMACY_ID,
  'grandparkpharmacy.medmeapp.ca-pharmacyId':
    oldProd.GRANDPARKPHARMACY_PHARMACY_ID,
  'medicineshoppebeverly.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPEBEVERLY_PHARMACY_ID,
  'msfleetwood.medmeapp.ca-pharmacyId': oldProd.MSFLEETWOOD_PHARMACY_ID,
  'pharmasavefh.medmeapp.ca-pharmacyId': oldProd.PHARMASAVEFH_PHARMACY_ID,
  'medshoppe315.medmeapp.ca-pharmacyId': oldProd.MEDSHOPPE315_PHARMACY_ID,
  'uppervillagepharmasave.medmeapp.ca-pharmacyId':
    oldProd.UPPERVILLAGEPHARMASAVE_PHARMACY_ID,
  'simcoesquareguardian.medmeapp.ca-pharmacyId':
    oldProd.SIMCOESQUAREGUARDIAN_PHARMACY_ID,
  'ferndalerxpharmacy.medmeapp.ca-pharmacyId':
    oldProd.FERNDALERXPHARMACY_PHARMACY_ID,
  'glenmorekelownapharmasave.medmeapp.ca-pharmacyId':
    oldProd.GLENMOREKELOWNAPHARMASAVE_PHARMACY_ID,
  'springdalepharmacy.medmeapp.ca-pharmacyId':
    oldProd.SPRINGDALEPHARMACY_PHARMACY_ID,
  'primarycarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.PRIMARYCAREPHARMACY_PHARMACY_ID,
  'rrxespanola.medmeapp.ca-pharmacyId': oldProd.RRXESPANOLA_PHARMACY_ID,
  'brantarts.medmeapp.ca-pharmacyId': oldProd.BRANTARTS_PHARMACY_ID,
  'rrxbrantford.medmeapp.ca-pharmacyId': oldProd.RRXBRANTFORD_PHARMACY_ID,
  'brinkleypharmacy.medmeapp.ca-pharmacyId':
    oldProd.BRINKLEYPHARMACY_PHARMACY_ID,
  'summeridgeguardian.medmeapp.ca-pharmacyId':
    oldProd.SUMMERIDGEGUARDIAN_PHARMACY_ID,
  'noblepharmacy.medmeapp.ca-pharmacyId': oldProd.NOBLEPHARMACY_PHARMACY_ID,
  'southfieldspharmasave.medmeapp.ca-pharmacyId':
    oldProd.SOUTHFIELDSPHARMASAVE_PHARMACY_ID,
  'portelginpharmasave.medmeapp.ca-pharmacyId':
    oldProd.PORTELGINPHARMASAVE_PHARMACY_ID,
  'jonsmedshoppe.medmeapp.ca-pharmacyId': oldProd.JONSMEDSHOPPE_PHARMACY_ID,
  'nhfp.medmeapp.ca-pharmacyId': oldProd.NHFP_PHARMACY_ID,
  'procarepharmacy.medmeapp.ca-pharmacyId': oldProd.PROCAREPHARMACY_PHARMACY_ID,
  'medbrandsida.medmeapp.ca-pharmacyId': oldProd.MEDBRANDSIDA_PHARMACY_ID,
  'medartspharmasave.medmeapp.ca-pharmacyId':
    oldProd.MEDARTSPHARMASAVE_PHARMACY_ID,
  'northernshorespc.medmeapp.ca-pharmacyId':
    oldProd.NORTHERNSHORESPC_PHARMACY_ID,
  'unicare.medmeapp.ca-pharmacyId': oldProd.UNICARE_PHARMACY_ID,
  'tuscanyguardian.medmeapp.ca-pharmacyId': oldProd.TUSCANYGUARDIAN_PHARMACY_ID,
  'emeraldhillspharmasave.medmeapp.ca-pharmacyId':
    oldProd.EMERALDHILLSPHARMASAVE_PHARMACY_ID,
  'nottinghampharmasave.medmeapp.ca-pharmacyId':
    oldProd.NOTTINGHAMPHARMASAVE_PHARMACY_ID,
  'brentwoodpharmasave.medmeapp.ca-pharmacyId':
    oldProd.BRENTWOODPHARMASAVE_PHARMACY_ID,
  'ps364.medmeapp.ca-pharmacyId': oldProd.PS364_PHARMACY_ID,
  'trailsidepharmasave.medmeapp.ca-pharmacyId':
    oldProd.CWPHARMASAVE_PHARMACY_ID,
  'caravaggioida.medmeapp.ca-pharmacyId': oldProd.CARAVAGGIOIDA_PHARMACY_ID,
  'williamsburgremedyrx.medmeapp.ca-pharmacyId':
    oldProd.WILLIAMSBURGREMEDYRX_PHARMACY_ID,
  'newmarketpharmacy.medmeapp.ca-pharmacyId':
    oldProd.NEWMARKETPHARMACY_PHARMACY_ID,
  'roulstonswellnesscentre.medmeapp.ca-pharmacyId':
    oldProd.ROULSTONSWELLNESSCENTRE_PHARMACY_ID,
  'kortrightpharmacy.medmeapp.ca-pharmacyId':
    oldProd.KORTRIGHTPHARMACY_PHARMACY_ID,
  'acadiadrivepharmacy.medmeapp.ca-pharmacyId':
    oldProd.ACADIADRIVEPHARMACY_PHARMACY_ID,
  'kozlovpharmacy.medmeapp.ca-pharmacyId': oldProd.KOZLOVPHARMACY_PHARMACY_ID,
  'sullivanspharmacy.medmeapp.ca-pharmacyId':
    oldProd.SULLIVANSPHARMACY_PHARMACY_ID,
  'thorntonpharmacy.medmeapp.ca-pharmacyId':
    oldProd.THORNTONPHARMACY_PHARMACY_ID,
  'robinspharmacy.medmeapp.ca-pharmacyId': oldProd.ROBINSPHARMACY_PHARMACY_ID,
  'paulspharmasave.medmeapp.ca-pharmacyId': oldProd.PAULSPHARMASAVE_PHARMACY_ID,
  'caledoneastguardian.medmeapp.ca-pharmacyId':
    oldProd.CALEDONEASTGUARDIAN_PHARMACY_ID,
  'paramountpharmasave.medmeapp.ca-pharmacyId':
    oldProd.PARAMOUNTPHARMASAVE_PHARMACY_ID,
  'morelliswalkerspharmacy.medmeapp.ca-pharmacyId':
    oldProd.MORELLISWALKERSPHARMACY_PHARMACY_ID,
  'atlaspharmacy.medmeapp.ca-pharmacyId': oldProd.ATLASPHARMACY_PHARMACY_ID,
  'hawkesburypharmacy.medmeapp.ca-pharmacyId':
    oldProd.HAWKESBURYPHARMACY_PHARMACY_ID,
  'woodlandpharmacy.medmeapp.ca-pharmacyId':
    oldProd.WOODLANDPHARMACY_PHARMACY_ID,
  'truscottpharmacy.medmeapp.ca-pharmacyId':
    oldProd.TRUSCOTTPHARMACY_PHARMACY_ID,
  'gorepharmacy.medmeapp.ca-pharmacyId': oldProd.GOREPHARMACY_PHARMACY_ID,
  'uptownapothecary.medmeapp.ca-pharmacyId':
    oldProd.UPTOWNAPOTHECARY_PHARMACY_ID,
  'medicinepointida.medmeapp.ca-pharmacyId':
    oldProd.MEDICINEPOINTIDA_PHARMACY_ID,
  'charolaisida.medmeapp.ca-pharmacyId': oldProd.CHAROLAISIDA_PHARMACY_ID,
  'cwpharmasave.medmeapp.ca-pharmacyId':
    oldProd.ELORATRAILSIDEPHARMASAVE_PHARMACY_ID,
  'milfordpharmasave.medmeapp.ca-pharmacyId':
    oldProd.MILFORDPHARMASAVE_PHARMACY_ID,
  'pharmasave9745.medmeapp.ca-pharmacyId': oldProd.PHARMASAVE9745_PHARMACY_ID,
  'whitfieldguardian.medmeapp.ca-pharmacyId':
    oldProd.WHITFIELDGUARDIAN_PHARMACY_ID,
  'lucknowpharmasave.medmeapp.ca-pharmacyId':
    oldProd.LUCKNOWPHARMASAVE_PHARMACY_ID,
  'phs711.medmeapp.ca-pharmacyId': oldProd.PHS711_PHARMACY_ID,
  'bossonspharmacy.medmeapp.ca-pharmacyId': oldProd.BOSSONSPHARMACY_PHARMACY_ID,
  'beaumontcentrepharmacy.medmeapp.ca-pharmacyId':
    oldProd.BEAUMONTCENTREPHARMACY_PHARMACY_ID,
  'kanatapharmasave.medmeapp.ca-pharmacyId':
    oldProd.KANATAPHARMASAVE_PHARMACY_ID,
  'westlockida.medmeapp.ca-pharmacyId': oldProd.PIDSADOWSKIIDA_PHARMACY_ID,
  'pharmasaveuxbridge.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVEUXBRIDGE_PHARMACY_ID,
  'pharmacymgr.medmeapp.ca-pharmacyId': oldProd.PHARMACYMGR_PHARMACY_ID,
  'manhattanguardianpharmacy.medmeapp.ca-pharmacyId':
    oldProd.MANHATTANGUARDIANPHARMACY_PHARMACY_ID,
  'ingersollpharmasave.medmeapp.ca-pharmacyId':
    oldProd.INGERSOLLPHARMASAVE_PHARMACY_ID,
  'cookspharmacy.medmeapp.ca-pharmacyId': oldProd.COOKSPHARMACY_PHARMACY_ID,
  'alconapharmacy.medmeapp.ca-pharmacyId': oldProd.ALCONAPHARMACY_PHARMACY_ID,
  'ghpharmasave.medmeapp.ca-pharmacyId': oldProd.GHPHARMASAVE_PHARMACY_ID,
  'robinsonsida.medmeapp.ca-pharmacyId': oldProd.ROBINSONSIDA_PHARMACY_ID,
  'cyrilpharmasave.medmeapp.ca-pharmacyId': oldProd.CYRILPHARMASAVE_PHARMACY_ID,
  'riepertpharmacy.medmeapp.ca-pharmacyId': oldProd.RIEPERTPHARMACY_PHARMACY_ID,
  'myerspharmacy.medmeapp.ca-pharmacyId': oldProd.MYERSPHARMACY_PHARMACY_ID,
  'nkshealthmississauga.medmeapp.ca-pharmacyId':
    oldProd.NKSHEALTHMISSISSAUGA_PHARMACY_ID,
  'pharmaciewendover.medmeapp.ca-pharmacyId':
    oldProd.PHARMACIEWENDOVER_PHARMACY_ID,
  'westendpharmasave.medmeapp.ca-pharmacyId':
    oldProd.WESTENDPHARMASAVE_PHARMACY_ID,
  'idastouffville.medmeapp.ca-pharmacyId': oldProd.IDASTOUFFVILLE_PHARMACY_ID,
  'youngspharmacyandhomecare.medmeapp.ca-pharmacyId':
    oldProd.YOUNGSPHARMACYANDHOMECARE_PHARMACY_ID,
  'beavertonida.medmeapp.ca-pharmacyId': oldProd.BEAVERTONIDA_PHARMACY_ID,
  'gravenhurstida.medmeapp.ca-pharmacyId': oldProd.GRAVENHURSTIDA_PHARMACY_ID,
  'morellisboxgrove.medmeapp.ca-pharmacyId':
    oldProd.MORELLISBOXGROVE_PHARMACY_ID,
  'meadowlands.medmeapp.ca-pharmacyId': oldProd.MEADOWLANDS_PHARMACY_ID,
  'ps647.medmeapp.ca-pharmacyId': oldProd.PS647_PHARMACY_ID,
  'carlspharmacy.medmeapp.ca-pharmacyId': oldProd.CARLSPHARMACY_PHARMACY_ID,
  'mountroyalpharmacy.medmeapp.ca-pharmacyId':
    oldProd.MOUNTROYALPHARMACY_PHARMACY_ID,
  'carriagecrossingpharmacy.medmeapp.ca-pharmacyId':
    oldProd.CARRIAGECROSSINGPHARMACY_PHARMACY_ID,
  'mountdalepharmacy.medmeapp.ca-pharmacyId':
    oldProd.MOUNTDALEPHARMACY_PHARMACY_ID,
  'kellysguardiandrugstore.medmeapp.ca-pharmacyId':
    oldProd.KELLYSGUARDIANDRUGSTORE_PHARMACY_ID,
  'morellis15yorkst.medmeapp.ca-pharmacyId':
    oldProd.MORELLIS15YORKST_PHARMACY_ID,
  'supercarepharmasave.medmeapp.ca-pharmacyId':
    oldProd.SUPERCAREPHARMASAVE_PHARMACY_ID,
  'centralpointpharmacy.medmeapp.ca-pharmacyId':
    oldProd.CENTRALPOINTPHARMACY_PHARMACY_ID,
  'epa.medmeapp.ca-pharmacyId': oldProd.EPA_PHARMACY_ID,
  'eustacepharmasave.medmeapp.ca-pharmacyId':
    oldProd.EUSTACEPHARMASAVE_PHARMACY_ID,
  'michaelspharmacy.medmeapp.ca-pharmacyId':
    oldProd.MICHAELSPHARMACY_PHARMACY_ID,
  'pollardida.medmeapp.ca-pharmacyId': oldProd.POLLARDIDA_PHARMACY_ID,
  'oakbaypharmasave.medmeapp.ca-pharmacyId':
    oldProd.OAKBAYPHARMASAVE_PHARMACY_ID,
  'tsa.medmeapp.ca-pharmacyId': oldProd.TSA_PHARMACY_ID,
  'medhealthpharmacy.medmeapp.ca-pharmacyId':
    oldProd.MEDHEALTHPHARMACY_PHARMACY_ID,
  'forestpharmasave.medmeapp.ca-pharmacyId':
    oldProd.FORESTPHARMASAVE_PHARMACY_ID,
  'heritagehills.medmeapp.ca-pharmacyId': oldProd.HERITAGEHILLS_PHARMACY_ID,
  'lackner.medmeapp.ca-pharmacyId': oldProd.LACKNER_PHARMACY_ID,
  'charlottecarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.CHARLOTTECAREPHARMACY_PHARMACY_ID,
  'gabrielpharmacy.medmeapp.ca-pharmacyId': oldProd.GABRIELPHARMACY_PHARMACY_ID,
  'inglesidepharmacy.medmeapp.ca-pharmacyId':
    oldProd.INGLESIDEPHARMACY_PHARMACY_ID,
  'longsaultpharmacy.medmeapp.ca-pharmacyId':
    oldProd.LONGSAULTPHARMACY_PHARMACY_ID,
  'accurxdrugstore.medmeapp.ca-pharmacyId': oldProd.ACCURXDRUGSTORE_PHARMACY_ID,
  'pscaledoneast.medmeapp.ca-pharmacyId': oldProd.PSCALEDONEAST_PHARMACY_ID,
  'cookstownpharmasave.medmeapp.ca-pharmacyId':
    oldProd.COOKSTOWNPHARMASAVE_PHARMACY_ID,
  'bancroftida.medmeapp.ca-pharmacyId': oldProd.BANCROFTIDA_PHARMACY_ID,
  'uptownpharmacy.medmeapp.ca-pharmacyId': oldProd.UPTOWNPHARMACY_PHARMACY_ID,
  'pelhamhillspharmacy.medmeapp.ca-pharmacyId':
    oldProd.PELHAMHILLSPHARMACY_PHARMACY_ID,
  'westlincolnpharmacy.medmeapp.ca-pharmacyId':
    oldProd.WESTLINCOLNPHARMACY_PHARMACY_ID,
  'yorkgateida.medmeapp.ca-pharmacyId': oldProd.YORKGATEIDA_PHARMACY_ID,
  'kingcitypharmacy.medmeapp.ca-pharmacyId':
    oldProd.KINGCITYPHARMACY_PHARMACY_ID,
  'johnstonspharmacy.medmeapp.ca-pharmacyId':
    oldProd.JOHNSTONSPHARMACY_PHARMACY_ID,
  'medicineshoppe129.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE129_PHARMACY_ID,
  'sunderlandpharmacy.medmeapp.ca-pharmacyId':
    oldProd.SUNDERLANDPHARMACY_PHARMACY_ID,
  'medionerx.medmeapp.ca-pharmacyId': oldProd.MEDIONERX_PHARMACY_ID,
  'rowanspharmasave.medmeapp.ca-pharmacyId':
    oldProd.ROWANSPHARMASAVE_PHARMACY_ID,
  'northgaetzpharmacy.medmeapp.ca-pharmacyId':
    oldProd.NORTHGAETZPHARMACY_PHARMACY_ID,
  'bankstreetpharmacy.medmeapp.ca-pharmacyId':
    oldProd.BANKSTREETPHARMACY_PHARMACY_ID,
  'portwellerpharmacy.medmeapp.ca-pharmacyId':
    oldProd.PORTWELLERPHARMACY_PHARMACY_ID,
  'highlandremedysrx.medmeapp.ca-pharmacyId':
    oldProd.HIGHLANDREMEDYSRX_PHARMACY_ID,
  'mmmcpharmacy.medmeapp.ca-pharmacyId': oldProd.MMMCPHARMACY_PHARMACY_ID,
  'walkerroadpharmasave.medmeapp.ca-pharmacyId':
    oldProd.WALKERROADPHARMASAVE_PHARMACY_ID,
  'universitypharmacy.medmeapp.ca-pharmacyId':
    oldProd.UNIVERSITYPHARMACY_PHARMACY_ID,
  'hiddenvalley.medmeapp.ca-pharmacyId': oldProd.HIDDENVALLEY_PHARMACY_ID,
  'neighbourhoodpharmacy.medmeapp.ca-pharmacyId':
    oldProd.NEIGHBOURHOODPHARMACY_PHARMACY_ID,
  'staynerpharmasave.medmeapp.ca-pharmacyId':
    oldProd.STAYNERPHARMASAVE_PHARMACY_ID,
  'rossmorepharmacy.medmeapp.ca-pharmacyId':
    oldProd.ROSSMOREPHARMACY_PHARMACY_ID,
  'madawaskapharmacy.medmeapp.ca-pharmacyId':
    oldProd.MADAWASKAPHARMACY_PHARMACY_ID,
  'courtlandpharmacy.medmeapp.ca-pharmacyId':
    oldProd.COURTLANDPHARMACY_PHARMACY_ID,
  'proactivepharmacy.medmeapp.ca-pharmacyId':
    oldProd.PROACTIVEPHARMACY_PHARMACY_ID,
  'livelypharmacy.medmeapp.ca-pharmacyId': oldProd.LIVELYPHARMACY_PHARMACY_ID,
  'ganpharmasave.medmeapp.ca-pharmacyId': oldProd.GANPHARMASAVE_PHARMACY_ID,
  'pwwpharmasave.medmeapp.ca-pharmacyId': oldProd.PWWPHARMASAVE_PHARMACY_ID,
  'remedysrxonkent.medmeapp.ca-pharmacyId': oldProd.REMEDYSRXONKENT_PHARMACY_ID,
  'adelpharmasave.medmeapp.ca-pharmacyId': oldProd.ADELPHARMASAVE_PHARMACY_ID,
  'colespharmacy.medmeapp.ca-pharmacyId': oldProd.COLESPHARMACY_PHARMACY_ID,
  'erinmillsida.medmeapp.ca-pharmacyId': oldProd.ERINMILLSIDA_PHARMACY_ID,
  'newcarepharmacy.medmeapp.ca-pharmacyId': oldProd.NEWCAREPHARMACY_PHARMACY_ID,
  'mdpharmasave.medmeapp.ca-pharmacyId': oldProd.MDPHARMASAVE_PHARMACY_ID,
  'tillsonburgcarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.TILLSONBURGCAREPHARMACY_PHARMACY_ID,
  'havelockguardian.medmeapp.ca-pharmacyId':
    oldProd.HAVELOCKGUARDIAN_PHARMACY_ID,
  'medicineshoppeonfort.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPEONFORT_PHARMACY_ID,
  'embrunida.medmeapp.ca-pharmacyId': oldProd.EMBRUNIDA_PHARMACY_ID,
  'neilsonpharmacy.medmeapp.ca-pharmacyId': oldProd.NEILSONPHARMACY_PHARMACY_ID,
  'bellpharmacy.medmeapp.ca-pharmacyId': oldProd.BELLPHARMACY_PHARMACY_ID,
  'foresthillpharmacy.medmeapp.ca-pharmacyId':
    oldProd.FORESTHILLPHARMACY_PHARMACY_ID,
  'pharmasaveosoyoos.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVEOSOYOOS_PHARMACY_ID,
  'belmontguardian.medmeapp.ca-pharmacyId': oldProd.BELMONTGUARDIAN_PHARMACY_ID,
  'marathonida.medmeapp.ca-pharmacyId': oldProd.MARATHONIDA_PHARMACY_ID,
  'stewartsguardian.medmeapp.ca-pharmacyId':
    oldProd.STEWARTSGUARDIAN_PHARMACY_ID,
  'nep.medmeapp.ca-pharmacyId': oldProd.NEWEDINBURGHGUARDIAN_PHARMACY_ID,
  'northcarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.NORTHCAREPHARMACY_PHARMACY_ID,
  'chelmsfordguardian.medmeapp.ca-pharmacyId':
    oldProd.CHELMSFORDGUARDIAN_PHARMACY_ID,
  'chelmsfordida.medmeapp.ca-pharmacyId': oldProd.CHELMSFORDIDA_PHARMACY_ID,
  'limogesguardian.medmeapp.ca-pharmacyId': oldProd.LIMOGESGUARDIAN_PHARMACY_ID,
  'medicineshoppe268.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPE268_PHARMACY_ID,
  'omcpharmacy.medmeapp.ca-pharmacyId': oldProd.OMCPHARMACY_PHARMACY_ID,
  'robertsonsida.medmeapp.ca-pharmacyId': oldProd.ROBERTSONSIDA_PHARMACY_ID,
  'wasagabeachida.medmeapp.ca-pharmacyId': oldProd.WASAGABEACHIDA_PHARMACY_ID,
  'medidrugsclareview.medmeapp.ca-pharmacyId':
    oldProd.MEDIDRUGSCLAREVIEW_PHARMACY_ID,
  'medidrugsmillcreek.medmeapp.ca-pharmacyId':
    oldProd.MEDIDRUGSMILLCREEK_PHARMACY_ID,
  'medicinepointpch.medmeapp.ca-pharmacyId':
    oldProd.MEDICINEPOINTPCH_PHARMACY_ID,
  'garneaupharmasave.medmeapp.ca-pharmacyId':
    oldProd.GARNEAUPHARMASAVE_PHARMACY_ID,
  'yorkcarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.YORKCAREPHARMACY_PHARMACY_ID,
  'clareviewdrugmart.medmeapp.ca-pharmacyId':
    oldProd.CLAREVIEWDRUGMART_PHARMACY_ID,
  'innovationida.medmeapp.ca-pharmacyId': oldProd.INNOVATIONIDA_PHARMACY_ID,
  'kenronfoothills.medmeapp.ca-pharmacyId': oldProd.KENRONFOOTHILLS_PHARMACY_ID,
  'ms393.medmeapp.ca-pharmacyId': oldProd.MS393_PHARMACY_ID,
  'copperridgeida.medmeapp.ca-pharmacyId': oldProd.COPPERRIDGEIDA_PHARMACY_ID,
  'shawnessypharmacy.medmeapp.ca-pharmacyId':
    oldProd.SHAWNESSYPHARMACY_PHARMACY_ID,
  'dundalkpharmacy.medmeapp.ca-pharmacyId': oldProd.DUNDALKPHARMACY_PHARMACY_ID,
  'northeyremedysrx.medmeapp.ca-pharmacyId':
    oldProd.NORTHEYREMEDYSRX_PHARMACY_ID,
  'carewellpharmacy.medmeapp.ca-pharmacyId':
    oldProd.CAREWELLPHARMACY_PHARMACY_ID,
  'bramqueenpharmacy.medmeapp.ca-pharmacyId':
    oldProd.BRAMQUEENPHARMACY_PHARMACY_ID,
  'pineplazaida.medmeapp.ca-pharmacyId': oldProd.PINEPLAZAIDA_PHARMACY_ID,
  'cridgepharmacydowntown.medmeapp.ca-pharmacyId':
    oldProd.CRIDGEPHARMACYDOWNTOWN_PHARMACY_ID,
  'cridgepharmacykeating.medmeapp.ca-pharmacyId':
    oldProd.CRIDGEPHARMACYKEATING_PHARMACY_ID,
  'kenronrockyview.medmeapp.ca-pharmacyId': oldProd.KENRONROCKYVIEW_PHARMACY_ID,
  'limeridgepharmacy.medmeapp.ca-pharmacyId':
    oldProd.LIMERIDGEPHARMACY_PHARMACY_ID,
  'careida.medmeapp.ca-pharmacyId': oldProd.CAREIDA_PHARMACY_ID,
  'nkshealthlondon.medmeapp.ca-pharmacyId': oldProd.NKSHEALTHLONDON_PHARMACY_ID,
  'mellpharmacy.medmeapp.ca-pharmacyId': oldProd.MELLPHARMACY_PHARMACY_ID,
  'peterstreetpharmacy.medmeapp.ca-pharmacyId':
    oldProd.PETERSTREETPHARMACY_PHARMACY_ID,
  'phcp2015.medmeapp.ca-pharmacyId': oldProd.PHCP2015_PHARMACY_ID,
  'highlandgreenremedysrx.medmeapp.ca-pharmacyId':
    oldProd.HIGHLANDGREENREMEDYSRX_PHARMACY_ID,
  'qualicumpharmasave.medmeapp.ca-pharmacyId':
    oldProd.QUALICUMPHARMASAVE_PHARMACY_ID,
  'ugpharmacy.medmeapp.ca-pharmacyId': oldProd.UGPHARMACY_PHARMACY_ID,
  'medicineshoppebyhospital.medmeapp.ca-pharmacyId':
    oldProd.MEDICINESHOPPEBYHOSPITAL_PHARMACY_ID,
  'ghcpharmacy.medmeapp.ca-pharmacyId': oldProd.GHCPHARMACY_PHARMACY_ID,
  'laclabichevaluedrugmart.medmeapp.ca-pharmacyId':
    oldProd.LACLABICHEVALUEDRUGMART_PHARMACY_ID,
  'primecarepharmacy.medmeapp.ca-pharmacyId':
    oldProd.PRIMECAREPHARMACY_PHARMACY_ID,
  'millwoodsidapharmacy.medmeapp.ca-pharmacyId':
    oldProd.MILLWOODSIDAPHARMACY_PHARMACY_ID,
  'cordovabaypharmacy.medmeapp.ca-pharmacyId':
    oldProd.CORDOVABAYPHARMACY_PHARMACY_ID,
  'conistonguardian.medmeapp.ca-pharmacyId':
    oldProd.CONISTONGUARDIAN_PHARMACY_ID,
  'castledownsarp.medmeapp.ca-pharmacyId': oldProd.CASTLEDOWNSARP_PHARMACY_ID,
  'ps242.medmeapp.ca-pharmacyId': oldProd.PS242_PHARMACY_ID,
  'pharmasaveturnervalley.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVETURNERVALLEY_PHARMACY_ID,
  'bvpharmacy.medmeapp.ca-pharmacyId': oldProd.BVPHARMACY_PHARMACY_ID,
  'hcp.medmeapp.ca-pharmacyId': oldProd.HCP_PHARMACY_ID,
  'parkedalepharmacy.medmeapp.ca-pharmacyId':
    oldProd.PARKEDALEPHARMACY_PHARMACY_ID,
  'draytonpharmacy.medmeapp.ca-pharmacyId': oldProd.DRAYTONPHARMACY_PHARMACY_ID,
  'westboropharmasave.medmeapp.ca-pharmacyId':
    oldProd.WESTBOROPHARMASAVE_PHARMACY_ID,
  'perthpharmasave.medmeapp.ca-pharmacyId': oldProd.PERTHPHARMASAVE_PHARMACY_ID,
  'pharmasave9662.medmeapp.ca-pharmacyId': oldProd.PHARMASAVE9662_PHARMACY_ID,
  'dundasneyagawapharmacy.medmeapp.ca-pharmacyId':
    oldProd.DUNDASNEYAGAWAPHARMACY_PHARMACY_ID,
  'courticepharmasave.medmeapp.ca-pharmacyId':
    oldProd.COURTICEPHARMASAVE_PHARMACY_ID,
  'westminster.medmeapp.ca-pharmacyId': oldProd.WESTMINSTER_PHARMACY_ID,
  'mainstpharmasave.medmeapp.ca-pharmacyId':
    oldProd.MAINSTPHARMASAVE_PHARMACY_ID,
  'totalhealthpharmacymilton.medmeapp.ca-pharmacyId':
    oldProd.TOTALHEALTHPHARMACYMILTON_PHARMACY_ID,
  'mooneysbaypharmasave.medmeapp.ca-pharmacyId':
    oldProd.MOONEYSBAYPHARMASAVE_PHARMACY_ID,
  'hoganpharmacy.medmeapp.ca-pharmacyId': oldProd.HOGANPHARMACY_PHARMACY_ID,
  'prohealthbarrie.medmeapp.ca-pharmacyId': oldProd.PROHEALTHBARRIE_PHARMACY_ID,
  'firstplacepharmacy.medmeapp.ca-pharmacyId':
    oldProd.FIRSTPLACEPHARMACY_PHARMACY_ID,
  'cumberlandpharmasave.medmeapp.ca-pharmacyId':
    oldProd.CUMBERLANDPHARMASAVE_PHARMACY_ID,
  'ariapharmacy.medmeapp.ca-pharmacyId': oldProd.ARIAPHARMACY_PHARMACY_ID,
  'nexuspharmacy.medmeapp.ca-pharmacyId': oldProd.NEXUSPHARMACY_PHARMACY_ID,
  'mountainsidepharmacy.medmeapp.ca-pharmacyId':
    oldProd.MOUNTAINSIDEPHARMACY_PHARMACY_ID,
  'brameastpharmacy.medmeapp.ca-pharmacyId':
    oldProd.BRAMEASTPHARMACY_PHARMACY_ID,
  'mcintyreida.medmeapp.ca-pharmacyId': oldProd.MCINTYREIDA_PHARMACY_ID,
  'swanlakepharmacy.medmeapp.ca-pharmacyId':
    oldProd.SWANLAKEPHARMACY_PHARMACY_ID,
  'brownspharmasave.medmeapp.ca-pharmacyId':
    oldProd.BROWNSPHARMASAVE_PHARMACY_ID,
  'idauxbridge.medmeapp.ca-pharmacyId': oldProd.IDAUXBRIDGE_PHARMACY_ID,
  'stonechurchpharmacy.medmeapp.ca-pharmacyId':
    oldProd.STONECHURCHPHARMACY_PHARMACY_ID,
  'coulterspharmacy.medmeapp.ca-pharmacyId':
    oldProd.COULTERSPHARMACY_PHARMACY_ID,
  'yorksuperpharmacy.medmeapp.ca-pharmacyId':
    oldProd.YORKSUPERPHARMACY_PHARMACY_ID,
  'cityviewpharmacy.medmeapp.ca-pharmacyId':
    oldProd.CITYVIEWPHARMACY_PHARMACY_ID,
  'jacksonsguardian.medmeapp.ca-pharmacyId':
    oldProd.JACKSONSGUARDIAN_PHARMACY_ID,
  'sgpharm.medmeapp.ca-pharmacyId': oldProd.SGPHARM_PHARMACY_ID,
  'peopleschoicedrugmart.medmeapp.ca-pharmacyId':
    oldProd.PEOPLESCHOICEDRUGMART_PHARMACY_ID,
  'wynpluspharmacy.medmeapp.ca-pharmacyId': oldProd.WYNPLUSPHARMACY_PHARMACY_ID,
  'surecare.medmeapp.ca-pharmacyId': oldProd.SURECARE_PHARMACY_ID,
  'erincreek.medmeapp.ca-pharmacyId': oldProd.ERINCREEK_PHARMACY_ID,
  'plaza67.medmeapp.ca-pharmacyId': oldProd.PLAZA67_PHARMACY_ID,
  'elginmallpharmasave.medmeapp.ca-pharmacyId':
    oldProd.ELGINMALLPHARMASAVE_PHARMACY_ID,
  'nicklespharmacy.medmeapp.ca-pharmacyId': oldProd.NICKLESPHARMACY_PHARMACY_ID,
  'portrowanpharmasave.medmeapp.ca-pharmacyId':
    oldProd.PORTROWANPHARMASAVE_PHARMACY_ID,
  'evanspharmasave.medmeapp.ca-pharmacyId': oldProd.EVANSPHARMASAVE_PHARMACY_ID,
  'rxdrugmartlindsay.medmeapp.ca-pharmacyId':
    oldProd.RXDRUGMARTLINDSAY_PHARMACY_ID,
  'walthersida.medmeapp.ca-pharmacyId': oldProd.WALTHERSIDA_PHARMACY_ID,
  'portlandpharmasave.medmeapp.ca-pharmacyId':
    oldProd.PORTLANDPHARMASAVE_PHARMACY_ID,
  'bayviewidapharmacy.medmeapp.ca-pharmacyId':
    oldProd.BAYVIEWIDAPHARMACY_PHARMACY_ID,
  'merrettspharmacy.medmeapp.ca-pharmacyId':
    oldProd.MERRETTSPHARMACY_PHARMACY_ID,
  'panoramahillspharmacy.medmeapp.ca-pharmacyId':
    oldProd.PANORAMAHILLSPHARMACY_PHARMACY_ID,
  'kwmedicineshoppe.medmeapp.ca-pharmacyId':
    oldProd.KWMEDICINESHOPPE_PHARMACY_ID,
  'gatewaymedicalplexpharmacy.medmeapp.ca-pharmacyId':
    oldProd.GATEWAYMEDICALPLEXPHARMACY_PHARMACY_ID,
  'rxdrugmartdurham.medmeapp.ca-pharmacyId':
    oldProd.RXDRUGMARTDURHAM_PHARMACY_ID,
  'sutherlandpharmacy.medmeapp.ca-pharmacyId':
    oldProd.SUTHERLANDPHARMACY_PHARMACY_ID,
  'pharmasavewestedmontonmall.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVEWESTEDMONTONMALL_PHARMACY_ID,
  'guardianmountainview.medmeapp.ca-pharmacyId':
    oldProd.GUARDIANMOUNTAINVIEW_PHARMACY_ID,
  'ps931.medmeapp.ca-pharmacyId': oldProd.PS931_PHARMACY_ID,
  'cooksqueensbushpharmacy.medmeapp.ca-pharmacyId':
    oldProd.COOKSQUEENSBUSHPHARMACY_PHARMACY_ID,
  'lacostepharmacy.medmeapp.ca-pharmacyId': oldProd.LACOSTEPHARMACY_PHARMACY_ID,
  'fairviewremedys.medmeapp.ca-pharmacyId': oldProd.FAIRVIEWREMEDYS_PHARMACY_ID,
  'tottenhampharmasave.medmeapp.ca-pharmacyId':
    oldProd.TOTTENHAMPHARMASAVE_PHARMACY_ID,
  'oshawadiscountpharmacy.medmeapp.ca-pharmacyId':
    oldProd.OSHAWADISCOUNTPHARMACY_PHARMACY_ID,
  'pharmasaveaurora.medmeapp.ca-pharmacyId':
    oldProd.PHARMASAVEAURORA_PHARMACY_ID,
  'jamespotterroadpharmacy.medmeapp.ca-pharmacyId':
    oldProd.JAMESPOTTERROADPHARMACY_PHARMACY_ID,
  'harrisvillepharmacy.medmeapp.ca-pharmacyId':
    oldProd.HARRISVILLEPHARMACY_PHARMACY_ID,
  'superrxpharmacy.medmeapp.ca-pharmacyId': oldProd.SUPERRXPHARMACY_PHARMACY_ID,
  'msp368.medmeapp.ca-pharmacyId': oldProd.MSP368_PHARMACY_ID,
  'bishoppharmacy.medmeapp.ca-pharmacyId': oldProd.BISHOPPHARMACY_PHARMACY_ID,
  'bramptoncommunitypharmacy.medmeapp.ca-pharmacyId':
    oldProd.BRAMPTONCOMMUNITYPHARMACY_PHARMACY_ID,
  'dukepharmacy.medmeapp.ca-pharmacyId': oldProd.DUKEPHARMACY_PHARMACY_ID,
  'creditviewida.medmeapp.ca-pharmacyId': oldProd.CREDITVIEWIDA_PHARMACY_ID,
  'whitneypharmacy.medmeapp.ca-pharmacyId': oldProd.WHITNEYPHARMACY_PHARMACY_ID,
  'markhamackpharmacy.medmeapp.ca-pharmacyId':
    oldProd.MARKHAMACKPHARMACY_PHARMACY_ID,
  'sundrefamilypharmacy.medmeapp.ca-pharmacyId':
    oldProd.SUNDREFAMILYPHARMACY_PHARMACY_ID,
  'cobypharmacy.medmeapp.ca-pharmacyId': oldProd.COBYPHARMACY_PHARMACY_ID,
  'prod-mb.medmeapp.ca-pharmacyId': env.PRODMB_PHARMACY_ID,
  'prod-nb.medmeapp.ca-pharmacyId': env.PRODNB_PHARMACY_ID,
  'prod-on.medmeapp.ca-pharmacyId': env.PRODON_PHARMACY_ID,
  'prod-sk.medmeapp.ca-pharmacyId': env.PRODSK_PHARMACY_ID,
  'prod-nl.medmeapp.ca-pharmacyId': env.PRODNL_PHARMACY_ID,
  'prod-pe.medmeapp.ca-pharmacyId': env.PRODPE_PHARMACY_ID,
  'prod-ns.medmeapp.ca-pharmacyId': env.PRODNS_PHARMACY_ID,
  'prod-ab.medmeapp.ca-pharmacyId': env.PRODAB_PHARMACY_ID,
  'yorktonpharmasave.medmeapp.ca-pharmacyId': env.YORKTONPHARMASAVE_PHARMACY_ID,
  'potterswheel.medmeapp.ca-pharmacyId': env.POTTERSWHEEL_PHARMACY_ID,
  'heritageidarx.medmeapp.ca-pharmacyId': env.HERITAGEIDARX_PHARMACY_ID,
  'michaelspharmasave.medmeapp.ca-pharmacyId':
    env.MICHAELSPHARMASAVE_PHARMACY_ID,
  'primecare.medmeapp.ca-pharmacyId': env.PRIMECARE_PHARMACY_ID,
  'pharmasavegordonpharmacy.medmeapp.ca-pharmacyId':
    env.PHARMASAVEGORDONPHARMACY_PHARMACY_ID,
  'auroraidapharmacy.medmeapp.ca-pharmacyId': env.AURORAIDAPHARMACY_PHARMACY_ID,
  'thorncliffemedicineshoppe.medmeapp.ca-pharmacyId':
    env.THORNCLIFFEMEDICINESHOPPE_PHARMACY_ID,
  'southlandcoop.medmeapp.ca-pharmacyId': env.SOUTHLANDCOOP_PHARMACY_ID,
  'medicalbuildingpharmacy.medmeapp.ca-pharmacyId':
    env.MEDICALBUILDINGPHARMACY_PHARMACY_ID,
  'transconaidapharmacy.medmeapp.ca-pharmacyId':
    env.TRANSCONAIDAPHARMACY_PHARMACY_ID,
  'coleman.medmeapp.ca-pharmacyId': env.COLEMAN_PHARMACY_ID,
  'cookswaterloo.medmeapp.ca-pharmacyId': env.COOKSWATERLOO_PHARMACY_ID,
  'sloaneguardian.medmeapp.ca-pharmacyId': env.SLOANEGUARDIAN_PHARMACY_ID,
  'pharmasave467.medmeapp.ca-pharmacyId': env.PHARMASAVE467_PHARMACY_ID,
  'macarthurdrugs.medmeapp.ca-pharmacyId': env.MACARTHURDRUGS_PHARMACY_ID,
  'wincarepharmacy.medmeapp.ca-pharmacyId': env.WINCAREPHARMACY_PHARMACY_ID,
  'sherbrooke.medmeapp.ca-pharmacyId': env.SHERBROOKE_PHARMACY_ID,
  'gibbonsremedysrx.medmeapp.ca-pharmacyId': env.GIBBONSREMEDYSRX_PHARMACY_ID,
  'southottawapharmacy.medmeapp.ca-pharmacyId':
    env.SOUTHOTTAWAPHARMACY_PHARMACY_ID,
  'deerparkida.medmeapp.ca-pharmacyId': env.DEERPARKIDA_PHARMACY_ID,
  'maidstoneida.medmeapp.ca-pharmacyId': env.MAIDSTONEIDA_PHARMACY_ID,
  'radiancepharmacy.medmeapp.ca-pharmacyId': env.RADIANCEPHARMACY_PHARMACY_ID,
  'riverbendcooppharmacy.medmeapp.ca-pharmacyId':
    env.RIVERBENDCOOPPHARMACY_PHARMACY_ID,
  'madillsidapharmacy.medmeapp.ca-pharmacyId':
    env.MADILLSIDAPHARMACY_PHARMACY_ID,
  'advancedpharmacy.medmeapp.ca-pharmacyId': env.ADVANCEDPHARMACY_PHARMACY_ID,
  'roulstonsportdover.medmeapp.ca-pharmacyId':
    env.ROULSTONSPORTDOVER_PHARMACY_ID,
  'cmp.medmeapp.ca-pharmacyId': env.CMP_PHARMACY_ID,
  'littlebritainpharmacy.medmeapp.ca-pharmacyId':
    env.LITTLEBRITAINPHARMACY_PHARMACY_ID,
  'sallyspharmasave.medmeapp.ca-pharmacyId': env.SALLYSPHARMASAVE_PHARMACY_ID,
  'riverterracepharmacy.medmeapp.ca-pharmacyId':
    env.RIVERTERRACEPHARMACY_PHARMACY_ID,
  'academypharmacy.medmeapp.ca-pharmacyId': env.ACADEMYPHARMACY_PHARMACY_ID,
  'bridlepath.medmeapp.ca-pharmacyId': env.BRIDLEPATH_PHARMACY_ID,
  'idarichmondmedical.medmeapp.ca-pharmacyId':
    env.IDARICHMONDMEDICAL_PHARMACY_ID,
  'mckenziesida.medmeapp.ca-pharmacyId': env.MCKENZIESIDA_PHARMACY_ID,
  'synergyremedysrx.medmeapp.ca-pharmacyId': env.SYNERGYREMEDYSRX_PHARMACY_ID,
  'healthhubpharmacy.medmeapp.ca-pharmacyId': env.HEALTHHUBPHARMACY_PHARMACY_ID,
  'caledoniapharmachoice.medmeapp.ca-pharmacyId': env.CALEDONIA_PHARMACY_ID,
  'homesteadcooppharmacy.medmeapp.ca-pharmacyId':
    env.HOMESTEADCOOPPHARMACY_PHARMACY_ID,
  'warkworthpharmacy.medmeapp.ca-pharmacyId': env.WARKWORTHPHARMACY_PHARMACY_ID,
  'medprohealth.medmeapp.ca-pharmacyId': env.MEDPROHEALTH_PHARMACY_ID,
  'dalecliffpharmacy.medmeapp.ca-pharmacyId': env.DALECLIFFPHARMACY_PHARMACY_ID,
  'michaelspharmasave9671.medmeapp.ca-pharmacyId':
    env.MICHAELSPHARMASAVE9671_PHARMACY_ID,
  'papharmacy.medmeapp.ca-pharmacyId': env.PAPHARMACY_PHARMACY_ID,
  'carpenterguardian.medmeapp.ca-pharmacyId': env.CARPENTERGUARDIAN_PHARMACY_ID,
  'marshallpark.medmeapp.ca-pharmacyId': env.MARSHALLPARK_PHARMACY_ID,
  'owensoundida.medmeapp.ca-pharmacyId': env.OWENSOUNDIDA_PHARMACY_ID,
  'pharmasavenorthbattleford.medmeapp.ca-pharmacyId':
    env.PHARMASAVENORTHBATTLEFORD_PHARMACY_ID,
  'pharmasavebarriedowntown.medmeapp.ca-pharmacyId':
    env.PHARMASAVEBARRIEDOWNTOWN_PHARMACY_ID,
  'pharmasavebayview.medmeapp.ca-pharmacyId': env.PHARMASAVEBAYVIEW_PHARMACY_ID,
  'pharmasavetrenton.medmeapp.ca-pharmacyId': env.PHARMASAVETRENTON_PHARMACY_ID,
  'bellevillepharmacy.medmeapp.ca-pharmacyId':
    env.BELLEVILLEPHARMACY_PHARMACY_ID,
  'stcharlespharmacy.medmeapp.ca-pharmacyId': env.STCHARLESPHARMACY_PHARMACY_ID,
  'memorialpharmasave.medmeapp.ca-pharmacyId':
    env.MEMORIALPHARMASAVE_PHARMACY_ID,
  'johnstonspharmacyhastings.medmeapp.ca-pharmacyId':
    env.JOHNSTONSPHARMACYHASTINGS_PHARMACY_ID,
  'fittonspharmacy.medmeapp.ca-pharmacyId': env.FITTONSPHARMACY_PHARMACY_ID,
  'reliancedrugmart.medmeapp.ca-pharmacyId': env.RELIANCEDRUGMART_PHARMACY_ID,
  'riversdalepharmacy.medmeapp.ca-pharmacyId':
    env.RIVERSDALEPHARMACY_PHARMACY_ID,
  'pinegrovepharmacy.medmeapp.ca-pharmacyId': env.PINEGROVEPHARMACY_PHARMACY_ID,
  'londoncarepharmacy.medmeapp.ca-pharmacyId':
    env.LONDONCAREPHARMACY_PHARMACY_ID,
  'pharmasavesimcoe.medmeapp.ca-pharmacyId': env.PHARMASAVESIMCOE_PHARMACY_ID,
  'granthampharmacy.medmeapp.ca-pharmacyId': env.GRANTHAMPHARMACY_PHARMACY_ID,
  'huronstreetpharmasave.medmeapp.ca-pharmacyId':
    env.HURONSTREETPHARMASAVE_PHARMACY_ID,
  'hillsidepharmasave.medmeapp.ca-pharmacyId':
    env.HILLSIDEPHARMASAVE_PHARMACY_ID,
  'niagararemedy.medmeapp.ca-pharmacyId': env.NIAGARAREMEDY_PHARMACY_ID,
  'pharmasaveallandale.medmeapp.ca-pharmacyId':
    env.PHARMASAVEALLANDALE_PHARMACY_ID,
  'grandpharmacy.medmeapp.ca-pharmacyId': env.GRANDPHARMACY_PHARMACY_ID,
  'ucs.medmeapp.ca-pharmacyId': env.UCS_PHARMACY_ID,
  'riverview.medmeapp.ca-pharmacyId': env.RIVERVIEW_PHARMACY_ID,
  'frontlinepharmacy.medmeapp.ca-pharmacyId': env.FRONTLINEPHARMACY_PHARMACY_ID,
  'lc.medmeapp.ca-pharmacyId': env.LC_PHARMACY_ID,
  'victoriasquare.medmeapp.ca-pharmacyId': env.VICTORIASQUARE_PHARMACY_ID,
  'behealthypharmacy.medmeapp.ca-pharmacyId': env.BEHEALTHYPHARMACY_PHARMACY_ID,
  'firstavepharmacy.medmeapp.ca-pharmacyId': env.FIRSTAVEPHARMACY_PHARMACY_ID,
  'maindrugmart.medmeapp.ca-pharmacyId': env.MAINDRUGMART_PHARMACY_ID,
  'pharmasavebathurst.medmeapp.ca-pharmacyId':
    env.PHARMASAVEBATHURST_PHARMACY_ID,
  'theapothecaryshop.medmeapp.ca-pharmacyId': env.THEAPOTHECARYSHOP_PHARMACY_ID,
  'medtrustpharmasave.medmeapp.ca-pharmacyId':
    env.MEDTRUSTPHARMASAVE_PHARMACY_ID,
  'pharmasaveadelaidenorth.medmeapp.ca-pharmacyId':
    env.PHARMASAVEADELAIDENORTH_PHARMACY_ID,
  'markhamhealthplex.medmeapp.ca-pharmacyId': env.MARKHAMHEALTHPLEX_PHARMACY_ID,
  'cochranepharmacy.medmeapp.ca-pharmacyId': env.COCHRANEPHARMACY_PHARMACY_ID,
  'huronpharmasave.medmeapp.ca-pharmacyId': env.HURONPHARMASAVE_PHARMACY_ID,
  '1355pharmasave.medmeapp.ca-pharmacyId': env['1355PHARMASAVE_PHARMACY_ID'],
  'queenpharmacy.medmeapp.ca-pharmacyId': env.QUEENPHARMACY_PHARMACY_ID,
  'rxdrugmartdeepriver.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTDEEPRIVER_PHARMACY_ID,
  'rxdrugmartwingham.medmeapp.ca-pharmacyId': env.RXDRUGMARTWINGHAM_PHARMACY_ID,
  'rxdrugmartiroquoisfalls.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTIROQUOISFALLS_PHARMACY_ID,
  'rxdrugmartsturgeonfalls.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTSTURGEONFALLS_PHARMACY_ID,
  'rxdrugmartstayner.medmeapp.ca-pharmacyId': env.RXDRUGMARTSTAYNER_PHARMACY_ID,
  'westhillpharmacy.medmeapp.ca-pharmacyId': env.WESTHILLPHARMACY_PHARMACY_ID,
  'medisensepharmacy.medmeapp.ca-pharmacyId': env.MEDISENSEPHARMACY_PHARMACY_ID,
  'pioneercooppharmacy.medmeapp.ca-pharmacyId':
    env.PIONEERCOOPPHARMACY_PHARMACY_ID,
  'interpharmacy.medmeapp.ca-pharmacyId': env.INTERPHARMACY_PHARMACY_ID,
  'thoroldmedicalpharmacy.medmeapp.ca-pharmacyId':
    env.THOROLDMEDICALPHARMACY_PHARMACY_ID,
  'firstmedicalpharmacy.medmeapp.ca-pharmacyId':
    env.FIRSTMEDICALPHARMACY_PHARMACY_ID,
  'devonpharmasave.medmeapp.ca-pharmacyId': env.DEVONPHARMASAVE_PHARMACY_ID,
  'medicalpharmacylondon.medmeapp.ca-pharmacyId':
    env.MEDICALPHARMACYLONDON_PHARMACY_ID,
  'letitiapharmacy.medmeapp.ca-pharmacyId': env.LETITIAPHARMACY_PHARMACY_ID,
  'healthcareidapharmacy.medmeapp.ca-pharmacyId':
    env.HEALTHCAREIDAPHARMACY_PHARMACY_ID,
  'medicineshoppe274.medmeapp.ca-pharmacyId': env.MEDICINESHOPPE274_PHARMACY_ID,
  'grandebankspharmacy.medmeapp.ca-pharmacyId':
    env.GRANDEBANKSPHARMACY_PHARMACY_ID,
  'valest.medmeapp.ca-pharmacyId': env.VALEST_PHARMACY_ID,
  'alconapharmacy2.medmeapp.ca-pharmacyId': env.ALCONAPHARMACY2_PHARMACY_ID,
  'martingrovepharmacy.medmeapp.ca-pharmacyId':
    env.MARTINGROVEPHARMACY_PHARMACY_ID,
  'theclinicpharmacy.medmeapp.ca-pharmacyId': env.THECLINICPHARMACY_PHARMACY_ID,
  'healthcaremart.medmeapp.ca-pharmacyId': env.HEALTHCAREMART_PHARMACY_ID,
  'wellandpharmsave.medmeapp.ca-pharmacyId': env.WELLANDPHARMSAVE_PHARMACY_ID,
  'singarspharmacy.medmeapp.ca-pharmacyId': env.SINGARSPHARMACY_PHARMACY_ID,
  'healthcheckpharmacy.medmeapp.ca-pharmacyId':
    env.HEALTHCHECKPHARMACY_PHARMACY_ID,
  'mcintyrepharmacy.medmeapp.ca-pharmacyId': env.MCINTYREPHARMACY_PHARMACY_ID,
  'strasburgcrossingpharmasave.medmeapp.ca-pharmacyId':
    env.STRASBURGCROSSINGPHARMASAVE_PHARMACY_ID,
  'bloorwestpharmacy.medmeapp.ca-pharmacyId': env.BLOORWESTPHARMACY_PHARMACY_ID,
  'pharmaciealfred.medmeapp.ca-pharmacyId': env.PHARMACIEALFRED_PHARMACY_ID,
  'pharmacybygrange.medmeapp.ca-pharmacyId': env.PHARMACYBYGRANGE_PHARMACY_ID,
  'kenmountpharmasave.medmeapp.ca-pharmacyId':
    env.KENMOUNTPHARMASAVE_PHARMACY_ID,
  'professionalmedicalpharmacy.medmeapp.ca-pharmacyId':
    env.PROFESSIONALMEDICALPHARMACY_PHARMACY_ID,
  'cooksnewhamburg.medmeapp.ca-pharmacyId': env.COOKSNEWHAMBURG_PHARMACY_ID,
  'westoncarepharmacy.medmeapp.ca-pharmacyId':
    env.WESTONCAREPHARMACY_PHARMACY_ID,
  'dannyswellnesspharmacy.medmeapp.ca-pharmacyId':
    env.DANNYSWELLNESSPHARMACY_PHARMACY_ID,
  'ortonparkpharmacy.medmeapp.ca-pharmacyId': env.ORTONPARKPHARMACY_PHARMACY_ID,
  'queeneastmedicalpharmacy.medmeapp.ca-pharmacyId':
    env.QUEENEASTMEDICALPHARMACY_PHARMACY_ID,
  'quinteidapharmacy.medmeapp.ca-pharmacyId': env.QUINTEIDAPHARMACY_PHARMACY_ID,
  'littlecurrentguardian.medmeapp.ca-pharmacyId':
    env.LITTLECURRENTGUARDIAN_PHARMACY_ID,
  'cookskingsbury.medmeapp.ca-pharmacyId': env.COOKSKINGSBURY_PHARMACY_ID,
  'avapharmacy.medmeapp.ca-pharmacyId': env.AVAPHARMACY_PHARMACY_ID,
  '1909ida.medmeapp.ca-pharmacyId': env['1909IDA_PHARMACY_ID'],
  'healthhubyongepharmacy.medmeapp.ca-pharmacyId':
    env.HEALTHHUBYONGEPHARMACY_PHARMACY_ID,
  'lakewoodpeoplespharmacy.medmeapp.ca-pharmacyId':
    env.LAKEWOODPEOPLESPHARMACY_PHARMACY_ID,
  'medicinecabinetpharmacy.medmeapp.ca-pharmacyId':
    env.MEDICINECABINETPHARMACY_PHARMACY_ID,
  'chathampharmasave.medmeapp.ca-pharmacyId': env.CHATHAMPHARMASAVE_PHARMACY_ID,
  'sheppardcommunitypharmacy.medmeapp.ca-pharmacyId':
    env.SHEPPARDCOMMUNITYPHARMACY_PHARMACY_ID,
  'emeraldpharmacy.medmeapp.ca-pharmacyId': env.EMERALDPHARMACY_PHARMACY_ID,
  'rocklandpharmacy.medmeapp.ca-pharmacyId': env.ROCKLANDPHARMACY_PHARMACY_ID,
  'graftonpharmacy.medmeapp.ca-pharmacyId': env.GRAFTONPHARMACY_PHARMACY_ID,
  'newcaledoniapharmacy.medmeapp.ca-pharmacyId':
    env.NEWCALEDONIAPHARMACY_PHARMACY_ID,
  'healthsense.medmeapp.ca-pharmacyId': env.HEALTHSENSE_PHARMACY_ID,
  'northendfarmacia.medmeapp.ca-pharmacyId': env.NORTHENDFARMACIA_PHARMACY_ID,
  'riverroadpharmasave.medmeapp.ca-pharmacyId':
    env.RIVERROADPHARMASAVE_PHARMACY_ID,
  '9elevendrugmart.medmeapp.ca-pharmacyId': env['9ELEVENDRUGMART_PHARMACY_ID'],
  'bentonmedicalpharmacy.medmeapp.ca-pharmacyId':
    env.BENTONMEDICALPHARMACY_PHARMACY_ID,
  'stroudguardian.medmeapp.ca-pharmacyId': env.STROUDGUARDIAN_PHARMACY_ID,
  'somersetdrugs.medmeapp.ca-pharmacyId': env.SOMERSETDRUGS_PHARMACY_ID,
  'robinsonspharmasave.medmeapp.ca-pharmacyId':
    env.ROBINSONSPHARMASAVE_PHARMACY_ID,
  'harmonyvalleypharmacy.medmeapp.ca-pharmacyId':
    env.HARMONYVALLEYPHARMACY_PHARMACY_ID,
  'rosedaleidapharmacy.medmeapp.ca-pharmacyId':
    env.ROSEDALEIDAPHARMACY_PHARMACY_ID,
  'medicineshoppe246.medmeapp.ca-pharmacyId': env.MEDICINESHOPPE246_PHARMACY_ID,
  'lighthousepharmacy.medmeapp.ca-pharmacyId':
    env.LIGHTHOUSEPHARMACY_PHARMACY_ID,
  'ottawasouthpharmasave.medmeapp.ca-pharmacyId':
    env.OTTAWASOUTHPHARMASAVE_PHARMACY_ID,
  'durhamapothecary.medmeapp.ca-pharmacyId': env.DURHAMAPOTHECARY_PHARMACY_ID,
  'campuspharmasave.medmeapp.ca-pharmacyId': env.CAMPUSPHARMASAVE_PHARMACY_ID,
  'medicineshoppe215.medmeapp.ca-pharmacyId': env.MEDICINESHOPPE215_PHARMACY_ID,
  'barrhavenmedicalpharmacy.medmeapp.ca-pharmacyId':
    env.BARRHAVENMEDICALPHARMACY_PHARMACY_ID,
  'highlandplazapharmacy.medmeapp.ca-pharmacyId':
    env.HIGHLANDPLAZAPHARMACY_PHARMACY_ID,
  'islingtonidapharmacy.medmeapp.ca-pharmacyId':
    env.ISLINGTONIDAPHARMACY_PHARMACY_ID,
  'forddrivepharmacy.medmeapp.ca-pharmacyId': env.FORDDRIVEPHARMACY_PHARMACY_ID,
  'keenedrugmart.medmeapp.ca-pharmacyId': env.KEENEDRUGMART_PHARMACY_ID,
  'carnduffdrugmart.medmeapp.ca-pharmacyId': env.CARNDUFFDRUGMART_PHARMACY_ID,
  'vinapharmacy.medmeapp.ca-pharmacyId': env.VINAPHARMACY_PHARMACY_ID,
  'mallorytownpharmacy.medmeapp.ca-pharmacyId':
    env.MALLORYTOWNPHARMACY_PHARMACY_ID,
  'medicineshoppeoakville.medmeapp.ca-pharmacyId':
    env.MEDICINESHOPPEOAKVILLE_PHARMACY_ID,
  'palmyraguardian.medmeapp.ca-pharmacyId': env.PALMYRAGUARDIAN_PHARMACY_ID,
  'divisionpharmacy.medmeapp.ca-pharmacyId': env.DIVISIONPHARMACY_PHARMACY_ID,
  'townlinepharmacy.medmeapp.ca-pharmacyId': env.TOWNLINEPHARMACY_PHARMACY_ID,
  'parkdalepharmasave.medmeapp.ca-pharmacyId':
    env.PARKDALEPHARMASAVE_PHARMACY_ID,
  'riverdalepharmacy.medmeapp.ca-pharmacyId': env.RIVERDALEPHARMACY_PHARMACY_ID,
  'allcarepharmacy.medmeapp.ca-pharmacyId': env.ALLCAREPHARMACY_PHARMACY_ID,
  'cayugapharmacy.medmeapp.ca-pharmacyId': env.CAYUGAPHARMACY_PHARMACY_ID,
  'lincolncentrepharmacy.medmeapp.ca-pharmacyId':
    env.LINCOLNCENTREPHARMACY_PHARMACY_ID,
  'longfieldspharmacy.medmeapp.ca-pharmacyId':
    env.LONGFIELDSPHARMACY_PHARMACY_ID,
  'royalcitypharmacy.medmeapp.ca-pharmacyId': env.ROYALCITYPHARMACY_PHARMACY_ID,
  'stevensvillepharmacy.medmeapp.ca-pharmacyId':
    env.STEVENSVILLEPHARMACY_PHARMACY_ID,
  'agapepharmacy.medmeapp.ca-pharmacyId': env.AGAPEPHARMACY_PHARMACY_ID,
  'bcp.medmeapp.ca-pharmacyId': env.BCP_PHARMACY_ID,
  'lapsleyidapharmacy.medmeapp.ca-pharmacyId':
    env.LAPSLEYIDAPHARMACY_PHARMACY_ID,
  'medicalcarepharmacy.medmeapp.ca-pharmacyId':
    env.MEDICALCAREPHARMACY_PHARMACY_ID,
  'wellingtonpharmacy.medmeapp.ca-pharmacyId':
    env.WELLINGTONPHARMACY_PHARMACY_ID,
  'pharmasavespeedvale.medmeapp.ca-pharmacyId':
    env.PHARMASAVESPEEDVALE_PHARMACY_ID,
  'royaloakspharmacy.medmeapp.ca-pharmacyId': env.ROYALOAKSPHARMACY_PHARMACY_ID,
  'wellandmedicalpharmacyplymouth.medmeapp.ca-pharmacyId':
    env.WELLANDMEDICALPHARMACYPLYMOUTH_PHARMACY_ID,
  'iriscompoundingpharmacy.medmeapp.ca-pharmacyId':
    env.IRISCOMPOUNDINGPHARMACY_PHARMACY_ID,
  'chapmanspharmacy.medmeapp.ca-pharmacyId': env.CHAPMANSPHARMACY_PHARMACY_ID,
  'bhpharmacy.medmeapp.ca-pharmacyId': env.BHPHARMACY_PHARMACY_ID,
  'windsorcrossingpharmasave.medmeapp.ca-pharmacyId':
    env.WINDSORCROSSINGPHARMASAVE_PHARMACY_ID,
  'glenroypharmacy.medmeapp.ca-pharmacyId': env.GLENROYPHARMACY_PHARMACY_ID,
  'rideaupharmacy.medmeapp.ca-pharmacyId': env.RIDEAUPHARMACY_PHARMACY_ID,
  'bigbayguardian.medmeapp.ca-pharmacyId': env.BIGBAYGUARDIAN_PHARMACY_ID,
  'mostarpharmacy.medmeapp.ca-pharmacyId': env.MOSTARPHARMACY_PHARMACY_ID,
  'wellandmedicalpharmacyontario.medmeapp.ca-pharmacyId':
    env.WELLANDMEDICALPHARMACYONTARIO_PHARMACY_ID,
  'caredrugsaurora.medmeapp.ca-pharmacyId': env.CAREDRUGSAURORA_PHARMACY_ID,
  'oakstreetpharmacy.medmeapp.ca-pharmacyId': env.OAKSTREETPHARMACY_PHARMACY_ID,
  'voakhealth.medmeapp.ca-pharmacyId': env.VOAKHEALTH_PHARMACY_ID,
  '2carepharmacy.medmeapp.ca-pharmacyId': env['2CAREPHARMACY_PHARMACY_ID'],
  'eastendpharmasave.medmeapp.ca-pharmacyId': env.EASTENDPHARMASAVE_PHARMACY_ID,
  'medsavepharmacy.medmeapp.ca-pharmacyId': env.MEDSAVEPHARMACY_PHARMACY_ID,
  'blueskypharmacy.medmeapp.ca-pharmacyId': env.BLUESKYPHARMACY_PHARMACY_ID,
  'laurelwoodpharmasave.medmeapp.ca-pharmacyId':
    env.LAURELWOODPHARMASAVE_PHARMACY_ID,
  'norwichpharmasave.medmeapp.ca-pharmacyId': env.NORWICHPHARMASAVE_PHARMACY_ID,
  'pharmacy101.medmeapp.ca-pharmacyId': env.PHARMACY101_PHARMACY_ID,
  'lefroypharmacy.medmeapp.ca-pharmacyId': env.LEFROYPHARMACY_PHARMACY_ID,
  'harvesterpharmacy.medmeapp.ca-pharmacyId': env.HARVESTERPHARMACY_PHARMACY_ID,
  'huntclubpharmacy.medmeapp.ca-pharmacyId': env.HUNTCLUBPHARMACY_PHARMACY_ID,
  'nickelcentre.medmeapp.ca-pharmacyId': env.NICKELCENTRE_PHARMACY_ID,
  'baylypharmacy.medmeapp.ca-pharmacyId': env.BAYLYPHARMACY_PHARMACY_ID,
  'trustcarepharmasave.medmeapp.ca-pharmacyId':
    env.TRUSTCAREPHARMASAVE_PHARMACY_ID,
  'clearviewpharmacy.medmeapp.ca-pharmacyId': env.CLEARVIEWPHARMACY_PHARMACY_ID,
  'roulstonsdelhi.medmeapp.ca-pharmacyId': env.ROULSTONSDELHI_PHARMACY_ID,
  'pharmasave3021.medmeapp.ca-pharmacyId': env.PHARMASAVE3021_PHARMACY_ID,
  'sandycove.medmeapp.ca-pharmacyId': env.SANDYCOVE_PHARMACY_ID,
  'pharmasavebradford.medmeapp.ca-pharmacyId':
    env.PHARMASAVEBRADFORD_PHARMACY_ID,
  'lifewatchpharmacy.medmeapp.ca-pharmacyId': env.LIFEWATCHPHARMACY_PHARMACY_ID,
  'dundasclinicalpharmacy.medmeapp.ca-pharmacyId':
    env.DUNDASCLINICALPHARMACY_PHARMACY_ID,
  'sandwichida.medmeapp.ca-pharmacyId': env.SANDWICHIDA_PHARMACY_ID,
  'medicaltreepharmacy.medmeapp.ca-pharmacyId':
    env.MEDICALTREEPHARMACY_PHARMACY_ID,
  'iroquoisfallsguardian.medmeapp.ca-pharmacyId':
    env.IROQUOISFALLSGUARDIAN_PHARMACY_ID,
  'saubleguardian.medmeapp.ca-pharmacyId': env.SAUBLEGUARDIAN_PHARMACY_ID,
  'peninsulaida.medmeapp.ca-pharmacyId': env.PENINSULAIDA_PHARMACY_ID,
  'tlcida.medmeapp.ca-pharmacyId': env.TLCIDA_PHARMACY_ID,
  'scottsdrugstore.medmeapp.ca-pharmacyId': env.SCOTTSDRUGSTORE_PHARMACY_ID,
  'macneildodd.medmeapp.ca-pharmacyId': env.MACNEILDODD_PHARMACY_ID,
  'medicineshoppedartmouth.medmeapp.ca-pharmacyId':
    env.MEDICINESHOPPEDARTMOUTH_PHARMACY_ID,
  'medicineshoppe408.medmeapp.ca-pharmacyId': env.MEDICINESHOPPE408_PHARMACY_ID,
  'baysideida.medmeapp.ca-pharmacyId': env.BAYSIDEIDA_PHARMACY_ID,
  'fontainebleuida.medmeapp.ca-pharmacyId': env.FONTAINEBLEUIDA_PHARMACY_ID,
  'forestgladeida.medmeapp.ca-pharmacyId': env.FORESTGLADEIDA_PHARMACY_ID,
  'dorchesterida.medmeapp.ca-pharmacyId': env.DORCHESTERIDA_PHARMACY_ID,
  'cridgepharmacy.medmeapp.ca-pharmacyId': env.CRIDGEPHARMACY_PHARMACY_ID,
  'cookspinebush.medmeapp.ca-pharmacyId': env.COOKSPINEBUSH_PHARMACY_ID,
  'medicineshoppe288.medmeapp.ca-pharmacyId': env.MEDICINESHOPPE288_PHARMACY_ID,
  'reynoldspharmasave.medmeapp.ca-pharmacyId':
    env.REYNOLDSPHARMASAVE_PHARMACY_ID,
  'rossandersonpharmacy.medmeapp.ca-pharmacyId':
    env.ROSSANDERSONPHARMACY_PHARMACY_ID,
  'oxfordpharmacy.medmeapp.ca-pharmacyId': env.OXFORDPHARMACY_PHARMACY_ID,
  'msfairviewclaytonpark.medmeapp.ca-pharmacyId':
    env.MSFAIRVIEWCLAYTONPARK_PHARMACY_ID,
  'macdonnellpharmacy.medmeapp.ca-pharmacyId':
    env.MACDONNELLPHARMACY_PHARMACY_ID,
  'finchmidlandpharmacy.medmeapp.ca-pharmacyId':
    env.FINCHMIDLANDPHARMACY_PHARMACY_ID,
  'elmdalemedical.medmeapp.ca-pharmacyId': env.ELMDALEMEDICAL_PHARMACY_ID,
  'ontariostreetpharmacy.medmeapp.ca-pharmacyId':
    env.ONTARIOSTREETPHARMACY_PHARMACY_ID,
  'newsudburymedicalclinic.medmeapp.ca-pharmacyId':
    env.NEWSUDBURYMEDICALCLINIC_PHARMACY_ID,
  'blueskiespharmacyida.medmeapp.ca-pharmacyId':
    env.BLUESKIESPHARMACYIDA_PHARMACY_ID,
  'carepluspharmacy.medmeapp.ca-pharmacyId': env.CAREPLUSPHARMACY_PHARMACY_ID,
  'clickpharmacy.medmeapp.ca-pharmacyId': env.CLICKPHARMACY_PHARMACY_ID,
  'stonespharmasave.medmeapp.ca-pharmacyId': env.STONESPHARMASAVE_PHARMACY_ID,
  'ridgesquarepharmacy.medmeapp.ca-pharmacyId':
    env.RIDGESQUAREPHARMACY_PHARMACY_ID,
  'mapleviewhealthcarepharmacy.medmeapp.ca-pharmacyId':
    env.MAPLEVIEWHEALTHCAREPHARMACY_PHARMACY_ID,
  'medcurepharmacy.medmeapp.ca-pharmacyId': env.MEDCUREPHARMACY_PHARMACY_ID,
  'windfieldspharmacy.medmeapp.ca-pharmacyId':
    env.WINDFIELDSPHARMACY_PHARMACY_ID,
  'chisholmspharmachoice.medmeapp.ca-pharmacyId':
    env.CHISHOLMSPHARMACHOICE_PHARMACY_ID,
  'blueskiespharmacyguardian.medmeapp.ca-pharmacyId':
    env.BLUESKIESPHARMACYGUARDIAN_PHARMACY_ID,
  'sunnyvalemed.medmeapp.ca-pharmacyId': env.SUNNYVALEMED_PHARMACY_ID,
  'memorialcompounding.medmeapp.ca-pharmacyId':
    env.MEMORIALCOMPOUNDING_PHARMACY_ID,
  'oxfordguardianpharmacy.medmeapp.ca-pharmacyId':
    env.OXFORDGUARDIANPHARMACY_PHARMACY_ID,
  'medshoppe418.medmeapp.ca-pharmacyId': env.MEDSHOPPE418_PHARMACY_ID,
  'caledonpharmacy.medmeapp.ca-pharmacyId': env.CALEDONPHARMACY_PHARMACY_ID,
  'kingraypharmacy.medmeapp.ca-pharmacyId': env.KINGRAYPHARMACY_PHARMACY_ID,
  'portcreditvillagepharmacy.medmeapp.ca-pharmacyId':
    env.PORTCREDITVILLAGEPHARMACY_PHARMACY_ID,
  'yc.medmeapp.ca-pharmacyId': env.YC_PHARMACY_ID,
  'clinicplusida.medmeapp.ca-pharmacyId': env.CLINICPLUSIDA_PHARMACY_ID,
  'stjosephpharmacy.medmeapp.ca-pharmacyId': env.STJOSEPHPHARMACY_PHARMACY_ID,
  'voakpharmacy.medmeapp.ca-pharmacyId': env.VOAKPHARMACY_PHARMACY_ID,
  'huntsvillepharmasave.medmeapp.ca-pharmacyId':
    env.HUNTSVILLEPHARMASAVE_PHARMACY_ID,
  'westonmedicalpharmacy.medmeapp.ca-pharmacyId':
    env.WESTONMEDICALPHARMACY_PHARMACY_ID,
  'davisvillepharmacy.medmeapp.ca-pharmacyId':
    env.DAVISVILLEPHARMACY_PHARMACY_ID,
  'frederickpharmasave.medmeapp.ca-pharmacyId':
    env.FREDERICKPHARMASAVE_PHARMACY_ID,
  'hcreekph.medmeapp.ca-pharmacyId': env.HCREEKPH_PHARMACY_ID,
  'adelaidepharmacy.medmeapp.ca-pharmacyId': env.ADELAIDEPHARMACY_PHARMACY_ID,
  'purehealthrh.medmeapp.ca-pharmacyId': env.PUREHEALTHRH_PHARMACY_ID,
  'trinitydrugstore.medmeapp.ca-pharmacyId': env.TRINITYDRUGSTORE_PHARMACY_ID,
  'bayridgepharmacy.medmeapp.ca-pharmacyId': env.BAYRIDGEPHARMACY_PHARMACY_ID,
  'purehealthv.medmeapp.ca-pharmacyId': env.PUREHEALTHV_PHARMACY_ID,
  'richviewpharmacy.medmeapp.ca-pharmacyId': env.RICHVIEWPHARMACY_PHARMACY_ID,
  'hamptonexternal.medmeapp.ca-pharmacyId': env.HAMPTONEXTERNAL_PHARMACY_ID,
  'uniquecarepharmacy.medmeapp.ca-pharmacyId':
    env.UNIQUECAREPHARMACY_PHARMACY_ID,
  'tomkenmedicalpharmacy.medmeapp.ca-pharmacyId':
    env.TOMKENMEDICALPHARMACY_PHARMACY_ID,
  'arianapharmacy.medmeapp.ca-pharmacyId': env.ARIANAPHARMACY_PHARMACY_ID,
  'lcp.medmeapp.ca-pharmacyId': env.LCP_PHARMACY_ID,
  'clarkspharmasave.medmeapp.ca-pharmacyId': env.CLARKSPHARMASAVE_PHARMACY_ID,
  'bramnorthpharmacy.medmeapp.ca-pharmacyId': env.BRAMNORTHPHARMACY_PHARMACY_ID,
  'inspiredpharmacy.medmeapp.ca-pharmacyId': env.INSPIREDPHARMACY_PHARMACY_ID,
  'henderson.medmeapp.ca-pharmacyId': env.HENDERSON_PHARMACY_ID,
  'huroniamedicalpharmacy.medmeapp.ca-pharmacyId':
    env.HURONIAMEDICALPHARMACY_PHARMACY_ID,
  'standrewspharmasave.medmeapp.ca-pharmacyId':
    env.STANDREWSPHARMASAVE_PHARMACY_ID,
  'riverdaleeastida.medmeapp.ca-pharmacyId': env.RIVERDALEEASTIDA_PHARMACY_ID,
  'romanapharmacy.medmeapp.ca-pharmacyId': env.ROMANAPHARMACY_PHARMACY_ID,
  'goldencarepharmacy.medmeapp.ca-pharmacyId':
    env.GOLDENCAREPHARMACY_PHARMACY_ID,
  'peopleschoiceremedymarkham.medmeapp.ca-pharmacyId':
    env.PEOPLESCHOICEREMEDYMARKHAM_PHARMACY_ID,
  'pharmacyonmetcalfe.medmeapp.ca-pharmacyId':
    env.PHARMACYONMETCALFE_PHARMACY_ID,
  'edmontonpharmacy.medmeapp.ca-pharmacyId': env.EDMONTONPHARMACY_PHARMACY_ID,
  'purehealtho.medmeapp.ca-pharmacyId': env.PUREHEALTHO_PHARMACY_ID,
  'shopritepharmacy.medmeapp.ca-pharmacyId': env.SHOPRITEPHARMACY_PHARMACY_ID,
  'tuxedodrugs.medmeapp.ca-pharmacyId': env.TUXEDODRUGS_PHARMACY_ID,
  'kingstownpharmasave.medmeapp.ca-pharmacyId':
    env.KINGSTOWNPHARMASAVE_PHARMACY_ID,
  'alnhealth.medmeapp.ca-pharmacyId': env.ALNHEALTH_PHARMACY_ID,
  'hcpharmacy.medmeapp.ca-pharmacyId': env.HCPHARMACY_PHARMACY_ID,
  'zaraspharmacy.medmeapp.ca-pharmacyId': env.ZARASPHARMACY_PHARMACY_ID,
  'memorialmedicalpharmasave.medmeapp.ca-pharmacyId':
    env.MEMORIALMEDICALPHARMASAVE_PHARMACY_ID,
  'ohswekenpharmasave.medmeapp.ca-pharmacyId':
    env.OHSWEKENPHARMASAVE_PHARMACY_ID,
  'pathwaypharmacy.medmeapp.ca-pharmacyId': env.PATHWAYPHARMACY_PHARMACY_ID,
  'mortarpestle.medmeapp.ca-pharmacyId': env.MORTARPESTLE_PHARMACY_ID,
  'adelaideida.medmeapp.ca-pharmacyId': env.ADELAIDEIDA_PHARMACY_ID,
  'shannonidapharmacy.medmeapp.ca-pharmacyId':
    env.SHANNONIDAPHARMACY_PHARMACY_ID,
  'boylevaluedrugmart.medmeapp.ca-pharmacyId':
    env.BOYLEVALUEDRUGMART_PHARMACY_ID,
  'sexsmithpharmacy.medmeapp.ca-pharmacyId': env.SEXSMITHPHARMACY_PHARMACY_ID,
  'familypharmacy46.medmeapp.ca-pharmacyId': env.FAMILYPHARMACY46_PHARMACY_ID,
  'sundrepharmasave.medmeapp.ca-pharmacyId': env.SUNDREPHARMASAVE_PHARMACY_ID,
  'northeastpharmacy.medmeapp.ca-pharmacyId': env.NORTHEASTPHARMACY_PHARMACY_ID,
  'sundrecommunitydrugmart.medmeapp.ca-pharmacyId':
    env.SUNDRECOMMUNITYDRUGMART_PHARMACY_ID,
  'elkpointpharmacy.medmeapp.ca-pharmacyId': env.ELKPOINTPHARMACY_PHARMACY_ID,
  'consortpharmacy.medmeapp.ca-pharmacyId': env.CONSORTPHARMACY_PHARMACY_ID,
  'hardistycommunitydrugmart.medmeapp.ca-pharmacyId':
    env.HARDISTYCOMMUNITYDRUGMART_PHARMACY_ID,
  'rxdrugmartbarrhead.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTBARRHEAD_PHARMACY_ID,
  'rxdrugmarthighprairie49.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTHIGHPRAIRIE49_PHARMACY_ID,
  'rxdrugmartblairmore.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTBLAIRMORE_PHARMACY_ID,
  'rxdrugmartbanff.medmeapp.ca-pharmacyId': env.RXDRUGMARTBANFF_PHARMACY_ID,
  'rxdrugmartjasper.medmeapp.ca-pharmacyId': env.RXDRUGMARTJASPER_PHARMACY_ID,
  'rxdrugmartfortmacleod.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTFORTMACLEOD_PHARMACY_ID,
  'rxdrugmarthighprairie53.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTHIGHPRAIRIE53_PHARMACY_ID,
  'rxdrugmartcamrose.medmeapp.ca-pharmacyId': env.RXDRUGMARTCAMROSE_PHARMACY_ID,
  'forbespharmacycoldlake.medmeapp.ca-pharmacyId':
    env.FORBESPHARMACYCOLDLAKE_PHARMACY_ID,
  'pinchercreek.medmeapp.ca-pharmacyId': env.PINCHERCREEK_PHARMACY_ID,
  'whitehornremedyrx.medmeapp.ca-pharmacyId': env.WHITEHORNREMEDYRX_PHARMACY_ID,
  'chaparralremedyrx.medmeapp.ca-pharmacyId': env.CHAPARRALREMEDYRX_PHARMACY_ID,
  'rxdrugmartmarinamall.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTMARINAMALL_PHARMACY_ID,
  'rxdrugmarttricitymall.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTTRICITYMALL_PHARMACY_ID,
  'medicinehat315.medmeapp.ca-pharmacyId': env.MEDICINEHAT315_PHARMACY_ID,
  'medicinehat303.medmeapp.ca-pharmacyId': env.MEDICINEHAT303_PHARMACY_ID,
  'medicinehat302.medmeapp.ca-pharmacyId': env.MEDICINEHAT302_PHARMACY_ID,
  'redcliffpharmasave.medmeapp.ca-pharmacyId':
    env.REDCLIFFPHARMASAVE_PHARMACY_ID,
  'grandinprescriptioncentre.medmeapp.ca-pharmacyId':
    env.GRANDINPRESCRIPTIONCENTRE_PHARMACY_ID,
  'madiganpharmacy.medmeapp.ca-pharmacyId': env.MADIGANPHARMACY_PHARMACY_ID,
  'familypharmacy49.medmeapp.ca-pharmacyId': env.FAMILYPHARMACY49_PHARMACY_ID,
  'familypharmacy50.medmeapp.ca-pharmacyId': env.FAMILYPHARMACY50_PHARMACY_ID,
  'rxdrugmartkamsack.medmeapp.ca-pharmacyId': env.RXDRUGMARTKAMSACK_PHARMACY_ID,
  'rxdrugmartshaunavon.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTSHAUNAVON_PHARMACY_ID,
  'rxdrugmartmoosomin.medmeapp.ca-pharmacyId':
    env.RXDRUGMARTMOOSOMIN_PHARMACY_ID,
  'melspharmacy.medmeapp.ca-pharmacyId': env.MELSPHARMACY_PHARMACY_ID,
  'clarkespharmacythompson.medmeapp.ca-pharmacyId':
    env.CLARKESPHARMACYTHOMPSON_PHARMACY_ID,
  'pembinavalleypharmacy.medmeapp.ca-pharmacyId':
    env.PEMBINAVALLEYPHARMACY_PHARMACY_ID,
  'hopkins.medmeapp.ca-pharmacyId': env.HOPKINS_PHARMACY_ID,
  'pharmasaveoaktree.medmeapp.ca-pharmacyId': env.PHARMASAVEOAKTREE_PHARMACY_ID,
  'rockyharbour.medmeapp.ca-pharmacyId': env.ROCKYHARBOUR_PHARMACY_ID,
  'arcfluclinic.medmeapp.ca-pharmacyId': env.ARCFLUCLINIC_PHARMACY_ID,
  'hendersonspharmacy.medmeapp.ca-pharmacyId':
    env.HENDERSONSPHARMACY_PHARMACY_ID,
  'ms363.medmeapp.ca-pharmacyId': env.MS363_PHARMACY_ID,
  'medlandia.medmeapp.ca-pharmacyId': env.MEDLANDIA_PHARMACY_ID,
  'minimajorspharmacy.medmeapp.ca-pharmacyId':
    env.MINIMAJORSPHARMACY_PHARMACY_ID,
  'thp.medmeapp.ca-pharmacyId': env.THP_PHARMACY_ID,
  'pharmasense.medmeapp.ca-pharmacyId': env.PHARMASENSE_PHARMACY_ID,
  'glengate.medmeapp.ca-pharmacyId': env.GLENGATE_PHARMACY_ID,
  'radiantpharmacy.medmeapp.ca-pharmacyId': env.RADIANTPHARMACY_PHARMACY_ID,
  'northlandpharmacy.medmeapp.ca-pharmacyId': env.NORTHLANDPHARMACY_PHARMACY_ID,
  'everestpharmacy.medmeapp.ca-pharmacyId': env.EVERESTPHARMACY_PHARMACY_ID,
  'gleneden.medmeapp.ca-pharmacyId': env.GLENEDEN_PHARMACY_ID,
  'dgrx.medmeapp.ca-pharmacyId': env.DGRX_PHARMACY_ID,
  'eastbridgepharmacy.medmeapp.ca-pharmacyId':
    env.EASTBRIDGEPHARMACY_PHARMACY_ID,
  'galleriapharmacy.medmeapp.ca-pharmacyId': env.GALLERIAPHARMACY_PHARMACY_ID,
  'geenspharmacy.medmeapp.ca-pharmacyId': env.GEENSPHARMACY_PHARMACY_ID,
  'demo.medmeapp.ca-pharmacyId': env.DEMO_PHARMACY_ID,
  'centralpharmacy.medmeapp.ca-pharmacyId': env.CENTRALPHARMACY_PHARMACY_ID,
  'fraserrx.medmeapp.ca-pharmacyId': env.FRASERRX_PHARMACY_ID,
  'staffordida.medmeapp.ca-pharmacyId': env.STAFFORDIDA_PHARMACY_ID,
  'eriepharmacy.medmeapp.ca-pharmacyId': env.ERIEPHARMACY_PHARMACY_ID,
  'strathroypharmacy.medmeapp.ca-pharmacyId': env.STRATHROYPHARMACY_PHARMACY_ID,
  'petersdrugs.medmeapp.ca-pharmacyId': env.PETERSDRUGS_PHARMACY_ID,
  'alshafapharmacy.medmeapp.ca-pharmacyId': env.ALSHAFAPHARMACY_PHARMACY_ID,
  'eastsidepharmacy.medmeapp.ca-pharmacyId': env.EASTSIDEPHARMACY_PHARMACY_ID,
  'maynoothida.medmeapp.ca-pharmacyId': env.MAYNOOTHIDA_PHARMACY_ID,
  'idapharmacybancroft.medmeapp.ca-pharmacyId':
    env.IDAPHARMACYBANCROFT_PHARMACY_ID,
  'wellerpharmacy.medmeapp.ca-pharmacyId': env.WELLERPHARMACY_PHARMACY_ID,
  'dvp.medmeapp.ca-pharmacyId': env.DVP_PHARMACY_ID,
  'prguardian.medmeapp.ca-pharmacyId': env.PRGUARDIAN_PHARMACY_ID,
  'sundridgepharmacy.medmeapp.ca-pharmacyId': env.SUNDRIDGEPHARMACY_PHARMACY_ID,
  'southriverpharmacy.medmeapp.ca-pharmacyId':
    env.SOUTHRIVERPHARMACY_PHARMACY_ID,
  'hogarthsclinic.medmeapp.ca-pharmacyId': env.HOGARTHSCLINIC_PHARMACY_ID,
  'oakvillepharmacy.medmeapp.ca-pharmacyId': env.OAKVILLEPHARMACY_PHARMACY_ID,
  'nblytravel.medmeapp.ca-pharmacyId': env.NBLYTRAVEL_PHARMACY_ID,
  'bedfordrx.medmeapp.ca-pharmacyId': env.BEDFORDRX_PHARMACY_ID,
  'grandeprairie.medmeapp.ca-pharmacyId': env.GRANDEPRAIRIE_PHARMACY_ID,
  'bethcunni.medmeapp.ca-pharmacyId': env.BETHCUNNI_PHARMACY_ID,
  'medicineshoppemartensville.medmeapp.ca-pharmacyId':
    env.MEDICINESHOPPEMARTENSVILLE_PHARMACY_ID,
  'travelmed.medmeapp.ca-pharmacyId': env.TRAVELMED_PHARMACY_ID,
  'medshoppe294clinic.medmeapp.ca-pharmacyId':
    env.MEDSHOPPE294CLINIC_PHARMACY_ID,
  'ms421.medmeapp.ca-pharmacyId': env.MS421_PHARMACY_ID,
  'lovelldrugs5056.medmeapp.ca-pharmacyId': env.LOVELLDRUGS5056_PHARMACY_ID,
  'frontenac.medmeapp.ca-pharmacyId': env.FRONTENAC_PHARMACY_ID,
  'taylorcreekpharmacy.medmeapp.ca-pharmacyId':
    env.TAYLORCREEKPHARMACY_PHARMACY_ID,
  'medicenterpharmacy.medmeapp.ca-pharmacyId':
    env.MEDICENTERPHARMACY_PHARMACY_ID,
  'hopehealthpharmacy.medmeapp.ca-pharmacyId':
    env.HOPEHEALTHPHARMACY_PHARMACY_ID,
  'whitespharmacy.medmeapp.ca-pharmacyId': env.WHITESPHARMACY_PHARMACY_ID,
  'woodbuffalopharmacy.medmeapp.ca-pharmacyId':
    env.WOODBUFFALOPHARMACY_PHARMACY_ID,
  'pharmasave9407.medmeapp.ca-pharmacyId': env.PHARMASAVE9407_PHARMACY_ID,
  'care4youpharmacy.medmeapp.ca-pharmacyId': env.CARE4YOUPHARMACY_PHARMACY_ID,
  'bowmanvillepharmacy.medmeapp.ca-pharmacyId':
    env.BOWMANVILLEPHARMACY_PHARMACY_ID,
  'strathconapharmacy.medmeapp.ca-pharmacyId':
    env.STRATHCONAPHARMACY_PHARMACY_ID,
  'tibbpharmacyclinic.medmeapp.ca-pharmacyId':
    env.TIBBPHARMACYCLINIC_PHARMACY_ID,
  // previously 'medicineshoppesydney.medmeapp.ca-pharmacyId'
  'ms258.medmeapp.ca-pharmacyId': env.MEDICINESHOPPESYDNEY_PHARMACY_ID,
  'healthplusida.medmeapp.ca-pharmacyId': env.HEALTHPLUSIDA_PHARMACY_ID,
  'kaplet.medmeapp.ca-pharmacyId': env.KAPLET_PHARMACY_ID,
  'wallacepharmacy.medmeapp.ca-pharmacyId': env.WALLACEPHARMACY_PHARMACY_ID,
  'commongoodpharmacy.medmeapp.ca-pharmacyId':
    env.COMMONGOODPHARMACY_PHARMACY_ID,
  'garrisonsquare.medmeapp.ca-pharmacyId': env.GARRISONSQUARE_PHARMACY_ID,
  'dundaswestpharmacy.medmeapp.ca-pharmacyId':
    env.DUNDASWESTPHARMACY_PHARMACY_ID,
  'hammondsplainspharmacyclinic.medmeapp.ca-pharmacyId':
    env.HAMMONDSPLAINSPHARMACYCLINIC_PHARMACY_ID,
  'bridgewaterguardianmichelin.medmeapp.ca-pharmacyId':
    env.BRIDGEWATERGUARDIANMICHELIN_PHARMACY_ID,
}
