import React from 'react'
import { Controller } from 'react-hook-form'

// components
import FormControlLabel from '@material-ui/core/FormControl'
import MuiSelect from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

const Select = ({
  className = null,
  children,
  label = null,
  error = null,
  helperText = null,
  required = false,
  ...rest
}) => (
  <FormControlLabel required={required} variant="outlined">
    <InputLabel>{label}</InputLabel>
    <Controller
      as={
        <MuiSelect native label={label} className={className}>
          {children}
        </MuiSelect>
      }
      error={error}
      {...rest}
    />
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </FormControlLabel>
)

export default Select
