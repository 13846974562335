import React, { useContext } from 'react'
import PatientRegistrationContext from '../../patientRegistrationContext'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

// styles
import { NavigationContainer } from './navigationFooter.styles'

import { BackButton, SubmitButton } from '../../patientRegistration.styles'

const NavigationFooter = ({
  confirmationPage = null,
  backButton = { disabled: false, label: 'goBackButtonLabel' },
  forwardButton = { label: 'continueButtonLabel' },
  disabled = false,
}) => {
  const { forms, state, previousScreen } = useContext(
    PatientRegistrationContext
  )
  const { t } = useMedMeTranslation('common')

  if (
    !confirmationPage &&
    !state.bookingError &&
    forms[state.pageIndex].isReviewAndConfirm
  )
    return null
  return (
    <NavigationContainer>
      {!backButton.disabled && !state.isEdit && (
        <BackButton type="button" disabled={disabled} onClick={previousScreen}>
          {t(backButton.label)}
        </BackButton>
      )}
      {forwardButton && (
        <SubmitButton
          type="submit"
          disabled={disabled}
          data-cy="ContinueButton"
        >
          {state.isEdit ? t('updateButtonLabel') : t(forwardButton.label)}
        </SubmitButton>
      )}
    </NavigationContainer>
  )
}

export default NavigationFooter
