import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Minor Ailment Eligibility Screening ',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const completingQuestions = createFhirQuestionnaireItem({
    text: "Please answer the questions below to confirm you're eligible to be seen by a pharmacist. If completing on behalf of someone else, answer from their perspective.",
    linkId: 'completingQuestions',
    type: 'display',
  })

  const redMessage = createFhirQuestionnaireItem({
    text: '_If you have any reason to believe you may require urgent medical attention, please call 9-1-1 or go to the nearest hospital emergency room._',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const healthCard = createFhirQuestionnaireItem({
    text: 'This service is free for eligible Ontario Health Card holders. A professional fee may apply if you do not have a valid card. \n\n&nbsp; \n\n',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const symptomsForSixMonths = createFhirQuestionnaireItem({
    text: 'Have you had symptoms for more than 6 months?',
    linkId: 'symptomsForSixMonths',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Experiencing Heartburn/Acid reflux symptoms continuously for more than 6 months may indicate severe Gastroesophageal Reflux Disease (GERD) and requires further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  const questionnaireHeading = createFhirQuestionnaireItem({
    linkId: 'questionnaireHeading',
    text: 'Screening Questions',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })
  const experiencingSymptoms = createFhirQuestionnaireItem({
    text: `Are you experiencing any of the following symptoms?
- Blood in vomit 
- Abdominal pain or mass 
- Pain or difficulty swallowing
- Chronic cough throat clearing, choking, wheezing, hoarseness or sore throat 
- Dizziness, fatigue, pale appearance or cold hands & feet
- Weight loss without trying 
- Vomiting for more than 7 days 
- Black, tarry stool 
- Chest pain that feels like squeezing that radiates through the shoulder, neck, jaw, and arms 
    `,
    linkId: 'experiencingSymptoms',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'These symptoms are not typical of Heartburn/Acid reflux and require further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })
  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      redMessage,
      healthCard,
      questionnaireHeading,
      symptomsForSixMonths,
      experiencingSymptoms,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningHeartBurnIndiesONJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['ON'],
    isEnterprise: false,
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: ['HeartBurn'],
    },
  },
  metadata: {
    id: 'fhirMedicalScreeningGerdIndiesONJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
