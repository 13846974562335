import {
  useTranslation,
  UseTranslationOptions,
  UseTranslationResponse,
  Namespace,
  KeyPrefix,
} from 'react-i18next'
import { getTranslationNamespace } from './i18n-namespaces'
import { isStandardTranslationNamespace } from './i18n-namespaces.type-guards'
import {
  StandardTranslationNamespace,
  TranslationNamespace,
} from './i18n-namespaces.types'

/**
 * Project wrapper of the `useTranslation()` hook provided by react-i18next that adds a layer
 * of validation and logic required by MedMe.
 *
 * The hook's arguments and return value are equivalent to the native react-i18next `useTranslation()`.
 *
 * The hook will throw an Error if invoked with an invalid/unrecognized namespace.
 *
 * This hook leverages `getTranslationNamespace()` which encapsulates the conditional logic to
 * support customer-specific "overrides" of a given i18next translation namespace. The implementation
 * seamlessly handles cases where customers have required us to use their copy and/or translations.
 *
 * **This `useMedMeTranslation()` hook should always be used in project components instead of
 * directly using the `useTranslation()` hook exported by react-i18next.**
 *
 * @param namespace supported i18next namespace name (or array of names)
 * @param options options passed to underlying react-i18next `useTranslation()`
 * @throws Error if a given namespace is not a valid/supported namespace
 * @returns
 *
 * @see getTranslationNamespace
 * @see {@link https://www.notion.so/medmehealth/F-E-i18n-Scope-Recommendations-3ebcfb461b5d49b9a8e19c19fef31927?pvs=4#d29b2c48147c499caf8ea95be9f5cad2 | Notion Documentation}
 */
export const useMedMeTranslation = <
  N extends Namespace<StandardTranslationNamespace> = 'common',
  TKPrefix extends KeyPrefix<N> = undefined
>(
  namespace?: StandardTranslationNamespace | StandardTranslationNamespace[],
  options?: UseTranslationOptions<TKPrefix>
): UseTranslationResponse<N, TKPrefix> => {
  if (namespace && !isStandardTranslationNamespace(namespace)) {
    throw new Error('Invalid namespace')
  }

  // note: react-i18next's useTranslation() hook supports being called without a namespace (ns `undefined`)
  // refer to i18next config for default namespace settings

  const translationNamespace:
    | TranslationNamespace
    | TranslationNamespace[]
    | undefined = Array.isArray(namespace)
    ? namespace.map((ns) => getTranslationNamespace(ns))
    : typeof namespace === 'string'
    ? getTranslationNamespace(namespace)
    : undefined

  return useTranslation(translationNamespace, options)
}
