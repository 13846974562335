import * as React from 'react'
import {
  useAppointmentActivityContext,
  useBookingContext,
  useRouterContext,
} from '~/pages/AppointmentIntake/contexts'

import Loading from '~/components/loading'
import { LocationList } from './LocationList'

import { useStoreSelectionData } from './useStoreSelectionData'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

import { Layout, Title } from './StoreTimeslotsList.style'
import { FormStepContentLayout } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { BookingDetailsWrapper } from '../../components/BookingDetails'
import { useCoordinate } from '../../hooks'
import { MapBox } from '../../components'

export const StoreTimeslotsList: React.FC = () => {
  const { t } = useMedMeTranslation('patient')
  const { resetAppointmentActivityIntakeAsync, state } = useBookingContext()
  const { appointmentActivity } = useAppointmentActivityContext()
  const [pharmacy, setPharmacy] = React.useState(state.pharmacy)

  const {
    settings: { isPublic },
  } = appointmentActivity

  React.useEffect(() => {
    resetAppointmentActivityIntakeAsync()
  }, [])

  const { pharmacyList, showMapbox, loading, error } = useStoreSelectionData()
  const {
    coordinate,
    setCoordinate,
    loading: loadingCoordinate,
  } = useCoordinate()
  const {
    searchParams: { global: isGlobal },
  } = useRouterContext()

  const title =
    !isPublic && isGlobal
      ? t('storeSelection.alternativeLocationsTitle')
      : t('storeSelection.title')
  if (loading || loadingCoordinate)
    return (
      <Loading
        title={t('loading.title.loading', { ns: 'common' })}
        subTitle={t('loading.subtitle.retrievingStoreList', { ns: 'common' })}
      />
    )
  if (error) throw new Error(error)

  return (
    <BookingDetailsWrapper>
      <FormStepContentLayout>
        <Layout>
          <Title>{title}</Title>
          {showMapbox && (
            <MapBox
              pharmacyList={pharmacyList}
              pharmacy={pharmacy}
              setPharmacy={setPharmacy}
              coordinate={coordinate}
              setCoordinate={setCoordinate}
            />
          )}
          <LocationList
            pharmacyList={pharmacyList}
            selectedPharmacyId={pharmacy.id}
            coordinate={coordinate}
            onChange={setPharmacy}
          />
        </Layout>
      </FormStepContentLayout>
    </BookingDetailsWrapper>
  )
}
