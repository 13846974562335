import React, { useCallback } from 'react'
import { useHistory, Switch, Route, Redirect } from 'react-router-dom'
import { FormStep } from './FormStep'
import { newBookingPaths } from '~/routes'

export interface FormManagerProps {
  steps: FormStep[]
  onComplete: () => void
}
export const FormManager: React.FC<FormManagerProps> = ({
  steps,
  onComplete,
}) => {
  const history = useHistory()
  const { search } = history.location

  const getWaitlistPath = useCallback(
    (path) => `${newBookingPaths.waitlist}/${path}${search}`,
    [search]
  )

  const prevStep = (_currentStep: FormStep) => {
    history.goBack()
  }

  const nextStep = (currentStep: FormStep) => {
    const currentIndex = steps.findIndex(
      (step) => step.path === currentStep.path
    )

    if (currentIndex < 0) throw new Error('cannot find current step')

    const nextIndex = currentIndex + 1

    if (nextIndex < steps.length) {
      const nextStep = steps[nextIndex]
      history.push(getWaitlistPath(nextStep.path))
    } else {
      onComplete()
    }
  }

  return (
    <Switch>
      <Route
        path={`${newBookingPaths.waitlist}/:path`}
        render={(props) => {
          const { path } = props.match.params
          const step = steps.find((step) => step.path === path)

          if (!step) {
            return <Redirect to={getWaitlistPath(steps[0].path)} />
          }

          const StepComponent = step.Component

          return (
            <StepComponent
              path={step.path}
              displayName={step.displayName}
              onComplete={nextStep}
              onBack={prevStep}
            />
          )
        }}
      />
      <Route
        path="/booking/waitlist"
        render={() => <Redirect to={getWaitlistPath(steps[0].path)} />}
      />
    </Switch>
  )
}
