import { DialogTitle, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import styled from 'styled-components'

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    padding: 14px 6px;
    border-radius: 5px;
  }
`

export const StyledTitle = styled(DialogTitle)`
  & .MuiTypography-root {
    font-weight: 600;
  }
`

export const StyledCoBookableAAIsNames = styled(Typography)`
  font-weight: 600;
`

export const StyledFooterText = styled(Typography)`
  margin-top: 20px;
`

export const StyledDialogActions = styled(DialogActions)`
  flex-direction: column;
  row-gap: 1.5rem;

  &.MuiDialogActions-root {
    justify-content: space-between;
    align-items: stretch;
    padding: 10px 24px 24px 24px;
  }

  @media screen and (min-width: 581px) {
    flex-direction: row;
  }
`
