import { QuestionnaireItem } from 'fhir/r4'
import { createJsonString } from '../../../../../../../lib/json'

export const createFhirDisplayItem = (
  json: QuestionnaireItem
): Omit<QuestionnaireItem, 'linkId'> => {
  if (json.type !== 'display')
    throw new Error('createFhirDisplayItem: json.item !== "display"')

  return {
    type: 'display',
    text: createJsonString(json.text ?? ''),
  }
}
