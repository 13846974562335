import React from 'react'
import {
  usePharmacyContext,
  useWhiteLabelContext,
} from '~/pages/AppointmentIntake/contexts'
import { Layout } from './ErrorLayout.styles'
import { HeaderWrapper } from '~/componentsTs/PatientSideLayout'

export function ErrorLayout({
  children,
}: React.PropsWithChildren<Record<never, never>>) {
  const { pharmacy } = usePharmacyContext()
  const { whiteLabel } = useWhiteLabelContext()

  const logoSrc = pharmacy.logoLong
    ? process.env.REACT_APP_S3_BASE_URL + pharmacy.logoLong
    : whiteLabel.logoLong

  return (
    <Layout>
      <HeaderWrapper logo={logoSrc} />
      {children}
    </Layout>
  )
}
