const initialState = {
  offset: 0,
  search: '',
  showFilterOptions: false,
  sortBy: null,
  profileCreatedAt: null,
  ageRange: null,
  patientIntakeQuestions: null,
  postalCode: null,
}

const UPDATE_OFFSET = 'PATIENT_LIST_UPDATE_OFFSET'
const UPDATE_SEARCH = 'PATIENT_LIST_UPDATE_SEARCH'
const CLEAR_FILTER = 'PATIENT_LIST_CLEAR_FILTER'
const UPDATE_SHOW_FILTER_OPTIONS = 'PATIENT_LIST_UPDATE_SHOW_FILTER_OPTIONS'
const UPDATE_SORT_BY = 'PATIENT_LIST_UPDATE_SORT_BY'
const UPDATE_AGE = 'PATIENT_LIST_UPDATE_AGE'
const UPDATE_PROFILE_CREATED_AT = 'PATIENT_LIST_UPDATE_PROFILE_CREATED_AT'
const UPDATE_PATIENT_INTAKE_QUESTIONS =
  'PATIENT_LIST_UPDATE_PATIENT_INTAKE_QUESTIONS'
const UPDATE_POSTAL_CODE_FILTER = 'PATIENT_LIST_UPDATE_POSTAL_CODE_FILTER'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OFFSET:
      return {
        ...state,
        offset: action.offset,
      }
    case UPDATE_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    case CLEAR_FILTER:
      return {
        ...initialState,
        showFilterOptions: state.showFilterOptions,
      }
    case UPDATE_SHOW_FILTER_OPTIONS:
      return {
        ...state,
        showFilterOptions: action.toggleFilterOptions,
      }
    case UPDATE_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case UPDATE_AGE:
      return {
        ...state,
        ageRange: action.ageRange,
      }
    case UPDATE_PROFILE_CREATED_AT:
      return {
        ...state,
        profileCreatedAt: action.profileCreatedAt,
      }
    case UPDATE_PATIENT_INTAKE_QUESTIONS:
      return {
        ...state,
        patientIntakeQuestions: action.patientIntakeQuestions,
      }
    case UPDATE_POSTAL_CODE_FILTER:
      return {
        ...state,
        postalCode: action.postalCode,
      }
    default:
      return state
  }
}

export const updateOffset = (offset) => ({
  type: UPDATE_OFFSET,
  offset,
})

export const updateSearch = (search) => ({
  type: UPDATE_SEARCH,
  search,
})

export const clearFilter = () => ({
  type: CLEAR_FILTER,
})

export const updateShowFilterOptions = (toggleFilterOptions) => ({
  type: UPDATE_SHOW_FILTER_OPTIONS,
  toggleFilterOptions,
})

export const updateSortBy = (sortBy) => ({
  type: UPDATE_SORT_BY,
  sortBy,
})

export const updateProfileCreatedAt = (profileCreatedAt) => ({
  type: UPDATE_PROFILE_CREATED_AT,
  profileCreatedAt,
})

export const updateAge = (ageRange) => ({
  type: UPDATE_AGE,
  ageRange,
})

export const updatePatientIntakeQuestions = (patientIntakeQuestions) => ({
  type: UPDATE_PATIENT_INTAKE_QUESTIONS,
  patientIntakeQuestions,
})

export const updatePostalCodeFilter = (postalCode) => ({
  type: UPDATE_POSTAL_CODE_FILTER,
  postalCode,
})

export default reducer
