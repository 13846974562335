import { Engine } from 'json-rules-engine'
import moment from 'moment'

/**
 * This function checks to see whether the date provided by factValue is greater than
 * the current date minus the number of months given by jsonValue
 */
export const addIsAfter = (rulesEngine: Engine) => {
  rulesEngine.addOperator('isAfter', (factValue, jsonValue) => {
    if (!factValue) return true
    // minDate is the current date minus the number of years given by jsonValue
    const minDate = moment(
      moment().subtract(Number(jsonValue), 'months').format('YYYY-MM-DD')
    )
    const factDate = moment(String(factValue))
    return factDate > minDate
  })
}
