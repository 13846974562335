import { loadFreshPaintSnippet } from './loadFreshpaintSnippet'

export const initializeFreshpaint = (
  pharmacy: any,
  pharmacist: any,
  isFreshPaintLoaded: boolean,
  setIsFreshPaintLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // load Freshpaint
  if (!isFreshPaintLoaded) {
    loadFreshPaintSnippet()
    setIsFreshPaintLoaded(true)
  }

  // Setup Freshpaint Identity and Group
  if (isFreshPaintLoaded && pharmacy && pharmacist) {
    // Cleanup and rename properties
    const {
      id: pharmacyId,
      name: pharmacyName,
      province: pharmacyProvince,
      zone: pharmacyZone,
      pharmacyAddress,
      logoCircle,
      logoLong,
      logoLongInverse,
      __typename: pharmacyTypename,
      ...pharmacyRemainingProperties
    } = pharmacy
    const {
      signatureFileName,
      signatureUrl,
      __typename,
      isFirstTimeLogin,
      id: pharmacistId,
      ...pharmacistRemainingProperties
    } = pharmacist

    window.freshpaint?.identify(pharmacistId, {
      pharmacistId,
      ...pharmacistRemainingProperties,
    })
    window.freshpaint?.group(pharmacyId, {
      pharmacyName,
      pharmacyCity: pharmacyAddress?.city,
      pharmacyPostalCode: pharmacyAddress?.postalCode,
      pharmacyZone,
      pharmacyProvince,
      pharmacyAddress,
      ...pharmacyRemainingProperties,
    })
  }
}
