import { RuleSerializable } from 'json-rules-engine'
import { FhirExtValidationProps } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { createValidationError } from '../events'

export const createValidationRule = (
  fieldName: string,
  { operator, message, value }: FhirExtValidationProps
): RuleSerializable => {
  return {
    name: `${fieldName}ValidationError`,
    conditions: {
      all: [
        {
          fact: fieldName,
          operator: operator,
          value: value,
        },
      ],
    },
    event: createValidationError({
      fieldName: fieldName,
      type: 'validationError',
      message: message,
    }),
    priority: 20,
  }
}
