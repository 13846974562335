import styled from 'styled-components'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiMenuList from '@material-ui/core/MenuList'

export const MenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    font-weight: 500;
    font-family: inherit;
  }
`

export const MenuList = styled(MuiMenuList)`
  &.MuiList-root {
    box-shadow: 2px 3px 4px #e6e6e6;
  }
`
