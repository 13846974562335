import React from 'react'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

// util
import { isEnterprise, isSdmEnterprise } from '~/util/enterpriseMethods'

// components
import { Select } from '../../../inputs'

// styles
import { Section, TitleContainer } from './contactAndQuestions.styles'
import { Title, Body } from '../../../patientRegistration.styles'

const GroupInformation = () => {
  const { errors } = useFormContext()

  return (
    <Section>
      <TitleContainer>
        <Title>Group Information</Title>
        {isSdmEnterprise() ? (
          <Body>
            When you (the primary person of the group) receive your booking
            link, you will be able to book up to 6 people into the same
            appointment. Group bookings are available for dependents who meet
            eligibility criteria (aged 2+) or adults you have consent to book on
            behalf of.
            <br />
            <br />
            Group bookings for anyone over 6 people will require an additional
            appointment booking. If you are booking for more than 1 person, the
            time shown will apply to the first person in your group, followed by
            increments of 5-15 minutes for every person thereafter in your
            group.
            <br />
            <br />
            To help our pharmacy allocate vaccination appointments please
            indicate the maximum number of people in your group that will attend
            the same appointment:
          </Body>
        ) : isEnterprise() ? (
          <Body>
            {`If you will be the only person at this appointment, please select
            "1" below.`}
            <br />
            <br />
            For families and groups, please indicate the maximum number of
            people that will be present for this appointment. We support group
            bookings up to 6 people in the same appointment.
            <br />
            <br />
            If members of your group would like to join other flu vaccine
            waitlists (options may include Regular, High-Dose or Nasal), please
            join those waitlists separately if available. Availability is
            subject to pharmacy offerings.
          </Body>
        ) : (
          <Body>
            {`If you will be the only person at this appointment, please select
            "1" below.`}
            <br />
            <br />
            For families and groups, please indicate the maximum number of
            people that will be present for this appointment. We support group
            bookings up to 6 people in the same appointment.
          </Body>
        )}
      </TitleContainer>

      <Select
        name="noOfWaitlistedPeople"
        error={errors.noOfWaitlistedPeople}
        helperText={errors.noOfWaitlistedPeople?.message}
      >
        <option value="">Select Max # of People in Your Group</option>
        {_.range(6).map((i) => (
          <option key={i} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </Select>
    </Section>
  )
}

export default GroupInformation
