import styled from 'styled-components'

export const UnorderedList = styled.ul`
  list-style: disc;
  padding-left: 1rem;
  padding-top: 0.5rem;
  gap: 0.5rem;
`

export const OrderedList = styled.ol`
  list-style: decimal;
  padding-left: 1rem;
  gap: 0.5rem;
`

export const ListElement = styled.li`
  margin-bottom: 0.5rem;
`

export const Bold = styled.strong`
  font-weight: bold;
`

export const Italic = styled.em`
  font-style: italic;
`

export const GrayListElement = styled(ListElement)`
  color: gray;
`
