import { Extension } from 'fhir/r4'
import { fhirStructDefUrlMedMe } from '../constants'

const extPathName = 'validation'

export const fhirExtValidationUrl =
  `${fhirStructDefUrlMedMe}${extPathName}` as const

export const isFhirExtValidation = (
  fhir?: Extension
): fhir is FhirExtValidation => {
  return fhir?.url === fhirExtValidationUrl
}

export const createFhirExtValidation = (
  fhir?: Partial<Extension>
): FhirExtValidation => {
  return {
    url: fhirExtValidationUrl,
    extension:
      fhir?.extension?.reduce((acc, ext) => {
        return [...acc, ext as InnerValidation]
      }, [] as InnerValidation[]) ?? ([] as InnerValidation[]),
  }
}

type InnerValidation =
  | {
      url: 'operator'
      valueString: string
    }
  | {
      url: 'message'
      valueString: string
    }
  | {
      url: 'value'
      extension?: [
        {
          url: 'fact'
          valueString: string
        },
        {
          url: 'pathname'
          valueString: string
        }
      ]
      valueString?: string
      valueBoolean?: boolean
      valueInteger?: number
      valueDecimal?: number
    }

export type FhirExtValidation = {
  url: typeof fhirExtValidationUrl
  extension: InnerValidation[]
}

const getFactPath = (
  exts?: Extension[]
): { fact: string; pathname: string } | undefined => {
  const output = exts?.reduce(
    (acc, ext) => {
      if (ext.url === 'fact') {
        return {
          ...acc,
          fact: ext.valueString ?? undefined,
        }
      }
      if (ext.url === 'pathname') {
        return {
          ...acc,
          pathname: ext.valueString ?? undefined,
        }
      }
      return acc
    },
    { fact: undefined, pathname: undefined }
  ) ?? { fact: undefined, pathname: undefined }

  if (output.fact === undefined || output.pathname === undefined) {
    return undefined
  }
  return undefined
}

export const createFhirExtValidationProps = (
  ext: Extension
): FhirExtValidationProps => {
  if (isFhirExtValidation(ext)) {
    const typedExt = createFhirExtValidation(ext)
    const output = typedExt.extension.reduce<FhirExtValidationProps>(
      (acc, ext) => {
        if (ext.url === 'operator') {
          return {
            ...acc,
            operator: ext.valueString,
          }
        }
        if (ext.url === 'message') {
          return {
            ...acc,
            message: ext.valueString ?? '',
          }
        }
        if (ext.url === 'value') {
          const values = {
            ...acc,
            value: getFactPath(ext.extension),
            valueString: ext.valueString,
            valueBoolean: ext.valueBoolean,
            valueInteger: ext.valueInteger,
            valueDecimal: ext.valueDecimal,
          }

          const value =
            values.value ??
            values.valueString ??
            values.valueBoolean ??
            values.valueInteger ??
            values.valueDecimal ??
            undefined
          return {
            ...acc,
            value,
          }
        }
        return acc
      },
      { operator: '', message: '', value: undefined }
    )

    if (output.value === undefined) {
      throw new Error('Value is not defined')
    }
    return output
  }
  return { operator: '', message: '', value: undefined }
}

export type FhirExtValidationProps = {
  operator: string
  value:
    | string
    | boolean
    | number
    | null
    | undefined
    | {
        fact: string
        pathname: string
      }
  message: string
}
