import * as React from 'react'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'
import { Pharmacy } from '~/lib/pharmacy'
import {
  formatPharmacyAddress,
  formatAppointmentInfo,
} from '~/util/formatDisplay'
import {
  AppointmentActivityInstance,
  getAaiFullName,
} from '~/lib/appointmentActivity'

// styles
import {
  Layout,
  Title,
  BodyText,
  BoldText,
  Bold,
  SubmitButton,
} from './reserveDateTime.styles'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { Trans } from 'react-i18next'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const ReserveTimeslot: React.FC<{
  pharmacy: Pharmacy
  appointmentActivity: AppointmentActivityInstance
}> = ({ pharmacy, appointmentActivity }) => {
  const {
    state: {
      appointmentActivityGroup,
      appointmentActivity: { urlParam },
    },
  } = useBookingContext()
  const {
    getValues,
    formState: { isSubmitting },
  } = useFormContext()
  const { t } = useMedMeTranslation('patient')
  const layoutRef = React.useRef<HTMLInputElement>(null)
  React.useEffect(() => {
    if (layoutRef.current) layoutRef.current.focus()
  }, [layoutRef])

  const getDescription = () => {
    if (urlParam === 'quit-smoking') {
      return t(
        'appointmentTypes.quitSmoking.continueWithBookingModalDescription'
      )
    }
  }

  const startDateTime = getValues('startDateTime')
  const title = t('continueWithBookingModal.title')
  const noOfPeople = 1
  const numberOfPeople = t('bookingFor', { count: noOfPeople })
  const timeslot = moment.tz(startDateTime, pharmacy.timeZone).format('llll')

  const appointmentActivityFullName = getAaiFullName(
    appointmentActivity,
    appointmentActivityGroup
  )

  const appointmentAndAddressInfo = [
    formatAppointmentInfo(
      appointmentActivityFullName,
      numberOfPeople,
      timeslot
    ),
    formatPharmacyAddress(pharmacy),
  ]
    .filter(Boolean)
    .join('\n\n')

  const buttonText = t('continueButtonLabel', {
    ns: 'common',
  })

  return (
    <Layout ref={layoutRef} tabIndex={0}>
      <Title>{title}</Title>

      <BodyText>
        <Trans
          t={t}
          i18nKey="continueWithBookingModal.subtitle"
          components={{ bold: <Bold /> }}
          values={{ duration: '20' }}
        />
      </BodyText>

      <BoldText>
        {appointmentAndAddressInfo
          .split('\n')
          .map((line, i) => (line ? <p key={i}>{line}</p> : <br key={i} />))}
      </BoldText>
      <BodyText>{getDescription()}</BodyText>

      <SubmitButton
        type="submit"
        disabled={isSubmitting}
        tabIndex={0}
        data-cy="submitButton"
      >
        {buttonText}
      </SubmitButton>
    </Layout>
  )
}
