import styled from 'styled-components'
import { Card } from '~/pages/AppointmentIntake/AppointmentIntake.style'
interface CardProps {
  selected?: boolean
}

export const LocationCardContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const StoreHeader = styled(Card)<CardProps>`
  display: flex;
  flex-flow: row nowrap;
  padding: 1.5rem;
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.backgroundPrimary : 'white'};
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  div {
    color: ${({ selected }) => (selected ? 'white' : 'black')};
  }
`
