import { QuestionnaireItem } from 'fhir/r4'
import { createFhirQuestionnaireItem } from '../fhirQuestionnaireItem'

export const createFhirGroupItem = (
  json: QuestionnaireItem
): Omit<QuestionnaireItem, 'linkId'> => {
  if (json.type !== 'group')
    throw new Error('createFhirDisplayItem: json.item !== "group"')

  return {
    type: 'group',
    item: json.item?.map(createFhirQuestionnaireItem) ?? [],
  }
}

export type FhirGroupItem = ReturnType<typeof createFhirGroupItem>
