import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 0.1;
  width: auto;
`

export const BooleanRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-shrink: 0;
  flex-flow: row nowrap;
  gap: 10px;
`
