import styled from 'styled-components'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const DistanceContainer = styled.div<{ isBottom?: boolean }>`
  display: grid;
  margin-left: auto;
  grid-row-gap: 1rem;
  align-items: ${({ isBottom }) => (isBottom ? 'end' : 'start')};
`

export const DistanceLabel = Heading4
