import { useCallback, useEffect, useRef, useState } from 'react'

export const createCoordinate = (longitude: number, latitude: number) => ({
  longitude,
  latitude,
})

export type Coordinate = ReturnType<typeof createCoordinate>

export const useCoordinate = ({
  disable = false,
}: { disable?: boolean } = {}) => {
  const [coordinate, setCoordinate] = useState<Coordinate | null>(null)
  const [initializing, setInitializing] = useState(!disable)
  const isMountedRef = useRef(true)
  const maxRetries = 3

  const navigatorGetCurrentPositionPromisified = useCallback(() => {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      if (
        typeof navigator === 'undefined' ||
        !navigator ||
        !('geolocation' in navigator)
      )
        return reject()

      // eslint-disable-next-line prefer-const
      let timeoutId: NodeJS.Timeout
      const timeout = 5000

      const success = (position: GeolocationPosition) => {
        clearTimeout(timeoutId)
        resolve(position)
      }

      const error = () => {
        clearTimeout(timeoutId)
        reject()
      }

      timeoutId = setTimeout(() => {
        error()
      }, timeout)
      // In the scenario where the user does not respond to the location prompt, the promise will reject in 5s and try again up to 3 times.
      // This is a workaround for the issue when the app is opened in WebView on iOS and the location prompt does not appear

      navigator.geolocation.getCurrentPosition(success, error)
    })
  }, [])

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (disable) return
    setInitializing(true)

    const getLocation = (retries = 0) => {
      navigatorGetCurrentPositionPromisified()
        .then((position) => {
          setCoordinate(
            createCoordinate(
              position.coords.longitude,
              position.coords.latitude
            )
          )
          setInitializing(false)
        })
        .catch((err) => {
          setInitializing(false)
          console.error('Could not get user location', err)
          if (retries < maxRetries) {
            setTimeout(() => {
              if (isMountedRef.current) {
                getLocation(retries + 1)
              }
            })
          }
        })
    }
    getLocation()
  }, [disable, navigatorGetCurrentPositionPromisified])

  return { coordinate, setCoordinate, loading: initializing }
}
