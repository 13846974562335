import { WebViewAnyMessage } from './types'
import { isSdmEnterprise } from '~/util/enterpriseMethods'

const postIOSMessage = (event: WebViewAnyMessage): boolean => {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.medMeHandler
  ) {
    window.webkit.messageHandlers.medMeHandler.postMessage(event)
    return true
  }

  return false
}

const postAndroidMessage = (event: string): boolean => {
  if (window.MedMeWebView && window.MedMeWebView.postMessage) {
    window.MedMeWebView.postMessage(event)
    return true
  }

  return false
}

const postIframeMessage = (event: string): boolean => {
  // this targets the parent window URL the iframe is in
  const parentURL = location.ancestorOrigins[0]

  if (parent) {
    parent.postMessage(event, parentURL)
    return true
  }
  return false
}

export const postMessage = (event: WebViewAnyMessage): boolean => {
  const jsonEvent = JSON.stringify(event)
  if (postIOSMessage(event)) return true
  if (postAndroidMessage(jsonEvent)) return true
  if (postIframeMessage(jsonEvent)) return true

  return false
}

const gaTagNames = {
  enter: 'Enter_WebView',
  success: 'Successful_Booking_WebView',
  walkin: 'Successful_Walkin_WebView',
  error: 'Error_WebView',
  abandon: 'Abandon_WebView',
  close: 'Close_WebView',
}

export const postWebViewGAEvent = (event: WebViewAnyMessage['event']): void => {
  if (isSdmEnterprise()) {
    window.ga('send', 'event', 'WEBVIEW', gaTagNames[event])
  }
}
