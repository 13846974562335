import * as React from 'react'
import { Pharmacy } from '~/lib/pharmacy'

import { LocationCardContainer, StoreHeader } from './LocationCard.style'
import { TimeslotSelection } from '../../TimeslotSelection'
import { Distance, LocationInfo } from '~/pages/AppointmentIntake/components'

type LocationCardProps = {
  pharmacy: Pharmacy
  distance: number
  selected: boolean
  onClick: (pharmacy: Pharmacy) => void
}

export const LocationCard: React.FC<LocationCardProps> = ({
  pharmacy,
  distance,
  selected,
  onClick,
}) => {
  const handleClick = () => {
    onClick(pharmacy)
  }

  return (
    <LocationCardContainer>
      <StoreHeader
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick()
          }
        }}
        selected={selected}
      >
        <LocationInfo pharmacy={pharmacy} />
        <Distance distance={distance} />
      </StoreHeader>

      {selected && <TimeslotSelection pharmacy={pharmacy} />}
    </LocationCardContainer>
  )
}
