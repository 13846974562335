import React from 'react'
import { useHistory } from 'react-router-dom'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { FormStep } from '../../createFormStep'
import { filterGATagsFromQueryString } from '~/util/appointmentTypeSelection/appointmentTypeSelectionHelper'

interface UseFormRouterProps {
  formSteps: FormStep[]
  isLastForm: boolean
  formStepsIndex: number
  onBackTriggered: (formStepsIndex: number) => void
  onFallbackTriggered: () => void
}

export const useFormRouter = ({
  formSteps,
  isLastForm,
  formStepsIndex,
  onBackTriggered,
  onFallbackTriggered,
}: UseFormRouterProps) => {
  const history = useHistory<{ obsolete: boolean }>()
  const {
    state: { router, appointmentActivity },
  } = useBookingContext()
  const { pathname, search, storeNo, formPath } = router
  const [isFormChanged, setIsFormChanged] = React.useState(false)
  const initialPathname = pathname + search
  const startUrl = '/schedule'
  const activityUrlParam = appointmentActivity?.urlParam.toLowerCase()
  const baseUrl = `/public/activity/${activityUrlParam}/appointment/`
  const filteredSearch = filterGATagsFromQueryString(search)

  const generateUrl = React.useCallback(
    (formStepsIndex: number) => {
      if (!formSteps[formStepsIndex]) throw new Error('No form steps found')
      const form = formSteps[formStepsIndex]
      const formPath = form?.metadata?.path
      const url = `${
        storeNo && `/${storeNo}`
      }${baseUrl}${formPath}${filteredSearch}`
      return url
    },
    [storeNo, filteredSearch, formSteps, baseUrl]
  )

  const goToFormUrl = React.useCallback(
    (index: number) => {
      history.push(generateUrl(index))
    },
    [history, generateUrl]
  )

  const goToStart = (initialPathname: string) => {
    window.location.href = initialPathname
  }

  const formChanged = () => {
    setIsFormChanged(true)
  }

  const previousFormUrl = () => {
    history.goBack()
  }

  React.useEffect(() => {
    if (isFormChanged && formStepsIndex > 0) {
      goToFormUrl(formStepsIndex)
    }
    setIsFormChanged(false)
  }, [isFormChanged])

  React.useEffect(() => {
    if (formPath) {
      const formStepsIndex = formSteps.findIndex(
        (formStep) => formStep.metadata.path === formPath
      )

      if (formStepsIndex > 0) {
        onBackTriggered(formStepsIndex)
      } else {
        // if we are on the page we loaded on
        // use the fallback url /schedule
        if (pathname + search === initialPathname) {
          goToStart(startUrl) // hard reload because we don't want to keep the state
        }
      }
    } else {
      onFallbackTriggered()
    }
  }, [formSteps, formPath])

  /**
   * disable forward history state
   * until we can validate previous form steps
   * on history.forward change
   */
  const handlePopState = React.useCallback(
    (event: PopStateEvent) => {
      if (typeof event.state === 'object' && event.state !== null) {
        if (!event.state.state || !event.state.state.obsolete) {
          history.replace(`${history.location.pathname}${filteredSearch}`, {
            obsolete: true,
          })
          history.push(
            `${history.location.pathname}${filteredSearch}`,
            event.state
          )
        } else if (event.state.state && event.state.state.obsolete === true) {
          previousFormUrl()
        }
      }
      if (isLastForm) goToStart(initialPathname)
    },
    [isLastForm, initialPathname, history, filteredSearch, previousFormUrl]
  )

  React.useEffect(() => {
    window.addEventListener('popstate', handlePopState, true)

    return () => {
      window.removeEventListener('popstate', handlePopState, true)
    }
  }, [handlePopState])

  return { formChanged, goToFormUrl, previousFormUrl }
}
