import { Pharmacy } from '~/lib/pharmacy'

type Address = {
  streetNumber?: string
  streetName?: string
  unit?: string
  city?: string
  province?: string
  postalCode?: string
  poBox?: string
}

export const formatAddress = ({
  streetNumber,
  streetName,
  unit,
  city,
  province,
  postalCode,
  poBox,
}: Address) => {
  const address1 = [streetNumber, streetName, unit ? `(${unit})` : '']
    .filter(Boolean)
    .join(' ')
  const address2 = [city, province, postalCode].filter(Boolean).join(', ')

  const formattedPo = poBox ? `PO Box ${poBox}` : ''
  const address = [address1, formattedPo, address2].filter(Boolean).join('\n')
  return address
}

export const formatPharmacyAddress = (pharmacy: Pharmacy) => {
  const { name, pharmacyAddress } = pharmacy

  const address = [name, formatAddress(pharmacyAddress)]
    .filter(Boolean)
    .join('\n')
  return address
}

export const formatAppointmentInfo = (
  appointmentActivityName: string,
  numberOfPeople: string,
  timeslot: string
) => {
  const appointmentInfo = [appointmentActivityName, numberOfPeople, timeslot]
    .filter(Boolean)
    .join('\n')
  return appointmentInfo
}
