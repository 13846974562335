import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { getTranslationNamespace } from './hooks/useMedMeTranslation/i18n-namespaces'
import { StandardTranslationNamespace } from './hooks/useMedMeTranslation/i18n-namespaces.types'
import { checkIsPatientUI } from './util/envMethods'
import {
  PatientQueryParamLanguageDetector,
  PatientDefaultLanguageDetector,
  PatientLanguageOverrideDetector,
} from './languageDetectors'
import { LANGUAGE_QUERY_PARAM, LanguageCode } from './util/languageMethods'
import frLocale from 'i18n-iso-countries/langs/fr.json'
import enLocale from 'i18n-iso-countries/langs/en.json'
import { registerLocale } from 'i18n-iso-countries'

// Consider migrating to locales instead of language short codes
const languages = [LanguageCode.EN, LanguageCode.FR]

const standardNamespaces: StandardTranslationNamespace[] = checkIsPatientUI()
  ? ['common', 'patient', 'geography']
  : ['common', 'pharmacy', 'geography']

const languageDetector = new LanguageDetector(null, {
  order: [
    'PatientQueryParamLanguageDetector',
    'PatientLanguageOverrideDetector',
    'PatientDefaultLanguageDetector',
    'sessionStorage',
    'localStorage',
    'navigator',
  ],
  lookupQuerystring: LANGUAGE_QUERY_PARAM,
})
languageDetector.addDetector(PatientQueryParamLanguageDetector)
languageDetector.addDetector(PatientLanguageOverrideDetector)
languageDetector.addDetector(PatientDefaultLanguageDetector)

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: languages,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    // debug: isDev,
    defaultNS: getTranslationNamespace('common'),
    ns: standardNamespaces,
    fallbackNS: standardNamespaces,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    supportedLngs: languages,
  })

registerLocale(frLocale)
registerLocale(enLocale)

export default i18n
