import styled from 'styled-components'
import { offWhiteTranslucent, offWhite } from '~/sharedStyles/colorPalette'

export const FinishBarWrapper = styled.nav`
  min-height: 5rem;
  box-sizing: border-box;
  margin-top: -1rem;
`
export const FinishBarFixed = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${offWhiteTranslucent};
  padding: 1rem;
  box-sizing: border-box;
  z-index: 1;
`

export const FinishBarInner = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1025px;
  margin: 0 auto;
`

export const FinishBarButton = styled.span`
  background-color: ${offWhite};
`
