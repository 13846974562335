import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const SelectedCardLayout = styled(Paper)`
  display: block;
  word-wrap: break-word;
  border-radius: 5px;
  max-width: 100%;
`

export const HeaderContentLayout = styled.div`
  width: 100%;
  @media (max-width: 700px) {
    display: grid;
    align-content: center;
    gap: 1rem;
  }
`

export const Header = styled(Paper)`
  display: flex;
  gap: 2rem;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  padding: 2rem;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    opacity: 90%;
  }
`

export const BodyLayout = styled.div`
  padding: 2rem;
`

export const TitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-flow: column nowrap;
  @media (max-width: 700px) {
    font-size: 90%;
  }
`

export const TitleLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: ${({ theme }) => theme.color.titleTertiary};
  @media (max-width: 700px) {
    padding-left: 0rem;
  }
`

export const Description = styled(Heading4)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.color.titleTertiary};
`
