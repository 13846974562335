import { Coding } from 'fhir/r4'
import { fhirSystemMedMe } from '../types'

const systemPathName = 'entity'
const version = '1.0.0'

export const fhirSystemEntityMap =
  `${fhirSystemMedMe}/${systemPathName}` as const

export const isFhirCodingEntityMap = (
  fhir?: Partial<Coding>
): fhir is FhirCodingEntityMap => {
  return fhir?.system?.includes(fhirSystemEntityMap) ?? false
}

export const createFhirCodingEntityMap = (
  fhir?: Partial<Coding>
): FhirCodingEntityMap => {
  return {
    system: fhirSystemEntityMap,
    version: version,
    code: fhir?.code ?? '',
  }
}

export interface FhirCodingEntityMap {
  system: string
  version: string
  code: string
}

const entityPatientPathName = 'patient'
export const fhirSystemEntityPatient =
  `${fhirSystemEntityMap}/${entityPatientPathName}` as const
export interface FhirCodingEntityPatient extends FhirCodingEntityMap {
  system: typeof fhirSystemEntityPatient
  version: typeof version
}
export const isFhirCodingEntityPatient = (
  fhir?: Partial<Coding>
): fhir is FhirCodingEntityPatient => {
  return fhir?.system === fhirSystemEntityPatient
}

export const createFhirCodingEntityPatient = (
  fhir?: Partial<Coding>
): FhirCodingEntityPatient => {
  return {
    system: fhirSystemEntityPatient,
    version: version,
    code: fhir?.code ?? '',
  }
}

export interface FhirCodingEntityAppointment extends FhirCodingEntityMap {
  system: typeof fhirSystemEntityAppointment
  version: typeof version
}

const entityAppointmentPathName = 'appointment'
export const fhirSystemEntityAppointment =
  `${fhirSystemEntityMap}/${entityAppointmentPathName}` as const

export const isFhirCodingEntityAppointment = (
  fhir?: Partial<Coding>
): fhir is FhirCodingEntityAppointment => {
  return fhir?.system === fhirSystemEntityAppointment
}

export const createFhirCodingEntityAppointment = (
  fhir?: Partial<Coding>
): FhirCodingEntityAppointment => {
  return {
    system: fhirSystemEntityAppointment,
    version: version,
    code: fhir?.code ?? '',
  }
}
