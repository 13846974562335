import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { PhoneInput } from '../../../inputs'

// util
import { isSdmEnterprise } from '~/util/enterpriseMethods'
import { getCaslLabel } from '~/util/caslConsent'

// hooks
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

// styles
import {
  TitleContainer,
  NameDobEmailCaslSection,
  LabeledCheckbox,
} from './contactAndQuestions.styles'
import {
  Title,
  Body,
  Input,
  DateInput,
} from '../../../patientRegistration.styles'

const NameDobEmailCaslContactInformation = ({ includeMiddleName = true }) => {
  const { t } = useMedMeTranslation('patient')
  const { watch, register, errors, control } = useFormContext()
  const { caslSmsConsent } = watch('patientConsent')

  return (
    <NameDobEmailCaslSection>
      <TitleContainer>
        <Title>{t('contactInformation.title')}</Title>
        {isSdmEnterprise() ? (
          <Body>
            Please enter the following information of the primary person who
            will be receiving booking notifications and appointment updates for
            appointments they have booked.
          </Body>
        ) : (
          <Body>
            Please enter the details of the primary person that will be
            receiving the notification to book an appointment.
          </Body>
        )}
        <Body>{t('medicalInformation.subTitle')} </Body>
      </TitleContainer>

      <Input
        required
        name="firstName"
        label={`First Name ${includeMiddleName ? '+ Middle Name' : ''}`}
        error={errors.firstName}
        helperText={errors.firstName?.message}
        variant="outlined"
        inputRef={register}
      />
      <Input
        required
        name="lastName"
        label={t('contactInformation.lastName')}
        error={errors.lastName}
        helperText={errors.lastName?.message}
        variant="outlined"
        inputRef={register}
      />
      <Controller
        as={<DateInput />}
        name="birthDate"
        label={t('contactInformation.birthDate')}
        placeholder="YYYY/MM/DD"
        error={errors.birthDate}
        helperText={errors.birthDate?.message}
        format="yyyy/MM/DD"
        openTo="year"
        views={['year', 'month', 'date']}
        disableFuture
        autoOk
        inputVariant="outlined"
        variant="inline"
        control={control}
        required
      />
      <Controller
        required
        as={Input}
        name="email"
        label={t('contactInformation.email')}
        error={errors.email}
        helperText={errors.email?.message}
        variant="outlined"
        control={control}
      />

      {isSdmEnterprise() && (
        <>
          <LabeledCheckbox
            name="patientConsent.caslSmsConsent"
            label="I would like to receive text message notifications in addition to email notifications. Please note that email notifications will provide the most detailed information about appointments."
            control={control}
          />
          {caslSmsConsent && (
            <PhoneInput
              required
              style={{ marginLeft: '2rem' }}
              name="phone.cell"
              label={t('contactInformation.phone.cell')}
              error={errors['phone.cell']}
              helperText={errors['phone.cell']?.message}
              variant="outlined"
              control={control}
            />
          )}
        </>
      )}

      <LabeledCheckbox
        name="patientConsent.caslEmailConsent"
        label={t(getCaslLabel())}
        control={control}
      />
    </NameDobEmailCaslSection>
  )
}

export default NameDobEmailCaslContactInformation
