import { createPresetFormStep } from '..'
import { BookingErrorTimeslotSelection } from '~/pages/AppointmentIntake/forms'

export const bookingErrorTimeslotSelectorFormStep: typeof createPresetFormStep =
  (json) => {
    return {
      Component: BookingErrorTimeslotSelection,
      metadata: json.metadata,
      condition: (state) => {
        return state.bookingError === 'timeslotTaken'
      },
      isAutoSkippable: async () => false,
    }
  }
