import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { NumbericInput } from './NumericField.style'

export const NumericField: React.FC<{
  name: string
  label?: string
  defaultValue?: string
  helperText?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
}> = ({
  name,
  label,
  defaultValue = '',
  helperText = '',
  placeholder = '',
  disabled = false,
  required = false,
}) => {
  const { errors } = useFormContext()
  const { field } = useController({
    name: name,
    defaultValue: defaultValue,
  })
  const error = disabled ? '' : errors[name]?.message

  return (
    <NumbericInput
      variant="outlined"
      label={label}
      type="number"
      error={Boolean(error)}
      helperText={error || helperText}
      placeholder={placeholder}
      disabled={disabled}
      required={!disabled && required}
      fullWidth
      {...field}
    />
  )
}
