import * as React from 'react'
import { WhiteLabelContext } from '../WhiteLabelContext'

export const useWhiteLabelContext = () => {
  const context = React.useContext(WhiteLabelContext)
  if (context === undefined) {
    throw new Error(
      'useWhiteLabelContext must be used within a WhiteLabelProvider'
    )
  }
  return context
}
