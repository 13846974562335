import * as React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { Consent as InnerConsent } from './Consent/Consent'
import { Appointment } from '~/lib/appointment'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { CreateFormStepComponent } from '../../FormManager/createFormStep'

type UpdateConsentProps = Pick<
  Appointment,
  'consentCollectedBy' | 'consentGiverName'
>

export const Consent: ReturnType<CreateFormStepComponent> = () => {
  const bookingContext = useBookingContext()
  const { nextStep, prevStep } = useFormManager()
  const onSubmit = (data: UpdateConsentProps) => {
    nextStep()
    bookingContext.updateConsent(data)
  }

  return <InnerConsent onBack={prevStep} onSubmit={onSubmit} />
}
