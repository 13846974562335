import * as React from 'react'
import { SymptomContainer, SymptomsTitle } from './symptomSelector.styles'
import { SymptomFilterButton } from './symptomFilterButtons/symptomFilterButton'
import { SymptomFilterDropdown } from './symptomFilterDropdown/symptomFilterDropdown'
import { AppointmentActivityGroup } from '~/lib/appointmentActivityGroup'
import { SymptomToActivities } from '../../ailmentSelector'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export type Symptom = {
  name: string
  value: string
  iconPath: string
}

type SymptomSelector = {
  name: string
  label: string
  symptomToActivities: SymptomToActivities
  symptoms: AppointmentActivityGroup[]
  selectedSymptom: string
  setSelectedSymptom: any
  control: any
  methods: any
  setValue: any
}

export const SymptomSelector: React.FC<SymptomSelector> = ({
  name,
  label,
  symptomToActivities,
  symptoms,
  selectedSymptom,
  setSelectedSymptom,
  control,
  methods,
  setValue,
}) => {
  const { t } = useMedMeTranslation('patient')

  const isSymptomActive = (symptom: AppointmentActivityGroup) => {
    return symptomToActivities[symptom.urlParam].length > 0
  }

  return (
    <div>
      <SymptomsTitle>{t('symptomsSuffering')}</SymptomsTitle>
      {/* NON PHONE VIEW (GRID LAYOUT) */}
      <SymptomContainer>
        {symptoms.map((symptom, index) => {
          return (
            isSymptomActive(symptom) && (
              <SymptomFilterButton
                key={index}
                name={symptom.title}
                value={symptom.urlParam}
                iconPath={symptom.iconUrl}
                selectedSymptom={selectedSymptom}
                setSelectedSymptom={setSelectedSymptom}
                setValue={setValue}
              />
            )
          )
        })}
      </SymptomContainer>
      {/* PHONE VIEW (DROPDOWN) */}
      <SymptomFilterDropdown
        name={name}
        label={label}
        symptoms={symptoms}
        methods={methods}
        control={control}
        selectedSymptom={selectedSymptom}
        setSelectedSymptom={setSelectedSymptom}
      />
    </div>
  )
}
