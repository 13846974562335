import { Pharmacy } from '~/lib/pharmacy'
import { Coordinate, MapBoxPoint } from '../hooks'
import distance from '@turf/distance'

export type LocationInterface = ReturnType<
  ReturnType<typeof distanceFromLocation>
>

/* [NOVA-504] Some pharmacies need to be hidden from the public and only available for QA purposes.
They must be configured with coordinates = 0,0 and postal code = '000', and will only be displayed
to users/clients with geolocation set to 0,0. The mechanism to do so is as follows:
1. distanceFromLocation() - if pharmacy matches hidden pharmacy criteria, return preset distance of -9999km.
2. sortLocationsByDistance() - if user's geolocation != (0,0), filter out pharmacies with distance = -9999km.

The -9999km distance is used to ensure hidden pharmacies are always displayed at the top of the list,
and ensure it won't filter out real pharmacies with an accidentally identical distance.
*/

const HIDDEN_PHARMACY_CRITERIA = {
  latitude: 0,
  longitude: 0,
  postalCode: '000',
  presetDistance: -9999,
}

const createMapBoxPoint = (
  longitude: number,
  latitude: number
): MapBoxPoint => ({
  type: 'Point',
  coordinates: [longitude, latitude],
})

const distanceFromLocation =
  (coordinate: Coordinate | null) => (pharmacy: Pharmacy) => {
    const {
      pharmacyAddress: { latitude, longitude, postalCode },
    } = pharmacy

    if (
      latitude === HIDDEN_PHARMACY_CRITERIA.latitude &&
      longitude === HIDDEN_PHARMACY_CRITERIA.longitude &&
      postalCode === HIDDEN_PHARMACY_CRITERIA.postalCode
    ) {
      return {
        pharmacyId: pharmacy.id,
        distance: HIDDEN_PHARMACY_CRITERIA.presetDistance,
      }
    }

    if (!coordinate || !latitude || !longitude) {
      return { pharmacyId: pharmacy.id, distance: 0 }
    }

    const point = createMapBoxPoint(longitude, latitude)

    return {
      pharmacyId: pharmacy.id,
      distance: location
        ? distance(
            point,
            createMapBoxPoint(coordinate.longitude, coordinate.latitude),
            { units: 'kilometers' }
          )
        : 0,
    }
  }

const sortFn = (a: LocationInterface, b: LocationInterface) => {
  const ad = a.distance || 0
  const bd = b.distance || 0
  return ad - bd
}

export const sortLocationsByDistance = (
  pharmacyList: Pharmacy[],
  location: Coordinate | null
): LocationInterface[] => {
  const sortedLocationData = pharmacyList
    ?.map(distanceFromLocation(location))
    ?.sort(sortFn)

  // Only display hidden pharmacies if the request geolocation is also set to 0,0
  // to prevent them from being accessed by real users.
  if (
    location?.latitude === HIDDEN_PHARMACY_CRITERIA.latitude &&
    location?.longitude === HIDDEN_PHARMACY_CRITERIA.longitude
  ) {
    return sortedLocationData
  } else {
    return sortedLocationData.filter(
      ({ distance }) => distance !== HIDDEN_PHARMACY_CRITERIA.presetDistance
    )
  }
}

export const moveLocationToTop = (
  pharmacyList: LocationInterface[],
  pharmacyId: string
) => {
  return pharmacyList.reduce((acc, item) => {
    if (item.pharmacyId === pharmacyId) {
      return [item, ...acc]
    } else {
      return [...acc, item]
    }
  }, [] as LocationInterface[])
}
