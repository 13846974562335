import env from './env.medmesandbox.com.json'

export default {
  'sandbox-on.medmesandbox.com': env.SANDBOX_MEDME_ON_TENANT_ID,
  'sandbox-ns.medmesandbox.com': env.SANDBOX_MEDME_NS_TENANT_ID,
  'sandbox-mb.medmesandbox.com': env.SANDBOX_MEDME_MB_TENANT_ID,
  'sandbox-sk.medmesandbox.com': env.SANDBOX_MEDME_SK_TENANT_ID,
  'sandbox-pe.medmesandbox.com': env.SANDBOX_MEDME_PE_TENANT_ID,
  'sandbox-bc.medmesandbox.com': env.SANDBOX_MEDME_BC_TENANT_ID,
  'sandbox-nb.medmesandbox.com': env.SANDBOX_MEDME_NB_TENANT_ID,
  'sandbox-nl.medmesandbox.com': env.SANDBOX_MEDME_NL_TENANT_ID,
  'sandbox-ab.medmesandbox.com': env.SANDBOX_MEDME_AB_TENANT_ID,

  // ENTERPRISE_SETUP
  'jeancoutu.medmesandbox.com': env.SANDBOX_JEAN_COUTU_TENANT_ID,
  'brunet.medmesandbox.com': env.SANDBOX_BRUNET_TENANT_ID,
}
