import { Patient } from '~/lib/patient'
import { CreatePatientInput } from '~/graphql/types/schema.type'

export const createPatientInput = (patient: Patient): CreatePatientInput => {
  return {
    firstName: patient.firstName,
    lastName: patient.lastName,
    email: patient.email,
    birthDate: patient.birthDate,
    gender: patient.gender,
    sex: patient.sex,
    phone: patient.phone,
    healthCard: patient.healthCard,
    address: patient.address,
    emergencyContact: patient.emergencyContact,
    primaryCareProvider: patient.primaryCareProvider,
    patientConsent: patient.patientConsent,
  }
}
