import styled from 'styled-components'
import { LabeledCheckbox as GenericLabeledCheckbox } from '../../../inputs'
import { Title, Caption } from '../../../patientRegistration.styles'

export const TitleContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0.5rem;

  & > ${Title} {
    margin-bottom: 0rem;
  }
`

export const Section = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;

  .MuiFormControl-root {
    margin-bottom: 1rem;
  }

  @media only screen and (orientation: landscape) and (min-width: 992px) {
    display: grid;
    grid-gap: 1rem 1rem;

    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }
`

export const NameDobEmailCaslSection = styled(Section)`
  margin-bottom: 0;

  @media only screen and (orientation: landscape) and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }
`

export const LabeledCheckbox = styled(GenericLabeledCheckbox)`
  margin-bottom: 1rem;
  grid-column: 1 / -1;

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (orientation: landscape) and (min-width: 992px) {
    margin-bottom: 0;
  }
`

export const BirthDateContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0.5rem;
`

export const ItalicCaption = styled(Caption)`
  font-style: italic;
`
