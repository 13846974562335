import { GridSize } from '@material-ui/core'
import {
  ExtensionStyling,
  isExtensionStylingGridColumn,
} from '../../extensions/styling'

export interface ItemStyle {
  gridColumn: GridSize | undefined | true
}

export const toItemStyleFromStyling = (ext: ExtensionStyling): ItemStyle => {
  const gridColumn = ext.extension.find(isExtensionStylingGridColumn)

  const getGridSize = (): GridSize | undefined | true => {
    if (gridColumn?.valueCode === undefined) {
      return gridColumn?.valueCode
    } else if (gridColumn.valueCode === 'auto') {
      return true // Setting to true lets mui fill in the rest of the row, might want to rethink code
    } else if (!Number.isNaN(Number.parseInt(gridColumn.valueCode))) {
      return Number(gridColumn.valueCode) as GridSize
    }
    throw new Error('toItemStyleFromStyling: invalid grid code')
  }

  return {
    gridColumn: getGridSize(),
  }
}
