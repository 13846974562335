import * as React from 'react'
import { Pharmacy } from '~/lib/pharmacy'
import { PharmacyContextInterface } from '../../PharmacyContext'

export const useSetPharmacy = (
  dispatch: PharmacyContextInterface['dispatch']
) => {
  const setPharmacy = React.useCallback(
    (pharmacy: Pharmacy) => {
      dispatch({
        type: 'setPharmacy',
        pharmacy: pharmacy,
      })
    },
    [dispatch]
  )
  return { setPharmacy }
}
