import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../types'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Emergency Screening',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'If you are experiencing a medical emergency, call 9-1-1 or go to your nearest hospital emergency room.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })
  const additionalEligibility = createFhirQuestionnaireItem({
    text: 'Eligibility for assessment and prescribing for minor ailments will be assessed by the pharmacist at the time of your appointment.',
    linkId: 'additionalEligibility',
    type: 'display',
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Allergies',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'UrinaryTractInfection',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ColdSores',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'PinkEye',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'HeartBurn',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Hemorrhoids',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'TickBites',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'SkinRash',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'OralThrush',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'PeriodCramps',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Impetigo',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'InsectBites',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'MuscleAches',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'MorningSickness',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'YeastInfection',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'DiaperRash',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Acne',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'CankerSores',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Threadworms',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Shingles',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'SoreThroat',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'SleepDisorder',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'SwimmersEar',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'HeadLice',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Warts',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'DryEyes',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Ringworms',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Headache',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Dandruff',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'NailFungus',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'CallusesCorns',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ScalpRash',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'JockItch',
      },
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'NasalCongestion',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const healthcardPaymentDisclaimerHeader = createFhirQuestionnaireItem({
    text: 'DISCLAIMER:',
    linkId: 'healthcardDisclaimerHeader',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const healthcardPaymentDisclaimer = createFhirQuestionnaireItem({
    text: "If you don't have a valid Ontario health card (OHIP), professional fees apply for the assessment.",
    linkId: 'healthcardDisclaimer',
    type: 'display',
  })

  const medicationPaymentDisclaimer = createFhirQuestionnaireItem({
    text: 'In the event a prescription is written for this service, the cost of the medication is not included in the assessment. The cost may be covered by you or your drug insurance.',
    linkId: 'medicationPaymentDisclaimer',
    type: 'display',
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      additionalEligibility,
      healthcardPaymentDisclaimerHeader,
      healthcardPaymentDisclaimer,
      medicationPaymentDisclaimer,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirMAMedicalScreeningSdmONJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['ON'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
    },
  },
  metadata: {
    id: 'fhirMAMedicalScreeningSdmONJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
