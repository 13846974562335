import R5 from 'fhir/r5'
import {
  SupportedExtensionInterface,
  getSupportedExtensionInterface,
} from '../extensions'
import { EntityPathObject, getFieldEntityFromCode } from './entityPathObject'
import { FhirValueSet } from './fhirValueSet'
import { ItemOption, SimpleValueType } from './type'
import { deepCopy } from '~/lib/helpers'
import { getTranslatedFhirElementText } from './fhirQuestionnaire.helpers'

type ItemTypeMap<T> = Record<R5.QuestionnaireItem['type'], T>

export class FhirQuestionnaireItem
  implements R5.QuestionnaireItem, Pick<R5.QuestionnaireResponseItem, 'answer'>
{
  readonly id: R5.QuestionnaireItem['id']
  readonly linkId: R5.QuestionnaireItem['linkId']
  readonly type: R5.QuestionnaireItem['type']
  readonly _type: R5.QuestionnaireItem['_type']
  readonly text: R5.QuestionnaireItem['text']
  readonly _text?: R5.QuestionnaireItem['_text']
  readonly extension: R5.QuestionnaireItem['extension']
  readonly modifierExtension: R5.QuestionnaireItem['modifierExtension']
  readonly code: R5.QuestionnaireItem['code']
  readonly answerConstraint: R5.QuestionnaireItem['answerConstraint']
  readonly answerValueSet: R5.QuestionnaireItem['answerValueSet']
  readonly answerOption: R5.QuestionnaireItem['answerOption']
  readonly item: FhirQuestionnaireItem[]

  readonly enableWhen: R5.QuestionnaireItem['enableWhen']
  readonly enableBehavior: R5.QuestionnaireItem['enableBehavior']
  readonly disabledDisplay: R5.QuestionnaireItem['disabledDisplay']

  readonly required: R5.QuestionnaireItem['required']
  readonly maxLength: R5.QuestionnaireItem['maxLength']

  readonly repeats: false | undefined // Not supported
  readonly initial: [R5.QuestionnaireItemInitial] | undefined // should be 1 item array since we don't currently support repeat
  readonly answer: [R5.QuestionnaireResponseItemAnswer] // should be 1 item array since we don't currently support repeat

  readonly supportedExtensions: SupportedExtensionInterface
  readonly valueSets: Record<string, FhirValueSet | undefined>
  readonly linkIdItems: Record<string, FhirQuestionnaireItem>

  readonly language: string

  // We will assume questionnaireItem is Fhir compliant and use only light validation for now
  constructor(item: R5.QuestionnaireItem, language = 'en-CA') {
    this.id = item.id
    this.linkId = item.linkId
    this.language = language
    this.type = item.type
    this._type = deepCopy(item._type)
    this.text = item.text
    this._text = item._text
    this.extension = item.extension?.map(deepCopy)
    this.modifierExtension = item.modifierExtension?.map(deepCopy)
    this.code = item.code?.map(deepCopy)

    if (item.repeats === true || (item.initial && item.initial.length !== 1)) {
      throw new Error('FhirQuestionnaireItem: repeats is not supported')
    }
    this.repeats = item.repeats
    this.initial = item.initial?.[0] ? [deepCopy(item.initial[0])] : undefined
    this.answer = [{}]

    this.answerConstraint = item.answerConstraint
    this.answerValueSet = item.answerValueSet
    this.answerOption = item.answerOption?.map(deepCopy)

    this.item =
      item.item?.map((item) => new FhirQuestionnaireItem(item, language)) ?? []

    if (item.enableWhen && item.enableWhen.length > 0) {
      this.enableWhen = item.enableWhen.map(deepCopy)
      this.enableBehavior = item.enableBehavior
      this.disabledDisplay = item.disabledDisplay
    } else {
      this.enableWhen = undefined
      this.enableBehavior = undefined
      this.disabledDisplay = undefined
    }

    this.required = item.required
    this.maxLength = item.maxLength

    const { typeVariant } = getSupportedExtensionInterface(
      this._type?.extension
    )
    const { style, validationRuleGenerators } = getSupportedExtensionInterface(
      this.extension,
      language
    )

    const { regex } = getSupportedExtensionInterface(this._type?.extension)

    this.supportedExtensions = {
      typeVariant: typeVariant,
      style: style,
      validationRuleGenerators: validationRuleGenerators,
      regex: regex,
    }

    this.valueSets = {}
    if (this.answerValueSet) {
      this.valueSets[this.answerValueSet] = undefined
    }

    const reduceLinkIdItems = (
      acc: Record<string, FhirQuestionnaireItem>,
      items: FhirQuestionnaireItem[]
    ): Record<string, FhirQuestionnaireItem> => {
      items.forEach((item) => {
        if (item.linkId) {
          if (item.linkId in acc) {
            console.error(`duplicate linkId '${item.linkId}' found`)
            throw new Error('Duplicate linkId found when producing linkIdItems')
          } else {
            acc[item.linkId] = item
            reduceLinkIdItems(acc, item.item ?? [])
          }
        }
      })
      return acc
    }

    this.linkIdItems = reduceLinkIdItems({}, this.item)
  }

  getText(): string | undefined {
    return getTranslatedFhirElementText(this._text, this.language) || this.text
  }

  setAnswerFromQuestionnaireResponseItem(
    response: R5.QuestionnaireResponseItem
  ): void {
    if (this.type === 'group') {
      if (response.answer !== undefined) {
        throw new Error(
          'setAnswerFromQuestionnaireResponseItem: group should not have answers'
        )
      }
      response.item?.forEach((responseItem) => {
        const item = this.item.find(
          (item) => item.linkId === responseItem.linkId
        )
        if (item === undefined) {
          throw new Error(
            'setAnswerFromQuestionnaireResponseItem: corresponding questionnaireItem not amoung children'
          )
        }
        item.setAnswerFromQuestionnaireResponseItem(responseItem)
      })
    } else if (this.type === 'display') {
      if (response.answer !== undefined) {
        throw new Error(
          'setAnswerFromQuestionnaireResponseItem: display should not have answers'
        )
      }
    } else if (response.answer && response.answer.length > 0) {
      if (response.answer.length !== 1) {
        throw new Error(
          "setAnswerByQuestionnaireResponseItem: current don't support repeated questions and answers"
        )
      }

      if (response.linkId !== this.linkId) {
        throw new Error('setAnswerByQuestionnaireResponseItem: linkId mismatch')
      } else {
        if ((response.item?.length ?? 0) > 0 || response.item !== undefined) {
          throw new Error(
            'setAnswerFromQuestionnaireResponseItem: only groups can have items'
          )
        }
        const firstAnswer = response.answer[0]
        const typeGetValueMap: ItemTypeMap<
          (
            param:
              | R5.QuestionnaireResponseItemAnswer
              | R5.QuestionnaireItemInitial
          ) => SimpleValueType
        > = {
          string: (answer) => answer.valueString,
          boolean: (answer) => answer.valueBoolean,
          group: () => undefined,
          display: () => undefined,
          question: () => undefined,
          decimal: (answer) => answer.valueDecimal,
          integer: (answer) => answer.valueInteger,
          date: (answer) => answer.valueDate,
          dateTime: (answer) => answer.valueDateTime,
          time: (answer) => answer.valueTime,
          text: (answer) => answer.valueString,
          url: (answer) => answer.valueUri,
          coding: (answer) => answer.valueCoding?.code,
          attachment: () => {
            throw new Error(
              'setAnswerFromQuestionnaireResponseItem: unsupported answer type found'
            )
          },
          reference: () => {
            throw new Error(
              'setAnswerFromQuestionnaireResponseItem: unsupported answer type found'
            )
          },
          quantity: () => {
            throw new Error(
              'setAnswerFromQuestionnaireResponseItem: unsupported answer type found'
            )
          },
        }

        const simpleAnswer: SimpleValueType = Object.keys(
          firstAnswer
        ).reduce<SimpleValueType>(
          (acc, key: keyof R5.QuestionnaireResponseItemAnswer) => {
            if (firstAnswer[key] !== undefined) {
              if (key.match(/^_value/)) {
                console.warn(
                  `setAnswerFromQuestionnaireResponseItem: ${key} props ignored`
                )
              } else if (key.match(/^value/)) {
                if (acc !== undefined) {
                  throw new Error(
                    'setAnswerFromQuestionnaireResponseItem: found multiple values'
                  )
                } else {
                  if (response.answer?.[0]) {
                    return typeGetValueMap[this.type](response.answer[0])
                  }
                }
              }
            }
            return acc
          },
          undefined
        )

        this.setAnswer(simpleAnswer)
      }
    }
  }

  /**
   * Check if the item's extensions include the batchUpdatable extension
   */
  isBatchUpdatable(): boolean {
    return (
      this._type?.extension?.some(
        (extension) =>
          extension.url ===
            'http://api.medmeapp.com/fhir/extensions/workflow/batchUpdatable' &&
          extension.valueBoolean
      ) ?? false
    )
  }

  /**
   * Check if the item or any of its nested subItems are batchUpdatable
   */
  hasBatchUpdatableItem(): boolean {
    if (this.isBatchUpdatable()) {
      return true
    }

    if (this.item) {
      for (const subItem of this.item) {
        if (subItem.hasBatchUpdatableItem()) {
          return true
        }
      }
    }

    return false
  }

  /**
   * Filter out only batchUpdatable items while preserving the original nesting structure.
   * ie. remove any items that are not batchUpdatable and have no batchUpdatable subItems
   * Keep any items that are batchUpdatable, or contains nested batchUpdatable subItems
   */
  parseBatchUpdatableItem(): FhirQuestionnaireItem | null {
    if (this.item?.length === 0) {
      return this.isBatchUpdatable() ? this : null
    } else {
      const subItems = []
      for (const subItem of this.item) {
        const result = subItem.parseBatchUpdatableItem()
        if (result) subItems.push(result)
      }

      const { item, ...currentItem } = this
      if (subItems.length > 0) {
        return new FhirQuestionnaireItem({ ...currentItem, item: subItems })
      } else {
        return this.isBatchUpdatable()
          ? new FhirQuestionnaireItem({ ...currentItem })
          : null
      }
    }
  }

  /**
   * Create a new QuestionnaireResponseAnswer object and make replace the first element in answers
   * @param value
   */
  setAnswer(value: SimpleValueType): void {
    if (value === undefined) {
      this.clearAnswer()
      return
    }

    const isValidOptionWhenAnswerConstraintExists = (): boolean => {
      if (this.answerConstraint === 'optionsOnly') {
        const options = this.getOptions()
        return (
          options.length > 0 && options.some((option) => option.value === value)
        )
      } else {
        return true
      }
    }

    const createAnswer = (): R5.QuestionnaireResponseItemAnswer => {
      if (this.answerConstraint) {
        const maybeAnswerSetCoding = this.answerValueSet
          ? this.valueSets[this.answerValueSet]
              ?.getCodings()
              .find((coding) => coding.code === value)
          : undefined
        const maybeOption = this.answerOption?.find((option) => {
          switch (this.type) {
            case 'text':
            case 'string':
              return option.valueString === value
            case 'date':
              return option.valueDate === value
            case 'time':
              return option.valueTime === value
            case 'integer':
              return option.valueInteger === value
            case 'reference':
              return option.valueReference === value
            case 'coding':
              return option.valueCoding
                ? option.valueCoding.code === value
                : false
            default:
              return false
          }
        })

        if (maybeAnswerSetCoding) {
          return { valueCoding: { ...maybeAnswerSetCoding } }
        } else if (maybeOption) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { initialSelected, ...option } = maybeOption

          if (this.type === 'coding' && maybeOption.valueCoding) {
            const { display, ...coding } = maybeOption.valueCoding
            return {
              valueCoding: coding,
            }
          }

          // Do a deep copy so no one can modify the object values
          return deepCopy(option)
        } else if (
          this.answerConstraint === 'optionsOrString' &&
          typeof value === 'string'
        ) {
          return { valueString: value }
        } else if (this.answerConstraint === 'optionsOnly') {
          console.error(`Could not find ${value} in options`)
          throw new Error('setAnswer: invalid option')
        }
      }

      switch (this.type) {
        case 'boolean':
          if (typeof value === 'boolean') {
            return { valueBoolean: value }
          }
          break
        case 'decimal':
          // TODO: make this more strict
          if (typeof value === 'number') {
            return { valueDecimal: value }
          }
          break
        case 'integer':
          // TODO: make this more strict
          if (typeof value === 'number') {
            return { valueInteger: value }
          }
          break
        case 'date':
          // TODO: make this more strict
          if (typeof value === 'string') {
            return { valueDate: value }
          }
          break
        case 'dateTime':
          // TODO: make this more strict
          if (typeof value === 'string') {
            return { valueDateTime: value }
          }
          break
        case 'time':
          // TODO: make this more strict
          if (typeof value === 'string') {
            return { valueTime: value }
          }
          break
        case 'text':
        case 'string':
          if (typeof value === 'string') {
            return { valueString: value }
          }
          break
        case 'coding':
          // TODO: make this more strict, this will create issues if there are duplicate codes in the valueSet
          // Could also prevent that on creation instead
          if (typeof value === 'string') {
            return { valueCoding: { code: value } }
          }
          break
        default:
          throw new Error('Unsupported type: Cannot assign answer')
      }

      throw new Error('Type mismatch: Cannot assign answer')
    }

    const maybeAnswer = createAnswer()
    if (!isValidOptionWhenAnswerConstraintExists()) {
      throw new Error('Answer does not exist within AnswerConstraint')
    }
    if (maybeAnswer !== undefined) {
      this.answer[0] = maybeAnswer
    }
  }

  /**
   * Set first element in answer to an empty object
   */
  clearAnswer(): void {
    this.answer[0] = {}
  }

  /**
   * Naively gets value from answer
   * @returns first value found in answer[0], otherwise undefined
   */
  getAnswer(): SimpleValueType {
    const firstAnswer = this.answer[0]

    if (
      this.answerConstraint === 'optionsOrString' &&
      firstAnswer.valueString !== undefined
    ) {
      return firstAnswer.valueString
    }
    switch (this.type) {
      case 'boolean':
        return firstAnswer.valueBoolean
      case 'decimal':
        return firstAnswer.valueDecimal
      case 'integer':
        return firstAnswer.valueInteger
      case 'date':
        return firstAnswer.valueDate
      case 'dateTime':
        return firstAnswer.valueDateTime
      case 'time':
        return firstAnswer.valueTime
      case 'text':
      case 'string':
        return firstAnswer.valueString
      case 'coding':
        return firstAnswer.valueCoding?.code
      default:
        return undefined
    }
  }

  /**
   * Returns a typesafe value based on this questionnaireItem.type
   *
   * @returns typesafe value relative to this.type
   */
  getFallbackValue(): SimpleValueType {
    switch (this.answerConstraint) {
      case 'optionsOrString':
        return ''
      case 'optionsOnly':
        return this.getOptions()[0].value
      default:
        break
    }

    switch (this.type) {
      case 'boolean':
        return false
      case 'decimal':
      case 'integer':
        return 0
      case 'date':
      case 'dateTime':
      case 'time':
      case 'string':
      case 'text':
      case 'coding':
        return ''
      default:
        return undefined
    }
  }

  /**
   * Gets value from initial or answerOption. Assumes properties are fhir complient
   * @returns first value found in initial[0], otherwise undefined
   */
  getInitial(): SimpleValueType {
    const initialFromAnswerOptions = this.answerOption?.find(
      (option) => option.initialSelected === true
    )

    const maybeInitial: R5.QuestionnaireItemInitial | undefined =
      this.initial?.[0] ?? initialFromAnswerOptions

    if (!maybeInitial) {
      return undefined
    }

    switch (this.type) {
      case 'coding':
        return maybeInitial.valueCoding?.code
      case 'boolean':
        return maybeInitial.valueBoolean
      case 'decimal':
        return maybeInitial.valueDecimal
      case 'integer':
        return maybeInitial.valueInteger
      case 'date':
        return maybeInitial.valueDate
      case 'dateTime':
        return maybeInitial.valueDateTime
      case 'time':
        return maybeInitial.valueTime
      case 'text':
      case 'string':
        return maybeInitial.valueString
      default:
        return undefined
    }
  }

  /**
   * Get EntityPathObject that maps this FhirQuestionnaireItem to a field in the MedMe system
   * @returns
   */
  getEntityPathObject(): EntityPathObject[] {
    return (
      this.code?.reduce((acc, coding) => {
        const entityCode = getFieldEntityFromCode(coding)
        if (entityCode) return [...acc, entityCode]
        return acc
      }, []) ?? []
    )
  }

  /**
   * Returns an array of possible options for this question
   *
   * @returns
   */
  getOptions(): ItemOption[] {
    if (this.answerValueSet) {
      const maybeValueSet = this.valueSets[this.answerValueSet]
      if (maybeValueSet) {
        return maybeValueSet
          .getCodings()
          .map(({ code, display }) => ({ value: code, label: display }))
      } else {
        throw new Error('answerValueSet is not defined')
      }
    } else if (this.answerOption) {
      const getOption = (
        option: R5.QuestionnaireItemAnswerOption
      ): ItemOption | undefined => {
        switch (this.type) {
          case 'boolean':
            return undefined
          case 'decimal':
            return undefined
          case 'integer':
            return option.valueInteger
              ? {
                  value: option.valueInteger,
                  label: String(option.valueInteger),
                }
              : undefined
          case 'date':
            return option.valueDate
              ? {
                  value: option.valueDate,
                  label: option.valueDate, //todo: Date translation needed ??
                }
              : undefined
          case 'time':
            return option.valueTime
              ? {
                  value: option.valueTime,
                  label: option.valueTime, //todo: Time translation needed ??
                }
              : undefined
          case 'text':
          case 'string':
            return option.valueString
              ? {
                  value: option.valueString,
                  label:
                    getTranslatedFhirElementText(
                      option._valueString,
                      this.language
                    ) || option.valueString,
                }
              : undefined
          case 'coding':
            return option.valueCoding?.code
              ? {
                  value: option.valueCoding.code,
                  label:
                    getTranslatedFhirElementText(
                      option.valueCoding._display,
                      this.language
                    ) ||
                    (option.valueCoding.display ?? option.valueCoding.code),
                }
              : undefined
          default:
            return undefined
        }
      }
      return this.answerOption
        .map(getOption)
        .reduce<ItemOption[]>((acc, option) => {
          if (option) {
            acc.push(option)
          }
          return acc
        }, [])
    } else {
      return []
    }
  }

  /**
   * Adds or replaces FhirValueSet if its needed for the question
   *
   * FhirValueSets are needed if their url is found as a key in valueSets
   *
   * @param valueSet
   */
  setFhirValueSet(fhirValueSet: FhirValueSet): void {
    Object.keys(this.valueSets).forEach((key) => {
      if (fhirValueSet.url === key) {
        this.valueSets[key] = fhirValueSet
      }
    })
  }

  generateQuestionnaireResponseItem(): R5.QuestionnaireResponseItem {
    const base: R5.QuestionnaireResponseItem = {
      linkId: this.linkId,
      text: this.text,
    }
    if (this.type === 'group') {
      return {
        linkId: this.linkId,
        item: this.item.map((item) => item.generateQuestionnaireResponseItem()),
      }
    } else if (this.getAnswer() === undefined) {
      return base
    } else {
      return {
        ...base,
        answer: this.answer,
      }
    }
  }
}
