import React from 'react'

import { Body } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { SectionBodyContainer } from '../../Consent.style'

type TextContainerProps = {
  text: string
}

export const TextContainer: React.FC<TextContainerProps> = ({ text }) => {
  return (
    <SectionBodyContainer>
      <Body>{text}</Body>
    </SectionBodyContainer>
  )
}
