import { Extension } from 'fhir/r5'
import { isValidExtensionProps } from '../lib/isValidExtensionProps'
import { ExtensionStyling, extensionStylingUrl } from './styling.type'
import { isExtensionStylingGridColumn } from './isExtensionStylingGridColumn'

export const isExtensionStyling = (
  extension: Extension
): extension is ExtensionStyling => {
  return (
    extension.url === extensionStylingUrl &&
    isValidExtensionProps(extension, [isExtensionStylingGridColumn])
  )
}
