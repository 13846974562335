import { DefaultValuesAccumulator } from './defaultValuesAccumulator.type'

export const setItemInitialWhenUndefined: DefaultValuesAccumulator = (
  acc,
  linkIdItems
) => {
  Object.keys(linkIdItems).forEach((linkId) => {
    if (acc[linkId] === undefined) {
      acc[linkId] = linkIdItems[linkId].getInitial()
    }
  })
  return acc
}
