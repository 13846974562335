import * as React from 'react'
import {
  Title,
  SubTitle,
  TagsLayout,
  tooltipTheme,
  TitleAndTagsContainer,
  TitleFlexBasis,
} from '../AaiCard.style'

import {
  SelectedCardLayout,
  Header,
  BodyLayout,
  TitleLayout,
  TitleContainer,
  HeaderContentLayout,
  Description,
} from './SelectedCard.style'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { ThemeProvider } from '@material-ui/styles'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { AaiBadge } from '../AaiBadge'
import { AaiIcon } from '../AaiIcon'
import { AaiCardSubmitButton } from '../AaiCardSubmitButton'
import { MarkdownComponent } from '~/lib/markdown/MarkdownComponent'
import { MethodsDropDownMenu } from '../MethodsDropDownMenu/MethodsDropDownMenu'

const removeDuplicateDescription = (aai: AppointmentActivityInstance) => {
  // e.g. Descriptions are removed for minor ailments because both description
  // and subdescriptions are the same. No need to show the same copy twice

  //it looks like this function is used for AAI cards on the CoBooking Upsell
  if (aai.details.description === aai.details.subDescription) {
    return {
      ...aai,
      details: {
        ...aai.details,
        description: '',
      },
    }
  }
  return aai
}

type SelectedApptCardProps = {
  aai: AppointmentActivityInstance
  available: boolean
  appointmentMethod?: string
  onCollapse: (id: string) => void
  onSubmit: (id: string) => void
}

export const SelectedCard: React.FC<SelectedApptCardProps> = ({
  aai,
  available,
  appointmentMethod = undefined,
  onCollapse,
  onSubmit,
}) => {
  const filteredAai = React.useMemo(() => {
    return removeDuplicateDescription(aai)
  }, [aai])

  const {
    id: appointmentActivityInstanceId,
    details: { description, title, subDescription, subtitle, badges },
    settings: { iconUrl, enabledMethods },
  } = filteredAai

  return (
    <>
      <SelectedCardLayout
        aria-expanded
        role="button"
        tabIndex={0}
        elevation={2}
      >
        <Header onClick={() => onCollapse(appointmentActivityInstanceId)}>
          <AaiIcon src={iconUrl} hideWhenSm={true} />
          <HeaderContentLayout>
            <TitleContainer>
              <TitleAndTagsContainer>
                <TitleFlexBasis>
                  <TitleLayout>
                    <Title>{title}</Title>
                    {subtitle !== '' && <SubTitle>{subtitle}</SubTitle>}
                  </TitleLayout>
                </TitleFlexBasis>
                <TagsLayout>
                  <ThemeProvider theme={tooltipTheme}>
                    {badges.map((badge, index) => (
                      <AaiBadge badge={badge} key={index} />
                    ))}
                  </ThemeProvider>
                </TagsLayout>
              </TitleAndTagsContainer>
            </TitleContainer>
            {Boolean(description) && (
              <Description>
                <MarkdownComponent>{description}</MarkdownComponent>
              </Description>
            )}
          </HeaderContentLayout>
        </Header>
        <BodyLayout>
          <MethodsDropDownMenu
            appointmentMethod={appointmentMethod}
            enabledMethods={enabledMethods}
          />
          <Heading4>
            <MarkdownComponent>{subDescription}</MarkdownComponent>
          </Heading4>
        </BodyLayout>
      </SelectedCardLayout>
      <AaiCardSubmitButton
        onClick={() => {
          onSubmit(appointmentActivityInstanceId)
        }}
        isAvailable={Boolean(available)}
      />
    </>
  )
}
