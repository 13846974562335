export interface FeatureFlagCLI {
  setFeatureEnabled: (storageKey: string, featureKey: string) => void
  setFeatureDisabled: (storageKey: string, featureKey: string) => void
  list: (storageKey: string) => Record<string, boolean>
}

const getFlags = (storageKey: string) => {
  try {
    const flags: Record<string, boolean> = JSON.parse(
      window.localStorage.getItem(storageKey) || '{}'
    )

    return flags
  } catch (e) {
    console.error(e)
    return {}
  }
}
const updateFlagStorage = (
  storageKey: string,
  featureKeys: string[],
  status: boolean
) => {
  try {
    const flags = getFlags(storageKey)
    const event = new Event(storageKey)

    featureKeys.forEach((featureKey) => {
      flags[featureKey] = status
    })

    window.localStorage.setItem(storageKey, JSON.stringify(flags))
    window.dispatchEvent(event)
  } catch (e) {
    console.error(e)
  }
}

export const initializeFeatureFlagCLI = () => {
  window.FeatureFlagCLI = window.FeatureFlagCLI || {
    setEnabled: (storageKey: string, ...featureKeys: string[]) =>
      updateFlagStorage(storageKey, featureKeys, true),
    setDisabled: (storageKey: string, ...featureKeys: string[]) =>
      updateFlagStorage(storageKey, featureKeys, false),
    list: (storageKey: string) => getFlags(storageKey),
  }
}
