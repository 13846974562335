import React, { ErrorInfo } from 'react'
import { DefaultFallback } from './DefaultFallback'

export interface ErrorFallbackProps {
  error?: Error
  resetErrorBoundary: () => void
}

type ErrorBoundaryProps = {
  noFallback: boolean
  onError?: (error: Error, errorInfo: ErrorInfo) => void
  render: (props: ErrorFallbackProps) => JSX.Element
}

type ErrorBoundaryState = {
  error: Error | undefined
}

export class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    noFallback: false,
    render: () => <DefaultFallback />,
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      error: undefined,
    }
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this)
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError } = this.props
    if (onError) onError(error, errorInfo)
  }

  resetErrorBoundary() {
    this.setState({ error: undefined })
  }

  render() {
    const { children, noFallback, render: FallbackComponent } = this.props
    const { error } = this.state

    if (!noFallback && error) {
      return (
        <FallbackComponent
          error={error}
          resetErrorBoundary={this.resetErrorBoundary}
        />
      )
    }
    return children
  }
}

export default ErrorBoundary
