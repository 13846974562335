import React from 'react'
import MuiAlert, {
  AlertProps as MuiAlertProps,
  Color,
} from '@material-ui/lab/Alert'
import { ButtonProps, styled } from '@material-ui/core'
import { AlertIcon } from './AlertIcon'
import { AlertButton } from './AlertButton'
import { IconButton } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'

export type ExtendedAlertSeverity = Color | 'loading'
export type ExtendedAlertSize = 'small' | 'medium' | 'large'

/**
 * Internal to MedMe Alert, overrides some baseline
 * styles of MUI's alert component
 */
export const BaseAlert = styled(MuiAlert)(() => ({
  borderRadius: 4,
  // we default to outline buttons, by default
  // MUI adds a negative margin for text buttons
  '& .MuiAlert-action': {
    marginRight: 0,
  },
}))

export const IconButtonWarning = styled(IconButton)(({ theme }) => ({
  '&.MuiButton-text': {
    color: theme.palette.warning.dark,
  },
}))

export interface AlertProps extends Omit<MuiAlertProps, 'severity'> {
  size?: ExtendedAlertSize
  severity?: ExtendedAlertSeverity
  buttonProps?: Omit<ButtonProps, 'children'>
  buttonText?: string
  onButtonClick?: () => void
  onDismiss?: () => void
}

/**
 * Extension of the MUI alert to allow for MedMe
 * specific visual stying and the addition of a 'loading'
 * severity to be used when waiting on async requests
 *
 * Automatically injects the proper iconography and button
 * styles with consistent MUI overrideable pattern
 */
export const Alert: React.FC<AlertProps> = ({
  size,
  buttonProps,
  buttonText = '',
  severity = 'success',
  icon = <AlertIcon severity={severity} size={size} />,
  onDismiss,
  ...props
}) => {
  const compatibleSeverity = severity === 'loading' ? 'info' : severity
  const isButtonRenderable = !!(buttonText.length && buttonProps)

  const buttonSpacer = onDismiss ? { marginRight: '12px' } : {}

  return (
    <BaseAlert
      severity={compatibleSeverity}
      icon={icon}
      action={
        <>
          {isButtonRenderable && (
            <AlertButton
              severity={severity}
              size={size}
              style={{ ...buttonSpacer, ...buttonProps.style }}
              {...buttonProps}
            >
              {buttonText}
            </AlertButton>
          )}
          {onDismiss && (
            <IconButtonWarning
              color="primary"
              aria-label="dismiss warning"
              style={{ padding: '0' }}
              onClick={onDismiss}
            >
              <CloseOutlined />
            </IconButtonWarning>
          )}
        </>
      }
      {...props}
    />
  )
}
