import * as React from 'react'
import { white } from '~/sharedStyles/colorPalette'
import {
  ProvinceSelectorContainer,
  ProvinceSelectorLayout,
  Title,
  ValidationText,
  ProvinceSelectorMenuItem,
} from './provinceSelector.styles'
import { ControlledSelect } from '~/util/minorAilments/reactHookFormSelect'
import { Province } from '~/util/minorAilments/provincesUtil'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

interface FormInputProps {
  provinces: Province[]
  name: string
  control: any
  label: string
  methods: any
  selectedProvince: string
  setSelectedProvince?: any
}

export const ProvinceSelector: React.FC<FormInputProps> = ({
  provinces,
  name,
  control,
  label,
  methods,
  selectedProvince,
}) => {
  const { t } = useMedMeTranslation('patient')

  return (
    <ProvinceSelectorContainer>
      <Title>{t('activityLanding.provinceSelector')}</Title>
      <ProvinceSelectorLayout>
        <ControlledSelect
          id={name}
          data-testid="provinceSelector"
          name={name}
          label={label}
          register={methods.register('province')}
          control={control}
          defaultValue={selectedProvince}
          variant="outlined"
          margin="normal"
          style={{ backgroundColor: white }}
        >
          {provinces.map((province, index) => {
            return (
              <ProvinceSelectorMenuItem
                key={index}
                selected={selectedProvince === province.value}
                value={province.value}
              >
                <div>{t(province.value, { ns: 'common' })}</div>
              </ProvinceSelectorMenuItem>
            )
          })}
        </ControlledSelect>
      </ProvinceSelectorLayout>
      {selectedProvince === '' && (
        <ValidationText data-testid="provinceSelectorValidationText">
          {t('activityLanding.selectProvince')}
        </ValidationText>
      )}
    </ProvinceSelectorContainer>
  )
}
