import React from 'react'
import { Route } from 'react-router-dom'
import { QuestionnaireTool } from '~/pages/QuestionnaireTool'
import { isDev, isLocalHost } from '~/util/envMethods'

/***
 *  Routes used to test in dev
 */
export const DevRoutes = () => {
  if (!isDev || !isLocalHost) return null
  return (
    <Route path="/questionnaires">
      <QuestionnaireTool />
    </Route>
  )
}
