import styled from 'styled-components'
import { DESKTOP_MIN_SCREEN_SIZE } from '../../const'

export const Body = styled.div`
  flex: 1 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: ${DESKTOP_MIN_SCREEN_SIZE}px) {
    flex-flow: row-reverse nowrap;
    width: 1065px;
  }
`

export const SideContainer = styled.div``

export const SideBar = styled.div`
  display: none;
  flex-direction: column;
  padding: 2rem 0;
  position: sticky;
  row-gap: 1.25rem;
  top: 5rem;

  @media only screen and (min-width: ${DESKTOP_MIN_SCREEN_SIZE}px) {
    display: flex;
    width: 265px;
  }
`

export const PageContainer = styled.div`
  flex: 1 0;
  display: flex;
  padding: 2rem;
`
