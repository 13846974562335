import env from './env.staging.medmeapp.com.json'

export default {
  'medme.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-ns.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-ab.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-sk.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-nb.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-pe.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-mb.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-bc.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-on.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'medme-nl.staging.medmeapp.com': env.MEDME_TENANT_ID,
  'sdm-sit.staging.medmeapp.com': env.SDM_SIT_TENANT_ID,
  'sdm-pt.staging.medmeapp.com': env.SDM_PT_TENANT_ID,
  'lcl-sit.staging.medmeapp.com': env.LCL_SIT_TENANT_ID,
  'lcl-pt.staging.medmeapp.com': env.LCL_PT_TENANT_ID,
}
