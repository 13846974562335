import * as React from 'react'
import { FhirQuestionnaireItemComponent } from '../type'
import { FhirBooleanInput } from './FhirBooleanInput'
import { FhirChoiceInput } from './FhirChoiceInput'
import { FhirDateInput } from './FhirDateInput'
import { FhirDisplayLabel } from './FhirDisplayLabel'
import { FhirStringComponent } from './FhirStringInput'

import { FhirIntegerInput } from './FhirIntegerInput'
import { withGrid } from '../withGrid'

export const FhirLeafComponent: FhirQuestionnaireItemComponent = React.memo(
  withGrid((props) => {
    const { questionnaireItem } = props

    switch (questionnaireItem.type) {
      case 'display':
        return <FhirDisplayLabel {...props} />
      case 'string':
        return <FhirStringComponent {...props} />
      case 'boolean':
        return <FhirBooleanInput {...props} />
      case 'integer':
        return <FhirIntegerInput {...props} />
      case 'date':
        return <FhirDateInput {...props} />
      // TODO: need to change the logic for this component to use answer constraint
      case 'coding':
        return <FhirChoiceInput {...props} />
      default:
        throw new Error(
          'FhirLeafComponent: received unsupported type: ' +
            JSON.stringify(questionnaireItem, null, 2)
        )
    }
  })
)
