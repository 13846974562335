const initialState = {
  offset: 0,
  search: '',
  sortBy: 'WAITLIST_JOINED_DATE_ASC',
  showFilterOptions: true,
  appointmentTypes: null,
  isScreeningAnswerAllNull: false,
  createdBy: null,
  screeningQuestionIds: null,
  frontlineWorkerQuestions: null,
  healthcareWorkerQuestions: null,
  waitlistJoinedDate: null,
  isAge: true,
  patientBirthYear: null,
  patientAge: null,
  email: null,
  cell: null,
  linkSentAt: null,
  linkSentCount: null,
  isStandBy: null,
  postalCode: null,
  tabIndex: 0,
  lastMassSendTime: null,
  bcFrontlineWorkerQuestionId: null,
  abHealthcareWorkerQuestionId: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COVID_WAITLIST_OFFSET:
      return {
        ...state,
        offset: action.offset,
      }
    case UPDATE_COVID_WAITLIST_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    case UPDATE_COVID_WAITLIST_APPOINTMENT_TYPES:
      return {
        ...state,
        appointmentTypes: action.appointmentTypes,
      }
    case UPDATE_COVID_WAITLIST_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case CLEAR_COVID_WAITLIST_FILTER:
      return {
        ...initialState,
        showFilterOptions: state.showFilterOptions,
      }
    case UPDATE_COVID_WAITLIST_SHOW_FILTER_OPTIONS:
      return {
        ...state,
        showFilterOptions: action.toggleFilterOptions,
      }
    case UPDATE_COVID_WAITLIST_CREATED_BY:
      return {
        ...state,
        createdBy: action.createdBy,
      }
    case UPDATE_COVID_WAITLIST_ANSWER_ALL_NULL:
      return {
        ...state,
        isScreeningAnswerAllNull: action.isScreeningAnswerAllNull,
      }
    case UPDATE_COVID_WAITLIST_IS_AGE:
      return {
        ...state,
        isAge: action.isAge,
      }
    case UPDATE_COVID_WAITLIST_BIRTH_YEAR:
      return {
        ...state,
        patientBirthYear: action.patientBirthYear,
      }
    case UPDATE_COVID_WAITLIST_AGE:
      return {
        ...state,
        patientAge: action.patientAge,
      }
    case UPDATE_COVID_WAITLIST_EMAIL:
      return {
        ...state,
        email: action.email,
      }
    case UPDATE_COVID_WAITLIST_CELL:
      return {
        ...state,
        cell: action.cell,
      }
    case UPDATE_COVID_WAITLIST_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex,
      }
    case UPDATE_COVID_WAITLIST_QUESTION_IDS:
      return {
        ...state,
        screeningQuestionIds: action.screeningQuestionIds,
      }
    case UPDATE_COVID_FRONTLINE_WORKER_WAITLIST_QUESTIONS:
      return {
        ...state,
        frontlineWorkerQuestions: action.frontlineWorkerQuestions,
      }
    case UPDATE_BC_FRONTLINE_WORKER_QUESTION_ID:
      return {
        ...state,
        bcFrontlineWorkerQuestionId: action.bcFrontlineWorkerQuestionId,
      }
    case UPDATE_COVID_HEALTHCARE_WORKER_WAITLIST_QUESTIONS:
      return {
        ...state,
        healthcareWorkerQuestions: action.healthcareWorkerQuestions,
      }
    case UPDATE_AB_HEALTHCARE_WORKER_QUESTION_ID:
      return {
        ...state,
        abHealthcareWorkerQuestionId: action.abHealthcareWorkerQuestionId,
      }
    case UPDATE_COVID_WAITLIST_JOIN_DATE:
      return {
        ...state,
        waitlistJoinedDate: action.waitlistJoinedDate,
      }
    case UPDATE_COVID_WAITLIST_LINK_SENT_AT:
      return {
        ...state,
        linkSentAt: action.linkSentAt,
      }
    case UPDATE_COVID_WAITLIST_LINK_SENT_COUNT:
      return {
        ...state,
        linkSentCount: action.linkSentCount,
      }
    case UPDATE_COVID_WAITLIST_IS_STANDBY:
      return {
        ...state,
        isStandBy: action.isStandBy,
      }
    case UPDATE_COVID_WAITLIST_POSTAL_CODE:
      return {
        ...state,
        postalCode: action.postalCode,
      }
    case UPDATE_LAST_MASS_SEND_TIME:
      return {
        ...state,
        lastMassSendTime: action.lastMassSendTime,
      }
    default:
      return state
  }
}

const UPDATE_COVID_WAITLIST_OFFSET = 'UPDATE_COVID_WAITLIST_OFFSET'
const UPDATE_COVID_WAITLIST_SEARCH = 'UPDATE_COVID_WAITLIST_SEARCH'
const CLEAR_COVID_WAITLIST_FILTER = 'CLEAR_COVID_WAITLIST_FILTER'
const UPDATE_COVID_WAITLIST_APPOINTMENT_TYPES =
  'UPDATE_COVID_WAITLIST_APPOINTMENT_TYPES'
const UPDATE_COVID_WAITLIST_SHOW_FILTER_OPTIONS =
  'UPDATE_COVID_WAITLIST_SHOW_FILTER_OPTIONS'
const UPDATE_COVID_WAITLIST_CREATED_BY = 'UPDATE_COVID_WAITLIST_CREATED_BY'
const UPDATE_COVID_WAITLIST_ANSWER_ALL_NULL =
  'UPDATE_COVID_WAITLIST_ANSWER_ALL_NULL'
const UPDATE_COVID_WAITLIST_IS_AGE = 'UPDATE_COVID_WAITLIST_IS_AGE'
const UPDATE_COVID_WAITLIST_AGE = 'UPDATE_COVID_WAITLIST_AGE'
const UPDATE_COVID_WAITLIST_BIRTH_YEAR = 'UPDATE_COVID_WAITLIST_BIRTH_YEAR'
const UPDATE_COVID_WAITLIST_EMAIL = 'UPDATE_COVID_WAITLIST_EMAIL'
const UPDATE_COVID_WAITLIST_CELL = 'UPDATE_COVID_WAITLIST_CELL'
const UPDATE_COVID_WAITLIST_TAB_INDEX = 'UPDATE_COVID_WAITLIST_TAB_INDEX'
const UPDATE_COVID_WAITLIST_QUESTION_IDS = 'UPDATE_COVID_WAITLIST_QUESTION_IDS'
const UPDATE_COVID_FRONTLINE_WORKER_WAITLIST_QUESTIONS =
  'UPDATE_COVID_FRONTLINE_WORKER_WAITLIST_QUESTIONS'
const UPDATE_BC_FRONTLINE_WORKER_QUESTION_ID =
  'UPDATE_BC_FRONTLINE_WORKER_QUESTION_ID'
const UPDATE_COVID_HEALTHCARE_WORKER_WAITLIST_QUESTIONS =
  'UPDATE_COVID_HEALTHCARE_WORKER_WAITLIST_QUESTIONS'
const UPDATE_AB_HEALTHCARE_WORKER_QUESTION_ID =
  'UPDATE_AB_HEALTHCARE_WORKER_QUESTION_ID'
const UPDATE_COVID_WAITLIST_JOIN_DATE = 'UPDATE_COVID_WAITLIST_JOIN_DATE'
const UPDATE_COVID_WAITLIST_SORT_BY = 'UPDATE_COVID_WAITLIST_SORT_BY'
const UPDATE_COVID_WAITLIST_LINK_SENT_AT = 'UPDATE_COVID_WAITLIST_LINK_SENT_AT'
const UPDATE_COVID_WAITLIST_LINK_SENT_COUNT =
  'UPDATE_COVID_WAITLIST_LINK_SENT_COUNT'
const UPDATE_COVID_WAITLIST_IS_STANDBY = 'UPDATE_COVID_WAITLIST_IS_STANDBY'
const UPDATE_COVID_WAITLIST_POSTAL_CODE = 'UPDATE_COVID_WAITLIST_POSTAL_CODE'
const UPDATE_LAST_MASS_SEND_TIME = 'UPDATE_LAST_MASS_SEND_TIME'

export const updateOffset = (offset) => ({
  type: UPDATE_COVID_WAITLIST_OFFSET,
  offset,
})

export const updateSearch = (search) => ({
  type: UPDATE_COVID_WAITLIST_SEARCH,
  search,
})

export const clearFilter = () => ({
  type: CLEAR_COVID_WAITLIST_FILTER,
})

export const updateAppointmentTypes = (appointmentTypes) => ({
  type: UPDATE_COVID_WAITLIST_APPOINTMENT_TYPES,
  appointmentTypes,
})

export const updateSortBy = (sortBy) => ({
  type: UPDATE_COVID_WAITLIST_SORT_BY,
  sortBy,
})
export const updateShowFilterOptions = (toggleFilterOptions) => ({
  type: UPDATE_COVID_WAITLIST_SHOW_FILTER_OPTIONS,
  toggleFilterOptions,
})

export const updateCreatedBy = (createdBy) => ({
  type: UPDATE_COVID_WAITLIST_CREATED_BY,
  createdBy,
})

export const updateIsScreeningAnswerAllNull = (isScreeningAnswerAllNull) => ({
  type: UPDATE_COVID_WAITLIST_ANSWER_ALL_NULL,
  isScreeningAnswerAllNull,
})

export const updateIsAge = (isAge) => ({
  type: UPDATE_COVID_WAITLIST_IS_AGE,
  isAge,
})

export const updateAge = (patientAge) => ({
  type: UPDATE_COVID_WAITLIST_AGE,
  patientAge,
})

export const updateBirthYear = (patientBirthYear) => ({
  type: UPDATE_COVID_WAITLIST_BIRTH_YEAR,
  patientBirthYear,
})

export const updateEmail = (email) => ({
  type: UPDATE_COVID_WAITLIST_EMAIL,
  email,
})

export const updateCell = (cell) => ({
  type: UPDATE_COVID_WAITLIST_CELL,
  cell,
})

export const updateTabIndex = (tabIndex) => ({
  type: UPDATE_COVID_WAITLIST_TAB_INDEX,
  tabIndex,
})

export const updateScreeningQuestionIds = (screeningQuestionIds) => ({
  type: UPDATE_COVID_WAITLIST_QUESTION_IDS,
  screeningQuestionIds,
})

export const updateFrontlineWorkerQuestions = (frontlineWorkerQuestions) => ({
  type: UPDATE_COVID_FRONTLINE_WORKER_WAITLIST_QUESTIONS,
  frontlineWorkerQuestions,
})

export const updateBcFrontlineWorkerQuestionId = (
  bcFrontlineWorkerQuestionId
) => ({
  type: UPDATE_BC_FRONTLINE_WORKER_QUESTION_ID,
  bcFrontlineWorkerQuestionId,
})

export const updateHealthcareWorkerQuestions = (healthcareWorkerQuestions) => ({
  type: UPDATE_COVID_HEALTHCARE_WORKER_WAITLIST_QUESTIONS,
  healthcareWorkerQuestions,
})

export const updateAbHealthcareWorkerQuestionId = (
  abHealthcareWorkerQuestionId
) => ({
  type: UPDATE_AB_HEALTHCARE_WORKER_QUESTION_ID,
  abHealthcareWorkerQuestionId,
})

export const updateWaitlistJoinDate = (waitlistJoinedDate) => ({
  type: UPDATE_COVID_WAITLIST_JOIN_DATE,
  waitlistJoinedDate,
})

export const updateLinkSentAt = (linkSentAt) => ({
  type: UPDATE_COVID_WAITLIST_LINK_SENT_AT,
  linkSentAt,
})

export const updateLinkSentCount = (linkSentCount) => ({
  type: UPDATE_COVID_WAITLIST_LINK_SENT_COUNT,
  linkSentCount,
})

export const updateIsStandBy = (isStandBy) => ({
  type: UPDATE_COVID_WAITLIST_IS_STANDBY,
  isStandBy,
})

export const updatePostalCode = (postalCode) => ({
  type: UPDATE_COVID_WAITLIST_POSTAL_CODE,
  postalCode,
})

export const updateLastMassSendTime = (lastMassSendTime) => ({
  type: UPDATE_LAST_MASS_SEND_TIME,
  lastMassSendTime,
})

export default reducer
