import React from 'react'
import { EditorProps } from 'react-draft-wysiwyg'
import { useController, useFormContext } from 'react-hook-form'
import { MarkdownTextField } from '~/componentsTs/Field/MarkdownTextField'

interface RichTextEditorProps extends EditorProps {
  name: string
  initialTextMarkup?: string
  disabled?: boolean
}

export const RichTextEditorField = ({
  initialTextMarkup,
  name,
  disabled,
}: RichTextEditorProps) => {
  const { control, setValue } = useFormContext()
  const {
    field: { onChange: formOnChange, value },
  } = useController({
    name,
    control,
    defaultValue: initialTextMarkup || '',
  })

  const handleFieldChange = (textMarkup: string) => {
    setValue(name, textMarkup, { shouldValidate: true })
    formOnChange(textMarkup)
  }

  return (
    <MarkdownTextField
      initialTextMarkup={value}
      onFieldChange={handleFieldChange}
      disabled={disabled}
    />
  )
}
