import styled from 'styled-components'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const ContactInformationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
`

export const SectionHeader = styled(Heading4)`
  margin-bottom: 1rem;
  font-weight: 600;
`
