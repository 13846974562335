import { useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  getCurrentCognitoUser,
  getSessionAsync,
  globalSignOutAsync,
} from '~/auth/awsCognitoMethods'
import {
  getAuthLogoutUrl,
  getIdToken,
  getIsSamlLogin,
  getRefreshToken,
  revokeRefreshTokenForEmailPasswordLogin,
  updateClientQueryParams,
} from '~/auth'
import { selectiveClearLocalStorage } from './util'
import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'

const cognitoLogout = async () => {
  const cognitoUser = getCurrentCognitoUser()
  if (cognitoUser) {
    await getSessionAsync(cognitoUser)
    await globalSignOutAsync(cognitoUser)
  }
}

let isLoggingOut = false

// WARNING, Only use this hook within components within Router wrappers as this uses history
const useLogoutHook = () => {
  const client = useApolloClient()
  const history = useHistory()
  const dispatch = useDispatch()
  // Some customers such as Rexall and SOF don't want their users to
  // be logged out of SAML when they logout of the app.
  // Therefore we control this logic with a feature flag
  const isSAMLLogoutDisabled = useFeatureFlag(
    FeatureFlag.DISABLE_SAML_LOGOUT,
    false
  )

  const logout = async (options = {}) => {
    if (isLoggingOut) return

    isLoggingOut = true

    const updatedOptions = {
      endPath: '/',
      resetCache: true,
      resetRedux: true,
      clearLocalStorage: true,
      clearCookie: true,
      logoutGql: true,
      logoutCognito: true,
      ...options,
    }
    const {
      endPath,
      resetCache,
      resetRedux,
      clearLocalStorage,
      clearCookie,
      logoutGql,
      logoutCognito,
    } = updatedOptions

    const idToken = getIdToken()
    if (
      idToken &&
      logoutCognito &&
      !isSAMLLogoutDisabled &&
      getIsSamlLogin(idToken)
    ) {
      await cognitoLogout().catch((err) =>
        console.error('Failed to logout of cognito\n', err)
      )
    }
    const refreshToken = getRefreshToken()

    if (refreshToken && logoutCognito) {
      await revokeRefreshTokenForEmailPasswordLogin(refreshToken).catch((err) =>
        console.error('Failed to revoke refresh token\n', err)
      )
    }

    if (clearCookie) {
      document.cookie =
        'path=/; domain=.microsoft.com; expires=' + new Date(0).toUTCString()
    }

    if (resetRedux) dispatch({ type: 'LOGOUT' })

    if (
      idToken &&
      logoutCognito &&
      !isSAMLLogoutDisabled &&
      getIsSamlLogin(idToken)
    ) {
      window.location.href = getAuthLogoutUrl()
    } else {
      if (resetCache) await client.cache.reset()
      if (logoutGql) {
        client.writeQuery(updateClientQueryParams(false, false, false, false))
      }
      if (endPath) history.push(endPath)
    }

    if (clearLocalStorage) {
      // clear all local storage on next tick to wait for components to unmount first
      setTimeout(selectiveClearLocalStorage, 0)
    }

    isLoggingOut = false
  }

  return {
    logout,
  }
}

export default useLogoutHook
