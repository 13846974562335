import React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import {
  TitleContainer,
  Title,
  Body,
  Card,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { SectionContainer, SectionHeader } from '../ReviewFormStep.styles'
import { transformToHumanReadableNumber } from '~/util/formatContact'
import dayjs from 'dayjs'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'

export function ContactInformation() {
  const { t } = useMedMeTranslation('patient')
  const { patientIntakeRecords, numberOfPersons } = useBookingContext()
  const waitlistIntake = patientIntakeRecords?.[0]

  if (!waitlistIntake) {
    throw new Error('waitlistIntake is not set')
  }

  const {
    firstName,
    lastName,
    birthDate,
    email,
    phone: { cell },
  } = waitlistIntake.patient

  const formattedCellPhone = transformToHumanReadableNumber(cell)

  return (
    <Card>
      <TitleContainer>
        <Title>{t('contactInformation.title')}</Title>
      </TitleContainer>
      <SectionContainer>
        <div>
          <Body>
            {firstName} {lastName}
          </Body>
          <Body>{dayjs(birthDate).format('YYYY-MM-DD')}</Body>
          <Body>{email}</Body>
          {formattedCellPhone && (
            <Body>
              {t('contactInformation.phone.cellAbbrev')}: {formattedCellPhone}
            </Body>
          )}
        </div>
        <div>
          <SectionHeader>{t('contactInformation.group.title')}</SectionHeader>
          <Body>{t('contactInformation.group.size', { numberOfPersons })}</Body>
        </div>
      </SectionContainer>
    </Card>
  )
}
