import React, { useMemo, createContext } from 'react'
import { isSdmEnterprise } from '~/util/enterpriseMethods'
import { isDev } from '~/util/envMethods'

import { postMessage, postWebViewGAEvent, WebViewAnyMessage } from './utils'
import { History } from 'history'
import { useManagePchSession } from '~/hooks/useManagePchSession'

interface WebViewContextValues {
  sendEvent: (
    name: WebViewAnyMessage['event'],
    data?: Record<string, string>
  ) => void
  inWebView: boolean
  pchSessionId: string | null
}

export const WebViewContext = createContext<WebViewContextValues>(
  {} as WebViewContextValues
)

export const useWebViewContext = () => React.useContext(WebViewContext)

interface WebViewProviderProps {
  history: History
}

export const WebViewProvider: React.FC<WebViewProviderProps> = ({
  children,
  history,
}) => {
  const inWebView = useMemo(() => {
    const params = new URLSearchParams(history.location.search)
    return params.has('webview') && params.get('webview') === 'true'
  }, [history.location.search])

  const { deletePchSessionCookie, pchSessionId } = useManagePchSession({
    isPchSessionActive: inWebView && isSdmEnterprise(),
    history,
  })

  const internalSendEvent = (
    name: WebViewAnyMessage['event'],
    data: Record<string, string> = {}
  ) => {
    if (!inWebView) {
      return
    }

    if (isDev && isSdmEnterprise()) {
      console.log('WebView Event Name:', name)
      console.log('WebView Event Data:', data)
    }

    if (['abandon', 'error', 'close'].includes(name)) {
      deletePchSessionCookie()
    }

    postWebViewGAEvent(name)
    postMessage({
      event: name,
      ...data,
    })
  }

  const contextValues: WebViewContextValues = {
    inWebView: inWebView,
    sendEvent: internalSendEvent,
    pchSessionId,
  }

  return (
    <WebViewContext.Provider value={contextValues}>
      {children}
    </WebViewContext.Provider>
  )
}
