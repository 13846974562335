import moment from 'moment'
import {
  EAppointmentStatus,
  PatientUpdatePatientAndCreateAppointmentV1Mutation,
  usePatientUpdatePatientAndCreateAppointmentV1Mutation,
} from '~/graphql/types/schema.type'
import { createAppointment } from '~/lib/appointment'
import { createPatient } from '~/lib/patient'
import {
  useAppointmentContext,
  usePatientContext,
  useRouterContext,
} from '~/pages/AppointmentIntake/contexts'
import { BookingContextInterface } from '../../../BookingContext'
import { createPatientAppointmentInput } from '../createAppointmentInput'
import { createPatientAndIntakeAnswerInput } from '../createPatientAndIntakeAnswerInput'
import { createIntakeSubmissionResult } from '../createIntakeSubmissionResult'
import { useAnalytics } from '~/hooks/useAnalytics'

export const useUpdatePatientAndCreateAppointment = (
  reservationId: BookingContextInterface['reservationId']
) => {
  const {
    appointmentTypeWithCalendarToken,
    patientToken,
    appointmentToken,
    intakeType,
  } = useRouterContext()
  const { patient } = usePatientContext()
  const { appointment } = useAppointmentContext()

  const [mutation] = usePatientUpdatePatientAndCreateAppointmentV1Mutation()

  const { trackAnalyticsEvent } = useAnalytics()

  const updatePatientAndCreateAppointment = async () => {
    if (!['bookingLink', 'bookNextAppointmentLink'].includes(intakeType)) {
      return
    }

    try {
      const isWaitlisted = appointment.status === EAppointmentStatus.WAITLIST
      const isNextAppointment = intakeType === 'bookNextAppointmentLink'
      const isWalkin = appointment.isWalkin ?? false

      const { data } = await mutation({
        variables: {
          key1: appointmentTypeWithCalendarToken,
          key2: patientToken,
          key3: isNextAppointment ? undefined : appointmentToken,
          patientCreatePatientAndIntakeAnswerInput:
            createPatientAndIntakeAnswerInput(patient, appointment),
          patientCreateAppointmentInput: createPatientAppointmentInput(
            appointment,
            reservationId
          ),
          isWalkin,
          isWaitlisted,
          isNextAppointment,
        },
        onCompleted: (
          _data: PatientUpdatePatientAndCreateAppointmentV1Mutation
        ) => {
          if (!isWaitlisted) {
            // flow is initiated by patient and not initiated by a pharmacist action
            // e.g. a current source of traffic is the MedMe CTA added to email notification templates
            if (intakeType === 'bookNextAppointmentLink') {
              trackAnalyticsEvent('pt__pt_appt_booking_common__end', {
                ui_flow: 'hooks',
                booking_type: isWalkin ? 'walk-in' : 'scheduled',
              })
            }

            // flow initiated by pharmacist invite action to patient
            // note: due to historical tech debt this actually creates an appointment record in the db
            if (intakeType === 'bookingLink') {
              trackAnalyticsEvent('pt__ph_appt_booking_invite__end', {
                ui_flow: 'hooks',
                booking_type: isWalkin ? 'walk-in' : 'scheduled',
              })
            }
          }
        },
      })

      const updatedPatient = createPatient(
        data?.patientUpdatePatientAndCreateAppointmentV1
      )

      const newAppointment = createAppointment(
        data?.patientUpdatePatientAndCreateAppointmentV1?.appointments?.reduce(
          (acc, resAppointment) => {
            if (acc === null) {
              if (resAppointment?.updatedAt) {
                return resAppointment
              }
              return null
            }

            if (!resAppointment?.updatedAt) {
              return acc
            }

            if (
              moment(resAppointment.updatedAt).isAfter(moment(acc.updatedAt))
            ) {
              return resAppointment
            }

            return acc
          },
          null
        )
      )

      return createIntakeSubmissionResult({
        patient: updatedPatient,
        appointment: newAppointment,
      })
    } catch (error) {
      const timeslotTaken =
        error?.graphQLErrors?.[0]?.extensions?.errorCode === '0038'

      return createIntakeSubmissionResult({
        error: String(error),
        timeslotTaken,
      })
    }
  }

  return {
    updatePatientAndCreateAppointment,
  }
}
