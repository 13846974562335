import React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

import {
  BookingDetailsLayout,
  Content,
  Title,
  PatientSection,
  PatientTitle,
  FormStepDetail,
  CircleIcon,
} from './BookingDetails.style'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'

export type FormStepDetails = {
  groupLabel: string
  items: {
    label: string
    isComplete: boolean
    isCurrent: boolean
    isDisabled: boolean
    isHidden: boolean
  }[]
}[]

export const BookingDetails: React.FC<{
  hideBookingDetails: boolean
  content: string
  formStepDetails: FormStepDetails
}> = ({ hideBookingDetails, content, formStepDetails }) => {
  const { t } = useMedMeTranslation('patient')
  const {
    state: { appointment },
  } = useBookingContext()
  const icon = (completed: boolean) =>
    completed ? <CheckCircleIcon /> : <CircleIcon />

  const showSteps = appointment.isWalkin || Boolean(appointment.startDateTime)

  return (
    <BookingDetailsLayout>
      <Title>{t('details')}</Title>
      <Content>
        {content
          .split('\n')
          .map((line, i) => (line ? <p key={i}>{line}</p> : <br key={i} />))}
      </Content>

      {!hideBookingDetails &&
        formStepDetails.map(({ groupLabel, items }, i) => (
          <PatientSection key={i}>
            {groupLabel && (
              <PatientTitle>
                {t('patient', { ns: 'common' })} {i + 1}
              </PatientTitle>
            )}

            {showSteps &&
              items
                .filter((item) => !item.isHidden)
                .map(({ isCurrent, isDisabled, isComplete, label }, j) => {
                  return (
                    <FormStepDetail
                      current={isCurrent}
                      disabled={isDisabled}
                      key={`${i}-${j}`}
                    >
                      {icon(isComplete)}
                      {label}
                    </FormStepDetail>
                  )
                })}
          </PatientSection>
        ))}
    </BookingDetailsLayout>
  )
}
