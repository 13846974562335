import { Extension } from 'fhir/r5'
import {
  ExtensionValidationOperator,
  validationOperatorCodes,
} from '../validation.type'

export const isExtensionValidationOperator = (
  extension: Extension
): extension is ExtensionValidationOperator => {
  return (
    extension.url === 'operator' &&
    (validationOperatorCodes as ReadonlyArray<string>).includes(
      extension.valueCode ?? ''
    ) &&
    Object.keys(extension).length === 2
  )
}
