import React, { HTMLFactory } from 'react'
import Markdown from 'markdown-to-jsx'
import {
  ListElement,
  OrderedList,
  UnorderedList,
  Bold,
  Italic,
} from './markdown.style'
import { StyledComponent } from 'styled-components'

interface MarkdownProps {
  children: string
  ulOverride?: StyledComponent<'ul', never>
  olOverride?: StyledComponent<'ol', never>
  liOverride?: StyledComponent<'li', never>
  aOverride?: StyledComponent<'a', never>
  strongOverride?: StyledComponent<'strong', never>
  emOverride?: StyledComponent<'em', never>
  options?: Omit<React.ComponentProps<typeof Markdown>, 'disableParsingRawHTML'>
}

const LinkRenderer: React.FC<
  React.ComponentProps<HTMLFactory<HTMLAnchorElement>>
> = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  )
}

export const MarkdownComponent: React.FC<MarkdownProps> = ({
  options,
  children,
  ulOverride = UnorderedList,
  olOverride = OrderedList,
  liOverride = ListElement,
  aOverride = LinkRenderer,
  strongOverride = Bold,
  emOverride = Italic,
}) => {
  return (
    <Markdown
      options={{
        ...options,
        overrides: {
          ul: { component: ulOverride },
          ol: { component: olOverride },
          li: { component: liOverride },
          a: { component: aOverride },
          strong: { component: strongOverride },
          em: { component: emOverride },
        },
        disableParsingRawHTML: true,
      }}
    >
      {children}
    </Markdown>
  )
}
