import { Engine, RuleSerializable } from 'json-rules-engine'
import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
} from '~/lib/fhir/fhirQuestionnaire'
import { createEnableWhenRule } from './createEnabledWhenRule'

const getEnableWhenEventRule = (
  item: FhirQuestionnaireItem
): RuleSerializable[] => {
  const { enableWhen } = item
  const enableWhenRule = enableWhen ? [createEnableWhenRule(item)] : []

  return enableWhenRule
}

export const addEnableWhenEventRules =
  (questionnaire: FhirQuestionnaire) => (rulesEngine: Engine) => {
    const rules = Object.values(questionnaire.linkIdItems).flatMap(
      getEnableWhenEventRule
    )

    rules.forEach((rule) => rulesEngine.addRule(rule))
    return rulesEngine
  }
