import { JsonPresetFormStep } from '../../jsonFormSteps'
import { CreateFormStep } from '../createFormStep'
import { completionFormStep } from './completionFormStep'
import { ailmentSelectorFormStep } from './ailmentSelectorFormStep'
import { reviewAndConfirmFormStep } from './reviewAndConfirmFormStep'
import { storeTimeslotSelectorFormStep } from './storeTimeslotSelectorFormStep'
import { consentFormStep } from './consentFormStep'
import { activityLandingFormStep } from './activityLandingFormStep'
import { activityGroupLandingFormStep } from './activityGroupLandingFormStep'
import { bookingErrorTimeslotSelectorFormStep } from './bookingErrorTimeslotSelectorFormStep'
import { cancelledAppointmentLandingFormStep } from './cancelledAppointmentLandingFormStep'
import { pendingRescheduleLandingFormStep } from './pendingRescheduleLandingFormStep'

export const createPresetFormStep = (
  json: JsonPresetFormStep
): ReturnType<CreateFormStep> => {
  switch (json.preset) {
    case 'consent':
      return consentFormStep(json)
    case 'storeTimeslotSelector':
      return storeTimeslotSelectorFormStep(json)
    case 'completion':
      return completionFormStep(json)
    case 'ailmentSelector':
      return ailmentSelectorFormStep(json)
    case 'reviewAndConfirm':
      return reviewAndConfirmFormStep(json)
    case 'activityLanding':
      return activityLandingFormStep(json)
    case 'activityGroupLanding':
      return activityGroupLandingFormStep(json)
    case 'bookingErrorTimeslotSelector':
      return bookingErrorTimeslotSelectorFormStep(json)
    case 'cancelledAppointmentLanding':
      return cancelledAppointmentLandingFormStep(json)
    case 'pendingRescheduleLanding':
      return pendingRescheduleLandingFormStep(json)
    default:
      throw new Error(`Unknown preset form step: ${json.preset}`)
  }
}
