import { Engine, RuleSerializable } from 'json-rules-engine'
import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
} from '~/lib/fhir/fhirQuestionnaire'
import { Priority } from './priority'
import { validationErrorEventType } from '../events'
import { createRequiredRule } from './createRequiredRule'
import { TFunction } from 'i18next'

const getValidationRulesFromItem = (
  item: FhirQuestionnaireItem,
  t: TFunction
): RuleSerializable[] => {
  const { required } = item
  const requiredRule: RuleSerializable[] = required
    ? [createRequiredRule(item.linkId, t)]
    : []
  const validationRules: RuleSerializable[] =
    item.supportedExtensions.validationRuleGenerators?.map((fn) =>
      fn({
        fact: item.linkId,
        eventType: validationErrorEventType,
        priority: Priority.validationError,
      })
    ) ?? []

  const rules: RuleSerializable[] = [...requiredRule, ...validationRules]

  return rules
}

export const addValidationRules =
  (questionnaire: FhirQuestionnaire, t: TFunction) => (rulesEngine: Engine) => {
    const rules: RuleSerializable[] = Object.values(
      questionnaire.linkIdItems
    ).flatMap((item) => getValidationRulesFromItem(item, t))
    rules.forEach((rule) => rulesEngine.addRule(rule))
    return rulesEngine
  }
