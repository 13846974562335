import React from 'react'

// components
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

// styles
import { MenuList, MenuItem } from './menu.styles'

const Menu = ({ open, setOpen, options, anchorRef }) => {
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="right-end"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                {options.map(({ title, onClick }, i) => (
                  <MenuItem key={i} onClick={onClick}>
                    {title}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default Menu
