import styled from 'styled-components'
import { Body } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const SelectContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;

  @media only screen and (orientation: landscape) {
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 0.5rem;
  }
`

export const SectionTitle = styled(Body)`
  font-weight: 700;
  margin-bottom: 0.5rem;

  @media only screen and (orientation: landscape) {
    margin-bottom: 0;
  }
`

export const Container = styled.div`
  display: grid;
  gap: 1rem;
`

export const BoldText = styled.span`
  font-weight: 700;
`
