import R5 from 'fhir/r5'

interface Extension {
  url: string
  extension?: Extension[]
  valueCode?: string
  valueString?: string
}

export const getTranslatedFhirElementText = (
  fhirElement: R5.Element | undefined,
  language: string
): string | undefined => {
  if (!fhirElement || !fhirElement.extension) {
    return undefined
  }

  const translations = fhirElement.extension.filter(
    (ext: Extension) =>
      ext.url === 'http://hl7.org/fhir/StructureDefinition/translation'
  )

  for (const translation of translations) {
    const langExtension = translation.extension?.find(
      (ext: Extension) => ext.url === 'lang' && ext.valueCode === language
    )

    if (langExtension) {
      const valueStringExtension = translation.extension?.find(
        (ext: Extension) => ext.url === 'content' && ext.valueString
      )

      if (valueStringExtension?.valueString) {
        return valueStringExtension.valueString
      }
    }
  }

  return undefined
}
