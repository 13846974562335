import * as React from 'react'
import { ProvinceSelector } from '../../AilmentSelectorFormStepComponent/AilmentSelector/components/provinceSelector'
import { Title, SubTitle } from './activityGroupCards.styles'
import { Province } from '~/util/minorAilments/provincesUtil'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { AppointmentActivityGroup } from '~/lib/appointmentActivityGroup'
import { FormStepContentLayout } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { useFormContext } from 'react-hook-form'
import { NoServicesAvailable } from '~/pages/AppointmentIntake/components/NoServicesAvailable'
import { ActivityGroupLandingFormState } from '..'
import { MarkdownComponent } from '~/lib/markdown/MarkdownComponent'
import { AppointmentActivityCardContainer } from '~/componentsTs/AppointmentActivityCardContainer'
import { IAaiCardProps } from '~/componentsTs/AppointmentActivityCardContainer/AppointmentActivityCardContainer.types'

type ActivityGroupCardsProps = {
  selectedProvince: string
  appointmentActivityUrlParam: string
  appointmentActivityGroup: AppointmentActivityGroup
  appointmentActivities: AppointmentActivityInstance[]
  hideProvinceSelector: boolean
  provinces: Province[]
  onSubmit: (data: ActivityGroupLandingFormState) => unknown
}

export interface SymptomToActivities {
  [key: string]: AppointmentActivityInstance[]
}

export const ActivityGroupCards: React.FC<ActivityGroupCardsProps> = ({
  selectedProvince,
  appointmentActivityUrlParam,
  appointmentActivityGroup,
  hideProvinceSelector,
  appointmentActivities,
  provinces,
  onSubmit,
}) => {
  const methods = useFormContext<ActivityGroupLandingFormState>()
  const { handleSubmit, control, setValue, watch } = methods
  const isProvinceSelected = Boolean(watch('province'))
  const onSubmitHandler = handleSubmit(onSubmit)
  const [selectedApptTypeId, setSelectedApptTypeId] = React.useState('')
  const { title, subDescription } = appointmentActivityGroup

  return (
    <FormStepContentLayout data-testid="activityGroupCards">
      <form onSubmit={onSubmitHandler} data-testid="activityGroupCardsFrom">
        <Title>{title}</Title>
        <SubTitle>
          <MarkdownComponent>{subDescription}</MarkdownComponent>
        </SubTitle>
        {!hideProvinceSelector &&
          !appointmentActivityUrlParam &&
          provinces.length > 1 && (
            <ProvinceSelector
              provinces={provinces}
              name="province"
              label="Province"
              control={control}
              methods={methods}
              selectedProvince={selectedProvince}
            />
          )}
        <div style={{ display: 'grid', rowGap: '1.5rem' }}>
          {isProvinceSelected &&
            (appointmentActivities.length > 0 ? (
              appointmentActivities.map((appointment, index) => {
                const cardContainerProps: IAaiCardProps = {
                  aai: appointment,
                  isSelected:
                    selectedApptTypeId === appointment.appointmentActivityId,
                  isAvailable: !appointment.isPrivate || Boolean(''),
                  onExpand: setSelectedApptTypeId,
                  onCollapse: setSelectedApptTypeId,
                  onSubmit: (id) => {
                    setValue('appointmentId', id)
                  },
                }

                return (
                  <div
                    key={index}
                    data-testid={`apptType${appointment.appointmentActivityId}`}
                    data-cy={appointment.name}
                  >
                    <AppointmentActivityCardContainer
                      {...cardContainerProps}
                      isLegacy={true}
                    />
                  </div>
                )
              })
            ) : (
              <div data-testid="noServicesAvailable">
                <NoServicesAvailable />
              </div>
            ))}
        </div>
      </form>
    </FormStepContentLayout>
  )
}
