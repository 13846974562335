import styled from 'styled-components'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { red, white, black } from '~/sharedStyles/colorPalette'
import { MenuItem } from '@material-ui/core'
import { hexToRGB } from '~/util/minorAilments/hexToRgb'

interface MenuItemProps {
  selected: boolean
}

export const ProvinceSelectorLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 30%;
  @media (max-width: 900px) {
    width: 40%;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`

export const ProvinceSelectorContainer = styled.div`
  margin-bottom: 2rem;
`

export const Title = styled(Heading4)`
  font-weight: 700;
`

export const ValidationText = styled(Heading4)`
  color: ${red};
`
export const ProvinceSelectorMenuItem = styled(MenuItem)<MenuItemProps>`
  height: 3rem;
  background-color: ${({ theme }) =>
    (props) =>
      props.selected ? theme.color.backgroundPrimary : white};
  color: ${({ theme }) =>
    (props) =>
      props.selected ? theme.color.backgroundPrimary : black};
  font-weight: ${(props) => props.selected && '500'};
  &.MuiListItem-root.Mui-selected {
    background-color: ${({ theme }) =>
      hexToRGB(theme.color.backgroundPrimary, '0.1')};
  }
  &.MuiListItem-button:hover {
    text-decoration: none;
    background-color: ${({ theme }) =>
      hexToRGB(theme.color.backgroundPrimary, '0.1')};
  }
`
