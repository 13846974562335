import { Engine } from 'json-rules-engine'
import { isEnableWhenEvent } from './events'

export const addEnableWhenEmitter =
  (onUpdate: (value: Record<string, true | undefined>) => void) =>
  (rulesEngine: Engine) => {
    rulesEngine.on('success', (event, _almanac) => {
      if (isEnableWhenEvent(event)) {
        onUpdate({
          [event.params.fieldName]: undefined,
        })
      }
    })
    rulesEngine.on('failure', (event, _almanac) => {
      if (isEnableWhenEvent(event)) {
        onUpdate({
          [event.params.fieldName]: true,
        })
      }
    })
  }
