import { Pharmacy } from '~/lib/pharmacy'
import { useGetAllAaisForPharmacyQuery } from '~/graphql/types/schemaNode.type'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { getTenantId } from '~/lib/tenantMetadata'
import { NetworkStatus } from '@apollo/client'
import { useGetAaiQuestionnairesLazyQuery } from '../../../graphql/types/schemaNode.type'
import { useMemo } from 'react'
import { PharmacyAai } from '../pharmacyAAI'
import { reportException } from '~/tools/monitoring'

export const useGetAllAaisForPharmacy = (
  pharmacy?: Pick<Pharmacy, 'id' | 'province'>,
  language?: string,
  skip?: boolean
) => {
  const { data, loading, error, refetch, networkStatus } =
    useGetAllAaisForPharmacyQuery({
      variables: {
        locationId: pharmacy?.id,
        organizationId: undefined, // TODO: need to confirm what value this should be
        regionCode: pharmacy?.province,
        customerId: getTenantId(),
        countryCode: 'CA',
      },
      context: {
        clientName: EServer.NODE,
        headers: {
          'accept-language': language || 'en-CA',
        },
      },
      notifyOnNetworkStatusChange: true,
      skip: skip || !pharmacy?.id || !pharmacy?.province,
    })

  if (!error) {
    /**
     *  TODO: Need to soft fail because request fails sometimes on first load, guessing graphql state on FE isn't ready yet
     *  removing console.error since its poluting the console but doesn't cause any harm
     */
    // console.error('useGetAllAaisForPharmacy: soft failing')
  }

  const [getAAIQuestionnaires] = useGetAaiQuestionnairesLazyQuery()

  const aais: PharmacyAai[] = useMemo(() => {
    if (!data) {
      return []
    }

    return data.findAllAppointmentActivityInstancesWithAppointmentTypes.map(
      (aai) => ({
        ...aai,
        getQuestionnaires: async () => {
          try {
            const questionnaires = await getAAIQuestionnaires({
              variables: {
                sublocationId: aai.scope.sublocationId,
                locationId: aai.scope.locationId,
                organizationId: aai.scope.organizationId,
                regionCode: aai.scope.regionCode,
                customerId: aai.scope.customerId,
                countryCode: aai.scope.countryCode,
                appointmentActivityId: aai.appointmentActivityId,
              },
              context: {
                clientName: EServer.NODE,
                headers: {
                  'accept-language': language || 'en-CA',
                },
              },
            })

            return (
              questionnaires.data?.appointmentActivityInstance
                ?.questionnaires ?? []
            )
          } catch (error) {
            reportException(error)
            return []
          }
        },
      })
    )
  }, [data, getAAIQuestionnaires, language])

  return {
    aais,
    refetchPharmacyAais: refetch,
    loading,
    refetching: networkStatus === NetworkStatus.refetch,
  }
}
