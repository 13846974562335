import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { offWhite, darkIndigo, primary } from '~/sharedStyles/colorPalette'

export const PharmacistLayoutWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  display: flex;
  height: 100vh;
  width: 100vw;
  min-height: -webkit-fill-available;
  background-color: ${offWhite};
`

export const PharmacistSideBar = styled.div`
  position: sticky;
  flex: ${({ hidden }) => (hidden ? '0.05' : '0.15')};
  min-width: ${({ hidden }) => (hidden ? '5rem' : '15rem')};
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  background-color: ${primary};
  color: white;
  height: 100vh;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

// TODO: temporary measure to fix the sidebar nav
export const PharmacistPictureContainer = styled.div`
  height: 8rem;
  width: 8rem;
  margin-top: 2rem;
`

export const HiddenPharmacistPictureContainer = styled.div`
  height: 4rem;
  width: 4rem;
  margin-top: 2rem;
`

export const CircleImage = styled.img`
  max-width: 100%;
  border: 2px ${darkIndigo} solid;
  border-radius: 50%;
`

export const Image = styled.img``

export const PharmacistName = styled.div`
  font-weight: 700;
  font-size: 2em;
  margin-top: 0.7rem;
  margin-bottom: 0.25rem;
  text-align: center;
`

export const SidebarOptionsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-top: 1.5rem;
  row-gap: 1px;
`

export const ExpandableOptionsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const OptionContainer = styled(Link)`
  display: flex;
  align-items: center;
  background-color: ${({ selected }) => (selected ? darkIndigo : 'none')};
  padding: 0.5rem 1rem;
  column-gap: 1rem;
  text-decoration: none;
  color: white;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.2;
  `}

  &:hover {
    background-color: ${darkIndigo};
    transition: all 0.2s ease-in;
  }
`

export const OptionContainerAnchor = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: ${({ selected }) => (selected ? darkIndigo : 'none')};
  padding: 0.5rem 1rem;
  column-gap: 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${darkIndigo};
    transition: all 0.2s ease-in;
  }
`

export const IconContainer = styled.div`
  width: 2rem;

  & > img {
    max-width: 100%;
  }
`

export const OptionText = styled.div`
  font-size: 1.2em;
  color: white;
`

export const HelperText = styled.div`
  font-size: 0.9em;
  display: block;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const WhiteBarLine = styled.div`
  width: 100%;
  border: 1px solid white;
  margin: 1rem 2rem;
`

export const SideBarSeparator = styled.div`
  width: calc(100% - 2.5rem);
  border: 1px solid white;
  align-self: center;
  margin: 1rem 0;
`

export const SidebarEndOptionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 1px;
  width: 100%;
  margin: 1rem 0;
`
export const SidebarFlexEndOptionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: auto;
`

export const PharmacistPageContent = styled.div`
  flex: 0.875;
  display: flex;
  height: 100vh;
  overflow-y: hidden;
`

export const HiddenPharmacistPageContent = styled.div`
  flex: 0.975;
  display: flex;
  height: 100vh;
  overflow-y: hidden;
`

export const HideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4.5%;
  right: -0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  box-shadow: 0px 0px 15px 1px #e6e6e6;
  background-color: white;
  cursor: pointer;
  z-index: 2;
`

export const IconImg = styled.img`
  width: 30px;
  height: 30px;
`

export const EnterpriseStoreHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;
`

export const EnterpriseContextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  margin-right: 1rem;
  row-gap: 0.45rem;
`

export const BoldText = styled.div`
  font-weight: 500;
`
