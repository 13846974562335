import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Minor Ailment Eligibility Screening ',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const completingQuestions = createFhirQuestionnaireItem({
    text: "Please answer the questions below to confirm you're eligible to be seen by a pharmacist. If completing on behalf of someone else, answer from their perspective.",
    linkId: 'completingQuestions',
    type: 'display',
  })

  const redMessage = createFhirQuestionnaireItem({
    text: '_If you have any reason to believe you may require urgent medical attention, please call 9-1-1 or go to the nearest hospital emergency room._',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const healthCard = createFhirQuestionnaireItem({
    text: 'This service is free for eligible Ontario Health Card holders. A professional fee may apply if you do not have a valid card. \n\n&nbsp; \n\n',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const questionnaireHeading = createFhirQuestionnaireItem({
    linkId: 'questionnaireHeading',
    text: 'Screening Questions',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const age = createFhirQuestionnaireItem({
    text: 'Are you booking for someone that is at least 1 year old or older?',
    linkId: 'age',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Impetigo is uncommon in infants under the age of 1 years old and requires further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  const experiencingSymptoms = createFhirQuestionnaireItem({
    text: `Are you experiencing any of the following symptoms?
- Fever
- Chills
- Fatigue
- Malaise
- 1 or more large patches of affected skin
- 4 or more small patches of affected skin
- Rapid spread of redness
- Widespread redness or inflammation
    `,
    linkId: 'experiencingSymptoms',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'The above symptoms may suggest an alternative diagnosis and require further assessment by a Primary Care Provider within 24 hours.',
          },
        ],
      }),
    ],
    required: true,
  })
  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      redMessage,
      healthCard,
      questionnaireHeading,
      age,
      experiencingSymptoms,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningImpetigoIndiesONJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['ON'],
    isEnterprise: false,
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: ['Impetigo'],
    },
  },
  metadata: {
    id: 'fhirMedicalScreeningImpetigoIndiesONJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
