import { Engine } from 'json-rules-engine'
import moment from 'moment'

const getDate = (value: string) => {
  if (value === 'now') {
    return moment()
  }
  return moment(value)
}

export const addIsSameOrBefore = (rulesEngine: Engine) => {
  rulesEngine.addOperator('isSameOrBefore', (factValue, jsonValue) => {
    if (!factValue) return true
    const factDate = getDate(String(factValue))
    const jsonDate = getDate(String(jsonValue))
    return factDate.isSameOrBefore(jsonDate)
  })
}
