import Snackbar from '@material-ui/core/Snackbar'
import styled from 'styled-components'

export const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    background-color: #333333;
    border-radius: 4px;
    font-size: 1em;
    padding: 8px 16px;
  }
`
