import { EServer } from '~/graphql/hooks/useGraphQL/links'
import {
  useGetBookingByKeysQuery,
  useGetIntakeServiceConfigurationsQuery,
} from '~/graphql/types/schemaNode.type'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import { getIsWalkin } from '../utils'
import { useLanguageForPatient } from '~/hooks/useLanguageForPatient'
import { useRouterContext } from '~/pages/AppointmentIntake/contexts'
import { useEffect } from 'react'
import { useQueryParams } from '~/hooks/useQueryParams'

export const useGetIntakeServiceConfigurations = () => {
  const { services, numberOfPersons, method, pharmacy, timeSlot, dispatch } =
    useBookingContext()
  const serviceTypes = services.map((service) => service.urlParam)
  const { languageForFHIR } = useLanguageForPatient()

  const {
    searchParams: { key1, key2, key3 },
  } = useRouterContext()
  const { isDocumentationLink } = useQueryParams()

  const {
    loading: bookingLoading,
    data: bookingData,
    error: bookingError,
  } = useGetBookingByKeysQuery({
    variables: {
      key1,
      key2,
      key3,
    },
    context: {
      clientName: EServer.NODE,
    },
    skip: !key1 || !key2 || !key3,
    fetchPolicy: 'network-only',
  })

  const context = {
    regionCode: pharmacy?.province,
    countryCode: 'CA',
    locationId: pharmacy?.id,
  }

  const { loading, data, error } = useGetIntakeServiceConfigurationsQuery({
    variables: { urlParams: serviceTypes, ...context },
    context: {
      clientName: EServer.NODE,
      headers: {
        'accept-language': languageForFHIR,
      },
    },
    fetchPolicy: 'network-only',
  })

  const booking = bookingData?.getBookingByKeys

  if (error || bookingError) {
    throw new Error(`Unable to get IntakeServiceConfigurations: ${error}`)
  }
  if (!bookingLoading && !booking && key1 && key2 && key3) {
    throw new Error(`Unable to get booking: ${bookingError}`)
  }

  const numberOfPatients =
    (isDocumentationLink && booking?.patients.length) || numberOfPersons

  useEffect(() => {
    if (isDocumentationLink) {
      dispatch({
        type: 'setNumberOfPersons',
        payload: numberOfPatients,
      })
    }
  }, [numberOfPatients, dispatch, isDocumentationLink])

  const intakeServices = data ? data.appointmentActivityInstanceList : []
  const isWalkin = getIsWalkin(timeSlot)

  return {
    loading: loading || bookingLoading,
    numberOfPatients,
    method,
    intakeServices,
    isWalkin,
    booking,
  }
}
