import * as React from 'react'
import { usePharmacyContext } from '../../PharmacyContext'
import { useRouterContext } from '../../RouterContext'
import { createWhiteLabel } from '../createWhiteLabel'
import { ThemeProvider, MuiThemeProvider } from '../Theme'
import { useFallbackEnterpriseWhiteLabel } from '../useFallbackEnterpriseWhiteLabel'
import {
  WhiteLabelContextInterface,
  createWhiteLabelContext,
  WhiteLabelContext,
} from '../WhiteLabelContext'

const reducer = (
  state: WhiteLabelContextInterface,
  action: any
): WhiteLabelContextInterface => {
  switch (action.type) {
    case 'initializeWhiteLabel':
      return {
        ...state,
        whiteLabel: action.whiteLabel,
      }
    case 'setWhiteLabel':
      return {
        ...state,
        whiteLabel: action.WhiteLabel,
      }
    case 'setLoading':
      return {
        ...state,
        loading: action.loading,
      }
    default:
      console.log('unknown action type', action.type, action)
      return state
  }
}

export const WhiteLabelProvider: React.FC = ({ children }) => {
  const { enterpriseCode } = useRouterContext()
  const { pharmacy } = usePharmacyContext()
  const [error, setError] = React.useState('')

  const [context, dispatch] = React.useReducer(
    reducer,
    createWhiteLabelContext()
  )
  const {
    whiteLabel: eFallbackWhiteLabel,
    loading: eFallbackLoading,
    error: eFallbackError,
  } = useFallbackEnterpriseWhiteLabel()

  React.useEffect(() => {
    if (eFallbackError) setError(eFallbackError)
  }, [eFallbackError])

  React.useEffect(() => {
    if (!eFallbackLoading) {
      if (pharmacy.id) {
        dispatch({
          type: 'initializeWhiteLabel',
          whiteLabel: createWhiteLabel({
            theme: pharmacy.theme,
            logoCircle: pharmacy.logoCircle,
            logoLong: pharmacy.logoLong,
            logoLongInverse: pharmacy.logoLongInverse,
          }),
        })
      } else if (enterpriseCode) {
        dispatch({
          type: 'initializeWhiteLabel',
          whiteLabel: eFallbackWhiteLabel,
        })
      } else {
        setError('No white label found. Should not be possible.')
      }

      dispatch({
        type: 'setLoading',
        loading: false,
      })

      return
    }

    dispatch({
      type: 'setLoading',
      loading: true,
    })
  }, [
    eFallbackLoading,
    eFallbackWhiteLabel,
    enterpriseCode,
    pharmacy.id,
    pharmacy.logoCircle,
    pharmacy.logoLong,
    pharmacy.logoLongInverse,
    pharmacy.theme,
  ])

  if (error) throw new Error(error)

  return (
    <WhiteLabelContext.Provider value={context}>
      <MuiThemeProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </MuiThemeProvider>
    </WhiteLabelContext.Provider>
  )
}
