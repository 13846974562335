import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { createFhirCodingEntityPatient } from '~/pages/AppointmentIntake/lib/fhir/systems'
import { presetFhirGridExt } from '../../../../../../lib/fhir/extensions/fhirExtGrid/presetFhirGridExt'
import { JsonFhirFormStep } from '../../../../types'

export const cardHeading = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Information',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Required fields (*)',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [heading, descReq],
  })
}

const pcp = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'pcpHeading',
    type: 'display',
    text: 'Primary Care Provider',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const pcpFirstName = createFhirQuestionnaireItem({
    linkId: 'pcpFirstName',
    type: 'string',
    text: 'First Name',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.firstName' }),
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })
  const pcpLastName = createFhirQuestionnaireItem({
    linkId: 'pcpLastName',
    type: 'string',
    text: 'Last Name',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.lastName' }),
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const pcpPhone = createFhirQuestionnaireItem({
    linkId: 'pcpPhone',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    text: 'Phone Number',
    extension: [
      presetFhirGridExt({ xs: 12, sm: 4 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Invalid phone number' },
        ],
      }),
    ],
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.phone' }),
    ],
    required: true,
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const pcpInfo = createFhirQuestionnaireItem({
    linkId: 'pcpInfo',
    type: 'display',
    text: '*It may be necessary for the pharmacist to discuss and share your health information with other healthcare professionals (e.g. physicians) in accordance with generally accepted medication therapy management principles.',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const noPcp = createFhirQuestionnaireItem({
    linkId: 'noPcp',
    type: 'boolean',
    text: "I don't have a Primary Care Provider",
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [heading, pcpFirstName, pcpLastName, pcpPhone, pcpInfo, noPcp],
  })
}

export const fhirMedicalInformationQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalInformationQuestionnaire',
  name: 'Medical Information',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalInformationPage',
      type: 'group',
      item: [cardHeading(), pcp()],
    }),
  ],
})

export const fhirMedicalInformationSdmStrepTestJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalInformationQuestionnaire,
  metadata: {
    id: 'fhirMedicalInformationSdmStrepTestJson',
    name: 'Medical Information',
    path: 'medical-information',
    showOnSteps: true,
  },
}
