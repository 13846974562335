import React from 'react'
import {
  PatientProgressLabel,
  ProgressList,
  PatientProgressContainer,
  CollapsibleContainer,
  PatientProgressLabelContainer,
  TruncatedLabel,
} from './ProgressWidget.styles'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import Divider from '@material-ui/core/Divider'
import {
  Step,
  StepLabel,
  Stepper,
  StepIconProps,
  StepConnector,
  Theme,
  createStyles,
} from '@material-ui/core'
import { getProgressInputStatus } from '../../NewBooking/Intake/utils'
import { useLocation } from 'react-router-dom'

interface IntakeProgressNode {
  label?: string
  done?: boolean
  current: boolean
}

export interface PatientProgressNode {
  label?: string
  open: boolean
  done?: boolean
  steps?: IntakeProgressNode[]
}
export interface ProgresWidgetProps {
  nodes: PatientProgressNode[]
}

const Collapsible = ({
  open,
  label,
  children,
}: {
  open: boolean
  label?: React.ReactNode
  children?: React.ReactNode | React.ReactNode[]
}) => {
  return (
    <CollapsibleContainer>
      {open && <div>{children}</div>}
      {!open && <PatientProgressLabel>{label}</PatientProgressLabel>}
    </CollapsibleContainer>
  )
}
const ProgressConnector = withStyles((theme: Theme) => {
  return {
    vertical: {
      padding: 0,
      marginLeft: 11,
    },
    active: {
      '& $line': {
        borderColor: theme.palette.primary.main,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.palette.primary.main,
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
      borderLeftWidth: 2,
    },
  }
})(StepConnector)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notActive: {
      color: 'grey',
    },
    completed: {
      color: theme.palette.primary.main,
    },
    active: {
      color: theme.palette.primary.main,
    },
  })
)

function ProgressStepIcon(props: StepIconProps) {
  const classes = useStyles()

  const { active, completed } = props

  return completed ? (
    <CheckCircleRoundedIcon className={classes.completed} />
  ) : (
    <RadioButtonUncheckedOutlinedIcon
      className={active ? classes.active : classes.notActive}
    />
  )
}

const StyledStepper = withStyles({
  vertical: {
    padding: '10px 0px',
  },
})(Stepper)

const ProgressPatientLabel = (node: PatientProgressNode) => {
  const classes = useStyles()

  return (
    <PatientProgressLabelContainer>
      {node.done && <CheckCircleRoundedIcon className={classes.completed} />}
      <PatientProgressLabel key={node.label} style={{ marginLeft: 10 }}>
        {node.label}
      </PatientProgressLabel>
    </PatientProgressLabelContainer>
  )
}

export function ProgressWidget(props: ProgresWidgetProps) {
  const { nodes } = props
  const location = useLocation()
  const currentNode = getProgressInputStatus(nodes, location.pathname)

  return (
    <ProgressList>
      {nodes.map((node, index) => {
        return (
          <div key={index}>
            <Collapsible open={node.open} label={ProgressPatientLabel(node)}>
              <PatientProgressContainer>
                {node.label && (
                  <PatientProgressLabel key={node.label}>
                    {node.label}
                  </PatientProgressLabel>
                )}
                <StyledStepper
                  activeStep={currentNode[index]}
                  orientation="vertical"
                  connector={<ProgressConnector />}
                >
                  {node.steps?.map((step) => {
                    return (
                      <Step key={step.label}>
                        <StepLabel StepIconComponent={ProgressStepIcon}>
                          <TruncatedLabel>{step.label}</TruncatedLabel>
                        </StepLabel>
                      </Step>
                    )
                  })}
                </StyledStepper>
              </PatientProgressContainer>
            </Collapsible>
            {index < nodes.length - 1 && <Divider />}
          </div>
        )
      })}
    </ProgressList>
  )
}
