import React from 'react'
import { FabSearch } from './components/FabSearch'
import { ChatDrawer } from './components/ChatDrawer'
import { AiChatProvider } from './context'
import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'

export const AiChatContent = () => {
  const isAiEnabled = useFeatureFlag(FeatureFlag.ENABLE_CLINICAL_SEARCH, false)

  if (!isAiEnabled) return null

  return (
    <AiChatProvider>
      <FabSearch />
      <ChatDrawer />
    </AiChatProvider>
  )
}
