const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PHARMACIST_DATA:
      return action.pharmacistData
    case CLEAR_PHARMACIST_DATA:
      return {}
    default:
      return state
  }
}

const LOAD_PHARMACIST_DATA = 'LOAD_PHARMACIST_DATA'
const CLEAR_PHARMACIST_DATA = 'CLEAR_PHARMACIST_DATA'

export const loadPharmacistData = (pharmacistData) => ({
  type: LOAD_PHARMACIST_DATA,
  pharmacistData,
})

export const clearPharmacistData = () => ({
  type: CLEAR_PHARMACIST_DATA,
})

export default reducer
