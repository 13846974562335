import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  touch-action: none;
`

export const Content = styled.div`
  z-index: 500;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`

export const FloatingCloseButton = styled(IconButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`
