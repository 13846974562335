import { EServer } from '~/graphql/hooks/useGraphQL/links'
import {
  GetAppointmentActivitiesQueryVariables,
  useGetAppointmentActivitiesQuery,
} from '~/graphql/types/schemaNode.type'
import { createAppointmentActivityInstance } from '~/lib/appointmentActivity'
import { useLanguageForPatient } from '~/hooks/useLanguageForPatient'
import { useMemo } from 'react'

export const useGetAppointmentActivities = (
  variables: GetAppointmentActivitiesQueryVariables
) => {
  const { languageForFHIR } = useLanguageForPatient()

  const {
    data,
    error: gqlError,
    loading,
  } = useGetAppointmentActivitiesQuery({
    skip: variables.groupId === '',
    variables: {
      ...variables,
      regionCode: variables.regionCode || undefined,
      locationId: variables.locationId || undefined,
    },
    context: {
      clientName: EServer.NODE,
      headers: {
        'accept-language': languageForFHIR,
      },
    },
  })

  const appointmentActivities = useMemo(
    () =>
      data?.appointmentActivityInstanceList?.map((activity) =>
        createAppointmentActivityInstance(activity)
      ) ?? [],
    [data?.appointmentActivityInstanceList]
  )

  const error = gqlError?.message ?? ''

  return {
    appointmentActivities,
    error,
    loading,
  }
}
