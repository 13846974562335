import { resolveBestError } from './utils'
import { StandardTranslationNamespace } from '~/hooks/useMedMeTranslation/i18n-namespaces.types'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { isDev } from '~/util/envMethods'

export interface UseErrorTextOptions {
  fallbackKey?: string
  namespace?: StandardTranslationNamespace | StandardTranslationNamespace[]
  devMode?: boolean
}

export const defaultFallbackKey = 'error.unknown' as const
export const defaultMessage = 'Unexpected error occurred.' as const
export const devModeFlag = '[DEV ONLY]:' as const
/**
 * Parses Error object to resolve the most accurate error message. Should be
 * reviewed and updated over time and extended for better localization.
 */
export const useErrorText = (
  e: Error | undefined,
  options: UseErrorTextOptions = {}
): string => {
  const {
    namespace = 'common',
    fallbackKey = defaultFallbackKey,
    devMode = isDev,
  } = options
  const { t, i18n } = useMedMeTranslation(namespace)
  const err = resolveBestError(e)
  const message: string[] = []

  if (err.message && err.message.length && i18n.exists(err?.message)) {
    message.push(t(err.message))
  } else if (fallbackKey && i18n.exists(fallbackKey)) {
    message.push(t(fallbackKey))
  } else {
    message.push(defaultMessage)
  }

  if (devMode) {
    message.push(devModeFlag)
    message.push(err.stack || '')
  }
  return message.join('\n')
}
