import { Engine } from 'json-rules-engine'
import { string } from 'yup'
import { tlds } from '@hapi/tlds'

export const addIsEmail = (rulesEngine: Engine) => {
  rulesEngine.addOperator('isEmail', (factValue, jsonValue) => {
    if (!factValue) {
      return true
    }

    let isEmailValid = string().email().isValidSync(String(factValue))

    if (isEmailValid) {
      const tld = String(factValue).split('.').pop()
      if (!tld || !tlds.has(tld.toLowerCase())) {
        isEmailValid = false
      }
    }

    return isEmailValid === Boolean(jsonValue)
  })
}
