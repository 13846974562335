import { Engine } from 'json-rules-engine'

export const addFhirEnableWhenOperators = (rulesEngine: Engine) => {
  rulesEngine.addOperator('=', (factValue, jsonValue) => {
    return factValue === jsonValue
  })
  rulesEngine.addOperator('!=', (factValue, jsonValue) => {
    return factValue !== jsonValue
  })
  rulesEngine.addOperator('>', (factValue, jsonValue) => {
    return Number(factValue) > Number(jsonValue)
  })
  rulesEngine.addOperator('>=', (factValue, jsonValue) => {
    return Number(factValue) >= Number(jsonValue)
  })
  rulesEngine.addOperator('<', (factValue, jsonValue) => {
    return Number(factValue) < Number(jsonValue)
  })
  rulesEngine.addOperator('<=', (factValue, jsonValue) => {
    return Number(factValue) <= Number(jsonValue)
  })
  rulesEngine.addOperator('exists', (factValue, jsonValue) => {
    return (factValue !== undefined) === jsonValue
  })
}
