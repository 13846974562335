import {
  CustomDetector,
  DetectorOptions,
} from 'i18next-browser-languagedetector'
import {
  LANGUAGE_OVERRIDE_LOCAL_STORAGE_KEY,
  LanguageCode,
  getLanguageCodeFromLocale,
} from './util/languageMethods'
import { checkIsPatientUI } from './util/envMethods'
import { getEnterpriseCode } from './util/enterpriseMethods'
import { EnterpriseEnums } from './shared/constants/enterpriseConstants'

export const PatientQueryParamLanguageDetector: CustomDetector = {
  name: 'PatientQueryParamLanguageDetector',

  lookup(options: DetectorOptions): string | string[] | undefined {
    if (!checkIsPatientUI()) return

    const searchParams = new URLSearchParams(window.location.search)
    const lookupQuerystring = options.lookupQuerystring ?? 'language'
    const languageFromQueryParam = searchParams.get(lookupQuerystring)
    if (!languageFromQueryParam) return

    return getLanguageCodeFromLocale(languageFromQueryParam)
  },
}

export const PatientLanguageOverrideDetector: CustomDetector = {
  name: 'PatientLanguageOverrideDetector',

  lookup(_options: DetectorOptions): string | string[] | undefined {
    if (!checkIsPatientUI()) return

    const languageOverride = localStorage.getItem(
      LANGUAGE_OVERRIDE_LOCAL_STORAGE_KEY
    )

    return languageOverride
      ? getLanguageCodeFromLocale(languageOverride)
      : undefined
  },
}

export const PatientDefaultLanguageDetector: CustomDetector = {
  name: 'PatientDefaultLanguageDetector',

  lookup(_options: DetectorOptions): string | string[] | undefined {
    if (!checkIsPatientUI()) return

    return getEnterpriseCode() === EnterpriseEnums.PHX
      ? LanguageCode.FR
      : undefined
  },
}
