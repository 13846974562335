import { useMemo } from 'react'
import { useWebViewContext } from '~/context/WebViewContext'

export function useGetPchSessionId() {
  const { inWebView, pchSessionId } = useWebViewContext()

  const getPchSessionId = useMemo(() => {
    // The PCH session ID should be returned only if the user is in the webview
    // https://medmehealth.atlassian.net/browse/NEB-1592
    if (inWebView) {
      return pchSessionId
    }

    return null
  }, [inWebView, pchSessionId])

  return getPchSessionId
}
