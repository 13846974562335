import * as React from 'react'
import { createPresetFormStep } from './createPresetFormStep'
import { createFhirFormStep } from './createFhirFormStep'
import { FormStepLayout } from '~/pages/AppointmentIntake/components'
import { FormStepMetaData, JsonFormStep } from '../jsonFormSteps'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'

export type FormState = Record<string, string | number | boolean | null>

export type FormStep = {
  Component: React.FC
  metadata: FormStepMetaData
  questionnaire?: FhirQuestionnaire | undefined
  enableWhen?: (state: ReturnType<typeof useBookingContext>['state']) => boolean
  condition?: (state: ReturnType<typeof useBookingContext>['state']) => boolean
  isAutoSkippable: (
    state: ReturnType<typeof useBookingContext>['state']
  ) => Promise<boolean>
}

export type CreateFormStep = (json: JsonFormStep) => FormStep

export const createFormStep: CreateFormStep = (json) => {
  const specficFormStep = (() => {
    switch (json.type) {
      case 'preset':
        return createPresetFormStep(json)
      case 'fhir':
        return createFhirFormStep(json)
      default:
        throw new Error('Unknown form step type')
    }
  })()

  const WrappedComponent: React.FC = () => (
    <FormStepLayout>
      <specficFormStep.Component />
    </FormStepLayout>
  )

  return {
    ...specficFormStep,
    Component: WrappedComponent,
  }
}

export type CreateFormStepComponent = (json: JsonFormStep) => React.FC
