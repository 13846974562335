import * as React from 'react'
import { Grid } from '@material-ui/core'
import { FhirQuestionnaireItemComponent } from '../type'

export const withGrid =
  (Component: FhirQuestionnaireItemComponent): FhirQuestionnaireItemComponent =>
  ({ questionnaireItem, isDisabled }) => {
    const grid = questionnaireItem.supportedExtensions.style?.gridColumn ?? 12
    if (questionnaireItem.type === 'group') {
      return (
        <Grid item xs={12} sm={grid}>
          <Grid container spacing={2}>
            <Component
              questionnaireItem={questionnaireItem}
              isDisabled={isDisabled}
            />
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid item xs={12} sm={grid}>
        <Component
          questionnaireItem={questionnaireItem}
          isDisabled={isDisabled}
        />
      </Grid>
    )
  }
