import { createFhirInputItem } from '../fhirInputItem'

export const createFhirChoiceItem: typeof createFhirInputItem = (json) => {
  if (json.type !== 'choice')
    throw new Error('createFhirDisplayItem: json.item !== "choice"')

  return {
    type: 'choice',
    answerOption: json.answerOption ?? [],
  }
}
