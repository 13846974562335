import { JsonFormStep } from './types'

export const reviewAndConfirm: JsonFormStep = {
  type: 'preset',
  preset: 'reviewAndConfirm',
  metadata: {
    id: 'reviewAndConfirm',
    name: 'reviewAndConfirm.title',
    path: 'review-and-confirm',
    showOnSteps: true,
  },
}
