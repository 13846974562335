export const fhirSystemUrlMedme = 'https://api.medmeapp.com' as const

export const fhirStructDefUrlMedMe =
  `${fhirSystemUrlMedme}/fhir/structure-definition` as const

export const fhirValueSetUrlMedme =
  `${fhirSystemUrlMedme}/fhir/value-set` as const

export const BULK_ANSWER_EXT_MEDME_URL =
  `${fhirStructDefUrlMedMe}/bulk-answer` as const

export const REUSE_TRIGGER_EXT_MEDME_URL =
  `${fhirStructDefUrlMedMe}/reuse-trigger` as const

export const REUSE_ITEM_EXT_MEDME_URL =
  `${fhirStructDefUrlMedMe}/reuse-item` as const
