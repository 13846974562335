import React from 'react'
import { Appointment } from '~/lib/appointment'

import { SectionBodyContainer, SectionBodyBold } from '../../Completion.style'
import moment from 'moment'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

type AppointmentContainer = {
  appointment: Appointment
  appointmentActivityName?: string
  timeZone: string
}

export const AppointmentContainer: React.FC<AppointmentContainer> = ({
  appointment,
  appointmentActivityName,
  timeZone,
}) => {
  const { noOfPeople, startDateTime } = appointment || {}
  const { t } = useMedMeTranslation('patient')

  return (
    <SectionBodyContainer>
      <SectionBodyBold>{appointmentActivityName}</SectionBodyBold>
      <SectionBodyBold>
        {startDateTime && moment.utc(startDateTime).tz(timeZone).format('llll')}
      </SectionBodyBold>
      <SectionBodyBold>
        {t('bookingFor', { count: noOfPeople })}
      </SectionBodyBold>
    </SectionBodyContainer>
  )
}
