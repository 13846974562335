import R5 from 'fhir/r5'
import { EntityPathObject } from './entityPathObject'
import { FhirValueSet } from './fhirValueSet'
import { FhirQuestionnaireItem } from './fhirQuestionnaireItem'
import { SimpleValueType } from './type'
import { getTranslatedFhirElementText } from './fhirQuestionnaire.helpers'

export class FhirQuestionnaire implements R5.Questionnaire {
  readonly id: string
  readonly version: string
  readonly name: R5.Questionnaire['name']
  readonly title: R5.Questionnaire['title']
  readonly _title?: R5.Questionnaire['_title']
  readonly language: Required<R5.Questionnaire>['language']
  readonly resourceType: 'Questionnaire' = 'Questionnaire'
  readonly status: 'draft' | 'active' | 'retired' | 'unknown'
  readonly item: FhirQuestionnaireItem[]
  readonly linkIdItems: Record<string, FhirQuestionnaireItem>
  readonly valueSets: Record<string, FhirValueSet | undefined>
  // We will assume questionnaire is Fhir Compliant
  constructor(
    questionnaire: R5.Questionnaire,
    private patientLanguage = 'en-CA',
    public path?: string
  ) {
    if (!questionnaire.id || !questionnaire.version) {
      throw new Error('id and version must be defined')
    }
    this.id = questionnaire.id
    this.version = questionnaire.version
    this.name = questionnaire.name
    this.title = questionnaire.title
    this._title = questionnaire._title
    this.language = questionnaire.language ?? 'en-CA'
    this.status = questionnaire.status
    this.item =
      questionnaire.item?.map(
        (item) => new FhirQuestionnaireItem(item, patientLanguage)
      ) ?? []

    const reduceLinkIdItems = (
      acc: Record<string, FhirQuestionnaireItem>,
      items: FhirQuestionnaireItem[]
    ): Record<string, FhirQuestionnaireItem> => {
      items.forEach((item) => {
        if (item.linkId) {
          if (item.linkId in acc) {
            console.log(`duplicate linkId '${item.linkId}' found`)
            throw new Error('duplicate linkId found')
          } else {
            acc[item.linkId] = item
            reduceLinkIdItems(acc, item.item ?? [])
          }
        }
      })
      return acc
    }

    this.linkIdItems = reduceLinkIdItems({}, this.item)
    this.valueSets = {}
  }

  getTitle(language?: string): string | undefined {
    if (this.language === language && this.title) {
      return this.title
    }

    if (!language) {
      return (
        getTranslatedFhirElementText(this._title, this.patientLanguage) ||
        this.title
      )
    }

    return getTranslatedFhirElementText(this._title, language) || this.title
  }

  saveAnswers(linkIdAnswers: Record<string, SimpleValueType>) {
    Object.keys(linkIdAnswers).forEach((linkId) => {
      if (
        linkIdAnswers[linkId] !== undefined &&
        this.linkIdItems[linkId] === undefined
      ) {
        console.error('saveAnswers: could not find QuestionnaireItem')
        throw new Error('saveAnswers: linkId not found')
      } else if (linkIdAnswers[linkId] === undefined) {
        this.linkIdItems[linkId].clearAnswer()
      } else {
        this.linkIdItems[linkId].setAnswer(linkIdAnswers[linkId])
      }
    })
  }

  getQuestionnaireItems(): FhirQuestionnaireItem[] {
    return Object.values(this.linkIdItems)
  }

  getQuestionnaireItem(questionId: string): FhirQuestionnaireItem | undefined {
    const questionnaireItem = this.linkIdItems[questionId]
    if (!questionnaireItem) return undefined

    return questionnaireItem
  }

  hasBatchUpdatableItem(): boolean {
    for (const item of this.item) {
      if (item.hasBatchUpdatableItem()) {
        return true
      }
    }
    return false
  }

  parseBatchUpdatableItem(): FhirQuestionnaire {
    const results = []
    const { item, ...currentQuestionnaire } = this

    for (const questionnaireItem of item) {
      const itemResult = questionnaireItem.parseBatchUpdatableItem()
      if (itemResult) results.push(itemResult)
    }

    if (results.length > 0) {
      return new FhirQuestionnaire(
        { ...currentQuestionnaire, item: results },
        this.patientLanguage
      )
    } else {
      return new FhirQuestionnaire(
        { ...currentQuestionnaire, item: [] },
        this.patientLanguage
      )
    }
  }

  getAnswers(): Record<string, SimpleValueType> {
    return Object.keys(this.linkIdItems).reduce<
      Record<string, SimpleValueType>
    >((acc, key) => {
      acc[key] = this.linkIdItems[key].getAnswer()
      return acc
    }, {})
  }

  getAnswer(questionId: string): SimpleValueType {
    const questionnaireItem = this.getQuestionnaireItem(questionId)
    if (!questionnaireItem) return undefined
    return questionnaireItem.getAnswer()
  }

  getInitialValues(): Record<string, SimpleValueType> {
    return Object.keys(this.linkIdItems).reduce<
      Record<string, SimpleValueType>
    >((acc, key) => {
      acc[key] = this.linkIdItems[key].getInitial()
      return acc
    }, {})
  }

  getLinkIdEntityPathObjectMap(): Record<string, EntityPathObject[]> {
    return Object.keys(this.linkIdItems).reduce<
      Record<string, EntityPathObject[]>
    >((acc, key) => {
      acc[key] = this.linkIdItems[key].getEntityPathObject()
      return acc
    }, {})
  }

  /**
   *
   */
  getQuestionnaireItemsWithSystem(system: string): FhirQuestionnaireItem[] {
    const matches = Object.entries(this.linkIdItems).filter(
      ([_linkId, questionnaireItem]) => {
        return !!(
          questionnaireItem.code &&
          questionnaireItem.code.length &&
          questionnaireItem.code.find((code) => code.system === system)
        )
      }
    )
    return matches.map(([_linkId, questionnaireItem]) => questionnaireItem)
  }

  /**
   *
   */
  getCodeSystemResponses(system: string): Record<string, SimpleValueType> {
    const systemQuestionnaireItems =
      this.getQuestionnaireItemsWithSystem(system)

    const responses: Record<string, SimpleValueType> = {}

    systemQuestionnaireItems.forEach((questionnaireItem) => {
      const coding = questionnaireItem.code?.find(
        (code) => code.system === system
      )
      if (!coding || !coding.code) throw new Error('no coding found for system')

      responses[coding.code] = questionnaireItem.getAnswer()
    })

    return responses
  }

  /**
   * Adds the valueSet to the questionnaire, and updates all QuestionnaireItems to point to that valueSet if they require it
   * @param valueSet
   */
  setValueSet(valueSet: R5.ValueSet): void {
    if (this.language && this.language !== valueSet.language) {
      throw new Error('valueSet language must be the same as FhirQuestionnaire')
    }
    const newFhirValue = new FhirValueSet(valueSet)
    this.valueSets[newFhirValue.url] = newFhirValue
    Object.values(this.linkIdItems).forEach((item) =>
      item.setFhirValueSet(newFhirValue)
    )
  }

  /**
   * Returns a unique list of valueSet urls that are required to properly use this questionnaire
   * @returns Unique list of valueSet urls
   */
  getRequiredValueSetUrls(): string[] {
    const valueSetUrls = Object.values(this.linkIdItems).reduce<string[]>(
      (acc, item) => {
        Object.keys(item.valueSets).forEach((key) => {
          acc.push(key)
        })
        return acc
      },
      []
    )

    const uniqueValueSetUrls = Array.from(new Set(valueSetUrls))

    return uniqueValueSetUrls
  }

  generateQuestionnaireResponse(): R5.QuestionnaireResponse {
    return {
      language: this.language,
      resourceType: 'QuestionnaireResponse',
      questionnaire: this.version,
      status: 'completed',
      item: this.item.map((item) => item.generateQuestionnaireResponseItem()),
    }
  }

  setAnswerFromQuestionnaireResponse(response: R5.QuestionnaireResponse): void {
    // TODO: update this logic once we decide on questionnaire url convensions
    if (
      response.questionnaire !== undefined &&
      response.questionnaire !== this.version
    ) {
      throw new Error(
        'setAnswerFromQuestionnaireResponse: QuestionnaireReponse questionnaire does not match id'
      )
    }

    if (response.item && this.item.length !== response.item.length) {
      throw new Error(
        'setAnswerFromQuestionnaireResponse: length of children don\t match'
      )
    }

    this.item.forEach((item, i) => {
      const responseItem = response.item?.[i]
      if (responseItem === undefined) {
        throw new Error(
          'setAnswerFromQuestionnaireResponse: corresponding questionnaireItem not amoung children'
        )
      }
      item.setAnswerFromQuestionnaireResponseItem(responseItem)
    })
  }
}
