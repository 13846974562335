import React from 'react'

// styles
import { CompletedLayout, Text, AppointmentText } from './cancelComplete.styles'
import { transformToHumanReadableNumber } from '~/util/formatContact'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { FinishBar } from '~/pages/patientRegistrationV2/components/FinishBar'
import { Appointment, Pharmacy } from '~/graphql/types/schemaNode.type'
import { DateFormat, getLocalizedDateFormat } from '~/util/date'

interface CancelCompleteProps {
  pharmacy?: Pharmacy
  existingBooking?: Appointment
}

const CancelComplete = ({ pharmacy, existingBooking }: CancelCompleteProps) => {
  const { t } = useMedMeTranslation('patient')

  return (
    <CompletedLayout>
      <Text>
        {t('cancelAppointment.appointmentCancelled')}
        <br />
        <br />
        {existingBooking && (
          <>
            {t('cancelAppointment.existingBookingReminder')}
            <br />
            <AppointmentText>
              {existingBooking.appointmentType.type}
              {' | '}
              {getLocalizedDateFormat(
                existingBooking.startDateTime.toString(),
                DateFormat.AbbrDayDateTime,
                pharmacy?.timeZone || ''
              )}
            </AppointmentText>
            <br />
          </>
        )}
        {t('cancelAppointment.mistakenlyCancelled', {
          pharmacyPhoneNumber: transformToHumanReadableNumber(
            pharmacy?.pharmacyContact?.phone
          ),
        })}
        <br />
        <br />
        {t('cancelAppointment.takeCare')}
      </Text>
      <FinishBar />
    </CompletedLayout>
  )
}

export default CancelComplete
