export const ALL_USER_ROLES = {
  NWC_SYSTEM_ADMIN: 'NWC_SYSTEM_ADMIN',
  NWC_CALL_CENTRE: 'NWC_CALL_CENTRE',
  NWC_PRESCRIBING_PROVIDER: 'NWC_PRESCRIBING_PROVIDER',
  NWC_NON_PRESCRIBING_PROVIDER: 'NWC_NON_PRESCRIBING_PROVIDER',
}

export const ALL_USER_POLICIES = {
  CREATE_FILE: 'CREATE_FILE',
  RESCHEDULE_APPOINTMENT: 'RESCHEDULE_APPOINTMENT',
  CANCEL_APPOINTMENT: 'CANCEL_APPOINTMENT',
  CREATE_USER_ACCOUNT: 'CREATE_USER_ACCOUNT',
  SCHEDULE_APPOINTMENT: 'SCHEDULE_APPOINTMENT',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  EDIT_DOCUMENT: 'EDIT_DOCUMENT',
  CREATE_DOCUMENT: 'CREATE_DOCUMENT',
  START_VIDEO_FILE: 'START_VIDEO_FILE',
}

export const ADDITIONAL_FIELDS_ROLES = [
  ALL_USER_ROLES.NWC_PRESCRIBING_PROVIDER,
  ALL_USER_ROLES.NWC_NON_PRESCRIBING_PROVIDER,
]
