import type { StandardTranslationNamespace } from './i18n-namespaces.types'

/**
 * Type-guard that determines if the given namespace is a valid MedMe i18n namespace or an array of them.
 *
 * Beyond providing type safety in TypeScript, the `useMedMeTranslation()` hook is used in many js
 * files per the present conditions of the codebase where it is important to manually check for type-safety.
 *
 * @see StandardTranslationNamespace
 * @see {@link https://www.notion.so/medmehealth/F-E-i18n-Scope-Recommendations-3ebcfb461b5d49b9a8e19c19fef31927?pvs=4#d29b2c48147c499caf8ea95be9f5cad2|Notion Documentation}
 */
export const isStandardTranslationNamespace = (
  namespace: unknown
): namespace is
  | StandardTranslationNamespace
  | StandardTranslationNamespace[] => {
  if (!namespace) {
    return false
  }

  if (Array.isArray(namespace)) {
    return namespace.every(
      (ns) => typeof ns === 'string' && isStandardTranslationNamespace(ns)
    )
  }

  return (
    namespace === 'common' ||
    namespace === 'patient' ||
    namespace === 'pharmacy'
  )
}
