import styled from 'styled-components'
import { Heading3 } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const NoLocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const NoLocationsText = styled(Heading3)`
  margin-bottom: 1.5rem;
`
