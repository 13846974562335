import * as React from 'react'
import {
  ThemeProvider,
  createTheme as createMuiTheme,
} from '@material-ui/core/styles'
import { useWhiteLabelContext } from '../..'

export const MuiThemeProvider: React.FC = ({ children }) => {
  const {
    whiteLabel: { theme },
  } = useWhiteLabelContext()

  const materialTheme = createMuiTheme({
    palette: {
      primary: {
        main: theme.color.backgroundPrimary,
      },
      secondary: {
        main: theme.color.backgroundPrimary,
      },
    },
    typography: {
      fontFamily: theme.font.main,
    },
  })

  return <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>
}
