import env from './env.medmesandbox.com.json'

export default {
  'sandbox-on.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_ON_PHARMACY_ID,
  'sandbox-ns.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_NS_PHARMACY_ID,
  'sandbox-mb.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_MB_PHARMACY_ID,
  'sandbox-sk.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_SK_PHARMACY_ID,
  'sandbox-pe.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_PE_PHARMACY_ID,
  'sandbox-bc.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_BC_PHARMACY_ID,
  'sandbox-nb.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_NB_PHARMACY_ID,
  'sandbox-nl.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_NL_PHARMACY_ID,
  'sandbox-ab.medmesandbox.com-pharmacyId': env.SANDBOX_MEDME_AB_PHARMACY_ID,
}
