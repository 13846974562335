
const initialState = {
  offset: 0,
  search: '',
  sortBy: 'START_DATE_TIME_SORT_DESC',
  showFilterOptions: false,
  appointmentDate: {
    startDateTime: '',
    endDateTime: '',
  },
  pharmacistId: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONSULTATION_OFFSET:
      return {
        ...state,
        offset: action.offset,
      }
    case UPDATE_CONSULTATION_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    case UPDATE_SHOW_CONSULTATION_FILTER_OPTIONS:
      return {
        ...state,
        showFilterOptions: action.toggleFilterOptions,
      }
    case UPDATE_CONSULTATION_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case UPDATE_APPOINTMENT_DATE:
      return {
        ...state,
        appointmentDate: action.appointmentDate,
      }
    case UPDATE_PHARMACIST_ID:
      return {
        ...state,
        pharmacistId: action.pharmacistId,
      }
    default:
      return state
  }
}

const UPDATE_CONSULTATION_OFFSET = 'UPDATE_CONSULTATION_OFFSET'
const UPDATE_CONSULTATION_SEARCH = 'UPDATE_CONSULTATION_SEARCH'
// const CLEAR_FILTER = 'CLEAR_FILTER'
const UPDATE_SHOW_CONSULTATION_FILTER_OPTIONS =
  'UPDATE_SHOW_CONSULTATION_FILTER_OPTIONS'
const UPDATE_CONSULTATION_SORT_BY = 'UPDATE_CONSULTATION_SORT_BY'
const UPDATE_APPOINTMENT_DATE = 'UPDATE_APPOINTMENT_DATE'
const UPDATE_PHARMACIST_ID = 'UPDATE_PHARMACIST_ID'

export const updateConsultationOffset = (offset) => ({
  type: UPDATE_CONSULTATION_OFFSET,
  offset,
})

export const updateConsultationSearch = (search) => ({
  type: UPDATE_CONSULTATION_SEARCH,
  search,
})

export const updateShowConsultationFilterOptions = (toggleFilterOptions) => ({
  type: UPDATE_SHOW_CONSULTATION_FILTER_OPTIONS,
  toggleFilterOptions,
})

export const updateConsultationSortBy = (sortBy) => ({
  type: UPDATE_CONSULTATION_SORT_BY,
  sortBy,
})

export const updateAppointmentDate = (appointmentDate) => ({
  type: UPDATE_APPOINTMENT_DATE,
  appointmentDate,
})

export const updatePharmacistId = (pharmacistId) => ({
  type: UPDATE_PHARMACIST_ID,
  pharmacistId,
})

export default reducer
