import * as React from 'react'
import { FhirQuestionnaireItemComponent } from '../type'
import { FhirGroupComponent } from '../FhirGroupComponent'
import { FhirLeafComponent } from '../FhirLeafComponent'
import { useFhirFormContext } from '../../FhirFormContext'

export const FhirStemComponent: FhirQuestionnaireItemComponent = React.memo(
  ({ questionnaireItem, isDisabled = false }) => {
    const { type, linkId, disabledDisplay } = questionnaireItem
    const { disabledItems, hiddenItems } = useFhirFormContext()

    if (
      (disabledDisplay === 'hidden' && disabledItems[linkId]) ||
      hiddenItems?.has(linkId)
    ) {
      return null
    }

    const isNodeDisabled = Boolean(isDisabled || disabledItems[linkId])

    if (type === 'group') {
      return (
        <FhirGroupComponent
          questionnaireItem={questionnaireItem}
          isDisabled={isNodeDisabled}
        />
      )
    }

    return (
      <FhirLeafComponent
        questionnaireItem={questionnaireItem}
        isDisabled={isNodeDisabled}
      />
    )
  }
)
