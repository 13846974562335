import styled from 'styled-components'
import { ironGray } from '~/sharedStyles/colorPalette'

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  margin: 1rem;
`

export const Title = styled.div`
  font-size: 1em;
  font-weight: 600;
  color: ${ironGray};
  margin: 1rem 0rem;
  text-align: center;
`

export const SubTitle = styled.div`
  font-size: 0.75em;
  color: ${ironGray};
  text-align: center;
`

export const Overlay = styled.div`
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  touch-action: none;
  overflow: hidden;
`

export const Content = styled.div`
  z-index: 999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  background-color: white;
  border-radius: 5px;
  padding: 3rem 4rem;
  overflow-y: scroll;
  max-height: 75vh;
  width: 15rem;
  align-items: center;
  line-height: 1.35;

  ::-webkit-scrollbar {
    display: none;
  }
`
