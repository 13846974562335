import * as React from 'react'
import { FhirMarkdownComponent } from '~/lib/markdown/FhirMarkdownComponent'
import {
  Body,
  CardTitle,
  SectionTitle,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { FhirLeafComponent } from '../FhirLeafComponent'

export const FhirDisplayLabel: typeof FhirLeafComponent = ({
  questionnaireItem,
}) => {
  const {
    supportedExtensions: { typeVariant },
  } = questionnaireItem

  const Component = React.useMemo(() => {
    switch (typeVariant) {
      case 'heading1':
        return CardTitle
      case 'markdown':
        return FhirMarkdownComponent
      case 'heading2':
        return SectionTitle
      default:
        return Body
    }
  }, [typeVariant])

  if (!questionnaireItem.text) {
    return null
  }

  return <Component>{questionnaireItem.getText() || ''}</Component>
}
