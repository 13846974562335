import * as React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import {
  bookingErrorTimeslotSelector,
  cancelledAppointmentLanding,
  completion,
  JsonFormStep,
  pendingRescheduleLanding,
} from '../../jsonFormSteps'
import { createFormStep, FormStep } from '../../createFormStep'

/**
 * These form steps are listed in priority order, if multiple form step conditions match, only the first one will be returned
 */
const conditionalJsonFormSteps: JsonFormStep[] = [
  cancelledAppointmentLanding,
  pendingRescheduleLanding,
  completion,
  bookingErrorTimeslotSelector,
]

export const useConditionalFormSteps = () => {
  const [conditionalFormStep, setConditionalFormStep] =
    React.useState<FormStep | null>(null)

  const { state } = useBookingContext()

  const conditionalFormSteps = React.useMemo(
    () => conditionalJsonFormSteps.map(createFormStep),
    []
  )

  const getConditionalFormStep = (
    state: ReturnType<typeof useBookingContext>['state']
  ) => {
    return conditionalFormSteps.find((conditionalFormStep) =>
      conditionalFormStep.condition?.(state)
    )
  }

  React.useEffect(() => {
    const formStep = getConditionalFormStep(state)
    if (formStep?.metadata?.id !== conditionalFormStep?.metadata?.id) {
      setConditionalFormStep(formStep ?? null)
    }
  }, [state])

  const ConditionalForm = React.useMemo(() => {
    if (!conditionalFormStep) return null
    return conditionalFormStep.Component
  }, [conditionalFormStep?.metadata?.id])

  return {
    ConditionalForm,
  }
}
