import * as React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/BookingContext/useBookingContext'
import { FormStep } from '../../createFormStep'

export const useFormIterator = (formSteps: FormStep[]) => {
  const { state } = useBookingContext()
  const [formStepsIndex, setFormStepsIndex] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState('')

  const { Component, isAutoSkippable }: FormStep = React.useMemo(
    () =>
      formSteps[formStepsIndex] ?? {
        Component: <div />,
        metadata: {
          id: '',
          name: '',
          urlName: '',
          title: '',
          description: '',
        },
        isAutoSkippable: async () => false,
      },
    [formSteps, formStepsIndex]
  )

  React.useEffect(() => {
    if (loading) {
      isAutoSkippable(state)
        .then((isSkippable: boolean) => {
          if (isSkippable) {
            setFormStepsIndex(formStepsIndex + 1)
          }
        })
        .catch((e: any) => {
          setError(e.toString())
        })
        .finally(() => {
          setLoading(false)
        })
    } else if (!loading && formSteps.length === 0) {
      setError('No form steps found')
    }
  }, [loading])

  const onNext = React.useCallback(() => {
    if (formStepsIndex < formSteps.length - 1) {
      setFormStepsIndex(formStepsIndex + 1)
    }
  }, [formStepsIndex, formSteps])

  const onBack = React.useCallback(() => {
    if (formStepsIndex > 0) {
      setFormStepsIndex(formStepsIndex - 1)
    }
  }, [formStepsIndex, formSteps])

  if (error) {
    throw new Error(error)
  }

  return {
    formStepsIndex,
    setFormStepsIndex,
    FormStep: Component,
    nextStep: onNext,
    prevStep: formStepsIndex > 0 ? onBack : null,
    loading,
    error,
  }
}
