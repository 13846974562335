const initialState = {
  pharmacistLayout: true,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PHARMACIST_LAYOUT:
      return {
        ...state,
        pharmacistLayout: action.pharmacistLayout,
      }
    case UPDATE_SIDEBAR_OPTION:
      return {
        ...state,
        [action.name]: action.showOption,
      }
    default:
      return state
  }
}

const UPDATE_PHARMACIST_LAYOUT = 'UPDATE_PHARMACIST_LAYOUT'
const UPDATE_SIDEBAR_OPTION = 'UPDATE_SIDEBAR_OPTION'

export const updatePharmacistLayout = (pharmacistLayout) => ({
  type: UPDATE_PHARMACIST_LAYOUT,
  pharmacistLayout,
})

export const updateSidebarOption = (showOption, name) => ({
  type: UPDATE_SIDEBAR_OPTION,
  showOption,
  name,
})

export default reducer
