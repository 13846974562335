import * as React from 'react'
import moment from 'moment'
import { Timeslot } from '~/lib/timeslot'
import { defaultFormat } from './useBookingRange'

// Should make all dates the pharmacy timezone
export const useAvailableTimeslotsMap = (
  timeslots: Timeslot[],
  minDate: string,
  maxDate: string,
  timezone: string
) => {
  const availableTimes = React.useMemo(() => {
    const sortedTimeslots = timeslots
      .slice()
      .sort((a, b) => moment(a.startDateTime).diff(moment(b.startDateTime)))

    const filteredTimeslots = sortedTimeslots.filter(
      ({ startDateTime, endDateTime }, i, arr) => {
        const minDateMTz = moment.tz(minDate, timezone).startOf('day')
        const maxDateMTz = moment.tz(maxDate, timezone).endOf('day')

        const afterMinDate = moment
          .tz(startDateTime, timezone)
          .isSameOrAfter(minDateMTz)

        const beforeMaxDate = moment
          .tz(endDateTime, timezone)
          .isBefore(maxDateMTz)

        const isStartTimeAfterNow = moment
          .tz(startDateTime, timezone)
          .isSameOrAfter()
        // This removes duplicate timeslots for different resouces
        const isNotSameTimeAsPrevious =
          !i || startDateTime !== arr[i - 1].startDateTime

        return (
          isStartTimeAfterNow &&
          afterMinDate &&
          beforeMaxDate &&
          isNotSameTimeAsPrevious
        )
      }
    )

    const categorizedTimeslots = filteredTimeslots.reduce<
      Record<string, Timeslot[]>
    >((acc, timeslot) => {
      const key = moment
        .tz(timeslot.startDateTime, timezone)
        .format(defaultFormat)
      const currentValue = acc[key] || ([] as Timeslot[])
      // TODO: Fix eslint error
      // eslint-disable-next-line no-sparse-arrays
      const newValue = [...currentValue, , timeslot].filter(
        Boolean
      ) as Timeslot[]

      return {
        ...acc,
        [key]: newValue,
      }
    }, {} as Record<string, Timeslot[]>)

    return categorizedTimeslots
  }, [timeslots, minDate, maxDate])

  return {
    availableTimeslotsMap: availableTimes,
  }
}
