import { Button, DialogContent } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

export const StyledDialog = styled(Dialog)<{
  zindex?: number
  padding?: number
}>`
  & .MuiDialog-paper {
    z-index: ${({ zindex }) => zindex};
    padding: ${({ padding }) => padding}px;
  }
`

export const StyledDialogTitle = styled(DialogTitle)<{
  px?: string
  py?: string
}>`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ py, px }) => `${py} ${px}`};

  & > .MuiTypography-root {
    font-weight: 700;
  }
`

export const StyledDialogContent = styled(DialogContent)<{
  px?: string
  py?: string
}>`
  padding: ${({ py, px }) => `${py} ${px}`};
`

export const StyledDialogActions = styled(DialogActions)<{
  px?: string
  py?: string
}>`
  &.MuiDialogActions-root {
    padding: ${({ py, px }) => `${py} ${px}`};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    > :only-child {
      margin-left: auto;
    }

    @media only screen and (orientation: portrait) {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      gap: 8px;
    }
  }
`

export const StyledButton = styled(Button)`
  @media only screen and (orientation: portrait) {
    width: 100%;
  }
`

export const StyledLoader = styled(CircularProgress)<{
  buttonvariant?: string
}>`
  color: ${({ buttonvariant }) =>
    buttonvariant === 'outlined' ? 'black' : 'white'};
`
