import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../types'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Minor Illness Assessment',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'If you are experiencing a medical emergency, call 9-1-1 or go to your nearest hospital emergency room.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })
  const eligibility = createFhirQuestionnaireItem({
    text: 'Eligibility for assessment and prescribing for minor ailments will be assessed by the pharmacist at the time of your appointment.',
    linkId: 'eligibility',
    type: 'display',
  })
  const healthcardPaymentDisclaimerHeader = createFhirQuestionnaireItem({
    text: 'DISCLAIMER:',
    linkId: 'healthcardDisclaimerHeader',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const notReimbursed = createFhirQuestionnaireItem({
    text: 'Assessment for this illness is not reimbursed by the NL Medical Care Plan. Fees apply',
    linkId: 'notReimbursed',
    type: 'display',
  })
  const medicationPaymentDisclaimer = createFhirQuestionnaireItem({
    text: 'In the event a prescription is written for this service, the cost of the medication is not included in the assessment. The cost may be covered by you or your drug insurance.',
    linkId: 'medicationPaymentDisclaimer',
    type: 'display',
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      eligibility,
      healthcardPaymentDisclaimerHeader,
      notReimbursed,
      medicationPaymentDisclaimer,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirNotReimbursedMAMedicalScreeningSdmNLJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['NL'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: [
        'Allergies',
        'OralThrush',
        'SkinRash',
        'PeriodCramps',
        'Impetigo',
        'InsectBites',
        'MuscleAches',
        'EmergencyContraception',
        'Acne',
        'CankerSores',
        'Headache',
        'CallusesCorns',
        'Diarrhea',
        'Dandruff',
        'Indigestion',
        'Nausea',
        'Pinworms',
        'SleepDisorder',
        'Cough',
        'StuffyNose',
        'SoreThroat',
        'YeastInfection',
        'Warts',
        'DryEyes',
        'Threadworms',
        'ScalpRash',
      ],
    },
  },
  metadata: {
    id: 'fhirNotReimbursedMAMedicalScreeningSdmNLJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
