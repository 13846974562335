import { RuleSerializable } from 'json-rules-engine'
import { createRequired } from '../events'
import { Priority } from './priority'
import { TFunction } from 'i18next'

export const createRequiredRule = (
  fieldName: string,
  t: TFunction
): RuleSerializable => {
  return {
    name: `${fieldName}Required`,
    conditions: {
      all: [
        {
          fact: fieldName,
          operator: 'notEqual',
          value: '',
        },
      ],
    },
    event: createRequired(fieldName, t('formRequired')),
    priority: Priority.required,
  }
}
