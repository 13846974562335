import React from 'react'
import dayjs from 'dayjs'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined'
import DateRangeOutlined from '@material-ui/icons/DateRangeOutlined'
import PersonOutlineOutlined from '@material-ui/icons/PersonOutlineOutlined'
import AccessTimeOutlined from '@material-ui/icons/AccessTimeOutlined'

import { StyledBlock, StyledList } from './DetailsWidget.styles'
import { Pharmacy } from '~/lib/pharmacy'
import {
  AppointmentActivityInstance,
  CombinedTimeslot,
  Sublocation,
} from '~/graphql/types/schemaNode.type'
import tz from 'dayjs/plugin/timezone'
import { getDayjsLocaleTimeFormat } from '~/util/date'

dayjs.extend(tz)

export type DetailsWidgetContentProps = React.PropsWithChildren<{
  services: AppointmentActivityInstance[]
  numberOfPersons: number
  timeSlot?: CombinedTimeslot
  pharmacy?: Pharmacy
  externalClinic?: Sublocation
}>

export function DetailsWidgetContent({
  children,
  pharmacy,
  externalClinic,
  services,
  numberOfPersons,
  timeSlot,
}: Omit<DetailsWidgetContentProps, 'mobileRef' | 'desktopRef'>) {
  const { t } = useMedMeTranslation('patient')
  const hasPharmacyOrSlotSelected = pharmacy?.pharmacyAddress || timeSlot
  const hourMinuteFormat = getDayjsLocaleTimeFormat()

  return (
    <>
      <Typography variant="body1" gutterBottom>
        {t('detailsWidget.services.title')}
      </Typography>
      <StyledList>
        {services.map((service) => (
          <li key={service.id}>{service?.details?.title || service.name}</li>
        ))}
      </StyledList>
      {hasPharmacyOrSlotSelected ? <Divider /> : null}
      {timeSlot?.startDateTime ? (
        <StyledBlock>
          <DateRangeOutlined />
          <Typography variant="body2">
            {dayjs(timeSlot.startDateTime)
              // When pharmacy timeZone is empty string, we set timezone to undefined.
              .tz(pharmacy?.timeZone || undefined)
              .format(`ddd MMM D, ${hourMinuteFormat}`)}
          </Typography>
        </StyledBlock>
      ) : null}
      {timeSlot?.duration ? (
        <StyledBlock>
          <AccessTimeOutlined />
          <Typography variant="body2">
            {t('detailsWidget.duration', {
              duration: timeSlot?.duration,
            })}
          </Typography>
        </StyledBlock>
      ) : null}
      {hasPharmacyOrSlotSelected ? (
        <StyledBlock>
          <PersonOutlineOutlined />
          <Typography variant="body2">
            {t('detailsWidget.persons', { count: numberOfPersons })}
          </Typography>
        </StyledBlock>
      ) : null}
      {externalClinic ? (
        <StyledBlock>
          <LocationOnOutlined />
          <div>
            <Typography variant="body2">{externalClinic.name}</Typography>
            <Typography variant="body2">
              {externalClinic.address.unit}
            </Typography>
          </div>
        </StyledBlock>
      ) : pharmacy?.pharmacyAddress ? (
        <StyledBlock>
          <LocationOnOutlined />
          <div>
            <Typography variant="body2">{pharmacy.name}</Typography>
            <Typography variant="body2">
              {`${pharmacy.pharmacyAddress.streetNumber} ${pharmacy.pharmacyAddress.streetName}`}
            </Typography>
            <Typography variant="body2">
              {`${pharmacy.pharmacyAddress.city}, ${pharmacy.pharmacyAddress.province} ${pharmacy.pharmacyAddress.postalCode}`}
            </Typography>
          </div>
        </StyledBlock>
      ) : null}
      {children}
    </>
  )
}
