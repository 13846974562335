export enum ProvinceCode {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NS = 'NS',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  NT = 'NT',
  NU = 'NU',
  YT = 'YT',
  other = 'other',
  none = '',
}

export const PROVINCE_DROPDOWN_OPTIONS = [
  {
    id: 'j6z3klg',
    text: 'Select Province',
    value: ProvinceCode.none,
  },
  {
    id: 'mb43jw8',
    text: 'Alberta',
    value: ProvinceCode.AB,
    healthCardValidationRegex: /^\d{9}$/,
  },
  {
    id: 'ruez0ts',
    text: 'British Columbia',
    value: ProvinceCode.BC,
    healthCardValidationRegex: /^\d{10}$/,
  },
  {
    id: '2hwzov6',
    text: 'Manitoba',
    value: ProvinceCode.MB,
    healthCardValidationRegex: /^[\w\d]{9}$/,
  },
  {
    id: 'e7hw0bz',
    text: 'New Brunswick',
    value: ProvinceCode.NB,
    healthCardValidationRegex: /^\d{9}$/,
  },
  {
    id: 'loi39p2',
    text: 'Newfoundland and Labrador',
    value: ProvinceCode.NL,
    healthCardValidationRegex: /^\d{12}$/,
  },
  {
    id: 'zd6719m',
    text: 'Nova Scotia',
    value: ProvinceCode.NS,
    healthCardValidationRegex: /^\d{10}$/,
  },
  {
    id: '97lhxi3',
    text: 'Ontario',
    value: ProvinceCode.ON,
    healthCardValidationRegex: /^[\d]{10}$/,
  },
  {
    id: '43m73u0',
    text: 'Prince Edward Island',
    value: ProvinceCode.PE,
    healthCardValidationRegex: /^\d{8}$/,
  },
  {
    id: 'fy2ndil',
    text: 'Quebec',
    value: ProvinceCode.QC,
    healthCardValidationRegex: /^\w{12}$/,
  },
  {
    id: 'pmdf896',
    text: 'Saskatchewan',
    value: ProvinceCode.SK,
    healthCardValidationRegex: /^\d{9}$/,
  },
  {
    id: '97q4zyd',
    text: 'Northwest Territories',
    value: ProvinceCode.NT,
    healthCardValidationRegex: /^[\w\d]{8}$/,
  },
  {
    id: 'sxo1vqh',
    text: 'Nunavut',
    value: ProvinceCode.NU,
    healthCardValidationRegex: /^[\w\d]{9}$/,
  },
  {
    id: 'jcwga9p',
    text: 'Yukon',
    value: ProvinceCode.YT,
    healthCardValidationRegex: /^\d{9}$/,
  },
  {
    id: 's9so1vq',
    text: 'Other',
    value: ProvinceCode.other,
  },
]

export const PROFESSIONAL_DESIGNATION_OPTIONS = [
  {
    id: 'xarr1en',
    value: '',
    text: 'Select Designation',
  },
  {
    id: 'km6b025',
    value: 'Doctor (MD)',
    text: 'Doctor (MD)',
  },
  {
    id: 'xlyhxzb',
    value: 'NP',
    text: 'NP',
  },
  {
    id: 's2diu8t',
    value: 'OT',
    text: 'OT',
  },
  {
    id: 'erez8i8',
    value: 'OD',
    text: 'OD',
  },
  {
    id: 'mjfob5s',
    value: 'Pharmacist (RPh)',
    text: 'Pharmacist (RPh)',
  },
  {
    id: 'hnb7e7b',
    value: 'PT',
    text: 'PT',
  },
  {
    id: 'adl97ck',
    value: 'Psy',
    text: 'Psy',
  },
  {
    id: '6zhv81d',
    value: 'RD',
    text: 'RD',
  },
  {
    id: 'ax7pewr',
    value: 'RDN',
    text: 'RDN',
  },
  {
    id: 'ler70hc',
    value: 'Registered Nurse (RN)',
    text: 'Registered Nurse (RN)',
  },
  {
    id: '54hyock',
    value: 'RT',
    text: 'RT',
  },
  {
    id: '39ffzeb',
    value: 'SW',
    text: 'SW',
  },
  {
    id: 'c7fom4x',
    value: 'Other',
    text: 'Other',
  },
]
