export const VACCINE_ENUMS = [
  'ASTRAZENECA',
  'COVISHIELD',
  'JOHNSON_JOHNSON',
  'MODERNA',
  'PFIZER',
  'UNASSIGNED',
]

export const VACCINE_NAMES = [
  'AstraZeneca',
  'Covishield',
  'Johnson & Johnson',
  'Moderna',
  'Pfizer',
  'Unassigned',
]

export const VACCINE_MAPPING = {
  ASTRAZENECA: 'AstraZeneca',
  COVISHIELD: 'Covishield',
  JOHNSON_JOHNSON: 'Johnson & Johnson',
  MODERNA: 'Moderna',
  PFIZER: 'Pfizer',
  NOVAVAX: 'Novavax',
  OTHER: 'Other',
  UNASSIGNED: 'Unassigned',
}

export const DOSE_1_BRANDS = [
  'Pfizer-BioNTech COVID-19 Vaccine',
  'Moderna COVID-19 Vaccine',
  'AstraZeneca COVID-19 Vaccine',
  'COVISHIELD COVID-19 Vaccine',
  'Johnson & Johnson COVID-19 Vaccine',
]

export const DOSE_1_BRAND_MAPPING = {
  'Pfizer-BioNTech COVID-19 Vaccine': 'COVID-19 Vaccine (Dose 2 - Pfizer)',
  'Moderna COVID-19 Vaccine': 'COVID-19 Vaccine (Dose 2 - Moderna)',
  'AstraZeneca COVID-19 Vaccine':
    'COVID-19 Vaccine (Dose 2 - AstraZeneca/COVISHIELD)',
  'COVISHIELD COVID-19 Vaccine':
    'COVID-19 Vaccine (Dose 2 - AstraZeneca/COVISHIELD)',
  'Johnson & Johnson COVID-19 Vaccine':
    'COVID-19 Vaccine (Dose 2 - Johnson & Johnson)',
}

export const MRNA_BRANDS = [
  'Pfizer-BioNTech COVID-19 Vaccine',
  'Moderna COVID-19 Vaccine',
]
