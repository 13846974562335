import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { offWhite } from '~/sharedStyles/colorPalette'
import { DESKTOP_MIN_SCREEN_SIZE } from '../../const'

export const StyledDropdownContainer = styled(Card)`
  position: sticky;
  top: 5rem;
  z-index: 999;
  padding: 0.7rem 1rem;
  border-radius: 0px;
  overflow-y: auto;
  max-height: 70vh;

  // Hide on desktop
  @media only screen and (min-width: ${DESKTOP_MIN_SCREEN_SIZE}px) {
    display: none;
  }
`

export const StyledDropdown = styled.div`
  width: 100%;
  padding: 0.7rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  background-color: ${offWhite};
  border-radius: 4px;
`

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledDrawer = styled.div<{ expanded: boolean }>`
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
  flex-direction: column;
  row-gap: 0.7rem;
`

export const StyledFlipIconContainer = styled.div<{
  expanded?: boolean
}>`
  height: 1.5rem;
  width: 1.5rem;
  transform: ${({ expanded }) => (expanded ? 'scaleY(-1)' : 'unset')};
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

export const StyledTitle = styled(Typography)`
  font-size: 1.15rem;
  font-weight: 600;
`

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: 0.2rem;
`

export const StyledBackdrop = styled.div<{ expanded: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: ${({ expanded }) => (expanded ? 998 : -1)};
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};

  // Hide on desktop
  @media only screen and (min-width: ${DESKTOP_MIN_SCREEN_SIZE}px) {
    display: none;
  }
`

export const StyledLayout = styled(Card)`
  display: none;

  // Show on desktop only
  @media only screen and (min-width: ${DESKTOP_MIN_SCREEN_SIZE}px) {
    align-self: flex-start;
    width: 265px;
    transform: unset;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.7rem;
    border-radius: 0px;
  }
`

export const StyledList = styled.ul`
  list-style: disc;
  padding: 0 1rem;

  & li {
    font-size: 0.875rem;
    line-height: 1.35;
  }
`
