import { useCallback, useEffect, useState } from 'react'
import { deleteCookie, getCookie } from '~/lib/cookieManager'

import { History } from 'history'
import {
  PCH_SESSION_ID_COOKIE_NAME,
  PCH_SESSION_ID_QUERY_PARAM,
} from '~/shared/constants'

function getBaseDomain(hostname: string) {
  const segments = hostname.split('.')

  return `.${segments.slice(-2).join('.')}`
}

const DEFAULT_PCH_SESSION_ID_COOKIE_OPTIONS: {
  path: string
  sameSite: 'None'
  secure: true
} = {
  path: '/',
  sameSite: 'None',
  secure: true,
}

type UseManagePchSessionProps = {
  isPchSessionActive: boolean
  history: History
}

export function useManagePchSession({
  isPchSessionActive,
  history,
}: UseManagePchSessionProps) {
  const [pchSessionId, setPchSessionId] = useState<string | null>(
    getCookie(PCH_SESSION_ID_COOKIE_NAME) ?? null
  )

  const deletePchSessionCookie = useCallback(() => {
    if (!isPchSessionActive) {
      return
    }

    deleteCookie(PCH_SESSION_ID_COOKIE_NAME, {
      domain: getBaseDomain(window.location.hostname),
      ...DEFAULT_PCH_SESSION_ID_COOKIE_OPTIONS,
    })
    setPchSessionId(null)
  }, [isPchSessionActive])

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search)
    const searchParamPchSessionId = searchParams.get(PCH_SESSION_ID_QUERY_PARAM)
    if (!searchParamPchSessionId) {
      return
    }
    setPchSessionId(searchParamPchSessionId)

    searchParams.delete(PCH_SESSION_ID_QUERY_PARAM)

    history.replace({ search: searchParams.toString() })
  }, [history])

  useEffect(() => {
    if (!isPchSessionActive) {
      return
    }

    window.addEventListener('beforeunload', deletePchSessionCookie)

    return () => {
      window.removeEventListener('beforeunload', deletePchSessionCookie)
    }
  }, [deletePchSessionCookie, isPchSessionActive])

  return { deletePchSessionCookie, pchSessionId }
}
