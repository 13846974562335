import * as React from 'react'
import { PharmacyContext } from '../PharmacyContext'
import { useResetPharmacy } from './useResetPharmacy'
import { useSelectPharmacy } from './useSelectPharmacy/useSelectPharmacy'
import { useSetPharmacy } from './useSetPharmacy'
import { useWithPharmacyIdHeader } from './useWithPharmacyIdHeader'

export const usePharmacyContext = () => {
  const context = React.useContext(PharmacyContext)
  if (context === undefined) {
    throw new Error('usePharmacyContext must be used within a PharmacyProvider')
  }
  const { dispatch, ...state } = context
  const { setPharmacy } = useSetPharmacy(dispatch)
  const { resetPharmacy } = useResetPharmacy(dispatch, state.initialPharmacy.id)
  const { withPharmacyIdHeader } = useWithPharmacyIdHeader()
  const { selectPharmacyAsync } = useSelectPharmacy(dispatch)
  return {
    ...state,
    setPharmacy,
    resetPharmacy,
    withPharmacyIdHeader,
    selectPharmacyAsync,
  }
}
