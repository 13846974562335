import * as React from 'react'

import { AppointmentActivityDetailsBadge } from '~/lib/appointmentActivity'
import { AaiBadgeContainer } from './AaiBadge.style'
import { ToolTip } from '~/componentsTs/ToolTip'

export const AaiBadge: React.FC<{
  badge: AppointmentActivityDetailsBadge
}> = ({ badge }) => {
  if (!badge.visible) return null
  return (
    <ToolTip title={badge.description}>
      <AaiBadgeContainer
        type="button"
        styleType={badge.style}
        onClick={(e) => e.stopPropagation()}
      >
        {badge.label}
      </AaiBadgeContainer>
    </ToolTip>
  )
}
