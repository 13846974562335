import styled from 'styled-components'
import { Heading2 } from '../../AppointmentIntake.style'

export const Layout = styled.div`
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  line-height: 1.35;
`

export const SectionBody = styled(Heading2)`
  font-weight: 500;
`

export const SectionTitle = styled(Heading2)`
  font-weight: 600;
  margin-bottom: 1rem;
`
