import { Extension } from 'fhir/r5'
import {
  ExtensionValidationValue,
  validExtensionValidationValueKeys,
} from '../validation.type'

export const isExtensionValidationValue = (
  extension: Extension
): extension is ExtensionValidationValue => {
  if (extension.url === 'value') {
    // Array should have unique
    const valueCount = validExtensionValidationValueKeys.reduce<number>(
      (acc, key) => {
        if (extension[key] !== undefined) return acc + 1
        return acc
      },
      0
    )
    return valueCount === 1
  }
  return false
}
