import dayjs from 'dayjs'

import { TimeFormat } from './types'
import { TIME_VALUE_FORMATS_LIST } from '~/shared/constants/timeConstants'

/**
 * @description Formats a time string to a localized time string
 *
 * @example getLocalizedTimeFormat('02:00:00', TimeFormat.HoursMinutes)
 *
 * @param timeString time string in hh:mm:ss | hh:mm | custom format
 * @param format Format of the time
 * @param timeStringExpectedFormats expected format(s) of the time string if it is not in hh:mm:ss | hh:mm format
 * @returns Formatted time string
 */
export const getLocalizedTimeFormat = (
  timeString: string,
  format: TimeFormat,
  timeStringExpectedFormats: string | string[] = TIME_VALUE_FORMATS_LIST
): string => {
  const date = dayjs(timeString, timeStringExpectedFormats)

  switch (format) {
    case TimeFormat.HoursMinutesCompact:
      return date.format('LT').replace(' AM', 'am').replace(' PM', 'pm')
    case TimeFormat.HoursMinutes:
      return date.format('LT')
    case TimeFormat.HoursMinutesSeconds:
      return date.format('LTS')
  }

  throw new Error('Invalid time format')
}
