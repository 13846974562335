import { isEnterprise } from './enterpriseMethods'

const hideHpvMerckQuestionCardDomains = [
  // 'localhost',
  'rexall.medmeapp.com',
  'rexalldev.medmeapp.com',
  'metropharmacy.medmeapp.com',
  'metrodev.medmeapp.com',
  'pollardida.medmeapp.ca',
  'cwpharmasave.medmeapp.ca',
  'westmountmedicalpharmacy.medmeapp.ca',
  'merrettspharmacy.medmeapp.ca',
  'pharmasavefortquappelle.medmeapp.com',
  'ingersollpharmasave.medmeapp.ca',
  'primecarepharmacy.medmeapp.ca',
]

export const hideHpvMerckQuestionCard = () =>
  hideHpvMerckQuestionCardDomains.includes(window.location.hostname)

// BOOSTER_DOSE_MODULE_CONDITIONAL
export const isBoosterEnabledUtil = true

export const getIsBillingEnabled = () => !isEnterprise()
