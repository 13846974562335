const initialState = {
  offset: 0,
  search: '',
  sortBy: 'DOSE_1_DATE_ASC',
  showFilterOptions: true,
  appointmentTypes: null,
  isScreeningAnswerAllNull: false,
  createdBy: null,
  screeningQuestionIds: null,
  waitlistJoinedDate: null,
  isAge: true,
  patientBirthYear: null,
  patientAge: null,
  email: null,
  cell: null,
  linkSentAt: null,
  linkSentCount: null,
  isStandBy: null,
  postalCode: null,
  tabIndex: 0,
  lastMassSendTime: null,
  bcFrontlineWorkerQuestionId: null,
  abHealthcareWorkerQuestionId: null,

  dose1Location: null,
  dose2Location: null,

  vaccineNames: null,
  dose2VaccineNames: null,
  lastDoseNames: null,

  dose1Date: null,
  dose2Date: null,
  lastDoseDate: null,

  numOfLastDoses: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COVID_DOSE_THREE_WAITLIST_OFFSET:
      return {
        ...state,
        offset: action.offset,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_APPOINTMENT_TYPES:
      return {
        ...state,
        appointmentTypes: action.appointmentTypes,
      }

    case UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_DOSE_1:
      return {
        ...state,
        vaccineNames: action.vaccineNames,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_DOSE_2:
      return {
        ...state,
        dose2VaccineNames: action.dose2VaccineNames,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_LAST_DOSE:
      return {
        ...state,
        lastDoseNames: action.lastDoseNames,
      }

    case UPDATE_COVID_DOSE_THREE_WAITLIST_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case CLEAR_COVID_DOSE_THREE_WAITLIST_FILTER:
      return {
        ...initialState,
        showFilterOptions: state.showFilterOptions,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_SHOW_FILTER_OPTIONS:
      return {
        ...state,
        showFilterOptions: action.toggleFilterOptions,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_CREATED_BY:
      return {
        ...state,
        createdBy: action.createdBy,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_ANSWER_ALL_NULL:
      return {
        ...state,
        isScreeningAnswerAllNull: action.isScreeningAnswerAllNull,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_IS_AGE:
      return {
        ...state,
        isAge: action.isAge,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_BIRTH_YEAR:
      return {
        ...state,
        patientBirthYear: action.patientBirthYear,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_AGE:
      return {
        ...state,
        patientAge: action.patientAge,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_EMAIL:
      return {
        ...state,
        email: action.email,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_CELL:
      return {
        ...state,
        cell: action.cell,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_QUESTION_IDS:
      return {
        ...state,
        screeningQuestionIds: action.screeningQuestionIds,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_JOIN_DATE:
      return {
        ...state,
        waitlistJoinedDate: action.waitlistJoinedDate,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_LINK_SENT_AT:
      return {
        ...state,
        linkSentAt: action.linkSentAt,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_LINK_SENT_COUNT:
      return {
        ...state,
        linkSentCount: action.linkSentCount,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_IS_STANDBY:
      return {
        ...state,
        isStandBy: action.isStandBy,
      }
    case UPDATE_COVID_DOSE_THREE_WAITLIST_POSTAL_CODE:
      return {
        ...state,
        postalCode: action.postalCode,
      }
    case UPDATE_COVID_DOSE_THREE_LAST_MASS_SEND_TIME:
      return {
        ...state,
        lastMassSendTime: action.lastMassSendTime,
      }

    case UPDATE_COVID_DOSE_THREE_DOSE_ONE_DATE:
      return {
        ...state,
        dose1Date: action.dose1Date,
      }
    case UPDATE_COVID_DOSE_THREE_DOSE_TWO_DATE:
      return {
        ...state,
        dose2Date: action.dose2Date,
      }
    case UPDATE_COVID_DOSE_THREE_DOSE_LAST_DATE:
      return {
        ...state,
        lastDoseDate: action.lastDoseDate,
      }

    case UPDATE_NUMBER_OF_LAST_DOSES:
      return {
        ...state,
        numOfLastDoses: action.numOfLastDoses,
      }

    case UPDATE_COVID_DOSE_THREE_DOSE_ONE_LOCATION:
      return {
        ...state,
        dose1Location: action.dose1Location,
      }
    case UPDATE_COVID_DOSE_THREE_DOSE_TWO_LOCATION:
      return {
        ...state,
        dose2Location: action.dose2Location,
      }
    default:
      return state
  }
}

const UPDATE_COVID_DOSE_THREE_WAITLIST_OFFSET =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_OFFSET'
const UPDATE_COVID_DOSE_THREE_WAITLIST_SEARCH =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_SEARCH'
const CLEAR_COVID_DOSE_THREE_WAITLIST_FILTER =
  'CLEAR_COVID_DOSE_THREE_WAITLIST_FILTER'
const UPDATE_COVID_DOSE_THREE_WAITLIST_APPOINTMENT_TYPES =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_APPOINTMENT_TYPES'
const UPDATE_COVID_DOSE_THREE_WAITLIST_SHOW_FILTER_OPTIONS =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_SHOW_FILTER_OPTIONS'
const UPDATE_COVID_DOSE_THREE_WAITLIST_CREATED_BY =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_CREATED_BY'
const UPDATE_COVID_DOSE_THREE_WAITLIST_ANSWER_ALL_NULL =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_ANSWER_ALL_NULL'
const UPDATE_COVID_DOSE_THREE_WAITLIST_IS_AGE =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_IS_AGE'
const UPDATE_COVID_DOSE_THREE_WAITLIST_AGE =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_AGE'
const UPDATE_COVID_DOSE_THREE_WAITLIST_BIRTH_YEAR =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_BIRTH_YEAR'
const UPDATE_COVID_DOSE_THREE_WAITLIST_EMAIL =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_EMAIL'
const UPDATE_COVID_DOSE_THREE_WAITLIST_CELL =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_CELL'
const UPDATE_COVID_DOSE_THREE_WAITLIST_TAB_INDEX =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_TAB_INDEX'
const UPDATE_COVID_DOSE_THREE_WAITLIST_QUESTION_IDS =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_QUESTION_IDS'
const UPDATE_COVID_DOSE_THREE_WAITLIST_JOIN_DATE =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_JOIN_DATE'
const UPDATE_COVID_DOSE_THREE_WAITLIST_SORT_BY =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_SORT_BY'
const UPDATE_COVID_DOSE_THREE_WAITLIST_LINK_SENT_AT =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_LINK_SENT_AT'
const UPDATE_COVID_DOSE_THREE_WAITLIST_LINK_SENT_COUNT =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_LINK_SENT_COUNT'
const UPDATE_COVID_DOSE_THREE_WAITLIST_IS_STANDBY =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_IS_STANDBY'
const UPDATE_COVID_DOSE_THREE_WAITLIST_POSTAL_CODE =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_POSTAL_CODE'
const UPDATE_COVID_DOSE_THREE_LAST_MASS_SEND_TIME =
  'UPDATE_COVID_DOSE_THREE_LAST_MASS_SEND_TIME'
const UPDATE_COVID_DOSE_THREE_DOSE_ONE_DATE =
  'UPDATE_COVID_DOSE_THREE_DOSE_ONE_DATE'
const UPDATE_COVID_DOSE_THREE_DOSE_TWO_DATE =
  'UPDATE_COVID_DOSE_THREE_DOSE_TWO_DATE'

const UPDATE_COVID_DOSE_THREE_DOSE_LAST_DATE =
  'UPDATE_COVID_DOSE_THREE_DOSE_LAST_DATE'
const UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_LAST_DOSE =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_LAST_DOSE'
const UPDATE_NUMBER_OF_LAST_DOSES = 'UPDATE_NUMBER_OF_LAST_DOSES'

const UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_DOSE_1 =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_DOSE_1'
const UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_DOSE_2 =
  'UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_DOSE_2'
const UPDATE_COVID_DOSE_THREE_DOSE_ONE_LOCATION =
  'UPDATE_COVID_DOSE_THREE_DOSE_ONE_LOCATION'
const UPDATE_COVID_DOSE_THREE_DOSE_TWO_LOCATION =
  'UPDATE_COVID_DOSE_THREE_DOSE_TWO_LOCATION'

export const updateOffset = (offset) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_OFFSET,
  offset,
})

export const updateSearch = (search) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_SEARCH,
  search,
})

export const clearFilter = () => ({
  type: CLEAR_COVID_DOSE_THREE_WAITLIST_FILTER,
})

export const updateAppointmentTypes = (appointmentTypes) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_APPOINTMENT_TYPES,
  appointmentTypes,
})

export const updateVaccineNames = (vaccineNames) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_DOSE_1,
  vaccineNames,
})

export const updateVaccineNamesDose2 = (dose2VaccineNames) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_DOSE_2,
  dose2VaccineNames,
})

export const updateVaccineNamesLastDose = (lastDoseNames) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_VAC_NAMES_LAST_DOSE,
  lastDoseNames,
})

export const updateSortBy = (sortBy) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_SORT_BY,
  sortBy,
})
export const updateShowFilterOptions = (toggleFilterOptions) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_SHOW_FILTER_OPTIONS,
  toggleFilterOptions,
})

export const updateCreatedBy = (createdBy) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_CREATED_BY,
  createdBy,
})

export const updateIsScreeningAnswerAllNull = (isScreeningAnswerAllNull) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_ANSWER_ALL_NULL,
  isScreeningAnswerAllNull,
})

export const updateIsAge = (isAge) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_IS_AGE,
  isAge,
})

export const updateAge = (patientAge) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_AGE,
  patientAge,
})

export const updateBirthYear = (patientBirthYear) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_BIRTH_YEAR,
  patientBirthYear,
})

export const updateEmail = (email) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_EMAIL,
  email,
})

export const updateCell = (cell) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_CELL,
  cell,
})

export const updateTabIndex = (tabIndex) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_TAB_INDEX,
  tabIndex,
})

export const updateScreeningQuestionIds = (screeningQuestionIds) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_QUESTION_IDS,
  screeningQuestionIds,
})

export const updateWaitlistJoinDate = (waitlistJoinedDate) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_JOIN_DATE,
  waitlistJoinedDate,
})

export const updateLinkSentAt = (linkSentAt) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_LINK_SENT_AT,
  linkSentAt,
})

export const updateLinkSentCount = (linkSentCount) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_LINK_SENT_COUNT,
  linkSentCount,
})

export const updateIsStandBy = (isStandBy) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_IS_STANDBY,
  isStandBy,
})

export const updatePostalCode = (postalCode) => ({
  type: UPDATE_COVID_DOSE_THREE_WAITLIST_POSTAL_CODE,
  postalCode,
})

export const updateLastMassSendTime = (lastMassSendTime) => ({
  type: UPDATE_COVID_DOSE_THREE_LAST_MASS_SEND_TIME,
  lastMassSendTime,
})

export const updateDose1Date = (dose1Date) => ({
  type: UPDATE_COVID_DOSE_THREE_DOSE_ONE_DATE,
  dose1Date,
})

export const updateDose2Date = (dose2Date) => ({
  type: UPDATE_COVID_DOSE_THREE_DOSE_TWO_DATE,
  dose2Date,
})

export const updateLastDoseDate = (lastDoseDate) => ({
  type: UPDATE_COVID_DOSE_THREE_DOSE_LAST_DATE,
  lastDoseDate,
})

export const updateNumberOfLastDoses = (numOfLastDoses) => ({
  type: UPDATE_NUMBER_OF_LAST_DOSES,
  numOfLastDoses,
})

export const updateDose1Location = (dose1Location) => ({
  type: UPDATE_COVID_DOSE_THREE_DOSE_ONE_LOCATION,
  dose1Location,
})

export const updateDose2Location = (dose2Location) => ({
  type: UPDATE_COVID_DOSE_THREE_DOSE_TWO_LOCATION,
  dose2Location,
})

export default reducer
