import * as React from 'react'
import { ReviewAndConfirm as ReviewAndConfirmForm } from './ReviewAndConfirm'
import useYupValidationResolver from '~/hooks/yupValidationResolverHook'
import { FormState } from '~/pages/AppointmentIntake/contexts/BookingContext/BookingProvider'
import { reviewAndConfirmValidationSchema } from './reviewAndConfirmValidationSchema'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { WebViewContext } from '~/context/WebViewContext'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { FormProvider, useForm } from 'react-hook-form'
import { BookingDetailsWrapper } from '../../components'
import { Loading } from '~/components/loading/loading'
import { getAaiTitleAndSubtitle } from '~/lib/appointmentActivity'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

/**
 * Review & Confirm component shown to patients only, across all flows including both
 * patient-initiated and pharacist-initiated flows.
 */
export const ReviewAndConfirm = () => {
  const {
    state: { appointmentActivity, pharmacy },
    setBookingError,
    submitIntakeAsync,
  } = useBookingContext()
  const { nextStep } = useFormManager()
  const [error, setError] = React.useState<string>('')
  const { t } = useMedMeTranslation('common')

  // validation schema enforces client-side only that the reviewAndConfirm checkbox was checked
  const validationSchema = React.useMemo(
    () => reviewAndConfirmValidationSchema(t),
    []
  )

  const resolver = useYupValidationResolver(validationSchema)
  const methods = useForm({
    resolver: resolver,
    shouldUnregister: false,
  })
  const { inWebView, sendEvent } = React.useContext(WebViewContext)

  // note: MedMe GAv4 analytics is tracked where the mutation is called
  const handleFormSubmit = async (_data: FormState) => {
    const { error, appointment, timeslotTaken } = await submitIntakeAsync()
    const aaiTitleAndSubtitle = getAaiTitleAndSubtitle(appointmentActivity)

    if (timeslotTaken) {
      setBookingError('timeslotTaken')
      return
    }

    if (error) {
      setError(error)
      return
    }

    // @todo this is most reliably handled in the onComplete callback of the mutation where it is called
    if (inWebView) {
      sendEvent('success', {
        appointmentId: appointment.id,
        event: 'success',
        eventType: appointment?.isWalkin ? 'walkin' : 'booking',
        province: pharmacy.province,
        type: aaiTitleAndSubtitle,
      })
    }

    nextStep()
  }

  if (error) {
    throw new Error(error)
  }

  return (
    <FormProvider {...methods}>
      <BookingDetailsWrapper>
        {methods.formState.isSubmitting ? (
          <Loading
            title={t('loading.title.submitting')}
            subTitle={t('loading.subtitle.pleaseWaitAMoment')}
            overlay={true}
          />
        ) : null}
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <ReviewAndConfirmForm />
        </form>
      </BookingDetailsWrapper>
    </FormProvider>
  )
}
