import * as React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { Body } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { SectionTitle, Container } from './SelectionHeader.style'

export const SelectDateTimeText: React.FC<{
  prefix?: string
  titlePrefix?: string
  isWalkin?: boolean
}> = ({ prefix, titlePrefix, isWalkin = false }) => {
  const { t } = useMedMeTranslation('patient')

  return (
    <Container>
      {prefix && <Body>{prefix}</Body>}
      <SectionTitle>
        {titlePrefix}
        {isWalkin ? t('selectWalkinOrBookAnApptText') : t('selectADateAndTime')}
      </SectionTitle>
    </Container>
  )
}
