/* eslint-disable camelcase -- google analytics consistently uses lower snake_case for params */
/* eslint-disable space-before-function-paren -- @todo fix eslint is irreconcilable vs prettier */
import { isProd } from '~/util/envMethods'

/**
 * Type of supported event names in MedMe's Google Analytics v4 account.
 *
 * Event names are limited to 40 characters and can only be comprised of alphanumeric characters and
 * underscores per Google. For MedMe's GAv4 account they must follow the convention:
 *
 * ```
 * <ACTOR>__<INITIATING_ACTOR>_<FLOW_NAMESPACE/GROUP>_<FLOW>__<EVENT_ACTION>
 * ```
 *
 * - for appointment bookings use generic `appt_booking` for both Appointment Types + Appointment Activities
 * - for completion/conversion events (e.g. successful booking) use <event_action> 'end'
 *
 * Refer to 'Event Names + Event Naming Conventions' heading in Notion doc below.
 *
 * Note: the earlier `patient_appointment_booking_complete` (2023-03) is now covered by `pt__pt_appt_booking_common__end`
 *
 * @see {@link https://www.notion.so/medmehealth/Google-Analytics-at-MedMe-8e8eace2d09f49c289ccf2872100d9ac?pvs=4}
 */
export type MedMeGoogleAnalyticsEventName =
  | 'pt__pt_appt_booking_common__end' // intake: 'public' + 'bookNextAppointment'
  | 'pt__ph_appt_booking_confirm__end' // intake: 'documentationLink'
  | 'pt__ph_appt_booking_invite__end' // intake: 'bookingLink'
  | 'iformsLink' // iformsLink

/**
 * Interface of the object returned by invoking the `useAnalytics()` hook.
 */
export interface UseAnalyticsHookReturnValue {
  trackAnalyticsEvent: typeof trackGoogleAnalyticsVersion4Event
}

/**
 * Note GAv4 recommends against high cardinality id's.
 * The non-unique patients are incompatible with other notions of user tracking.
 *
 * Appointment ID's and Patient ID's are high cardinality and should not be tracked via
 * event params.
 * @see {@link https://support.google.com/analytics/answer/12226705?hl=en}
 */
export interface MedMeAnalyticsAppointmentBookingFlowParams {
  appointment_booking_type: 'walk-in' | 'scheduled'
}

/**
 * Default eventParams to include in argument passed to gtag().
 */
const DEFAULT_EVENT_PARAMS = {}

/**
 * Track an event in Google Analytics v4 in production deployments.
 *
 * For non-production deployments this function will output the event to the console log
 * instead.
 *
 * Assumes global `gtag()` is configured to push to the data stream of
 * MedMe's internal Google Analytics v4 account: "G-3XLY12BEFW".
 */
function trackGoogleAnalyticsVersion4Event(
  eventName: MedMeGoogleAnalyticsEventName,
  eventParams?:
    | Gtag.CustomParams
    | Gtag.ControlParams
    | Gtag.EventParams
    | undefined
): void {
  if (typeof window?.gtag !== 'function') {
    return
  }

  const allEventParams = {
    ...DEFAULT_EVENT_PARAMS,
    ...(!!eventParams && typeof eventParams === 'object' ? eventParams : {}),
  }

  if (isProd) {
    gtag('event', eventName, allEventParams)
  } else {
    console.log(
      `useAnalytics hook: mock fire analytics event '${eventName}':`,
      JSON.stringify(allEventParams, null, 2)
    )
  }
}

/**
 * Hook for MedMe's Google Analytics v4 property that returns an object containing a
 * stable reference to a `trackEvent()` function.
 *
 * This function only fires event for production deploy environments per `isProd` defined in
 * `utils/envMethods.ts` otherwise it writes to the console log instead.
 *
 * Usage:
 *
 * ```ts
 * // trackEvent is a stable reference that's safe to include in dependency arrays
 * const { trackEvent } = useAnalytics()
 * ```
 *
 * It is assumed that the `gtag()` definition in `index.html`/`index.js` is "wired up" to
 * the data stream corresponding to MedMe's internal Google Analytics v4 account: "G-3XLY12BEFW".
 *
 * @see ReviewAndConfirmStepFormComponent new intake (appointment booking) flow
 * @see useCreateNewPatientAndAppointment hook re new intake (appointment booking) flow
 * @see submission.gql.ts legacy intake (appointment booking) flow
 */
export function useAnalytics(): UseAnalyticsHookReturnValue {
  return {
    trackAnalyticsEvent: trackGoogleAnalyticsVersion4Event,
  }
}
