import React from 'react'
import MuiIconButton from '@material-ui/core/IconButton'

const IconButton = ({ children, ...props }) => (
  <MuiIconButton
    size="small"
    style={{ alignSelf: 'center' }}
    {...props}
    color="default"
  >
    {children}
  </MuiIconButton>
)

export default IconButton
