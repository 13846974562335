import React from 'react'
import { IAppointmentActivityAccordionProps } from './AppointmentActivityAccordion.types'
import { Accordion, AccordionDetails, Box, Typography } from '@material-ui/core'
import { MarkdownComponent } from '~/lib/markdown/MarkdownComponent'
import { AppointmentActivityBadge } from '../AppointmentActivityBadge'
import {
  BadgesContainer,
  BoldText,
  Heading4,
  MethodDropDownAndSubDescriptionContainer,
  StylizedAccordionSummary,
  StylizedExpandMoreIcon,
  TitleAndBadgeContainer,
  TitleAndDescriptionContainer,
  TitleDescriptionAndIconContainer,
  TitleLayout,
} from './AppointmentActivityAccordion.styles'
import { AppointmentActivityCardSubmitButton } from '../AppointmentActivityCardSubmitButton'
import { AppointmentActivityCardMethodsDropDown } from '../AppointmentActivityCardMethodsDropDown'
import { useIsMaxWidthOfDevice } from '~/hooks/useIsMaxWidthOfDevice'
import { useAAIMetadata } from './AppointmentActivityAccordion.utils'
import { AppointmentActivityIcon } from '../AppointmentActivityIcon'

export const AppointmentActivityAccordion: React.FC<
  IAppointmentActivityAccordionProps
> = ({
  aai,
  isSelected,
  isAvailable,
  appointmentMethod,
  onExpand,
  onCollapse,
  onSubmit,
  isLegacy,
}) => {
  const isSmallDevice = useIsMaxWidthOfDevice('800px')
  const aaiMetadata = useAAIMetadata({ aai })

  if (!aaiMetadata) return null

  const {
    appointmentActivityId,
    title,
    subtitle,
    badges,
    iconUrl,
    description,
    subDescription,
    enabledMethods,
  } = aaiMetadata

  const onAccordionChange = (
    _: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      onExpand(appointmentActivityId)
    } else {
      onCollapse('')
    }
  }

  return (
    <Accordion expanded={isSelected} onChange={onAccordionChange}>
      <StylizedAccordionSummary
        expandIcon={<StylizedExpandMoreIcon isExpanded={isSelected} />}
        isExpanded={isSelected}
      >
        <TitleDescriptionAndIconContainer display="flex" flexDirection="row">
          <AppointmentActivityIcon src={iconUrl} />

          <TitleAndDescriptionContainer display="flex" flexDirection="column">
            <TitleAndBadgeContainer isSmallDevice={isSmallDevice}>
              <TitleLayout>
                <BoldText variant="h6">{title}</BoldText>
                {Boolean(subtitle) && (
                  <Typography variant="h6">{subtitle}</Typography>
                )}
              </TitleLayout>

              <BadgesContainer>
                {badges.map((badge, index) => (
                  <AppointmentActivityBadge badge={badge} key={index} />
                ))}
              </BadgesContainer>
            </TitleAndBadgeContainer>

            {description === subDescription || !description.length ? null : (
              <Heading4 isExpanded={isSelected}>
                <MarkdownComponent>{description}</MarkdownComponent>
              </Heading4>
            )}
          </TitleAndDescriptionContainer>
        </TitleDescriptionAndIconContainer>
      </StylizedAccordionSummary>

      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%">
          <MethodDropDownAndSubDescriptionContainer
            display="flex"
            flexDirection="column"
          >
            <AppointmentActivityCardMethodsDropDown
              appointmentMethod={appointmentMethod}
              enabledMethods={enabledMethods}
              isLegacy={isLegacy}
            />

            {subDescription.length ? (
              <Heading4>
                <MarkdownComponent>{subDescription}</MarkdownComponent>
              </Heading4>
            ) : null}
          </MethodDropDownAndSubDescriptionContainer>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            marginTop="1rem"
          >
            <AppointmentActivityCardSubmitButton
              onClick={() => onSubmit(appointmentActivityId)}
              isAvailable={isAvailable}
              isLegacy={isLegacy}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
