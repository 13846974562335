
const initialState = {
  offset: 0,
  search: '',
  sortBy: 'SENT_DATE_TIME_SORT_DESC',
  showFilterOptions: false,
  sentDate: {
    startDateTime: '',
    endDateTime: '',
  },
  receivedDate: {
    startDateTime: '',
    endDateTime: '',
  },
  formStatus: null,
  appointmentTypeId: null,
  sentBy: '',
  reviewedBy: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FORM_OFFSET:
      return {
        ...state,
        offset: action.offset,
      }
    case UPDATE_FORM_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    case CLEAR_FORM_FILTER:
      return {
        ...initialState,
        showFilterOptions: state.showFilterOptions,
      }
    case UPDATE_SHOW_FORM_FILTER_OPTIONS:
      return {
        ...state,
        showFilterOptions: action.toggleFilterOptions,
      }
    case UPDATE_FORM_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case UPDATE_SENT_DATE:
      return {
        ...state,
        sentDate: action.sentDate,
      }
    case UPDATE_RECEIVED_DATE:
      return {
        ...state,
        receivedDate: action.receivedDate,
      }
    case UPDATE_SENT_BY:
      return {
        ...state,
        sentBy: action.sentBy,
      }
    case UPDATE_FORM_STATUS:
      return {
        ...state,
        formStatus: action.formStatus,
      }
    case UPDATE_REVIEWED_BY:
      return {
        ...state,
        reviewedBy: action.reviewedBy,
      }
    case UPDATE_APPOINTMENT_TYPE_ID:
      return {
        ...state,
        appointmentTypeId: action.appointmentTypeId,
      }
    default:
      return state
  }
}

const UPDATE_FORM_OFFSET = 'UPDATE_FORM_OFFSET'
const UPDATE_FORM_SEARCH = 'UPDATE_FORM_SEARCH'
const CLEAR_FORM_FILTER = 'CLEAR_FORM_FILTER'
const UPDATE_SHOW_FORM_FILTER_OPTIONS = 'UPDATE_SHOW_FORM_FILTER_OPTIONS'
const UPDATE_FORM_SORT_BY = 'UPDATE_FORM_SORT_BY'
const UPDATE_SENT_DATE = 'UPDATE_SENT_DATE'
const UPDATE_RECEIVED_DATE = 'UPDATE_RECEIVED_DATE'
const UPDATE_SENT_BY = 'UPDATE_SENT_BY'
const UPDATE_REVIEWED_BY = 'UPDATE_REVIEWED_BY'
const UPDATE_FORM_STATUS = 'UPDATE_FORM_STATUS'
const UPDATE_APPOINTMENT_TYPE_ID = 'UPDATE_APPOINTMENT_TYPE_ID'

export const updateFormOffset = (offset) => ({
  type: UPDATE_FORM_OFFSET,
  offset,
})

export const updateFormSearch = (search) => ({
  type: UPDATE_FORM_SEARCH,
  search,
})

export const clearFormFilter = () => ({
  type: CLEAR_FORM_FILTER,
})

export const updateShowFormFilterOptions = (toggleFilterOptions) => ({
  type: UPDATE_SHOW_FORM_FILTER_OPTIONS,
  toggleFilterOptions,
})

export const updateFormSortBy = (sortBy) => ({
  type: UPDATE_FORM_SORT_BY,
  sortBy,
})

export const updateSentDate = (sentDate) => ({
  type: UPDATE_SENT_DATE,
  sentDate,
})

export const updateReceivedDate = (receivedDate) => ({
  type: UPDATE_RECEIVED_DATE,
  receivedDate,
})

export const updateSentBy = (sentBy) => ({
  type: UPDATE_SENT_BY,
  sentBy,
})

export const updateReviewedBy = (reviewedBy) => ({
  type: UPDATE_REVIEWED_BY,
  reviewedBy,
})

export const updateFormStatus = (formStatus) => ({
  type: UPDATE_FORM_STATUS,
  formStatus,
})

export const updateAppointmentTypeId = (appointmentTypeId) => ({
  type: UPDATE_APPOINTMENT_TYPE_ID,
  appointmentTypeId,
})

export default reducer
