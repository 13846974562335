import * as React from 'react'
import R5 from 'fhir/r5'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { usePublicCreateQuestionnaireResponseMutation } from '~/graphql/types/schemaNode.type'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'

type PublicCreateQuestionnaireResponses = (
  patientId: string,
  appointmentId: string
) => Promise<void>

export const usePublicCreateQuestionnaireResponses = (
  questionnaires: FhirQuestionnaire[]
) => {
  const [mutation] = usePublicCreateQuestionnaireResponseMutation({
    context: {
      clientName: EServer.NODE,
    },
  })

  const publicCreateQuestionnaireResponses =
    React.useCallback<PublicCreateQuestionnaireResponses>(
      async (patientId, appointmentId) => {
        const responses = questionnaires.map<{
          questionnaireVersionId: string
          questionnaireResponse: R5.QuestionnaireResponse
        }>((questionnaire) => {
          return {
            questionnaireVersionId: questionnaire.version,
            questionnaireResponse:
              questionnaire.generateQuestionnaireResponse(),
          }
        })
        try {
          /* Responses must be created in the questionnaire sequence order.
           * Future editing of responses relies on response created_at timestamp
           * for sorting. */
          for (const response of responses) {
            const { questionnaireVersionId, questionnaireResponse } = response
            await mutation({
              variables: {
                patientId: patientId,
                appointmentId: appointmentId,
                questionnaireVersionId: questionnaireVersionId,
                fhirJson: questionnaireResponse,
              },
            })
          }
        } catch (e) {
          // NOTE: expected to soft fail until response generation is moved to the createAppointment endpoint as we have no createAppointment rollback
          // mechanism in place.
          console.error(
            'usePublicCreateQuestionnaireResponses: failed to create a response' +
              e
          )
        }
      },
      [mutation, questionnaires]
    )

  return {
    publicCreateQuestionnaireResponses,
  }
}
