import { JsonFormStep } from './types'

export const activityLanding: JsonFormStep = {
  type: 'preset',
  preset: 'activityLanding',
  metadata: {
    id: 'activityLanding',
    name: 'Activity Landing',
    path: 'activity-landing',
    showOnSteps: false,
  },
}
