import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParser from 'dayjs/plugin/customParseFormat'

export const initializeDayJs = () => {
  dayjs.extend(localizedFormat)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(customParser)
}
