import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Minor Ailment Eligibility Screening ',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const completingQuestions = createFhirQuestionnaireItem({
    text: "Please answer the questions below to confirm you're eligible to be seen by a pharmacist. If completing on behalf of someone else, answer from their perspective.",
    linkId: 'completingQuestions',
    type: 'display',
  })

  const redMessage = createFhirQuestionnaireItem({
    text: '_If you have any reason to believe you may require urgent medical attention, please call 9-1-1 or go to the nearest hospital emergency room._',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const healthCard = createFhirQuestionnaireItem({
    text: 'This service is free for eligible Ontario Health Card holders. A professional fee may apply if you do not have a valid card. \n\n&nbsp; \n\n',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const olderThanTwelve = createFhirQuestionnaireItem({
    text: 'Are you booking for someone that is at least 12 years old or older?',
    linkId: 'olderThanTwelve',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Suspected Urinary Tract Infections in patients under 12 years old require further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  const sexAtBirth = createFhirQuestionnaireItem({
    text: 'Was your sex assigned at birth female? ',
    linkId: 'sexAtBirth',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Suspected Urinary Tract Infection in individuals assigned male at birth requires further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  const pregnant = createFhirQuestionnaireItem({
    text: 'Could you be or are you currently pregnant?',
    linkId: 'pregnant',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Suspected Urinary Tract Infection in pregnancy requires further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    enableWhen: [
      {
        question: 'sexAtBirth',
        operator: '=',
        answerString: 'yes',
      },
    ],
    enableBehavior: 'all',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    required: true,
  })

  const pastDiagnosis = createFhirQuestionnaireItem({
    text: 'In the past, have you ever been diagnosed with an Uncomplicated Urinary Tract Infection by a Physician or Nurse Practitioner?',
    linkId: 'pastDiagnosis',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Prior diagnosis of Urinary Tract Infection is required by a Physician or Nurse Practitioner in order to be eligible for a Urinary Tract Infection assessment by a pharmacist. Please seek further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  const questionnaireHeading = createFhirQuestionnaireItem({
    linkId: 'questionnaireHeading',
    text: 'Screening Questions',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })
  const experiencingSymptoms = createFhirQuestionnaireItem({
    text: `Are you experiencing any of the following symptoms?
- Fever
- Chills
- A sudden feeling of cold (shivering or sweating)
- Flank or back pain
- Significant illness or discomfort
- Persistent or recurrent pain before, during or after intercourse
- Red coloured urine 
    `,
    linkId: 'experiencingSymptoms',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'These symptoms are not typical of an Uncomplicated Urinary Tract Infection and require further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  const antibioticTreatment = createFhirQuestionnaireItem({
    text: 'Within the last 4 weeks, have you received antibiotic treatment for an uncomplicated urinary tract infection?',
    linkId: 'antibioticTreatment',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Symptoms of a Urinary Tract Infection recurring within 4 weeks of a previous episode treated with antibiotics require further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      redMessage,
      healthCard,
      questionnaireHeading,
      olderThanTwelve,
      sexAtBirth,
      pregnant,
      pastDiagnosis,
      experiencingSymptoms,
      antibioticTreatment,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningUrinaryTractInfectionIndiesONJson: JsonFhirFormStep =
  {
    type: 'fhir',
    fhir: fhirMedicalScreeningQuestionnaire,
    filter: {
      regionCodes: ['ON'],
      isEnterprise: false,
      appointmentActivity: {
        category: 'MINOR_AILMENT',
        urlParams: ['UrinaryTractInfection'],
      },
    },
    metadata: {
      id: 'fhirMedicalScreeningUrinaryTractInfectionIndiesONJson',
      name: 'Medical Screening',
      path: 'medical-screening',
      showOnSteps: true,
    },
  }
