import { FieldError, FieldErrors } from 'react-hook-form'
import { EngineResult } from 'json-rules-engine'
import {
  isEnableWhenEvent,
  isValidationError,
  isRequiredFieldValidationError,
} from '../../rulesEngine'
import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
} from '~/lib/fhir/fhirQuestionnaire'

export type ValidationOptions = { skipRequiredFields: boolean }

export const createValidationErrorHandler =
  (options: ValidationOptions = { skipRequiredFields: false }) =>
  (result: EngineResult, questionnaire: FhirQuestionnaire): FieldErrors => {
    const errors = result.failureEvents.reduce(
      (acc: Record<string, FieldError>, event) => {
        if (
          options.skipRequiredFields &&
          isRequiredFieldValidationError(event)
        ) {
          return acc
        }

        if (isValidationError(event)) {
          return {
            ...acc,
            [event.params.fieldName]: {
              type: event.type,
              message: event.params.message,
            },
          }
        }
        return acc
      },
      {}
    )

    const disabledFields = result.failureEvents.reduce(
      (acc: Record<string, boolean>, event) => {
        if (isEnableWhenEvent(event)) {
          return {
            ...acc,
            [event.params.fieldName]: true,
          }
        }
        return acc
      },
      {}
    )

    const getFilteredValidations = (
      acc: FieldErrors,
      item: FhirQuestionnaireItem
    ): FieldErrors => {
      const { linkId } = item
      if (disabledFields[linkId] === true) return acc

      if (item.item?.length > 0) {
        acc = { ...acc, ...item.item.reduce(getFilteredValidations, acc) }
      }

      if (errors[linkId] !== undefined) {
        acc = { ...acc, [linkId]: errors[linkId] }
      }

      return acc
    }

    const enabledValidations = questionnaire.item.reduce(
      getFilteredValidations,
      {}
    )

    return enabledValidations
  }

export type ValidationErrorHandler = ReturnType<
  typeof createValidationErrorHandler
>
