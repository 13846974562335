import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { useGetAvailableProvincesQuery } from '~/graphql/types/schemaNode.type'

export const useGetAvailableProvinces = (groupId: string) => {
  const { data: availableProvinces, loading } = useGetAvailableProvincesQuery({
    variables: {
      groupId,
    },
    context: {
      clientName: EServer.NODE,
    },
  })

  // For now we only support one country = 'CA
  // TODO: Update this when we support multiple countries

  return {
    availableProvinces:
      availableProvinces?.appointmentActivityInstanceLocations[0]?.regionCodes,
    loading,
  }
}
