import {
  Maybe,
  ScreeningAnswer as JavaScreeningAnswer,
} from '~/graphql/types/schema.type'
import { DeepPartial } from '~/pages/AppointmentIntake/lib'
import { ScreeningAnswer } from '..'

// TODO: make ScreeningAnswer more accurate, a lot of these should not be undefined
// but its merely to address dependencies.
export const createAppointmentScreeningAnswers = (
  screeningAnswers: DeepPartial<Maybe<Maybe<JavaScreeningAnswer>[]>> | undefined
): ScreeningAnswer[] => {
  if (!screeningAnswers) return []
  return screeningAnswers.map((screeningAnswer) => ({
    answer: screeningAnswer?.answer,
    comments: screeningAnswer?.comments ?? undefined,
    dateInput: screeningAnswer?.dateInput ?? undefined,
    integerInput: screeningAnswer?.integerInput ?? undefined,
    jsonInput: screeningAnswer?.jsonInput,
    screeningQuestion: screeningAnswer?.screeningQuestion ?? undefined,
  }))
}
