import { setContext } from '@apollo/client/link/context'

import { getAccessToken, getIsAccessTokenActive } from '~/auth'
import {
  getOrganizationId,
  getPharmacyId,
  getTenantId,
} from '~/lib/tenantMetadata'
import { getSublocationIdFromSearchParams } from '~/util/getSublocationIdFromSearchParams'

const addOrganizationId = (orgIdFromContext?: string) => {
  if (orgIdFromContext) {
    return { 'x-organization-id': orgIdFromContext }
  }

  const orgId = getOrganizationId()
  return orgId ? { 'x-organization-id': orgId } : undefined
}

const addSublocationId = (
  sublocationId: string | null = getSublocationIdFromSearchParams()
) => (sublocationId ? { 'x-sublocation-id': sublocationId } : undefined)

function getHeaders(context: {
  pharmacyId?: string
  regionCode?: string
  countryCode?: string
  headers?: Record<string, string>
  organizationId?: string
  sublocationId?: string
}) {
  const pharmacyId = getPharmacyId(true)
  const tenantId = getTenantId()

  return {
    'x-tenantid': tenantId ?? '',
    'x-pharmacyid': context.pharmacyId ?? pharmacyId ?? '',
    'x-region-code': context.regionCode ?? '',
    'x-country-code': context.countryCode || 'CA',
    'accept-language': 'en-CA',
    ...addOrganizationId(context.organizationId),
    ...addSublocationId(context.sublocationId),
    ...context.headers,
  }
}

export const authLink = (refreshToken: () => Promise<string>) =>
  setContext(async (_operation, previousContext) => {
    const token = getAccessToken()

    if (!token) {
      return {
        ...previousContext,
        headers: getHeaders(previousContext),
      }
    }

    if (getIsAccessTokenActive(token)) {
      return {
        ...previousContext,
        headers: {
          authorization: `Bearer ${token}`,
          ...getHeaders(previousContext),
        },
      }
    }

    const refreshedToken = await refreshToken()

    return {
      ...previousContext,
      headers: {
        authorization: `Bearer ${refreshedToken}`,
        ...getHeaders(previousContext),
      },
    }
  })
