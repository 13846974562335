import { createFhirInputItem } from '../fhirInputItem'

export const createFhirStringItem: typeof createFhirInputItem = (json) => {
  if (json.type !== 'string')
    throw new Error('createFhirDisplayItem: json.item !== "string"')

  return {
    type: 'string',
  }
}
