
const initialState = {
  offset: 0,
  search: '',
  sortBy: 'WAITLIST_JOINED_DATE_ASC',
  showFilterOptions: true,
  appointmentTypes: null,
  createdAt: null,
  createdBy: null,
  linkSentAt: null,
  linkSentCount: null,
  patientAge: null,
  tabIndex: 0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_WAITLIST_OFFSET:
      return {
        ...state,
        offset: action.offset,
      }
    case UPDATE_WAITLIST_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    case UPDATE_WAITLIST_APPOINTMENT_TYPES:
      return {
        ...state,
        appointmentTypes: action.appointmentTypes,
      }
    case CLEAR_WAITLIST_FILTER:
      return {
        ...initialState,
        showFilterOptions: state.showFilterOptions,
      }
    case UPDATE_WAITLIST_SHOW_FILTER_OPTIONS:
      return {
        ...state,
        showFilterOptions: action.toggleFilterOptions,
      }
    case UPDATE_WAITLIST_CREATED_AT:
      return {
        ...state,
        createdAt: action.createdAt,
      }
    case UPDATE_WAITLIST_AGE:
      return {
        ...state,
        patientAge: action.patientAge,
      }
    case UPDATE_WAITLIST_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex,
      }
    case UPDATE_WAITLIST_LINK_SENT_AT:
      return {
        ...state,
        linkSentAt: action.linkSentAt,
      }
    case UPDATE_WAITLIST_LINK_SENT_COUNT:
      return {
        ...state,
        linkSentCount: action.linkSentCount,
      }
    case UPDATE_WAITLIST_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    case UPDATE_WAITLIST_CREATED_BY:
      return {
        ...state,
        createdBy: action.createdBy,
      }
    default:
      return state
  }
}

const UPDATE_WAITLIST_OFFSET = 'UPDATE_WAITLIST_OFFSET'
const UPDATE_WAITLIST_SEARCH = 'UPDATE_WAITLIST_SEARCH'
const CLEAR_WAITLIST_FILTER = 'CLEAR_WAITLIST_FILTER'
const UPDATE_WAITLIST_APPOINTMENT_TYPES = 'UPDATE_WAITLIST_APPOINTMENT_TYPES'
const UPDATE_WAITLIST_SHOW_FILTER_OPTIONS =
  'UPDATE_WAITLIST_SHOW_FILTER_OPTIONS'
const UPDATE_WAITLIST_CREATED_AT = 'UPDATE_WAITLIST_CREATED_AT'
const UPDATE_WAITLIST_AGE = 'UPDATE_WAITLIST_AGE'
const UPDATE_WAITLIST_TAB_INDEX = 'UPDATE_WAITLIST_TAB_INDEX'
const UPDATE_WAITLIST_LINK_SENT_AT = 'UPDATE_WAITLIST_LINK_SENT_AT'
const UPDATE_WAITLIST_LINK_SENT_COUNT = 'UPDATE_WAITLIST_LINK_SENT_COUNT'
const UPDATE_WAITLIST_SORT_BY = 'UPDATE_WAITLIST_SORT_BY'
const UPDATE_WAITLIST_CREATED_BY = 'UPDATE_WAITLIST_CREATED_BY'

export const updateOffset = (offset) => ({
  type: UPDATE_WAITLIST_OFFSET,
  offset,
})

export const updateSearch = (search) => ({
  type: UPDATE_WAITLIST_SEARCH,
  search,
})

export const clearFilter = () => ({
  type: CLEAR_WAITLIST_FILTER,
})

export const updateAppointmentTypes = (appointmentTypes) => ({
  type: UPDATE_WAITLIST_APPOINTMENT_TYPES,
  appointmentTypes,
})

export const updateShowFilterOptions = (toggleFilterOptions) => ({
  type: UPDATE_WAITLIST_SHOW_FILTER_OPTIONS,
  toggleFilterOptions,
})

export const updateCreatedAt = (createdAt) => ({
  type: UPDATE_WAITLIST_CREATED_AT,
  createdAt,
})

export const updateAge = (patientAge) => ({
  type: UPDATE_WAITLIST_AGE,
  patientAge,
})

export const updateTabIndex = (tabIndex) => ({
  type: UPDATE_WAITLIST_TAB_INDEX,
  tabIndex,
})

export const updateLinkSentAt = (linkSentAt) => ({
  type: UPDATE_WAITLIST_LINK_SENT_AT,
  linkSentAt,
})

export const updateLinkSentCount = (linkSentCount) => ({
  type: UPDATE_WAITLIST_LINK_SENT_COUNT,
  linkSentCount,
})

export const updateSortBy = (sortBy) => ({
  type: UPDATE_WAITLIST_SORT_BY,
  sortBy,
})

export const updateCreatedBy = (createdBy) => ({
  type: UPDATE_WAITLIST_CREATED_BY,
  createdBy,
})
export default reducer
