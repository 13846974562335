import { Questionnaire } from 'fhir/r4'
import { createJsonString } from '../../../../../../lib/json'
import { createFhirQuestionnaireItem } from '../fhirQuestionnaireItem'

export const createFhirQuestionnaire = (
  json?: Questionnaire
): FhirQuestionnaire => {
  return {
    name: createJsonString(json?.name ?? ''),
    resourceType: json?.resourceType ?? 'Questionnaire',
    status: json?.status ?? 'draft',
    item: json?.item?.map((item) => createFhirQuestionnaireItem(item)) ?? [],
  }
}

export type FhirQuestionnaire = Pick<
  Required<Questionnaire>,
  'name' | 'resourceType' | 'status' | 'item'
>
