import { JsonFormStep } from './types'

export const cancelledAppointmentLanding: JsonFormStep = {
  type: 'preset',
  preset: 'cancelledAppointmentLanding',
  metadata: {
    id: 'cancelledAppointmentLanding',
    name: 'Cancelled Appointment',
    path: 'cancelled-appointment',
    showOnSteps: false,
  },
}
