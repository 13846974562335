import React, { useState } from 'react'
import { AiChatContext } from './AiChatContext'
import { useHotkeys } from 'react-hotkeys-hook'

export const AiChatProvider = ({ children }: { children: React.ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  useHotkeys(['ctrl+k', 'cmd+k'], () => setDrawerOpen((prev) => !prev), {
    preventDefault: true,
  })
  return (
    <AiChatContext.Provider value={{ drawerOpen, setDrawerOpen }}>
      {children}
    </AiChatContext.Provider>
  )
}
