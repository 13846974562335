import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtInputVariant,
  createFhirExtHideWhenDisabled,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import {
  createFhirCodingEntityPatient,
  createFhirCodingEntityAppointment,
} from '~/pages/AppointmentIntake/lib/fhir/systems'
import { presetFhirGridExt } from '../../../../../../lib/fhir/extensions/fhirExtGrid/presetFhirGridExt'
import { JsonFhirFormStep } from '../../../../types'

import { provinceValueSet } from '../../../lib/provinceValueSet'

export const cardHeading = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Renseignements médicaux',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Champs obligatoires (*)',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [heading, descReq],
  })
}

export const healthCard = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'healthCardHeading',
    type: 'display',
    text: 'Carte santé',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const desc = createFhirQuestionnaireItem({
    linkId: 'healthCardDesc',
    type: 'display',
    text: 'Veuillez saisir uniquement les caractères numériques de votre carte santé. Ces renseignements sont recueillis et utilisés à des fins de facturation par le régime provincial d’assurance-maladie.',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const hcProvince = createFhirQuestionnaireItem({
    linkId: 'healthCardProvince',
    type: 'choice',
    text: 'Province',
    required: true,
    answerOption: [
      ...provinceValueSet('', 'fr'),
      {
        valueCoding: {
          code: 'other',
          display: 'Autre',
        },
      },
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    code: [createFhirCodingEntityPatient({ code: 'healthCard.province' })],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcNumber = createFhirQuestionnaireItem({
    linkId: 'healthCardNumber',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'numeric' })],
    },
    text: 'Numéro de carte santé',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'healthCard.number' })],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 'auto',
      }),
    ],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcVersion = createFhirQuestionnaireItem({
    linkId: 'healthCardVersion',
    type: 'string',
    text: 'Code de version',
    code: [createFhirCodingEntityPatient({ code: 'healthCard.versionCode' })],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    enableBehavior: 'all',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const postOntarioDesc = createFhirQuestionnaireItem({
    linkId: 'postOntarioDesc',
    type: 'display',
    text: 'Le code de version est composé de deux lettres après votre numéro de carte santé à 10 chiffres (laisser vide si vous avez une carte rouge ou blanche).',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
    enableWhen: [
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    enableBehavior: 'any',
  })

  const noHealthCard = createFhirQuestionnaireItem({
    linkId: 'noHealthCard',
    type: 'boolean',
    text: ' Je n’ai pas de carte santé.',
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [
      heading,
      desc,
      hcProvince,
      hcNumber,
      hcVersion,
      postOntarioDesc,
      noHealthCard,
    ],
  })
}

const pcp = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'pcpHeading',
    type: 'display',
    text: 'Médecin de famille',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const pcpFirstName = createFhirQuestionnaireItem({
    linkId: 'pcpFirstName',
    type: 'string',
    text: 'Prénom',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.firstName' }),
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })
  const pcpLastName = createFhirQuestionnaireItem({
    linkId: 'pcpLastName',
    type: 'string',
    text: 'Nom de famille',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.lastName' }),
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const pcpPhone = createFhirQuestionnaireItem({
    linkId: 'pcpPhone',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    text: 'Numéro de téléphone',
    extension: [
      presetFhirGridExt({ xs: 12, sm: 4 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Numéro de téléphone invalide' },
        ],
      }),
    ],
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.phone' }),
    ],
    required: true,
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const pcpInfo = createFhirQuestionnaireItem({
    linkId: 'pcpInfo',
    type: 'display',
    text: '*Le pharmacien peut être amené à discuter et à partager des renseignements sur votre santé avec d’autres professionnels de la santé (par exemple des médecins) conformément aux principes généralement reconnus de gestion des traitements médicamenteux.',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const noPcp = createFhirQuestionnaireItem({
    linkId: 'noPcp',
    type: 'boolean',
    text: 'Je n’ai pas de médecin de famille',
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [heading, pcpFirstName, pcpLastName, pcpPhone, pcpInfo, noPcp],
  })
}

const reasonForVisit = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'reasonForVisitHeading',
    type: 'display',
    text: 'Raison de la visite',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const reasonForVisit = createFhirQuestionnaireItem({
    linkId: 'reasonForVisit',
    type: 'string',
    text: 'Raison de la visite',
    required: true,
    code: [createFhirCodingEntityAppointment({ code: 'reasonForVisit' })],
    maxLength: 128,
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [heading, reasonForVisit],
  })
}

export const fhirMedicalInformationQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalInformationQuestionnaire',
  name: 'Renseignements médicaux',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalInformationPage',
      type: 'group',
      item: [cardHeading(), healthCard(), pcp(), reasonForVisit()],
    }),
  ],
})

export const fhirMedicalInformationSdmClinicJsonFr: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalInformationQuestionnaire,
  metadata: {
    id: 'fhirMedicalInformationSdmClinicJsonFr',
    name: 'Renseignements médicaux',
    path: 'medical-information',
    showOnSteps: true,
  },
}
