import * as React from 'react'

// styles
import { CustomRadio, HiddenInput } from './hiddenRadio.style'

type Props = {
  className?: string
} & React.ComponentPropsWithoutRef<'input'>

const HiddenRadio = React.forwardRef<HTMLInputElement, Props>(
  ({ className, children, value, ...rest }, ref) => (
    <label>
      <HiddenInput type="radio" value={value} ref={ref} {...rest} />
      <CustomRadio className={className}>{children}</CustomRadio>
    </label>
  )
)

export default HiddenRadio
