import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import React, { useState } from 'react'
import { Editor, EditorProps, RawDraftContentState } from 'react-draft-wysiwyg'

import { EditorContainer } from './MarkdownTextField.styles'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { StandardTranslationNamespace } from '~/hooks/useMedMeTranslation/i18n-namespaces.types'

interface Props extends EditorProps {
  initialTextMarkup?: string
  onFieldChange?: (textMarkup: string) => void
  disabled?: boolean
  translationNamespace?: StandardTranslationNamespace
}

/**
 * Replace each empty line with &nbsp; to preserve new lines in when it's rendered as jsx
 * Add extra \n to each line to render line breaks in markdown
 */
const addMarkdownNewLines = (text: string) =>
  text
    .split('\n')
    .map((line) => (line.trim() === '' ? '&nbsp;' : line))
    .join('\n\n')

/**
 * Removes &nbsp; and extra \n's to avoid displaying unwanted characters and line breaks in markdown
 * Replaces <ins> and </ins> with ++ to display underline in markdown
 */
const underlineRegex = new RegExp('<ins>(.*?)</ins>', 'g')
const doubleNewLineRegex = new RegExp('\\n\\n', 'g')
const nonBreakingSpaceRegex = new RegExp('&nbsp;', 'g')

const preProcessMarkdownToDraft = (text: string) =>
  text
    .replace(underlineRegex, '++$1++')
    .replace(doubleNewLineRegex, '\n')
    .replace(nonBreakingSpaceRegex, '')
    .trim()

const customDraftToMarkdown = (rawContent: RawDraftContentState) => {
  const options = {
    preserveNewLines: true,
    styleItems: {
      UNDERLINE: {
        open: () => '<ins>',
        close: () => '</ins>',
      },
    },
  }
  return draftToMarkdown(rawContent, options)
}

const customMarkdownToDraft = (markdown: string) => {
  const options = {
    preserveNewLines: true,
    blockStyles: {
      ins_open: 'UNDERLINE',
    },
    remarkableOptions: {
      enable: {
        inline: 'ins',
      },
    },
  }
  return markdownToDraft(markdown, options)
}

export const MarkdownTextField = ({
  initialTextMarkup,
  onFieldChange,
  disabled,
  translationNamespace = 'common',
  ...props
}: Props) => {
  const { i18n } = useMedMeTranslation(translationNamespace)
  const [editorState, setEditorState] = useState(
    initialTextMarkup
      ? EditorState.createWithContent(
          convertFromRaw(
            customMarkdownToDraft(preProcessMarkdownToDraft(initialTextMarkup))
          )
        )
      : EditorState.createEmpty()
  )

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState)
    const markdownText = customDraftToMarkdown(
      convertToRaw(editorState.getCurrentContent())
    )
    const markDownTextWithPreservedNewLines = addMarkdownNewLines(markdownText)
    onFieldChange?.(markDownTextWithPreservedNewLines)
  }

  return (
    <EditorContainer>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        localization={{ locale: i18n.language }}
        readOnly={disabled}
        toolbarClassName="sticky-toolbar"
        toolbar={{
          options: ['inline', 'list', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
        }}
        {...props}
      />
    </EditorContainer>
  )
}
