import React from 'react'
import { Controller } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Layout, BooleanRadioGroup } from './booleanRadio.styles'

import { RadioInput, InputLabel } from '../../patientRegistration.styles'

const direction = {
  top: 'Top',
  end: 'Right',
  bottom: 'Bottom',
  start: 'Left',
}

const getDirection = (placement) => {
  if (!placement) return 'Right'
  return direction[placement]
}

const BooleanRadio = ({
  className,
  styles = {},
  error,
  helperText,
  labelStyles = {},
  ...rest
}) => (
  <Layout className={className} {...styles}>
    <Controller
      as={
        <BooleanRadioGroup>
          <FormControlLabel
            value="true"
            control={
              <RadioInput
                style={{
                  padding: 0,
                  [`margin${getDirection(labelStyles.placement)}`]: '7px',
                }}
                color="default"
              />
            }
            label={<InputLabel color={labelStyles.color || ''}>Yes</InputLabel>}
            labelPlacement={labelStyles.placement || 'end'}
            style={{ margin: 0, marginRight: '10px' }}
          />
          <FormControlLabel
            value="false"
            control={
              <RadioInput
                style={{
                  padding: 0,
                  margin: 0,
                  [`margin${getDirection(labelStyles.placement)}`]: '7px',
                }}
                color="default"
              />
            }
            label={<InputLabel color={labelStyles.color || ''}>No</InputLabel>}
            labelPlacement={labelStyles.placement || 'end'}
            style={{ margin: 0 }}
          />
        </BooleanRadioGroup>
      }
      {...rest}
    />
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </Layout>
)

export default BooleanRadio
