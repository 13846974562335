import { useEffect } from 'react'
import { reportException } from '~/tools/monitoring'

export const useStoreRwgToken = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const rwgToken = params.get('rwg_token')
    const pharmacyId = localStorage.getItem('pharmacyId')

    if (rwgToken) {
      const now = new Date()
      const expirationDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000)
      const tokenData = {
        value: rwgToken,
        pharmacyId: pharmacyId,
        expiration: expirationDate.getTime(),
        merchantChanged: 2,
      }
      localStorage.setItem('rwg_token', JSON.stringify(tokenData))
    } else {
      const storedToken = localStorage.getItem('rwg_token')
      let tokenData

      try {
        tokenData = storedToken ? JSON.parse(storedToken) : null
      } catch (error) {
        reportException(error)
        localStorage.removeItem('rwg_token')
        return
      }

      if (!tokenData) return

      const currentTime = new Date().getTime()
      if (tokenData.expiration < currentTime) {
        localStorage.removeItem('rwg_token')
        return
      }

      if (pharmacyId && tokenData.pharmacyId !== pharmacyId) {
        tokenData.merchantChanged = 1
        tokenData.pharmacyId = pharmacyId
        localStorage.setItem('rwg_token', JSON.stringify(tokenData))
      }
    }
  }, [])
}
