import { InjectionMaster } from '~/graphql/types/schema.type'

const GOOGLE_ANALYTICS_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  '_gac',
  '_gcl',
  '_ga',
  'gclid',
  'fbclid',
  'ttclid',
]

export const filterGATagsFromQueryString = (queryString: string) => {
  if (!queryString) return ''
  const query = new URLSearchParams(queryString)
  GOOGLE_ANALYTICS_PARAMS.forEach((param) => query.delete(param))
  return '?' + query.toString()
}

export const getPreselectedInjection = (
  queryString: string,
  sortedInjection: InjectionMaster[]
) => {
  if (!queryString) return null

  const query = new URLSearchParams(queryString)
  const injectionName = query.get('immunization')?.toLowerCase()

  if (!injectionName) return null

  const filteredInjection = sortedInjection.filter(
    (injection) =>
      injection.name?.toLowerCase().replace(/\s/g, '') === injectionName
  )[0]
  return filteredInjection
}
