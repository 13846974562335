import { Coding, QuestionnaireItemInitial } from 'fhir/r4'
import * as React from 'react'
import { JsonPrimitive, isJsonPrimitive } from '~/lib/json'

import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
} from '../../../../../lib/fhir'
import {
  isFhirCodingEntityAppointment,
  isFhirCodingEntityMap,
  isFhirCodingEntityPatient,
} from '../../../../../lib/fhir/systems'
import { FhirFormStateContext } from '../../FhirForm'

const getFallbackValue = (
  questionnaireItem: FhirQuestionnaireItem
): JsonPrimitive | undefined => {
  const { type } = questionnaireItem

  switch (type) {
    case 'choice':
      return ''
    case 'boolean':
      return false
    case 'decimal':
      return 0
    case 'integer':
      return 0
    case 'date':
      return ''
    case 'dateTime':
      return ''
    case 'time':
      return ''
    case 'string':
      return ''
    case 'text':
      return ''
    default:
      return undefined
  }
}

export const getFallbackValues: DefaultValuesAccumulator = (acc, item) => {
  const { linkId, type } = item
  if (type === 'group') {
    if (item.item)
      return { ...acc, ...item.item.reduce(getFallbackValues, acc) }
  }
  return {
    ...acc,
    [linkId]: getFallbackValue(item),
  }
}

const getDefaultValueFromInitial = (
  questionnaireItem: FhirQuestionnaireItem,
  initialItem?: QuestionnaireItemInitial
): JsonPrimitive | undefined => {
  const { type } = questionnaireItem

  switch (type) {
    case 'choice':
      return (
        questionnaireItem?.answerOption?.filter(
          (option) => option?.initialSelected === true
        )?.[0]?.valueCoding?.code ?? ''
      )
    case 'boolean':
      return initialItem?.valueBoolean ?? false
    case 'decimal':
      return initialItem?.valueDecimal ?? 0
    case 'integer':
      return initialItem?.valueInteger ?? 0
    case 'date':
      return initialItem?.valueDate ?? ''
    case 'dateTime':
      return initialItem?.valueDateTime ?? ''
    case 'time':
      return initialItem?.valueTime ?? ''
    case 'string':
      return initialItem?.valueString ?? ''
    case 'text':
      return initialItem?.valueString ?? ''
    default:
      return undefined
  }
}

type DefaultValuesAccumulator = (
  acc: InitialData['defaultValues'],
  item: FhirQuestionnaireItem
) => InitialData['defaultValues']

export const getDumbInitial: DefaultValuesAccumulator = (acc, item) => {
  const { linkId, type, initial, repeats } = item
  if (type === 'group') {
    if (item.item) return { ...acc, ...item.item.reduce(getDumbInitial, acc) }
  }
  if (repeats === true) {
    console.error('getFhirDefaultValues: repeats not supported')
  }
  return {
    ...acc,
    [linkId]: getDefaultValueFromInitial(item, initial?.[0]),
  }
}

export const getStateFieldValues =
  (state: FhirFormStateContext): DefaultValuesAccumulator =>
  (acc, item) => {
    const { linkId, type } = item
    if (type === 'group') {
      if (item.item)
        return { ...acc, ...item.item.reduce(getStateFieldValues(state), acc) }
    }

    const value = state.fields[linkId]
    if (value === undefined) return acc
    return {
      ...acc,
      [linkId]: value,
    }
  }

export const getStateEntityValues =
  (
    state: FhirFormStateContext,
    fieldEntityMap: InitialData['fieldEntityMap']
  ): DefaultValuesAccumulator =>
  (acc, item) => {
    const { linkId, type } = item
    if (type === 'group') {
      if (item.item)
        return {
          ...acc,
          ...item.item.reduce(getStateEntityValues(state, fieldEntityMap), acc),
        }
    }

    if (fieldEntityMap === undefined) return acc
    if (fieldEntityMap[linkId] === undefined) return acc
    if (fieldEntityMap[linkId].length === 0) return acc

    const { entity, path } = fieldEntityMap[linkId][0]

    if (['patient', 'appointment'].includes(entity)) {
      const get = (obj: any, path: string): any => {
        if (!obj) return undefined
        const [firstElement, ...rest] = path.split('.')
        if (firstElement === path) return obj[path]
        return get(obj[firstElement], rest.join('.'))
      }
      const value = get(state.patient, path)
      if (isJsonPrimitive(value)) return { ...acc, [linkId]: value }
    }

    return acc
  }

const INVALID_ENTITY_MAP_CODES = [
  'pharmacy',
  'appointment',
  'patient',
  'state',
  'appointmentType',
  'router',
  '',
] as const

const VALID_APPOINTMENT_ENTITY_MAP_CODES = [
  'reasonForVisit',
  'injectionName',
  'topic',
  'topicCustom',
] as const

// Can't use includes on a `Array<string> as const` since it has any string passed to
// includes is expected to be within the set of string literals, so this is a work around
const readonlyArrayIncludes = <T>(
  str: T,
  readonlyArray: ReadonlyArray<T>
): boolean => {
  return readonlyArray.includes(str)
}
export const getFieldEntityFromCode = (coding: Coding) => {
  if (!isFhirCodingEntityMap(coding)) {
    return undefined
  }

  if (readonlyArrayIncludes(coding.code, INVALID_ENTITY_MAP_CODES)) {
    throw new Error(
      `getFieldCodeFromExtension: invalid entityMapCode '${coding.code}'`
    )
  }

  if (isFhirCodingEntityPatient(coding)) {
    return {
      entity: 'patient',
      path: coding.code,
    }
  }

  if (isFhirCodingEntityAppointment(coding)) {
    if (
      readonlyArrayIncludes(coding.code, VALID_APPOINTMENT_ENTITY_MAP_CODES)
    ) {
      return {
        entity: 'appointment',
        path: coding.code,
      }
    }
    throw new Error(
      `getFieldCodeFromExtension: invalid entityMapCode '${coding.code}'`
    )
  }

  return undefined
}

export const getFieldEntityMapCodes = (
  acc: InitialData['fieldEntityMap'],
  item: FhirQuestionnaireItem
): InitialData['fieldEntityMap'] => {
  const { linkId, type, code } = item

  if (type === 'group') {
    if (item.item)
      return { ...acc, ...item.item.reduce(getFieldEntityMapCodes, acc) }
  }

  const fieldCodes =
    code?.reduce((acc, coding) => {
      const entityCode = getFieldEntityFromCode(coding)
      if (entityCode) return [...acc, entityCode]
      return acc
    }, []) ?? []

  return {
    ...acc,
    [linkId]: fieldCodes,
  }
}

interface InitialData {
  defaultValues: Record<string, JsonPrimitive | undefined>
  fallbackValues: Record<string, JsonPrimitive | undefined>
  fieldEntityMap: Record<string, { entity: string; path: string }[]>
}

export const useDefaultValues = (
  questionnaire: FhirQuestionnaire,
  state: FhirFormStateContext
): InitialData => {
  const fieldCodes = React.useMemo(
    () => questionnaire.item?.reduce(getFieldEntityMapCodes, {}) ?? {},
    []
  )

  const pipe = (
    x: ReturnType<DefaultValuesAccumulator>,
    fns: DefaultValuesAccumulator[]
  ) =>
    fns.reduce(
      (v, f) =>
        questionnaire.item?.reduce((acc, item) => {
          const value = f(acc, item)
          return value
        }, v),
      x
    )

  const defaultValues = React.useMemo(() => {
    return pipe({}, [
      getDumbInitial,
      getStateEntityValues(state, fieldCodes),
      getStateFieldValues(state),
    ])
  }, [])

  const fallbackValues = React.useMemo(() => {
    return pipe({}, [getFallbackValues])
  }, [])

  return {
    defaultValues,
    fallbackValues,
    fieldEntityMap: fieldCodes,
  }
}
