import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import MuiTextField from '@material-ui/core/TextField'

export const TextField: React.FC<{
  name: string
  label: string
  defaultValue?: string
  helperText?: string
  placeholder?: string
  inputProps?: React.ComponentProps<typeof MuiTextField>['inputProps']
  disabled?: boolean
  required?: boolean
  sanitizationRegex?: RegExp
  maxLength?: number
}> = ({
  name,
  label,
  defaultValue = '',
  helperText,
  placeholder,
  inputProps,
  disabled = false,
  required = false,
  sanitizationRegex = /[=@\\/?:;!{}[\]()%$#^"+]/g,
  maxLength,
}) => {
  const { errors, setValue } = useFormContext()
  const {
    field: { onChange: formOnChange, onBlur: formOnBlur, ...restOfFields },
  } = useController({
    name: name,
    defaultValue: defaultValue,
  })

  const onChange: React.ComponentProps<typeof MuiTextField>['onChange'] =
    React.useCallback(
      (e) => {
        e.target.value = e.target.value.replace(sanitizationRegex, '')
        formOnChange(e)
      },
      [formOnChange]
    )

  const onBlur: React.ComponentProps<typeof MuiTextField>['onBlur'] =
    React.useCallback(
      (e) => {
        const value = e.target.value
        const cleaned = value.replace(/\s+/g, ' ').trim()
        if (cleaned !== value) {
          setValue(name, cleaned, { shouldValidate: true })
        }
        formOnBlur()
      },
      [formOnBlur]
    )

  const error = disabled === true ? '' : errors[name]?.message

  return (
    <MuiTextField
      variant="outlined"
      label={label}
      error={Boolean(error)}
      helperText={error || helperText}
      placeholder={placeholder}
      inputProps={
        maxLength !== undefined ? { ...inputProps, maxLength } : inputProps
      }
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      required={!disabled && required}
      fullWidth
      {...restOfFields}
    />
  )
}
