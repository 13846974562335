import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { TextField } from '@material-ui/core'
import { Control, Controller, FieldErrors } from 'react-hook-form'

interface ControlledTextFieldProps {
  name: string
  label: string
  control: Control<Record<string, any>>
  defaultValue: unknown
  variant: 'standard' | 'filled' | 'outlined' | undefined
  rules?: object
  errors?: FieldErrors<Record<string, string>>
  renderValue?: (value: unknown) => React.ReactNode
  // All other props:
  [x: string]: any
}

export const ControlledTextField: React.FC<ControlledTextFieldProps> = ({
  name,
  label,
  control,
  defaultValue,
  variant,
  rules,
  errors,
  children,
  ...rest
}) => {
  return (
    <FormControl {...rest}>
      <Controller
        as={
          <TextField
            variant={variant}
            label={label}
            error={errors && !!errors[name]}
            helperText={errors && errors[name]?.message}
          />
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
      />
    </FormControl>
  )
}
