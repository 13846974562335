import * as React from 'react'
import R5 from 'fhir/r5'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import { useCurrentAppointment } from '~/pages/AppointmentIntake/contexts/AppointmentContext/useCurrentAppointment'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { useUpdateQuestionnaireResponseMutation } from '~/graphql/types/schemaNode.type'

type PublicCreateQuestionnaireResponses = (
  patientId: string,
  appointmentId: string
) => Promise<void>

export const useUpdateQuestionnaireResponses = (
  questionnaires: FhirQuestionnaire[]
) => {
  const { t } = useMedMeTranslation('common')
  const { appointment } = useCurrentAppointment()
  const questionnaireResponses = appointment?.questionnaireResponses

  const [updateQuestionnaireResponse] = useUpdateQuestionnaireResponseMutation({
    context: {
      clientName: EServer.NODE,
    },
  })

  const updateQuestionnaireResponses =
    React.useCallback<PublicCreateQuestionnaireResponses>(
      async (patientId) => {
        const responses = questionnaires.map<{
          questionnaireVersionId: string
          questionnaireResponse: R5.QuestionnaireResponse
        }>((questionnaire) => {
          return {
            questionnaireVersionId: questionnaire.version,
            questionnaireResponse:
              questionnaire.generateQuestionnaireResponse(),
          }
        })

        try {
          await Promise.all(
            responses.map(
              ({ questionnaireResponse, questionnaireVersionId }) => {
                const originalResponse = questionnaireResponses?.find((qr) => {
                  return qr.questionnaireVersionId === questionnaireVersionId
                })
                if (!originalResponse) {
                  console.error(
                    `Unable to find questionnaire response for questionnaire with version id ${questionnaireVersionId}`
                  )
                  throw new Error(t('error.api.INTERNAL_SERVER_ERROR'))
                }
                const questionnaireResponseId = originalResponse.id

                return updateQuestionnaireResponse({
                  variables: {
                    questionnaireResponseId,
                    fhirJson: questionnaireResponse,
                    updatedBy: patientId,
                  },
                })
              }
            )
          )
        } catch (e) {
          console.error(
            'useUpdateQuestionnaireResponses: failed to update questionnaire responses' +
              e
          )
        }
      },
      [updateQuestionnaireResponse, questionnaires, questionnaireResponses, t]
    )

  return {
    updateQuestionnaireResponses,
  }
}
