import { createFhirInputItem } from '../fhirInputItem'

export const createFhirDateItem: typeof createFhirInputItem = (json) => {
  if (json.type !== 'date')
    throw new Error('createFhirDisplayItem: json.item !== "date"')

  return {
    type: 'date',
  }
}
