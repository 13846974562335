import * as React from 'react'
import { Select, YesNoRadioField } from '~/controlledContextInputs'
import { FhirLeafComponent } from '../FhirLeafComponent'
import { createFhirExtProps } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { InputLabel } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const FhirChoiceInput: typeof FhirLeafComponent = ({
  questionnaireItem,
  isDisabled,
}) => {
  const { _type } = questionnaireItem

  const { typeVariant } = React.useMemo(
    () => createFhirExtProps(_type?.extension),
    []
  )
  const { defaultValue, options } = React.useMemo(
    () =>
      questionnaireItem?.answerOption?.reduce(
        (acc, answerOption) => {
          const option = {
            label:
              answerOption.valueCoding?.display ??
              answerOption.valueCoding?.code ??
              '',
            value: answerOption.valueCoding?.code ?? '',
          }

          const defaultValue = answerOption.initialSelected
            ? answerOption.valueCoding?.code ?? ''
            : acc.defaultValue

          return {
            options: [...acc.options, option],
            defaultValue,
          }
        },
        { defaultValue: '', options: [] }
      ) ?? { defaultValue: '', options: [] },
    []
  )
  if (typeVariant === 'radio') {
    return (
      <YesNoRadioField
        options={options}
        defaultValue={defaultValue}
        name={questionnaireItem.linkId}
        label={questionnaireItem?.text ?? ''}
        LabelComponent={InputLabel}
        disabled={isDisabled}
        required={questionnaireItem.required}
      />
    )
  }
  return (
    <Select
      name={questionnaireItem.linkId}
      label={questionnaireItem?.text ?? ''}
      defaultValue={defaultValue}
      options={options}
      disabled={isDisabled}
      required={questionnaireItem.required}
    />
  )
}
