/* eslint-disable camelcase */
import jwtDecode from 'jwt-decode'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import qs from 'qs'
import axios from 'axios'

import { getCognitoUser, getAuthDetails } from '~/auth/awsCognitoMethods'
import { isDev, isSandbox, isStaging } from '~/util/envMethods'
import { getClientId } from '~/clientIds'
import {
  getEnterpriseCode,
  isJcg,
  isBrunet,
  isJcgBrunet,
  isSdmEnterprise,
} from '~/util/enterpriseMethods'
import { reportException } from '~/tools/monitoring'
import { identityProviderClient } from './utils'

export enum TokenEnums {
  ACCESS_TOKEN = 'token',
  ID_TOKEN = 'idToken',
  REFRESH_TOKEN = 'refreshToken',
}

export enum CognitoGroupsEnums {
  PHARMACIST = 'PHARMACIST',
  PHARMACY_STAFF_READ_ONLY = 'PHARMACY_STAFF_READ_ONLY',
  ADMIN = 'ADMIN',
}

export interface AccessToken {
  device_key?: string
  'cognito:groups'?: CognitoGroupsEnums[]
  iss?: string
  client_id?: string
  event_id?: string
  token_use?: string
  scope?: string
  auth_time?: number
  exp?: number
  iat?: number
  jti?: string
  username: string
}
export interface IdToken {
  identities: {
    providerType: string
  }[]
}
export interface Tokens {
  accessToken: string
  idToken: string
  refreshToken: string
}

export const getAccessToken = () =>
  localStorage.getItem(TokenEnums.ACCESS_TOKEN) || ''

const getSafeDecodedToken = <T>(token: string): T | null => {
  try {
    return token ? jwtDecode<T>(token) : null
  } catch (error) {
    reportException(error)
    return null
  }
}

export const getIsAccessTokenActive = (token: string = getAccessToken()) => {
  const decodedToken = getSafeDecodedToken<AccessToken>(token)

  if (!decodedToken) return false

  const { exp } = decodedToken
  return exp && exp * 1000 > Date.now()
}

export const getIdToken = () => localStorage.getItem(TokenEnums.ID_TOKEN) || ''

export const getRefreshToken = () => {
  return localStorage.getItem(TokenEnums.REFRESH_TOKEN) || ''
}

export const setAccessToken = (token: string) =>
  localStorage.setItem(TokenEnums.ACCESS_TOKEN, token)

export const setIdToken = (token: string) =>
  localStorage.setItem(TokenEnums.ID_TOKEN, token)

export const setRefreshToken = (token: string) =>
  localStorage.setItem(TokenEnums.REFRESH_TOKEN, token)

export const getIsPharmacyStaffReadOnly = (jwt?: string) => {
  const token = jwt || getAccessToken()
  if (!token) return false
  return (
    jwtDecode<AccessToken>(token)['cognito:groups']?.includes(
      CognitoGroupsEnums.PHARMACY_STAFF_READ_ONLY
    ) ?? false
  )
}

export const getIsAdmin = (jwt?: string) => {
  const token = jwt || getAccessToken()
  if (!token) return false
  return (
    jwtDecode<AccessToken>(token)['cognito:groups']?.includes(
      CognitoGroupsEnums.ADMIN
    ) ?? false
  )
}

export const getIsSamlLogin = (idToken: string = getIdToken()) => {
  const decodedIdToken = getSafeDecodedToken<IdToken>(idToken)

  return decodedIdToken
    ? decodedIdToken.identities?.[0]?.providerType === 'SAML'
    : false
}

export const getUsername = (jwt?: string) => {
  const token = jwt || getAccessToken()
  if (!token) return ''
  return jwtDecode<AccessToken>(token).username || ''
}

export const getSamlRedirectHostName = () => {
  if (isJcg() && isDev) return 'consultation-dev.jeancoutu.com'
  if (isJcg()) return 'consultation.jeancoutu.com'
  if (isBrunet() && isDev) return 'consultation-dev.brunet.ca'
  if (isBrunet()) return 'consultation.brunet.ca'
  return window.location.hostname
}

export const getSamlRedirectPath = () => {
  if (isJcgBrunet()) return '/loginLanding'
  return '/selectStore'
}

type LoginFn = (
  username: string,
  password: string,
  options?: {
    onSuccess?: (tokens: Tokens, session: CognitoUserSession) => void
    onFailure?: (error: any) => void
    onError?: (error: any) => void
  }
) => void

export const login: LoginFn = async (username, password, options) => {
  const { onSuccess, onFailure, onError } = options ?? {}
  try {
    const cognitoUser = getCognitoUser(username)
    const authDetails = getAuthDetails(username, password)
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session) => {
        const accessToken = session.getAccessToken().getJwtToken()
        const idToken = session.getIdToken().getJwtToken()
        const refreshToken = session.getRefreshToken().getToken()

        setAccessToken(accessToken)
        setIdToken(idToken)
        setRefreshToken(refreshToken)

        onSuccess?.({ accessToken, idToken, refreshToken }, session)
      },
      onFailure: (error) => {
        console.error(error)
        onFailure?.(error)
      },
    })
  } catch (error) {
    console.error(error)
    onError?.(error)
  }
}

export const getAuthHost = () => {
  if (isDev) return 'https://dev-auth.medmeapp.com'
  if (isSandbox) return 'https://sandbox-auth.medmeapp.com'
  if (isStaging) return 'https://auth.staging.medmeapp.com'
  return 'https://auth.medmeapp.com'
}

export const getSamlLogoutRedirectHostName = () => {
  if (isSdmEnterprise() && isDev) return 'lclsdmdev.medmeapp.com'
  if (isSdmEnterprise() && isSandbox) return 'lclsdmsandbox.medmeapp.com'
  if (isSdmEnterprise() && isStaging) return 'lclsdm.staging.medmeapp.com'
  if (isSdmEnterprise()) return 'lclsdm.medmeapp.com'
  if (isJcgBrunet() && isDev) return 'consultation-dev.centrerx.com'
  if (isJcgBrunet()) return 'consultation.centrerx.com'
  return getSamlRedirectHostName()
}

const getSamlLoginRedirectUrl = (enterprise?: string) => {
  // Return the redirectUri from the identity provider client only if it is initialized
  if (identityProviderClient.isInitialized) {
    return identityProviderClient.getIdpIntegrationByEnterpriseCode(
      enterprise ?? getEnterpriseCode()
    ).redirectUri
  }

  return `https://${getSamlRedirectHostName()}${getSamlRedirectPath()}` // Default to the redirect host name
}

const getSamlLogoutRedirectUrl = (enterprise?: string) => {
  // Return the logoutUri from the identity provider client only if it is initialized
  if (identityProviderClient.isInitialized) {
    return (
      identityProviderClient.getIdpIntegrationByEnterpriseCode(
        enterprise ?? getEnterpriseCode()
      ).logoutUri ?? ''
    )
  }

  return `https://${getSamlLogoutRedirectHostName()}` // Default to the logout redirect host name
}

export const getAuthLogoutUrl = () => {
  const host = getAuthHost()
  const clientId = getClientId(getSamlRedirectHostName())
  const redirectUrl = getSamlLogoutRedirectUrl()

  return `${host}/logout?client_id=${clientId}&logout_uri=${redirectUrl}`
}

export const getSamlLoginUrl = () => {
  const host = getAuthHost()
  const identityProvider = getEnterpriseCode()?.toLowerCase()
  const redirectUrl = getSamlLoginRedirectUrl()
  const clientId = getClientId()
  const responseType = 'CODE'
  const scope = 'email+profile+openid+aws.cognito.signin.user.admin'

  return `${host}/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${redirectUrl}&response_type=${responseType}&client_id=${clientId}&scope=${scope}`
}

enum AuthGrantTypeEnums {
  AUTHORIZATION_CODE = 'authorization_code',
  REFRESH_TOKEN = 'refresh_token',
}

type AuthGrant =
  | {
      code: string
      grant_type: AuthGrantTypeEnums.AUTHORIZATION_CODE
    }
  | {
      refresh_token: string
      grant_type: AuthGrantTypeEnums.REFRESH_TOKEN
    }

const getAuthTokensWithAxios = async (authGrant: AuthGrant) => {
  const { data } = await axios({
    method: 'POST',
    url: `${getAuthHost()}/oauth2/token`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({
      client_id: getClientId(getSamlRedirectHostName()),
      redirect_uri: `https://${getSamlRedirectHostName()}${getSamlRedirectPath()}`,
      scope: 'profile+email+openid',
      ...authGrant,
    }),
  })
  const accessToken = data.access_token
  const idToken = data.id_token
  const refreshToken = data.refresh_token

  setAccessToken(accessToken)
  setIdToken(idToken)

  // The token endpoint returns refresh_token only when the grant_type is authorization_code.
  // https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
  if (refreshToken) {
    setRefreshToken(refreshToken)
  }

  return {
    accessToken,
    idToken,
    refreshToken,
  }
}

export const loginWithAxios = async (code: string): Promise<Tokens> => {
  return getAuthTokensWithAxios({
    code,
    grant_type: AuthGrantTypeEnums.AUTHORIZATION_CODE,
  })
}

export const refreshWithAxios = async (
  refreshToken: string = getRefreshToken()
): Promise<Tokens> => {
  return getAuthTokensWithAxios({
    refresh_token: refreshToken,
    grant_type: AuthGrantTypeEnums.REFRESH_TOKEN,
  })
}

export const revokeRefreshTokenForEmailPasswordLogin = async (
  refreshToken: string
) => {
  await axios({
    method: 'POST',
    url: new URL('/oauth2/revoke', getAuthHost()).toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({
      token: refreshToken,
      client_id: process.env.COGNITO_APP_CLIENT_ID,
    }),
  })
}
