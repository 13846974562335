import { Event } from 'json-rules-engine'

export const validationErrorEventType = 'FormFieldValidationError'
export interface ValidationError extends Event {
  type: typeof validationErrorEventType
  params: {
    fieldName: string
    message: string
  }
}

export const createValidationError = (
  params: ValidationError['params']
): ValidationError => {
  return {
    type: validationErrorEventType,
    params: {
      fieldName: params.fieldName,
      message: params.message,
    },
  }
}

export const isValidationError = (event: Event): event is ValidationError =>
  event.type === validationErrorEventType

export const isRequiredFieldValidationError = (
  event: Event
): event is ValidationError =>
  event.type === validationErrorEventType &&
  event.params?.message === 'Required'
