import styled from 'styled-components'

export const CompletedLayout = styled.div`
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  line-height: 1.35;
`

export const Text = styled.div`
  font-size: 1.25em;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  font-weight: 600;
  line-height: 1.35;
  text-align: center;
`

export const AppointmentText = styled.div`
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  font-weight: 400;
  line-height: 1.35;
  text-align: center;
  margin-top: 8px;
`
