import { ELanguageCode } from '~/graphql/types/schema.type'

export const isLanguageCode = (data: any): data is ELanguageCode => {
  return data && data in ELanguageCode
}

export const createAppointmentLanguageCode = (
  languageCode?: undefined | ELanguageCode | null
) => {
  if (isLanguageCode(languageCode)) {
    return languageCode
  }

  return ELanguageCode.EN
}
