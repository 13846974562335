import * as React from 'react'

// styles
import { Layout, Title, BodyText, ErrorButton } from './reserveDateTime.styles'

export const ReserveTimeslotError: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const layout = React.useRef<HTMLInputElement>(null)
  React.useEffect(() => {
    if (layout.current) layout.current.focus()
  }, [layout])

  const title = 'Choose another time'
  const description =
    'The time you have selected has just been booked by someone else.\n\nPlease choose another time.'
  const buttonText = 'Select Time'

  return (
    <Layout ref={layout} tabIndex={0}>
      <Title>{title}</Title>

      <BodyText>{description}</BodyText>

      <ErrorButton onClick={onClose} tabIndex={0}>
        {buttonText}
      </ErrorButton>
    </Layout>
  )
}
