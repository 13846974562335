import styled from 'styled-components'
import {
  Body,
  Heading1,
  Heading2,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { red } from '~/sharedStyles/colorPalette'

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
  gap: 1.5rem;
  width: 100%;
`

export const SectionBodySemiBold = styled(Body)`
  font-weight: 600;
  color: gray;
`

export const SectionBodyContainer = styled.div`
  margin-bottom: 1rem;
`

export const ConsentContainer = styled.ul`
  list-style: disc;
  padding-left: 1rem;
  gap: 0.5rem;
`

export const ConsentBullet = styled.li`
  margin-bottom: 0.5rem;
`

export const SectionBodyBold = styled(Body)`
  font-weight: 700;
`

export const SectionTitle = styled(Heading1)`
  margin-bottom: 1rem;
`

export const CardTitle = styled(Heading2)`
  font-weight: 700;
  margin-bottom: 1.5rem;
`

export const ValidationText = styled(Body)`
  color: ${red};
`
