import { ApolloError, ServerError } from '@apollo/client'
import { GraphQLError } from 'graphql'

export const isApolloError = (e: Error | ApolloError): e is ApolloError =>
  e.name === 'ApolloError'
export const isGqlError = (e: Error | GraphQLError): e is GraphQLError =>
  e.name === 'GraphQLError'
export const isServerError = (e: Error | ServerError): e is ServerError =>
  e.name === 'ServerError'

/**
 * Parses an Error that
 */
export const resolveBestError = (e: Error | undefined) => {
  if (!e) return new Error()

  if (isApolloError(e)) {
    if (e.clientErrors && e.clientErrors.length) return e.clientErrors[0]
    if (e.graphQLErrors && e.graphQLErrors.length) return e.graphQLErrors[0]
    if (e.networkError) return e.networkError
  }

  return e
}
