import React from 'react'
import { Controller } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { RadioGroupContainer, RadioSubLabel } from './radioGroup.styles'
import { RadioInput, InputLabel } from '../../patientRegistration.styles'

const direction = {
  top: 'Top',
  end: 'Right',
  bottom: 'Bottom',
  start: 'Left',
}

const getDirection = (placement) => {
  if (!placement) return 'Right'
  return direction[placement]
}

const RadioGroup = ({
  className,
  error,
  helperText,
  styles = {},
  labelStyles = { color: '', placement: 'end', margin: 0 },
  options = [{ value: '', label: '', subLabel: '' }],
  ...rest
}) => (
  <Controller
    as={
      <RadioGroupContainer className={className} {...styles}>
        {options.map(({ value, label, subLabel }, i) => (
          <div key={i}>
            <FormControlLabel
              value={value}
              control={
                <RadioInput
                  style={{
                    padding: 0,
                    [`margin${getDirection(labelStyles.placement)}`]: '7px',
                  }}
                  color="default"
                />
              }
              label={<InputLabel color={labelStyles.color}>{label}</InputLabel>}
              labelPlacement={labelStyles.placement}
              style={{ margin: labelStyles.margin, flex: 0 }}
            />
            <RadioSubLabel>{subLabel}</RadioSubLabel>
          </div>
        ))}
        {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </RadioGroupContainer>
    }
    {...rest}
  />
)

export default RadioGroup
