import React from 'react'
import {
  ButtonProps,
  Box,
  DialogProps as MuiDialogProps,
  IconButton,
} from '@material-ui/core'
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledButton,
  StyledLoader,
} from './Dialog.styles'
import CloseIcon from '@material-ui/icons/Close'
import { theme } from '~/util/mui.theme'

const DEFAULT_Z_INDEX = 9999999

export enum Reason {
  EscapeKeyDown = 'escapeKeyDown',
  BackdropClick = 'backdropClick',
  CloseButtonClick = 'closeButtonClick',
}

function isReason(reason?: string): reason is Reason {
  return Object.values(Reason).includes(reason as Reason)
}

export interface DialogButton {
  label: string
  color?: ButtonProps['color'] | 'error'
  variant?: ButtonProps['variant']
  onClick: () => void | Promise<void>
  hasLoadingState?: boolean
}
export interface DialogProps {
  title: React.ReactNode
  content?: React.ReactNode
  onClose: (reason?: Reason) => void
  isOpen: boolean
  zIndex?: number
  withCloseButton?: boolean
  withDividers?: boolean
  padding?: number
  width?: MuiDialogProps['maxWidth']
  px?: string
  py?: string
  buttons?: DialogButton[]
  isLoading?: boolean
  isFullWidth?: boolean
}

/**
 * A reusable dialog component
 *
 * @example - Confirmation Dialog
 *
 * import { Typography } from '@material-ui/core'
 * import React from 'react'
 * import { Dialog, DialogButton } from '~/componentsTs/Dialog'
 * import useDisclosure from '~/hooks/useDisclosure'

 * export const ConfirmationDialogDemo = () => {
 *   const [opened, { open, close }] = useDisclosure()

 *   const buttons: DialogButton[] = [
 *     {
 *       label: 'ok',
 *       color: 'primary',
 *       variant: 'contained',
 *       onClick: () => {
 *         alert('Cancelled')
 *         close()
 *       },
 *     },
 *   ]

 *   return (
 *     <div>
 *       <button onClick={open}>Open Confirmation Dialog</button>
 *       <Dialog
 *          title={<Typography variant="h6">Are you sure?</Typography>}
 *          content={
 *            <div>
 *              <p>
 *                This action is irreversible and will remove all data from the
 *                system.
 *              </p>
 *              <br />
 *              <p>Do you want to continue?</p>
 *            </div>
 *           }
 *         onClose={close}
 *         isOpen={opened}
 *         buttons={buttons}
 *       />
 *     </div>
 *   )
 * }
 *
 */
export const Dialog = ({
  title,
  content,
  onClose,
  isOpen,
  zIndex = DEFAULT_Z_INDEX,
  withCloseButton = false,
  withDividers = false,
  padding = 12,
  width = 'md',
  px = '24px',
  py = '8px',
  buttons,
  isLoading = false,
  isFullWidth = true,
}: DialogProps) => {
  const onCloseHandler = (
    _event: React.MouseEvent<HTMLButtonElement>,
    reason?: string
  ) => {
    // prevent closing the dialog if it's loading
    if (isLoading) {
      return
    }

    onClose(isReason(reason) ? reason : Reason.CloseButtonClick)
  }

  return (
    <StyledDialog
      open={isOpen}
      onClose={onCloseHandler}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      zindex={zIndex}
      padding={padding}
      maxWidth={width}
      fullWidth={isFullWidth}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {typeof title === 'string' ? (
          <StyledDialogTitle id="confirmation-dialog-title" px={px} py={py}>
            {title}
          </StyledDialogTitle>
        ) : (
          <Box px={px} py={py}>
            {title}
          </Box>
        )}

        {withCloseButton ? (
          <IconButton aria-label="close" onClick={onCloseHandler}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>

      {content ? (
        <StyledDialogContent dividers={withDividers} px={px} py={py}>
          {content}
        </StyledDialogContent>
      ) : null}

      <StyledDialogActions px={px} py={py}>
        {buttons &&
          buttons.map((button) => (
            <StyledButton
              key={button.label}
              id={button.label}
              color={
                button.color && button.color !== 'error'
                  ? button.color
                  : 'default'
              }
              style={(() => {
                if (button.color === 'error') {
                  switch (button.variant) {
                    case 'contained':
                      return {
                        backgroundColor: theme.palette.error.main,
                        color: 'white',
                      }
                    case 'outlined':
                      return {
                        color: theme.palette.error.main,
                        borderColor: theme.palette.error.main,
                      }
                    default:
                      return undefined
                  }
                }
                return undefined
              })()}
              variant={button.variant}
              onClick={button.onClick}
              disabled={isLoading}
              theme={button.color === 'error' ? 'error' : 'default'}
            >
              {isLoading && button.hasLoadingState ? (
                <StyledLoader size={20} buttonvariant={button.variant} />
              ) : (
                button.label
              )}
            </StyledButton>
          ))}
      </StyledDialogActions>
    </StyledDialog>
  )
}
