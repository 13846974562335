import { JsonPrimitive } from '~/lib/json'
import { ObjectPropTypeFns } from '~/pages/AppointmentIntake/lib'
import { isObject, isPropertySafeValueConversionFunction } from './helpers'

/**
 * Deep searches `entity` for the property that matches `jsonPath` and returns a copy with the updated value.
 *
 * @param entity Object to be traversed.
 * @param jsonPath Simple jsonPath for `entity` property. Does not support Array traversal.
 * @param value New value to be set.
 * @param propFns Object with the same keys as `entity` with type-safe cast functions as their values
 * @returns copy of `entity` with the updated value
 */
const setEntityPropertyValue = <T extends Record<string, unknown>>(
  entity: T,
  jsonPath: string,
  value: string | number | boolean | null | undefined,
  propFns: ObjectPropTypeFns<T>
): T => {
  if (value === undefined) {
    return entity
  }

  const [name, ...restOfJsonPath] = jsonPath.split('.')
  const isLastKeyInJsonPath = !restOfJsonPath || restOfJsonPath.length === 0
  const entityPropValue = entity[name]
  const propFnsValue = propFns[name]

  const isPropTypeFnObject = (
    maybePropTypeFnObj: unknown
  ): maybePropTypeFnObj is ObjectPropTypeFns<Record<string, unknown>> => {
    return isObject(maybePropTypeFnObj)
  }

  const isSubEntity = (
    maybeSubEntity: unknown
  ): maybeSubEntity is Record<string, unknown> => {
    return isObject(maybeSubEntity)
  }

  if (
    !isLastKeyInJsonPath &&
    isSubEntity(entityPropValue) &&
    isPropTypeFnObject(propFnsValue)
  ) {
    return {
      ...entity,
      [name]: setEntityPropertyValue(
        entityPropValue,
        restOfJsonPath.join('.'),
        value,
        propFnsValue
      ),
    }
    // Assumes that if the entity value is undefined, it's not actually a field.
  } else if (entityPropValue === undefined) {
    return entity
  } else if (
    isLastKeyInJsonPath &&
    isObject(propFns) &&
    isPropertySafeValueConversionFunction(propFnsValue)
  ) {
    return { ...entity, [name]: propFnsValue(value) }
  }

  throw new Error('Not sure how you got here')
}

/**
 * Updates `entity` property values with values from `data`
 *
 * @param data Object with values to update the entity with
 * @param fieldToStateMap Object that maps the key in `data` to a list of entity properties.
 * @param entity Entity to update
 * @param entityName Entity name
 * @param entityPropFns Object with the same keys as `entity` with type-safe cast functions as their values
 * @returns `entity` with updated values
 */
export const updateEntity = <T extends Record<string, unknown>>(
  data: Record<string, JsonPrimitive | undefined>,
  fieldToStateMap: Record<
    string,
    {
      entity: 'patient' | 'appointment'
      path: string
    }[]
  >,
  entity: T,
  entityName: 'patient' | 'appointment',
  entityPropFns: ObjectPropTypeFns<T>
): T => {
  const updatedEntity = Object.keys(fieldToStateMap).reduce((acc, field) => {
    return fieldToStateMap[field].reduce(
      (updatedAcc, { entity: fieldEntityName, path }) => {
        if (entityName === fieldEntityName) {
          const value = data[field]
          if (value !== undefined) {
            return setEntityPropertyValue(
              updatedAcc,
              path,
              value,
              entityPropFns
            )
          }
        }
        return updatedAcc
      },
      acc
    )
  }, entity)
  return updatedEntity
}
