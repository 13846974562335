import { useCallback, useEffect, useMemo, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useMedMeTranslation } from '../useMedMeTranslation'

import { reportException } from '~/tools/monitoring'
import {
  DEFAULT_LANGUAGES,
  LANGUAGE_OVERRIDE_LOCAL_STORAGE_KEY,
  LANGUAGE_QUERY_PARAM,
  LanguageLocale,
  getLanguageCodeFromLocale,
  getLocaleFromLanguageCode,
} from '~/util/languageMethods'
import { useApolloClient } from '@apollo/client'

export type ChangeLanguageOptions = {
  setQueryParams?: boolean
  setLocalStorageOverride?: boolean
  refetchQueries?: string[] | 'active' | 'all'
}

export const useLanguage = (
  supportedLanguages = DEFAULT_LANGUAGES,
  options: ChangeLanguageOptions = {}
) => {
  const { i18n } = useMedMeTranslation()
  const history = useHistory()
  const { search } = useLocation()

  const client = useApolloClient()

  const [language, setLanguage] = useState<LanguageLocale>(
    getLocaleFromLanguageCode(i18n.language)
  )

  const memoizedOptions = useMemo(
    () => ({
      refetchQueries: options.refetchQueries,
      setLocalStorageOverride: options.setLocalStorageOverride,
      setQueryParams: options.setQueryParams,
    }),
    [
      options.refetchQueries,
      options.setLocalStorageOverride,
      options.setQueryParams,
    ]
  )

  const changeLanguage = useCallback(
    (
      selectedLanguage: LanguageLocale,
      changeLanguageOptions: ChangeLanguageOptions & {
        supportedLanguages?: LanguageLocale[]
      } = {}
    ) => {
      const changeLanguageAsync = async () => {
        const combinedSupportedLanguages = [
          ...supportedLanguages,
          ...(changeLanguageOptions.supportedLanguages
            ? changeLanguageOptions.supportedLanguages
            : []),
        ]
        if (
          !combinedSupportedLanguages.some(
            (supportedLanguage) => supportedLanguage === selectedLanguage
          )
        ) {
          const errorMessage = `Unsupported language locale: ${selectedLanguage}`
          reportException(new Error(errorMessage), {
            extra: {
              selectedLanguage,
              combinedSupportedLanguages,
            },
          })

          return Promise.reject(errorMessage)
        }

        const combinedOptions = {
          ...memoizedOptions,
          ...changeLanguageOptions,
        }

        const code = getLanguageCodeFromLocale(selectedLanguage)

        const searchParams = new URLSearchParams(search)
        const languageFromQueryParam = searchParams.get(LANGUAGE_QUERY_PARAM)

        if (combinedOptions.setLocalStorageOverride) {
          localStorage.setItem(
            LANGUAGE_OVERRIDE_LOCAL_STORAGE_KEY,
            selectedLanguage
          )
        }

        if (
          combinedOptions.setQueryParams &&
          selectedLanguage !== languageFromQueryParam
        ) {
          searchParams.set(LANGUAGE_QUERY_PARAM, selectedLanguage)
          history.replace({ search: searchParams.toString() })
        }

        setLanguage(selectedLanguage)

        const t = await i18n.changeLanguage(code)

        if (typeof combinedOptions.refetchQueries !== 'undefined') {
          await client.refetchQueries({
            include: combinedOptions.refetchQueries,
          })
        }

        return t
      }
      return changeLanguageAsync()
    },
    [client, history, i18n, memoizedOptions, search, supportedLanguages]
  )

  useEffect(() => {
    const locale = getLocaleFromLanguageCode(i18n.language)
    if (locale !== language) {
      setLanguage(locale)
    }
  }, [i18n.language, language])

  return { language, supportedLanguages, changeLanguage }
}
