import React from 'react'
import { NumberField } from '~/controlledContextInputs'
import { FhirLeafComponent } from '../FhirLeafComponent'

export const FhirIntegerInput: typeof FhirLeafComponent = ({
  questionnaireItem,
  isDisabled,
}) => {
  return (
    <NumberField
      name={questionnaireItem.linkId}
      label={questionnaireItem.getText()}
      disabled={isDisabled}
      required={questionnaireItem.required}
    />
  )
}
