import { Extension } from 'fhir/r5'
import { ExtensionValidationConditionList } from '../validation.type'
import { isExtensionValidationCondition } from './isExtensionValidationCondition'
import { isValidExtensionList } from '../../lib/isValidExtensionList'

export const isExtensionValidationConditionList = (
  extension: Extension
): extension is ExtensionValidationConditionList => {
  return (
    extension.url === 'conditionList' &&
    isValidExtensionList(extension, isExtensionValidationCondition) &&
    Object.keys(extension).length === 2
  )
}
