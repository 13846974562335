import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import React, { PropsWithChildren } from 'react'
import { FloatingCloseButton } from './modal.styles'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

type ModalCloseReason =
  | Parameters<NonNullable<DialogProps['onClose']>>[1]
  | 'closeButtonClick'
type ModalProps = PropsWithChildren<
  {
    className?: string
    closeOnBackdropClick?: boolean
    isOpen: boolean
    onClose?(reason: ModalCloseReason): void
    showCloseModalButton?: boolean
  } & Omit<DialogProps, 'open' | 'onClose'>
>

const Modal = ({
  children,
  className = undefined,
  closeOnBackdropClick = false,
  isOpen,
  onClose,
  showCloseModalButton = false,
  ...muiDialogProps
}: ModalProps) => {
  const { t } = useMedMeTranslation()

  const onCloseDialog = (reason: ModalCloseReason) => {
    if (reason === 'backdropClick') {
      if (closeOnBackdropClick) {
        onClose?.(reason)
      }
    } else {
      onClose?.(reason)
    }
  }

  return (
    <Dialog
      className={className}
      maxWidth={false}
      disableEnforceFocus={true}
      {...muiDialogProps}
      open={isOpen}
      onClose={(e, reason) => onCloseDialog(reason)}
    >
      {showCloseModalButton ? (
        <FloatingCloseButton
          aria-label={t('close')}
          onClick={() => onCloseDialog('closeButtonClick')}
        >
          <CloseIcon />
        </FloatingCloseButton>
      ) : null}
      {children}
    </Dialog>
  )
}

export default Modal
