import { AppointmentActivityDetailsBadge as AADB } from '~/graphql/types/schemaNode.type'
import { AppointmentActivityDetailsBadge } from '../AppointmentActivityDetailsBadge'
import { DeepPartial } from '../../../pages/AppointmentIntake/lib/types'

export const createAppointmentActivityDetailsBadge = (
  appointmentActivityDetailsBadge?: DeepPartial<AADB>
): AppointmentActivityDetailsBadge => {
  const res = {
    description: appointmentActivityDetailsBadge?.description || '',
    label: appointmentActivityDetailsBadge?.label || '',
    style: appointmentActivityDetailsBadge?.style || '',
    visible: appointmentActivityDetailsBadge?.visible || false,
  }
  return res
}
