import { useForm } from 'react-hook-form'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import * as yup from 'yup'
import useYupValidationResolver from '~/hooks/yupValidationResolverHook'
import { useCallback } from 'react'
import { FormStepProps } from '../../types'
import { useSubmitWaitlistIntake } from './useSubmitWaitlistIntake'
import { useSendWebviewEvent } from '../../../hooks'

export const useReviewFormStep = ({ path, onComplete }: FormStepProps) => {
  const { t } = useMedMeTranslation()
  const { sendWebviewEvent } = useSendWebviewEvent()

  const schema = yup.object().shape({
    reviewAndConfirm: yup.bool().oneOf([true], t('formRequired')),
  })

  const resolver = useYupValidationResolver(schema)

  const formMethods = useForm({
    defaultValues: { reviewAndConfirm: false },
    resolver: resolver,
    shouldUnregister: false,
  })

  const { handleSubmit } = formMethods

  const { submit, loading, error } = useSubmitWaitlistIntake()

  const handleFormSubmit = useCallback(async () => {
    const patient = await submit()
    const appointmentId = patient?.appointments?.[0]?.id
    sendWebviewEvent(appointmentId)
    onComplete({ path })
  }, [onComplete, path, sendWebviewEvent, submit])

  return {
    loading,
    error,
    formMethods,
    handleSubmit: handleSubmit(handleFormSubmit),
  }
}
