import moment from 'moment'
import dayjs from 'dayjs'

export * from './types'
export * from './initializeDayJs'
export * from './getLocalizedDateFormat'
export * from './getLocalizedTimeFormat'

const TWELVE_HOUR_TIME = 'MMM D, h:mmA'
const TWENTY_FOUR_HOUR_TIME = 'D MMM HH:mm'

export const NEVER = moment.utc('9999-12-31T00:00:00Z')

export const getDateWithTime = ({
  timestamp,
  timezone,
  languageCode,
}: {
  timestamp: string
  timezone: string
  languageCode: string
}) => {
  const date = moment.utc(timestamp).tz(timezone)

  return languageCode === 'FR'
    ? date.format(TWENTY_FOUR_HOUR_TIME)
    : date.format(TWELVE_HOUR_TIME)
}

export const getDayjsLocaleTimeFormat = () => {
  return dayjs.locale() === 'fr-ca' ? 'HH:mm' : 'h:mmA'
}
