import { combineReducers } from 'redux'
import pharmacist from './pharmacist'
import medsCheckDocuments from './medscheckDocuments'
import pharmacy from './pharmacy'
import patientList from './patientList'
import pharmacistLayout from './pharmacistLayout'
import calendar from './calendar'
import dayCalendar from './dayCalendar'
import dashboard from './dashboard'
import appointmentList from './appointmentList'
import consultationList from './consultationList'
import generalWaitlist from './generalWaitlist'
import covidVaccineWaitlist from './covidVaccineWaitlist'
import covidVaccineDoseTwoWaitlist from './covidVaccineDoseTwoWaitlist'
import covidVaccineDoseThreeWaitlist from './covidVaccineDoseThreeWaitlist'
import fluShotWaitlist from './fluShotWaitlist'
import formList from './formList'
import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'

const dashboardPersistConfig = {
  key: 'dashboard',
  storage,
  whitelist: ['walkInAppointmentsFilters'],
}

const appReducer = combineReducers({
  pharmacist,
  pharmacy,
  patientList,
  medsCheckDocuments,
  pharmacistLayout,
  calendar,
  dayCalendar,
  dashboard: persistReducer(dashboardPersistConfig, dashboard),
  appointmentList,
  consultationList,
  generalWaitlist,
  covidVaccineWaitlist,
  covidVaccineDoseTwoWaitlist,
  covidVaccineDoseThreeWaitlist,
  fluShotWaitlist,
  formList,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT')
    // TODO: persist pharmacy/pharmacist related settings like walkin appointment filters in the database
    return appReducer(
      {
        dashboard: {
          walkInAppointmentsFilters: state.dashboard.walkInAppointmentsFilters,
        },
      },
      action
    )
  return appReducer(state, action)
}

// redux
const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['patientList', 'formList', 'consultationList'],
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = createStore(persistedReducer, composeWithDevTools())
export const persistor = persistStore(store)

export default rootReducer
