import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useConsent } from './hooks'
import { UpdateConsentProps } from '~/pages/AppointmentIntake/contexts/BookingContext/useBookingContext/useUpdateConsent/useUpdateConsent'
import { ControlledSelect } from '~/util/minorAilments/reactHookFormSelect'
import { ControlledTextField } from '~/util/minorAilments/reactHookFormTextField'
import { BookingDetailsWrapper } from '../../../components/BookingDetails'
import { NavigationFooter } from '~/pages/AppointmentIntake/components'
import {
  Card,
  FormStepContentLayout,
  Heading1,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { Form, SectionBodyBold, ValidationText } from './Consent.style'
import { MenuItem } from '@material-ui/core'
import { white } from '~/sharedStyles/colorPalette'
import { ConsentVerbiage } from './ConsentVerbiage/ConsentVerbiage'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { getAaiTitleAndSubtitle } from '~/lib/appointmentActivity'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

type ConsentPageProps = {
  onSubmit: (data: UpdateConsentProps) => unknown
  onBack: null | (() => void)
}

export const Consent: React.FC<ConsentPageProps> = ({ onBack, onSubmit }) => {
  const { t } = useMedMeTranslation('patient')
  const { consentGivers, defaultValues } = useConsent()
  const {
    state: {
      patient: { firstName, lastName },
      appointmentActivity,
    },
  } = useBookingContext()
  const methods = useForm<UpdateConsentProps>({
    defaultValues: defaultValues,
    shouldUnregister: false,
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = methods
  const onSubmitHandler = handleSubmit(onSubmit)
  const consentCollectedBy = methods.watch('consentCollectedBy')

  const cleanName = (fullName: string) => {
    return fullName.replace(/\s+/g, ' ').trim()
  }

  const validateName = (input: string) => {
    return (
      cleanName(input).toLowerCase() ===
      firstName.toLowerCase() + ' ' + lastName.toLowerCase()
    )
  }

  const aaiTitleAndSubtitle = getAaiTitleAndSubtitle(appointmentActivity)

  return (
    <BookingDetailsWrapper>
      <FormStepContentLayout>
        <Form onSubmit={onSubmitHandler} data-testid="minorAilmentSelectorForm">
          <Heading1>{aaiTitleAndSubtitle}</Heading1>
          <Card>
            <ConsentVerbiage consentCollected={false} />
            <SectionBodyBold>{t('consentBy')}:</SectionBodyBold>
            <ControlledSelect
              id="consentCollectedBy"
              data-testid="consentCollectedBy"
              name="consentCollectedBy"
              label={t('consentCollectedBy')}
              register={register('consentCollectedBy')}
              control={control}
              defaultValue={defaultValues.consentCollectedBy}
              variant="outlined"
              margin="normal"
              style={{ backgroundColor: white }}
              rules={{
                required: t('patientValidation.required'),
              }}
            >
              {consentGivers.map((consentCollectedBy, index) => {
                return (
                  <MenuItem value={consentCollectedBy.value} key={index}>
                    <div>{consentCollectedBy.label}</div>
                  </MenuItem>
                )
              })}
            </ControlledSelect>
            {errors?.consentCollectedBy && (
              <ValidationText>
                {errors.consentCollectedBy?.message}
              </ValidationText>
            )}

            <ControlledTextField
              id="consentGiverName"
              data-testid="consentGiverName"
              name="consentGiverName"
              label={t('patientLabel')}
              register={register('consentGiverName')}
              rules={{
                required: t('patientValidation.required'),
                validate: (input: string) => {
                  if (consentCollectedBy === 'patient') {
                    return (
                      validateName(input) ||
                      t('patientValidation.incorrectConsentProviderName')
                    )
                  }
                },
              }}
              control={control}
              defaultValue={defaultValues.consentGiverName}
              margin="normal"
              variant="outlined"
              style={{ backgroundColor: white }}
            />
            {errors?.consentGiverName && (
              <ValidationText>
                {errors.consentGiverName?.message}
              </ValidationText>
            )}
          </Card>
          <NavigationFooter
            onBack={onBack}
            nextButtonLabel={t('continueButtonLabel', {
              ns: 'common',
            })}
          />
        </Form>
      </FormStepContentLayout>
    </BookingDetailsWrapper>
  )
}
