import styled from 'styled-components'
import { Body, Button } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const EmptyCalendarOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  background-color: rgba(0, 0, 0, 0.2);
`

export const EmptyCalendarContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 3px;

  & > ${Button} {
    width: fit-content;
    padding: 0.25rem 0.5rem;
  }
`

export const Text = styled(Body)`
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: black;
  font-weight: 500;
`

export const ActionButton = styled(Button)`
  width: fit-content;
  padding: 0.25rem 0.5rem;
`
