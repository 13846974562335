import * as React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import {
  BookAppointment,
  NotAvailable,
} from './AppointmentActivityCardSubmitButton.styles'

export const AppointmentActivityCardSubmitButton: React.FC<{
  onClick: () => void
  isAvailable: boolean
  isLegacy?: boolean
}> = ({ onClick, isAvailable, isLegacy }) => {
  const { t } = useMedMeTranslation('patient')

  const verbiage = {
    submitButtonText: isLegacy
      ? t('continue', { ns: 'common' }).toLocaleUpperCase()
      : t('serviceSelection.addService'),
    notAvailableText: t('aai.currentlyUnavailable'),
  }

  if (isAvailable)
    return (
      <BookAppointment
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
        type="submit"
        data-cy="bookAppointment"
      >
        {verbiage.submitButtonText}
      </BookAppointment>
    )
  return (
    <NotAvailable
      onClick={(e: any) => {
        e.stopPropagation()
      }}
    >
      {verbiage.notAvailableText}
    </NotAvailable>
  )
}
