import { EAppointmentTypeMethod } from '~/graphql/types/schema.type'

export const isAppointmentMethod = (
  data: any
): data is EAppointmentTypeMethod => {
  return data && data in EAppointmentTypeMethod
}

export const createAppointmentMethod = (
  appointmentMethod?: string | EAppointmentTypeMethod | null
) => {
  if (isAppointmentMethod(appointmentMethod)) {
    return appointmentMethod
  }

  return EAppointmentTypeMethod.ONSITE
}

export type AppointmentMethod = ReturnType<typeof createAppointmentMethod>
