import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Minor Ailment Eligibility Screening ',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const completingQuestions = createFhirQuestionnaireItem({
    text: "Please answer the questions below to confirm you're eligible to be seen by a pharmacist. If completing on behalf of someone else, answer from their perspective.",
    linkId: 'completingQuestions',
    type: 'display',
  })

  const redMessage = createFhirQuestionnaireItem({
    text: '_If you have any reason to believe you may require urgent medical attention, please call 9-1-1 or go to the nearest hospital emergency room._',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const healthCard = createFhirQuestionnaireItem({
    text: 'This service is free for eligible Ontario Health Card holders. A professional fee may apply if you do not have a valid card. \n\n&nbsp; \n\n',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const questionnaireHeading = createFhirQuestionnaireItem({
    linkId: 'questionnaireHeading',
    text: 'Screening Questions',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const olderThanOne = createFhirQuestionnaireItem({
    text: 'Are you booking for someone that is 1 year old or younger?',
    linkId: 'olderThanOne',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    required: true,
  })

  const olderThan65 = createFhirQuestionnaireItem({
    text: 'Are you booking for someone over the age of 65 years old?',
    linkId: 'olderThan65',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    required: true,
    enableWhen: [
      {
        question: 'olderThanOne',
        operator: '=',
        answerString: 'no',
      },
    ],
    enableBehavior: 'all',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const medicationHistory = createFhirQuestionnaireItem({
    text: 'Have you used antibiotics or inhaled corticosteroids in the last 2 weeks?',
    linkId: 'medicationHistory',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    required: true,
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Oral thrush is unlikely if you are between the ages of 1-65 years old without recent antibiotic or inhaled corticosteroid use, please seek further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    enableWhen: [
      {
        question: 'olderThan65',
        operator: '=',
        answerString: 'no',
      },
    ],
    enableBehavior: 'all',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const experiencingSymptoms = createFhirQuestionnaireItem({
    text: `Are you experiencing any of the following symptoms?
- Painful swallowing
- Recent burn to the mouth area 
- Lesions on areas of the body outside the mouth 
- Reduced appetite causing weight loss and nutritional deficiencies 
- Lesions have been present for more than 3 weeks
    `,
    linkId: 'experiencingSymptoms',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'These are not typical symptoms of Oral Thrush and require further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })
  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      redMessage,
      healthCard,
      questionnaireHeading,
      olderThanOne,
      olderThan65,
      medicationHistory,
      experiencingSymptoms,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningOralThrushIndiesONJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['ON'],
    isEnterprise: false,
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: ['OralThrush'],
    },
  },
  metadata: {
    id: 'fhirMedicalScreeningOralThrushIndiesONJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
