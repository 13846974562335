import { questionnaire_indie_ma_on_contactInformation } from './questionnaire_indie_ma_on_contactInformation'
import { questionnaire_indie_ma_on_medicalInformation } from './questionnaire_indie_ma_on_medicalInformation'
import * as bulk_answer from './bulk_answer.json'

export default {
  fhirBulkAnswerJson: bulk_answer,
  fhirMAContactInformationIndiesONJson:
    questionnaire_indie_ma_on_contactInformation,
  fhirMAMedicalInformationIndiesONJson:
    questionnaire_indie_ma_on_medicalInformation,
}
