import { Engine } from 'json-rules-engine'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import {
  addContext,
  addEnableWhenEventRules,
  addValidationRules,
  addIsEmail,
  addFhirEnableWhenOperators,
  addIsSameOrBefore,
  addMatch,
  addIsPhone,
  addIsBefore,
  addIsAfter,
} from '../../rulesEngine'
import { TFunction } from 'i18next'

export const createFhirQuestionnaireResolverEngine = (
  state: ReturnType<typeof useBookingContext>['state'],
  questionnaire: FhirQuestionnaire,
  t: TFunction
): Engine => {
  const rulesEngine = new Engine()
  const addOperators: ((rulesEngine: Engine) => void)[] = [
    addContext(state),
    addFhirEnableWhenOperators,
    addValidationRules(questionnaire, t),
    addEnableWhenEventRules(questionnaire),
    addMatch,
    addIsEmail,
    addIsPhone,
    addIsSameOrBefore,
    addIsBefore,
    addIsAfter,
  ]
  addOperators.forEach((fn) => {
    fn(rulesEngine)
  })

  return rulesEngine
}
