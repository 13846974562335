import moment from 'moment'
import { Appointment } from './types'

export const isDocumentationComplete = (appointment: Appointment): boolean => {
  return Boolean(appointment.documentationCompletedAt)
}

export const canFillOutDocumentation = (appointment: Appointment): boolean => {
  if (isDocumentationComplete(appointment)) return false
  return ['SCHEDULED', 'ARRIVED', 'CONSENTED'].includes(
    appointment.appointmentStatus
  )
}

export const isAppointmentStatusCompleteLike = (
  appointment: Appointment
): boolean => {
  return ['SCHEDULED', 'COMPLETED', 'ARRIVED'].includes(appointment.status)
}

export const isAppointmentStatusCancelledLike = (
  appointment: Appointment
): boolean => {
  return ['CANCELLED', 'NO_SHOW', 'DEFERRED'].includes(appointment.status)
}

export const isWaitlistAppointment = (appointment: Appointment): boolean => {
  return appointment.status === 'WAITLIST'
}

export const isPendingReschedule = (appointment: Appointment): boolean => {
  return appointment.status === 'RESCHEDULE_LINK_SENT'
}

export const isExpiredLink = (appointment: Appointment): boolean => {
  return (
    moment().diff(moment(appointment.linkExpiresAt)) > 0 ||
    moment().diff(moment(appointment.bookingEndDate), 'days') > 0
  )
}
