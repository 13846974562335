import * as React from 'react'
import { Grid } from '@material-ui/core'
import { createFhirExtProps } from '../../../../../lib/fhir/extensions'
import { FhirQuestionnaireItemComponent } from '../FhirQuestionnaireItemComponent'

export const FhirContainerComponent: typeof FhirQuestionnaireItemComponent = (
  props
) => {
  const { questionnaireItem } = props

  const { grid } = React.useMemo(
    () => createFhirExtProps(questionnaireItem.extension),
    []
  )

  return (
    <Grid
      item
      xs={grid?.xs ?? 12}
      sm={grid?.sm}
      md={grid?.md}
      lg={grid?.lg}
      xl={grid?.xl}
    >
      {props.children}
    </Grid>
  )
}
