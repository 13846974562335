import React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { usePharmacyContext } from '../../contexts'
import {
  transformToAmericanHrefNumber,
  transformToStandardPhoneNumber,
} from '~/util/formatContact'
import { Box } from '@material-ui/core'

export function ErrorContactPharmacy() {
  const { t } = useMedMeTranslation()
  const { pharmacy } = usePharmacyContext()

  const phone = transformToStandardPhoneNumber(pharmacy?.pharmacyContact?.phone)

  if (!phone) return null

  return (
    <Box
      fontSize="1.2rem"
      fontWeight="bold"
      lineHeight="1.8rem"
      marginTop="1rem"
      textAlign="center"
    >
      <div>{t('oopsDesc')}</div>
      <div>
        {`${t('call')} `}
        <span>
          <a href={`tel:${transformToAmericanHrefNumber(phone)}`}>{phone}</a>
        </span>
      </div>
    </Box>
  )
}
