import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Minor Ailment Eligibility Screening ',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const completingQuestions = createFhirQuestionnaireItem({
    text: "Please answer the questions below to confirm you're eligible to be seen by a pharmacist. If completing on behalf of someone else, answer from their perspective.",
    linkId: 'completingQuestions',
    type: 'display',
  })

  const redMessage = createFhirQuestionnaireItem({
    text: '_If you have any reason to believe you may require urgent medical attention, please call 9-1-1 or go to the nearest hospital emergency room._',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const healthCard = createFhirQuestionnaireItem({
    text: 'This service is free for eligible Ontario Health Card holders. A professional fee may apply if you do not have a valid card. \n\n&nbsp; \n\n',
    linkId: 'healthCard',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const questionnaireHeading = createFhirQuestionnaireItem({
    linkId: 'questionnaireHeading',
    text: 'Screening Questions',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const sexAtBirth = createFhirQuestionnaireItem({
    text: 'Was your sex assigned at birth female?',
    linkId: 'sexAtBirth',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Period cramps (Dysmenorrhea) is a condition that can only be present in individuals assigned female at birth. Only individuals assigned female at birth are eligible for dysmenorrhea assessments by Ontario pharmacists.',
          },
        ],
      }),
    ],
    required: true,
  })

  const menstruation = createFhirQuestionnaireItem({
    text: 'Have you ever gone through menstruation (commonly referred to as a period)?',
    linkId: 'menstruation',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Period cramps (Dysmenorrhea) is a condition that can only be present in individuals who have experienced menstruation. Only individuals who have experienced menstruation are eligible for dysmenorrhea assessments by Ontario pharmacists.',
          },
        ],
      }),
    ],
    required: true,
  })

  const pregnancy = createFhirQuestionnaireItem({
    text: 'Could you be or are you currently pregnant?',
    linkId: 'pregnancy',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Suspected pelvic pain in pregnancy is a medical emergency. Please seek medical attention by a Primary Care Provider immediately.',
          },
        ],
      }),
    ],
    required: true,
  })

  const infertility = createFhirQuestionnaireItem({
    text: 'Do you have a history of infertility?',
    linkId: 'infertility',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Suspected pelvic pain with a history of infertility requires further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  const iudIus = createFhirQuestionnaireItem({
    text: 'Have you had an IUD (intrauterine device) or IUS (intrauterine system) inserted in the last 12 months?',
    linkId: 'iudIus',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Please seek further assessment by a Primary Care Provider.',
          },
        ],
      }),
    ],
    required: true,
  })

  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      redMessage,
      healthCard,
      questionnaireHeading,
      sexAtBirth,
      menstruation,
      pregnancy,
      infertility,
      iudIus,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningPeriodCrampsIndiesONJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['ON'],
    isEnterprise: false,
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: ['PeriodCramps'],
    },
  },
  metadata: {
    id: 'fhirMedicalScreeningPeriodCrampsIndiesONJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
