import { Extension } from 'fhir/r5'
import {
  ExtensionItemTypeVariant,
  extensionItemTypeVariantUrl,
  itemTypeVariantCodes,
} from './itemTypeVariant.type'

export const isExtensionItemTypeVariant = (
  extension: Extension
): extension is ExtensionItemTypeVariant => {
  return (
    extension.url === extensionItemTypeVariantUrl &&
    (itemTypeVariantCodes as ReadonlyArray<string>).includes(
      extension.valueCode ?? ''
    ) &&
    Object.keys(extension).length === 2
  )
}
