import { JsonFormStep } from './types'

export const activityGroupLanding: JsonFormStep = {
  type: 'preset',
  preset: 'activityGroupLanding',
  metadata: {
    id: 'activityGroupLanding',
    name: 'Activity Group Landing',
    path: 'activity-group-landing',
    showOnSteps: false,
  },
}
