import * as React from 'react'
import { Appointment, createAppointment } from '~/lib/appointment'
import {
  AppointmentActivityInstance,
  createAppointmentActivityInstance,
} from '~/lib/appointmentActivity'
import {
  AppointmentActivityGroup,
  createAppointmentActivityGroup,
} from '~/lib/appointmentActivityGroup'
import { createPatient, Patient } from '~/lib/patient'
import { createPharmacy, Pharmacy } from '~/lib/pharmacy'
import { createScope } from '../AppointmentActivityContext/AppointmentActivityContext'
import {
  DEFAULT_APPOINTMENT_INTAKE_ROUTER_CONTEXT,
  type AppointmentIntakeRouterContext,
} from '../RouterContext/RouterContext'
import { BookingFields, createBookingFields } from './bookingFields'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import { BookingScope } from '../../types'

export type BookingErrorValues = 'timeslotTaken' | ''
export interface BookingContextInterface {
  initial: {
    pharmacy: Pharmacy
    patient: Patient
    appointment: Appointment
    appointmentActivityGroup: AppointmentActivityGroup
    appointmentActivity: AppointmentActivityInstance
  }
  pharmacy: Pharmacy
  patient: Patient
  appointment: Appointment
  appointmentActivityGroup: AppointmentActivityGroup
  appointmentActivity: AppointmentActivityInstance
  scope: BookingScope
  router: AppointmentIntakeRouterContext
  fields: BookingFields
  questionnaires: FhirQuestionnaire[]
  setQuestionnaires: (questionnaires: FhirQuestionnaire[]) => void
  setFields: (fields: BookingContextInterface['fields']) => void
  reservationId: string
  setReservationId: (
    reservationId: BookingContextInterface['reservationId']
  ) => void
  bookingError: BookingErrorValues
  setBookingError: (
    bookingError: BookingContextInterface['bookingError']
  ) => void
}

export const createBookingContext = (): BookingContextInterface => ({
  initial: {
    pharmacy: createPharmacy(),
    patient: createPatient(),
    appointment: createAppointment(),
    appointmentActivityGroup: createAppointmentActivityGroup(),
    appointmentActivity: createAppointmentActivityInstance(),
  },
  pharmacy: createPharmacy(),
  patient: createPatient(),
  appointment: createAppointment(),
  appointmentActivityGroup: createAppointmentActivityGroup(),
  appointmentActivity: createAppointmentActivityInstance(),
  router: DEFAULT_APPOINTMENT_INTAKE_ROUTER_CONTEXT,
  scope: createScope(),
  fields: createBookingFields(),
  questionnaires: [],
  reservationId: '',
  setQuestionnaires: () => {
    throw new Error('setQuestionnaires default function expected')
  },
  setFields: () => {
    // @todo fix types
    throw new Error('setFields default function expected')
  },
  setReservationId: () => {
    // @todo fix types
    throw new Error('setReservationId default function expected')
  },
  bookingError: '',
  setBookingError: () => {
    // @todo fix types
    throw new Error('setBookingError default function expected')
  },
})

export const BookingContext = React.createContext(createBookingContext())
