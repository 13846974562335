import React, { ReactNode, useEffect, useReducer, useState } from 'react'
import { BookingManagementContext } from './BookingManagementContext'
import {
  Appointment,
  AppointmentActivityInstance,
  AvailabilityDateTimeValue,
  CombinedTimeslot,
  PatientOutput,
  Pharmacy,
  Sublocation,
} from '~/graphql/types/schemaNode.type'
import uniq from 'lodash/uniq'
import dayjs from 'dayjs'
import { computeDuration } from '../../helpers'
import { Reducer } from 'redux'
import { ClaimCodeFromBookingReturnType } from '~/util/claimCode'

interface BookingManagementProviderInterface {
  appointments: Appointment[]
  isCancelled: boolean
  isRescheduled: boolean
  isPatientDocumentLoading: boolean
  timeSlot?: CombinedTimeslot
  services: AppointmentActivityInstance[]
  claimCode: ClaimCodeFromBookingReturnType | string | null
  existingBooking?: Appointment
  nextAvailableDate?: AvailabilityDateTimeValue
  externalClinic?: Sublocation
  mainPatient?: PatientOutput
  mainService?: AppointmentActivityInstance
}

const reducer: Reducer<BookingManagementProviderInterface> = (
  state: BookingManagementProviderInterface,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case 'setAppointments': {
      const appointments = action.payload as Appointment[]
      const isCancelled = appointments.every(
        (appointment) => appointment.appointmentStatus === 'CANCELLED'
      )
      return {
        ...state,
        appointments,
        isCancelled,
      }
    }
    case 'setIsCancelled': {
      return {
        ...state,
        isCancelled: action.payload,
      }
    }
    case 'setIsRescheduled': {
      return {
        ...state,
        isRescheduled: action.payload,
      }
    }
    case 'setIsPatientDocumentLoading': {
      return {
        ...state,
        isPatientDocumentLoading: action.payload,
      }
    }
    case 'setCombinedTimeSlot': {
      return {
        ...state,
        timeSlot: action.payload,
      }
    }
    case 'setIntakeServices': {
      return {
        ...state,
        services: action.payload.services,
      }
    }
    case 'setClaimCode': {
      return {
        ...state,
        claimCode: action.payload,
      }
    }
    case 'setExistingBooking': {
      return {
        ...state,
        existingBooking: action.payload,
      }
    }
    case 'setNextAvailableDate': {
      return {
        ...state,
        nextAvailableDate: action.payload,
      }
    }
    case 'setExternalClinic': {
      return {
        ...state,
        externalClinic: action.payload,
      }
    }
    case 'setMainPatient': {
      return {
        ...state,
        mainPatient: action.payload,
      }
    }
    case 'setMainService': {
      return {
        ...state,
        mainService: action.payload,
      }
    }
    default:
      return state
  }
}

const initialState: BookingManagementProviderInterface = {
  appointments: [],
  isCancelled: false,
  isRescheduled: false,
  isPatientDocumentLoading: false,
  timeSlot: undefined,
  services: [],
  existingBooking: undefined,
  nextAvailableDate: undefined,
  claimCode: null,
  mainPatient: undefined,
  mainService: undefined,
}

export const BookingManagementProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[]
}) => {
  const [
    {
      appointments,
      isCancelled,
      isRescheduled,
      isPatientDocumentLoading,
      timeSlot,
      services,
      claimCode,
      existingBooking,
      nextAvailableDate,
      externalClinic,
      mainPatient,
      mainService,
    },
    dispatch,
  ] = useReducer(reducer, initialState)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [numberOfPersons, setNumberOfPersons] = useState<number>(0)
  const [pharmacy, setPharmacy] = useState<Pharmacy | undefined>()
  const [startDateTime, setStartDateTime] = useState<Date | undefined>()
  const [duration, setDuration] = useState<number>(0)
  const [urlParams, setUrlParams] = useState<string[]>([])

  useEffect(() => {
    if (appointments.length > 0) {
      setPharmacy(appointments[0].pharmacy)

      const noOfPersons = uniq(
        appointments.map((appointment) => appointment.patient?.id)
      ).length
      setNumberOfPersons(noOfPersons)

      const sortedAppointments = appointments
        .map((appointment) => ({
          ...appointment,
          startDateTime: appointment.startDateTime
            ? dayjs(appointment.startDateTime)
            : appointment.startDateTime,
        }))
        // Sort by date ascending
        .sort((a, b) => {
          if (!a.startDateTime || !b.startDateTime) return 0
          return a.startDateTime.diff(b.startDateTime)
        })

      const mainAppointment = sortedAppointments[0]
      const startDate = dayjs.isDayjs(mainAppointment.startDateTime)
        ? mainAppointment.startDateTime.toDate()
        : undefined
      setStartDateTime(startDate)

      const durationSum = computeDuration(noOfPersons, appointments)
      setDuration(durationSum)

      const urlParamsList: string[] = sortedAppointments
        .map(
          (appointment) =>
            appointment.appointmentType.appointmentActivityType || ''
        )
        .filter((x) => !!x)

      setUrlParams(urlParamsList)

      setIsInitialized(true)
    }
  }, [appointments])

  return (
    <BookingManagementContext.Provider
      value={{
        isCancelled: isCancelled,
        isRescheduled: isRescheduled,
        isPatientDocumentLoading: isPatientDocumentLoading,
        isInitialized,
        appointments,
        numberOfPersons,
        pharmacy,
        startDateTime,
        duration,
        urlParams,
        timeSlot,
        dispatch,
        services,
        mainService,
        claimCode,
        existingBooking,
        nextAvailableDate,
        externalClinic,
        mainPatient,
      }}
    >
      {children}
    </BookingManagementContext.Provider>
  )
}
