import styled from 'styled-components'

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: ${({ flex }) => flex || '0 1'};
  width: ${({ width }) => width || 'auto'};
  grid-column: ${({ gridColumn }) => gridColumn || 'auto / auto'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 'none'};

  .MuiFormControlLabel-root {
    align-items: ${({ alignCheckbox }) => alignCheckbox || 'center'};
  }
`
