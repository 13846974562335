import { Extension } from 'fhir/r5'
import { isEqual } from 'lodash'
import * as React from 'react'
import { UseFormMethods } from 'react-hook-form'
import { BULK_ANSWER_EXT_MEDME_URL } from '~/lib/fhir/extensions/constants'
import {
  FhirQuestionnaire,
  SimpleValueType,
} from '~/lib/fhir/fhirQuestionnaire'

type BulkAnswerGroup = Record<
  string,
  Record<
    // Although the key is a boolean, we're using a string here because
    // we need to be able to compare the value from the form to an optional
    // keys in the object.
    string,
    {
      answerCode: string | null
      linkIds: string[]
    }
  >
>
/**
 * @param questionnaire FhirQuestionnaire
 * @param methods method from react-hook-form useForm
 *
 * @returns
 */
export const useBulkAnswerFhirQuestionnaireItems = (
  questionnaire: FhirQuestionnaire,
  methods: UseFormMethods<Record<string, SimpleValueType>>
) => {
  const [error, setError] = React.useState<Error | undefined>(undefined)

  const [bulkAnswerGroups, setBulkAnswerGroups] =
    React.useState<BulkAnswerGroup>({})

  React.useEffect(() => {
    const seen = new Set(
      questionnaire.getQuestionnaireItems().map((item) => item.linkId)
    )

    questionnaire.getQuestionnaireItems().forEach((item) => {
      // Extension typing from FHIR does not include the values we need
      // for bulk answer, so we're using custom interface here.
      interface BulkAnswerExtension extends Omit<Extension, 'valueCode'> {
        valueCode: string[]
        triggerBoolean: boolean
        answerCode: string | null
      }

      const extensions = item.extension?.filter((ext) => {
        return ext.url === BULK_ANSWER_EXT_MEDME_URL
      }) as BulkAnswerExtension[] | undefined

      if (extensions && extensions?.length > 1) {
        throw new Error(
          `Multiple bulk answer extensions found for ${item.linkId}`
        )
      }

      extensions?.forEach((ext) => {
        const bulkAnswerValueCodes: string[] = ext.valueCode

        bulkAnswerValueCodes.forEach((valueCode) => {
          if (!seen.has(valueCode)) {
            setError(
              new Error(
                `Bulk answer value code "${valueCode}" not found in questionnaire`
              )
            )
          }
        })

        setBulkAnswerGroups((prevState) => ({
          ...prevState,
          [item.linkId]: {
            ...prevState[item.linkId],
            [String(ext.triggerBoolean)]: {
              answerCode: ext.answerCode,
              linkIds: bulkAnswerValueCodes,
            },
          },
        }))
      })
    })
  }, [questionnaire, questionnaire.linkIdItems])

  const values = methods.watch(Object.keys(bulkAnswerGroups))
  const valuesRef = React.useRef(values)

  const linkValues = methods.watch(
    Object.keys(bulkAnswerGroups).flatMap(
      (key) => bulkAnswerGroups[key]?.true?.linkIds
    )
  )
  const linkValuesRef = React.useRef(linkValues)

  React.useEffect(() => {
    if (isEqual(linkValuesRef.current, linkValues)) return

    for (const key in bulkAnswerGroups) {
      const linkIds = bulkAnswerGroups[key]?.true?.linkIds

      if (
        linkIds.some(
          (linkId) =>
            linkValues[linkId] !== bulkAnswerGroups[key]?.true?.answerCode
        )
      ) {
        methods.setValue(key, false)
      }
    }

    linkValuesRef.current = linkValues
  }, [bulkAnswerGroups, linkValues, methods])

  React.useEffect(() => {
    for (const key in values) {
      if (values[key] === valuesRef.current[key]) continue

      const rule = bulkAnswerGroups[key]?.[String(values[key])]
      if (!rule) continue

      const linkIdsToUpdate: string[] = rule.linkIds || []

      linkIdsToUpdate.forEach((id) => {
        methods.setValue(id, rule.answerCode)
      })
    }
    valuesRef.current = values
  }, [values, bulkAnswerGroups, methods])

  return React.useMemo(
    () => ({
      error,
    }),
    [error]
  )
}
