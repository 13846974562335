import styled from 'styled-components'
import SanitizedTextField from './inputs/sanitizedTextField'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import MuiButton from '@material-ui/core/Button'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  red,
  formGray,
  darkGray,
  disabledGray,
} from '~/sharedStyles/colorPalette'
import { Form } from 'formik'
import { Input as GenericInput } from '~/sharedStyles/formComponents'

// ==============================
//             Inputs
// ==============================

export const InputContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0;
`

export const Input = styled(SanitizedTextField)`
  .MuiFormLabel-root {
  }
  .MuiInputBase-input {
    ${(props) =>
      props.dimmedcolor &&
      `
    color: gray
    `}
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const CheckboxInput = styled(Checkbox)`
  padding: 0;
  margin-right: 10px;

  &.Mui-checked {
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
`

export const RadioInput = styled(Radio)`
  &.Mui-checked {
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
`

export const DateInput = styled(KeyboardDatePicker)`
  .MuiFormLabel-root {
  }
  .MuiInputBase-root {
  }
`

export const PostalCodeInput = styled(Input)`
  & > .MuiOutlinedInput-root > .MuiInputBase-input {
    text-transform: uppercase;
  }
`

export const Button = styled(MuiButton)`
  font-size: 0.75em;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  text-transform: none;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  color: white;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    color: white;
    opacity: 0.4;
  }
`

export const InverseButton = styled(Button)`
  font-size: 0.75em;
  background-color: white;
  color: ${({ theme }) => theme.color.backgroundPrimary};
  border: ${({ theme }) => `${theme.color.backgroundPrimary} solid 2px`};

  &:hover {
    background-color: white;
    opacity: 0.8;
  }

  &:disabled {
    background-color: white;
    color: ${({ theme }) => theme.color.backgroundPrimary};
    border: ${({ theme }) => `${theme.color.backgroundPrimary} solid 2px`};
    opacity: 0.4;
  }
`

export const EditButton = styled.div`
  display: flex;
  color: ${formGray};
  font-size: 0.85em;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
`

export const BackButton = styled(InverseButton)`
  width: 9rem;
  height: 3rem;
  margin-right: auto;
`

export const SubmitButton = styled(Button)`
  color: white;
  width: 9rem;
  height: 3rem;
`

// ==============================
//             Fonts
// ==============================

// H1 - Regular 40pt(page heading)
export const Heading1 = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`

// H2 - Regular 30pt(additional patient heading, modal headings)
export const Heading2 = styled.div`
  // 30pt
  font-size: 1em;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`

// H3 - Bold 24pt(section headings – Contact Information, Medical Information, Booking Details)
export const Heading3 = styled.div`
  font-size: 0.9em;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
`

export const Heading4 = styled.div`
  font-size: 0.8em;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`

// Body - Regular 18pt(sentences, checkbox, radio buttons, etc.)
export const Body = styled.div`
  font-size: 0.75em;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  font-weight: 500;
  line-height: 1.35;
  color: ${({ theme }) => theme.color.textPrimary};
`

// Caption - Regular 16pt(size of text box “filler text”, actual booking details)
export const Caption = styled.div`
  font-size: 0.7em;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`

export const Point = styled.div`
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  font-weight: 500;
  color: ${({ theme }) => theme.color.textTertiary};
  line-height: 1.35;
`

// ==============================
//             Labels
// ==============================

export const Title = styled(Heading3)`
  margin-bottom: 2rem;
  font-weight: 700;
`

export const SubTitle = styled(Heading4)`
  margin-bottom: 2rem;
`

export const InputLabel = styled.span`
  // material ui not respecting font family nor font size em correctly, need to set it 1em to make  it look like 0.9em
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ color, theme }) => color || theme.font.main};
  font-size: 0.95em;
  font-weight: 500;
  cursor: pointer;
`

export const ErrorText = styled(Body)`
  color: ${red};
  font-weight: 500;
  grid-column: 1 / -1;
  /* margin-bottom: 1rem; */
`

// ==============================
//             Layouts
// ==============================

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CardLayout = styled.form`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
  width: 100%;

  & > * {
    margin-bottom: 1.5rem;
  }
`

export const FormikCardLayout = styled(Form)`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
  min-width: 100%;

  & > * {
    margin-bottom: 1.5rem;
  }
`

export const Card = styled.div`
  /* flex: 1 0; */
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1.5rem 1rem;
  border-radius: 3px;
  background-color: white;

  @media only screen and (orientation: landscape) and (min-width: 992px) {
    padding: 1.5rem;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;

  & > * {
    margin-bottom: 0.5rem;
  }

  & > ${Title} {
    margin-bottom: 1rem;
  }
`

export const ReviewTitleContainer = styled.div`
  display: flex;
  flex: 0 0;
  justify-content: space-between;
  align-items: center;
  grid-column: 1 / -1;
  margin-bottom: 0;

  & > ${Title} {
    margin-bottom: 0;
  }

  @media only screen and (orientation: portrait) {
    margin-bottom: 1rem;
  }
`
export const Gray = styled.span`
  color: ${darkGray};
  font-weight: 700;
`

// ==============================
//             Formik
// ==============================

export const FormikInput = styled(GenericInput)`
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  font-size: 16px;
  margin-top: 0.5rem;

  &:disabled {
    background-color: ${disabledGray};
  }
`

export const QuestionBodyContainer = styled.div`
  display: grid;
  grid-row-gap: 0.25rem;
  margin-bottom: 0.5rem;
  flex: 0.9;
  @media only screen and (orientation: landscape) and (min-width: 992px) {
    margin-bottom: 0;
  }
`

export const RadioGroupContainer = styled.div`
  display: grid;
  flex: 0.1;
  margin-top: 0.5rem;
`
