import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import NameDobEmailCaslContactInformation from '~/pages/patientRegistrationV2/cards/contactAndQuestions/components/nameDobEmailCaslContactInformation'
import GroupInformation from '~/pages/patientRegistrationV2/cards/contactAndQuestions/components/groupInformation'
import { FormStepComponent } from '../types'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import { Card } from '../../WaitlistIntake.style'
import { FormLayout } from '../FormLayout'
import { useContactFormStep } from './hooks'

export const ContactFormStep: FormStepComponent = (formStepProps) => {
  const { t } = useMedMeTranslation()
  const { pharmacy } = useBookingContext()

  const { handleSubmit, formMethods } = useContactFormStep(formStepProps)

  return (
    <FormLayout
      onSubmit={handleSubmit}
      nextButtonLabel={t('continueButtonLabel')}
      {...formStepProps}
    >
      <FormProvider {...formMethods}>
        <Card elevation={0}>
          <NameDobEmailCaslContactInformation
            includeMiddleName={pharmacy?.enterprise !== 'SDM'}
          />
        </Card>
        <Card elevation={0}>
          <GroupInformation />
        </Card>
      </FormProvider>
    </FormLayout>
  )
}
