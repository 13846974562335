import * as React from 'react'
import { FhirQuestionnaireItemComponent } from '../FhirQuestionnaireItemComponent'
import { FhirGroupComponent } from '../FhirGroupComponent'
import { FhirLeafComponent } from '../FhirLeafComponent'
import { FhirCardComponent } from '../FhirCardComponent'
import { useFhirFormContext } from '../../FhirFormContext'

import {
  createFhirExtHideWhenDisabled,
  isFhirExtHideWhenDisabled,
} from '../../../../../lib/fhir/extensions'

export const FhirStemComponent: typeof FhirQuestionnaireItemComponent =
  React.memo((props) => {
    const {
      questionnaireItem: { type, linkId, _enableBehavior },
      depth = 0,
      isDisabled = false,
    } = props
    const { disabledFields } = useFhirFormContext()

    const isNodeDisabled = Boolean(isDisabled || disabledFields[linkId])
    const hideWhenDisabled = React.useMemo(() => {
      return (
        _enableBehavior?.extension?.reduce((acc, ext) => {
          if (isFhirExtHideWhenDisabled(ext)) {
            return createFhirExtHideWhenDisabled(ext).valueBoolean
          }
          return acc
        }, undefined) ?? false
      )
    }, [])

    if (hideWhenDisabled && disabledFields[linkId]) return null

    const currentDepth = depth + 1
    if (currentDepth > 5) throw new Error('FhirStemComponent: Depth exceeded')

    const updatedProps: React.ComponentProps<
      typeof FhirQuestionnaireItemComponent
    > = {
      ...props,
      depth: currentDepth,
      isDisabled: isNodeDisabled,
    }

    if (type === 'group') {
      if (depth === 0) return <FhirCardComponent {...updatedProps} />
      return <FhirGroupComponent {...updatedProps} />
    }

    return <FhirLeafComponent {...updatedProps} />
  })
