import { createGlobalStyle } from 'styled-components'

import gtEestiBold from '../public/fonts/GT-Eesti-Text-Bold-Trial.otf'
import gtEestiMedium from '../public/fonts/GT-Eesti-Text-Medium-Trial.otf'
import gtEestiRegular from '../public/fonts/GT-Eesti-Text-Regular-Trial.otf'
import gtEestiBook from '../public/fonts/GT-Eesti-Text-Book-Trial.otf'
import gtEestiLight from '../public/fonts/GT-Eesti-Text-Light-Trial.otf'
import gtEestiThin from '../public/fonts/GT-Eesti-Text-Thin-Trial.otf'

import gtEestiDisplayBold from '../public/fonts/GTEestiDisplay-Bold.woff2'
import gtEestiDisplayRegular from '../public/fonts/GTEestiDisplay-Regular.woff2'
import gtEestiDisplayLight from '../public/fonts/GTEestiDisplay-light.woff2'
import gtEestiDisplayMedium from '../public/fonts/GTEestiDisplay-medium.woff2'
import gtEestiDisplayThin from '../public/fonts/GTEestiDisplay-Thin.woff2'

import BogleRegular from '../public/fonts/BogleRegular.otf'
import BogleBold from '../public/fonts/BogleBold.otf'

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.35;
  }

  @font-face {
    font-family: 'GT Eesti';
    src: url(${gtEestiThin}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti';
    src: url(${gtEestiLight}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti';
    src: url(${gtEestiBook}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti';
    src: url(${gtEestiRegular}) format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti';
    src: url(${gtEestiMedium}) format('opentype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti';
    src: url(${gtEestiBold}) format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Display';
    src: url(${gtEestiDisplayThin}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Display';
    src: url(${gtEestiDisplayLight}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Display';
    src: url(${gtEestiDisplayRegular}) format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Display';
    src: url(${gtEestiDisplayMedium}) format('opentype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Display';
    src: url(${gtEestiDisplayBold}) format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bogle';
    src: url(${BogleRegular}) format('opentype');
    font-weight: 300 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bogle';
    src: url(${BogleBold}) format('opentype');
    font-weight: 700 800;
    font-style: normal;
  }
`

export default GlobalStyle
