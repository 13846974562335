import styled from 'styled-components'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const SectionHeader = styled(Heading4)`
  margin-bottom: 0.5rem;
  font-weight: 600;
`
