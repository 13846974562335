import * as React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const useMethodsDropDownVerbiage = () => {
  const { t } = useMedMeTranslation('patient')

  const verbiage = React.useMemo(
    () => ({
      headingLabel: t('activityLanding.methodsDropdDown.headingLabel'),
      inputLabel: t('activityLanding.methodsDropdDown.inputLabel'),
      methodMap: {
        VIRTUAL: t('activityLanding.methodsDropdDown.methods.virtual'),
        ONSITE: t('activityLanding.methodsDropdDown.methods.onSite'),
        PHONE: t('activityLanding.methodsDropdDown.methods.phone'),
      },
    }),
    [t]
  )

  return verbiage
}
