import { EnterpriseEnums } from '~/shared/constants/enterpriseConstants'
import { primary } from '~/sharedStyles/colorPalette'

type EnterpriseIdentifier = string
type EnterpriseName = string
type EnterpriseColor = string
type EnterpriseVariant = 'Clinician' | 'Pharmacist'

interface EnterpriseCodeDictionary {
  [enterpriseIdentifier: EnterpriseIdentifier]: EnterpriseEnums
}

interface EnterpriseNameDictionary {
  [enterpriseIdentifier: EnterpriseIdentifier]: EnterpriseName
}

interface EnterpriseColorDictionary {
  [enterpriseIdentifier: EnterpriseIdentifier]: EnterpriseColor
}

interface EnterpriseVariantDictionary {
  [key: string]: EnterpriseVariant
}

const ENTERPRISE_CODES: EnterpriseCodeDictionary = {
  REXALL: EnterpriseEnums.REXALL,
  'rexall.medmeapp.com': EnterpriseEnums.REXALL,
  'rexalldev.medmeapp.com': EnterpriseEnums.REXALL,
  'rexallsandbox.medmeapp.com': EnterpriseEnums.REXALL,
  'rexall.medmeapp.local': EnterpriseEnums.REXALL,
  SOF: EnterpriseEnums.SOF,
  'sofdev.medmeapp.com': EnterpriseEnums.SOF,
  'saveonfoods.medmeapp.com': EnterpriseEnums.SOF,
  'saveonfoodspharmacy.medmeapp.com': EnterpriseEnums.SOF,
  'sof.medmeapp.local': EnterpriseEnums.SOF,
  METRO: EnterpriseEnums.METRO,
  'metrodev.medmeapp.com': EnterpriseEnums.METRO,
  'metropharmacy.medmeapp.com': EnterpriseEnums.METRO,
  'metro.medmeapp.local': EnterpriseEnums.METRO,
  HEART: EnterpriseEnums.HEART,
  'heartpharmacy-dev.medme.io': EnterpriseEnums.HEART,
  'heartpharmacy.medmeapp.ca': EnterpriseEnums.HEART,
  'heartpharmacy.medme.local': EnterpriseEnums.HEART,
  NWC: EnterpriseEnums.NWC,
  'wconnect.medmeapp.com': EnterpriseEnums.NWC,
  'wconnect.medme.io': EnterpriseEnums.NWC,
  'wconnect.medmeapp.local': EnterpriseEnums.NWC,
  SDM: EnterpriseEnums.SDM,
  'sdmdev.medmeapp.com': EnterpriseEnums.SDM,
  'sdmdevqa.medmeapp.com': EnterpriseEnums.SDM,
  'sdmsandbox.medmeapp.com': EnterpriseEnums.SDM,
  'sdm-sit.staging.medmeapp.com': EnterpriseEnums.SDM,
  'sdm-pt.staging.medmeapp.com': EnterpriseEnums.SDM,
  'shoppersdrugmart.medmeapp.com': EnterpriseEnums.SDM,
  'sdm.medmeapp.local': EnterpriseEnums.SDM,
  PHX: EnterpriseEnums.PHX,
  'phx.medmeapp.local': EnterpriseEnums.PHX,
  'scheduler-dev.pharmaprix.ca': EnterpriseEnums.PHX,
  'scheduler-sit.pharmaprix.ca': EnterpriseEnums.PHX,
  'scheduler-pt.pharmaprix.ca': EnterpriseEnums.PHX,
  'scheduler.pharmaprix.ca': EnterpriseEnums.PHX,
  JCG: EnterpriseEnums.JCG,
  'jcgdev.medmeapp.com': EnterpriseEnums.JCG,
  'jcg.medme.io': EnterpriseEnums.JCG,
  'jeancoutu.medmesandbox.com': EnterpriseEnums.JCG,
  'jeancoutu.medmeapp.com': EnterpriseEnums.JCG,
  'consultation-dev.jeancoutu.com': EnterpriseEnums.JCG,
  'consultation.jeancoutu.com': EnterpriseEnums.JCG,
  'jcg.medmeapp.local': EnterpriseEnums.JCG,
  BRUNET: EnterpriseEnums.BRUNET,
  'brunetdev.medmeapp.com': EnterpriseEnums.BRUNET,
  'brunet.medme.io': EnterpriseEnums.BRUNET,
  'brunet.medmesandbox.com': EnterpriseEnums.BRUNET,
  'brunet.medmeapp.com': EnterpriseEnums.BRUNET,
  'consultation-dev.brunet.ca': EnterpriseEnums.BRUNET,
  'consultation.brunet.ca': EnterpriseEnums.BRUNET,
  'brunet.medmeapp.local': EnterpriseEnums.BRUNET,
  LIFE_SMART: EnterpriseEnums.LIFE_SMART,
  'lifesmartpharmacy.medme.io': EnterpriseEnums.LIFE_SMART,
  'lifesmartpharmacy.medmeapp.ca': EnterpriseEnums.LIFE_SMART,
  'lifesmartpharmacy.medmeapp.local': EnterpriseEnums.LIFE_SMART,
  LCL: EnterpriseEnums.LCL,
  'lcldev.medmeapp.com': EnterpriseEnums.LCL,
  'lcldevqa.medmeapp.com': EnterpriseEnums.LCL,
  'lclsandbox.medmeapp.com': EnterpriseEnums.LCL,
  'lcl-sit.staging.medmeapp.com': EnterpriseEnums.LCL,
  'lcl-pt.staging.medmeapp.com': EnterpriseEnums.LCL,
  'loblawpharmacy.medmeapp.com': EnterpriseEnums.LCL,
  'lcl.medmeapp.local': EnterpriseEnums.LCL,
  // sso?
  MEDME: EnterpriseEnums.MEDME,
  'pharmacyenterprise-dev.medmeapp.local': EnterpriseEnums.MEDME,
  'pharmacyenterprise-dev.medmeapp.com': EnterpriseEnums.MEDME,
  'pharmacyenterprise.medmeapp.com': EnterpriseEnums.MEDME,
  MINT: EnterpriseEnums.MINT,
  'mintdrugsdev.medmeapp.com': EnterpriseEnums.MINT,
  'mintdrugs.medmeapp.com': EnterpriseEnums.MINT,
  'mintdrugs.medmeapp.local': EnterpriseEnums.MINT,
  //  localhost: EnterpriseEnums.REXALL, // TOGGLE THIS FOR TESTING IN LOCAL-DEV
  LDRUGS: EnterpriseEnums.LDRUGS,
  'londondrugs.medmeapp.com': EnterpriseEnums.LDRUGS,
  'londondrugs.development.medmeapp.com': EnterpriseEnums.LDRUGS,
  'londondrugs.staging.medmeapp.com': EnterpriseEnums.LDRUGS,
  'londondrugs.sandbox.medmeapp.com': EnterpriseEnums.LDRUGS,
  'londondrugs.medmeapp.local': EnterpriseEnums.LDRUGS,
}

const ENTERPRISE_NAMES: EnterpriseNameDictionary = {
  [EnterpriseEnums.REXALL]: EnterpriseEnums.REXALL,
  'rexall.medmeapp.com': EnterpriseEnums.REXALL,
  'rexalldev.medmeapp.com': EnterpriseEnums.REXALL,
  'rexallsandbox.medmeapp.com': EnterpriseEnums.REXALL,
  'rexallsandbox.medmeapp.local': EnterpriseEnums.REXALL,
  [EnterpriseEnums.SOF]: 'Save-On-Foods',
  'sofdev.medmeapp.com': 'Save-On-Foods',
  'saveonfoodspharmacy.medmeapp.com': 'Save-On-Foods',
  'sof.medmeapp.local': 'Save-On-Foods',
  [EnterpriseEnums.METRO]: 'Metro/Food Basics',
  'metrodev.medmeapp.com': 'Metro/Food Basics',
  'metropharmacy.medmeapp.com': 'Metro/Food Basics',
  'metro.medmeapp.local': 'Metro/Food Basics',
  [EnterpriseEnums.HEART]: 'Heart Pharmacy',
  'heartpharmacy-dev.medme.io': 'Heart pharmacy',
  'heartpharmacy.medmeapp.ca': 'Heart pharmacy',
  'heartpharmacy.medme.local': 'Heart pharmacy',
  [EnterpriseEnums.NWC]: 'W-Connect',
  'wconnect.medmeapp.com': 'W-Connect',
  'wconnect.medme.io': 'W-Connect',
  'wconnect.medme.local': 'W-Connect',
  [EnterpriseEnums.SDM]: 'Shoppers Drug Mart',
  'sdmdev.medmeapp.com': 'Shoppers Drug Mart',
  'sdmdevqa.medmeapp.com': 'Shoppers Drug Mart',
  'sdmsandbox.medmeapp.com': 'Shoppers Drug Mart',
  'shoppersdrugmart.medmeapp.com': 'Shoppers Drug Mart',
  'sdm-sit.staging.medmeapp.com': 'Shoppers Drug Mart',
  'sdm-pt.staging.medmeapp.com': 'Shoppers Drug Mart',
  'shoppers.medmeapp.com': 'Shoppers Drug Mart',
  'sdm.medmeapp.local': 'Shoppers Drug Mart',

  // TODO: NEB-1102 - to be reverted
  [EnterpriseEnums.JCG]: 'Test Pharmacy',
  'jcgdev.medmeapp.com': 'Test Pharmacy',
  'jcg.medmeapp.local': 'Test Pharmacy',
  'jcg.medme.io': 'Jean Coutu Pharmacy',
  'jeancoutu.medmesandbox.com': 'Jean Coutu Pharmacy',
  'jeancoutu.medmeapp.com': 'Jean Coutu Pharmacy',
  'consultation-dev.jeancoutu.com': 'Jean Coutu Pharmacy',
  'consultation.jeancoutu.com': 'Jean Coutu Pharmacy',
  [EnterpriseEnums.BRUNET]: 'Brunet Pharmacy',
  'brunetdev.medmeapp.com': 'Brunet Pharmacy',
  'brunet.medme.io': 'Brunet Pharmacy',
  'brunet.medmesandbox.com': 'Brunet Pharmacy',
  'brunet.medmeapp.com': 'Brunet Pharmacy',
  'consultation-dev.brunet.ca': 'Brunet Pharmacy',
  'consultation.brunet.ca': 'Brunet Pharmacy',
  'brunet.medmeapp.local': 'Brunet Pharmacy',
  [EnterpriseEnums.LIFE_SMART]: 'Life Smart',
  'lifesmartpharmacy.medme.io': 'List Smart',
  'lifesmartpharmacy.medmeapp.ca': 'Life Smart',
  'lifesmartpharmacy.medmeapp.local': 'Life Smart',
  [EnterpriseEnums.LCL]: 'Loblaw',
  'lcldev.medmeapp.com': 'Loblaw',
  'lcldevqa.medmeapp.com': 'Loblaw',
  'lclsandbox.medmeapp.com': 'Loblaw',
  'lcl-sit.staging.medmeapp.com': 'Loblaw',
  'lcl-pt.staging.medmeapp.com': 'Loblaw',
  'loblawpharmacy.medmeapp.com': 'Loblaw',
  'lcl.medmeapp.local': 'Loblaw',
  [EnterpriseEnums.MEDME]: 'MedMe Enterprises',
  'pharmacyenterprise-dev.medmeapp.local': 'MedMe',
  'pharmacyenterprise-dev.medmeapp.com': 'MedMe',
  'pharmacyenterprise.medmeapp.com': 'MedMe',
  [EnterpriseEnums.MINT]: 'Mint Health & Drugs',
  'mintdrugsdev.medmeapp.com': 'Mint Health & Drugs',
  'mintdrugs.medmeapp.com': 'Mint Health & Drugs',
  'mintdrugs.medmeapp.local': 'Mint Health & Drugs',
  [EnterpriseEnums.PHX]: 'Pharmaprix',
  'phx.medmeapp.local': 'Pharmaprix',
  'scheduler-dev.pharmaprix.ca': 'Pharmaprix',
  'scheduler-sit.pharmaprix.ca': 'Pharmaprix',
  'scheduler-pt.pharmaprix.ca': 'Pharmaprix',
  'scheduler.pharmaprix.ca': 'Pharmaprix',
  //  localhost: 'REXALL', // TOGGLE THIS FOR TESTING IN LOCAL-DEV
  [EnterpriseEnums.LDRUGS]: 'London Drugs',
  'londondrugs.medmeapp.com': 'London Drugs',
  'londondrugs.development.medmeapp.com': 'London Drugs',
  'londondrugs.staging.medmeapp.com': 'London Drugs',
  'londondrugs.sandbox.medmeapp.com': 'London Drugs',
  'londondrugs.medmeapp.local': 'London Drugs',
}

const enterpriseColors: EnterpriseColorDictionary = {
  [EnterpriseEnums.REXALL]: '#00B2A9',
  'rexall.medmeapp.local': '#00B2A9',
  'rexall.medmeapp.com': '#00B2A9',
  'rexalldev.medmeapp.com': '#00B2A9',
  'rexallsandbox.medmeapp.com': '#00B2A9',
  [EnterpriseEnums.SOF]: '#78BE20',
  'sof.medmeapp.local': '#78BE20',
  'sofdev.medmeapp.com': '#78BE20',
  'saveonfoodspharmacy.medmeapp.com': '#78BE20',
  [EnterpriseEnums.METRO]: '#4F81BD',
  'metro.medmeapp.local': '#4F81BD',
  'metrodev.medmeapp.com': '#4F81BD',
  'metropharmacy.medmeapp.com': '#4F81BD',
  [EnterpriseEnums.HEART]: '#000000',
  'heartpharmacy.medmeapp.local': primary,
  'heartpharmacy-dev.medme.io': '#000000',
  'heartpharmacy.medmeapp.ca': '#000000',
  'wconnect.medmeapp.com': primary,
  'wconnect.medme.io': primary,
  [EnterpriseEnums.SDM]: '#CB333B',
  'sdm.medmeapp.local': '#CB333B',
  'sdmdev.medmeapp.com': '#CB333B',
  'sdmdevqa.medmeapp.com': '#CB333B',
  'sdmsandbox.medmeapp.com': '#CB333B',
  'sdm-sit.staging.medmeapp.com': '#CB333B',
  'sdm-pt.staging.medmeapp.com': '#CB333B',
  'shoppersdrugmart.medmeapp.com': '#CB333B',
  [EnterpriseEnums.JCG]: primary,
  'jcg.medmeapp.local': primary,
  'jcg.medme.io': primary,
  'jcgdev.medmeapp.com': primary,
  'jeancoutu.medmesandbox.com': primary,
  'jeancoutu.medmeapp.com': primary,
  'consultation-dev.jeancoutu.com': primary,
  'consultation.jeancoutu.com': primary,
  [EnterpriseEnums.BRUNET]: primary,
  'brunet.medmeapp.local': primary,
  'brunet.medme.io': primary,
  'brunetdev.medmeapp.com': primary,
  'brunet.medmesandbox.com': primary,
  'brunet.medmeapp.com': primary,
  'consultation-dev.brunet.ca': primary,
  'consultation.brunet.ca': primary,
  [EnterpriseEnums.LIFE_SMART]: primary,
  'lifesmartpharmacy.medmeapp.local': primary,
  'lifesmartpharmacy.medme.io': primary,
  'lifesmartpharmacy.medmeapp.ca': primary,
  [EnterpriseEnums.LCL]: '#DA291C',
  'lcl.medmeapp.local': '#DA291C',
  'lcldev.medmeapp.com': '#DA291C',
  'lcldevqa.medmeapp.com': '#DA291C',
  'lclsandbox.medmeapp.com': '#DA291C',
  'lcl-sit.staging.medmeapp.com': '#DA291C',
  'lcl-pt.staging.medmeapp.com': '#DA291C',
  'loblawpharmacy.medmeapp.com': '#DA291C',
  [EnterpriseEnums.PHX]: '#CB333B',
  'phx.medmeapp.local': '#CB333B',
  'scheduler-dev.pharmaprix.ca': '#CB333B',
  'scheduler-sit.pharmaprix.ca': '#CB333B',
  'scheduler-pt.pharmaprix.ca': '#CB333B',
  'scheduler.pharmaprix.ca': '#CB333B',
  // localhost: primary, // TOGGLE THIS FOR TESTING IN LOCAL-DEV
}

const enterpriseVariant: EnterpriseVariantDictionary = {
  NWC: 'Clinician',
  JCHEALTH: 'Clinician',
}

export const isEnterprise = (enterprise?: EnterpriseIdentifier) =>
  Object.keys(ENTERPRISE_CODES).includes(enterprise || window.location.hostname)

export const isLCLRedirect = () => {
  return (
    ENTERPRISE_CODES[window.location.hostname] === EnterpriseEnums.SDM &&
    window.location.pathname.startsWith('/lcl/')
  )
}

/**
 * Returns the _Enterprise Code_ corresponding to the given `EnterpriseIdentifier` if one
 * is given as an argument, otherwise a falsey value (empty string) if the value is not recognized.
 *
 * If no argument is provided, this function returns the _Enterprise Code_ based on the hostname
 * of the current app deployment based on `window.location.hostname`, otherwise a falsey value
 * (empty string) if the app deployment URL is not associated with an enterprise.
 *
 * Enterprise Codes are defined in `EnterpriseEnums`.
 *
 * @see EnterpriseIdentifier
 * @see EnterpriseEnums
 * @see enterpriseCodes
 *
 * @returns EnterpriseEnums value or falsey value (empty string)
 */
export const getEnterpriseCode = (
  enterprise?: EnterpriseIdentifier
): EnterpriseEnums | '' => {
  const enterpriseCode = isLCLRedirect()
    ? EnterpriseEnums.LCL
    : ENTERPRISE_CODES[enterprise || window.location.hostname] || ''

  return enterpriseCode
}

export const getEnterpriseName = (
  enterprise?: EnterpriseIdentifier
): EnterpriseName | '' =>
  ENTERPRISE_NAMES[window.location.hostname ?? enterprise] || ''

export const getEnterpriseColor = (
  enterprise?: EnterpriseIdentifier
): EnterpriseColor | '' =>
  enterpriseColors[enterprise || window.location.hostname] || '#063E54'

export const getEnterpriseProviderType = (
  enterprise?: EnterpriseIdentifier
): EnterpriseVariant =>
  enterpriseVariant[enterprise || window.location.hostname] || 'Pharmacist'

// SDM LCL
export const isSdmEnterprise = (): boolean =>
  ['SDM', 'LCL', 'PHX'].includes(getEnterpriseCode())
export const isSdm = () => ['SDM'].includes(getEnterpriseCode())
export const isLcl = () => ['LCL'].includes(getEnterpriseCode())

export const isSdmEnterpriseDevSubdomain = (subdomain: string) =>
  [
    'sdmdev.medmeapp.com',
    'lclsdmdev.medmeapp.com',
    'scheduler-dev.pharmaprix.ca',
  ].some((item) => subdomain.includes(item))

export const isSdmEnterpriseProdSubdomain = (subdomain: string) =>
  [
    'shoppersdrugmart.medmeapp.com',
    'loblawpharmacy.medmeapp.com',
    'scheduler.pharmaprix.ca',
  ].some((item) => subdomain.includes(item))

export const isSdmEnterpriseJointSubdomain = (subdomain: string) =>
  ['lclsdmdev', 'lclsdm', 'lclsdmsandbox'].some((item) =>
    subdomain.includes(item)
  )

export const isRexallProdSubdomain = (subdomain: string) =>
  ['rexall.medmeapp.com'].some((item) => subdomain.includes(item))

export const isSofProdSubdomain = (subdomain: string) =>
  ['saveonfoodspharmacy.medmeapp.com'].some((item) => subdomain.includes(item))

export const isMetroPharmacyProdSubdomain = (subdomain: string) =>
  ['metropharmacy.medmeapp.com'].some((item) => subdomain.includes(item))

export const isMintDrugsProdSubdomain = (subdomain: string) =>
  ['mintdrugs.medmeapp.com'].some((item) => subdomain.includes(item))

// JCG BRUNET
export const JEAN_COUTU_ENVIRONMENTS = [
  EnterpriseEnums.JCG,
  EnterpriseEnums.BRUNET,
]

export const isJcgBrunet = (enterprise?: EnterpriseIdentifier): boolean => {
  const enterpriseCode = enterprise || getEnterpriseCode()
  return [EnterpriseEnums.JCG, EnterpriseEnums.BRUNET].includes(
    ENTERPRISE_CODES[enterpriseCode]
  )
}

export const isJcg = (enterprise?: EnterpriseIdentifier) => {
  const enterpriseCode = enterprise || getEnterpriseCode()
  return [EnterpriseEnums.JCG].includes(ENTERPRISE_CODES[enterpriseCode])
}

export const isBrunet = (enterprise?: EnterpriseIdentifier) => {
  const enterpriseCode = enterprise || getEnterpriseCode()
  return [EnterpriseEnums.BRUNET].includes(ENTERPRISE_CODES[enterpriseCode])
}

export const isJcgBrunetJointSubdomain = (subdomain: string) =>
  [
    'jcgbrunetdev',
    'jcgbrunet',
    'consultation-dev.centrerx.com',
    'consultation.centrerx.com',
  ].some((item) => subdomain.includes(item))

// JC HEALTH ENTERPRISE
export const isJcHealth = (): boolean => {
  return [
    'jchealthottawa.medmeapp.com',
    'jchealthsurrey.medmeapp.com',
    'jchealthtelemedicine.medmeapp.com',
    'jchealthtoronto.medmeapp.com',
    //    'localhost', // toggle for local testing
  ].includes(window.location.hostname)
}

// NWC
export const isNwc = () => EnterpriseEnums.NWC === getEnterpriseCode()

// SOF
export const isSof = () => EnterpriseEnums.SOF === getEnterpriseCode()

// REXALL
export const isRexall = () => EnterpriseEnums.REXALL === getEnterpriseCode()

// MINOR ENTERPRISES are enterprise clients that don't have their own database instance (Heart, Lifesmart), intead they share a database with indies.
export const isMinorEnterprise = () =>
  EnterpriseEnums.HEART === getEnterpriseCode() ||
  EnterpriseEnums.LIFE_SMART === getEnterpriseCode()

// METRO
export const isMetro = () => EnterpriseEnums.METRO === getEnterpriseCode()

export const isMint = () => getEnterpriseCode() === EnterpriseEnums.MINT
