import { Extension } from 'fhir/r5'
import { fhirStructDefUrlMedMe } from '../constants'

const extPathName = 'regex'

export const extensionRegexUrl =
  `${fhirStructDefUrlMedMe}/${extPathName}` as const

export interface ExtensionRegex extends Pick<Extension, 'url' | 'valueCode'> {
  url: typeof extensionRegexUrl
  valueCode: string
}
