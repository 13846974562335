import * as React from 'react'
import { Patient } from '~/lib/patient'
import { PatientContext } from '../PatientContext'

export const usePatientContext = () => {
  const context = React.useContext(PatientContext)
  if (context === undefined) {
    throw new Error('usePatientContext must be used within a PatientProvider')
  }

  const { dispatch, ...state } = context

  const updatePatient = React.useCallback(
    (patient: Patient) => {
      dispatch({
        type: 'updatePatient',
        patient: patient,
      })
    },
    [dispatch]
  )

  return {
    ...state,
    updatePatient,
  }
}
