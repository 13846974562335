import React from 'react'
import { Controller } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { Layout } from './labeledCheckbox.styles'
import { CheckboxInput, InputLabel } from '../../patientRegistration.styles'

const LabeledCheckbox = ({
  className,
  checkboxType,
  label,
  styles = {},
  labelStyles = {},
  error,
  helperText,
  prePopulate,
  disabled,
  onChange = () => undefined,
  ...rest
}) => {
  return (
    <Layout className={className} {...styles}>
      <FormControlLabel
        style={{ margin: 0, alignItems: 'flex-start' }}
        control={
          <Controller
            render={(props) => (
              <CheckboxInput
                name={checkboxType}
                disabled={disabled}
                onChange={(e) => {
                  props.onChange(e.target.checked)
                  if (prePopulate) prePopulate(e)
                  onChange(e)
                }}
                checked={props.value}
                color="default"
              />
            )}
            {...rest}
          />
        }
        disabled={disabled}
        label={<InputLabel color={labelStyles.color || ''}>{label}</InputLabel>}
        labelPlacement={labelStyles.placement || 'end'}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Layout>
  )
}

export default LabeledCheckbox
