import * as React from 'react'

const INTAKE_TYPES = [
  /** Patient-initiated flows are classified as "public flows" */
  'public',

  /**
   * Pharmacist-initiatied flow where patient record is already in the system for that pharmacy,
   * the pharmacist knows the patient wants the appointment, and then sends a link (via email or sms)
   * for the Patient to complete the booking.
   */
  'bookingLink',

  /**
   * Related to bookingLink: patient + appointment exists, but no documentation has been filled out by the patient
   * that's required for this appointment.
   *
   * This flow refers to the pharmacist side initiating sending a notification to the patient to
   * complete documentation and is initiated when the patient.
   */
  'documentationLink',

  /**
   * @ThomasLuu02 stubbed... If patient completed their appointment
   *
   * @deprecated - should be deprecated - unsure if retired (@todo investigate and delete if retired)
   */
  'bookNextAppointmentLink',

  /**
   * Relates to WebView flow (see PC Health App)
   *
   * If page refreshed during intake, if URL doesn't exist, this is used to determine if the patient
   * refreshed and in current configuration the URL + params are lost, so this is used as a canary to
   * detect if the patient refreshed and fall-back to safety e.g. fallback to /schedule public intake page.
   *
   * @todo this is not the greatest and should be revisited -- its scope accommodating for router issues
   * @todo webview specific behaviours should ideally be in their own dedicated app
   */
  'bookingInProgress',
] as const
export type IntakeType = (typeof INTAKE_TYPES)[number]

export interface AppointmentIntakeRouterContextSearchParams {
  /**
   * Flag if the current context is a webview (e.g. PC Health App)
   */
  webview: boolean
  province: string // @todo strict types + validation/schema
  appointmentType: string
  global: boolean
  isWalkin: boolean
  language: string // @todo future
  key1: string
  key2: string
  key3: string
  service: string
}

/**
 * These reflect all search/query URL params across pharmacies + patients UI
 * per current understanding of TL.
 */
export interface AppointmentIntakeRouterContext {
  hostname: string
  pathname: string
  search: string
  appointmentTypeWithCalendarToken: string
  patientToken: string
  appointmentToken: string
  storeNo: string
  enterpriseCode: string
  activity: string // dynamic string (no strong types available)
  group: string // dynamic string (no strong types available)
  activityGroup: string
  appointmentActivityId: string
  intakeType: IntakeType

  /**
   * Name of the form displayed in the URL for webview integration (@jesse / @ruowen re back button).
   * Router value required by SDM (related to custom SDM work)
   *
   * Back button in webview is a native back-button click...
   */
  formPath: string

  searchParams: AppointmentIntakeRouterContextSearchParams
}

export const DEFAULT_APPOINTMENT_INTAKE_ROUTER_CONTEXT: AppointmentIntakeRouterContext =
  {
    hostname: '',
    pathname: '',
    search: '',
    appointmentTypeWithCalendarToken: '',
    patientToken: '',
    appointmentToken: '',
    storeNo: '',
    enterpriseCode: '',
    activity: '',
    activityGroup: '',
    group: '',
    appointmentActivityId: '',
    intakeType: 'public',
    formPath: '',
    searchParams: {
      webview: false,
      province: '',
      appointmentType: '',
      global: false,
      isWalkin: false,
      language: '',
      key1: '',
      key2: '',
      key3: '',
      service: '',
    },
  } as const

export const RouterContext = React.createContext(
  DEFAULT_APPOINTMENT_INTAKE_ROUTER_CONTEXT
)
