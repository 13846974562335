import pharmacyIdsMedmeIo from '~/config/pharmacyIds.medme.io'
import pharmacyIdsMedmeSandboxCom from '~/config/pharmacyIds.medmesandbox.com'
import pharmacyIdsMedmeappCa from '~/config/pharmacyIds.medmeapp.ca'
import pharmacyIdsFeature from '~/config/pharmacyIds.feature'
import pharmacyIdsStagingMedmeappCom from '~/config/pharmacyIds.staging.medmeapp.com'

export default {
  'medmeapp.local-pharmacyId': process.env.INDIE_PHARMACY_ID,
  'on.medmeapp.local-pharmacyId': process.env.ON_PHARMACY_ID,
  'ab.medmeapp.local-pharmacyId': process.env.AB_PHARMACY_ID,
  'bc.medmeapp.local-pharmacyId': process.env.BC_PHARMACY_ID,
  'mb.medmeapp.local-pharmacyId': process.env.MB_PHARMACY_ID,
  'nb.medmeapp.local-pharmacyId': process.env.NB_PHARMACY_ID,
  'nl.medmeapp.local-pharmacyId': process.env.NL_PHARMACY_ID,
  'ns.medmeapp.local-pharmacyId': process.env.NS_PHARMACY_ID,
  'pe.medmeapp.local-pharmacyId': process.env.PE_PHARMACY_ID,
  'sk.medmeapp.local-pharmacyId': process.env.SK_PHARMACY_ID,
  'sheppardtucker.medmeapp.local-pharmacyId':
    process.env.SHEPPARDTUCKER_STAGING_PHARMACY_ID,
  'medme.medmeapp.local-pharmacyId': process.env.MEDME_PHARMACY_ID,
  '192.168.1.86-pharmacyId': process.env['192.168.1.86-pharmacyId'],
  'localhost-pharmacyId': process.env['localhost-pharmacyId'],
  'dev.medmeapp.com-pharmacyId': process.env['dev.medmeapp.com-pharmacyId'],
  'medmepharmacy.medmeapp.com-pharmacyId':
    process.env['medmepharmacy.medmeapp.com-pharmacyId'],
  'simpsonspharmacy.medmeapp.com-pharmacyId':
    process.env['simpsonspharmacy.medmeapp.com-pharmacyId'],
  'sparklepharmacy.medmeapp.com-pharmacyId':
    process.env['sparklepharmacy.medmeapp.com-pharmacyId'],
  'medshoppe365.medmeapp.com-pharmacyId':
    process.env['medshoppe365.medmeapp.com-pharmacyId'],
  'cooksville.medmeapp.com-pharmacyId':
    process.env['cooksville.medmeapp.com-pharmacyId'],
  'nwcmoosonee.medmeapp.com-pharmacyId':
    process.env['nwcmoosonee.medmeapp.com-pharmacyId'],
  'lansdallpharmacy.medmeapp.com-pharmacyId':
    process.env['lansdallpharmacy.medmeapp.com-pharmacyId'],
  'communityhealth.medmeapp.com-pharmacyId':
    process.env['communityhealth.medmeapp.com-pharmacyId'],
  'lifemed.medmeapp.com-pharmacyId':
    process.env['lifemed.medmeapp.com-pharmacyId'],
  'jaespharmacy.medmeapp.com-pharmacyId':
    process.env['jaespharmacy.medmeapp.com-pharmacyId'],
  'wynpharm.medmeapp.com-pharmacyId':
    process.env['wynpharm.medmeapp.com-pharmacyId'],
  'stspain.medmeapp.com-pharmacyId':
    process.env['stspain.medmeapp.com-pharmacyId'],
  'sandstonesprague.medmeapp.com-pharmacyId':
    process.env['sandstonesprague.medmeapp.com-pharmacyId'],
  'sandstonecarrydrive.medmeapp.com-pharmacyId':
    process.env['sandstonecarrydrive.medmeapp.com-pharmacyId'],
  'sandstoneairdrie.medmeapp.com-pharmacyId':
    process.env['sandstoneairdrie.medmeapp.com-pharmacyId'],
  'glenbrookpharmasave.medmeapp.com-pharmacyId':
    process.env['glenbrookpharmasave.medmeapp.com-pharmacyId'],
  'sandstoneglenmore.medmeapp.com-pharmacyId':
    process.env['sandstoneglenmore.medmeapp.com-pharmacyId'],
  'sandstonedover.medmeapp.com-pharmacyId':
    process.env['sandstonedover.medmeapp.com-pharmacyId'],
  'msp260.medmeapp.com-pharmacyId':
    process.env['msp260.medmeapp.com-pharmacyId'],
  'dev-ab.medmeapp.com-pharmacyId':
    process.env['dev-ab.medmeapp.com-pharmacyId'],
  'dev-bc.medmeapp.com-pharmacyId':
    process.env['dev-bc.medmeapp.com-pharmacyId'],
  'breakwaterpharmacy.medmeapp.com-pharmacyId':
    process.env['breakwaterpharmacy.medmeapp.com-pharmacyId'],
  'pharmasavebashaw.medmeapp.com-pharmacyId':
    process.env['pharmasavebashaw.medmeapp.com-pharmacyId'],
  'sandstonesimonsvalley.medmeapp.com-pharmacyId':
    process.env['sandstonesimonsvalley.medmeapp.com-pharmacyId'],
  'sandstonehendaycentre.medmeapp.com-pharmacyId':
    process.env['sandstonehendaycentre.medmeapp.com-pharmacyId'],
  'pharmasavebeaumont.medmeapp.com-pharmacyId':
    process.env['pharmasavebeaumont.medmeapp.com-pharmacyId'],
  'medicineshoppe200.medmeapp.com-pharmacyId':
    process.env['medicineshoppe200.medmeapp.com-pharmacyId'],
  'hppharmasave.medmeapp.com-pharmacyId':
    process.env['hppharmasave.medmeapp.com-pharmacyId'],
  'okotokspharmasave.medmeapp.com-pharmacyId':
    process.env['okotokspharmasave.medmeapp.com-pharmacyId'],
  'sandstonenanton.medmeapp.com-pharmacyId':
    process.env['sandstonenanton.medmeapp.com-pharmacyId'],
  'msmeadowlark.medmeapp.com-pharmacyId':
    process.env['msmeadowlark.medmeapp.com-pharmacyId'],
  'medicineshoppeosborne.medmeapp.com-pharmacyId':
    process.env['medicineshoppeosborne.medmeapp.com-pharmacyId'],
  'msp328.medmeapp.com-pharmacyId':
    process.env['msp328.medmeapp.com-pharmacyId'],
  'sherwooddrugmart.medmeapp.com-pharmacyId':
    process.env['sherwooddrugmart.medmeapp.com-pharmacyId'],
  'sandstonehythe.medmeapp.com-pharmacyId':
    process.env['sandstonehythe.medmeapp.com-pharmacyId'],
  'sandstonecrescentheights.medmeapp.com-pharmacyId':
    process.env['sandstonecrescentheights.medmeapp.com-pharmacyId'],
  'polaristravelclinic.medmeapp.com-pharmacyId':
    process.env['polaristravelclinic.medmeapp.com-pharmacyId'],
  'sandstoneblackfalds.medmeapp.com-pharmacyId':
    process.env['sandstoneblackfalds.medmeapp.com-pharmacyId'],
  'pharmasaveelora.medmeapp.com-pharmacyId':
    process.env['pharmasaveelora.medmeapp.com-pharmacyId'],
  'parksidemednow.medmeapp.com-pharmacyId':
    process.env['parksidemednow.medmeapp.com-pharmacyId'],
  'sandstonefifthave.medmeapp.com-pharmacyId':
    process.env['sandstonefifthave.medmeapp.com-pharmacyId'],
  'wilsons521.medmeapp.com-pharmacyId':
    process.env['wilsons521.medmeapp.com-pharmacyId'],
  'msp412.medmeapp.com-pharmacyId':
    process.env['msp412.medmeapp.com-pharmacyId'],
  'esquimaltpharmasave.medmeapp.com-pharmacyId':
    process.env['esquimaltpharmasave.medmeapp.com-pharmacyId'],
  'langfordpharmasave.medmeapp.com-pharmacyId':
    process.env['langfordpharmasave.medmeapp.com-pharmacyId'],
  'hillsidepharmasave.medmeapp.com-pharmacyId':
    process.env['hillsidepharmasave.medmeapp.com-pharmacyId'],
  'sandstonemckenzie.medmeapp.com-pharmacyId':
    process.env['sandstonemckenzie.medmeapp.com-pharmacyId'],
  'sandstonelangdon.medmeapp.com-pharmacyId':
    process.env['sandstonelangdon.medmeapp.com-pharmacyId'],
  'sandstonebraggcreek.medmeapp.com-pharmacyId':
    process.env['sandstonebraggcreek.medmeapp.com-pharmacyId'],
  'sandstonecitycentre.medmeapp.com-pharmacyId':
    process.env['sandstonecitycentre.medmeapp.com-pharmacyId'],
  'sandstoneinnisfail.medmeapp.com-pharmacyId':
    process.env['sandstoneinnisfail.medmeapp.com-pharmacyId'],
  'pharmasavelionsgate.medmeapp.com-pharmacyId':
    process.env['pharmasavelionsgate.medmeapp.com-pharmacyId'],
  'pharmasavemarinedrive.medmeapp.com-pharmacyId':
    process.env['pharmasavemarinedrive.medmeapp.com-pharmacyId'],
  'simpsonsapothecary.medmeapp.com-pharmacyId':
    process.env['simpsonsapothecary.medmeapp.com-pharmacyId'],
  'swiftpharmasave.medmeapp.com-pharmacyId':
    process.env['swiftpharmasave.medmeapp.com-pharmacyId'],
  'pharmasavemartensville.medmeapp.com-pharmacyId':
    process.env['pharmasavemartensville.medmeapp.com-pharmacyId'],
  'pharmasave445.medmeapp.com-pharmacyId':
    process.env['pharmasave445.medmeapp.com-pharmacyId'],
  'msp171.medmeapp.com-pharmacyId':
    process.env['msp171.medmeapp.com-pharmacyId'],
  'ms0112.medmeapp.com-pharmacyId':
    process.env['ms0112.medmeapp.com-pharmacyId'],
  'ms0257.medmeapp.com-pharmacyId':
    process.env['ms0257.medmeapp.com-pharmacyId'],
  'ms0304.medmeapp.com-pharmacyId':
    process.env['ms0304.medmeapp.com-pharmacyId'],
  'ms0407.medmeapp.com-pharmacyId':
    process.env['ms0407.medmeapp.com-pharmacyId'],
  'bridgewaterguardian.medmeapp.com-pharmacyId':
    process.env['bridgewaterguardian.medmeapp.com-pharmacyId'],
  'vanierpharmacy.medmeapp.com-pharmacyId':
    process.env['vanierpharmacy.medmeapp.com-pharmacyId'],
  'sandstonesuncor.medmeapp.com-pharmacyId':
    process.env['sandstonesuncor.medmeapp.com-pharmacyId'],
  'sandstonebrookfield.medmeapp.com-pharmacyId':
    process.env['sandstonebrookfield.medmeapp.com-pharmacyId'],
  'sandstoneineos.medmeapp.com-pharmacyId':
    process.env['sandstoneineos.medmeapp.com-pharmacyId'],
  'sandstonepop.medmeapp.com-pharmacyId':
    process.env['sandstonepop.medmeapp.com-pharmacyId'],
  'sandstonepriddis.medmeapp.com-pharmacyId':
    process.env['sandstonepriddis.medmeapp.com-pharmacyId'],
  'sandstonesilvera.medmeapp.com-pharmacyId':
    process.env['sandstonesilvera.medmeapp.com-pharmacyId'],
  'sandstonevelvet.medmeapp.com-pharmacyId':
    process.env['sandstonevelvet.medmeapp.com-pharmacyId'],
  'clinicpharmacy.medmeapp.com-pharmacyId':
    process.env['clinicpharmacy.medmeapp.com-pharmacyId'],
  'durhampharmacy.medmeapp.com-pharmacyId':
    process.env['durhampharmacy.medmeapp.com-pharmacyId'],
  'medicalpharmacy.medmeapp.com-pharmacyId':
    process.env['medicalpharmacy.medmeapp.com-pharmacyId'],
  'scarboroughmp.medmeapp.com-pharmacyId':
    process.env['scarboroughmp.medmeapp.com-pharmacyId'],
  'collegestmp.medmeapp.com-pharmacyId':
    process.env['collegestmp.medmeapp.com-pharmacyId'],
  'windsormedical.medmeapp.com-pharmacyId':
    process.env['windsormedical.medmeapp.com-pharmacyId'],
  'cornwallmp.medmeapp.com-pharmacyId':
    process.env['cornwallmp.medmeapp.com-pharmacyId'],
  'healthcentremp.medmeapp.com-pharmacyId':
    process.env['healthcentremp.medmeapp.com-pharmacyId'],
  'chelseyparkmp.medmeapp.com-pharmacyId':
    process.env['chelseyparkmp.medmeapp.com-pharmacyId'],
  'byronmp.medmeapp.com-pharmacyId':
    process.env['byronmp.medmeapp.com-pharmacyId'],
  'tauntonmp.medmeapp.com-pharmacyId':
    process.env['tauntonmp.medmeapp.com-pharmacyId'],
  'carlingmp.medmeapp.com-pharmacyId':
    process.env['carlingmp.medmeapp.com-pharmacyId'],
  'smythmp.medmeapp.com-pharmacyId':
    process.env['smythmp.medmeapp.com-pharmacyId'],
  'tmcmp.medmeapp.com-pharmacyId': process.env['tmcmp.medmeapp.com-pharmacyId'],
  'blackspharmacy.medmeapp.com-pharmacyId':
    process.env['blackspharmacy.medmeapp.com-pharmacyId'],
  'stokes.medmeapp.com-pharmacyId':
    process.env['stokes.medmeapp.com-pharmacyId'],
  'hamptonpharmasave.medmeapp.com-pharmacyId':
    process.env['hamptonpharmasave.medmeapp.com-pharmacyId'],
  'pharmasavemoosomin.medmeapp.com-pharmacyId':
    process.env['pharmasavemoosomin.medmeapp.com-pharmacyId'],
  'rcrx.medmeapp.com-pharmacyId': process.env['rcrx.medmeapp.com-pharmacyId'],
  'hybridpharm.medmeapp.com-pharmacyId':
    process.env['hybridpharm.medmeapp.com-pharmacyId'],
  'assiniboinepharmacy.medmeapp.com-pharmacyId':
    process.env['assiniboinepharmacy.medmeapp.com-pharmacyId'],
  'arvinject.medmeapp.com-pharmacyId':
    process.env['arvinject.medmeapp.com-pharmacyId'],
  'cgfultontata.medmeapp.com-pharmacyId':
    process.env['cgfultontata.medmeapp.com-pharmacyId'],
  'grandmedicinepharmacy.medmeapp.com-pharmacyId':
    process.env['grandmedicinepharmacy.medmeapp.com-pharmacyId'],
  'lundarpharmacy.medmeapp.com-pharmacyId':
    process.env['lundarpharmacy.medmeapp.com-pharmacyId'],
  'pharmasavemorden.medmeapp.com-pharmacyId':
    process.env['pharmasavemorden.medmeapp.com-pharmacyId'],
  'ashernpharmacy.medmeapp.com-pharmacyId':
    process.env['ashernpharmacy.medmeapp.com-pharmacyId'],
  'pharmasavebrandonclinic.medmeapp.com-pharmacyId':
    process.env['pharmasavebrandonclinic.medmeapp.com-pharmacyId'],
  'pharmasavebrandon.medmeapp.com-pharmacyId':
    process.env['pharmasavebrandon.medmeapp.com-pharmacyId'],
  'pharmasaveflinflonhc.medmeapp.com-pharmacyId':
    process.env['pharmasaveflinflonhc.medmeapp.com-pharmacyId'],
  'pharmasaveflinflon.medmeapp.com-pharmacyId':
    process.env['pharmasaveflinflon.medmeapp.com-pharmacyId'],
  'pharmasavegimli.medmeapp.com-pharmacyId':
    process.env['pharmasavegimli.medmeapp.com-pharmacyId'],
  'taylorpharmacy.medmeapp.com-pharmacyId':
    process.env['taylorpharmacy.medmeapp.com-pharmacyId'],
  'gladstonepharmacy.medmeapp.com-pharmacyId':
    process.env['gladstonepharmacy.medmeapp.com-pharmacyId'],
  'pinefallscommunity.medmeapp.com-pharmacyId':
    process.env['pinefallscommunity.medmeapp.com-pharmacyId'],
  'pinefallspharmacy.medmeapp.com-pharmacyId':
    process.env['pinefallspharmacy.medmeapp.com-pharmacyId'],
  'swanhealthcarepharmacy.medmeapp.com-pharmacyId':
    process.env['swanhealthcarepharmacy.medmeapp.com-pharmacyId'],
  'scalesdrugstore.medmeapp.com-pharmacyId':
    process.env['scalesdrugstore.medmeapp.com-pharmacyId'],
  'positivehealthpharmacy.medmeapp.com-pharmacyId':
    process.env['positivehealthpharmacy.medmeapp.com-pharmacyId'],
  'regentparkpharmacy.medmeapp.com-pharmacyId':
    process.env['regentparkpharmacy.medmeapp.com-pharmacyId'],
  'meadowoodpharmacy.medmeapp.com-pharmacyId':
    process.env['meadowoodpharmacy.medmeapp.com-pharmacyId'],
  'battlefordremedysrx.medmeapp.com-pharmacyId':
    process.env['battlefordremedysrx.medmeapp.com-pharmacyId'],
  'pharmasavebiggar.medmeapp.com-pharmacyId':
    process.env['pharmasavebiggar.medmeapp.com-pharmacyId'],
  'quaylespharmacy.medmeapp.com-pharmacyId':
    process.env['quaylespharmacy.medmeapp.com-pharmacyId'],
  'pharmasavebroadview.medmeapp.com-pharmacyId':
    process.env['pharmasavebroadview.medmeapp.com-pharmacyId'],
  'canorapharmacy.medmeapp.com-pharmacyId':
    process.env['canorapharmacy.medmeapp.com-pharmacyId'],
  'carnduffdrugmart.medmeapp.com-pharmacyId':
    process.env['carnduffdrugmart.medmeapp.com-pharmacyId'],
  'carrotriverpharmacy.medmeapp.com-pharmacyId':
    process.env['carrotriverpharmacy.medmeapp.com-pharmacyId'],
  'midwaypharmacy.medmeapp.com-pharmacyId':
    process.env['midwaypharmacy.medmeapp.com-pharmacyId'],
  'pharmasaveesterhazy.medmeapp.com-pharmacyId':
    process.env['pharmasaveesterhazy.medmeapp.com-pharmacyId'],
  'saparasdrugmart.medmeapp.com-pharmacyId':
    process.env['saparasdrugmart.medmeapp.com-pharmacyId'],
  'pharmasaveestevan.medmeapp.com-pharmacyId':
    process.env['pharmasaveestevan.medmeapp.com-pharmacyId'],
  'pharmasavefortquappelle.medmeapp.com-pharmacyId':
    process.env['pharmasavefortquappelle.medmeapp.com-pharmacyId'],
  'valleydrugmart.medmeapp.com-pharmacyId':
    process.env['valleydrugmart.medmeapp.com-pharmacyId'],
  'pharmasavehumboldt.medmeapp.com-pharmacyId':
    process.env['pharmasavehumboldt.medmeapp.com-pharmacyId'],
  'sametzpharmacy.medmeapp.com-pharmacyId':
    process.env['sametzpharmacy.medmeapp.com-pharmacyId'],
  'kamsackpharmacy.medmeapp.com-pharmacyId':
    process.env['kamsackpharmacy.medmeapp.com-pharmacyId'],
  'pharmasavekindersley.medmeapp.com-pharmacyId':
    process.env['pharmasavekindersley.medmeapp.com-pharmacyId'],
  'balfourdrugs.medmeapp.com-pharmacyId':
    process.env['balfourdrugs.medmeapp.com-pharmacyId'],
  'pharmasavelangenburg.medmeapp.com-pharmacyId':
    process.env['pharmasavelangenburg.medmeapp.com-pharmacyId'],
  'madillsdrugs.medmeapp.com-pharmacyId':
    process.env['madillsdrugs.medmeapp.com-pharmacyId'],
  'pharmasavemelfort.medmeapp.com-pharmacyId':
    process.env['pharmasavemelfort.medmeapp.com-pharmacyId'],
  'royaldrugmart.medmeapp.com-pharmacyId':
    process.env['royaldrugmart.medmeapp.com-pharmacyId'],
  'pharmasavemoosejaw.medmeapp.com-pharmacyId':
    process.env['pharmasavemoosejaw.medmeapp.com-pharmacyId'],
  'pharmasavelillooet.medmeapp.com-pharmacyId':
    process.env['pharmasavelillooet.medmeapp.com-pharmacyId'],
  'pharmasavenaicam.medmeapp.com-pharmacyId':
    process.env['pharmasavenaicam.medmeapp.com-pharmacyId'],
  'pharmasavenipawin.medmeapp.com-pharmacyId':
    process.env['pharmasavenipawin.medmeapp.com-pharmacyId'],
  'davisrexalldrugs.medmeapp.com-pharmacyId':
    process.env['davisrexalldrugs.medmeapp.com-pharmacyId'],
  'spencerspharmacy.medmeapp.com-pharmacyId':
    process.env['spencerspharmacy.medmeapp.com-pharmacyId'],
  'pharmasaveponteix.medmeapp.com-pharmacyId':
    process.env['pharmasaveponteix.medmeapp.com-pharmacyId'],
  'crescentheightspharmacy.medmeapp.com-pharmacyId':
    process.env['crescentheightspharmacy.medmeapp.com-pharmacyId'],
  'mypharmacy.medmeapp.com-pharmacyId':
    process.env['mypharmacy.medmeapp.com-pharmacyId'],
  'pharmasaveprincealbert.medmeapp.com-pharmacyId':
    process.env['pharmasaveprincealbert.medmeapp.com-pharmacyId'],
  'pharmasaveradville.medmeapp.com-pharmacyId':
    process.env['pharmasaveradville.medmeapp.com-pharmacyId'],
  'queencitywellness.medmeapp.com-pharmacyId':
    process.env['queencitywellness.medmeapp.com-pharmacyId'],
  'wellnesspharmacycanterbury.medmeapp.com-pharmacyId':
    process.env['wellnesspharmacycanterbury.medmeapp.com-pharmacyId'],
  'pharmasaveglencairn.medmeapp.com-pharmacyId':
    process.env['pharmasaveglencairn.medmeapp.com-pharmacyId'],
  'pioneervillagepharmacy.medmeapp.com-pharmacyId':
    process.env['pioneervillagepharmacy.medmeapp.com-pharmacyId'],
  'universityparkpharmacy.medmeapp.com-pharmacyId':
    process.env['universityparkpharmacy.medmeapp.com-pharmacyId'],
  'rubiconhealthsolutions.medmeapp.com-pharmacyId':
    process.env['rubiconhealthsolutions.medmeapp.com-pharmacyId'],
  'pharmasaverosetown.medmeapp.com-pharmacyId':
    process.env['pharmasaverosetown.medmeapp.com-pharmacyId'],
  'pharmasavesaskatoon.medmeapp.com-pharmacyId':
    process.env['pharmasavesaskatoon.medmeapp.com-pharmacyId'],
  'mayfairdrugs.medmeapp.com-pharmacyId':
    process.env['mayfairdrugs.medmeapp.com-pharmacyId'],
  'mountroyaldrugs.medmeapp.com-pharmacyId':
    process.env['mountroyaldrugs.medmeapp.com-pharmacyId'],
  'pharmasavecentralave.medmeapp.com-pharmacyId':
    process.env['pharmasavecentralave.medmeapp.com-pharmacyId'],
  'earlspharmacy.medmeapp.com-pharmacyId':
    process.env['earlspharmacy.medmeapp.com-pharmacyId'],
  'pharmasaveidylwyld.medmeapp.com-pharmacyId':
    process.env['pharmasaveidylwyld.medmeapp.com-pharmacyId'],
  'pharmasavekenderdine.medmeapp.com-pharmacyId':
    process.env['pharmasavekenderdine.medmeapp.com-pharmacyId'],
  'pharmasavestonebridge.medmeapp.com-pharmacyId':
    process.env['pharmasavestonebridge.medmeapp.com-pharmacyId'],
  'shoallake.medmeapp.com-pharmacyId':
    process.env['shoallake.medmeapp.com-pharmacyId'],
  'smeaton.medmeapp.com-pharmacyId':
    process.env['smeaton.medmeapp.com-pharmacyId'],
  'stwalburgpharmacy.medmeapp.com-pharmacyId':
    process.env['stwalburgpharmacy.medmeapp.com-pharmacyId'],
  'diamondcentrepharmacy.medmeapp.com-pharmacyId':
    process.env['diamondcentrepharmacy.medmeapp.com-pharmacyId'],
  'pharmasavewatson.medmeapp.com-pharmacyId':
    process.env['pharmasavewatson.medmeapp.com-pharmacyId'],
  'pharmasaveweyburn.medmeapp.com-pharmacyId':
    process.env['pharmasaveweyburn.medmeapp.com-pharmacyId'],
  'weyburnhealthcentrepharmacy.medmeapp.com-pharmacyId':
    process.env['weyburnhealthcentrepharmacy.medmeapp.com-pharmacyId'],
  'pharmasavewhitewood.medmeapp.com-pharmacyId':
    process.env['pharmasavewhitewood.medmeapp.com-pharmacyId'],
  'louckspharmacy.medmeapp.com-pharmacyId':
    process.env['louckspharmacy.medmeapp.com-pharmacyId'],
  'guardianbeachpharmacy.medmeapp.com-pharmacyId':
    process.env['guardianbeachpharmacy.medmeapp.com-pharmacyId'],
  'ritasapothecary.medmeapp.com-pharmacyId':
    process.env['ritasapothecary.medmeapp.com-pharmacyId'],
  'tellierpharmacy.medmeapp.com-pharmacyId':
    process.env['tellierpharmacy.medmeapp.com-pharmacyId'],
  'pharmasavecarstairs.medmeapp.com-pharmacyId':
    process.env['pharmasavecarstairs.medmeapp.com-pharmacyId'],
  'pharmasavedidsbury.medmeapp.com-pharmacyId':
    process.env['pharmasavedidsbury.medmeapp.com-pharmacyId'],
  'primroseidapharmacy.medmeapp.com-pharmacyId':
    process.env['primroseidapharmacy.medmeapp.com-pharmacyId'],
  'fournierdrugs.medmeapp.com-pharmacyId':
    process.env['fournierdrugs.medmeapp.com-pharmacyId'],
  'preferredrxpharmacy.medmeapp.com-pharmacyId':
    process.env['preferredrxpharmacy.medmeapp.com-pharmacyId'],
  'onowaypharmacy.medmeapp.com-pharmacyId':
    process.env['onowaypharmacy.medmeapp.com-pharmacyId'],
  'pharmasaverimbey.medmeapp.com-pharmacyId':
    process.env['pharmasaverimbey.medmeapp.com-pharmacyId'],
  'rimbeydrugmart.medmeapp.com-pharmacyId':
    process.env['rimbeydrugmart.medmeapp.com-pharmacyId'],
  'alpinedrugmart.medmeapp.com-pharmacyId':
    process.env['alpinedrugmart.medmeapp.com-pharmacyId'],
  'stonyplainpharmacy.medmeapp.com-pharmacyId':
    process.env['stonyplainpharmacy.medmeapp.com-pharmacyId'],
  'vegrevilledrugmart.medmeapp.com-pharmacyId':
    process.env['vegrevilledrugmart.medmeapp.com-pharmacyId'],
  'lakecountryremedysrx.medmeapp.com-pharmacyId':
    process.env['lakecountryremedysrx.medmeapp.com-pharmacyId'],
  'nanoosepharmacy.medmeapp.com-pharmacyId':
    process.env['nanoosepharmacy.medmeapp.com-pharmacyId'],
  'peachlandpharmacy.medmeapp.com-pharmacyId':
    process.env['peachlandpharmacy.medmeapp.com-pharmacyId'],
  'ragespharmacy.medmeapp.com-pharmacyId':
    process.env['ragespharmacy.medmeapp.com-pharmacyId'],
  'mcpharmacy.medmeapp.com-pharmacyId':
    process.env['mcpharmacy.medmeapp.com-pharmacyId'],
  'qualicumpharmacy.medmeapp.com-pharmacyId':
    process.env['qualicumpharmacy.medmeapp.com-pharmacyId'],
  'pharmasavesooke.medmeapp.com-pharmacyId':
    process.env['pharmasavesooke.medmeapp.com-pharmacyId'],
  'pharmasavegardinerpark.medmeapp.com-pharmacyId':
    process.env['pharmasavegardinerpark.medmeapp.com-pharmacyId'],
  'harbourpharmacy.medmeapp.com-pharmacyId':
    process.env['harbourpharmacy.medmeapp.com-pharmacyId'],
  'pharmasaveswiftcurrent.medmeapp.com-pharmacyId':
    process.env['pharmasaveswiftcurrent.medmeapp.com-pharmacyId'],
  'pharmasaveindianhead.medmeapp.com-pharmacyId':
    process.env['pharmasaveindianhead.medmeapp.com-pharmacyId'],
  'wynyardpharmacy.medmeapp.com-pharmacyId':
    process.env['wynyardpharmacy.medmeapp.com-pharmacyId'],
  'pharmasaveholyrood.medmeapp.com-pharmacyId':
    process.env['pharmasaveholyrood.medmeapp.com-pharmacyId'],
  'travelhealthexperts.medmeapp.com-pharmacyId':
    process.env['travelhealthexperts.medmeapp.com-pharmacyId'],
  'fallowfieldpharmasave.medmeapp.com-pharmacyId':
    process.env['fallowfieldpharmasave.medmeapp.com-pharmacyId'],
  'greenstreetpharmasave.medmeapp.com-pharmacyId':
    process.env['greenstreetpharmasave.medmeapp.com-pharmacyId'],
  'howesoundpharmacy.medmeapp.com-pharmacyId':
    process.env['howesoundpharmacy.medmeapp.com-pharmacyId'],
  'villagefamilypharmachoice.medmeapp.com-pharmacyId':
    process.env['villagefamilypharmachoice.medmeapp.com-pharmacyId'],
  'cgfultonriverjohn.medmeapp.com-pharmacyId':
    process.env['cgfultonriverjohn.medmeapp.com-pharmacyId'],
  'blackdiamondnw.medmeapp.com-pharmacyId':
    process.env['blackdiamondnw.medmeapp.com-pharmacyId'],
  'cahoonspharmacy.medmeapp.com-pharmacyId':
    process.env['cahoonspharmacy.medmeapp.com-pharmacyId'],
  'msp117.medmeapp.com-pharmacyId':
    process.env['msp117.medmeapp.com-pharmacyId'],
  'kingswoodpharmacy.medmeapp.com-pharmacyId':
    process.env['kingswoodpharmacy.medmeapp.com-pharmacyId'],
  'boltonpharmasave.medmeapp.com-pharmacyId':
    process.env['boltonpharmasave.medmeapp.com-pharmacyId'],
  'polarisexternal.medmeapp.com-pharmacyId':
    process.env['polarisexternal.medmeapp.com-pharmacyId'],
  'msp190.medmeapp.com-pharmacyId':
    process.env['msp190.medmeapp.com-pharmacyId'],
  'loyalist.medmeapp.com-pharmacyId':
    process.env['loyalist.medmeapp.com-pharmacyId'],
  'kennedymediplex.medmeapp.com-pharmacyId':
    process.env['kennedymediplex.medmeapp.com-pharmacyId'],
  'londonmediplex.medmeapp.com-pharmacyId':
    process.env['londonmediplex.medmeapp.com-pharmacyId'],
  'villagesquarepharmacy.medmeapp.com-pharmacyId':
    process.env['villagesquarepharmacy.medmeapp.com-pharmacyId'],
  'msp271.medmeapp.com-pharmacyId':
    process.env['msp271.medmeapp.com-pharmacyId'],
  'lancasterwellnesspharmacy.medmeapp.com-pharmacyId':
    process.env['lancasterwellnesspharmacy.medmeapp.com-pharmacyId'],
  'jchealthottawa.medmeapp.com-pharmacyId':
    process.env['jchealthottawa.medmeapp.com-pharmacyId'],
  'jchealthtoronto.medmeapp.com-pharmacyId':
    process.env['jchealthtoronto.medmeapp.com-pharmacyId'],
  'jchealthgatineau.medmeapp.com-pharmacyId':
    process.env['jchealthgatineau.medmeapp.com-pharmacyId'],
  'myeasydose.medmeapp.com-pharmacyId':
    process.env['myeasydose.medmeapp.com-pharmacyId'],
  'msp383.medmeapp.com-pharmacyId':
    process.env['msp383.medmeapp.com-pharmacyId'],
  'msp341.medmeapp.com-pharmacyId':
    process.env['msp341.medmeapp.com-pharmacyId'],
  'jchealthtelemedicine.medmeapp.com-pharmacyId':
    process.env['jchealthtelemedicine.medmeapp.com-pharmacyId'],
  'medicineshoppe103.medmeapp.com-pharmacyId':
    process.env['medicineshoppe103.medmeapp.com-pharmacyId'],
  'medicineshoppe387.medmeapp.com-pharmacyId':
    process.env['medicineshoppe387.medmeapp.com-pharmacyId'],
  'extendpharmacy.medmeapp.com-pharmacyId':
    process.env['extendpharmacy.medmeapp.com-pharmacyId'],
  'sandstoneblackdiamond.medmeapp.com-pharmacyId':
    process.env['sandstoneblackdiamond.medmeapp.com-pharmacyId'],
  'msp188.medmeapp.com-pharmacyId':
    process.env['msp188.medmeapp.com-pharmacyId'],
  'bentopharmacy.medmeapp.com-pharmacyId':
    process.env['bentopharmacy.medmeapp.com-pharmacyId'],
  'enhancedcare.medmeapp.com-pharmacyId':
    process.env['enhancedcare.medmeapp.com-pharmacyId'],
  'pharmasavebrentwoodbay.medmeapp.com-pharmacyId':
    process.env['pharmasavebrentwoodbay.medmeapp.com-pharmacyId'],
  'pans.medmeapp.com-pharmacyId': process.env['pans.medmeapp.com-pharmacyId'],
  'medicineshoppemontgomery.medmeapp.com-pharmacyId':
    process.env['medicineshoppemontgomery.medmeapp.com-pharmacyId'],
  'medicineshoppebowness.medmeapp.com-pharmacyId':
    process.env['medicineshoppebowness.medmeapp.com-pharmacyId'],
  'medshoppe114.medmeapp.com-pharmacyId':
    process.env['medshoppe114.medmeapp.com-pharmacyId'],
  'medicineshoppe266.medmeapp.com-pharmacyId':
    process.env['medicineshoppe266.medmeapp.com-pharmacyId'],
  'johnstoneida.medmeapp.com-pharmacyId':
    process.env['johnstoneida.medmeapp.com-pharmacyId'],
  'medicineshoppe397.medmeapp.com-pharmacyId':
    process.env['medicineshoppe397.medmeapp.com-pharmacyId'],
  'jchealthsurrey.medmeapp.com-pharmacyId':
    process.env['jchealthsurrey.medmeapp.com-pharmacyId'],
  'adamsdrugstore.medmeapp.com-pharmacyId':
    process.env['adamsdrugstore.medmeapp.com-pharmacyId'],
  'devus.medmeapp.com-pharmacyId': process.env['devus.medmeapp.com-pharmacyId'],
  'bjrxpharmacyltc.medmeapp.com-pharmacyId':
    process.env['bjrxpharmacyltc.medmeapp.com-pharmacyId'],
  'inclusivecareidaho-dev.medmeapp.com-pharmacyId':
    process.env['inclusivecareidaho-dev.medmeapp.com-pharmacyId'],
  'inclusivecareidaho-dev.medmeapp.local-pharmacyId':
    process.env['inclusivecareidaho-dev.medmeapp.com-pharmacyId'],
  'inclusivecareidaho.medmeapp.com-pharmacyId':
    process.env['inclusivecareidaho.medmeapp.com-pharmacyId'],
  'savewaymarket.medmeapp.com-pharmacyId':
    process.env['savewaymarket.medmeapp.com-pharmacyId'],
  ...pharmacyIdsMedmeIo,
  ...pharmacyIdsMedmeSandboxCom,
  ...pharmacyIdsMedmeappCa,
  ...pharmacyIdsFeature,
  ...pharmacyIdsStagingMedmeappCom,
} as { [key: string]: string }
