import React from 'react'
import { DateField } from '~/controlledContextInputs'
import { FhirLeafComponent } from '../FhirLeafComponent'

export const FhirDateInput: typeof FhirLeafComponent = ({
  isDisabled,
  questionnaireItem,
}) => {
  return (
    <DateField
      name={questionnaireItem.linkId}
      label={questionnaireItem?.text ?? ''}
      disabled={isDisabled}
      required={questionnaireItem.required}
    />
  )
}
