import React, { CSSProperties } from 'react'
import { SvgIconProps } from '@material-ui/core'
import {
  CheckCircleOutline,
  InfoOutlined,
  ErrorOutlineRounded,
} from '@material-ui/icons'
import { AnimatedLoadingIcon } from '../Icon/AnimatedLoadingIcon'
import { ExtendedAlertSeverity, ExtendedAlertSize } from './Alert'

export interface AlertIconProps extends SvgIconProps {
  size?: ExtendedAlertSize
  severity: ExtendedAlertSeverity
}

/**
 * Predefined icons and icon styling based on alert severity
 * Used internally by MedMe Alert component by default
 * Includes ability to adjust styling based on 'size' props
 */
export const AlertIcon: React.FC<AlertIconProps> = ({
  severity,
  size = 'medium',
  ...props
}) => {
  const sizeStyles: Record<ExtendedAlertSize, CSSProperties> = {
    small: {},
    medium: {},
    large: {
      fontSize: '24px',
    },
  }

  const sharedStyles: CSSProperties = { ...sizeStyles[size] }
  switch (severity) {
    case 'loading':
      return <AnimatedLoadingIcon style={{ ...sharedStyles }} {...props} />
    case 'success':
      return <CheckCircleOutline style={{ ...sharedStyles }} {...props} />
    case 'error':
    case 'warning':
      return <ErrorOutlineRounded style={{ ...sharedStyles }} {...props} />
    case 'info':
    default:
      return <InfoOutlined style={{ ...sharedStyles }} {...props} />
  }
}
