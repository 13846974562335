import React, { forwardRef } from 'react'
import dayjs from 'dayjs'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

import { StyledLayout, StyledTitle } from './DetailsWidget.styles'
import tz from 'dayjs/plugin/timezone'
import {
  DetailsWidgetContent,
  DetailsWidgetContentProps,
} from './DetailsWidgetContent'

dayjs.extend(tz)

interface DetailsWidgetProps extends DetailsWidgetContentProps {
  desktopRef: React.RefObject<HTMLDivElement>
}

const ChildrenWithRefWrapper = forwardRef<
  HTMLDivElement,
  Pick<DetailsWidgetProps, 'children'>
>(({ children }, ref) => {
  return <div ref={ref}>{children}</div>
})

export function DetailsWidgetDesktop(props: DetailsWidgetProps) {
  const { t } = useMedMeTranslation('patient')
  const { children, desktopRef, ...context } = props

  return (
    <StyledLayout>
      <StyledTitle>{t('detailsWidget.title')}</StyledTitle>
      <DetailsWidgetContent {...context}>
        <ChildrenWithRefWrapper ref={desktopRef}>
          {children}
        </ChildrenWithRefWrapper>
      </DetailsWidgetContent>
    </StyledLayout>
  )
}
