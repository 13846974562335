import * as React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import useYupValidationResolverHook from '~/hooks/yupValidationResolverHook'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'

export const useFormTimeslotSelection = () => {
  const {
    state: {
      initial: { appointment },
    },
  } = useBookingContext()
  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        isWalkin: yup.string(),
        startDateTime: yup
          .string()
          .nullable()
          .required('Please select a date and time for your appointment'),
      }),
    []
  )

  const resolver = useYupValidationResolverHook(validationSchema)

  const defaultValues = {
    noOfPeople: appointment.noOfPeople,
    startDateTime: '',
    endDateTime: '',
    resourceId: '',
  }

  return useForm<typeof defaultValues>({
    resolver,
    defaultValues: defaultValues,
    shouldUnregister: false,
  })
}
