import { Extension } from 'fhir/r5'
import {
  ExtensionStylingGridColumn,
  stylingGridColumnCodes,
} from './styling.type'

export const isExtensionStylingGridColumn = (
  extension: Extension
): extension is ExtensionStylingGridColumn => {
  return (
    extension.url === 'gridColumn' &&
    (stylingGridColumnCodes as ReadonlyArray<string>).includes(
      extension?.valueCode ?? ''
    ) &&
    Object.keys(extension).length === 2
  )
}
