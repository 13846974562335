import React from 'react'
import { Controller } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Layout, TripleRadioGroup } from './tripleRadio.styles'

import { RadioInput, InputLabel } from '../../patientRegistration.styles'

const direction = {
  top: 'Top',
  end: 'Right',
  bottom: 'Bottom',
  start: 'Left',
}

const getDirection = (placement) => {
  if (!placement) return 'Right'
  return direction[placement]
}

const TripleRadio = ({
  className,
  name,
  control,
  styles = {},
  error,
  helperText,
  labelStyles = {},
  ...rest
}) => (
  <Layout className={className} {...styles}>
    <Controller
      as={
        <TripleRadioGroup>
          <FormControlLabel
            value="true"
            control={
              <RadioInput
                style={{
                  padding: 0,
                  [`margin${getDirection(labelStyles.placement)}`]: '7px',
                }}
                color="default"
              />
            }
            label={<InputLabel color={labelStyles.color || ''}>Yes</InputLabel>}
            labelPlacement={labelStyles.placement || 'end'}
            style={{ margin: 0, marginRight: '10px' }}
          />
          <FormControlLabel
            value="false"
            control={
              <RadioInput
                style={{
                  padding: 0,
                  margin: 0,
                  [`margin${getDirection(labelStyles.placement)}`]: '7px',
                }}
                color="default"
              />
            }
            label={<InputLabel color={labelStyles.color || ''}>No</InputLabel>}
            labelPlacement={labelStyles.placement || 'end'}
            style={{ margin: 0, marginRight: '10px' }}
          />
          <FormControlLabel
            value="null"
            control={
              <RadioInput
                style={{
                  padding: 0,
                  margin: 0,
                  [`margin${getDirection(labelStyles.placement)}`]: '7px',
                }}
                color="default"
              />
            }
            label={
              <InputLabel color={labelStyles.color || ''}>Unsure</InputLabel>
            }
            labelPlacement={labelStyles.placement || 'end'}
            style={{ margin: 0 }}
          />
        </TripleRadioGroup>
      }
      name={name}
      control={control}
      {...rest}
    />
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </Layout>
)

export default TripleRadio
