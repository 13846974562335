import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Caption } from '../../patientRegistration.styles'
import { formGray } from '~/sharedStyles/colorPalette'

export const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`

export const RadioGroupContainer = styled(RadioGroup)`
  flex-shrink: 0;
  display: ${({ display }) => display || 'grid'};
  grid-row-gap: 0.5rem;
  flex: ${({ flex }) => flex || 0};
  width: ${({ width }) => width || '100%'};
`

export const RadioSubLabel = styled(Caption)`
  color: ${formGray};
  margin-left: 2rem;
`
