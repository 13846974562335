import { TWeekDay } from '~/types/timeTypes'

export const WEEKDAYS_SHORT: TWeekDay[] = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
]

export const DATE_VALUE_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT_LONG = 'ddd, MMM. Do, YYYY'

export const TIME_VALUE_FORMAT = 'HH:mm:ss'
export const TIME_VALUE_FORMAT_WITHOUT_SECONDS = 'HH:mm'
export const TIME_VALUE_FORMATS_LIST = [
  TIME_VALUE_FORMAT,
  TIME_VALUE_FORMAT_WITHOUT_SECONDS,
]
export const TIME_DISPLAY_FORMAT = 'h:mm a'
export const TIME_DISPLAY_FORMAT_WITHOUT_SPACE = 'h:mma'

export const MIN_ALLOWED_BIRTHDATE = '1890-01-01'

export const MONDAY_START_LOCALES = ['fr']
