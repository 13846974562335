import { HttpLink } from '@apollo/client'

const javaServerLink = new HttpLink({
  uri: `${process.env.GRAPHQL_ENDPOINT}/graphql`,
})

const nodeServerLink = new HttpLink({
  uri: `${process.env.NODE_GRAPHQL_ENDPOINT}/graphql`,
})

const nodeServerKeepAliveLink = new HttpLink({
  uri: `${process.env.NODE_GRAPHQL_ENDPOINT}/graphql`,
  fetchOptions: {
    keepalive: true,
  },
})

export enum EServer {
  JAVA,
  NODE,
}
export const httpLink = () =>
  HttpLink.split(
    (operation) => operation.getContext().clientName === EServer.NODE,
    HttpLink.split(
      (operation) => operation.getContext().keepalive,
      nodeServerKeepAliveLink,
      nodeServerLink
    ),
    javaServerLink
  )
