import React from 'react'

import { Pharmacy as PharmacyGql } from '~/graphql/types/schema.type'
import { Pharmacy } from '~/lib/pharmacy'
import { DeepPartial } from '~/pages/AppointmentIntake/lib/types'
import { CancelButton } from '~/pages/patientRegistrationV2/layoutWrapper/components'

import {
  AddressSection,
  Header,
  HeaderImageContainer,
  Image,
} from './LayoutWrapper.style'
import useMediaHook from '~/hooks/mediaHook'
import { transformToStandardPhoneNumber } from '~/util/formatContact'
import { PatientLanguageSelector } from '../PatientLanguageSelector'
import { useRouteMatch } from 'react-router-dom'
import { oldBookingPaths } from '~/routes/AppointmentIntakeRoutes'

type TProps = {
  logo: string
  pharmacy?: DeepPartial<PharmacyGql> | Pharmacy
  showAddress?: boolean
  isLastForm?: boolean
}

export const HeaderWrapper = ({
  logo,
  pharmacy,
  showAddress = false,
  isLastForm = false,
}: TProps) => {
  const deviceType = useMediaHook(
    // Most xl phone sizes under 500px, 1024px is ipad pro size, largest tablet?
    ['(max-width: 500px)', '(max-width: 1065px)'],
    ['phone', 'tablet'],
    'desktop'
  )

  const formattedPhone = transformToStandardPhoneNumber(
    pharmacy?.pharmacyContact?.phone
  )

  const showLanguageSelector = useRouteMatch([
    // Added hardcoded path instead of oldBookingPaths.activity in order to exclude
    // language selector from the activity form steps
    '/:storeNo?/public/activity/:activity/appointment',
    oldBookingPaths.activityGroups,
    oldBookingPaths.activity,
    oldBookingPaths.groups,
    oldBookingPaths.publicActivity,
    '/:storeNo?/schedule',
  ])

  return (
    <Header>
      <HeaderImageContainer shrink={showAddress} href="/schedule">
        <Image src={logo} alt="Logo" />
      </HeaderImageContainer>
      <CancelButton isLastForm={isLastForm} />

      {showAddress && pharmacy && (
        <AddressSection pharmacy={pharmacy}>
          {deviceType !== 'desktop' ? (
            <>
              {pharmacy.name} <br />
              {pharmacy.pharmacyAddress?.streetNumber}{' '}
              {pharmacy.pharmacyAddress?.streetName}
              <br />
              {pharmacy.pharmacyAddress?.city}{' '}
              {pharmacy.pharmacyAddress?.province}{' '}
              {pharmacy.pharmacyAddress?.postalCode}{' '}
              {pharmacy.pharmacyAddress?.unit &&
                `(${pharmacy.pharmacyAddress?.unit})`}{' '}
              <br />
              {formattedPhone}
            </>
          ) : (
            <>
              {pharmacy.name && `${pharmacy.name} | `}
              {pharmacy.pharmacyAddress?.streetNumber}{' '}
              {pharmacy.pharmacyAddress?.streetName &&
                `${pharmacy.pharmacyAddress?.streetName}, `}
              {pharmacy.pharmacyAddress?.city}{' '}
              {pharmacy.pharmacyAddress?.province}{' '}
              {pharmacy.pharmacyAddress?.postalCode}
              {' | '}
              {pharmacy.pharmacyAddress?.unit &&
                `(${pharmacy.pharmacyAddress?.unit}) |`}{' '}
              {formattedPhone}
            </>
          )}
        </AddressSection>
      )}
      {showLanguageSelector?.isExact && (
        <PatientLanguageSelector pharmacyId={pharmacy?.id} />
      )}
    </Header>
  )
}
