import { RuleSerializable } from 'json-rules-engine'
import { createRequired } from '../events'

export const createRequiredRule = (fieldName: string): RuleSerializable => {
  return {
    name: `${fieldName}Required`,
    conditions: {
      all: [
        {
          fact: fieldName,
          operator: 'notEqual',
          value: '',
        },
      ],
    },
    event: createRequired(fieldName),
    priority: 10,
  }
}
