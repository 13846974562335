import * as React from 'react'

import { Appointment } from '~/lib/appointment'
import { DeepPartial } from '~/pages/AppointmentIntake/lib'
import { AppointmentContext } from '../AppointmentContext'

export const useAppointmentContext = () => {
  const context = React.useContext(AppointmentContext)
  if (context === undefined) {
    throw new Error(
      'useAppointmentContext must be used within a AppointmentProvider'
    )
  }
  const { dispatch, ...state } = context

  // TODO: since this is a deep partial, might be worth adding unit tests to make sure partials
  // don't result in weird states
  const updateAppointment = React.useCallback(
    (appointment: DeepPartial<Appointment>) => {
      dispatch({
        type: 'updateAppointment',
        appointment: appointment,
      })
    },
    [dispatch]
  )

  return {
    ...state,
    updateAppointment,
  }
}
