import { NestedCondition, TopLevelCondition } from 'json-rules-engine'
import {
  ExtensionValidationConditionGroup,
  isExtensionValidationBehaviour,
  isExtensionValidationConditionList,
} from '~/lib/fhir/extensions/validation'
import { isExtensionValidationConditionGroupList } from '~/lib/fhir/extensions/validation/typeGuards/isExtensionValidationConditionGroupList'
import { toCreateRuleConditionFromValidationCondition } from './toCreateRuleConditionFromValidationCondition'

type CreateTopLevelRuleConditionSignature = (fact: string) => TopLevelCondition

export const toCreateTopLevelRuleConditionFromValidationConditionGroup = (
  ext: ExtensionValidationConditionGroup
): CreateTopLevelRuleConditionSignature => {
  const behaviourExt = ext.extension.find(isExtensionValidationBehaviour)
  const conditionListExt = ext.extension.find(
    isExtensionValidationConditionList
  )
  const conditionGroupListExt = ext.extension.find(
    isExtensionValidationConditionGroupList
  )

  if (
    behaviourExt === undefined ||
    (conditionListExt === undefined && conditionGroupListExt === undefined)
  ) {
    throw new Error('missing extension')
  }

  const createTopLevelConditionFn = (
    fns: ((fact: string) => NestedCondition)[]
  ): CreateTopLevelRuleConditionSignature => {
    if (fns.length === 0) {
      throw new Error(
        'toCreateTopLevelRuleConditionFromValidationConditionGroup: cannot accept an array of empty functions'
      )
    }
    switch (behaviourExt.valueCode) {
      case 'all':
        return (fact: string) => ({
          all: fns.map((fn) => fn(fact)),
        })
      case 'any':
        return (fact: string) => ({
          any: fns.map((fn) => fn(fact)),
        })
      default:
        throw new Error(
          'toCreateTopLevelRuleConditionFromValidationConditionGroup: invalid behaviour valueCode'
        )
    }
  }

  const ruleConditions =
    conditionListExt?.extension.map((ext) =>
      toCreateRuleConditionFromValidationCondition(ext)
    ) ?? []
  const topLevelRuleConditions =
    conditionGroupListExt?.extension.map((ext) =>
      toCreateTopLevelRuleConditionFromValidationConditionGroup(ext)
    ) ?? []

  return createTopLevelConditionFn([
    ...ruleConditions,
    ...topLevelRuleConditions,
  ])
}
