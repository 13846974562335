import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../types'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Emergency Screening',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'If you are experiencing a medical emergency, call 9-1-1 or go to your nearest hospital emergency room.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })

  const erectileDysfunction = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 18 years old
- No previous diagnosis of erectile dysfunction by a medical doctor`,
    linkId: 'erectileDysfunction',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
  })

  const cannotPrescribe = createFhirQuestionnaireItem({
    text: 'If you fall in the category of individuals for whom Pharmacists cannot prescribe, please book an appointment at your nearest walk-in clinic or primary care provider for further assessment.',
    linkId: 'cannotPrescribe',
    type: 'display',
  })

  const additionalEligibility = createFhirQuestionnaireItem({
    text: 'Additional eligibility for assessment and prescribing for minor ailments will be assessed by the pharmacist at the time of your appointment.',
    linkId: 'additionalEligibility',
    type: 'display',
  })

  const healthcardPaymentDisclaimerHeader = createFhirQuestionnaireItem({
    text: 'DISCLAIMER:',
    linkId: 'healthcardDisclaimerHeader',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const skHc = createFhirQuestionnaireItem({
    text: "If you don't have a valid Saskatchewan Health Card, Professional fees may apply.",
    linkId: 'skHc',
    type: 'display',
  })

  const medicationPaymentDisclaimer = createFhirQuestionnaireItem({
    text: 'In the event a prescription is written for this service, the cost of the medication is not included in the assessment. The cost may be covered by you or your drug insurance.',
    linkId: 'medicationPaymentDisclaimer',
    type: 'display',
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      erectileDysfunction,
      cannotPrescribe,
      additionalEligibility,
      healthcardPaymentDisclaimerHeader,
      skHc,
      medicationPaymentDisclaimer,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirEDMedicalScreeningSdmSKJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['SK'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      urlParams: ['ErectileDysfunction'],
    },
  },
  metadata: {
    id: 'fhirEDMedicalScreeningSdmSKJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
