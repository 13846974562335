import { JsonFormStep } from './types'

export const bookingErrorTimeslotSelector: JsonFormStep = {
  type: 'preset',
  preset: 'storeTimeslotSelector',
  metadata: {
    id: 'bookingErrorTimeslotSelector',
    name: 'Booking Error Timeslot Selector',
    path: 'booking-error-timeslot-selector',
    showOnSteps: false,
  },
}
