import React, { useState, useRef, Suspense, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// redux
import { updatePharmacistLayout } from '~/store/pharmacistLayout'

// utils
import { EnterpriseEnums } from '~/shared/constants/enterpriseConstants'
import { getIsBillingEnabled } from '~/util/featureAccess'
import { useFeatureFlag, FeatureFlag } from '~/tools/featureGating'
import {
  isEnterprise,
  getEnterpriseName,
  JEAN_COUTU_ENVIRONMENTS,
  isJcgBrunet,
  isRexall,
} from '~/util/enterpriseMethods'
import { TIME_ZONES } from '~/shared/constants'

// hooks
import useMediaHook from '~/hooks/mediaHook'
import useLogoutHook from '~/auth/useLogout'

// components
import ExpandableSidebarOption from './expandableSidebarOption'
import SidebarOption from './sidebarOption'
import Menu from '~/componentsV1/menu'
import IconButton from '~/componentsV1/buttons/iconButton'

// helpers
import { gotoImdLink } from '../pharmacistLayoutHelpers'

// constants
import { IMD_HOSTNAMES } from '../pharmacistLayoutConstants'

// styles
import {
  PharmacistSideBar,
  SidebarOptionsContainer,
  WhiteBarLine,
  SidebarEndOptionContainer,
  HideButton,
  SidebarFlexEndOptionContainer,
  IconImg,
  EnterpriseStoreHeader,
  EnterpriseContextContainer,
  BoldText,
  PharmacistPictureContainer,
  CircleImage,
  PharmacistName,
  Image,
  SideBarSeparator,
} from '../pharmacistLayout.styles'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import DateRangeIcon from '@material-ui/icons/DateRange'
import StoreIcon from '@material-ui/icons/Store'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TimerIcon from '@material-ui/icons/Timer'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import DescriptionIcon from '@material-ui/icons/Description'
import PersonIcon from '@material-ui/icons/Person'
import ForumIcon from '@material-ui/icons/Forum'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { Box } from '@material-ui/core'
import { primary } from '~/sharedStyles/colorPalette'

const SEPARATOR_NAME = 'separator'

const Sidebar = ({ isAdmin, selected }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const pharmacy = useSelector((state) => state.pharmacy)
  const { pharmacistLayout } = useSelector((state) => state.pharmacistLayout)
  const { firstName, lastName, email } = useSelector(
    (state) => state.pharmacist
  )
  const [cannyOpen, setCannyOpen] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)
  const cannyAnchorRef = useRef(null)
  const profileAnchorRef = useRef(null)
  const { logout } = useLogoutHook()
  const { t } = useMedMeTranslation('pharmacy')

  const isPharmacistReportsDisabled = useFeatureFlag(
    FeatureFlag.HIDE_PHARMACIST_REPORTS,
    false
  )
  const isPharmacistWaitlistFeaturesDisabled = useFeatureFlag(
    FeatureFlag.HIDE_PHARMACIST_WAITLIST,
    false
  )
  const isSeriesADemo = useFeatureFlag(
    FeatureFlag.ENABLE_SERIES_A_DEMO_MODE,
    false
  )

  const openLinkAndCloseMenu = (link) => {
    setCannyOpen(false)
    window.open(link)
  }

  const sidebarOptions = useMemo(() => {
    if (isSeriesADemo) {
      return [
        {
          name: 'dashboard',
          link: '/pharmacist/dashboard',
          icon: <DashboardIcon style={{ color: 'white', fontSize: 30 }} />,
          title: t('sidebar.home'),
          isHidden: ({ isAdmin }) => isAdmin,
        },
        {
          name: 'patient-list',
          link: '/pharmacist/patientList',
          icon: <PeopleAltIcon style={{ color: 'white', fontSize: 30 }} />,
          title: t('sidebar.patients'),
          isHidden: ({ isAdmin }) => isAdmin,
        },
        {
          name: 'reports',
          link: '/pharmacist/reports',
          icon: <TrendingUpIcon style={{ color: 'white', fontSize: 30 }} />,
          title: t('sidebar.reports'),
          isHidden: ({ isAdmin }) => isAdmin,
        },
        { name: SEPARATOR_NAME },
        {
          name: 'scheduling',
          icon: <DateRangeIcon style={{ color: 'white', fontSize: 30 }} />,
          title: t('sidebar.scheduling'),
          isHidden: ({ isAdmin }) => isAdmin,
          subOptions: [
            {
              name: 'appointment-list',
              link: '/pharmacist/appointmentList',
              title: t('sidebar.appointments'),
            },
            {
              name: 'flu-shot-regular-waitlist',
              link: '/pharmacist/fluShotWaitlist',
              title: t('sidebar.waitlist'),
            },
          ],
        },
        {
          name: 'form-list',
          link: '/pharmacist/forms',
          icon: (
            <Box style={{ position: 'relative' }}>
              <DescriptionIcon
                style={{ color: 'white', fontSize: 26, marginBottom: 8 }}
              />
              <Box
                style={{
                  position: 'absolute',
                  width: 14,
                  height: 14,
                  top: 11,
                  left: 12,
                  borderRadius: '50%',
                  backgroundColor: primary,
                }}
              />
              <PersonIcon
                style={{
                  position: 'absolute',
                  top: 8,
                  left: 4,
                  color: 'white',
                  fontSize: 30,
                }}
              />
            </Box>
          ),
          title: t('sidebar.patientForms'),
          isHidden: ({ isAdmin }) => isAdmin,
        },
        {
          name: 'documentation',
          link: '/pharmacist/documentation',
          icon: <NoteAddIcon style={{ color: 'white', fontSize: 30 }} />,
          title: t('sidebar.documentation'),
          isHidden: ({ isAdmin }) => isAdmin,
        },
        {
          name: 'communication',
          link: '/pharmacist/communication',
          icon: <ForumIcon style={{ color: 'white', fontSize: 30 }} />,
          title: t('sidebar.communications'),
          isHidden: ({ isAdmin }) => isAdmin,
        },
      ]
    }
    const commonSidebarOptions = [
      {
        name: 'dashboard',
        link: '/pharmacist/dashboard',
        icon: <DashboardIcon style={{ color: 'white', fontSize: 30 }} />,
        title: t('sidebar.dashboard'),
        isHidden: ({ isAdmin }) => isAdmin,
      },
      {
        name: 'patient-list',
        link: '/pharmacist/patientList',
        icon: <PeopleAltIcon style={{ color: 'white', fontSize: 30 }} />,
        title: t('sidebar.patients'),
        isHidden: ({ isAdmin, enterprise }) =>
          isAdmin || isJcgBrunet(enterprise),
      },
      {
        name: 'appointment-list',
        link: '/pharmacist/appointmentList',
        icon: <DateRangeIcon style={{ color: 'white', fontSize: 30 }} />,
        title: t('sidebar.appointments'),
        isHidden: ({ isAdmin, enterprise }) =>
          isAdmin || isJcgBrunet(enterprise),
      },
      {
        name: 'consultation-list',
        link: '/pharmacist/consultationList',
        icon: <DateRangeIcon style={{ color: 'white', fontSize: 30 }} />,
        title: t('sidebar.consultations'),
        isHidden: ({ isAdmin, enterprise }) =>
          isAdmin || !isJcgBrunet(enterprise),
      },
      {
        name: 'form-list',
        link: '/pharmacist/forms',
        icon: <NoteAddIcon style={{ color: 'white', fontSize: 30 }} />,
        title: t('sidebar.forms'),
        isHidden: ({ enterprise }) => !isJcgBrunet(enterprise),
      },
    ]

    const waitlistSidebarOptions = [
      {
        icon: <TimerIcon style={{ color: 'white', fontSize: 30 }} />,
        name: 'covid-vaccine-waitlist',
        title: t('sidebar.covid19Waitlist.title'),
        isHidden: ({ isAdmin, enterprise }) =>
          isAdmin ||
          [
            EnterpriseEnums.PANS,
            EnterpriseEnums.NWC,
            ...JEAN_COUTU_ENVIRONMENTS,
          ].includes(enterprise),
        subOptions: ['ON', 'SK', 'AB', 'MB', 'NB'].includes(pharmacy.province)
          ? [
              {
                name: 'covid-vaccine-dose-one-waitlist',
                link: '/pharmacist/covidVaccineWaitlist',
                title: t('sidebar.covid19Waitlist.dose1'),
              },
              {
                name: 'covid-vaccine-dose-two-waitlist',
                link: '/pharmacist/covidVaccineDoseTwoWaitlist',
                title: t('sidebar.covid19Waitlist.dose2'),
              },
              {
                name: 'covid-vaccine-dose-three-waitlist',
                link: '/pharmacist/covidVaccineDoseThreeWaitlist',
                title: t('sidebar.covid19Waitlist.dose3Booster'),
              },
            ]
          : [
              {
                name: 'covid-vaccine-dose-one-waitlist',
                link: '/pharmacist/covidVaccineWaitlist',
                title: t('sidebar.covid19Waitlist.dose1'),
              },
              {
                name: 'covid-vaccine-dose-two-waitlist',
                link: '/pharmacist/covidVaccineDoseTwoWaitlist',
                title: t('sidebar.covid19Waitlist.dose2'),
              },
            ],
      },
      {
        icon: <TimerIcon style={{ color: 'white', fontSize: 30 }} />,
        name: 'flu-shot-waitlist',
        title: t('sidebar.fluShotWaitlist.title'),
        isHidden: ({ isAdmin, enterprise }) =>
          isAdmin || isJcgBrunet(enterprise),
        subOptions: [
          {
            name: 'flu-shot-regular-waitlist',
            link: '/pharmacist/fluShotWaitlist',
            title: t('sidebar.fluShotWaitlist.regular'),
          },
          {
            name: 'flu-shot-high-dose-waitlist',
            link: '/pharmacist/fluShotHighDoseWaitlist',
            title: t('sidebar.fluShotWaitlist.highDose'),
          },
          {
            name: 'flu-mist-waitlist',
            link: '/pharmacist/fluMistWaitlist',
            title: t('sidebar.fluShotWaitlist.mist'),
          },
        ],
      },
    ]

    const reportsSidebarOptions = [
      {
        name: 'reports',
        link: '/pharmacist/reports',
        icon: <TrendingUpIcon style={{ color: 'white', fontSize: 30 }} />,
        title: t('sidebar.reports'),
        isHidden: ({ isAdmin }) => isAdmin,
      },
    ]

    if (!isPharmacistWaitlistFeaturesDisabled) {
      commonSidebarOptions.push(...waitlistSidebarOptions)
    }

    if (!isPharmacistReportsDisabled) {
      commonSidebarOptions.push(...reportsSidebarOptions)
    }

    // maintaining a certain order in the tabs
    commonSidebarOptions.push({
      name: 'stores',
      link: '/admin/stores',
      icon: <StoreIcon style={{ color: 'white', fontSize: 30 }} />,
      title: 'Stores',
      isHidden: ({ isAdmin, enterprise }) =>
        !isAdmin || isJcgBrunet(enterprise),
    })

    return commonSidebarOptions
  }, [
    t,
    pharmacy.province,
    isPharmacistReportsDisabled,
    isPharmacistWaitlistFeaturesDisabled,
    isSeriesADemo,
  ])

  const cannyOptions = [
    {
      title: t('sidebar.newAtMedme'),
      onClick: () =>
        openLinkAndCloseMenu('https://medmehealth.canny.io/changelog'),
    },
    {
      title: t('sidebar.discussionForum'),
      onClick: () =>
        openLinkAndCloseMenu('https://medmehealth.canny.io/discussions'),
    },
    {
      title: t('sidebar.featureRequest'),
      onClick: () =>
        openLinkAndCloseMenu('https://medmehealth.canny.io/feature-requests'),
    },
  ]

  // hide settings from Rexall users. undo after flu season
  const profileOptions =
    isRexall() && !isAdmin
      ? [
          {
            title: t('sidebar.logout'),
            onClick: () => logout(),
          },
        ]
      : [
          {
            title: t('sidebar.settings.title'),
            onClick: () => {
              setProfileOpen(false)
              history.push('/pharmacist/settings')
            },
          },
          {
            title: t('sidebar.logout'),
            onClick: () => logout(),
          },
        ]

  const deviceType = useMediaHook(
    // Width set to 1280px to handle the need of minimized sidebar on larger tablets
    ['(max-width: 1280px)'],
    ['tablet'],
    'desktop'
  )

  return (
    <Suspense fallback={null}>
      <PharmacistSideBar hidden={deviceType !== 'desktop' && !pharmacistLayout}>
        {deviceType === 'tablet' && (
          <HideButton
            onClick={() => dispatch(updatePharmacistLayout(!pharmacistLayout))}
          >
            <ArrowBackIosRoundedIcon style={{ color: 'black', fontSize: 20 }} />
          </HideButton>
        )}

        {isEnterprise(pharmacy.enterprise) && pharmacistLayout && (
          <EnterpriseStoreHeader>
            <IconButton
              onClick={async () => {
                /**
                 * A previous issue that occurred was that when it cleared the apollo cache, it didn't clear the data that was set locally through @client.
                 * Due to this, the following error happens:
                 *  1. login
                 *  2. click on back button
                 *  3. select store
                 *  4. land on dashboard
                 *  5. logout
                 * Logging out does clear both the redux store, apollo cache and localStorage along with setting the isLoggedIn to false, but does not update the data fetched in app.js,
                 * which causes the page to stay on the pharmacistSide with a spinner loading forever.
                 *
                 * The full solution to this is instead of handling the clearing logic in app.js, it should be all transferred to the logout hook and that should be called where necessary
                 */

                await logout({
                  clearLocalStorage: false,
                  clearCookie: false,
                  logoutGql: false,
                  endPath: '/selectStore',
                  logoutCognito: false,
                  resetRedux: false,
                })
              }}
            >
              <ArrowBackIosIcon
                style={{ color: 'white', fontSize: 30, marginLeft: '1rem' }}
              />
            </IconButton>
            <div />
            <EnterpriseContextContainer>
              <BoldText>{getEnterpriseName(pharmacy.enterprise)}</BoldText>
              <div>#{pharmacy.storeNo}</div>
              <div>
                {t('sidebar.allTimesIn')} {TIME_ZONES[pharmacy.timeZone]}
              </div>
            </EnterpriseContextContainer>
          </EnterpriseStoreHeader>
        )}

        {pharmacy.enterprise === EnterpriseEnums.NWC && (
          <>
            <PharmacistPictureContainer>
              {pharmacy && (
                <CircleImage
                  src={process.env.REACT_APP_S3_BASE_URL + pharmacy.logoCircle}
                />
              )}
            </PharmacistPictureContainer>
            <PharmacistName>
              {firstName && lastName && `${firstName} ${lastName}`}
            </PharmacistName>
          </>
        )}

        <SidebarOptionsContainer>
          {sidebarOptions.map(
            ({ name, link, icon, title, isHidden, subOptions }, i) => {
              if (name === SEPARATOR_NAME) return <SideBarSeparator key={i} />
              if (isHidden({ isAdmin, enterprise: pharmacy.enterprise }))
                return null
              if (subOptions)
                return (
                  <ExpandableSidebarOption
                    key={i}
                    selected={selected}
                    name={name}
                    icon={icon}
                    title={title}
                    subOptions={subOptions}
                  />
                )
              return (
                <SidebarOption
                  key={i}
                  selected={selected}
                  name={name}
                  link={link}
                  icon={icon}
                  title={title}
                  data-cy={name}
                />
              )
            }
          )}
        </SidebarOptionsContainer>

        <SidebarFlexEndOptionContainer>
          {/* add this to condition when we need it: window.location.hostname.includes('nwcmoosonee')**/}
          {IMD_HOSTNAMES.includes(window.location.hostname) && (
            <SidebarOption
              selected={false}
              title={t('sidebar.imdHealth')}
              onClick={() => gotoImdLink(pharmacy)}
              icon={<IconImg src="/assets/imd_logo.svg" />}
            />
          )}
        </SidebarFlexEndOptionContainer>

        <WhiteBarLine />

        <SidebarEndOptionContainer>
          {getIsBillingEnabled() && (
            <SidebarOption
              selected={selected}
              name="billing"
              title={t('sidebar.billing')}
              icon={
                <Image
                  src="/assets/dollar-sign.svg"
                  alt=""
                  style={{ margin: 'auto', display: 'block' }}
                />
              }
              link="/pharmacist/billing"
              ref={cannyAnchorRef}
            />
          )}
          {!['SDM', 'LCL', 'NWC', ...JEAN_COUTU_ENVIRONMENTS].includes(
            pharmacy.enterprise
          ) && (
            <SidebarOption
              selected={false}
              title={t('sidebar.updatesOrForum')}
              icon={
                <IconImg
                  src="/assets/description-file-white.svg"
                  alt="Update/Forum"
                />
              }
              onClick={() => setCannyOpen(!cannyOpen)}
              ref={cannyAnchorRef}
            />
          )}
          <Menu
            open={cannyOpen}
            setOpen={setCannyOpen}
            options={cannyOptions}
            anchorRef={cannyAnchorRef}
          />

          <SidebarOption
            name="settings"
            selected={selected}
            title={
              pharmacy.enterprise === 'NWC'
                ? 'Settings/Logout'
                : `${firstName} ${lastName}`
            }
            helperText={email}
            tooltipTitle={`${firstName} ${lastName}`}
            tooltipHelper={email}
            icon={
              <Image
                src={process.env.REACT_APP_S3_BASE_URL + pharmacy.logoCircle}
              />
            }
            onClick={() => setProfileOpen(!profileOpen)}
            ref={profileAnchorRef}
          >
            <MoreVertIcon data-cy="MoreVertIcon" />
          </SidebarOption>
          <Menu
            open={profileOpen}
            setOpen={setProfileOpen}
            options={profileOptions}
            anchorRef={profileAnchorRef}
          />

          {isAdmin && (
            <SidebarOption
              selected={false}
              link="/"
              title="Logout"
              icon={<ExitToAppIcon style={{ color: 'white', fontSize: 30 }} />}
              onClick={() => logout()}
            />
          )}
        </SidebarEndOptionContainer>
      </PharmacistSideBar>
    </Suspense>
  )
}

export default Sidebar
