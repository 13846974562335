import { gql } from '@apollo/client'

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    isAdmin: Boolean!
    isPharmacyStaffReadOnly: Boolean!
    isSamlLogin: Boolean!
  }
`

export const resolvers = {}
