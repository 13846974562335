import * as React from 'react'
import {
  Card,
  Heading1,
  FormStepContentLayout,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { NavigationFooter } from '~/pages/AppointmentIntake/components'
import { CheckboxField } from '~/controlledContextInputs'
import { ContactInformation } from './components/ContactInformation/ContactInformation'
import { MedicalInformation } from './components/MedicalInformation/MedicalInformation'
import { ConsentVerbiage } from '../../ConsentFormStepComponent/Consent/ConsentVerbiage'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { useFormContext } from 'react-hook-form'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const ReviewAndConfirm: React.FC = () => {
  const { t } = useMedMeTranslation('patient')
  const {
    state: {
      appointment: { consentGiverName },
    },
  } = useBookingContext()

  const { prevStep } = useFormManager()
  const methods = useFormContext()

  return (
    <FormStepContentLayout>
      <Heading1>{t('reviewAndConfirm.title')}</Heading1>
      <Card>
        <ContactInformation />
      </Card>
      <MedicalInformation />
      <Card>
        <ConsentVerbiage
          consentCollected={true}
          consentGiverName={consentGiverName}
        />
      </Card>
      <Card>
        <CheckboxField
          name="reviewAndConfirm"
          label={t('reviewAndConfirm.reviewedAllInformation')}
          defaultValue={false}
        />
      </Card>
      <NavigationFooter
        onBack={prevStep}
        isDisabled={methods.formState.isSubmitting}
      />
    </FormStepContentLayout>
  )
}
