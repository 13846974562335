import React, { lazy } from 'react'
import { BookingManagementProvider } from '~/pages/AppointmentIntake/contexts/BookingManagementContext'
import { AppointmentManagement } from '~/pages/AppointmentIntake/NewBooking/AppointmentManagement'
import { CommonProviders } from '~/pages/AppointmentIntake/NewBooking/CommonProviders'
import { BookingProvider as NewBookingProvider } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import { MonitoredRoute } from '~/tools/monitoring'

const BookingCancellation = lazy(
  () => import('~/pages/AppointmentIntake/NewBooking/BookingCancellation')
)
const BookingExpiration = lazy(
  () => import('~/pages/AppointmentIntake/NewBooking/BookingExpiration')
)
const BookingRescheduling = lazy(
  () => import('~/pages/AppointmentIntake/NewBooking/BookingRescheduling')
)

export const AppointmentManagementRoutes = () => {
  const bookingManagementPaths = {
    cancel: '/:storeNo?/booking/cancel/:key1/:key2/:key3',
    reschedule: '/:storeNo?/booking/reschedule/:key1/:key2/:key3',
    expired: '/:storeNo?/booking/expired/:key1/:key2/:key3',
  }

  return (
    <MonitoredRoute path={Object.values(bookingManagementPaths)}>
      <BookingManagementProvider>
        <NewBookingProvider>
          <CommonProviders>
            <AppointmentManagement>
              <MonitoredRoute path={bookingManagementPaths.cancel}>
                <BookingCancellation />
              </MonitoredRoute>
              <MonitoredRoute path={bookingManagementPaths.reschedule}>
                <BookingRescheduling />
              </MonitoredRoute>
              <MonitoredRoute path={bookingManagementPaths.expired}>
                <BookingExpiration />
              </MonitoredRoute>
            </AppointmentManagement>
          </CommonProviders>
        </NewBookingProvider>
      </BookingManagementProvider>
    </MonitoredRoute>
  )
}
