import * as React from 'react'
import {
  JsonFhirFormStep,
  JsonFhirR5FormStep,
} from '../../FormManager/jsonFormSteps'
import { FhirForm } from './FhirForm'
import {
  BookingDetailsWrapper,
  NavigationFooter,
} from '~/pages/AppointmentIntake/components'
import {
  CardLayout,
  Heading1,
  FormStepContentLayout,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/BookingContext/useBookingContext'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { getAaiTitleAndSubtitle } from '~/lib/appointmentActivity'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { FhirForm as FhirR5Form } from '~/lib/fhir/FhirForm'

export const FhirFormStepComponent: React.FC<{
  json: JsonFhirFormStep | JsonFhirR5FormStep
}> = ({ json }) => {
  const { state, updateFields } = useBookingContext()
  const { t } = useMedMeTranslation('common')
  const { appointmentActivity } = state
  const { nextStep, prevStep, formStepsIndex } = useFormManager()
  const onSubmit = React.useCallback(
    async (data, codeMap) => {
      if (json.version === 'R5') {
        json.fhir.saveAnswers(data)
      }
      updateFields(data, codeMap)
      nextStep()
    },
    [nextStep, updateFields]
  )

  const FormLayout = React.useCallback<React.FC<JSX.IntrinsicElements['form']>>(
    ({ children, ...rest }) => {
      return (
        <CardLayout {...(rest as any)}>
          {children}
          <NavigationFooter
            onBack={formStepsIndex > 0 ? prevStep : null}
            nextButtonLabel={t('continueButtonLabel')}
          />
        </CardLayout>
      )
    },
    [formStepsIndex, prevStep, t]
  )

  const aaiTitleAndSubtitle = getAaiTitleAndSubtitle(appointmentActivity)

  return (
    <BookingDetailsWrapper>
      <FormStepContentLayout>
        <Heading1>{aaiTitleAndSubtitle}</Heading1>
        {json.version === undefined ? (
          <FhirForm
            questionnaire={json.fhir}
            onSubmit={onSubmit}
            Form={FormLayout}
            state={state}
          />
        ) : (
          <FhirR5Form
            questionnaire={json.fhir}
            onSubmit={onSubmit}
            Form={FormLayout}
            state={state}
          />
        )}
      </FormStepContentLayout>
    </BookingDetailsWrapper>
  )
}
