import { createPresetFormStep } from '..'
import { AilmentSelector } from '~/pages/AppointmentIntake/forms'

export const ailmentSelectorFormStep: typeof createPresetFormStep = (json) => {
  return {
    Component: AilmentSelector,
    metadata: json.metadata,
    enableWhen: (state) => {
      return state.appointmentActivityGroup.urlParam === 'minorAilments'
    },
    isAutoSkippable: async () => false,
  }
}
