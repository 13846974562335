import * as React from 'react'
import { useConsent } from '../hooks'
import {
  CardTitle,
  SectionBodyBold,
  SectionBodyContainer,
} from '../Consent.style'
import {
  BoldTextContainer,
  ConsentDescriptionContainer,
  TextContainer,
} from '../components'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

type ConsentVerbiage = {
  consentCollected: boolean
  consentGiverName?: string
}

export const ConsentVerbiage: React.FC<ConsentVerbiage> = ({
  consentCollected,
  consentGiverName,
}) => {
  const { t } = useMedMeTranslation('patient')
  const {
    acknowledgeConsent,
    consentDescription,
    appointmentDetails,
    patientFullName,
    pharmacyAddressDisplay,
    pharmacyProfessionalText,
  } = useConsent()
  const {
    state: {
      appointmentActivity: { urlParam },
    },
  } = useBookingContext()

  /**
   * Grabs the correct consent title for the consent page. At time of commit, the SDM lipid test is the only AA that has a different copy.
   */
  const getConsentTitleTranslationKey = (urlParam: string) => {
    if (urlParam === 'poct-lipids') {
      return 'provideConsent'
    }
    return 'reviewAndConfirm.informedConsentTitle'
  }

  return (
    <div>
      <CardTitle>{t(getConsentTitleTranslationKey(urlParam))}</CardTitle>
      <TextContainer text={appointmentDetails} />
      <BoldTextContainer text={patientFullName} />
      <TextContainer text={pharmacyProfessionalText} />
      <SectionBodyContainer>
        <SectionBodyBold>{pharmacyAddressDisplay.name}</SectionBodyBold>
        <SectionBodyBold>
          {pharmacyAddressDisplay.buildingAddress}
        </SectionBodyBold>
        <SectionBodyBold>{pharmacyAddressDisplay.cityAddress}</SectionBodyBold>
      </SectionBodyContainer>

      <ConsentDescriptionContainer
        acknowledgeConsent={acknowledgeConsent}
        consentDescription={consentDescription}
      />
      {consentCollected && (
        <div>
          <BoldTextContainer
            text={`${t('consentGiverName')}: ${consentGiverName}`}
          />
          <BoldTextContainer
            text={`${t('consentCollected')}: ${t('yes', { ns: 'common' })}`}
          />
        </div>
      )}
    </div>
  )
}
