import {
  EAppointmentStatus,
  usePublicCreatePatientAndAppointmentV1Mutation,
  type PublicCreatePatientAndAppointmentV1Mutation,
} from '~/graphql/types/schema.type'
import { useAnalytics } from '~/hooks/useAnalytics'
import { createAppointment } from '~/lib/appointment'
import { createPatient } from '~/lib/patient'
import {
  useAppointmentActivityContext,
  useAppointmentContext,
  usePatientContext,
  useRouterContext,
} from '~/pages/AppointmentIntake/contexts'
import { BookingContextInterface } from '../../../BookingContext'
import { createPublicAppointmentInput } from '../createAppointmentInput'
import { createIntakeSubmissionResult } from '../createIntakeSubmissionResult'
import { createPatientAndIntakeAnswerInput } from '../createPatientAndIntakeAnswerInput'
import { useGetPchSessionId } from '~/hooks/useGetPchSessionId'
import { useQueryParams } from '~/hooks/useQueryParams'
import { useRwgConversion } from '~/hooks/reserveWithGoogle/useRwgConversion'

/**
 * Hook that exposes a function that creates a new patient along with a new appointment record
 * using the GraphQL/java backend.
 *
 * Supports intakeType 'public' (i.e. patient-initiated-and-driven flows only)
 */
export const useCreateNewPatientAndAppointment = (
  reservationId: BookingContextInterface['reservationId']
) => {
  const { intakeType } = useRouterContext()
  const { patient } = usePatientContext()
  const { appointment } = useAppointmentContext()
  const { appointmentActivity } = useAppointmentActivityContext()
  const [mutation] = usePublicCreatePatientAndAppointmentV1Mutation()
  const {
    searchParams: { language },
  } = useRouterContext()

  const { trackAnalyticsEvent } = useAnalytics()

  const pchSessionId = useGetPchSessionId()
  const { rwgToken } = useQueryParams()
  const { sendConversionData } = useRwgConversion(rwgToken)

  const createNewPatientAndAppointment = async () => {
    // @todo see useSubmitIntake note to refactor for clarity vs. this buried condition
    if (intakeType !== 'public') {
      return
    }

    const isWalkIn = appointment.isWalkin ?? false // note the inconsistent caps of isWalkIn here
    const isWaitlisted = appointment.status === EAppointmentStatus.WAITLIST

    try {
      const { data } = await mutation({
        variables: {
          publicCreatePatientAndIntakeAnswerInput:
            createPatientAndIntakeAnswerInput(patient, appointment),
          publicCreateAppointmentInput: createPublicAppointmentInput(
            appointment,
            appointmentActivity,
            reservationId,
            language
          ),
          isWalkIn,
          isWaitlisted,
          pchSessionId,
        },
        onCompleted: (
          _newlyCreatedPatient: PublicCreatePatientAndAppointmentV1Mutation
        ) => {
          // NOTE: both 'legacy' and 'new' flow call the same endpoint (non-DRY)
          // @see `submission.gql.ts` for the legacy patient intake flow
          if (!isWaitlisted) {
            trackAnalyticsEvent('pt__pt_appt_booking_common__end', {
              ui_flow: 'hooks',
              booking_type: isWalkIn ? 'walk-in' : 'scheduled',
            })
          }
        },
      })

      const newPatient = createPatient(
        data?.publicCreatePatientAndAppointmentV1
      )
      const newAppointment = createAppointment(
        data?.publicCreatePatientAndAppointmentV1?.appointments?.[0]
      )

      sendConversionData()

      return createIntakeSubmissionResult({
        patient: newPatient,
        appointment: newAppointment,
      })
    } catch (error) {
      const timeslotTaken =
        error?.graphQLErrors?.[0]?.extensions?.errorCode === '0038'
      return createIntakeSubmissionResult({
        error: String(error),
        timeslotTaken,
      })
    }
  }

  return {
    createNewPatientAndAppointment,
  }
}
