import { AppointmentType as GqlAppointmentType } from '~/graphql/types/schema.type'
import { DeepPartial } from '../../../pages/AppointmentIntake/lib/types'
import { AppointmentType } from '../AppointmentType'
export type ReplaceProps<
  K extends Record<string, unknown>,
  T extends { [index in keyof K]?: unknown }
> = Omit<K, keyof T> & T

export const createAppointmentType = (
  appointmentType?: DeepPartial<GqlAppointmentType> | null
): AppointmentType => {
  return {
    id: appointmentType?.id ?? '',
    type: appointmentType?.type ?? '', // should deprecate for name
    name: appointmentType?.type ?? '',
    category: appointmentType?.category ?? '',
    subCategory: appointmentType?.subCategory ?? '',
    description: appointmentType?.description ?? '',
    subDescription: appointmentType?.subDescription ?? '',
    methods: appointmentType?.methods?.map(String) ?? [],
    intakeForms:
      appointmentType?.intakeForms?.filter((form): form is string =>
        Boolean(form)
      ) ?? [],
    isWalkinAllowed: appointmentType?.isWalkinAllowed ?? false,
    isWaitlisted: appointmentType?.isWaitlisted ?? false,
    waitlistCount: appointmentType?.waitlistCount ?? 0,
    bookingStartDate: appointmentType?.bookingStartDate ?? '',
    bookingEndDate: appointmentType?.bookingEndDate ?? '',
    durationOne: appointmentType?.durationOne ?? 0,
    durationTwo: appointmentType?.durationTwo ?? 0,
    durationThree: appointmentType?.durationThree ?? 0,
    durationFour: appointmentType?.durationFour ?? 0,
    durationFive: appointmentType?.durationFive ?? 0,
    durationSix: appointmentType?.durationSix ?? 0,
    isEvent: appointmentType?.isEvent ?? false,
    // For External Clinic
    isExternalClinic: appointmentType?.isExternalClinic ?? false,
    location: appointmentType?.location ?? '',
    appointmentActivityId: appointmentType?.appointmentActivityId ?? '',
  }
}
