import React, { createContext, useContext } from 'react'
import {
  AppointmentActivityInstance,
  BookingReservation,
  CombinedTimeslot,
  EAppointmentTypeMethod,
  Sublocation,
} from '~/graphql/types/schemaNode.type'
import { BookingScope } from '../../types'
import { Pharmacy } from '~/lib/pharmacy'
import { PatientIntakeRecord } from '../../NewBooking/Intake/PatientIntakeContext'
import { ClaimCodeFromBookingReturnType } from '~/util/claimCode'

export interface BookingContextInterface {
  mainService: AppointmentActivityInstance | null
  services: AppointmentActivityInstance[]
  method: EAppointmentTypeMethod
  scope: BookingScope
  numberOfPersons: number
  customerId?: string
  pharmacy?: Pharmacy
  timeSlot?: CombinedTimeslot
  reservation?: BookingReservation
  patientIntakeRecords?: PatientIntakeRecord[]
  claimCode: ClaimCodeFromBookingReturnType
  externalClinic?: Sublocation
  detailsWidget: {
    desktopRef: React.RefObject<HTMLDivElement>
    mobileRef: React.RefObject<HTMLDivElement>
  }
  dispatch: React.Dispatch<{ type: string; payload?: any }>
}

export const BookingContext = createContext<
  BookingContextInterface | undefined
>(undefined)

export const useBookingContext = () => {
  const context = useContext(BookingContext)
  if (context === undefined) {
    throw new Error('useBookingContext must be used within a BookingProvider')
  }

  return context
}
