import * as React from 'react'
import { Paper } from '@material-ui/core'
import { FhirQuestionnaireItemComponent } from '../type'
import { FhirStemComponent } from '../FhirStemComponent'
import { itemTypeVariantCodes } from '~/lib/fhir/extensions'
import { withGrid } from '../withGrid'

const GroupLayout: React.FC<{
  type: typeof itemTypeVariantCodes[number] | undefined
  children: React.ReactNode
}> = ({ type, children }) => {
  switch (type) {
    case 'card':
      return (
        <Paper elevation={0} style={{ padding: '1rem' }}>
          {children}
        </Paper>
      )
    default:
      return <>{children}</>
  }
}

export const FhirGroupComponent: FhirQuestionnaireItemComponent = withGrid(
  (props) => {
    const { questionnaireItem } = props

    if (questionnaireItem.type !== 'group') {
      console.error(
        `FhirGroupComponent: Unsupported type '${questionnaireItem.type}'`
      )
      throw new Error("FhirGroupComponent: type must be 'group'")
    }

    if (
      questionnaireItem.item === undefined ||
      questionnaireItem.item.length === 0
    ) {
      throw new Error('FhirGroupComponent: groups should always items')
    }

    return (
      <GroupLayout type={questionnaireItem.supportedExtensions.typeVariant}>
        {questionnaireItem.item.map((item, i) => (
          <FhirStemComponent {...props} questionnaireItem={item} key={i} />
        ))}
      </GroupLayout>
    )
  }
)
