import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { gray } from '~/sharedStyles/colorPalette'
import { Heading4 } from '../AppointmentActivityAccordion/AppointmentActivityAccordion.styles'

export const BookAppointment = styled(Button)`
  width: max-content;
  justify-self: end;
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  padding: 0.25rem 1rem;
  color: ${({ theme }) => theme.color.titleTertiary};
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  text-transform: none;
  transition: 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    opacity: 75%;
  }
`
export const NotAvailable = styled(Heading4)`
  width: max-content;
  padding: 0.25rem 1rem;
  color: ${gray};
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  transition: 0.3s;
`
