import { useEffect, useState } from 'react'
import { createPatient } from '~/lib/patient'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import { getNextSteps } from '../../Intake/utils'
import { DeepPartial } from '@apollo/client/utilities'
import { Booking } from '~/graphql/types/schemaNode.type'
import { createPatientFromPatientOutput } from '~/util/createPatientFromPatientOutput'

export function useInitializePatientIntakeRecords(
  intakeServiceLoading?: boolean,
  existingBooking?: DeepPartial<Booking> | null
) {
  const { patientIntakeRecords, services, method, dispatch } =
    useBookingContext()
  const [isInitialized, setIsInitialized] = useState(
    patientIntakeRecords !== undefined
  )

  // Initialize empty patientIntakeRecords context
  useEffect(() => {
    if (intakeServiceLoading) return
    if (!isInitialized) {
      const patient = createPatientFromPatientOutput(
        existingBooking?.patients?.[0] || createPatient()
      )
      dispatch({
        type: 'setIntakeRecords',
        payload: [
          {
            patient: { ...patient, patientIdx: 0 },
            appointments: [],
            questionnaires: [],
            serviceTypes: services.map((service) => {
              return {
                urlParam: service.urlParam,
                details: {
                  title: service.details?.title || '',
                  nextSteps:
                    (service.details &&
                      getNextSteps(service.details, method, false)) ||
                    '',
                },
                method: method,
                locationServiceId: service.appointmentTypeId || null,
                documentTemplates: service.settings?.documentTemplates,
              }
            }),
          },
        ],
      })
      setIsInitialized(true)
    }
  }, [
    dispatch,
    existingBooking?.patients,
    intakeServiceLoading,
    isInitialized,
    method,
    patientIntakeRecords,
    services,
  ])

  return {
    patientIntakeRecords,
  }
}
