import { useEffect } from 'react'
import { checkIsPatientUI } from '~/util/envMethods'

export const lclToSdmHostMappings: { [key: string]: string } = {
  'lcl.medmeapp.local': 'sdm.medmeapp.local',
  'lcldev.medmeapp.com': 'sdmdev.medmeapp.com',
  'lcldevqa.medmeapp.com': 'sdmdevqa.medmeapp.com',
  'lclsandbox.medmeapp.com': 'sdmsandbox.medmeapp.com',
  'lcl-sit.staging.medmeapp.com': 'sdm-sit.staging.medmeapp.com',
  'lcl-pt.staging.medmeapp.com': 'sdm-pt.staging.medmeapp.com',
  'loblawpharmacy.medmeapp.com': 'shoppersdrugmart.medmeapp.com',
} as const

export const useRedirectToSDM = () => {
  useEffect(() => {
    if (
      !checkIsPatientUI() ||
      !lclToSdmHostMappings[window.location.hostname]
    ) {
      return
    }

    const newUrl = new URL(window.location.href)
    newUrl.hostname = lclToSdmHostMappings[window.location.hostname]

    if (/^\/\d+\/.+$/.test(window.location.pathname)) {
      newUrl.pathname = `/lcl${window.location.pathname}`
    }

    window.location.href = newUrl.href
  }, [])
}
