import { TFunction } from 'i18next'
import { transformToHumanReadableNumber } from '~/util/formatContact'

export const formatDeliveryMessage = (
  t: TFunction,
  email: string,
  cell: string
) => {
  if (email && cell)
    return t('appointmentBookingCompletion.confirmationText.phoneAndEmail', {
      cell: transformToHumanReadableNumber(cell),
      email,
    })
  else if (email)
    return t('appointmentBookingCompletion.confirmationText.email', {
      email,
    })
  else if (cell)
    return t('appointmentBookingCompletion.confirmationText.phone', {
      cell: transformToHumanReadableNumber(cell),
    })
  return ''
}
