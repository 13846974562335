import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'
import { FbaFlagReturnType } from './types'

export const useIsSkipToEarliestTimeslotEnabled = (
  services: { urlParam: string }[]
) => {
  const { isEnabledForAll, urlParamsEnabled } =
    useFeatureFlag<FbaFlagReturnType>(
      FeatureFlag.ENABLE_SKIP_TO_EARLIEST_WEEK_IN_TIMESLOT_SELECTION_JSON,
      { isEnabledForAll: false }
    )
  const isEnabled =
    isEnabledForAll ||
    (Array.isArray(urlParamsEnabled) &&
      services.every((service) => urlParamsEnabled.includes(service.urlParam)))

  return isEnabled
}
