import { TextField as MuiTextField } from '@material-ui/core'
import React from 'react'
import { useFormContext, useController } from 'react-hook-form'

export const PhoneField: React.FC<{
  name: string
  label?: string
  defaultValue?: string
  helperText?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
}> = (props) => {
  const { errors } = useFormContext()
  const { field } = useController({
    name: props.name,
    defaultValue: props.defaultValue ?? '',
  })
  const error = props.disabled === true ? '' : errors[props.name]?.message

  const formatPhone = React.useCallback((value: string, countryCode = '1') => {
    const regex = new RegExp(`^[+]${countryCode}`, 'g')
    const removeCountryCode = value.replace(regex, '')
    const onlyNums = removeCountryCode.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
      return onlyNums
    }
    if (onlyNums.length <= 6) {
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`
    }
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10
    )}`
  }, [])

  const deformatPhone = React.useCallback(
    (value: string, countryCode = '1') => {
      const numbers = value.replace(/[^\d]/g, '')
      if (numbers) {
        return `+${countryCode}${numbers.substring(0, 10)}`
      }
      return ''
    },
    []
  )

  return (
    <MuiTextField
      variant="outlined"
      label={props.label}
      error={Boolean(error)}
      helperText={error || props.helperText}
      placeholder={props.placeholder}
      disabled={props.disabled}
      fullWidth
      name={field.name}
      value={formatPhone(field.value)}
      onChange={(e) => {
        e.target.value = deformatPhone(e.target.value)
        field.onChange(e)
      }}
      onBlur={field.onBlur}
      inputProps={{
        type: 'tel',
      }}
      ref={field.ref}
      required={!props.disabled && props.required}
    />
  )
}
