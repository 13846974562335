import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
  FhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { createFhirCodingEntityPatient } from '~/pages/AppointmentIntake/lib/fhir/systems'
import { presetFhirGridExt } from '../../../../../../lib/fhir/extensions/fhirExtGrid/presetFhirGridExt'
import { countryValueSet } from '../../../lib/countryValueSet'
import { provinceValueSet } from '../../../lib/provinceValueSet'
import { JsonFhirFormStep } from '../../../../types'

export const patientInformation = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientInformationHeading',
    text: 'Coordonnées de la (des) personne(s) recevant un rendez-vous à la clinique gérée par un pharmacien.',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const desc = createFhirQuestionnaireItem({
    linkId: 'patientInformationDesc',
    text: 'Veuillez entrer vos renseignements personnels et, le cas échéant, ceux de toute personne à charge ou adulte admissible pour lesquels vous avez consenti à prendre un rendez-vous. Tous les renseignements doivent être entrés comme ils apparaissent sur la carte santé provinciale de chaque personne (le cas échéant).',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Champs obligatoires (*)',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const firstName = createFhirQuestionnaireItem({
    linkId: 'patientFirstName',
    text: 'Prénom',
    type: 'string',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'firstName' })],
  })

  const lastName = createFhirQuestionnaireItem({
    linkId: 'patientLastName',
    text: 'Nom de famille',
    type: 'string',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'lastName' })],
  })

  const gender = createFhirQuestionnaireItem({
    linkId: 'patientGender',
    text: 'Sexe',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'female',
          display: 'Femme',
        },
      },
      {
        valueCoding: {
          code: 'male',
          display: 'Homme',
        },
      },
      {
        valueCoding: {
          code: 'self-identify',
          display: 'Ego identité',
        },
      },
    ],
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'gender' })],
  })

  const dateOfBirth = createFhirQuestionnaireItem({
    linkId: 'patientDateOfBirth',
    text: 'Date de naissance',
    type: 'date',
    required: true,

    extension: [
      presetFhirGridExt({ xs: 12, sm: 6 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isSameOrBefore' },
          { url: 'value', valueString: 'now' },
          { url: 'message', valueString: 'Date de naissance non valide' },
        ],
      }),
    ],
    code: [createFhirCodingEntityPatient({ code: 'birthDate' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientInformationSection',
    type: 'group',
    item: [heading, desc, descReq, firstName, lastName, gender, dateOfBirth],
  })
}

export const patientAddress = () => {
  const enabledWhenNoPoBox = (items: FhirQuestionnaireItem[]) =>
    createFhirQuestionnaireItem({
      linkId: 'enabledWhenNoPoBox',
      type: 'group',
      enableWhen: [
        {
          question: 'patientHasPOBox',
          operator: '=',
          answerBoolean: false,
        },
      ] as FhirQuestionnaireItem['enableWhen'],
      enableBehavior: 'all' as FhirQuestionnaireItem['enableBehavior'],
      item: items,
    })

  const renderWhenHasPoBox = {
    enableWhen: [
      {
        question: 'patientHasPOBox',
        operator: '=',
        answerBoolean: true,
      },
    ] as FhirQuestionnaireItem['enableWhen'],
    enableBehavior: 'all' as FhirQuestionnaireItem['enableBehavior'],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  }

  const heading = createFhirQuestionnaireItem({
    linkId: 'patientAddressHeading',
    type: 'display',
    text: 'Adresse',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const unit = createFhirQuestionnaireItem({
    linkId: 'patientUnit',
    type: 'string',
    text: 'Numéro de l’unité',
    extension: [presetFhirGridExt({ xs: 12, sm: 3 })],
    code: [createFhirCodingEntityPatient({ code: 'address.unit' })],
  })

  const streetNumberReq = createFhirQuestionnaireItem({
    linkId: 'patientStreetNumber',
    type: 'string',
    text: 'Numéro municipal',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 3 })],
    code: [createFhirCodingEntityPatient({ code: 'address.streetNumber' })],
  })

  const streetNameReq = createFhirQuestionnaireItem({
    linkId: 'patientStreetName',
    type: 'string',
    text: 'Nom de la rue',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.streetName' })],
  })

  const countryReq = createFhirQuestionnaireItem({
    linkId: 'patientCountry',
    type: 'choice',
    text: 'Pays',
    answerOption: countryValueSet('CA'),
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.country' })],
  })

  const cityReq = createFhirQuestionnaireItem({
    linkId: 'patientCity',
    type: 'string',
    text: 'Ville',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.city' })],
  })

  const canadaProvince = createFhirQuestionnaireItem({
    linkId: 'patientCanadaProvince',
    type: 'choice',
    text: 'Province',
    required: true,
    answerOption: provinceValueSet('', 'fr'),
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.province' })],
    enableWhen: [
      {
        question: 'patientCountry',
        operator: '=',
        answerString: 'CA',
      },
    ],
    enableBehavior: 'all',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const provinceReq = createFhirQuestionnaireItem({
    linkId: 'patientProvince',
    type: 'string',
    text: 'Province/État',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.province' })],
    enableWhen: [
      {
        question: 'patientCountry',
        operator: '!=',
        answerString: 'CA',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })

  const postalCodeReq = createFhirQuestionnaireItem({
    linkId: 'patientPostalCode',
    type: 'string',
    text: 'Code postal ou code ZIP',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [createFhirCodingEntityPatient({ code: 'address.postalCode' })],
  })

  const hasPOBox = createFhirQuestionnaireItem({
    linkId: 'patientHasPOBox',
    type: 'boolean',
    text: 'J’ai une case postale',
  })

  const poBox = createFhirQuestionnaireItem({
    linkId: 'patientPOBox',
    type: 'string',
    text: 'Case postale',
    required: true,
    ...renderWhenHasPoBox,
    code: [createFhirCodingEntityPatient({ code: 'address.poBox' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientInformationPage',
    type: 'group',
    item: [
      heading,
      enabledWhenNoPoBox([
        unit,
        streetNumberReq,
        streetNameReq,
        countryReq,
        cityReq,
        provinceReq,
        canadaProvince,
        postalCodeReq,
      ]),
      hasPOBox,
      poBox,
    ],
  })
}

export const patientContactDetails = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsHeading',
    type: 'display',
    text: 'Coordonnées',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const subheading1 = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsSubheading1',
    type: 'display',
    text: 'Veuillez saisir les coordonnées de la personne principale qui recevra la notification de réservation et les mises à jour concernant les rendez-vous qu’elle a pris.',
  })

  const subheading2 = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsSubheading2',
    type: 'display',
    text: 'Veuillez indiquer au moins un numéro de téléphone où nous pouvons vous joindre.',
  })

  const postDesc = createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPostDesc',
    type: 'display',
    text: 'Veuillez vous assurer d’avoir fourni la bonne adresse de courriel et le bon numéro de téléphone cellulaire. Nous utiliserons ces renseignements pour vous envoyer des mises à jour et des rappels de rendez-vous pour vous-même et pour toute personne à charge ou tout adulte admissible pour lesquels vous avez pris un rendez-vous. Notez que les courriels contiendront les renseignements les plus complets au sujet de votre rendez-vous.',
  })

  const email = createFhirQuestionnaireItem({
    linkId: 'patientEmail',
    type: 'string',
    text: 'Adresse courriel',
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isEmail' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Adresse courriel invalide' },
        ],
      }),
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'email' })],
    },
    required: true,
    enableWhen: [
      {
        question: 'patientNoEmail',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'email' })],
  })

  const home = createFhirQuestionnaireItem({
    linkId: 'patientHome',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    text: 'Numéro de téléphone maison',
    extension: [
      presetFhirGridExt({ sm: 8 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Numéro de téléphone invalide' },
        ],
      }),
    ],
    enableWhen: [
      {
        question: 'patientContactType',
        operator: '=',
        answerString: 'home',
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    required: true,
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'phone.home' })],
  })

  const cell = createFhirQuestionnaireItem({
    linkId: 'patientCell',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    text: 'Numéro de téléphone cellulaire',
    extension: [
      presetFhirGridExt({ sm: 8 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Numéro de téléphone invalide' },
        ],
      }),
    ],
    enableWhen: [
      {
        question: 'patientContactType',
        operator: '=',
        answerString: 'cell',
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    required: true,
    enableBehavior: 'all',
    code: [createFhirCodingEntityPatient({ code: 'phone.cell' })],
  })

  const phoneType = createFhirQuestionnaireItem({
    linkId: 'patientContactType',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'cell',
          display: 'Cellulaire',
        },
        initialSelected: true,
      },
      {
        valueCoding: {
          code: 'home',
          display: 'Maison',
        },
      },
    ],
    required: true,
    extension: [presetFhirGridExt({ sm: 4 })],
  })

  const noEmail = createFhirQuestionnaireItem({
    linkId: 'patientNoEmail',
    type: 'boolean',
    text: 'Je n’ai pas d’adresse courriel.',
  })

  const noEmailConfirm = createFhirQuestionnaireItem({
    linkId: 'patientNoEmailConfirm',
    type: 'boolean',
    text: 'Je comprends que je ne recevrai pas de courriels et que je devrai communiquer directement avec cette pharmacie pour apporter des changements à mon rendez-vous',
    required: true,
    enableWhen: [
      {
        question: 'patientNoEmail',
        operator: '=',
        answerBoolean: true,
      },
    ],
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
    enableBehavior: 'all',
  })

  const emailNotificationConsent = createFhirQuestionnaireItem({
    linkId: 'patientEmailNotificationConsent',
    type: 'boolean',
    text: 'J’aimerais recevoir de futures notifications et communications (courriels et messages texte) concernant d’autres services dont je pourrais bénéficier, offerts par Loblaws Inc., Shoppers Drug Mart Inc. ou leurs sociétés affiliées. Je comprends que je peux me désabonner à tout moment.',
    code: [
      createFhirCodingEntityPatient({
        code: 'patientConsent.caslEmailConsent',
      }),
      createFhirCodingEntityPatient({ code: 'patientConsent.caslSmsConsent' }),
    ],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [
      heading,
      subheading1,
      subheading2,
      email,
      home,
      cell,
      phoneType,
      postDesc,
      noEmail,
      noEmailConfirm,
      emailNotificationConsent,
    ],
  })
}

export const patientEmergencyContact = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactHeading',
    type: 'display',
    text: 'Personne à contacter en cas d’urgence',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const ecFirstName = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactFirstName',
    type: 'string',
    text: 'Prénom',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.firstName' }),
    ],
  })

  const ecLastName = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactLastName',
    type: 'string',
    text: 'Nom de famille',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.lastName' }),
    ],
  })

  const ecRelationship = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactRelationship',
    type: 'string',
    text: 'Lien',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.relationship' }),
    ],
  })

  const ecPhone = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactPhone',
    type: 'string',
    text: 'Numéro de téléphone',
    required: true,
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    extension: [
      presetFhirGridExt({ xs: 12, sm: 6 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Numéro de téléphone invalide' },
        ],
      }),
    ],
    code: [createFhirCodingEntityPatient({ code: 'emergencyContact.phone' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactSection',
    type: 'group',
    item: [heading, ecFirstName, ecLastName, ecRelationship, ecPhone],
  })
}
export const fhirContactInformationQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'contactInformation',
  name: 'Coordonnées',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'contactInformationPage',
      type: 'group',
      item: [
        patientInformation(),
        patientAddress(),
        patientContactDetails(),
        patientEmergencyContact(),
      ],
    }),
  ],
})

export const fhirContactInformationSdmClinicJsonFr: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirContactInformationQuestionnaire,
  metadata: {
    id: 'fhirContactInformationSdmClinicJsonFr',
    name: 'Coordonnées',
    path: 'contact-information',
    showOnSteps: true,
  },
}
