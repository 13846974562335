import * as React from 'react'
import { FormProvider } from 'react-hook-form'
import { useFormTimeslotSelection } from './hooks'
import {
  NavigationFooter,
  TimeslotCalendar,
} from '~/pages/AppointmentIntake/components'
import {
  Heading1,
  Heading3,
  FormStepContentLayout,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { Layout } from './BookingErrorTimeslotSelection.style'
import { useBookingContext } from '../../contexts'
import { useInitializeTimeslotSelection } from '../StoreTimeslotsList/TimeslotSelection/hooks'
import { createTimeslot } from '~/lib/timeslot'
import Loading from '~/components/loading'
import { useBookingErrorTimeslotSelectionVerbiage } from './useBookingErrorTimeslotSelectionVerbiage'

export const BookingErrorTimeslotSelection: React.FC = () => {
  const [error, setError] = React.useState('')
  const { setBookingError } = useBookingContext()
  const methods = useFormTimeslotSelection()
  const verbiage = useBookingErrorTimeslotSelectionVerbiage()
  const { startDateTime } = methods.watch(['startDateTime'])

  const {
    state: { pharmacy, appointmentActivity },
    startAppointmentActivityIntakeAsync,
  } = useBookingContext()

  const {
    aai,
    loading: initializing,
    error: initializeError,
  } = useInitializeTimeslotSelection(pharmacy)

  const onSubmit = methods.handleSubmit(async (data) => {
    const { error } = await startAppointmentActivityIntakeAsync(
      pharmacy.id,
      aai.appointmentTypeId,
      createTimeslot({ ...data }),
      false
    )
    if (error) {
      setError('Error booking appointment, please try another timeslot.')
    } else {
      setBookingError('')
    }
  })

  if (initializeError) throw new Error()

  return (
    <FormStepContentLayout>
      <FormProvider {...methods}>
        <Layout onSubmit={onSubmit}>
          <Heading1>{verbiage.title}</Heading1>
          {initializing && <Loading title={verbiage.initializing} />}
          {methods.formState.isSubmitting && (
            <Loading title={verbiage.reserving} overlay={true} />
          )}
          {!initializing && (
            <>
              <TimeslotCalendar
                pharmacy={pharmacy}
                appointmentActivity={appointmentActivity}
                hideHeader={true}
              />
              {error ? (
                <Heading3 style={{ color: 'red' }}>{error}</Heading3>
              ) : null}
              <NavigationFooter
                isNextDisabled={
                  methods.formState.isSubmitting || !startDateTime
                }
                nextButtonLabel={verbiage.nextButtonLabel}
              />
            </>
          )}
        </Layout>
      </FormProvider>
    </FormStepContentLayout>
  )
}
