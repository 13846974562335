import * as React from 'react'
import {
  EAppointmentTypeMethod,
  useGetCoBookableAppointmentActivityInstancesLazyQuery,
} from '~/graphql/types/schemaNode.type'
import { createAppointmentActivityInstance } from '~/lib/appointmentActivity'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { BookingScope } from '~/pages/AppointmentIntake/types'

interface GetCoBookableAAIsParams {
  urlParams: string[]
  method: EAppointmentTypeMethod
  isPublic?: boolean
  scope: BookingScope
  isWaitlisted?: boolean
}

interface UseGetCoBookableAAIsParams {
  language?: string
  isGlobal?: boolean
}

export function useGetCoBookableAAIs({
  language,
  isGlobal,
}: UseGetCoBookableAAIsParams) {
  const [fetchCoBookableAAIs, { data, error }] =
    useGetCoBookableAppointmentActivityInstancesLazyQuery({
      context: {
        clientName: EServer.NODE,
        headers: {
          'accept-language': language || 'en-CA',
        },
      },
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
    })

  const getCoBookableAAIs = React.useCallback(
    async ({
      urlParams,
      method,
      isPublic,
      scope,
      isWaitlisted,
    }: GetCoBookableAAIsParams) => {
      const { data, error } = await fetchCoBookableAAIs({
        variables: {
          ...scope,
          locationId: isGlobal ? undefined : scope.locationId,
          urlParams,
          method,
          isPublic,
          isWaitlisted,
        },
      })

      if (error) {
        throw new Error(error.message)
      }

      return (
        data?.coBookableAppointmentActivityInstances?.map((activity) =>
          createAppointmentActivityInstance(activity)
        ) ?? []
      )
    },
    [fetchCoBookableAAIs, isGlobal]
  )

  return {
    data,
    error,
    getCoBookableAAIs,
  }
}
