import styled from 'styled-components'

export const CustomRadio = styled.div``

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked ~ ${CustomRadio} {
    background-color: ${({ theme }) => theme?.color?.backgroundPrimary};
    color: white;
  }
`
