import React from 'react'

// styles
import { NavigationContainer } from './NavigationFooter.style'

import {
  BackButton,
  SubmitButton,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const NavigationFooter: React.FC<{
  onBack?: (() => void) | null
  onNext?: () => void
  nextButtonType?: 'submit' | 'button'
  isNextDisabled?: boolean
  isDisabled?: boolean
  nextButtonLabel?: string
  backButtonLabel?: string
}> = ({
  onBack = null,
  onNext = () => {
    // @todo fix types for onNext in NavigationFooter
  },
  nextButtonType = 'submit',
  isNextDisabled = false,
  isDisabled = false,
  nextButtonLabel = '',
  backButtonLabel,
}) => {
  const { t } = useMedMeTranslation()

  const nextLabel =
    nextButtonLabel || (nextButtonType === 'submit' ? t('submit') : t('next'))

  return (
    <NavigationContainer>
      {onBack && (
        <BackButton
          type="button"
          data-cy="BackButton"
          onClick={onBack}
          disabled={isDisabled}
        >
          {backButtonLabel ?? t('back')}
        </BackButton>
      )}
      <SubmitButton
        type={nextButtonType}
        data-cy="NextButton"
        disabled={isDisabled || isNextDisabled}
        onClick={onNext}
      >
        {nextLabel}
      </SubmitButton>
    </NavigationContainer>
  )
}
