import { styled } from '@material-ui/core/styles'
import { CachedOutlined } from '@material-ui/icons'

/**
 * Continuously rotating icon for small loading states
 * used by the AlertIcon component
 */
export const AnimatedLoadingIcon = styled(CachedOutlined)({
  '@global': {
    '@keyframes spin': {
      from: {
        transform: 'rotate(359deg)',
      },
      to: {
        transform: 'rotate(0deg)',
      },
    },
  },
  animation: '$spin 1.5s linear infinite',
  '@media (prefers-reduced-motion)': {
    animation: 'none',
  },
})
