import { Extension } from 'fhir/r5'
import { ExtensionRegex, extensionRegexUrl } from './regex.type'

export const isExtensionRegex = (
  extension: Extension
): extension is ExtensionRegex => {
  if (
    extension.url === extensionRegexUrl &&
    extension.valueCode &&
    Object.keys(extension).length === 2
  ) {
    try {
      new RegExp(extension.valueCode, 'g')
      return true
    } catch (error) {
      return false
    }
  }
  return false
}
