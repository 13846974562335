import * as React from 'react'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import { FhirStemComponent } from '../FhirQuestionnaireItemComponent/FhirStemComponent'
import { GridWithDisabledFontLigatures } from './FhirQuestionnaireComponent.styles'

export const FhirQuestionnaireComponent: React.FC<{
  questionnaire: FhirQuestionnaire
}> = ({ questionnaire }) => {
  return (
    <GridWithDisabledFontLigatures container spacing={1}>
      {questionnaire.item.map((item, i) => (
        <FhirStemComponent
          questionnaireItem={item}
          isDisabled={false}
          key={i}
        />
      ))}
    </GridWithDisabledFontLigatures>
  )
}
