export const getAutoCompleteLine = (linkId: string) => {
  let line
  switch (linkId) {
    case 'patientUnit':
      line = 'address-line1'
      break
    case 'patientStreetNumber':
      line = 'address-line2'
      break
    case 'patientStreetName':
      line = 'address-line3'
      break
    default:
      line = undefined
      break
  }
  return line
}
