import React from 'react'
import { CheckboxField } from '~/controlledContextInputs'
import { FhirLeafComponent } from '../FhirLeafComponent'

export const FhirBooleanInput: typeof FhirLeafComponent = ({
  isDisabled,
  questionnaireItem,
}) => {
  return (
    <CheckboxField
      name={questionnaireItem.linkId}
      label={questionnaireItem.getText()}
      disabled={isDisabled}
      required={questionnaireItem.required}
    />
  )
}
