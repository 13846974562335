import { useEffect, useState } from 'react'
import { reportException } from '~/tools/monitoring'

enum State {
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
  Success = 'success',
}

export type UseGetIdpIntegrationsProps = {
  client: {
    initialize(): Promise<void>
  }
}

export const useInitializeAuthClient = ({
  client,
}: UseGetIdpIntegrationsProps) => {
  const [state, setState] = useState<State>(State.Idle)

  useEffect(() => {
    setState(State.Loading)

    const initializeClient = async () => {
      try {
        await client.initialize()
        setState(State.Success)
      } catch (error) {
        reportException(error)
        setState(State.Error)
      }
    }

    initializeClient()
  }, [client])

  return {
    loading: state === State.Loading,
    error: state === State.Error,
    idle: state === State.Idle,
    success: state === State.Success,
  }
}
