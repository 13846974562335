/**
 * Naive typeguard for a PropertySafeValueConversionFunction
 * Assumes that if `maybePropFn` is a function with an arity of 1, then it's a PropertySafeValueConversionFunction
 *
 * @param maybePropFn
 */
export const isPropertySafeValueConversionFunction = (
  maybePropFn: unknown
): maybePropFn is <T>(a: unknown) => T => {
  return typeof maybePropFn === 'function' && maybePropFn.length === 1
}
