import * as React from 'react'
import { RouterContext } from '../RouterContext'

export const useRouterContext = () => {
  const context = React.useContext(RouterContext)
  if (context === undefined) {
    throw new Error('useRouterContext must be used within a RouterProvider')
  }
  return context
}
