import * as React from 'react'
import { FormProvider } from 'react-hook-form'
import { Pharmacy } from '~/lib/pharmacy'

import { CardLayout } from '~/pages/AppointmentIntake/AppointmentIntake.style'

import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { createTimeslot } from '~/lib/timeslot'
import { TimeslotCalendar } from '../../../components/TimeslotCalendar'
import { NavigationFooter } from '~/pages/AppointmentIntake/components'
import { ReserveTimeslot, ReserveTimeslotError } from './ReserveTimeslot'
import Dialog from '@material-ui/core/Dialog'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager'
import Loading from '~/components/loading'
import {
  useFormTimeslotSelection,
  useInitializeTimeslotSelection,
} from './hooks'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const TimeslotSelection: React.FC<{
  pharmacy: Pharmacy
}> = ({ pharmacy }) => {
  const { startAppointmentActivityIntakeAsync } = useBookingContext()
  const { nextStep, prevStep } = useFormManager()
  const { t } = useMedMeTranslation('common')
  const {
    aai,
    loading: initializing,
    error: initializeError,
  } = useInitializeTimeslotSelection(pharmacy)
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false)
  const [reservationError, setReservationError] = React.useState('')
  const methods = useFormTimeslotSelection()

  const { isWalkin, startDateTime } = methods.watch([
    'isWalkin',
    'startDateTime',
  ])

  const isWalkinBool = isWalkin === 'true'

  const onSubmit = methods.handleSubmit(async (data) => {
    const { error } = await startAppointmentActivityIntakeAsync(
      pharmacy.id,
      aai.appointmentTypeId,
      createTimeslot({ ...data }),
      isWalkinBool
    )
    if (error) {
      setReservationError(error)
      return
    }
    nextStep()
  })

  const loading = initializing || methods.formState.isSubmitting
  if (initializeError) throw new Error(initializeError)

  return (
    <FormProvider {...methods}>
      <CardLayout onSubmit={onSubmit}>
        {initializing && <Loading title={t('loading.title.loading')} />}
        {methods.formState.isSubmitting && (
          <Loading title={t('loading.title.reserving')} overlay={true} />
        )}
        {!loading && (
          <>
            <Dialog
              open={showConfirmationModal}
              onClose={() => setShowConfirmationModal(false)}
              disablePortal
            >
              {reservationError ? (
                <ReserveTimeslotError
                  onClose={() => {
                    setShowConfirmationModal(false)
                    setReservationError('')
                  }}
                />
              ) : (
                <ReserveTimeslot
                  pharmacy={pharmacy}
                  appointmentActivity={aai}
                />
              )}
            </Dialog>

            <TimeslotCalendar pharmacy={pharmacy} appointmentActivity={aai} />

            <NavigationFooter
              isNextDisabled={
                methods.formState.isSubmitting ||
                (!startDateTime && !isWalkinBool)
              }
              nextButtonLabel={t('continueButtonLabel')}
              nextButtonType={isWalkinBool ? 'submit' : 'button'}
              onNext={() => !isWalkinBool && setShowConfirmationModal(true)}
              onBack={prevStep}
            />
          </>
        )}
      </CardLayout>
    </FormProvider>
  )
}
