import { transformToStandardPhoneNumber } from '~/util/formatContact'
import { DeepPartial } from '~/pages/AppointmentIntake/lib/types'
import { Pharmacy } from '~/graphql/types/schema.type'
import { createAppointmentType } from '~/lib/appointmentType'
import {
  AvailabilityDateTimeValue,
  Distance,
} from '~/graphql/types/schemaNode.type'

export const createPharmacy = (
  pharmacy?:
    | (DeepPartial<Pharmacy> & {
        distance?: Distance | null
        availabilityDateTime?: AvailabilityDateTimeValue | null
      })
    | null
) => {
  return {
    id: pharmacy?.id ?? '', //'57e07f95-d457-45dd-b302-ed096c3e2815',
    name: pharmacy?.name ?? '',
    enterprise: pharmacy?.enterprise ?? '',
    timeZone: pharmacy?.timeZone ?? '',
    storeNo: pharmacy?.storeNo ?? '',
    logoCircle: pharmacy?.logoCircle ?? '',
    logoLong: pharmacy?.logoLong ?? '',
    logoLongInverse: pharmacy?.logoLongInverse ?? '',
    theme: pharmacy?.theme ?? null,
    province: pharmacy?.province ?? '',
    distance: pharmacy?.distance ?? null,
    pharmacyAddress: {
      poBox: pharmacy?.pharmacyAddress?.poBox ?? '',
      unit: pharmacy?.pharmacyAddress?.unit ?? '',
      streetNumber: pharmacy?.pharmacyAddress?.streetNumber ?? '',
      streetName: pharmacy?.pharmacyAddress?.streetName ?? '',
      city: pharmacy?.pharmacyAddress?.city ?? '',
      province: pharmacy?.pharmacyAddress?.province ?? '',
      postalCode: pharmacy?.pharmacyAddress?.postalCode ?? '',
      country: pharmacy?.pharmacyAddress?.country ?? '',
      latitude: Number.isNaN(
        parseFloat(pharmacy?.pharmacyAddress?.latitude ?? '')
      )
        ? null
        : Number(pharmacy?.pharmacyAddress?.latitude),
      longitude: Number.isNaN(
        parseFloat(pharmacy?.pharmacyAddress?.longitude ?? '')
      )
        ? null
        : Number(pharmacy?.pharmacyAddress?.longitude),
    },
    pharmacyContact: {
      phone: transformToStandardPhoneNumber(
        pharmacy?.pharmacyContact?.phone ?? ''
      ),
      fax: transformToStandardPhoneNumber(pharmacy?.pharmacyContact?.fax ?? ''),
      email: pharmacy?.pharmacyContact?.email ?? '',
    },
    appointmentTypes:
      pharmacy?.appointmentTypes?.map(createAppointmentType) ?? [],
    availabilityDateTime: pharmacy?.availabilityDateTime ?? null,
  }
}
