import moment from 'moment'

const initialState = {
  offset: 0,
  search: '',
  showFilterOptions: false,
  appointmentTypes: null,
  vaccineNames: null,
  appointmentStatuses: null,
  createdAt: null,
  appointmentDate: {
    startDateTime: moment().startOf('day').utc().format(),
    endDateTime: moment().add(7, 'days').endOf('day').utc().format(),
  },
  patientAge: null,
  caslEmailConsent: null,
  patientBirthYear: null,
  isAge: true,
  currentView: 'WEEK',
  pharmacistId: '',
  email: null,
  cell: null,
  staySwitch: null,
  modeOfBooking: null,
  postalCode: '',
}

const UPDATE_OFFSET = 'APPOINTMENT_LIST_UPDATE_OFFSET'
const UPDATE_SEARCH = 'APPOINTMENT_LIST_UPDATE_SEARCH'
const CLEAR_FILTER = 'APPOINTMENT_LIST_CLEAR_FILTER'
const UPDATE_APPOINTMENT_TYPES = 'APPOINTMENT_LIST_UPDATE_APPOINTMENT_TYPES'
const UPDATE_VACCINE_NAMES = 'APPOINTMENT_LIST_UPDATE_VACCINE_NAMES'
const UPDATE_APPOINTMENT_STATUSES =
  'APPOINTMENT_LIST_UPDATE_APPOINTMENT_STATUSES'
const UPDATE_SHOW_FILTER_OPTIONS = 'APPOINTMENT_LIST_UPDATE_SHOW_FILTER_OPTIONS'
const UPDATE_CREATED_AT = 'APPOINTMENT_LIST_UPDATE_CREATED_AT'
const UPDATE_APPOINTMENT_DATE = 'APPOINTMENT_LIST_UPDATE_APPOINTMENT_DATE'
const UPDATE_AGE = 'APPOINTMENT_LIST_UPDATE_AGE'
const UPDATE_PATIENT_BIRTH_YEAR = 'APPOINTMENT_LIST_UPDATE_PATIENT_BIRTH_YEAR'
const UPDATE_IS_AGE = 'APPOINTMENT_LIST_UPDATE_IS_AGE'
const UPDATE_EMAIL = 'APPOINTMENT_LIST_UPDATE_EMAIL'
const UPDATE_CELL = 'APPOINTMENT_LIST_UPDATE_CELL'
const UPDATE_CASL_CONSENT = 'APPOINTMENT_LIST_UPDATE_CASL_CONSENT'
const UPDATE_CURRENT_VIEW = 'APPOINTMENT_LIST_UPDATE_CURRENT_VIEW'
const UPDATE_PHARMACIST_ID = 'APPOINTMENT_LIST_UPDATE_PHARMACIST_ID'
const UPDATE_STAY_SWITCH = 'APPOINTMENT_LIST_UPDATE_STAY_SWITCH'
const UPDATE_MODE_OF_BOOKING = 'APPOINTMENT_LIST_UPDATE_MODE_OF_BOOKING'
const UPDATE_POSTAL_CODE_FILTER = 'APPOINTMENT_LIST_UPDATE_POSTAL_CODE_FILTER'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OFFSET:
      return {
        ...state,
        offset: action.offset,
      }
    case UPDATE_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    case UPDATE_APPOINTMENT_TYPES:
      return {
        ...state,
        appointmentTypes: action.appointmentTypes,
      }
    case UPDATE_VACCINE_NAMES:
      return {
        ...state,
        vaccineNames: action.vaccineNames,
      }
    case UPDATE_APPOINTMENT_STATUSES:
      return {
        ...state,
        appointmentStatuses: action.appointmentStatuses,
      }
    case CLEAR_FILTER:
      return {
        ...initialState,
        showFilterOptions: state.showFilterOptions,
      }
    case UPDATE_SHOW_FILTER_OPTIONS:
      return {
        ...state,
        showFilterOptions: action.toggleFilterOptions,
      }
    case UPDATE_CREATED_AT:
      return {
        ...state,
        createdAt: action.createdAt,
      }
    case UPDATE_APPOINTMENT_DATE:
      return {
        ...state,
        appointmentDate: action.appointmentDate,
      }
    case UPDATE_AGE:
      return {
        ...state,
        patientAge: action.patientAge,
      }
    case UPDATE_PATIENT_BIRTH_YEAR:
      return {
        ...state,
        patientBirthYear: action.patientBirthYear,
      }
    case UPDATE_IS_AGE:
      return {
        ...state,
        isAge: action.isAge,
      }
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.email,
      }
    case UPDATE_CELL:
      return {
        ...state,
        cell: action.cell,
      }
    case UPDATE_CASL_CONSENT:
      return {
        ...state,
        caslEmailConsent: action.caslEmailConsent,
      }
    case UPDATE_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.currentView,
      }
    case UPDATE_PHARMACIST_ID:
      return {
        ...state,
        pharmacistId: action.pharmacistId,
      }
    case UPDATE_STAY_SWITCH:
      return {
        ...state,
        staySwitch: action.staySwitch,
      }
    case UPDATE_MODE_OF_BOOKING:
      return {
        ...state,
        modeOfBooking: action.modeOfBooking,
      }
    case UPDATE_POSTAL_CODE_FILTER:
      return {
        ...state,
        postalCode: action.postalCode,
      }
    default:
      return state
  }
}

export const updateOffset = (offset) => ({
  type: UPDATE_OFFSET,
  offset,
})

export const updateSearch = (search) => ({
  type: UPDATE_SEARCH,
  search,
})

export const updateAppointmentTypes = (appointmentTypes) => ({
  type: UPDATE_APPOINTMENT_TYPES,
  appointmentTypes,
})

export const updateVaccineNames = (vaccineNames) => ({
  type: UPDATE_VACCINE_NAMES,
  vaccineNames,
})

export const updateAppointmentStatuses = (appointmentStatuses) => ({
  type: UPDATE_APPOINTMENT_STATUSES,
  appointmentStatuses,
})

export const clearFilter = () => ({
  type: CLEAR_FILTER,
})

export const updateShowFilterOptions = (toggleFilterOptions) => ({
  type: UPDATE_SHOW_FILTER_OPTIONS,
  toggleFilterOptions,
})

export const updateCreatedAt = (createdAt) => ({
  type: UPDATE_CREATED_AT,
  createdAt,
})

export const updateAppointmentDate = (appointmentDate) => ({
  type: UPDATE_APPOINTMENT_DATE,
  appointmentDate,
})

export const updateAge = (patientAge) => ({
  type: UPDATE_AGE,
  patientAge,
})

export const updatePatientBirthYear = (patientBirthYear) => ({
  type: UPDATE_PATIENT_BIRTH_YEAR,
  patientBirthYear,
})

export const updateIsAge = (isAge) => ({
  type: UPDATE_IS_AGE,
  isAge,
})

export const updateEmail = (email) => ({
  type: UPDATE_EMAIL,
  email,
})

export const updateCell = (cell) => ({
  type: UPDATE_CELL,
  cell,
})
export const updateCaslConsent = (caslEmailConsent) => ({
  type: UPDATE_CASL_CONSENT,
  caslEmailConsent,
})

export const updateCurrentView = (currentView) => ({
  type: UPDATE_CURRENT_VIEW,
  currentView,
})

export const updatePharmacistId = (pharmacistId) => ({
  type: UPDATE_PHARMACIST_ID,
  pharmacistId,
})

export const updateStaySwitch = (staySwitch) => ({
  type: UPDATE_STAY_SWITCH,
  staySwitch,
})

export const updateModeOfBooking = (modeOfBooking) => ({
  type: UPDATE_MODE_OF_BOOKING,
  modeOfBooking,
})

export const updatePostalCode = (postalCode) => ({
  type: UPDATE_POSTAL_CODE_FILTER,
  postalCode,
})

export default reducer
