import { QuestionnaireItem } from 'fhir/r4'
import {
  createJsonBoolean,
  createJsonString,
} from '../../../../../../../lib/json'
import { createFhirIntegerItem } from './fhirIntegerItem'
import { createFhirBooleanItem } from './fhirBooleanItem'
import { createFhirChoiceItem } from './fhirChoiceItem'
import { createFhirDateItem } from './fhirDateItem'
import { createFhirStringItem } from './fhirStringItem'

const typeSpecificInputItemProps: typeof createFhirInputItem = (json) => {
  switch (json.type) {
    case 'string':
      return createFhirStringItem(json)
    case 'boolean':
      return createFhirBooleanItem(json)
    case 'integer':
      return createFhirIntegerItem(json)
    case 'date':
      return createFhirDateItem(json)
    case 'choice':
      return createFhirChoiceItem(json)
    default:
      throw new Error(
        `typeSpecificInputItemProps: json.item is not a valid type, got ${json.type}`
      )
  }
}

export const createFhirInputItem = (
  json: QuestionnaireItem
): Omit<QuestionnaireItem, 'linkId'> => {
  const { item, ...rest } = typeSpecificInputItemProps(json)

  return {
    text: createJsonString(json.text ?? ''),
    required: createJsonBoolean(json.required ?? false),
    ...rest,
  }
}

export type FhirInputItem = ReturnType<typeof createFhirInputItem>
