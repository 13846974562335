import { Extension } from 'fhir/r5'
import { fhirStructDefUrlMedMe } from '../constants'

const extPathName = 'styling'

export const extensionStylingUrl =
  `${fhirStructDefUrlMedMe}/${extPathName}` as const

export const stylingGridColumnCodes = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'auto',
] as const
export interface ExtensionStylingGridColumn
  extends Pick<Extension, 'url' | 'valueCode'> {
  url: 'gridColumn'
  valueCode: typeof stylingGridColumnCodes[number]
}

export interface ExtensionStyling extends Pick<Extension, 'url' | 'extension'> {
  url: typeof extensionStylingUrl
  extension: ExtensionStylingGridColumn[]
}
