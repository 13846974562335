import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Eligibility Screening ',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const completingQuestions = createFhirQuestionnaireItem({
    text: 'If completing on behalf of someone else, answer from their perspective.',
    linkId: 'completingQuestions',
    type: 'display',
  })

  const assignedSex = createFhirQuestionnaireItem({
    text: 'Was your assigned sex at birth female?',
    linkId: 'assignedSex',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const symptoms = createFhirQuestionnaireItem({
    text: 'Do you have any of the following symptoms?\n\n- Frequent, persistent urge to urinate (go to the washroom) despite passing a small amount of urine\n- Discomfort or burning sensation during urination',
    linkId: 'symptoms',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const fever = createFhirQuestionnaireItem({
    text: 'Do you have a fever >100°F, chills, or back pain?',
    linkId: 'fever',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const kidneyStones = createFhirQuestionnaireItem({
    text: 'Do you have a history of kidney stones?',
    linkId: 'kidneyStones',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const immunosuppression = createFhirQuestionnaireItem({
    text: 'Do you have any conditions that cause immunosuppression and reduce your body’s ability to fight infection (e.g., HIV infection, chemotherapy treatment, etc.)?',
    linkId: 'immunosuppression',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const kidneyTransplant = createFhirQuestionnaireItem({
    text: 'Have you had a kidney transplant?',
    linkId: 'kidneyTransplant',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const catheter = createFhirQuestionnaireItem({
    text: 'Do you have an indwelling catheter (a tube running from the bladder to an outer urine collection bag)?',
    linkId: 'catheter',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      assignedSex,
      symptoms,
      fever,
      kidneyStones,
      immunosuppression,
      kidneyTransplant,
      catheter,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningUrinaryTractInfectionIDJson: JsonFhirFormStep =
  {
    type: 'fhir',
    fhir: fhirMedicalScreeningQuestionnaire,
    filter: {
      regionCodes: ['ID'],
      isEnterprise: false,
      appointmentActivity: {
        urlParams: ['UrinaryTractInfection', 'uti-treatment'],
      },
    },
    metadata: {
      id: 'fhirMedicalScreeningUTIJson',
      name: 'Medical Screening',
      path: 'medical-screening',
      showOnSteps: true,
    },
  }
