import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles'

/**
 * Button styled using the Error color palette,
 * extends MUI Button
 */
export const ButtonError = styled(Button)(({ theme }) => ({
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.error.dark,
    color: 'white',
  },
  '&.MuiButton-outlined': {
    color: theme.palette.error.dark,
    borderColor: 'currentcolor',
  },
  '&.MuiButton-text': {
    color: theme.palette.error.dark,
  },
}))
