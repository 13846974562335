import * as React from 'react'
import { Moment } from 'moment'

import { NavigationContainer, NavigationContext } from './CalendarNavbar.style'

import { NextNavButton } from './NextNavButton'
import { PrevNavButton } from './PrevNavButton'

export const CalendarNavbar: React.FC<{
  startDateMTz: Moment
  numDays: number
  onPrevClick: () => void
  onNextClick: () => void
  isPrevDisabled: boolean
  isNextDisabled: boolean
  isDisabled: boolean
}> = ({
  startDateMTz,
  numDays,
  onPrevClick,
  onNextClick,
  isPrevDisabled,
  isNextDisabled,
  isDisabled,
}) => {
  const content = `${startDateMTz.format('lll')} - ${startDateMTz
    .add(numDays - 1, 'days')
    .format('lll')}`

  return (
    <NavigationContainer disabled={isDisabled}>
      <PrevNavButton
        onClick={onPrevClick}
        isDisabled={isPrevDisabled}
        numDays={numDays}
      />

      <NavigationContext>{content}</NavigationContext>

      <NextNavButton
        onClick={onNextClick}
        isDisabled={isNextDisabled}
        numDays={numDays}
      />
    </NavigationContainer>
  )
}
