import { Engine, RuleSerializable } from 'json-rules-engine'
import {
  FhirQuestionnaire,
  FhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createEnableWhenRule } from './rules/createEnabledWhenRule'

const getEnableWhenEventRule = (
  item: FhirQuestionnaireItem
): RuleSerializable[] => {
  const { enableWhen } = item
  const enableWhenRule = enableWhen ? [createEnableWhenRule(item)] : []

  return enableWhenRule
}

const getEnableWhenEventRulesFromItems = (
  items: FhirQuestionnaireItem[]
): RuleSerializable[] => {
  return (
    items.reduce((acc, item) => {
      if (item.type === 'group') {
        if (item.item) {
          return [
            ...acc,
            ...getEnableWhenEventRule(item),
            ...getEnableWhenEventRulesFromItems(item.item),
          ]
        } else {
          return acc
        }
      }
      const itemRules = getEnableWhenEventRule(item)
      return [...acc, ...itemRules]
    }, []) ?? []
  )
}

export const addEnableWhenEventRules =
  (questionnaire: FhirQuestionnaire) => (rulesEngine: Engine) => {
    const rules = getEnableWhenEventRulesFromItems(questionnaire.item)

    rules.forEach((rule) => rulesEngine.addRule(rule))
    return rulesEngine
  }
