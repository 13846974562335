import * as React from 'react'
import Loading from '~/components/loading'
import { createAppointmentActivityGroup } from '~/lib/appointmentActivityGroup'
import {
  createAppointmentActivityGroupContext,
  AppointmentActivityGroupContext,
  AppointmentActivityGroupContextInterface,
} from '../AppointmentActivityGroupContext'
import { useInitialAppointmentActivityGroup } from './useInitialAppointmentActivityGroup'

const reducer = (
  state: AppointmentActivityGroupContextInterface,
  action: any
): AppointmentActivityGroupContextInterface => {
  switch (action.type) {
    case 'initializeAppointmentActivityGroup':
      return {
        ...state,
        initialAppointmentActivityGroup: action.appointmentActivityGroup,
        appointmentActivityGroup: action.appointmentActivityGroup,
      }
    case 'setAppointmentActivityGroup':
      return {
        ...state,
        appointmentActivityGroup: action.appointmentActivityGroup,
      }
    default:
      console.log('unknown action type', action.type, action)
      return state
  }
}

export const AppointmentActivityGroupProvider: React.FC = ({ children }) => {
  const [context, dispatch] = React.useReducer(
    reducer,
    createAppointmentActivityGroupContext()
  )
  const [initialized, setInitialized] = React.useState(false)
  const { appointmentActivityGroup, loading, error } =
    useInitialAppointmentActivityGroup()

  React.useEffect(() => {
    if (!loading) {
      dispatch({
        type: 'initializeAppointmentActivityGroup',
        appointmentActivityGroup: createAppointmentActivityGroup(
          appointmentActivityGroup
        ),
      })
      setInitialized(true)
    }
  }, [loading])

  if (!initialized) return <Loading title="Loading AppointmentActivityGroup" />
  if (error) throw new Error(error)

  return (
    <AppointmentActivityGroupContext.Provider value={{ ...context, dispatch }}>
      {children}
    </AppointmentActivityGroupContext.Provider>
  )
}
