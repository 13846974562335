import {
  Address,
  AddressDto,
  Patient,
  Phone,
} from '~/graphql/types/schema.type'
import {
  DeepPartial,
  ObjectPropTypeFns,
} from '~/pages/AppointmentIntake/lib/types'
import { createPatientLanguageCode } from './createPatientLanguageCode'

type PartialGraphql<T> = Partial<Omit<T, '__typename'>>

export type PartialPatient =
  | (DeepPartial<Omit<Patient, '__typename' | 'phone' | 'allergies'>> & {
      phone?: PartialGraphql<Phone> | undefined | null
      address?:
        | PartialGraphql<Address>
        | PartialGraphql<AddressDto | Address>
        | undefined
        | null
    })
  | undefined
  | null

export const createPatient = (patient?: PartialPatient) => {
  return {
    id: patient?.id ?? '',
    firstName: patient?.firstName ?? '',
    lastName: patient?.lastName ?? '',
    sex: patient?.sex ?? '',
    gender: patient?.gender ?? '',
    birthDate: patient?.birthDate ?? '',
    email: patient?.email ?? '',
    languageCode: patient?.languageCode ?? null,
    address: {
      unit: patient?.address?.unit ?? '',
      streetNumber: patient?.address?.streetNumber ?? '',
      streetName: patient?.address?.streetName ?? '',
      city: patient?.address?.city ?? '',
      province: patient?.address?.province ?? '',
      postalCode: patient?.address?.postalCode ?? '',
      country: patient?.address?.country ?? '',
      poBox: patient?.address?.poBox ?? '',
    },
    phone: {
      home: patient?.phone?.home ?? '',
      cell: patient?.phone?.cell ?? '',
    },
    healthCard: {
      number: patient?.healthCard?.number ?? '',
      versionCode: patient?.healthCard?.versionCode ?? '',
      expiry: patient?.healthCard?.expiry ?? '',
      province: patient?.healthCard?.province ?? '',
    },
    primaryCareProvider: {
      firstName: patient?.primaryCareProvider?.firstName ?? '',
      lastName: patient?.primaryCareProvider?.lastName ?? '',
      phone: patient?.primaryCareProvider?.phone ?? '',
      email: patient?.primaryCareProvider?.email ?? '',
    },
    emergencyContact: {
      firstName: patient?.emergencyContact?.firstName ?? '',
      lastName: patient?.emergencyContact?.lastName ?? '',
      relationship: patient?.emergencyContact?.relationship ?? '',
      phone: patient?.emergencyContact?.phone ?? '',
    },
    patientConsent: {
      caslEmailConsent: patient?.patientConsent?.caslEmailConsent ?? false,
      caslSmsConsent: patient?.patientConsent?.caslSmsConsent ?? false,
    },
  }
}

export const patientPropFns: ObjectPropTypeFns<
  ReturnType<typeof createPatient>
> = {
  id: String,
  firstName: String,
  lastName: String,
  sex: String,
  gender: String,
  birthDate: String,
  email: String,
  languageCode: createPatientLanguageCode,
  address: {
    unit: String,
    streetNumber: String,
    streetName: String,
    city: String,
    province: String,
    postalCode: String,
    country: String,
    poBox: String,
  },
  phone: {
    home: String,
    cell: String,
  },
  healthCard: {
    number: String,
    versionCode: String,
    expiry: String,
    province: String,
  },
  primaryCareProvider: {
    firstName: String,
    lastName: String,
    phone: String,
    email: String,
  },
  emergencyContact: {
    firstName: String,
    lastName: String,
    relationship: String,
    phone: String,
  },
  patientConsent: {
    caslEmailConsent: Boolean,
    caslSmsConsent: Boolean,
  },
}
