import { createContext } from 'react'
import { PatientRegistrationState } from './patientRegistration.reducer'
import { TSFixMe } from '~/types/commonTypes'
export interface PatientRegistrationContextInput {
  pharmacyLogo: string
  patientIndex: number
  theme: TSFixMe
  pharmacy: TSFixMe
  forms: TSFixMe
  state: PatientRegistrationState
  dispatch: TSFixMe
  submitData: TSFixMe
  previousScreen: () => void
  goToStart: () => void
  handleEdit: () => void
  hasBookingReference: boolean
  handlePageNavigation: () => void
  isLastForm: boolean
}

const PatientRegistrationContext =
  createContext<PatientRegistrationContextInput>(
    {} as PatientRegistrationContextInput
  )

export default PatientRegistrationContext
