import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../../types'
import { LanguageCodesFE } from '~/types/languageEnums'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Dépistage des urgences médicales',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'Si vous êtes en situation d’urgence médicale, composez le 9-1-1 ou rendez-vous immédiatement aux urgences de l’hôpital le plus proche.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })
  const utiEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Moins de 16 ans et plus de 64 ans (les pharmaciens ne peuvent évaluer et prescrire que pour les personnes âgées de 16 à 64 ans).
- Personnes de sexe masculin assigné à la naissance
- Femmes enceintes
    `,
    linkId: 'utiEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'UrinaryTractInfection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const coldSoreEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Personnes âgées de moins de 12 ans.
    `,
    linkId: 'coldSoreEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ColdSores',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const pinkEyeEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Enfants de moins de 1 an
    `,
    linkId: 'pinkEyeEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'PinkEye',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const skinRashEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Enfants de moins de 2 ans.
    `,
    linkId: 'skinRashEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'SkinRash',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const contraceptionEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Personnes âgées de moins de 12 ans et de plus de 50 ans (les pharmaciens ne peuvent évaluer et prescrire des médicaments que pour les personnes âgées de 12 à 50 ans)
    `,
    linkId: 'contraceptionEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Contraception',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const heartBurnEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Enfants de moins de 18 ans.
    `,
    linkId: 'heartBurnEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'HeartBurn',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const impetigoEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Enfants de moins de 2 ans.
    `,
    linkId: 'impetigoEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Impetigo',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const acneEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Personnes de moins de 12 ans et de plus de 30 ans (s’il s’agit d’une nouvelle apparition d’acné)
    `,
    linkId: 'acneEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Acne',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const shinglesEligibility = createFhirQuestionnaireItem({
    text: `Les pharmaciens ne peuvent ni évaluer ni prescrire pour les personnes suivantes :
- Personnes de moins de 12 ans
    `,
    linkId: 'shinglesEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Shingles',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const eligibility = createFhirQuestionnaireItem({
    text: 'L’admissibilité à l’évaluation et à la prescription de médicaments pour des problèmes de santé courants sera évaluée par le pharmacien au moment de votre rendez-vous.',
    linkId: 'eligibility',
    type: 'display',
  })
  const healthcardPaymentDisclaimerHeader = createFhirQuestionnaireItem({
    text: 'AVERTISSEMENT:',
    linkId: 'healthcardDisclaimerHeader',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const reimbursed = createFhirQuestionnaireItem({
    text: 'Si vous n’avez pas de carte d’assurance-maladie du Nouveau-Brunswick valide. Des honoraires professionnels s’appliquent.',
    linkId: 'reimbursed',
    type: 'display',
  })
  const medicationPaymentDisclaimer = createFhirQuestionnaireItem({
    text: 'Si une ordonnance est rédigée pour ce service, le coût du médicament n’est pas inclus dans l’évaluation et sera à mes frais ou couvert par mon régime d’assurance-médicaments.',
    linkId: 'medicationPaymentDisclaimer',
    type: 'display',
  })
  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      utiEligibility,
      coldSoreEligibility,
      pinkEyeEligibility,
      skinRashEligibility,
      contraceptionEligibility,
      heartBurnEligibility,
      impetigoEligibility,
      acneEligibility,
      shinglesEligibility,
      eligibility,
      healthcardPaymentDisclaimerHeader,
      reimbursed,
      medicationPaymentDisclaimer,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirReimbursedMAMedicalScreeningSdmNBJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['NB'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: [
        'SkinRash',
        'HeartBurn',
        'PinkEye',
        'ColdSores',
        'PinkEye',
        'Impetigo',
        'UrinaryTractInfection',
        'Shingles',
        'Contraception',
        'Acne',
        'TickBites',
      ],
    },
  },
  metadata: {
    id: 'frenchFhirReimbursedMAMedicalScreeningSdmNBJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
    languageCode: LanguageCodesFE.FR,
  },
}
