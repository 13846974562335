import { useGetBookingByKeysQuery } from '~/graphql/types/schemaNode.type'
import { useRouterContext } from '../../RouterContext'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { QuestionnaireResponse } from '~/lib/appointment'
import { createAppointment } from '~/lib/appointment'
import { getJavaAppointmentFromNode } from '~/util/adapters/nodeToJavaAppointment'

export const useCurrentAppointment = () => {
  const { appointmentTypeWithCalendarToken, patientToken, appointmentToken } =
    useRouterContext()

  const {
    data: appointmentData,
    loading,
    error,
  } = useGetBookingByKeysQuery({
    variables: {
      key1: appointmentTypeWithCalendarToken,
      key2: patientToken,
      key3: appointmentToken,
    },
    context: {
      clientName: EServer.NODE,
    },
    skip: !(
      appointmentTypeWithCalendarToken &&
      patientToken &&
      appointmentToken
    ),
  })

  const nodeAppointment =
    appointmentData?.getBookingByKeys?.patients?.[0]?.appointments?.[0]

  const questionnaireResponses =
    nodeAppointment?.questionnaireResponses as QuestionnaireResponse[]

  const javaAppointment = getJavaAppointmentFromNode(nodeAppointment)

  const appointment = createAppointment({
    ...javaAppointment,
    questionnaireResponses: questionnaireResponses,
  })

  return {
    appointment,
    loading: loading,
    error,
  }
}
