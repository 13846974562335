export const SEARCH_RADII = [
  5, 10, 25, 50, 75, 100, 150, 200, 250, 300, 400, 500,
]
export const INITIAL_SEARCH_RADIUS = SEARCH_RADII[0]
export const RURAL_INITIAL_SEARCH_RADIUS = SEARCH_RADII[2]
export const MAX_SEARCH_RADIUS = SEARCH_RADII[SEARCH_RADII.length - 1]

export const SEARCH_RADIUS_ZOOM_SCALE: Record<number, number> = {
  5: 10.5,
  10: 9.5,
  25: 8.5,
  50: 7.3,
  75: 6.7,
  100: 6.3,
  150: 5.7,
  200: 5.5,
  250: 5.2,
  300: 4.9,
  400: 4.3,
  500: 3.9,
}

export const MAX_NAD_FETCH_WINDOW_SIZE_DAYS = 28
