import { AppointmentModel, EFormStatus } from '~/graphql/types/schema.type'
import {
  EAppointmentTypeMethod,
  EAppointmentTypeCategory,
} from '~/graphql/types/schemaNode.type'

export const getJcgFormStatus = (
  appointment: AppointmentModel | null | undefined
) => {
  /**
   * Adopted from https://github.com/medmehealth/medscheck-java-backend/blob/f3a64fdda06a4129128cad486da320d1e2f6b75e/src/main/java/ca/medmehealth/medscheck/service/query/TabsQueryService.java#L669
   */

  if (appointment?.appointmentStatus?.toLowerCase() === 'cancelled') {
    return EFormStatus.CANCELLED
  } else if (appointment?.receivedDate === null) {
    return EFormStatus.REQUESTED
  } else if (appointment?.isPrinted && appointment?.receivedDate) {
    return EFormStatus.REVIEWED
  } else if (!appointment?.isPrinted && appointment?.receivedDate) {
    return EFormStatus.PENDING_REVIEW
  }

  return null
}

export const isValidAppointmentMethod = (
  value: any
): value is EAppointmentTypeMethod => {
  return Object.values(EAppointmentTypeMethod).includes(value)
}

export const getInjectionNameAndReasonForVisit = (
  injectionName: string,
  reasonForVisit: string,
  category: string
) => {
  if (
    !injectionName &&
    category !== EAppointmentTypeCategory.IMMUNIZATION_INJECTION_SDM &&
    !reasonForVisit
  ) {
    return null
  }

  const injectionNameText = injectionName
    ? `(${injectionName})`
    : category === EAppointmentTypeCategory.IMMUNIZATION_INJECTION_SDM
    ? '(No Rx)'
    : ''

  const reasonForVisitText = reasonForVisit
    ? reasonForVisit.length > 20
      ? `(${reasonForVisit.slice(0, 20)}...)`
      : `(${reasonForVisit})`
    : ''

  return `${injectionNameText} ${reasonForVisitText}`.trim()
}
