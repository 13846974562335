import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import MuiTextField from '@material-ui/core/TextField'

export const NumberField: React.FC<{
  name: string
  label?: string
  defaultValue?: number
  helperText?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  inputProps?: React.ComponentProps<typeof MuiTextField>['inputProps']
}> = ({
  name,
  label,
  defaultValue = 0,
  helperText,
  placeholder,
  disabled = false,
  required = false,
}) => {
  const { errors } = useFormContext()
  const { field } = useController({
    name: name,
    defaultValue: defaultValue,
  })
  const error = disabled === true ? '' : errors[name]?.message

  return (
    <MuiTextField
      variant="outlined"
      type="number"
      label={label}
      error={Boolean(error)}
      helperText={error || helperText}
      placeholder={placeholder}
      disabled={disabled}
      required={!disabled && required}
      fullWidth
      {...field}
    />
  )
}
