import styled from 'styled-components'
import {
  Heading1,
  Heading4,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const Title = styled(Heading1)`
  margin-bottom: 1rem;
  font-weight: 700;
`

export const SubTitle = styled(Heading4)`
  margin-bottom: 1rem;
`
export const SymptomsTitle = styled(Heading4)`
  margin-bottom: 1rem;
  font-weight: 700;
`
