import styled from 'styled-components'

export const Body = styled.div`
  flex: 1 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 1065px) {
    flex-flow: row-reverse nowrap;
    width: 1065px;
  }
`

export const PageContainer = styled.div`
  flex: 1 0;
  display: flex;
  padding: 2rem;
`
