import React from 'react'
import { WalkInContainer, WalkInLabel } from './WalkInIndicator.style'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

export const WalkInIndicator: React.FC = () => {
  const { t } = useMedMeTranslation('patient')

  return (
    <WalkInContainer>
      <WalkInLabel>
        <DirectionsWalkIcon
          style={{
            verticalAlign: 'middle',
            marginRight: '4px',
            height: '1.0rem',
            width: '1.0rem',
          }}
        />
        {t('storeSelection.walkInsAccepted')}{' '}
      </WalkInLabel>
    </WalkInContainer>
  )
}
