export enum DateFormat {
  Time, // 12:00 AM or 00:00
  TimeCompact, // 12:00am or 00:00
  TimeWithSeconds, // 12:00:00 AM or 00:00:00
  NumberedDate, // 01-01-01
  Date, // January 1, 2001,
  DateTime, // January 1, 2001 12:00 AM
  DayDateTime, // Monday, January 1, 2001 12:00 AM
  AbbrDate, // Jan 1, 2001
  AbbrDateWithoutYear, // Jan 1
  AbbrDateTime, // Jan 1, 2001 12:00 AM
  AbbrDayDate, // Mon, Jan 1, 2001
  AbbrDayDateWithoutYear, // Mon, Jan 1 or lun. 1 févr.
  AbbrDayDateTime, // Mon, Jan 1, 2001 12:00 AM
  TimeAndAbbrDate, // 12:00 AM Jan 1, 2001
}

export enum TimeFormat {
  HoursMinutes, // 12:00 AM or 00:00
  HoursMinutesCompact, // 12:00am or 00:00
  HoursMinutesSeconds, // 12:00:00 AM or 00:00:00
}
