/* eslint-disable camelcase */

import { createFhirCodingEntityPatient } from '~/lib/fhir/systems'
import { provinceValueSetAnswerOptions } from '~/lib/fhir/questionnaires/valueSets/provinceValueSet'
import { createFhirQuestionnaire } from '~/lib/fhir/questionnaires/fhirQuestionnaire'
import { createFhirQuestionnaireItem } from '~/lib/fhir/questionnaires/fhirQuestionnaireItem'
import {
  ExtensionValidationMessage,
  ExtensionValidationOperator,
  extensionValidationUrl,
  ExtensionValidationValue,
  ExtensionItemTypeVariant,
  ExtensionStyling,
  ExtensionStylingGridColumn,
  extensionStylingUrl,
  extensionItemTypeVariantUrl,
} from '../extensions'

const basicValidation = (
  extMessage: ExtensionValidationMessage,
  extOperator: ExtensionValidationOperator,
  extValue: ExtensionValidationValue
) => ({
  url: extensionValidationUrl,
  extension: [
    extMessage,
    {
      url: 'conditionGroup',
      extension: [
        {
          url: 'behaviour',
          valueCode: 'all',
        },
        {
          url: 'conditionList',
          extension: [
            {
              url: 'condition',
              extension: [
                extOperator,
                extValue,
                { url: 'isPath', valueBoolean: false },
              ],
            },
          ],
        },
      ],
    },
  ],
})

const basicGridStyling = (
  value: ExtensionStylingGridColumn['valueCode']
): ExtensionStyling => ({
  url: extensionStylingUrl,
  extension: [
    {
      url: 'gridColumn',
      valueCode: value,
    },
  ],
})

const itemTypeVariantExt = (
  value: ExtensionItemTypeVariant['valueCode']
): ExtensionItemTypeVariant => ({
  url: extensionItemTypeVariantUrl,
  valueCode: value,
})

const cardHeading = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Information',
    type: 'display',
    _type: {
      extension: [itemTypeVariantExt('heading1')],
    },
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Required fields (*)',
    type: 'display',
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [heading, descReq],
  })
}

const healthCard = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'healthCardHeading',
    type: 'display',
    text: 'Health Card',
    _type: {
      extension: [itemTypeVariantExt('heading2')],
    },
  })

  const desc = createFhirQuestionnaireItem({
    linkId: 'healthCardDesc',
    type: 'display',
    text: 'Please enter only numeric characters from your Health Card',
  })

  const hcProvince = createFhirQuestionnaireItem({
    linkId: 'healthCardProvince',
    type: 'coding',
    text: 'Province',
    required: true,
    answerConstraint: 'optionsOnly',
    answerOption: provinceValueSetAnswerOptions('ON'),
    extension: [basicGridStyling('4')],
    code: [createFhirCodingEntityPatient({ code: 'healthCard.province' })],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcNumber = createFhirQuestionnaireItem({
    linkId: 'healthCardNumber',
    type: 'string',
    _type: {
      extension: [itemTypeVariantExt('numeric')],
    },
    text: 'Health Card Number',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'healthCard.number' })],
    extension: [basicGridStyling('auto')],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcVersion = createFhirQuestionnaireItem({
    linkId: 'healthCardVersion',
    type: 'string',
    text: 'Version Code',
    code: [createFhirCodingEntityPatient({ code: 'healthCard.versionCode' })],
    extension: [basicGridStyling('auto')],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    enableBehavior: 'all',
    disabledDisplay: 'hidden',
  })

  const postOntarioDesc = createFhirQuestionnaireItem({
    linkId: 'postOntarioDesc',
    type: 'display',
    text: 'Version Code is the 2 letters after your 10-digit Health Card Number (Leave Blank if you have a red/white card)',
    enableWhen: [
      {
        question: 'healthCardProvince',
        operator: '=',
        answerString: 'ON',
      },
    ],
    enableBehavior: 'any',
    disabledDisplay: 'hidden',
  })

  const noHealthCard = createFhirQuestionnaireItem({
    linkId: 'noHealthCard',
    type: 'boolean',
    text: "I don't have a valid Health Card in the province I'm receiving this service. I understand that I must bring a government-issued ID to the pharmacy and I may have to pay for this service.",
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientHealthCard',
    type: 'group',
    item: [
      heading,
      desc,
      hcProvince,
      hcNumber,
      hcVersion,
      postOntarioDesc,
      noHealthCard,
    ],
  })
}

const pcp = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'pcpHeading',
    type: 'display',
    text: 'Family Doctor',
    _type: {
      extension: [itemTypeVariantExt('heading2')],
    },
  })

  const pcpFirstName = createFhirQuestionnaireItem({
    linkId: 'pcpFirstName',
    type: 'string',
    text: 'First Name',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.firstName' }),
    ],
    extension: [basicGridStyling('4')],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })
  const pcpLastName = createFhirQuestionnaireItem({
    linkId: 'pcpLastName',
    type: 'string',
    text: 'Last Name',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.lastName' }),
    ],
    extension: [basicGridStyling('4')],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const pcpPhone = createFhirQuestionnaireItem({
    linkId: 'pcpPhone',
    type: 'string',
    _type: {
      extension: [itemTypeVariantExt('phone')],
    },
    text: 'Phone Number',
    extension: [
      basicGridStyling('4'),
      basicValidation(
        { url: 'message', valueString: 'Invalid phone number' },
        { url: 'operator', valueCode: 'isPhone' },
        { url: 'value', valueBoolean: true }
      ),
    ],
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.phone' }),
    ],
    required: true,
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const noPcp = createFhirQuestionnaireItem({
    linkId: 'noPcp',
    type: 'boolean',
    text: "I don't have a family doctor",
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientMedicalInfoPage',
    type: 'group',
    item: [heading, pcpFirstName, pcpLastName, pcpPhone, noPcp],
  })
}

const patientEmergencyContact = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactHeading',
    type: 'display',
    text: 'Emergency Contact Information',
    _type: {
      extension: [itemTypeVariantExt('heading2')],
    },
  })

  const ecFirstName = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactFirstName',
    type: 'string',
    text: 'First Name',
    required: true,
    extension: [basicGridStyling('6')],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.firstName' }),
    ],
  })

  const ecLastName = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactLastName',
    type: 'string',
    text: 'Last Name',
    required: true,
    extension: [basicGridStyling('6')],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.lastName' }),
    ],
  })

  const ecRelationship = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactRelationship',
    type: 'string',
    text: 'Relationship',
    required: true,
    extension: [basicGridStyling('6')],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.relationship' }),
    ],
  })

  const ecPhone = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactPhone',
    type: 'string',
    text: 'Phone Number',
    required: true,
    _type: {
      extension: [itemTypeVariantExt('phone')],
    },
    extension: [
      basicGridStyling('6'),
      basicValidation(
        { url: 'message', valueString: 'Invalid phone number' },
        { url: 'operator', valueCode: 'isPhone' },
        { url: 'value', valueBoolean: true }
      ),
    ],
    code: [createFhirCodingEntityPatient({ code: 'emergencyContact.phone' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactSection',
    type: 'group',
    item: [heading, ecFirstName, ecLastName, ecRelationship, ecPhone],
  })
}

export const questionnaire_indie_ma_on_medicalInformation =
  createFhirQuestionnaire({
    resourceType: 'Questionnaire',
    id: 'de971839-e909-4aa5-85fb-e81b4ac88fe0',
    version: '62362e8c-1cf2-42de-9dfc-16200a48d547',
    name: 'medical-information',
    title: 'Medical Information',
    status: 'active',
    item: [
      createFhirQuestionnaireItem({
        linkId: 'medicalInformationPage',
        type: 'group',
        _type: {
          extension: [itemTypeVariantExt('card')],
        },
        item: [cardHeading(), healthCard(), pcp(), patientEmergencyContact()],
      }),
    ],
  })
