import { createPresetFormStep } from '..'
import { CancelledAppointmentLanding } from '~/pages/AppointmentIntake/forms'
import { isAppointmentStatusCancelledLike } from '~/lib/appointment'

export const cancelledAppointmentLandingFormStep: typeof createPresetFormStep =
  (json) => {
    return {
      Component: CancelledAppointmentLanding,
      metadata: json.metadata,
      condition: (state) => {
        const appointment = state.initial.appointment
        return isAppointmentStatusCancelledLike(appointment)
      },
      isAutoSkippable: async () => false,
    }
  }
