import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import { primary } from '~/sharedStyles/colorPalette'

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .MuiFormControlLabel-root {
    margin: 0;
  }

  .MuiTypography-root {
    font-family: inherit;
  }
`

export const CheckboxInput = styled(Checkbox)`
  padding: 0;
  margin-right: 7px;

  &.MuiButtonBase-root {
    padding: 0;
  }

  &.Mui-checked:not(.Mui-disabled) {
    color: ${({ theme }) => theme?.color?.backgroundPrimary || primary};
  }
`
