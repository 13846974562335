import * as React from 'react'
import { createPharmacy } from '~/lib/pharmacy'
import { Timeslot } from '~/lib/timeslot'
import {
  useAppointmentActivityContext,
  useAppointmentContext,
  usePharmacyContext,
} from '../../..'
import { BookingContextInterface } from '../../BookingContext'
import { useReserveTimeslot } from '../useReserveTimeslot'
import { useSelectAppointmentActivity } from '../useSelectAppointmentActivity'

export const useStartAppointmentActivityIntake = (
  context: BookingContextInterface
) => {
  const { selectPharmacyAsync, resetPharmacy } = usePharmacyContext()
  const { updateAppointment, initialAppointment } = useAppointmentContext()
  const { reserveTimeslotAsync, clearReservation } = useReserveTimeslot(
    context.reservationId,
    context.setReservationId
  )
  const { appointmentActivity, scope } = useAppointmentActivityContext()
  const { selectAppointmentActivityAsync, resetAppointmentActivity } =
    useSelectAppointmentActivity()

  const startAppointmentActivityIntakeAsync = React.useCallback(
    async (
      pharmacyId: string,
      appointmentTypeId: string,
      timeslot: Timeslot,
      isWalkin: boolean
    ) => {
      const createError = (message?: string | null) => {
        return {
          reservationId: '',
          pharmacy: createPharmacy(),
          error: message ?? '',
        }
      }

      const { reservationId, error: reserveTimeError } = isWalkin
        ? { reservationId: '', error: '' }
        : await reserveTimeslotAsync(pharmacyId, appointmentTypeId, timeslot)
      if (reserveTimeError) return createError(reserveTimeError)

      const { pharmacy, error: pharmacyError } = await selectPharmacyAsync(
        pharmacyId
      )
      if (pharmacyError) return createError(pharmacyError)
      updateAppointment({ isWalkin })
      await selectAppointmentActivityAsync({
        id: appointmentActivity.appointmentActivityId,
        regionCode: scope.regionCode,
        countryCode: scope.countryCode,
        pharmacyId: pharmacy.id,
      })

      return {
        reservationId: reservationId,
        pharmacy: pharmacy,
        error: '',
      }
    },
    [reserveTimeslotAsync, selectPharmacyAsync]
  )

  const resetAppointmentActivityIntakeAsync = React.useCallback(async () => {
    await resetAppointmentActivity()
    updateAppointment({ isWalkin: initialAppointment.isWalkin })
    clearReservation()
    resetPharmacy()
  }, [])

  return {
    startAppointmentActivityIntakeAsync,
    resetAppointmentActivityIntakeAsync,
  }
}
