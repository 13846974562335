import React, { useContext } from 'react'

import { WebViewContext } from '~/context/WebViewContext'
import PatientRegistrationContext from '~/pages/patientRegistrationV2/patientRegistrationContext'

// utils
import { Button } from '../../../patientRegistration.styles'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

interface CancelButtonProps {
  onClick?: () => boolean
  isLastForm?: boolean
}

export const CancelButton: React.FC<CancelButtonProps> = ({
  onClick,
  isLastForm,
}) => {
  const { t } = useMedMeTranslation('common')
  const { inWebView, sendEvent } = useContext(WebViewContext)
  const { isLastForm: isLastFormOldContainer } = useContext(
    PatientRegistrationContext
  )
  const handleClick = () => {
    sendEvent('abandon')
    if (onClick) onClick()
  }
  if (isLastForm || isLastFormOldContainer || !inWebView) return null
  return (
    <Button type="button" onClick={handleClick}>
      {t('cancel')}
    </Button>
  )
}
