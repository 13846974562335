import { Engine } from 'json-rules-engine'

export const createFhirFormRulesEngine =
  (operators: ((rulesEngine: Engine) => void)[]) => (): Engine => {
    const rulesEngine = new Engine()

    operators.forEach((fn) => {
      fn(rulesEngine)
    })

    return rulesEngine
  }
