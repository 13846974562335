import React from 'react'
import { useController } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Layout, BooleanRadioGroup } from './controlledBooleanRadio.styles'

import { RadioInput, InputLabel } from '../../patientRegistration.styles'
import FormControl from '@material-ui/core/FormControl'

interface ControlledBooleanRadioProps {
  name: string
  error: boolean
  helperText: string
  control: any
}

const radioOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
] as const

export const ControlledBooleanRadio = (props: ControlledBooleanRadioProps) => {
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: '',
  })
  return (
    <Layout>
      <FormControl component="fieldset" error={props.error}>
        <BooleanRadioGroup {...field}>
          {radioOptions.map((option, i) => (
            <FormControlLabel
              control={
                <RadioInput
                  style={{
                    padding: 0,
                    margin: 0,
                    marginRight: '7px',
                  }}
                  color="default"
                />
              }
              value={option.value}
              label={<InputLabel>{option.label}</InputLabel>}
              labelPlacement={'end'}
              style={{ margin: 0 }}
              key={i}
            />
          ))}
        </BooleanRadioGroup>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </Layout>
  )
}
