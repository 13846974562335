import * as React from 'react'
import { FhirQuestionnaireItemComponent } from '../FhirQuestionnaireItemComponent'
import { FhirBooleanInput } from './FhirBooleanInput'
import { FhirChoiceInput } from './FhirChoiceInput'
import { FhirDateInput } from './FhirDateInput'
import { FhirDisplayLabel } from './FhirDisplayLabel'
import { FhirStringComponent } from './FhirStringInput'
import { FhirContainerComponent } from '../FhirContainerComponent'
import { FhirIntegerInput } from './FhirIntegerInput'

export const FhirLeafComponent: typeof FhirQuestionnaireItemComponent =
  React.memo((props) => {
    const { questionnaireItem } = props
    const Component = () => {
      switch (questionnaireItem.type) {
        case 'display':
          return <FhirDisplayLabel {...props} />
        case 'string':
          return <FhirStringComponent {...props} />
        case 'boolean':
          return <FhirBooleanInput {...props} />
        case 'integer':
          return <FhirIntegerInput {...props} />
        case 'date':
          return <FhirDateInput {...props} />
        case 'choice':
          return <FhirChoiceInput {...props} />
        default:
          console.debug(
            'FhirLeafComponent: Unsupported type',
            questionnaireItem.type
          )
          return null
      }
    }

    return (
      <FhirContainerComponent {...props}>
        <Component />
      </FhirContainerComponent>
    )
  })
