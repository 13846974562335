import { FormStep } from '../FormStep/types'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { ContactFormStep, ReviewFormStep } from '../FormStep'
import {
  CONTACT_PAGE_PATH,
  REVIEW_AND_CONFIRM_PAGE_PATH,
} from '../../constants'

export const useFormSteps = (): FormStep[] => {
  const { t } = useMedMeTranslation('patient')

  return [
    {
      path: CONTACT_PAGE_PATH,
      displayName: t('waitlistContact.title'),
      Component: ContactFormStep,
    },
    {
      path: REVIEW_AND_CONFIRM_PAGE_PATH,
      displayName: t('reviewAndConfirm.title'),
      Component: ReviewFormStep,
    },
  ]
}
