import * as React from 'react'
import { useEffect } from 'react'
import { Card } from '../symptomSelector.styles'
import { SymptomSvgIcon } from '../symptomSvgIcon/SymptomSvgIcon'

type SymptomFilterProps = {
  name: string
  value: string
  iconPath: string
  selectedSymptom: string
  setSelectedSymptom: any
  setValue: any
}

export const SymptomFilterButton: React.FC<SymptomFilterProps> = ({
  name,
  value,
  iconPath,
  selectedSymptom,
  setSelectedSymptom,
  setValue,
}) => {
  useEffect(() => {
    setValue('symptom', selectedSymptom)
  }, [selectedSymptom])

  return (
    <Card
      variant="outlined"
      data-testid={value}
      selected={selectedSymptom === value}
      onClick={() => {
        setSelectedSymptom(value)
      }}
    >
      <div style={{ pointerEvents: 'none' }}>
        <SymptomSvgIcon isSelected={selectedSymptom} iconPath={iconPath} />
      </div>
      {name}
    </Card>
  )
}
