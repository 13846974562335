import Cookies from 'js-cookie'

export type CookieAttributes = Cookies.CookieAttributes

export function setCookie(
  cname: string,
  cvalue: string,
  attributes?: CookieAttributes
): void {
  Cookies.set(cname, cvalue, attributes)
}

export function deleteCookie(
  cname: string,
  attributes?: CookieAttributes
): void {
  Cookies.remove(cname, attributes)
}

export function getCookie(cname: string): string | undefined {
  return Cookies.get(cname)
}
