export const dedupeRecords = <T>(
  records?: T[] | null,
  getKey: (record: T) => string = (record: T) => JSON.stringify(record)
): T[] | null => {
  if (!records) return null
  const dedupedRecords: T[] = []
  const hashmap: { [key: string]: boolean } = {}

  for (const record of records) {
    const key = getKey(record)
    if (!hashmap[key]) {
      dedupedRecords.push(record)
      hashmap[key] = true
    }
  }

  return dedupedRecords
}
