import React from 'react'
import { Pharmacy } from '~/lib/pharmacy'
import {
  LocationInfoContainer,
  LocationName,
  LocationAddress,
  DistanceLabel,
} from './LocationInfo.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { externalClinic } from '~/hooks/useFindSublocationById'

type PharmacyInfoContainerProps = {
  distance?: number
  pharmacy: Pharmacy
  externalClinic?: externalClinic
}

export const LocationInfo: React.FC<PharmacyInfoContainerProps> = ({
  distance,
  pharmacy,
  externalClinic,
}) => {
  const { t } = useMedMeTranslation('patient')

  const {
    pharmacyAddress: { streetNumber, streetName, city, province, postalCode },
    name,
  } = pharmacy

  return (
    <LocationInfoContainer>
      <LocationAddress>
        {externalClinic ? (
          externalClinic.address.unit
        ) : (
          <>
            {streetNumber} {streetName}
            <br />
            {city}, {province} {postalCode}
          </>
        )}
      </LocationAddress>
      {!!distance && (
        <DistanceLabel>
          {t('storeSelection.storeDistance', {
            distance: Math.round((distance ?? 0) * 100) / 100,
          })}{' '}
        </DistanceLabel>
      )}
      <LocationName>{externalClinic ? externalClinic.name : name}</LocationName>
    </LocationInfoContainer>
  )
}
