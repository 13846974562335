import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import {
  createFhirExtHideWhenDisabled,
  createFhirExtInputVariant,
} from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { JsonFhirFormStep } from '../../../../../../types'
import { LanguageCodesFE } from '~/types/languageEnums'

export const medicalEmergencyScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Emergency Screening',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const medicalEmergency = createFhirQuestionnaireItem({
    text: 'If you are experiencing a medical emergency, call 9-1-1 or go to your nearest hospital emergency room.',
    linkId: 'experiencingEmergency',
    type: 'display',
  })
  const utiEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under the age of 16 and over 64 (Pharmacists can only assess & prescribe for individuals between ages 16 and 64)
- Male sex assigned at birth
- Pregnant
    `,
    linkId: 'utiEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'UrinaryTractInfection',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const coldSoreEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 12 years of age
    `,
    linkId: 'coldSoreEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'ColdSores',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const pinkEyeEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 1 year of age
    `,
    linkId: 'pinkEyeEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'PinkEye',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const skinRashEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 2 years of age
    `,
    linkId: 'skinRashEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'SkinRash',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const contraceptionEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under the age of 12 and over 50 (Pharmacists can only assess & prescribe for individuals between ages 12 and 50)
    `,
    linkId: 'contraceptionEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Contraception',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const heartBurnEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 18 years of age
    `,
    linkId: 'heartBurnEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'HeartBurn',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const impetigoEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 2 years of age
    `,
    linkId: 'impetigoEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Impetigo',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const acneEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 12 years of age and over 30 years of age (if new onset of acne)
    `,
    linkId: 'acneEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Acne',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const shinglesEligibility = createFhirQuestionnaireItem({
    text: `Pharmacists cannot assess and prescribe for the following individuals:
- Under 12 years of age
    `,
    linkId: 'shinglesEligibility',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'markdown' })],
    },
    enableWhen: [
      {
        question: 'appointmentActivity.urlParam',
        operator: '=',
        answerString: 'Shingles',
      },
    ],
    enableBehavior: 'any',
    _enableBehavior: {
      extension: [createFhirExtHideWhenDisabled({ valueBoolean: true })],
    },
  })
  const eligibility = createFhirQuestionnaireItem({
    text: 'Eligibility for assessment and prescribing for minor ailments will be assessed by the pharmacist at the time of your appointment.',
    linkId: 'eligibility',
    type: 'display',
  })
  const healthcardPaymentDisclaimerHeader = createFhirQuestionnaireItem({
    text: 'DISCLAIMER:',
    linkId: 'healthcardDisclaimerHeader',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })
  const reimbursed = createFhirQuestionnaireItem({
    text: 'If you don’t have a valid NB Medicare. Professional fees apply.',
    linkId: 'reimbursed',
    type: 'display',
  })
  const medicationPaymentDisclaimer = createFhirQuestionnaireItem({
    text: 'In the event a prescription is written for this service, the cost of the medication is not included in the assessment. The cost may be covered by you or your drug insurance.',
    linkId: 'medicationPaymentDisclaimer',
    type: 'display',
  })
  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [
      heading,
      medicalEmergency,
      utiEligibility,
      coldSoreEligibility,
      pinkEyeEligibility,
      skinRashEligibility,
      contraceptionEligibility,
      heartBurnEligibility,
      impetigoEligibility,
      acneEligibility,
      shinglesEligibility,
      eligibility,
      healthcardPaymentDisclaimerHeader,
      reimbursed,
      medicationPaymentDisclaimer,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [medicalEmergencyScreening()],
    }),
  ],
})

export const fhirReimbursedMAMedicalScreeningSdmNBJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['NB'],
    enterpriseCodes: ['SDM', 'LCL'],
    appointmentActivity: {
      category: 'MINOR_AILMENT',
      urlParams: [
        'SkinRash',
        'HeartBurn',
        'PinkEye',
        'ColdSores',
        'PinkEye',
        'Impetigo',
        'UrinaryTractInfection',
        'Shingles',
        'Contraception',
        'Acne',
        'TickBites',
      ],
    },
  },
  metadata: {
    id: 'fhirReimbursedMAMedicalScreeningSdmNBJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
    languageCode: LanguageCodesFE.EN,
  },
}
