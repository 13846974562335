import { Extension } from 'fhir/r5'
import {
  CreateRuleSignature,
  ItemStyle,
  toCreateRuleFromValidation,
  toItemStyleFromStyling,
} from '../extensionAdapters'
import { isExtensionValidation } from './validation'
import { isExtensionStyling } from './styling'
import { isExtensionRegex } from './regex'
import {
  isExtensionItemTypeVariant,
  itemTypeVariantCodes,
} from './itemTypeVariant'

export interface SupportedExtensionInterface {
  typeVariant?: (typeof itemTypeVariantCodes)[number]
  style?: ItemStyle
  validationRuleGenerators?: CreateRuleSignature[]
  regex?: string
}

export const getSupportedExtensionInterface = (
  extensions?: Extension[],
  language = 'en-CA'
): SupportedExtensionInterface => {
  return (
    extensions?.reduce<SupportedExtensionInterface>((acc, ext) => {
      if (isExtensionStyling(ext)) {
        acc.style = toItemStyleFromStyling(ext)
      } else if (isExtensionItemTypeVariant(ext)) {
        acc.typeVariant = ext.valueCode
      } else if (isExtensionValidation(ext)) {
        acc.validationRuleGenerators = [
          ...(acc.validationRuleGenerators ?? []),
          toCreateRuleFromValidation(ext, language),
        ]
      } else if (isExtensionRegex(ext)) {
        acc.regex = ext.valueCode
      }

      return acc
    }, {}) ?? {}
  )
}
