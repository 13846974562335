import React, { useContext } from 'react'

interface ReusableIntakeData {
  data: Record<string, any>
  updateData: (question: Record<string, any>) => void
  setPatientIndex: (patientIndex: number) => void
  patientIndex: number
}

export const ReusableIntakeDataContext =
  React.createContext<ReusableIntakeData>({
    data: {},
    updateData: () => void '',
    setPatientIndex: () => void '',
    patientIndex: 0,
  })

export const useReusableIntakeDataState = () => {
  const context = useContext(ReusableIntakeDataContext)

  const { data, updateData, setPatientIndex, patientIndex } = context

  return {
    data,
    updateData,
    patientIndex,
    setPatientIndex,
  }
}
