import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'

import { WebViewContext } from '~/context/WebViewContext'

import { FinishButton } from '../FinishButton'

import {
  FinishBarWrapper,
  FinishBarFixed,
  FinishBarInner,
  FinishBarButton,
} from './FinishBar.styles'

interface FinishButtonProps {
  onClick?: () => void
}

export const FinishBar: React.FC<FinishButtonProps> = ({ onClick }) => {
  const { inWebView } = useContext(WebViewContext)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const fixedRef = useRef<HTMLDivElement>(null)
  const [buttonEnabled, setButtonEnabled] = useState(false)

  const checkForPosition = useCallback(() => {
    if (!wrapperRef.current || !fixedRef.current) return false
    const wrapperBounds = wrapperRef.current.getBoundingClientRect()
    const fixedBounds = fixedRef.current.getBoundingClientRect()

    setButtonEnabled(
      Math.floor(fixedBounds.top) >= Math.floor(wrapperBounds.top)
    )
  }, [wrapperRef, fixedRef, setButtonEnabled])

  useEffect(() => {
    if (!wrapperRef.current || !fixedRef.current) return
    checkForPosition()

    window.addEventListener('scroll', checkForPosition, true)
    window.addEventListener('orientationchange', checkForPosition, true)
    window.addEventListener('resize', checkForPosition, true)

    return () => {
      window.removeEventListener('scroll', checkForPosition, true)
      window.removeEventListener('orientationchange', checkForPosition, true)
      window.removeEventListener('resize', checkForPosition, true)
    }
  }, [checkForPosition])

  if (!inWebView) return null

  return (
    <FinishBarWrapper ref={wrapperRef}>
      <FinishBarFixed ref={fixedRef}>
        <FinishBarInner>
          <FinishBarButton>
            <FinishButton onClick={onClick} disabled={!buttonEnabled} />
          </FinishBarButton>
        </FinishBarInner>
      </FinishBarFixed>
    </FinishBarWrapper>
  )
}
