import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { createFhirCodingEntityPatient } from '~/pages/AppointmentIntake/lib/fhir/systems'
import { presetFhirGridExt } from '../../../../../../../lib/fhir/extensions/fhirExtGrid/presetFhirGridExt'
import { provinceValueSet } from '../../../../lib/provinceValueSet'
import { JsonFhirFormStep } from '../../../../../types'

export const cardHeading = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeading',
    text: 'Medical Information',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const descReq = createFhirQuestionnaireItem({
    linkId: 'patientInformationDescReq',
    text: 'Required fields (*)',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  return createFhirQuestionnaireItem({
    linkId: 'medicalInformationHeadingSection',
    type: 'group',
    item: [heading, descReq],
  })
}

export const healthCard = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'healthCardHeading',
    type: 'display',
    text: 'Health Card',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const desc = createFhirQuestionnaireItem({
    linkId: 'healthCardDesc',
    type: 'display',
    text: 'Please enter only numeric characters from your Health Card',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'body' })],
    },
  })

  const hcProvince = createFhirQuestionnaireItem({
    linkId: 'healthCardProvince',
    type: 'choice',
    text: 'Province',
    required: true,
    answerOption: [
      ...provinceValueSet('AB'),
      {
        valueCoding: {
          code: 'other',
          display: 'Other',
        },
      },
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    code: [createFhirCodingEntityPatient({ code: 'healthCard.province' })],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const hcNumber = createFhirQuestionnaireItem({
    linkId: 'healthCardNumber',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'numeric' })],
    },
    text: 'Health Card Number',
    required: true,
    code: [createFhirCodingEntityPatient({ code: 'healthCard.number' })],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 'auto',
      }),
    ],
    enableWhen: [
      {
        question: 'noHealthCard',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const noHealthCard = createFhirQuestionnaireItem({
    linkId: 'noHealthCard',
    type: 'boolean',
    text: "I don't have a valid Health Card in the province I'm receiving this service. I understand that I must bring a government-issued ID to the pharmacy and I may have to pay for this service.",
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [heading, desc, hcProvince, hcNumber, noHealthCard],
  })
}

const pcp = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'pcpHeading',
    type: 'display',
    text: 'Family Doctor',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const pcpFirstName = createFhirQuestionnaireItem({
    linkId: 'pcpFirstName',
    type: 'string',
    text: 'First Name',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.firstName' }),
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })
  const pcpLastName = createFhirQuestionnaireItem({
    linkId: 'pcpLastName',
    type: 'string',
    text: 'Last Name',
    required: true,
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.lastName' }),
    ],
    extension: [
      presetFhirGridExt({
        xs: 12,
        sm: 4,
      }),
    ],
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const pcpPhone = createFhirQuestionnaireItem({
    linkId: 'pcpPhone',
    type: 'string',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    text: 'Phone Number',
    extension: [
      presetFhirGridExt({ xs: 12, sm: 4 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Invalid phone number' },
        ],
      }),
    ],
    code: [
      createFhirCodingEntityPatient({ code: 'primaryCareProvider.phone' }),
    ],
    required: true,
    enableWhen: [
      {
        question: 'noPcp',
        operator: '=',
        answerBoolean: false,
      },
    ],
    enableBehavior: 'all',
  })

  const noPcp = createFhirQuestionnaireItem({
    linkId: 'noPcp',
    type: 'boolean',
    text: "I don't have a family doctor",
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientContactDetailsPage',
    type: 'group',
    item: [heading, pcpFirstName, pcpLastName, pcpPhone, noPcp],
  })
}

export const patientEmergencyContact = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactHeading',
    type: 'display',
    text: 'Emergency Contact Information',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'sectionTitle' })],
    },
  })

  const ecFirstName = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactFirstName',
    type: 'string',
    text: 'First Name',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.firstName' }),
    ],
  })

  const ecLastName = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactLastName',
    type: 'string',
    text: 'Last Name',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.lastName' }),
    ],
  })

  const ecRelationship = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactRelationship',
    type: 'string',
    text: 'Relationship',
    required: true,
    extension: [presetFhirGridExt({ xs: 12, sm: 6 })],
    code: [
      createFhirCodingEntityPatient({ code: 'emergencyContact.relationship' }),
    ],
  })

  const ecPhone = createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactPhone',
    type: 'string',
    text: 'Phone Number',
    required: true,
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'phone' })],
    },
    extension: [
      presetFhirGridExt({ xs: 12, sm: 6 }),
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'isPhone' },
          { url: 'value', valueBoolean: true },
          { url: 'message', valueString: 'Invalid phone number' },
        ],
      }),
    ],
    code: [createFhirCodingEntityPatient({ code: 'emergencyContact.phone' })],
  })

  return createFhirQuestionnaireItem({
    linkId: 'patientEmergencyContactSection',
    type: 'group',
    item: [heading, ecFirstName, ecLastName, ecRelationship, ecPhone],
  })
}

export const fhirMedicalInformationQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalInformationQuestionnaire',
  name: 'Medical Information',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalInformationPage',
      type: 'group',
      item: [cardHeading(), healthCard(), pcp(), patientEmergencyContact()],
    }),
  ],
})

export const fhirMAMedicalInformationIndiesABJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalInformationQuestionnaire,
  filter: {
    regionCodes: ['AB'],
    isEnterprise: false,
    appointmentActivity: {
      category: 'MINOR_AILMENT',
    },
  },
  metadata: {
    id: 'fhirMAMedicalInformationIndiesABJson',
    name: 'Medical Information',
    path: 'medical-information',
    showOnSteps: true,
  },
}
