import * as React from 'react'
import { UseFormMethods } from 'react-hook-form'
import {
  FhirQuestionnaire,
  SimpleValueType,
} from '~/lib/fhir/fhirQuestionnaire'
import { createDisabledItemsEngine } from './createDisabledItemsEngine'
import { isEnableWhenEvent } from '../../rulesEngine'

/**
 * Returns an object maping QuestionnaireItem.linkId to disabled status.
 *
 * @param questionnaire FhirQuestionnaire
 * @param methods method from react-hook-form useForm
 *
 * @returns
 */
export const useDisabledFhirQuestionnaireItems = (
  questionnaire: FhirQuestionnaire,
  methods: UseFormMethods<Record<string, SimpleValueType>>
) => {
  const getDefaultStateForFields = () =>
    Object.keys(questionnaire.linkIdItems).reduce<
      Record<string, undefined | true>
    >((acc, key) => {
      acc[key] = undefined
      return acc
    }, {})

  const [disabledItems, setDisabledItems] = React.useState<
    Record<string, undefined | true>
  >(getDefaultStateForFields())
  const disabledItemsRef = React.useRef<Record<string, undefined | true>>(
    getDefaultStateForFields()
  ) // Need to compare against a ref as disabledItems will only update on rerender

  const [rulesEngine, setRulesEngine] = React.useState(
    createDisabledItemsEngine(questionnaire)
  )
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>(undefined)

  // Should only fire if ref to questionnaire or onDisabledUpdate changes
  React.useEffect(() => {
    setLoading(true)
    disabledItemsRef.current = getDefaultStateForFields()
    setDisabledItems(getDefaultStateForFields())
    setRulesEngine(createDisabledItemsEngine(questionnaire))
  }, [questionnaire])

  const linkIdsToSubscribeTo = React.useMemo(
    () =>
      Array.from(
        new Set(
          Object.values(questionnaire.linkIdItems).flatMap(
            (item) =>
              item.enableWhen?.map((enableWhen) => enableWhen.question) ?? []
          )
        )
      ),
    [questionnaire]
  )

  const subscribedFields = React.useCallback(methods.watch, [
    methods,
    linkIdsToSubscribeTo,
  ])(linkIdsToSubscribeTo)

  /**
   * Use useLayoutEffect since we want this to fire the async call before repaint.
   * We subscribe to each value of the subscribedFields, assuming that the order of `Object.values(subscribedFields)` is always the same
   * This also assumes methods.control.getValues() has the updated values by the time useLayoutEffect is called, which it should according to the react-hook-form docs.
   * */
  React.useLayoutEffect(() => {
    rulesEngine
      .run({ ...getDefaultStateForFields(), ...methods.control.getValues() })
      .then((result) => {
        const currentDisabledItems = getDefaultStateForFields()
        result.failureEvents.forEach((event) => {
          if (isEnableWhenEvent(event)) {
            currentDisabledItems[event.params.fieldName] = true
          }
        })
        if (
          Object.keys(currentDisabledItems).some(
            (key) => currentDisabledItems[key] !== disabledItemsRef.current[key]
          )
        ) {
          disabledItemsRef.current = { ...currentDisabledItems }
          setDisabledItems(currentDisabledItems)
        }
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
        setLoading(false)
      })
  }, [rulesEngine, ...Object.values(subscribedFields)])

  return React.useMemo(
    () => ({
      disabledItems,
      loading,
      error,
    }),
    [disabledItems, loading, error]
  )
}
