import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const Card = styled(Paper)`
  padding: 1.5rem 1rem;

  @media only screen and (orientation: landscape) and (min-width: 992px) {
    padding: 1.5rem;
  }
`
