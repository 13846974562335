import {
  createFhirQuestionnaire,
  createFhirQuestionnaireItem,
} from '~/pages/AppointmentIntake/lib/fhir'
import { createFhirExtInputVariant } from '~/pages/AppointmentIntake/lib/fhir/extensions'
import { createFhirExtValidation } from '~/pages/AppointmentIntake/lib/fhir/extensions/fhirExtValidation'
import { JsonFhirFormStep } from '../../../../../types'

export const minorAilmentEligibilityScreening = () => {
  const heading = createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    text: 'Eligibility Screening',
    type: 'display',
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'cardTitle' })],
    },
  })

  const completingQuestions = createFhirQuestionnaireItem({
    text: 'If completing on behalf of someone else, answer from their perspective.',
    linkId: 'completingQuestions',
    type: 'display',
  })

  const assignedSex = createFhirQuestionnaireItem({
    text: 'Was your assigned sex at birth male?',
    linkId: 'assignedSex',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const age = createFhirQuestionnaireItem({
    text: 'Are you between the ages of 35 and 75?',
    linkId: 'age',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'yes' },
          {
            url: 'message',
            valueString:
              'You must be between 35 and 75 years old in order to be eligible for pharmacist-guided treatment. We recommend scheduling a visit with another primary care provider for further assessment.',
          },
        ],
      }),
    ],
    required: true,
  })

  const prostateCancer = createFhirQuestionnaireItem({
    text: 'Do you currently have prostate cancer?',
    linkId: 'prostateCancer',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const breastCancer = createFhirQuestionnaireItem({
    text: 'Do you have breast cancer or a history of breast cancer?',
    linkId: 'breastCancer',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const hyperprolactinemia = createFhirQuestionnaireItem({
    text: 'Have you ever been diagnosed with hyperprolactinemia, pituitary tumor, Prader-Willi syndrome, or Kallmann syndrome?',
    linkId: 'hyperprolactinemia',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  const polycythemia = createFhirQuestionnaireItem({
    text: 'Have you ever been diagnosed with polycythemia (increased red blood cell concentration)?',
    linkId: 'polycythemia',
    type: 'choice',
    answerOption: [
      {
        valueCoding: {
          code: 'yes',
          display: 'Yes',
        },
      },
      {
        valueCoding: {
          code: 'no',
          display: 'No',
        },
      },
    ],
    _type: {
      extension: [createFhirExtInputVariant({ valueString: 'radio' })],
    },
    extension: [
      createFhirExtValidation({
        extension: [
          { url: 'operator', valueString: 'match' },
          { url: 'value', valueString: 'no' },
          {
            url: 'message',
            valueString:
              'Pharmacist-guided treatment may not be the best option for you at this time. We recommend scheduling a visit with another primary care provider for further assessment and personalized care.',
          },
        ],
      }),
    ],
    required: true,
  })

  return createFhirQuestionnaireItem({
    linkId: 'minorAilmentEligibilityHeading',
    type: 'group',
    item: [
      heading,
      completingQuestions,
      assignedSex,
      age,
      prostateCancer,
      breastCancer,
      hyperprolactinemia,
      polycythemia,
    ],
  })
}

export const fhirMedicalScreeningQuestionnaire = createFhirQuestionnaire({
  resourceType: 'Questionnaire',
  id: 'medicalScreeningQuestionnaire',
  status: 'draft',
  item: [
    createFhirQuestionnaireItem({
      linkId: 'medicalScreeningQuestionnairePage',
      type: 'group',
      item: [minorAilmentEligibilityScreening()],
    }),
  ],
})

export const fhirMedicalScreeningLowTestosteroneIDJson: JsonFhirFormStep = {
  type: 'fhir',
  fhir: fhirMedicalScreeningQuestionnaire,
  filter: {
    regionCodes: ['ID'],
    isEnterprise: false,
    appointmentActivity: {
      urlParams: ['low-testosterone'],
    },
  },
  metadata: {
    id: 'fhirMedicalScreeningLowTestosteroneIDJson',
    name: 'Medical Screening',
    path: 'medical-screening',
    showOnSteps: true,
  },
}
