import * as React from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { FhirQuestionnaire } from '../../../../../lib/fhir/resources'
import { createFhirFormRulesEngine } from './createFhirFormRulesEngine'
import { createRulesEngineFhirResolver } from './createRulesEngineFhirResolver'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { addContext } from './addContext'
import { addEnableWhenEmitter } from './addEnableWhenEmitter'
import { addEnableWhenEventRules } from './addEnableWhenEventRules'
import { addValidationRules } from './addValidationRules'
import {
  addFhirEnableWhenOperators,
  addMatch,
  addIsEmail,
  addIsPhone,
  addIsSameOrBefore,
  addIsBefore,
  addIsAfter,
} from './operators'

export const useRulesEngine = (
  questionnaire: FhirQuestionnaire,
  onDisabledUpdate: (value: Record<string, true | undefined>) => void
) => {
  const { t } = useMedMeTranslation('common')
  const { state } = useBookingContext()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rulesEngine = React.useMemo(
    createFhirFormRulesEngine([
      addContext(state),
      addFhirEnableWhenOperators,
      addValidationRules(questionnaire, t),
      addEnableWhenEventRules(questionnaire),
      addEnableWhenEmitter(onDisabledUpdate),
      addMatch,
      addIsEmail,
      addIsPhone,
      addIsSameOrBefore,
      addIsBefore,
      addIsAfter,
    ]),
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resolver = React.useMemo(
    createRulesEngineFhirResolver(rulesEngine, questionnaire),
    []
  )

  return { rulesEngine, resolver }
}
