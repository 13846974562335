export class AuthError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AuthError'
  }
}

export type IdpIntegration = {
  id: string
  clientId: string
  identityProvider?: string
  logoutUri?: string
  name?: string
  redirectUri: string
}
