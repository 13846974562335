import * as React from 'react'
import { ThemeProvider as ScThemeProvider } from 'styled-components'

import { createAppointment } from '~/lib/appointment'
import { FhirForm } from '~/lib/fhir/FhirForm'
import { createPatient } from '~/lib/patient'
import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import { createTheme } from '../AppointmentIntake/contexts/WhiteLabelContext/Theme'

import { PersistentDrawer } from './PersistantDrawer'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import questionnaires from '~/lib/fhir/questionnaires'
import { Questionnaire } from 'fhir/r5'

/**
 * Page used for devs to test questionnaires without having to go throught the intake
 * @returns
 */
export const QuestionnaireTool: React.FC = () => {
  const [questionnaireName, setQuestionnaireName] = React.useState<
    keyof typeof questionnaires
  >('fhirMAContactInformationIndiesONJson')
  const [fhirQuestionnaires] = React.useState<
    Record<keyof typeof questionnaires, FhirQuestionnaire>
  >(() => {
    return Object.keys(questionnaires).reduce<
      Record<string, FhirQuestionnaire>
    >((acc, key: keyof typeof questionnaires) => {
      const fhirQuestionnaire = new FhirQuestionnaire(
        questionnaires[key] as Questionnaire
      )
      acc[key] = fhirQuestionnaire
      return acc
    }, {})
  })

  const fhirQuestionnaire = fhirQuestionnaires[questionnaireName]

  // Need to unmount FhirForm for it to load a FhirQuestionnaire properly
  // Can remove this if we improve the FhirForm implementation to be less memorized
  const [updating, setUpdating] = React.useState(true)

  React.useEffect(() => {
    if (updating) {
      setUpdating(false)
    }
  }, [updating])

  return (
    <PersistentDrawer
      name={questionnaireName}
      elements={[
        // TODO: Fix eslint error
        // eslint-disable-next-line react/jsx-key
        <FormControl>
          <InputLabel>Questionnaire</InputLabel>
          <Select
            value={questionnaireName}
            onChange={(e) => {
              const value = e.target.value as keyof typeof questionnaires
              setQuestionnaireName(value)
              setUpdating(true)
            }}
          >
            {Object.keys(questionnaires).map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>,
      ]}
    >
      <ScThemeProvider theme={createTheme()}>
        {updating ? (
          <div>loading...</div>
        ) : (
          <FhirForm
            questionnaire={fhirQuestionnaire}
            state={{
              patient: createPatient({
                firstName: 'TestFirstName',
                lastName: 'TestLastName',
                gender: 'male',
                birthDate: '1990-09-09',
                address: {
                  unit: 'Suite 01',
                  streetName: 'MedMe Ave',
                  streetNumber: '908',
                  city: 'Toronto',
                  province: 'ON',
                  postalCode: 'M4L2H0',
                  country: 'Canada',
                },
                email: 'thomas@medmehealth.com',
                phone: {
                  home: '+16478888888',
                  cell: '+14169671111',
                },
              }),
              appointment: createAppointment(),
              fields: {},
            }}
            Form={({ children, ...props }) => (
              <form key={questionnaireName} {...props}>
                {children}
                <Button type="submit">Submit</Button>
              </form>
            )}
            onSubmit={async (data, fieldMap, _e) => {
              fhirQuestionnaire.saveAnswers(data)
              console.log(
                'response: ',
                fhirQuestionnaire.generateQuestionnaireResponse()
              )
              console.log(questionnaires[questionnaireName])
              console.log('data: ', data)
              console.log('fieldMap: ', fieldMap)
            }}
          />
        )}
      </ScThemeProvider>
    </PersistentDrawer>
  )
}
