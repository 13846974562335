import { CreateIntakeAnswerInput } from '~/graphql/types/schema.type'
import { Appointment } from '~/lib/appointment'

export const createIntakeAnswerInput = (
  appointment: Appointment
): CreateIntakeAnswerInput => {
  return {
    consentCollectedBy: appointment.consentCollectedBy,
    consentGiverName: appointment.consentGiverName,
    injectionName: appointment.injectionName,
  }
}
