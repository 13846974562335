import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { AilmentSelector as InnerSelector } from './AilmentSelector/ailmentSelector'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useProvinces } from './AilmentSelector/hooks/useProvinces/useProvinces'
import { useGetAppointmentActivities } from './useGetAppointmentActivities'
import { useFormManager } from '~/pages/AppointmentIntake/FormManager/useFormManager'
import { Province } from '~/util/minorAilments/provincesUtil'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { EAppointmentTypeMethod } from '~/graphql/types/schema.type'
export interface AilmentSelectorForm {
  province: string
  symptom: string
  appointmentId: string
  method: EAppointmentTypeMethod
}

export const getProvince = (provincesData: Province[], province: string) => {
  for (let i = 0; i < provincesData?.length; i++) {
    if (provincesData[i].value === province) {
      return province
    }
  }
  return ''
}

export const AilmentSelector: React.FC = () => {
  const {
    state: {
      scope,
      router: {
        searchParams: { appointmentType },
      },
      pharmacy,
      appointmentActivityGroup,
    },
    selectAppointmentActivityAsync,
  } = useBookingContext()
  const { nextStep } = useFormManager()
  const [error, setError] = React.useState('')
  const provincesData = useProvinces()
  const defaultValues = {
    province: scope.regionCode || '',
    symptom: 'all-minor-ailments',
    appointmentId: '',
  }
  const methods = useForm<AilmentSelectorForm>({
    defaultValues: defaultValues,
    shouldUnregister: false,
  })
  const selectedProvince = getProvince(provincesData, methods.watch('province'))
  const getAppointmentActivityUrlParam = (urlParam: string) => {
    if (selectedProvince !== '') {
      return urlParam
    }
    return ''
  }

  const {
    appointmentActivities,
    loading: getApptActivitiesLoading,
    error: getApptActivitiesError,
  } = useGetAppointmentActivities({
    regionCode: selectedProvince,
    countryCode: 'CA',
    groupId: appointmentActivityGroup.id,
    locationId: pharmacy.id,
  })

  type UrlParamToActivities = {
    [key: string]: AppointmentActivityInstance
  }
  const urlParamToActivities: UrlParamToActivities = React.useMemo(
    () =>
      appointmentActivities.reduce(
        (accum, activity) => ({
          ...accum,
          [activity.urlParam]: activity,
        }),
        {}
      ),
    [appointmentActivities]
  )

  const onSubmit = async (data: AilmentSelectorForm) => {
    const { error } = await selectAppointmentActivityAsync({
      id: data.appointmentId,
      regionCode: data.province,
      method: data.method,
    })
    if (error) {
      setError(error)
    }
    nextStep()
  }

  const anyErrors = error || getApptActivitiesError

  if (anyErrors) {
    throw new Error(anyErrors)
  }

  return (
    <FormProvider {...methods}>
      <InnerSelector
        selectedProvince={getProvince(provincesData, selectedProvince)}
        appointmentActivityUrlParam={getAppointmentActivityUrlParam(
          appointmentType
        )}
        urlParamToActivities={urlParamToActivities}
        appointmentActivityGroup={appointmentActivityGroup}
        appointmentActivities={appointmentActivities}
        isAppointmentActivitiesLoading={getApptActivitiesLoading}
        hideProvinceSelector={!!pharmacy.id}
        provinces={provincesData}
        onSubmit={onSubmit}
      />
    </FormProvider>
  )
}
