import React, { useState, useCallback } from 'react'
import { ReusableIntakeDataContext } from './ReusableIntakeDataContext'

export const ReuseableInputProvider: React.FC = ({ children }) => {
  // Data can be any data type returned from the Input.
  const [data, setData] = useState<Record<string, any>>({})
  const [patientIndex, setPatientIndex] = useState<number>(0)

  const updateData = useCallback(
    (question: Record<string, any>) => {
      setData({
        ...data,
        ...question,
      })
    },
    [data]
  )

  return (
    <ReusableIntakeDataContext.Provider
      value={{ data, updateData, setPatientIndex, patientIndex }}
    >
      {children}
    </ReusableIntakeDataContext.Provider>
  )
}
