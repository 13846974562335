import React from 'react'
import { DetailsWidgetItem } from './DetailsWidgetItem'
import { Divider } from '@material-ui/core'
import {
  PatientProgressNode,
  ProgressWidget,
} from '../ProgressWidget/ProgressWidget'

export const DetailsWidgetContainer = ({
  progressInput,
}: {
  progressInput: PatientProgressNode[]
}) => {
  return (
    <>
      <DetailsWidgetItem type="desktop">
        <Divider />
        <ProgressWidget nodes={progressInput} />
      </DetailsWidgetItem>
      <DetailsWidgetItem type="mobile">
        <ProgressWidget nodes={progressInput} />
      </DetailsWidgetItem>
    </>
  )
}
