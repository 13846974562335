import { ICache } from './types'

export class InMemoryCache<K, V> implements ICache<K, V> {
  private readonly cache: Map<K, V>

  constructor() {
    this.cache = new Map<K, V>()
  }

  public set(key: K, value: V): void {
    this.cache.set(key, value)
  }

  public get(key: K): V | undefined {
    return this.cache.get(key)
  }

  public has(key: K): boolean {
    return this.cache.has(key)
  }

  public delete(key: K): void {
    this.cache.delete(key)
  }

  public clear(): void {
    this.cache.clear()
  }
}
