import React from 'react'
import { DistanceContainer, DistanceLabel } from './Distance.style'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

type DistanceProps = {
  distance?: number
  isBottom?: boolean
}

export const Distance: React.FC<DistanceProps> = ({ distance, isBottom }) => {
  const { t } = useMedMeTranslation('patient')

  if (!distance) return null
  return (
    <DistanceContainer isBottom={isBottom}>
      <DistanceLabel>
        {t('storeSelection.storeDistance', {
          distance: Math.round((distance ?? 0) * 100) / 100,
        })}{' '}
      </DistanceLabel>
    </DistanceContainer>
  )
}
