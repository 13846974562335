import * as React from 'react'
import { CreateFormStep } from '..'
import { JsonFhirFormStep, JsonFhirR5FormStep } from '../../jsonFormSteps'
import { FhirFormStepComponent } from '~/pages/AppointmentIntake/forms'

export const createFhirFormStep: CreateFormStep = (
  json: JsonFhirFormStep | JsonFhirR5FormStep
) => {
  return {
    Component: () => <FhirFormStepComponent json={json} />,
    metadata: json.metadata,
    questionnaire: json.version === 'R5' ? json.fhir : undefined,
    enableWhen: (state) => {
      if (json.version === 'R5') return true
      const filter = json.filter
      if (!filter) return true
      const applyAAIFilter = (
        aaiFilter: Required<typeof filter>['appointmentActivity']
      ) =>
        Object.keys(aaiFilter).reduce<boolean>(
          (acc, key: keyof typeof aaiFilter) => {
            if (!acc) return acc
            const keyChecks = () => {
              switch (key) {
                case 'category':
                  return state.appointmentActivity.category === aaiFilter[key]
                case 'urlParams':
                  return aaiFilter[key]!.includes(
                    state.appointmentActivity.urlParam
                  )
                default:
                  return true
              }
            }
            return acc && keyChecks()
          },
          true
        )

      const applyFilter = Object.keys(filter).reduce<boolean>(
        (acc, key: keyof typeof filter) => {
          if (!acc) return acc
          const keyChecks = () => {
            switch (key) {
              case 'regionCodes':
                return filter[key]!.includes(state.scope.regionCode || '')
              case 'isEnterprise':
                return Boolean(state.router.enterpriseCode) === filter[key]
              case 'enterpriseCodes':
                return filter[key]!.includes(state.router.enterpriseCode)
              case 'appointmentActivity':
                return applyAAIFilter(filter[key]!)
              default:
                return true
            }
          }
          return acc && keyChecks()
        },
        true
      )

      return applyFilter
    },
    isAutoSkippable: async () => false,
  }
}
