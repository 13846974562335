const initialState = {
  pharmacistId: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DAY_CALENDAR_PHARMACIST_ID:
      return {
        pharmacistId: action.pharmacistId,
      }
    default:
      return state
  }
}

const UPDATE_DAY_CALENDAR_PHARMACIST_ID = 'UPDATE_DAY_CALENDAR_PHARMACIST_ID'

export const updatePharmacistId = (pharmacistId) => ({
  type: UPDATE_DAY_CALENDAR_PHARMACIST_ID,
  pharmacistId,
})

export default reducer
